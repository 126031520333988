import { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { List } from 'immutable';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';

const INITIAL_STATE = {
  tableId: null,
  databaseTableName: null,
};

const getTablesWithPrimaryKeyAsOptions = (tables) => {
  return tables
    .filter((table) => table.get('primaryKey', List()).count() === 1)
    .map((table) => {
      return {
        value: table.get('tableId'),
        label: table.get('dbName'),
      };
    })
    .toArray();
};

class LookerLinkTableModal extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.onHide = this.onHide.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.onHide}>
        <form onSubmit={this.handleSubmit} className="form-horizontal">
          <Modal.Header closeButton>
            <Modal.Title>Select Table You Want to Link</Modal.Title>
            <ModalIcon icon="database" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <p>
              Primary key from the selected table will be linked to the column{' '}
              <strong>{this.props.column.get('dbName')}</strong> as Foreign Key.
            </p>
            <Select
              clearable={false}
              value={this.state.tableId}
              onChange={(option) => {
                this.setState({
                  tableId: option,
                  databaseTableName: this.props.availableTables
                    .find((table) => table.get('tableId') === option)
                    .getIn(['primaryKey', 0]),
                });
              }}
              options={getTablesWithPrimaryKeyAsOptions(this.props.availableTables)}
            />
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel="Set"
              saveButtonType="submit"
              isDisabled={this.isSubmitDisabled()}
            />
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  isSubmitDisabled() {
    return !this.state.databaseTableName;
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.setPrimaryKeyFn(this.state.databaseTableName);
    this.onHide();
  }

  onHide() {
    this.setState(INITIAL_STATE);
    this.props.onHideFn();
  }
}

LookerLinkTableModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setPrimaryKeyFn: PropTypes.func.isRequired,
  onHideFn: PropTypes.func.isRequired,
  availableTables: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
};

export default LookerLinkTableModal;
