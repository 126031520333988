import { createCallbackMiddleware, createGenericFetchClient } from '../../fetchClient';
import type { ClientInitOptions } from '../types';

import { createAuthMiddleware } from './authMiddleware';
import { createFeatures } from './features';
import { createProjects } from './projects';
import { createUsers } from './users';

export type GenericFetchClient = ReturnType<typeof createGenericFetchClient>;

export const createManagementClient = ({
  baseUrl,
  callbacks,
  token,
}: { token?: string } & Omit<ClientInitOptions, 'token'>) => {
  const callbackMiddleware = createCallbackMiddleware(callbacks);
  const authMiddleware = createAuthMiddleware({
    baseUrl,
    accessToken: token,
  });

  const client = createGenericFetchClient({
    baseUrl: `${baseUrl}/manage`,
    middlewares: [
      callbackMiddleware,
      authMiddleware, // 👈  beware order of middlewares always matter
    ],
  });

  return {
    projects: createProjects(client),
    features: createFeatures(client),
    users: createUsers(client),
  };
};
