import { Button } from 'react-bootstrap';
import { Map } from 'immutable';

import {
  KEBOOLA_DATA_APPS,
  KEBOOLA_ORCHESTRATOR,
  KEBOOLA_SCHEDULER,
} from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { allowedTypes } from '@/modules/components-directory/constants';
import EventTriggersStore from '@/modules/event-trigger/EventTriggersStore';
import FlowConfigs from '@/modules/flows/components/FlowConfigs';
import NotificationsStore from '@/modules/notifications/store';
import { prepareOrchestrations } from '@/modules/orchestrations-v2/helpers';
import JobsStore from '@/modules/queue/store';
import CollapsibleAlert from '@/react/common/CollapsibleAlert';
import ConfigurationsTable from '@/react/common/ConfigurationsTable/ConfigurationsTable';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import contactSupport from '@/utils/contactSupport';
import { getInstanceConfigurationsFromComponent } from './helpers';
import TemplatesStore from './store';
import TemplateDetail from './TemplateDetail';

const InstanceDetail = () => {
  const {
    instance,
    allComponents,
    allConfigurations,
    hasProtectedDefaultBranch,
    latestJobs,
    triggers,
    notifications,
    admins,
    sapiToken,
    currentAdmin,
    hasNewQueue,
    hasFlows,
    readOnly,
  } = useStores(
    () => {
      const instance = TemplatesStore.getStore().instanceDetail;

      return {
        instance,
        allComponents: ComponentsStore.getAll() as Map<string, any>,
        allConfigurations: InstalledComponentsStore.getAll() as Map<string, any>,
        hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
        latestJobs: JobsStore.getLatestConfigJobs(),
        triggers: EventTriggersStore.getForComponent(KEBOOLA_ORCHESTRATOR),
        notifications: NotificationsStore.getAll(),
        admins: ApplicationStore.getAdmins(),
        sapiToken: ApplicationStore.getSapiToken(),
        currentAdmin: ApplicationStore.getCurrentAdmin(),
        hasNewQueue: ApplicationStore.hasNewQueue(),
        hasFlows: ApplicationStore.hasFlows(),
        readOnly: ApplicationStore.isReadOnly(),
      };
    },
    [],
    [
      TemplatesStore,
      ApplicationStore,
      ComponentsStore,
      InstalledComponentsStore,
      EventTriggersStore,
      NotificationsStore,
      JobsStore,
    ],
  );

  if (!instance?.instanceId) return null;

  const renderFlows = () => {
    const flowComponent: Map<string, any> = allConfigurations.get(KEBOOLA_ORCHESTRATOR);
    const flowConfigurations: Map<string, any> = prepareOrchestrations(
      getInstanceConfigurationsFromComponent(flowComponent, instance?.configurations),
      allConfigurations.get(KEBOOLA_SCHEDULER)?.get('configurations') || Map(),
    );

    if (flowConfigurations.isEmpty()) {
      return null;
    }

    return (
      <div>
        <h3 className="tw-mb-3 tw-leading-6">{hasFlows ? 'Flows' : 'Orchestrations'}</h3>
        <FlowConfigs
          readOnly={readOnly}
          hasFlows={hasFlows}
          configurations={flowConfigurations}
          allConfigurations={allConfigurations}
          admins={admins}
          currentAdmin={currentAdmin}
          triggers={triggers}
          component={flowComponent}
          latestJobs={latestJobs}
          isSearching={false}
          allComponents={allComponents}
          notifications={notifications}
          sapiToken={sapiToken}
          hasProtectedDefaultBranch={hasProtectedDefaultBranch}
        />
      </div>
    );
  };

  const renderConfigurationTable = (componentId: string) => {
    const component = allConfigurations.find((component) => component.get('id') === componentId);
    const isOther = component.get('type') === 'other';

    return (
      <ConfigurationsTable
        key={componentId}
        showComponentDetailLink={!isOther}
        showComponentIcon={!allowedTypes.includes(component.get('type'))}
        component={component}
        admins={admins}
        currentAdmin={currentAdmin}
        notifications={notifications}
        allComponents={allComponents}
        allConfigurations={allConfigurations}
        configurations={getInstanceConfigurationsFromComponent(component, instance?.configurations)}
        hasNewQueue={hasNewQueue}
        hasFlows={hasFlows}
        readOnly={readOnly || isOther}
      />
    );
  };

  const renderDataApps = () => {
    const hasDataApps = [
      ...new Set(instance.configurations.map(({ componentId }) => componentId)),
    ].includes(KEBOOLA_DATA_APPS);

    if (!hasDataApps) {
      return null;
    }

    return (
      <div>
        <h3 className="tw-mb-3 tw-leading-6">Data Apps</h3>
        {renderConfigurationTable(KEBOOLA_DATA_APPS)}
      </div>
    );
  };

  const renderConfigurations = () => {
    const components = [
      ...new Set(instance.configurations.map(({ componentId }) => componentId)),
    ].filter((componentId) => {
      return Object.values(componentTypes).includes(allComponents.getIn([componentId, 'type']));
    });

    if (!components.length) {
      return null;
    }

    return (
      <div>
        <h3 className="tw-mb-3 tw-leading-6">Configurations</h3>
        {components.map(renderConfigurationTable)}
      </div>
    );
  };

  return (
    <>
      {instance.templateDetail?.deprecated && (
        <CollapsibleAlert
          id={`template-${instance.templateId}`}
          title="This template has been deprecated"
          variant="warning"
          className="tw-mb-6"
        >
          <p>
            To ensure future compatibility, consider switching to an alternative template. If you
            have any questions or need assistance, please{' '}
            <Button bsStyle="link" className="btn-link-inline" onClick={() => contactSupport()}>
              contact our support
            </Button>
            .
          </p>
        </CollapsibleAlert>
      )}
      <div>
        {instance.versionDetail && <TemplateDetail expandByDefault={false} hideInstancesTable />}
        {renderFlows()}
        {renderDataApps()}
        {renderConfigurations()}
      </div>
    </>
  );
};

export default InstanceDetail;
