import { Fragment } from 'react';

import { Separator } from '@keboola/design';

import { FeatureListSkeleton } from './FeaturesItemSkeleton';
import { FeatureSwitch, type OnFeatureToggleHandler } from './FeatureSwitch';
import type { FeatureFilter, FeatureVariant, FeFeature } from './types';
import { isFeatureApproved, isFeatureFiltered } from './utils';

const NO_FEATURES = 'No project features available';

type Props = {
  variant: FeatureVariant;
  filter?: FeatureFilter;
  features: FeFeature[] | undefined;
  isLoading: boolean;
  isError: boolean;
  onFeatureToggle?: OnFeatureToggleHandler;
  isFeatureLoading?: ((feature: FeFeature) => boolean) | boolean;
};

export const FeatureList = ({
  variant,
  filter = { type: 'all' },
  features = [],
  isLoading,
  isError,
  onFeatureToggle,
  isFeatureLoading,
}: Props) => {
  if (isLoading) return <FeatureListSkeleton variant={variant} />;
  if (isError) return NO_FEATURES;

  const filteredFeatures = features.filter(
    (feature) => isFeatureApproved(feature) && isFeatureFiltered(feature, filter),
  );

  return (
    <div className="tw-m-0 tw-flex tw-flex-col tw-gap-6">
      {filteredFeatures.length === 0 && NO_FEATURES}
      {filteredFeatures.map((feature) => (
        <Fragment key={feature.id}>
          <FeatureSwitch
            variant={variant}
            searchQuery={filter?.query}
            data={feature}
            onToggle={onFeatureToggle}
            isLoading={isFeatureLoading}
          />
          <Separator orientation="horizontal" className="last:tw-hidden" />
        </Fragment>
      ))}
    </div>
  );
};
