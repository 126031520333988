import { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Map } from 'immutable';
import removeMarkdown from 'remove-markdown';

import { Button, ButtonGroup, copyToClipboard, Icon } from '@keboola/design';

import { getErrorExplanation, sendFeedback } from '@/modules/ai/api';
import { FEEDBACK_STATUS } from '@/modules/ai/constants';
import { extractErrorDetails, hasInternalError } from '@/modules/queue/helpers';
import ModalIcon from '@/react/common/ModalIcon';
import contactSupport from '@/utils/contactSupport';
import JobErrorResult from './JobErrorResult';

class JobErrorModal extends Component {
  state = {
    message: '',
    hasError: false,
    correlationId: null,
    usedSupportButton: false,
    savedInClipboard: false,
    feedbackStatus: null,
    isGenerating: false,
  };

  render() {
    const exceptionId = this.props.job.getIn(
      ['result', 'error', 'exceptionId'], // new queue
      this.props.job.getIn(['result', 'exceptionId']), // old jobs
    );

    return (
      <Modal
        bsSize="large"
        show={this.props.show}
        onHide={this.onHide}
        onEnter={() => this.props.useAi && !this.state.message && this.handleExplain()}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Job Error
            {exceptionId && <small className="text-muted ml-2">ExceptionId {exceptionId}</small>}
          </Modal.Title>
          <ModalIcon icon="circle-exclamation" color="red" bold />
        </Modal.Header>
        <Modal.Body className="overflow-break-anywhere">
          <JobErrorResult
            job={this.props.job}
            useAi={this.props.useAi}
            hasError={this.state.hasError}
            explanationViaAI={this.state.message}
            feedbackStatus={this.state.feedbackStatus}
            onReload={this.handleExplain}
            onGood={this.sendGoodFeedback}
            onBad={this.sendBadFeedback}
            isGenerating={this.state.isGenerating}
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup variant="block">
            <Button
              variant="outline"
              disabled={
                this.state.savedInClipboard ||
                (this.props.useAi && (this.state.isGenerating || !this.state.message))
              }
              onClick={() => {
                return copyToClipboard(this.prepareClipboardText(exceptionId)).then(() => {
                  this.setState({ savedInClipboard: true });
                  setTimeout(() => this.setState({ savedInClipboard: false }), 1000);
                });
              }}
            >
              <Icon icon="copy" />
              {this.state.savedInClipboard ? 'Copied' : 'Copy details to clipboard'}
            </Button>
            <Button
              onClick={() => {
                contactSupport();
                this.setState({ usedSupportButton: true });
              }}
            >
              <Icon icon="message-question" />
              Contact Support
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    );
  }

  sendGoodFeedback = () => {
    this.sendFeedback(FEEDBACK_STATUS.GOOD);
  };

  sendBadFeedback = (comment) => {
    this.sendFeedback(FEEDBACK_STATUS.BAD, comment);
  };

  onHide = () => {
    this.props.onHide();

    if (!this.state.feedbackStatus && !!this.state.message && !this.state.isGenerating) {
      this.sendFeedback(
        FEEDBACK_STATUS.CANCEL,
        this.state.usedSupportButton && 'Contact support clicked!',
      );
    }
  };

  sendFeedback = (status, comment) => {
    this.setState({ feedbackStatus: status });
    sendFeedback({
      value: status,
      subject: { type: 'failed-job', jobId: this.props.job.get('id') },
      ...(comment && { comment }),
      ...(this.state.correlationId && { correlationId: this.state.correlationId }),
    });
  };

  handleExplain = () => {
    this.setState({ isGenerating: true, feedbackStatus: null, hasError: false });
    getErrorExplanation(this.props.job)
      .then((data) => {
        this.setState({ message: data.answer, correlationId: data.correlationId });
      })
      .catch(() => this.setState({ hasError: true }))
      .finally(() => this.setState({ isGenerating: false }));
  };

  prepareClipboardText = (exceptionId) => {
    const jobId = this.props.job.get('id');
    const exception = exceptionId ? `\nExceptionId ${exceptionId}` : '';
    const errorMessage = hasInternalError(this.props.job)
      ? 'Internal Error'
      : extractErrorDetails(this.props.job.getIn(['result', 'message'])).trim();

    const aiMessage =
      this.state.message && !this.state.isGenerating
        ? `\nAI Explanation: ${removeMarkdown(this.state.message).trim()}`
        : '';

    return `Job Error

Job ${jobId}${exception}
${window.location.href}

${errorMessage}
${aiMessage}`;
  };
}

JobErrorModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  job: PropTypes.instanceOf(Map).isRequired,
  useAi: PropTypes.bool.isRequired,
};

export default JobErrorModal;
