import React from 'react';

import { ProgressBar } from './ProgressBar';

export const FakeProgressBar = (
  props: Omit<React.ComponentProps<typeof ProgressBar>, 'progress'>,
) => {
  const [fakeProgress, setFakeProgress] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setFakeProgress((previous) => {
        const remainingProgress = 100 - previous;
        const increment = Math.max(0.05, remainingProgress / 100);

        return Math.min(99.9, previous + increment);
      });
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return <ProgressBar {...props} progress={fakeProgress} />;
};
