import { useMemo } from 'react';
import { Map } from 'immutable';

import type { UserMessage } from '@/api/routes/aiService';
import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { findTemplateInstanceIdFromMetadata } from '@/modules/components/helpers';
import Notifications from '@/modules/notifications/components/Notifications';
import JobsGraphWithPaging from '@/modules/queue/components/JobsGraphWithPaging';
import { filterLatestJobs } from '@/modules/queue/helpers';
import Schedules from '@/modules/scheduler/components/Schedules';
import { activeSchedules } from '@/modules/scheduler/helpers';
import { routeNames as templatesRouteNames } from '@/modules/templates/constants';
import { RouterLink } from '@/react/common';
import Builder from './components/Builder';
import FlowActions from './components/FlowActions';
import JobsTable from './components/JobsTable';
import Tabs from './components/Tabs';
import useFlow from './hooks/useFlow';
import { tabs } from './constants';
import Versions from './Versions';

const Detail = (props: { submitAutomationMessage?: (message: Partial<UserMessage>) => void }) => {
  const store = useFlow();

  const templateInstanceId = useMemo(() => {
    return findTemplateInstanceIdFromMetadata(
      KEBOOLA_ORCHESTRATOR,
      store.configId,
      store.componentsMetadata,
    );
  }, [store.configId, store.componentsMetadata]);

  if (!store.configId) return;

  const renderTabContent = () => {
    if (store.activeTab === tabs.ALL_RUNS) {
      const filteredJobs = filterLatestJobs(store.jobs);

      return (
        <div className="mt-2">
          <JobsGraphWithPaging job={filteredJobs.first()} />
          <JobsTable
            jobs={filteredJobs}
            configId={store.configId}
            allJobs={store.allJobs}
            admins={store.admins}
            sapiToken={store.sapiToken}
            isLoading={store.latestJobData.get('isLoading', false)}
            terminatingPendingActions={store.jobsPendingActions.get('terminating', Map())}
          />
        </div>
      );
    }

    if (store.activeTab === tabs.SCHEDULES) {
      return (
        <div className="mt-2">
          <Schedules
            configId={store.configId}
            tables={store.tables}
            buckets={store.buckets}
            triggers={store.triggers}
            schedulers={store.flow.get('schedulers')}
            hasDataStreams={store.hasDataStreams}
            isDevModeActive={store.isDevModeActive}
            canManageTriggers={store.canManageTriggers}
            canManageSchedule={store.canManageSchedule}
            hasProtectedDefaultBranch={store.hasProtectedDefaultBranch}
            dataStreamSources={store.dataStreamSources}
          />
        </div>
      );
    }

    if (store.activeTab === tabs.NOTIFICATIONS) {
      return (
        <div className="mt-1">
          <Notifications
            configId={store.configId}
            componentId={KEBOOLA_ORCHESTRATOR}
            notifications={store.notifications}
            allNotifications={store.allNotifications}
            admins={store.admins}
            readOnly={!store.canSetupNotifications}
          />
        </div>
      );
    }

    if (store.activeTab === tabs.VERSIONS) {
      return (
        <div className="mt-2">
          <Versions />
        </div>
      );
    }

    return (
      <div className="flow-container grid-background">
        {(!store.automationId || !store.phases.isEmpty()) && (
          <Builder
            config={store.config}
            configId={store.configId}
            automationId={store.automationId}
            readOnly={store.readOnly}
            hasPayAsYouGo={store.hasPayAsYouGo}
            showBackendSize={store.showBackendSize}
            patternComponents={store.allowedPatternComponents}
            hasJobsDynamicBackendSize={store.hasJobsDynamicBackendSize}
            hasSnowflakeDynamicBackendSize={store.hasSnowflakeDynamicBackendSize}
            hasDataApps={store.hasDataApps}
            flowStatus={store.flowStatus}
            components={store.components}
            configurations={store.configurations}
            visualizationPhases={store.visualizationPhases}
            tablesMetadataMap={store.tablesMetadataMap}
            tasks={store.tasks}
            phases={store.phases}
            folders={store.folders}
            isChanged={store.isChanged}
            hasSnowflakePartnerConnectLimited={store.hasSnowflakePartnerConnectLimited}
            hasTemplates={store.hasTemplates}
            isDevModeActive={store.isDevModeActive}
            submitAutomationMessage={props.submitAutomationMessage}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div className="tabs-with-border-wrapper flex-container flex-nowrap align-top">
        <Tabs
          configId={store.configId}
          activeTab={store.activeTab}
          hasAutomation={!!store.automationId}
          activeSchedules={activeSchedules(store.triggers, store.flow.get('schedulers'))}
        />
        <div className="flow-navigation-controls flex-container flex-end">
          {templateInstanceId && (
            <div className="navigation-control">
              <span className="text-muted">Created from </span>
              <RouterLink
                to={templatesRouteNames.INSTANCE_DETAIL}
                params={{ instanceId: templateInstanceId }}
                className="font-medium"
              >
                Template
              </RouterLink>
            </div>
          )}
          {!store.automationId && (
            <FlowActions
              flow={store.flow}
              config={store.config}
              component={store.component}
              otherFlows={store.otherFlows}
              readOnly={store.readOnly}
            />
          )}
        </div>
      </div>
      {renderTabContent()}
    </>
  );
};

export default Detail;
