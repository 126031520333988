import type { Map } from 'immutable';

import { cn } from '@keboola/design';

import { useDeleteComponent } from '@/modules/trash/rqHooks/useDeleteComponent';
import { useRestoreConfiguration } from '@/modules/trash/rqHooks/useRestoreConfiguration';
import type { Configuration } from '@/modules/trash/types';
import { getDisabledRestoreReason } from '@/modules/trash/utils';
import { Truncated } from '@/react/common';
import Checkbox from '@/react/common/Checkbox';
import TimeAndUser from '@/react/common/TimeAndUser';
import descriptionExcerpt from '@/utils/descriptionExcerpt';
import DeleteConfigurationButton from './DeleteConfigurationButton';
import RestoreConfigurationButton from './RestoreConfigurationButton';

type Props = {
  readOnly: boolean;
  config: Configuration;
  component: Map<string, any>;
  admins: Map<string, any>;
  isDeleteEnabled: boolean;
  isSelected: boolean;
  isRestoreEnabled: boolean;
  onSelect: (isChecked: boolean) => void;
};

const DeletedConfigurationRow = ({
  readOnly,
  config,
  admins,
  component,
  isDeleteEnabled,
  isRestoreEnabled,
  isSelected,
  onSelect,
}: Props) => {
  const deleteComponent = useDeleteComponent();
  const restoreConfiguration = useRestoreConfiguration();

  const renderActions = () => {
    if (readOnly) {
      return null;
    }

    return (
      <>
        <RestoreConfigurationButton
          text={
            <>
              Are you sure you want to restore the configuration <strong>{config.name}</strong>?
            </>
          }
          disabledReason={getDisabledRestoreReason(component.get('id'))}
          onConfirm={() => {
            return restoreConfiguration.mutateAsync({
              componentId: component.get('id'),
              configurationId: config.id,
              configurationName: config.name,
            });
          }}
          isPending={restoreConfiguration.isPending}
        />
        {isDeleteEnabled && (
          <DeleteConfigurationButton
            configName={config.name}
            onConfirm={() => {
              return deleteComponent.mutateAsync({
                componentId: component.get('id'),
                configurationId: config.id,
                configurationName: config.name,
              });
            }}
            isPending={deleteComponent.isPending}
          />
        )}
      </>
    );
  };

  return (
    <span className={cn('tr hoverable-actions-with-replacement', { 'bg-selected': isSelected })}>
      <span className={cn('td', isSelected && 'bg-selected')}>
        <div className="tw-flex tw-items-center tw-gap-3">
          {!readOnly && isRestoreEnabled && (
            <Checkbox
              className="tw-mb-0.5"
              tooltip={`${isSelected ? 'Deselect' : 'Select'} configuration`}
              checked={isSelected}
              onChange={onSelect}
            />
          )}
          <div>
            <Truncated text={config.name ?? '---'} />
            {config.description && (
              <div>
                <span className="f-13 text-muted">{descriptionExcerpt(config.description)}</span>
              </div>
            )}
          </div>
        </div>
      </span>
      <span className={cn('td w-200 text-right', isSelected && 'bg-selected')}>
        <div className="actions-container">
          <div className="not-actions">
            <TimeAndUser
              admin={admins.get(config.currentVersion.creatorToken.description || '')}
              time={config.currentVersion.created}
              fallbackName={config.currentVersion.creatorToken.description}
            />
          </div>
          <div className="actions tw-z-10 tw-flex tw-flex-row tw-justify-end">
            {renderActions()}
          </div>
        </div>
      </span>
    </span>
  );
};

export default DeletedConfigurationRow;
