import { useEffect, useMemo, useState } from 'react';
import type {
  ChangeEvent,
  ChangeEventHandler,
  FocusEvent,
  FocusEventHandler,
  InputHTMLAttributes,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
} from 'react';

import { cn, debounce, noop } from '../../utils';
import { Icon } from '../Icon';

import { ClearButton } from './ClearButton';
import { genSearchTestId } from './utils';

export type SearchChangeEventHandler = (
  value: string,
  event: ChangeEvent<HTMLInputElement> | MouseEvent<HTMLButtonElement>,
) => void;

export type SearchBlurEventHandler = (
  value: string,
  event: FocusEvent<HTMLInputElement, Element>,
) => void;

type Props = {
  suffix?: ReactNode;
  disableDebounce?: boolean;
  debounceDelay?: number;
  onChange?: SearchChangeEventHandler;
  onBlur?: SearchBlurEventHandler;
  value?: string | null;
  defaultValue?: string | null;
  testId?: string;
  onClear?: () => void;
} & Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'onBlur' | 'onChange' | 'value' | 'defaultValue'
>;

export const Search = ({
  suffix,
  className,
  onBlur,
  onChange = noop,
  disabled = false,
  readOnly = false,
  disableDebounce = false,
  debounceDelay = 300,
  value: externalValue,
  defaultValue,
  testId: prefixTestId = '',
  placeholder = 'Search',
  onClear,
  ...rest
}: Props) => {
  const [internalValue, setInternalValue] = useState(() =>
    externalValue != null ? externalValue : defaultValue,
  );
  const hasValue = internalValue !== '';

  const isClearButtonVisible = hasValue && !disabled && !readOnly;
  const testId = genSearchTestId(prefixTestId);

  const debouncedOnChange = useMemo(
    () => (disableDebounce ? onChange : debounce(onChange, debounceDelay)),
    [disableDebounce, debounceDelay, onChange],
  );

  useEffect(() => {
    if (externalValue == null) return; // uncontrolled state
    if (externalValue === internalValue) return; // no sync needed
    setInternalValue(externalValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalValue]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const currentValue = event.target.value;
    setInternalValue(currentValue);
    debouncedOnChange(currentValue, event);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    const currentValue = event.target.value;
    setInternalValue(currentValue);
    onBlur?.(currentValue, event);
  };

  const handleClear: MouseEventHandler<HTMLButtonElement> = (event) => {
    setInternalValue('');
    onChange?.('', event);
    onClear?.();
  };

  return (
    <div
      className={cn(
        // positioning
        'tw-flex',
        'tw-items-center',
        'tw-gap-2.5',

        // base
        'tw-bg-white',
        'tw-rounded-lg',
        'tw-px-5',
        'tw-text-neutral-800',
        'tw-transition-all',
        'tw-font-sans',
        'tw-shadow-[0_2px_6px_0_rgba(100,135,153,0.13)]',

        // outline
        'tw-outline',
        'tw-outline-1',
        'tw-outline-transparent',

        // focus
        'has-[input:focus]:tw-outline-1',
        'has-[input:focus]:tw-ring-4',
        'has-[input:focus]:tw-outline-secondary-500',
        'has-[input:focus]:tw-ring-secondary-200',

        // //readonly
        'has-[input[readonly]]:tw-bg-neutral-150',
        'has-[input[readonly]]:focus-within:tw-ring-0',
        'has-[input[readonly]]:tw-outline-0',

        // disabled
        'disabled:tw-opacity-40',
        {
          'tw-cursor-not-allowed tw-opacity-40': disabled,
        },
        className,
      )}
      data-testid={testId.wrapper}
    >
      <Icon className="tw-text-base tw-text-neutral-400" icon="magnifying-glass" />

      <input
        {...rest}
        placeholder={placeholder}
        data-testid={testId.input}
        type="text"
        value={internalValue ?? ''}
        onChange={handleChange}
        onBlur={handleBlur}
        className={cn(
          // reset
          'tw-border-none',
          'tw-bg-transparent',
          'tw-outline-none',
          'tw-w-full',
          'tw-p-0',

          // base
          'tw-py-[15px]',
          'tw-text-base',
          'tw-leading-6',
          'tw-font-normal',
          'tw-font-sans',

          // placeholder
          'placeholder:tw-text-neutral-400',

          // disabled
          'disabled:tw-cursor-not-allowed',
        )}
        disabled={disabled}
        readOnly={readOnly}
      />

      {isClearButtonVisible && <ClearButton onClick={handleClear} testId={testId.clearButton} />}

      {suffix}
    </div>
  );
};
