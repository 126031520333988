import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup, Radio } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { HelpBlock } from '@keboola/design';

import GooglePicker from '@/modules/google-utils/react/GooglePicker';
import ViewTemplates from '@/modules/google-utils/react/PickerViewTemplates';
import Checkbox from '@/react/common/Checkbox';

const FileTab = createReactClass({
  propTypes: {
    onSelectExisting: PropTypes.func.isRequired,
    onSelectFolder: PropTypes.func.isRequired,
    onChangeTitle: PropTypes.func.isRequired,
    onToggleConvert: PropTypes.func.isRequired,
    onSwitchType: PropTypes.func.isRequired,
    valueTitle: PropTypes.string.isRequired,
    valueFolder: PropTypes.string.isRequired,
    valueAction: PropTypes.oneOf(['create', 'update']),
    valueConvert: PropTypes.bool.isRequired,
    type: PropTypes.oneOf(['new', 'existing']),
  },

  render() {
    const radio = this.props.valueAction === 'create' ? null : this.renderTypeRadio();
    const picker = this.props.type === 'new' ? this.renderFolderPicker() : this.renderFilePicker();
    const convertCheckbox = this.props.type === 'new' ? this.renderConvertCheckbox() : null;
    return (
      <div className="form-horizontal">
        {radio}
        {picker}
        {convertCheckbox}
      </div>
    );
  },

  renderTypeRadio() {
    return (
      <FormGroup>
        <div className="col-xs-2">
          <ControlLabel>File exists?</ControlLabel>
        </div>
        <div className="col-xs-10">
          <FormGroup>
            <Radio
              value="new"
              checked={this.props.type === 'new'}
              onChange={(event) => this.props.onSwitchType(event.target.value)}
            >
              No
            </Radio>
            <HelpBlock className="tw-mt-1">
              Create a new file that will be updated on each run
            </HelpBlock>
          </FormGroup>
          <FormGroup>
            <Radio
              value="existing"
              checked={this.props.type === 'existing'}
              onChange={(event) => this.props.onSwitchType(event.target.value)}
            >
              Yes
            </Radio>
            <HelpBlock className="tw-mt-1">
              Use an existing file previously created by this editor.
            </HelpBlock>
          </FormGroup>
        </div>
      </FormGroup>
    );
  },

  renderFilePicker() {
    return (
      <FormGroup>
        <div className="col-xs-2">
          <ControlLabel>File location</ControlLabel>
        </div>
        <div className="col-xs-10">
          <GooglePicker
            dialogTitle="Select File"
            buttonLabel={this.props.valueTitle ? this.props.valueTitle : 'Select File'}
            onPickedFn={this.props.onSelectExisting}
            buttonProps={{ bsStyle: 'default' }}
            views={[
              ViewTemplates.files,
              ViewTemplates.teamDriveFiles,
              ViewTemplates.sharedFiles,
              ViewTemplates.starredFiles,
              ViewTemplates.recentFiles,
            ]}
            multiselectEnabled={false}
          />
          <HelpBlock className="tw-mt-1">Choose a file you wish to update</HelpBlock>
        </div>
      </FormGroup>
    );
  },

  renderFolderPicker() {
    return (
      <FormGroup>
        <div className="col-xs-2">
          <ControlLabel>File location</ControlLabel>
        </div>
        <div className="col-xs-10">
          <div className="input-group">
            <div className="input-group-btn">
              <GooglePicker
                dialogTitle="Select Folder"
                buttonLabel={this.props.valueFolder}
                onPickedFn={this.props.onSelectFolder}
                buttonProps={{ bsStyle: 'default' }}
                views={[
                  ViewTemplates.rootFolder,
                  ViewTemplates.teamDriveFolders,
                  ViewTemplates.sharedFolders,
                  ViewTemplates.starredFolders,
                ]}
                multiselectEnabled={false}
              />
            </div>
            <FormControl
              placeholder="New file"
              type="text"
              value={this.props.valueTitle ? this.props.valueTitle : ''}
              onChange={this.props.onChangeTitle}
            />
          </div>
          <HelpBlock className="tw-mt-1">
            Select the file parent <strong>folder</strong> and enter the <strong>title</strong> of
            the file.
            <br />
            {this.props.valueAction === 'create'
              ? 'The file will be created on the next run. The current date and time will be appended to the file name.'
              : 'The file will be created upon saving.'}
          </HelpBlock>
        </div>
      </FormGroup>
    );
  },

  renderConvertCheckbox() {
    return (
      <FormGroup>
        <div className="col-xs-10 col-xs-offset-2">
          <Checkbox checked={this.props.valueConvert} onChange={this.props.onToggleConvert}>
            Convert to Google Docs format
          </Checkbox>
          <HelpBlock className="tw-mt-1">
            After the upload, the file will be converted so it can be edited directly in Google
            Drive
          </HelpBlock>
        </div>
      </FormGroup>
    );
  },
});

export default FileTab;
