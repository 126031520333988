import { useState } from 'react';

import { Button, ButtonGroup, ButtonLink, Icon, IconButton, Link, Tooltip } from '@keboola/design';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import EventTriggersStore from '@/modules/event-trigger/EventTriggersStore';
import JobsStore from '@/modules/queue/store';
import actions from '@/modules/stream/actions';
import { DATA_STREAMS_DOCS } from '@/modules/stream/constants';
import { findSource } from '@/modules/stream/helpers';
import StreamStore from '@/modules/stream/store';
import ConfirmModal from '@/react/common/ConfirmModal';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import AutomateStreamModal from './AutomateStreamModal';

const DetailHeaderButtons = () => {
  const { source, readOnly, triggers, flows, latestJobs } = useStores(
    () => {
      const { sources } = StreamStore.getStore();
      const source = findSource(sources, RoutesStore.getCurrentRouteParam('sourceId'));

      return {
        source,
        readOnly: ApplicationStore.isReadOnly(),
        triggers: EventTriggersStore.getForComponent(KEBOOLA_ORCHESTRATOR),
        flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
        latestJobs: JobsStore.getLatestConfigJobs(KEBOOLA_ORCHESTRATOR),
      };
    },
    [],
    [StreamStore, EventTriggersStore, JobsStore, ApplicationStore, RoutesStore],
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAutomateModal, setShowAutomateModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  if (readOnly || !source) return null;

  return (
    <>
      <ButtonGroup>
        <Button variant="outline" onClick={() => setShowAutomateModal(true)}>
          <Icon icon="clock" />
          Automate
        </Button>
        <ButtonLink variant="outline">
          <Link href={DATA_STREAMS_DOCS}>
            <Icon icon="book-blank" />
            Documentation
          </Link>
        </ButtonLink>
        <Tooltip type="action" tooltip="Delete Data Stream" placement="bottom">
          <IconButton
            variant="outline"
            onClick={() => setShowDeleteModal(true)}
            disabled={isDeleting}
            icon="trash"
          />
        </Tooltip>
      </ButtonGroup>
      <ConfirmModal
        closeAfterResolve
        show={showDeleteModal}
        icon="trash"
        title="Delete Data Stream"
        text="Are you sure you want to delete the data stream?"
        buttonLabel="Delete"
        buttonType="danger"
        onConfirm={() => {
          setIsDeleting(true);

          return actions.deleteSource(source.sourceId).finally(() => setIsDeleting(false));
        }}
        onHide={() => setShowDeleteModal(false)}
        isLoading={isDeleting}
      />
      <AutomateStreamModal
        show={showAutomateModal}
        onHide={() => setShowAutomateModal(false)}
        source={source}
        triggers={triggers}
        flows={flows}
        latestJobs={latestJobs}
      />
    </>
  );
};

export default DetailHeaderButtons;
