import createReactClass from 'create-react-class';

import { Search } from '@keboola/design';

import { KEBOOLA_ORCHESTRATOR, KEBOOLA_SCHEDULER } from '@/constants/componentIds';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import EventTriggersStore from '@/modules/event-trigger/EventTriggersStore';
import FlowConfigs from '@/modules/flows/components/FlowConfigs';
import NotificationsStore from '@/modules/notifications/store';
import JobsStore from '@/modules/queue/store';
import NoResultsFound from '@/react/common/NoResultsFound';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { matchByWords } from '@/utils';
import BlankOrchestrationPage from './components/BlankOrchestrationPage';
import { prepareOrchestrations } from './helpers';

const Index = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      ComponentsStore,
      InstalledComponentsStore,
      EventTriggersStore,
      NotificationsStore,
      JobsStore,
    ),
  ],

  getStateFromStores() {
    const orchestrations = prepareOrchestrations(
      InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SCHEDULER),
    );

    return {
      orchestrations,
      latestJobs: JobsStore.getLatestConfigJobs(),
      allConfigurations: InstalledComponentsStore.getAll(),
      component: ComponentsStore.getComponent(KEBOOLA_ORCHESTRATOR),
      triggers: EventTriggersStore.getForComponent(KEBOOLA_ORCHESTRATOR),
      hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      sapiToken: ApplicationStore.getSapiToken(),
      notifications: NotificationsStore.getAll(),
      admins: ApplicationStore.getAdmins(),
      readOnly: ApplicationStore.isReadOnly(),
      allComponents: ComponentsStore.getAll(),
    };
  },

  getInitialState() {
    return {
      filterQuery: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
    };
  },

  render() {
    if (this.state.orchestrations.isEmpty()) {
      return <BlankOrchestrationPage />;
    }

    return (
      <div className="tw-flex tw-flex-col tw-gap-6">
        <Search
          placeholder={this.getPlaceholder()}
          defaultValue={this.state.filterQuery}
          onChange={(value) => {
            this.setState({ filterQuery: value });
            RoutesStore.getRouter().updateQuery({ q: value });
          }}
          allowClear
        />
        {this.renderOrchestrations()}
      </div>
    );
  },

  renderOrchestrations() {
    const filteredOrchestrations = this.state.orchestrations.filter((orchestration) => {
      return (
        orchestration.get('id') === this.state.filterQuery ||
        matchByWords(
          [orchestration.get('name'), orchestration.get('description')],
          this.state.filterQuery,
        )
      );
    });

    if (filteredOrchestrations.isEmpty()) {
      return <NoResultsFound entityName="orchestrations" />;
    }

    return (
      <FlowConfigs
        hasFlows={false}
        configurations={filteredOrchestrations}
        allConfigurations={this.state.allConfigurations}
        hasProtectedDefaultBranch={this.state.hasProtectedDefaultBranch}
        admins={this.state.admins}
        currentAdmin={this.state.currentAdmin}
        sapiToken={this.state.sapiToken}
        triggers={this.state.triggers}
        readOnly={this.state.readOnly}
        component={this.state.component}
        latestJobs={this.state.latestJobs}
        isSearching={!!this.state.filterQuery}
        allComponents={this.state.allComponents}
        notifications={this.state.notifications}
      />
    );
  },

  getPlaceholder() {
    return `Search orchestrations (${this.state.orchestrations.count()})`;
  },
});

export default Index;
