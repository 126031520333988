import { memo, useRef } from 'react';

import type { PrevieData } from '@keboola/api-client';
import { Alert } from '@keboola/design';

import { DataSampleItem } from '@/modules/storage/components/DataSample';
import { previewLimit } from '@/modules/storage/constants';
import LazyList from '@/react/common/LazyList';
import string from '@/utils/string';

export const TableRowsPreview = memo(({ previewData }: { previewData: PrevieData }) => {
  const tableRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Alert
        className="tw-mb-4"
        variant={previewData.rows.length === previewLimit ? 'warning' : 'info'}
      >
        {previewData.rows.length === previewLimit ? (
          <>
            <b>First {previewLimit}</b> matched rows found. The actual number of matched rows may be
            higher.
          </>
        ) : (
          <>
            <b>{previewData.rows.length}</b> matched{' '}
            {string.pluralize(previewData.rows.length, 'row')} found.
          </>
        )}
      </Alert>
      {previewData.rows.length > 0 && (
        <div
          ref={tableRef}
          className="table-responsive tw-mb-4 tw-max-h-80 tw-border tw-border-solid tw-border-neutral-200"
        >
          <table className="table ">
            <thead>
              <tr className="is-sticky no-shadow">
                {previewData.columns.map((column) => (
                  <th
                    key={column}
                    className="tw-min-w-32 tw-whitespace-nowrap tw-bg-neutral-50 !tw-p-2"
                  >
                    {column}
                  </th>
                ))}
              </tr>
            </thead>
            <LazyList
              rootElement="tbody"
              limit={50}
              useWindow={false}
              getScrollParent={() => tableRef.current}
              items={previewData.rows}
              render={(rows) => {
                return rows.map((row, index) => {
                  return (
                    <tr key={index}>
                      {row.map((cell) => (
                        <td key={cell.columnName} className="!tw-px-2 !tw-py-1">
                          <DataSampleItem source={cell.value} isNotDownloadable />
                        </td>
                      ))}
                    </tr>
                  );
                });
              }}
            />
          </table>
        </div>
      )}
    </>
  );
});
