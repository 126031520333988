import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { cn, Icon, IconButton, Tooltip } from '@keboola/design';

import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import StorageTableLink from '@/modules/components/react/components/StorageApiTableLinkEx';
import { RowActionDropdown, RowActionMenuItem } from '@/react/common';
import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';
import RoutesStore from '@/stores/RoutesStore';

const QueriesTable = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    componentId: PropTypes.string.isRequired,
    accounts: PropTypes.object.isRequired,
    allTables: PropTypes.object.isRequired,
    queries: PropTypes.object.isRequired,
    configId: PropTypes.string.isRequired,
    bucketId: PropTypes.string.isRequired,
    deleteQueryFn: PropTypes.func.isRequired,
    isPendingFn: PropTypes.func.isRequired,
    toggleQueryEnabledFn: PropTypes.func.isRequired,
    getRunSingleQueryDataFn: PropTypes.func.isRequired,
  },

  render() {
    return (
      <table className="table table-hover overflow-break-anywhere">
        <thead>
          <tr>
            <th>Name</th>
            <th>Accounts</th>
            <th />
            <th>Output Tables</th>
            <th />
          </tr>
        </thead>
        <tbody>{this.props.queries.map((q) => this.renderQueryRow(q))}</tbody>
      </table>
    );
  },

  renderQueryRow(query) {
    const queryName = query.get('name');
    const queryId = query.get('id');
    const isDeleting = !!this.props.isPendingFn(['delete', queryId]);
    const isPending = !!this.props.isPendingFn(['toggle', queryId]);

    return (
      <tr
        key={queryId}
        className={cn('tr clickable hoverable-actions', {
          'row-disabled': query.get('disabled'),
        })}
        onClick={() => {
          return RoutesStore.getRouter().transitionTo(this.props.componentId + '-query-detail', {
            config: this.props.configId,
            queryId: queryId,
          });
        }}
      >
        <td>{queryName}</td>
        <td>{this.renderAccounts(query.getIn(['query']))}</td>
        <td>
          <Icon icon="angle-right" fixedWidth />
        </td>
        <td>{this.renderTables(queryName)}</td>
        <td className="td pl-0 pr-1 no-wrap">
          {!this.props.readOnly && (
            <>
              <Tooltip tooltip="Delete Query" placement="top" triggerClassName="tw-align-middle">
                <IconButton
                  className="tw-inline-flex"
                  variant="inline"
                  disabled={isDeleting}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.deleteQueryFn(query);
                  }}
                  isLoading={isDeleting}
                  icon="trash"
                />
              </Tooltip>
              <RowActionDropdown showLoading={isPending}>
                <RunComponentButton
                  mode="menuitem"
                  title="Run Query"
                  componentId={this.props.componentId}
                  runParams={() => {
                    return {
                      config: this.props.configId,
                      configData: this.props.getRunSingleQueryDataFn(queryId),
                    };
                  }}
                >
                  You are about to run an extraction of {queryName}.
                </RunComponentButton>
                <RowActionMenuItem divider />
                <ActivateDeactivateSwitch
                  mode="menuitem"
                  isActive={!query.get('disabled')}
                  isPending={!!this.props.isPendingFn(['toggle', queryId])}
                  onChange={() => this.props.toggleQueryEnabledFn(queryId)}
                />
              </RowActionDropdown>
            </>
          )}
        </td>
      </tr>
    );
  },

  matchTableToLongestQuery(tableName) {
    const qs = this.props.queries.filter((q) => this.tableIsFromQuery(tableName, q.get('name')));
    return qs.max((qa, qb) => qa.get('name').length > qb.get('name').length);
  },

  tableIsFromQuery(tableName, queryName) {
    return tableName.startsWith(`${queryName}_`) || tableName === queryName;
  },

  renderTables(queryName) {
    const configTables = this.props.allTables.filter((t) => {
      if (t.getIn(['bucket', 'id']) !== this.props.bucketId) return false;
      const tableName = t.get('name');
      const bestMatchQuery = this.matchTableToLongestQuery(tableName);
      return bestMatchQuery && bestMatchQuery.get('name') === queryName;
    });

    if (configTables.isEmpty()) {
      return 'N/A';
    }

    return (
      <div className="tables-list">
        {configTables
          .map((table) => <StorageTableLink key={table.get('id')} tableId={table.get('id')} />)
          .toArray()}
      </div>
    );
  },

  renderAccounts(query) {
    if (!query.has('ids')) {
      return 'None';
    }
    const ids = query.get('ids');
    if (!ids) {
      return 'All accounts';
    }
    return ids
      .split(', ')
      .map((id) => this.props.accounts.getIn([id, 'name'], id))
      .join(',');
  },
});

export default QueriesTable;
