import { extraErrorDataIntegration, globalHandlersIntegration, init } from '@sentry/react';

import { IS_DEVELOPMENT } from './constants/environment';

if (!IS_DEVELOPMENT) {
  init({
    dsn: 'https://718dc19aef5e4a77b8a7bf06cda52f75@sentry.io/90575',
    release: `${process.env.TURBO_HASH}`,
    integrations: [
      extraErrorDataIntegration(),
      globalHandlersIntegration({ onunhandledrejection: false }),
    ],
  });
}
