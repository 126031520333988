import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import CreateDockerSandboxForm from '@/modules/transformations/react/components/CreateDockerSandboxForm';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';

const CreateDockerSandboxModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    create: PropTypes.func.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
    onConfigurationChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      disabled: false,
    };
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.close} bsSize="large">
        <Modal.Header closeButton>
          <Modal.Title>Create {this.props.type} Sandbox</Modal.Title>
          <ModalIcon icon="gear" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          <CreateDockerSandboxForm
            tables={this.props.tables}
            buckets={this.props.buckets}
            type={this.props.type}
            onChange={this.props.onConfigurationChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveLabel="Create Sandbox"
            isDisabled={this.props.disabled}
            onSave={this.create}
          />
        </Modal.Footer>
      </Modal>
    );
  },
  create() {
    this.props.create();
    this.props.close();
  },
});

export default CreateDockerSandboxModal;
