import type { Map } from 'immutable';

import { Button, Icon } from '@keboola/design';

import { KEBOOLA_CSV_IMPORT as COMPONENT_ID } from '@/constants/componentIds';
import { GenericConfigBody } from '@/modules/components/react/pages/GenericConfigBody';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import actionsProvisioning from '@/modules/csv-import/actionsProvisioning';
import Settings from '@/modules/csv-import/react/components/Settings';
import Upload from '@/modules/csv-import/react/components/Upload';
import storeProvisioning from '@/modules/csv-import/storeProvisioning';
import { getDefaultTable } from '@/modules/csv-import/utils';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { routeNames as storageRouteNames } from '@/modules/storage/constants';
import { RouterLink } from '@/react/common';
import SaveButtons from '@/react/common/SaveButtons';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const Index = () => {
  const state = useStores(
    () => {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const store = storeProvisioning(configId);
      const actions = actionsProvisioning(configId);

      return {
        configId: configId,
        actions: actions,
        config: store.config,
        settings: store.settings,
        tables: StorageTablesStore.getAll() as Map<string, any>,
        buckets: StorageBucketsStore.getAll() as Map<string, any>,
        isUploaderFileBig: store.isUploaderFileBig,
        localState: store.getLocalState(),
        readOnly: ApplicationStore.isReadOnly(),
        isDevModeActive: DevBranchesStore.isDevModeActive(),
      };
    },
    [],
    [
      ApplicationStore,
      DevBranchesStore,
      InstalledComponentsStore,
      StorageTablesStore,
      StorageBucketsStore,
    ],
  );

  const renderUploader = () => {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">CSV File</h3>
        </div>
        <div className="box-content">
          {state.isDevModeActive ? (
            <div className="text-center">
              <p>
                Currently, it is not possible to upload files via CSV import in a development
                branch.
              </p>
              <p>
                If you want to upload data in a development branch, please do so via{' '}
                <RouterLink to={storageRouteNames.ROOT}>Storage</RouterLink>.
              </p>
              <p>
                However, this configuration can be used to upload data after the development branch
                is merged.
              </p>
            </div>
          ) : (
            <Upload
              disabled={state.readOnly}
              key={state.localState.get('fileInputKey', 0)}
              onChange={state.actions.setFile}
              file={state.localState.get('file')}
              isFileBig={state.isUploaderFileBig}
              isUploading={state.localState.get('isUploading', false)}
              uploadingMessage={state.localState.get('uploadingMessage', '')}
              uploadingProgress={state.localState.get('uploadingProgress', 0)}
              resultMessage={state.localState.get('resultMessage', '')}
              resultState={state.localState.get('resultState', '')}
              dismissResult={state.actions.dismissResult}
            />
          )}
        </div>
      </div>
    );
  };

  const renderSettings = () => {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Upload Settings</h3>
          {!state.readOnly && (
            <SaveButtons
              isSaving={state.localState.get('isSaving', false)}
              isChanged={state.localState.get('isChanged', false)}
              onReset={state.actions.editReset}
              onSave={state.actions.editSave}
            />
          )}
        </div>
        <div className="box-content">
          <Settings
            settings={state.settings}
            onChange={state.actions.editChange}
            tables={state.tables}
            buckets={state.buckets}
            defaultTable={getDefaultTable(state.configId)}
            disabled={state.readOnly || state.localState.get('isSaving', false)}
            destinationEditing={state.localState.get('isDestinationEditing', false)}
            onDestinationEdit={state.actions.destinationEdit}
          />
        </div>
      </div>
    );
  };

  const isUploadDisabled = () => {
    return (
      state.isDevModeActive ||
      !state.localState.get('file') ||
      state.localState.get('isChanged', false) ||
      state.localState.get('isUploading', false)
    );
  };

  return (
    <GenericConfigBody
      key={`${COMPONENT_ID}-${state.configId}`}
      componentId={COMPONENT_ID}
      configId={state.configId}
      sidebarProps={{
        hideJobs: true,
        run: (
          <Button block disabled={isUploadDisabled()} onClick={state.actions.startUpload}>
            <Icon icon="circle-play" fixedWidth />
            Upload
          </Button>
        ),
      }}
    >
      {renderUploader()}
      {renderSettings()}
    </GenericConfigBody>
  );
};

export default Index;
