import type { ReactNode } from 'react';
import { FormGroup, Radio } from 'react-bootstrap';

type Props<T> = {
  onChange: (option: T) => void;
  options: T[];
  value: T;
  renderLabel?: (value: T) => string;
  disabled?: boolean;
};

const SliderPicker = <T extends ReactNode>({
  onChange,
  options,
  value,
  renderLabel,
  disabled,
}: Props<T>) => {
  return (
    <FormGroup className="slider-picker">
      <div className="picker-values">
        {options.map((option) => (
          <Radio
            inline
            key={option}
            name="credits"
            onChange={() => onChange(option)}
            checked={value === option}
            disabled={disabled}
          >
            <span className="picker-label text-muted">{renderLabel?.(option) || option}</span>
          </Radio>
        ))}
      </div>
    </FormGroup>
  );
};

export default SliderPicker;
