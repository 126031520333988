import { type Map } from 'immutable';

import { Badge, Tooltip } from '@keboola/design';

import { TypedTableLabel } from '@/modules/storage/components/TypedTableLabel';
import { isTableShared } from '@/modules/storage/helpers';
import ExternalTableLabel from './ExternalTableLabel';

type Props = {
  table: Map<string, any>;
};

export const TableLabels = ({ table }: Props) => {
  if (!table || table.isEmpty()) return null;

  return (
    <div className="tw-ml-2 tw-inline-flex tw-gap-2">
      <TypedTableLabel isTyped={table.get('isTyped')} />
      <ExternalTableLabel
        tableType={table.get('tableType')}
        hasExternalSchema={table.getIn(['bucket', 'hasExternalSchema'])}
      />
      {isTableShared(table) && (
        <Tooltip
          tooltip="The object is being shared with other projects"
          placement="top"
          type="explanatory"
        >
          <Badge text="Shared" variant="purple-inverse" />
        </Tooltip>
      )}
    </div>
  );
};
