import { IconButton, Tooltip } from '@keboola/design';

const CodeEditorExpandButton = (props: {
  isCollapsed: boolean;
  onClick: () => void;
  inline?: boolean;
}) => {
  return (
    <Tooltip
      key="full-screen"
      placement="bottom"
      tooltip={`Switch to ${props.isCollapsed ? 'full' : 'half'} screen view`}
    >
      <IconButton
        variant={props.inline ? 'invisible' : 'outline'}
        onClick={() => {
          props.onClick();
          (document.activeElement as HTMLElement | null)?.blur();
        }}
        icon={
          props.isCollapsed
            ? 'up-right-and-down-left-from-center'
            : 'down-left-and-up-right-to-center'
        }
      />
    </Tooltip>
  );
};

export default CodeEditorExpandButton;
