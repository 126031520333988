import { useState } from 'react';
import { Promise } from 'bluebird';
import { Map } from 'immutable';
import memoizeOne from 'memoize-one';

import { Badge, Button, cn, Icon, IconButton, Tooltip } from '@keboola/design';

import type { VariableWithHash } from '@/api/routes/vaultService';
import { canManageVariable } from '@/modules/admin/privileges';
import Authorization from '@/modules/oauth-v2/react/Authorization';
import { deleteVariable, loadVariables, updateVariable } from '@/modules/vault/actions';
import { type FilterType, VARIABLE_TYPE } from '@/modules/vault/constants';
import { getVariablesByType } from '@/modules/vault/helpers';
import { RowActionDropdown, RowActionMenuItem, SortIcon, Truncated } from '@/react/common';
import ConfigurationWithComponent from '@/react/common/ConfigurationWithComponent';
import ConfirmModal from '@/react/common/ConfirmModal';
import MarkedText from '@/react/common/MarkedText';
import MultiActionsHeader from '@/react/common/MultiActionsHeader';
import MultiActionsSelectCheckbox from '@/react/common/MultiActionsSelectCheckbox';
import { matchByWords } from '@/utils';
import nextTick from '@/utils/nextTick';
import string from '@/utils/string';
import FullScreenTable from './FullScreenTable';
import { VaultSearch } from './VaultSearch';

const COLUMNS = { name: 'Name', configuration: 'Configuration' } as const;
type Sorter = { column: keyof typeof COLUMNS; order: -1 | 1 };

const getFilteredVariables = memoizeOne(
  (
    variables: VariableWithHash[],
    query: string,
    type: FilterType,
    allComponents: Map<string, any>,
    allConfigurations: Map<string, any>,
    allOauthCredentials: Map<string, any>,
  ) => {
    const filteredVariables = getVariablesByType(variables, type);

    if (!query) {
      return filteredVariables;
    }

    return filteredVariables.filter((variable) => {
      const oauthCredentials = allOauthCredentials.get(variable.value, Map());
      const componentName = allComponents.getIn([variable.attributes?.componentId, 'name'], '');
      const configurationName = allConfigurations.getIn(
        [variable.attributes?.componentId, 'configurations', variable.attributes?.configId, 'name'],
        '',
      );

      return matchByWords(
        [
          oauthCredentials.get('authorizedFor', ''),
          oauthCredentials.getIn(['creator', 'description']),
          componentName,
          configurationName,
        ],
        query,
      );
    });
  },
);

type Props = {
  sapiToken: Map<string, any>;
  variables: VariableWithHash[];
  allComponents: Map<string, any>;
  allConfigurations: Map<string, any>;
  allOauthCredentials: Map<string, any>;
  isDevModeActive: boolean;
  canManageVariables: boolean;
  currentDevBranchId: number | null;
  defaultDevBranchId: number;
};

const OauthVariablesTable = (props: Props) => {
  const [sort, setSort] = useState<Sorter>({ column: 'name', order: 1 });
  const [isCloning, setCloning] = useState(Map());
  const [selectedVariables, setSelectedVariables] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [variablesType, setVariablesType] = useState<FilterType>(VARIABLE_TYPE.ALL);
  const [authorizeModalData, setAuthorizeModalData] = useState<VariableWithHash | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const filteredVariables = getFilteredVariables(
    props.variables,
    searchQuery,
    variablesType,
    props.allComponents,
    props.allConfigurations,
    props.allOauthCredentials,
  );

  const isVariableSelectable = (variable: VariableWithHash) => {
    return props.canManageVariables && canManageVariable(props.sapiToken, variable);
  };

  const renderSortColumn = (column: keyof typeof COLUMNS) => {
    const isActive = sort.column === column;

    return (
      <span
        className="clickable"
        title={`Sort by ${COLUMNS[column]}`}
        onClick={() => setSort({ column, order: isActive ? (sort.order === 1 ? -1 : 1) : 1 })}
      >
        {COLUMNS[column]}
        <SortIcon
          isSorted={isActive}
          isSortedDesc={isActive && sort.order === -1}
          className="icon-addon-left"
        />
      </span>
    );
  };

  const getFilterPlaceholder = () => {
    return `Search authorizations (${filteredVariables.length})`;
  };

  const hasVariables = !!props.variables.length;
  const hasSelectableVariables = !!filteredVariables.filter(isVariableSelectable).length;

  return (
    <>
      <VaultSearch
        query={searchQuery}
        setSearchQuery={setSearchQuery}
        variablesType={variablesType}
        setVariablesType={setVariablesType}
        placeholder={getFilterPlaceholder()}
        isDevModeActive={props.isDevModeActive}
      />
      <FullScreenTable className="oauth-variables-table">
        {hasVariables && (
          <thead>
            <tr className="is-sticky bg-color-white">
              <th className="w-600">
                <MultiActionsHeader
                  entity="authorization"
                  hide={!hasSelectableVariables}
                  disabled={!hasSelectableVariables}
                  selectedCount={selectedVariables.length}
                  totalCount={filteredVariables.length}
                  onToggleAll={(checked) => {
                    setSelectedVariables(
                      checked
                        ? filteredVariables
                            .filter(isVariableSelectable)
                            .map((variable) => variable.hash)
                        : [],
                    );
                  }}
                  placeholder={renderSortColumn('name')}
                >
                  <div className="table-action-buttons">
                    <Tooltip placement="top" tooltip="Reset Selected">
                      <IconButton
                        variant="inline"
                        icon="arrow-rotate-left"
                        onClick={() => setShowResetModal(true)}
                      />
                    </Tooltip>
                    <Tooltip placement="top" tooltip="Delete Selected">
                      <IconButton
                        variant="inline"
                        icon="trash"
                        onClick={() => setShowDeleteModal(true)}
                      />
                    </Tooltip>
                  </div>
                </MultiActionsHeader>
              </th>
              <th>{renderSortColumn('configuration')}</th>
              <th className="w-200"></th>
            </tr>
          </thead>
        )}
        <tbody>
          {!hasVariables && (
            <tr className="no-hover">
              <td colSpan={3}>No variables created yet.</td>
            </tr>
          )}
          {hasVariables && !filteredVariables.length && (
            <tr className="no-hover">
              <td colSpan={3}>No variables found.</td>
            </tr>
          )}
          {filteredVariables
            .sort((a, b) => {
              if (sort.column === 'configuration') {
                const configurationNameA = props.allConfigurations.getIn(
                  [a.attributes?.componentId, 'configurations', a.attributes?.configId, 'name'],
                  '',
                );
                const configurationNameB = props.allConfigurations.getIn(
                  [b.attributes?.componentId, 'configurations', b.attributes?.configId, 'name'],
                  '',
                );

                return configurationNameA.localeCompare(configurationNameB) * sort.order;
              }

              const oauthCredentialsNameA = props.allOauthCredentials.getIn([
                a.value,
                'authorizedFor',
              ]);
              const oauthCredentialsNameB = props.allOauthCredentials.getIn([
                b.value,
                'authorizedFor',
              ]);

              return oauthCredentialsNameA?.localeCompare(oauthCredentialsNameB) * sort.order;
            })
            .map((variable) => {
              const key = `${variable.key}-${variable.attributes?.branchId}`;

              return (
                <OauthVariableRow
                  key={key}
                  variable={variable}
                  variables={props.variables}
                  searchQuery={searchQuery}
                  selected={selectedVariables}
                  setSelected={setSelectedVariables}
                  canManageVariables={props.canManageVariables}
                  startCloning={() => setCloning(isCloning.set(key, true))}
                  setAuthorizeModalData={setAuthorizeModalData}
                  defaultDevBranchId={props.defaultDevBranchId}
                  currentDevBranchId={props.currentDevBranchId}
                  canManageVariable={isVariableSelectable(variable)}
                  hasSelectableVariables={hasSelectableVariables}
                  allComponents={props.allComponents}
                  allConfigurations={props.allConfigurations}
                  allOauthCredentials={props.allOauthCredentials}
                />
              );
            })}
        </tbody>
      </FullScreenTable>
      <Authorization
        skipSave
        showModal={!!authorizeModalData}
        onModalHideFn={() => nextTick(() => setAuthorizeModalData(null))}
        onCompleteFn={({ id }: { id: string }) => {
          if (!authorizeModalData) return;

          updateVariable({ ...authorizeModalData, value: id }).then(loadVariables);
        }}
        branchId={authorizeModalData?.attributes?.branchId ?? null}
        componentId={authorizeModalData?.attributes?.componentId || ''}
        configId={authorizeModalData?.attributes?.configId}
      />
      <ResetOauthVariablesModal
        show={showResetModal}
        selected={selectedVariables}
        onConfirm={() => {
          return Promise.each(selectedVariables, (hash) => {
            const variable = props.variables.find((variable) => variable.hash === hash);
            return variable ? updateVariable({ ...variable, value: '' }) : null;
          })
            .then(() => setSelectedVariables([]))
            .then(loadVariables);
        }}
        onHide={() => setShowResetModal(false)}
      />
      <DeleteOauthVariablesModal
        show={showDeleteModal}
        selected={selectedVariables}
        onConfirm={() => {
          return Promise.each(selectedVariables, (hash) => {
            const variable = props.variables.find((variable) => variable.hash === hash);
            return variable ? deleteVariable(variable) : null;
          })
            .then(() => setSelectedVariables([]))
            .then(loadVariables);
        }}
        onHide={() => setShowDeleteModal(false)}
      />
    </>
  );
};

const OauthVariableRow = ({
  variable,
  variables,
  searchQuery,
  canManageVariable,
  hasSelectableVariables,
  defaultDevBranchId,
  currentDevBranchId,
  canManageVariables,
  selected,
  setSelected,
  startCloning,
  setAuthorizeModalData,
  allComponents,
  allConfigurations,
  allOauthCredentials,
}: {
  variable: VariableWithHash;
  variables: VariableWithHash[];
  searchQuery: string;
  canManageVariable: boolean;
  hasSelectableVariables: boolean;
  defaultDevBranchId: number;
  currentDevBranchId: number | null;
  canManageVariables: boolean;
  selected: string[];
  setSelected: (selected: string[]) => void;
  startCloning: () => void;
  setAuthorizeModalData: (variable: VariableWithHash) => void;
  allComponents: Map<string, any>;
  allConfigurations: Map<string, any>;
  allOauthCredentials: Map<string, any>;
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const isScoped = !!variable.attributes?.branchId;
  const isScopedToCurrentBranch =
    isScoped && variable.attributes?.branchId !== defaultDevBranchId.toString();
  const showCloneVariable =
    canManageVariables && !canManageVariable && isScoped && !isScopedToCurrentBranch;
  const currentBranchId = currentDevBranchId?.toString() ?? defaultDevBranchId.toString();
  const alreadyExistingInScope = variables.some((savedVariable) => {
    return (
      savedVariable.key === variable.key && savedVariable.attributes?.branchId === currentBranchId
    );
  });
  const oauthCredentials = allOauthCredentials.get(variable.value, Map());
  const name = oauthCredentials.get('authorizedFor');
  const email = oauthCredentials.getIn(['creator', 'description']);

  return (
    <tr className="variable-row">
      <td>
        <div className="flex-container flex-start">
          {hasSelectableVariables && (
            <span className="icon-addon-right">
              <MultiActionsSelectCheckbox
                entity="authorization"
                isDisabled={!canManageVariable}
                isChecked={selected.includes(variable.hash)}
                onToggle={(checked: boolean) => {
                  setSelected(
                    checked
                      ? [...selected, variable.hash]
                      : selected.filter((hash) => hash !== variable.hash),
                  );
                }}
              />
            </span>
          )}
          <div>
            <Truncated
              tooltip={name ? name : ''}
              text={
                name ? (
                  <MarkedText source={name} mark={searchQuery} />
                ) : canManageVariable ? (
                  <Button
                    variant="inline"
                    className="tw-text-sm"
                    icon="user"
                    onClick={() => setAuthorizeModalData(variable)}
                  >
                    Authorize Account
                  </Button>
                ) : (
                  'No Account authorized'
                )
              }
            />
            {name !== email && (
              <MarkedText
                source={email}
                mark={searchQuery}
                className="text-muted f-12 line-height-16"
              />
            )}
          </div>
        </div>
      </td>
      <td className="text-left">
        <ConfigurationWithComponent
          withLink
          openInNewTab
          component={allComponents.get(variable.attributes?.componentId, Map())}
          configuration={allConfigurations.getIn(
            [variable.attributes?.componentId, 'configurations', variable.attributes?.configId],
            Map(),
          )}
        />
      </td>
      <td>
        {isScoped && (
          <Badge variant={isScopedToCurrentBranch ? 'orange' : 'blue'}>
            {isScopedToCurrentBranch ? 'Branch Specific' : 'Production Only'}
          </Badge>
        )}
      </td>
      <td>
        {canManageVariable && (
          <RowActionDropdown inModal showLoading={isResetting || isDeleting}>
            {variable.value ? (
              <RowActionMenuItem
                onSelect={() => {
                  setIsResetting(true);
                  updateVariable({ ...variable, value: '' })
                    .then(loadVariables)
                    .finally(() => setIsResetting(false));
                }}
                disabled={isResetting}
              >
                <Icon fixedWidth icon="arrow-rotate-left" />
                Reset authorization
              </RowActionMenuItem>
            ) : (
              <RowActionMenuItem onSelect={() => setAuthorizeModalData(variable)}>
                <Icon fixedWidth icon="user" />
                Authorize account
              </RowActionMenuItem>
            )}
            <RowActionMenuItem divider />
            <RowActionMenuItem
              onSelect={() => {
                setIsDeleting(true);
                deleteVariable(variable)
                  .then(loadVariables)
                  .finally(() => setIsDeleting(false));
              }}
              disabled={isDeleting}
            >
              <Icon fixedWidth icon="trash" />
              Delete authorization
            </RowActionMenuItem>
          </RowActionDropdown>
        )}
        {showCloneVariable && (
          <Tooltip
            placement="top"
            type="explanatory"
            tooltip={
              alreadyExistingInScope ? (
                <>
                  Already Authorized
                  <br />
                  in Current Branch
                </>
              ) : (
                <>
                  Reauthorize
                  <br />
                  for Current Branch
                </>
              )
            }
          >
            <IconButton
              variant="inline"
              className={cn('text-muted', { disabled: alreadyExistingInScope })}
              icon="clone"
              onClick={() => !alreadyExistingInScope && startCloning()}
            />
          </Tooltip>
        )}
      </td>
    </tr>
  );
};

const ResetOauthVariablesModal = (props: {
  show: boolean;
  selected: string[];
  onHide: () => void;
  onConfirm: () => Promise<any>;
}) => {
  const [isDeleting, setDeleting] = useState(false);

  return (
    <ConfirmModal
      closeAfterResolve
      show={props.show}
      icon="arrow-rotate-left"
      title="Reset Selected"
      text={`Are you sure you want to reset the selected ${string.pluralize(
        props.selected.length,
        'authorization',
      )}?`}
      buttonLabel="Reset"
      buttonType="danger"
      isLoading={isDeleting}
      onConfirm={() => {
        setDeleting(true);
        return props.onConfirm().finally(() => setDeleting(false));
      }}
      onHide={props.onHide}
    />
  );
};

const DeleteOauthVariablesModal = (props: {
  show: boolean;
  selected: string[];
  onHide: () => void;
  onConfirm: () => Promise<any>;
}) => {
  const [isDeleting, setDeleting] = useState(false);

  return (
    <ConfirmModal
      closeAfterResolve
      show={props.show}
      icon="trash"
      title="Delete Selected"
      text={
        <>
          Are you sure you want to delete the <b>{props.selected.length}</b> selected{' '}
          {string.pluralize(props.selected.length, 'authorization')}?
        </>
      }
      buttonLabel="Delete"
      buttonType="danger"
      isLoading={isDeleting}
      onConfirm={() => {
        setDeleting(true);
        return props.onConfirm().finally(() => setDeleting(false));
      }}
      onHide={props.onHide}
    />
  );
};

export default OauthVariablesTable;
