import { type ReactNode, useCallback, useEffect, useState } from 'react';
import _ from 'underscore';

import { Icon, Tooltip } from '@keboola/design';

import type { UserMessage } from '@/api/routes/aiService';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { routeNames as flowsRouteNames } from '@/modules/flows/constants';
import useFlow from '@/modules/flows/hooks/useFlow';
import { RouterLink } from '@/react/common';
import PromptInput from '@/react/common/AI/PromptInput';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { injectButtonStyles } from '@/utils/external/productFruits';
import { injectPropsToChildren } from '@/utils/injectPropsToChildren';
import Message from './interactive-elements/Message';
import { constructMessage, isInteractiveMessage } from './helpers';
import { useAutomation, useAutomationMessages, useSendMessage } from './queries';

type Props = {
  children: ReactNode;
};

const Automation = (props: Props) => {
  const { automationId, config: flowConfig, configId: flowConfigId } = useFlow();
  const {
    taskId,
    componentId,
    configId,
    configuration,
    rowId,
    allComponents,
    allAdmins,
    sapiTokenId,
  } = useStores(
    () => {
      const taskId = RoutesStore.getRouterState()
        .getIn(['location', 'state', 'taskId'])
        ?.toString();
      const componentId = RoutesStore.getCurrentRouteComponentId();
      const configId = RoutesStore.getConfigId();
      const rowId = RoutesStore.getRowId();

      return {
        taskId,
        componentId,
        configId,
        configuration: InstalledComponentsStore.getConfigData(componentId, configId),
        rowId,
        allComponents: ComponentsStore.getAll().toJS(),
        allAdmins: ApplicationStore.getAdmins(),
        sapiTokenId: ApplicationStore.getSapiToken()?.get('id'),
      };
    },
    [],
    [ApplicationStore, RoutesStore, DevBranchesStore, ComponentsStore],
  );
  const [prompt, setPrompt] = useState('');
  const { data: automation, isLoading: isAutomationLoading } = useAutomation(automationId);
  const { data: messages, isLoading: isMessagesLoading } = useAutomationMessages(automationId);
  const { mutateAsync: sendMessageMutation, isPending: isSendingMessage } =
    useSendMessage(automationId);
  const isLoading = isAutomationLoading || isMessagesLoading || isSendingMessage;

  const flowConfiguration = flowConfig?.get('configuration')?.toJS();
  const admin = allAdmins?.get(automation?.createdByToken?.name || '');
  const readOnly = sapiTokenId !== automation?.createdByToken?.id;

  const submitMessage = useCallback(
    async (messageObject: Partial<UserMessage>) => {
      if (!automationId) return;

      const message = constructMessage(
        messageObject,
        {
          flowConfiguration,
          taskId,
          componentId,
          configurationId: configId,
          configuration,
          rowId,
        },
        allComponents,
      );

      if (automation?.id && (message.text?.trim() || !_.isEmpty(message.data))) {
        setPrompt((prevPrompt) => (prevPrompt === message.text ? '' : prevPrompt));
        await sendMessageMutation(message);
      }
    },
    [
      flowConfiguration,
      taskId,
      componentId,
      configId,
      rowId,
      configuration,
      automation,
      allComponents,
      sendMessageMutation,
      automationId,
    ],
  );

  useEffect(() => {
    if (!automationId) return;

    if (window.productFruitsIsReady) {
      injectButtonStyles({ left: '15px' });
    } else {
      window.productFruitsReady = () => injectButtonStyles({ left: '15px' });
    }

    setTimeout(() => {
      const jiraWidgetElement = window.document.querySelector<HTMLElement>('#jsd-widget');

      if (!jiraWidgetElement) return;

      jiraWidgetElement.style.left = '26px';
    }, 1000);

    return () => injectButtonStyles({ left: 'unset' });
  }, [automationId]);

  if (!flowConfigId || !automationId) return props.children;

  return (
    <div className="tw-flex tw-h-full tw-overflow-hidden">
      <div className="tw-grow tw-overflow-hidden [&_.container]:tw-w-full">
        <div className="tw-flex tw-h-full tw-flex-col tw-overflow-hidden tw-rounded-lg">
          <div className="tw-relative tw-grow tw-overflow-y-auto tw-overflow-x-hidden tw-bg-neutral-50 tw-px-6 tw-pb-7 [&_.box-separator:first-child]:tw-mt-0 [&_.col-sm-9:has(+.col-sm-3:empty)]:tw-w-full">
            {injectPropsToChildren(props.children, {
              submitAutomationMessage: submitMessage,
            })}
          </div>
        </div>
      </div>
      <div className="tw-flex tw-w-[420px] tw-shrink-0 tw-flex-col tw-gap-3 tw-overflow-hidden tw-bg-white tw-p-4 tw-pt-3">
        <div className="tw-flex tw-items-center tw-justify-between">
          <h4 className="tw-my-0 tw-uppercase">Keboola AI Agent</h4>
          <Tooltip tooltip="Back to flows list">
            <RouterLink className="btn btn-default" to={flowsRouteNames.ROOT} onlyActiveOnIndex>
              <Icon icon="xmark" />
            </RouterLink>
          </Tooltip>
        </div>
        <div className="-tw-mx-5 tw-flex tw-grow tw-flex-col tw-gap-2 tw-overflow-x-hidden tw-overflow-y-scroll tw-px-5 tw-break-anywhere">
          {messages?.map((messageObject, index) => {
            if (
              messageObject.text ||
              (messageObject.data &&
                ['componentSelectionResponse', 'sourceTableSelectionResponse'].includes(
                  messageObject.type,
                ))
            ) {
              return (
                <Message
                  key={index}
                  automationId={automationId}
                  admin={admin}
                  messageObject={messageObject}
                  submitMessage={submitMessage}
                  flowConfigId={flowConfigId}
                  isLastMessage={!isLoading && index === messages.length - 1}
                />
              );
            }

            return null;
          })}
          {isLoading && <Message isLastMessage />}
        </div>
        <Tooltip tooltip="You can only edit automations created by you." forceHide={!readOnly}>
          <PromptInput
            autoFocus
            multiLine
            placeholder="Write Message"
            prompt={prompt}
            onChange={setPrompt}
            onSubmit={() => submitMessage({ text: prompt })}
            isDisabled={!prompt.trim() || isLoading}
            isDisabledInput={readOnly || isInteractiveMessage(messages?.at(-1))}
            className="tw-mx-auto tw-w-full tw-max-w-4xl"
            variant="narrow"
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default Automation;
