import { type Map } from 'immutable';

import { Badge, Tooltip } from '@keboola/design';

import { hasBucketExternalSchema, isBucketLinked, isBucketShared } from '@/modules/storage/helpers';
import BucketStageLabel from './BucketStageLabel';

type Props = {
  bucket: Map<string, any>;
  withStageLabel?: boolean;
  withSharedLabel?: boolean;
};

export const BucketLabels = ({ bucket, withStageLabel = true, withSharedLabel = true }: Props) => {
  if (!bucket || bucket.isEmpty()) return null;
  const stage = bucket.get('stage');

  return (
    <div className="tw-ml-2 tw-inline-flex tw-gap-2">
      {withStageLabel && Boolean(stage) && <BucketStageLabel stage={stage} round />}
      {hasBucketExternalSchema(bucket) && <Badge text="External" variant="blue-inverse" />}
      {withSharedLabel && isBucketShared(bucket) && (
        <Tooltip
          tooltip="The object is being shared with other projects"
          placement="top"
          type="explanatory"
        >
          <Badge text="Shared" variant="purple-inverse" />
        </Tooltip>
      )}
      {isBucketLinked(bucket) && (
        <Tooltip
          tooltip="The object is linked in at least one other project"
          placement="top"
          type="explanatory"
        >
          <Badge text="Linked" variant="green-inverse" />
        </Tooltip>
      )}
    </div>
  );
};
