import type { ComponentProps } from 'react';

import { cn, Icon } from '@keboola/design';

import { JOBS_STATUS } from '@/modules/queue/constants';

const ICON_MAP: Record<string, ComponentProps<typeof Icon>['icon']> = {
  [JOBS_STATUS.CREATED]: 'circle-dot',
  [JOBS_STATUS.WAITING]: 'spinner',
  [JOBS_STATUS.PROCESSING]: 'spinner',
  [JOBS_STATUS.SUCCESS]: 'check-circle',
  [JOBS_STATUS.ERROR]: 'exclamation-circle',
  warn: 'exclamation-circle',
  [JOBS_STATUS.WARNING]: 'exclamation-circle',
  canceled: 'times-circle',
  [JOBS_STATUS.CANCELLED]: 'times-circle',
  [JOBS_STATUS.TERMINATED]: 'times-circle',
  [JOBS_STATUS.TERMINATING]: 'times-circle',
};

const JobStatusIcon = (props: { status: string; className?: string }) => {
  const icon = ICON_MAP[props.status] ?? 'circle';

  return (
    <Icon
      icon={icon}
      spin={icon === 'spinner'}
      className={cn(`status-circle`, props.status, props.className)}
    />
  );
};

export default JobStatusIcon;
