import { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { Map } from 'immutable';

import { cn, Icon } from '@keboola/design';

import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';
import SearchContextDropdownMenu from './SearchContextDropdownMenu';

class SearchContextDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  render() {
    return (
      <Dropdown
        id="search-context-dropdown"
        open={this.state.isOpen}
        onToggle={(isOpen) => this.setState({ isOpen })}
      >
        <Dropdown.Toggle noCaret className={cn({ active: this.state.isOpen })}>
          <Icon fixedWidth icon="warehouse" className="text-muted btn-icon" />
          Open Storage Tree
        </Dropdown.Toggle>
        <Dropdown.Menu className="overflow-break-anywhere">
          {(this.props.searchQuery || !this.props.searchFilters.isEmpty()) && (
            <li className="flex-container no-wrap query-row">
              <div className="flex-container flex-start color-primary">
                <Icon fixedWidth icon="magnifying-glass" className="f-18 icon-addon-right" />
                Searched by:&nbsp;<b>{this.getSearchBy()}</b>
              </div>
              <ActivateDeactivateSwitch
                isPending={false}
                buttonDisabled={false}
                tooltipPlacement="top"
                isActive={this.props.contextFilter}
                onChange={this.props.toggleContextFilter}
                activateTooltip="Active filter query"
                deactivateTooltip="Deactivate filter query"
              />
            </li>
          )}
          <SearchContextDropdownMenu
            tables={this.props.tables}
            buckets={this.props.buckets}
            searchQuery={this.props.searchQuery}
            searchFilters={this.props.searchFilters}
            activeBucket={this.props.activeBucket}
            contextFilter={this.props.contextFilter}
            activeTable={this.props.activeTable}
            isOpen={this.state.isOpen}
            activeTab={this.props.activeTab}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  getSearchBy() {
    const filters = this.props.searchFilters
      .valueSeq()
      .map((filter) => filter.toUpperCase())
      .reduce((prev, curr) => [prev, ', ', curr]);

    if (this.props.searchQuery && !this.props.searchFilters.isEmpty()) {
      return `${this.props.searchQuery}, ${filters}`;
    }

    return !this.props.searchFilters.isEmpty() ? filters : this.props.searchQuery;
  }
}

SearchContextDropdown.propTypes = {
  tables: PropTypes.instanceOf(Map).isRequired,
  buckets: PropTypes.instanceOf(Map).isRequired,
  searchFilters: PropTypes.instanceOf(Map).isRequired,
  searchQuery: PropTypes.string.isRequired,
  activeBucket: PropTypes.string.isRequired,
  contextFilter: PropTypes.bool.isRequired,
  toggleContextFilter: PropTypes.func.isRequired,
  activeTable: PropTypes.string,
  activeTab: PropTypes.string,
};

export default SearchContextDropdown;
