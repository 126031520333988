import { Modal } from 'react-bootstrap';
import type { Map } from 'immutable';

import { Alert } from '@keboola/design';

import keyCodes from '@/constants/keyCodes';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import DevBranchStorageWarning from './DevBranchStorageWarning';

type Props = {
  show: boolean;
  bucket: Map<string, any>;
  deleting: boolean;
  onConfirm: () => void;
  onHide: () => void;
  tables: Map<string, any>;
};

const DeleteBucketModal = (props: Props) => {
  const renderWarning = () => {
    if (props.bucket.has('sourceBucket') || props.tables.count() === 0) {
      return null;
    }

    return (
      <Alert variant="warning" className="tw-mb-5">
        The bucket is not empty. All tables will also be deleted!
      </Alert>
    );
  };

  const saveLabel = () => {
    if (props.bucket.has('sourceBucket')) {
      return props.deleting ? 'Unlinking...' : 'Unlink';
    }

    return props.deleting ? 'Deleting...' : 'Delete';
  };

  return (
    <span
      className="tw-hidden"
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.key === keyCodes.ENTER && e.stopPropagation()}
    >
      <Modal show={props.show} onHide={props.onHide}>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            return props.onConfirm();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <span>{props.bucket.has('sourceBucket') ? 'Unlink bucket' : 'Delete bucket'}</span>
            </Modal.Title>
            <ModalIcon.Trash />
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to {props.bucket.has('sourceBucket') ? 'unlink' : 'delete'} the
              bucket <b>{props.bucket.get('displayName')}</b>?
            </p>
            <DevBranchStorageWarning
              message={`The bucket will also be ${
                props.bucket.has('sourceBucket') ? 'unlinked' : 'deleted'
              } in production. `}
              hasProductionEntity={!isCreatedInDevBranch(props.bucket)}
            />
            {renderWarning()}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              isSaving={props.deleting}
              saveLabel={saveLabel()}
              saveStyle="danger"
              saveButtonType="submit"
            />
          </Modal.Footer>
        </form>
      </Modal>
    </span>
  );
};

export default DeleteBucketModal;
