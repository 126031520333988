import PropTypes from 'prop-types';

import { Icon, Link } from '@keboola/design';

const DocumentationLink = ({ href }) => (
  <Link href={href}>
    <Icon icon="book-blank" fixedWidth />
    Documentation
  </Link>
);

DocumentationLink.propTypes = {
  href: PropTypes.string.isRequired,
};

export default DocumentationLink;
