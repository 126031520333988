import { Component, createRef } from 'react';

import { cn } from '@keboola/design';
import * as Flow from '@keboola/flow-builder';

import TaskIcon from './TaskIcon';
import TaskName from './TaskName';

type Props = {
  name: string;
  componentName: string;
  componentType: string;
  iconUrl: string;
  isBlank: boolean;
  isDragged: boolean;
  hasDeletedConfiguration: boolean;
};

export default class FloatingTask extends Component<Props> {
  ref = createRef<HTMLDivElement>();

  move = (position: [number, number]) => {
    const node = this.ref.current;
    if (node) {
      node.style.left = `${position[0]}px`;
      node.style.top = `${position[1]}px`;
    }
  };

  getPosition(): [number, number] {
    const node = this.ref.current;
    if (node) {
      return [parseFloat(node.style.left), parseFloat(node.style.top)];
    } else {
      return [0, 0];
    }
  }

  render() {
    return (
      <Flow.Node
        ref={this.ref}
        name="floating-task"
        className={cn('drag-target with-border', {
          'has-blank-configuration': this.props.isBlank,
          'has-deleted-configuration': this.props.hasDeletedConfiguration,
          'is-dragged': this.props.isDragged,
          'display-none': !this.props.isDragged,
        })}
        position={[0, 0]}
      >
        <div className="node">
          <TaskIcon src={this.props.iconUrl} />
          <TaskName
            name={this.props.name}
            componentName={this.props.componentName}
            componentType={this.props.componentType}
            isBlank={this.props.isBlank}
            isDragged={this.props.isDragged}
            isDeleted={this.props.hasDeletedConfiguration}
          />
        </div>
      </Flow.Node>
    );
  }
}
