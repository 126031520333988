import { Icon, Link } from '@keboola/design';

import { INVOICE_STATUS } from '@/modules/billing/constants';
import { convertCreditsToMinutes, formatPrice } from '@/modules/billing/helpers';
import type { Purchase } from '@/modules/billing/store';
import { CreatedDate } from '@/react/common';
import LazyList from '@/react/common/LazyList';

export const LatestPurchases = (props: { purchases: Purchase[] }) => {
  if (!props.purchases.length) {
    return null;
  }

  const renderStatus = (purchase: Purchase) => {
    switch (purchase.stripeInvoiceStatus) {
      case null:
      case INVOICE_STATUS.PAID:
        return <span className="text-success">Paid</span>;

      case INVOICE_STATUS.OPEN:
        return <span className="text-warning">Open</span>;

      case INVOICE_STATUS.UNCOLLECTIBLE:
        return <span className="text-danger">Fail</span>;
    }

    return <span className="text-muted">N/A</span>;
  };

  const renderBody = (purchases: Purchase[]) => {
    return purchases.map((purchase) => (
      <tr key={purchase.id}>
        <td>
          <CreatedDate createdTime={purchase.created} />
          {!purchase.moneyAmount && (
            <>
              {' '}
              <strong className="text-success">{purchase.description}</strong>
            </>
          )}
        </td>
        <td className="w-175 text-right font-medium text-muted">
          {convertCreditsToMinutes(purchase.creditsAmount)}
        </td>
        <td className="w-175 text-right font-medium text-muted">
          {formatPrice(purchase.moneyAmount ? purchase.moneyAmount / 100 : 0)}
        </td>
        <td className="w-100 text-center">
          {purchase.urlStripeInvoice && (
            <Link
              href={purchase.urlStripeInvoice}
              className="text-muted font-medium no-underline no-wrap"
            >
              <Icon icon={['far', 'arrow-down-to-line']} className="icon-addon-left" />
            </Link>
          )}
        </td>
        <td className="w-100 text-right font-medium">{renderStatus(purchase)}</td>
      </tr>
    ));
  };

  return (
    <div className="box latest-purchases">
      <div className="box-header above-table">
        <h2 className="box-title">Latest Purchases</h2>
      </div>
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Date</th>
              <th className="w-175 text-right">Minutes</th>
              <th className="w-175 text-right">Amount (ext. VAT)</th>
              <th className="w-100 text-center">Invoice</th>
              <th className="w-100 text-right">Status</th>
            </tr>
          </thead>
          <LazyList
            limit={10}
            rootElement="tbody"
            className="letter-spacing-narrower"
            items={props.purchases}
            render={renderBody}
          />
        </table>
      </div>
    </div>
  );
};
