import PropTypes from 'prop-types';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import { Badge, cn } from '@keboola/design';

import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import * as actionsProvisioning from '@/modules/ex-mongodb/actionsProvisioning';
import { routeNames } from '@/modules/ex-mongodb/constants';
import QueryDeleteButton from '@/modules/ex-mongodb/react/components/QueryDeleteButton';
import { RouterLink as Link, RowActionDropdown, RowActionMenuItem } from '@/react/common';
import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';
import Check from '@/react/common/Check';

const QueryRow = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    query: PropTypes.object.isRequired,
    pendingActions: PropTypes.object.isRequired,
    configurationId: PropTypes.string.isRequired,
    componentId: PropTypes.string.isRequired,
  },

  handleActiveChange: function (newValue) {
    const actionCreators = actionsProvisioning.createActions(this.props.componentId);
    return actionCreators.changeQueryEnabledState(
      this.props.configurationId,
      this.props.query.get('id'),
      newValue,
    );
  },

  render: function () {
    const actionCreators = actionsProvisioning.createActions(this.props.componentId);
    const { configurationId, query, pendingActions, componentId } = this.props;

    return (
      <Link
        className={cn('tr hoverable-actions', { 'row-disabled': !query.get('enabled') })}
        to={routeNames.QUERY}
        params={{
          config: configurationId,
          query: query.get('id'),
        }}
      >
        <span className="td overflow-break-anywhere">
          {query.get('name') ? query.get('name') : <span className="text-muted">Untitled</span>}
        </span>
        <span className="td">
          <Badge text={query.get('type')} />
        </span>
        <span className="td">
          <Check isChecked={query.get('incremental', false)} />
        </span>
        <span className="td pr-1">
          {!this.props.readOnly && (
            <RowActionDropdown
              showLoading={!!pendingActions.get('deleteQuery') || !!pendingActions.get('enabled')}
            >
              <RunComponentButton
                mode="menuitem"
                title="Run Export"
                componentId={componentId}
                runParams={() => {
                  return {
                    config: configurationId,
                    configData: actionCreators.prepareSingleQueryRunData(configurationId, query),
                  };
                }}
              >
                You are about to run an extraction.
              </RunComponentButton>
              <RowActionMenuItem divider />
              <ActivateDeactivateSwitch
                mode="menuitem"
                isActive={query.get('enabled')}
                isPending={pendingActions.get('enabled')}
                onChange={this.handleActiveChange}
              />
              <RowActionMenuItem divider />
              <QueryDeleteButton
                mode="menuitem"
                query={query}
                componentId={componentId}
                configurationId={configurationId}
                isPending={pendingActions.get('deleteQuery') || false}
                actionsProvisioning={actionsProvisioning}
              />
            </RowActionDropdown>
          )}
        </span>
      </Link>
    );
  },
});

export default QueryRow;
