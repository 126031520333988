import { cva } from 'class-variance-authority';

import { cn } from '../../utils';

export const cardClassnames = cva(
  [
    'tw-bg-white',
    'tw-flex',
    'tw-flex-col',
    'tw-rounded-lg',
    'tw-gap-4',
    'tw-shadow-[0_2px_6px_0_rgba(100,135,153,0.13)]',
  ],
  {
    defaultVariants: {
      padding: 'small',
    },
    variants: {
      padding: {
        small: 'tw-px-6 tw-py-4',
        medium: 'tw-p-7',
        large: 'tw-p-8',
      },
    },
  },
);

export const cardTitleClassnames = cn(
  'tw-m-0',
  'tw-leading-6',
  'tw-font-medium',
  'tw-text-base',
  'tw-neutral-800',
);
