import { Component } from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

import { HelpBlock } from '@keboola/design';

import Checkbox from '@/react/common/Checkbox';

class Schema extends Component {
  render() {
    return (
      <div className="box">
        <div className="box-header with-border big-padding">
          <h3 className="box-title">Schema</h3>
        </div>
        <div className="box-content">
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Name</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl
                type="text"
                value={this.props.schema_name}
                onChange={(e) => this.props.onChange({ schema_name: e.target.value })}
                disabled={this.props.disabled}
              />
              <HelpBlock className="tw-mt-1">Name of the schema to be created.</HelpBlock>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col-xs-8 col-xs-offset-4">
              <Checkbox
                checked={this.props.reset_password}
                onChange={(checked) => this.props.onChange({ reset_password: checked })}
                disabled={this.props.disabled}
              >
                Reset password
              </Checkbox>
            </div>
          </FormGroup>
        </div>
      </div>
    );
  }
}

Schema.propTypes = {
  schema_name: PropTypes.string.isRequired,
  reset_password: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Schema;
