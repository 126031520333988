import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import installedComponentsActions from '@/modules/components/InstalledComponentsActionCreators';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import OrchestrationsActionCreators from '@/modules/orchestrations/ActionCreators';
import mergeTasksWithConfigurations from '@/modules/orchestrations/mergeTasksWithConfigurations';
import OrchestrationStore from '@/modules/orchestrations/stores/OrchestrationsStore';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import TasksEditor from './TasksEditor';
import TasksTable from './TasksTable';

const componentId = 'orchestrations';

const OrchestrationTasks = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      OrchestrationStore,
      ComponentsStore,
      InstalledComponentsStore,
    ),
  ],

  getStateFromStores() {
    let tasks;
    const orchestrationId = RoutesStore.getCurrentRouteIntParam('orchestrationId');
    const readOnly = ApplicationStore.isReadOnly();

    const isEditing = OrchestrationStore.isEditing(orchestrationId, 'tasks');
    if (!readOnly && isEditing) {
      tasks = OrchestrationStore.getEditingValue(orchestrationId, 'tasks');
    } else {
      tasks = OrchestrationStore.getOrchestrationTasks(orchestrationId);
    }

    return {
      readOnly,
      isEditing,
      orchestrationId,
      localState: InstalledComponentsStore.getLocalState(componentId, orchestrationId) || Map(),
      orchestration: OrchestrationStore.get(orchestrationId),
      tasks: mergeTasksWithConfigurations(tasks, InstalledComponentsStore.getAll()),
      components: ComponentsStore.getAll(),
      isSaving: OrchestrationStore.isSaving(orchestrationId, 'tasks'),
    };
  },

  componentDidMount() {
    // start edit if orchestration is empty
    if (!this.state.isEditing && this.state.tasks.count() === 0) {
      return OrchestrationsActionCreators.startOrchestrationTasksEdit(
        this.state.orchestration.get('id'),
      );
    }
  },

  UNSAFE_componentWillReceiveProps() {
    return this.setState(this.getStateFromStores());
  },

  _handleTasksChange(newTasks) {
    return OrchestrationsActionCreators.updateOrchestrationsTasksEdit(
      this.state.orchestration.get('id'),
      newTasks,
    );
  },

  _handleTaskRun(task) {
    let tasks;
    if (this.state.tasks.count()) {
      tasks = this.state.tasks.map((phase) =>
        phase.set(
          'tasks',
          phase.get('tasks').map((item) => {
            if (item.get('id') === task.get('id')) {
              return item.set('active', true);
            } else {
              return item.set('active', false);
            }
          }),
        ),
      );
    } else {
      tasks = List();
    }

    return OrchestrationsActionCreators.runOrchestration(
      this.state.orchestration.get('id'),
      tasks,
      true,
    );
  },

  render() {
    return <div className="box">{this.renderTasks()}</div>;
  },

  renderTasks() {
    if (!this.state.readOnly && this.state.isEditing) {
      return (
        <TasksEditor
          tasks={this.state.tasks}
          isSaving={this.state.isSaving}
          components={this.state.components}
          onChange={this._handleTasksChange}
          localState={this.state.localState.get('taskstable', Map())}
          updateLocalState={(path, data) => {
            return this.updateLocalState(['taskstable'].concat(path), data);
          }}
        />
      );
    }

    return (
      <TasksTable
        tasks={this.state.tasks}
        orchestration={this.state.orchestration}
        components={this.state.components}
        onRun={this._handleTaskRun}
        localState={this.state.localState.get('taskstable', Map())}
        updateLocalState={(path, data) => {
          return this.updateLocalState(['taskstable'].concat(path), data);
        }}
        readOnly={this.state.readOnly}
      />
    );
  },

  updateLocalState(path, data) {
    let newState;
    if (data !== null) {
      newState = this.state.localState.setIn([].concat(path), data);
    } else {
      newState = this.state.localState.deleteIn([].concat(path), data);
    }

    return installedComponentsActions.updateLocalState(
      componentId,
      this.state.orchestrationId,
      newState,
      path,
    );
  },
});

export default OrchestrationTasks;
