import { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';
import { fromJS, List, Map } from 'immutable';

import { Icon } from '@keboola/design';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import dayjs, { TIME_FORMAT } from '@/date';
import StorageApi from '@/modules/components/StorageApi';
import { createTrigger, deleteTrigger, updateTrigger } from '@/modules/event-trigger/actions';
import EventTrigger from '@/modules/event-trigger/components/EventTrigger';
import { crontabDefault, defaultTrigger } from '@/modules/event-trigger/constants';
import {
  createAndActivateScheduler,
  removeScheduler,
  updateScheduler,
} from '@/modules/scheduler/actions';
import CronScheduler from '@/modules/scheduler/components/CronScheduler';
import CronSchedulerPreview from '@/modules/scheduler/components/CronSchedulerPreview';
import SchedulePredefinedButtons from '@/modules/scheduler/components/SchedulePredefinedButtons';
import ScheduleTimezone from '@/modules/scheduler/components/ScheduleTimezone';
import { scheduleInvokeType } from '@/modules/scheduler/constants';
import { getPeriodForCrontab, getSingleScheduler } from '@/modules/scheduler/helpers';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';

class ScheduleModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    orchestration: PropTypes.instanceOf(Map).isRequired,
    tables: PropTypes.instanceOf(Map).isRequired,
    buckets: PropTypes.instanceOf(Map).isRequired,
    trigger: PropTypes.instanceOf(Map).isRequired,
    canManageSchedule: PropTypes.bool,
    canManageTriggers: PropTypes.bool,
    hasProtectedDefaultBranch: PropTypes.bool,
    componentName: PropTypes.string,
  };

  static defaultProps = {
    componentName: 'Orchestration',
    canManageSchedule: true,
    canManageTriggers: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      trigger: fromJS(defaultTrigger).merge(this.props.trigger),
      showTriggerMode:
        !this.props.trigger.isEmpty() || (!props.canManageSchedule && props.canManageTriggers),
      ...this.getCronTabData(),
    };
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => this.setState(this.getCronTabData())}
        className="scheduler-modal"
      >
        <Modal.Header className="no-border" closeButton>
          <Modal.Title>{`${this.props.componentName} Schedule`}</Modal.Title>
          <ModalIcon icon="clock" color="green" bold />
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="schedule-form">
            {this.renderInvokeSelect()}
            {this.renderForm()}
          </div>
        </Modal.Body>
        <Modal.Footer>{this.renderButtons()}</Modal.Footer>
      </Modal>
    );
  }

  renderForm() {
    if (this.state.showTriggerMode) {
      return (
        <EventTrigger
          hasDataStreams={false}
          tables={this.props.tables}
          buckets={this.props.buckets}
          selected={this.state.trigger
            .get('tables', List())
            .map((table) => table.get('tableId'))
            .toArray()}
          period={this.state.trigger.get('coolDownPeriodMinutes').toString()}
          onAddTable={this.handleTriggerTableAdd}
          onRemoveTable={this.handleTriggerTableRemove}
          onChangePeriod={this.handleTriggerPeriodChange}
        />
      );
    }

    return (
      <>
        <SchedulePredefinedButtons
          onSelect={(cronTab) => {
            this.setState({ cronTab, cronTabPeriod: getPeriodForCrontab(cronTab) });
          }}
        />
        <CronScheduler
          crontabRecord={this.state.cronTab}
          crontabTimezone={this.state.timezone}
          period={this.state.cronTabPeriod}
          onChange={(cronTab, cronTabPeriod) => {
            this.setState({ cronTab, ...(cronTabPeriod && { cronTabPeriod }) });
          }}
        />
        <div className="flex-container text-muted">
          <span>Current time is {dayjs.utc().format(TIME_FORMAT)} UTC.</span>
          <ScheduleTimezone
            onChange={(timezone) => this.setState({ timezone })}
            crontabTimezone={this.state.timezone}
          />
        </div>
        <CronSchedulerPreview
          crontabRecord={this.state.cronTab}
          timezone={this.state.timezone || 'UTC'}
        />
      </>
    );
  }

  renderButtons() {
    const label = this.state.showTriggerMode ? 'Trigger' : 'Schedule';
    const isRemoveButtonDisabled =
      !!this.props.hasProtectedDefaultBranch &&
      (this.state.showTriggerMode ? !this.props.canManageTriggers : !this.props.canManageSchedule);

    return (
      <ButtonToolbar className="block">
        {this.shouldShowRemoveButton() && (
          <Button bsStyle="danger" onClick={this.handleRemove} disabled={isRemoveButtonDisabled}>
            Remove {label}
          </Button>
        )}
        <Button
          type="submit"
          bsStyle="success"
          onClick={this.handleSave}
          disabled={this.isSaveButtonDisabled()}
        >
          {this.state.isSaving ? (
            <Loader className="icon-addon-right" />
          ) : (
            <Icon icon="arrow-right" className="icon-addon-right" />
          )}
          Set Up {label}
        </Button>
      </ButtonToolbar>
    );
  }

  renderInvokeSelect = () => {
    return (
      <div className="form-group mb-2">
        <Select
          searchable={false}
          clearable={false}
          options={[
            {
              label: 'Date & Time',
              value: scheduleInvokeType.TIME,
              isDisabled: !this.props.canManageSchedule,
              disabledReason: !this.props.canManageSchedule
                ? 'Time schedule can be set up only in the development branch by a developer.'
                : '',
            },
            {
              label: 'Triggered',
              value: scheduleInvokeType.EVENT,
              isDisabled: !this.props.canManageTriggers,
              disabledReason: !this.props.canManageTriggers
                ? 'Triggers can be set up in the production by a production manager and only if there is not time schedule set up.'
                : '',
            },
          ]}
          value={this.state.showTriggerMode ? scheduleInvokeType.EVENT : scheduleInvokeType.TIME}
          onChange={(invokeType) =>
            this.setState({ showTriggerMode: scheduleInvokeType.EVENT === invokeType })
          }
          disabled={this.state.isSaving}
        />
      </div>
    );
  };

  handleTriggerTableAdd = (tableId) => {
    this.setState((state) => ({
      trigger: state.trigger.set(
        'tables',
        state.trigger.get('tables', List()).push(Map({ tableId })),
      ),
    }));
  };

  handleTriggerTableRemove = (tableId) => {
    this.setState((state) => ({
      trigger: state.trigger.update('tables', List(), (tables) =>
        tables.filter((table) => table.get('tableId') !== tableId),
      ),
    }));
  };

  handleTriggerPeriodChange = ({ target }) => {
    this.setState((state) => ({
      trigger: state.trigger.set('coolDownPeriodMinutes', parseInt(target.value, 10)),
    }));
  };

  isSaveButtonDisabled() {
    if (this.state.isSaving) {
      return true;
    }

    if (this.state.showTriggerMode) {
      return (
        this.state.trigger.get('tables', List()).isEmpty() ||
        isNaN(this.state.trigger.get('coolDownPeriodMinutes')) ||
        this.state.trigger.get('coolDownPeriodMinutes') < 1
      );
    }

    return false;
  }

  shouldShowRemoveButton() {
    if (this.state.isSaving) return false;
    if (this.state.showTriggerMode) return !this.props.trigger.isEmpty();

    return !!this.findScheduler().getIn(['configuration', 'schedule', 'cronTab']);
  }

  handleSave = () => {
    this.setState({ isSaving: true });
    const promise = this.state.showTriggerMode
      ? this.saveTrigger().then(() => this.handleRemoveScheduler())
      : this.saveScheduler().then(() => this.handleRemoveTrigger());
    return promise.then(this.props.onHide).finally(() => this.setState({ isSaving: false }));
  };

  saveScheduler() {
    const scheduler = this.findScheduler();
    const schedule = {
      cronTab: this.state.cronTab,
      timezone: this.state.timezone || 'UTC',
    };

    if (scheduler.isEmpty()) {
      return createAndActivateScheduler(this.props.orchestration.get('id'), schedule);
    }

    return updateScheduler(
      scheduler.get('id'),
      scheduler.mergeIn(['configuration', 'schedule'], schedule),
    );
  }

  saveTrigger() {
    const options = {
      tableIds: this.state.trigger
        .get('tables', List())
        .map((table) => table.get('tableId'))
        .toArray(),
      coolDownPeriodMinutes: this.state.trigger.get('coolDownPeriodMinutes'),
    };

    if (this.props.trigger.isEmpty()) {
      return StorageApi.createTriggerToken(this.props.orchestration.get('id')).then((token) =>
        createTrigger({
          component: KEBOOLA_ORCHESTRATOR,
          configurationId: this.props.orchestration.get('id'),
          runWithTokenId: token.id,
          ...options,
        }),
      );
    }

    return updateTrigger(this.props.trigger.get('id'), options);
  }

  getCronTabData = () => {
    const scheduleConfig = this.findScheduler().getIn(['configuration', 'schedule'], Map());
    const cronTab = scheduleConfig.get('cronTab') || crontabDefault();

    return {
      cronTab,
      cronTabPeriod: getPeriodForCrontab(cronTab),
      timezone: scheduleConfig.get('timezone') || 'UTC',
    };
  };

  handleRemove = () => {
    this.setState({ isSaving: true });
    const promise = this.state.showTriggerMode
      ? this.handleRemoveTrigger()
      : this.handleRemoveScheduler();
    return promise.then(this.props.onHide).finally(() => this.setState({ isSaving: false }));
  };

  handleRemoveScheduler = () => {
    if (this.findScheduler().has('id')) {
      return removeScheduler(this.findScheduler().get('id'));
    }
  };

  handleRemoveTrigger = () => {
    if (this.props.trigger.has('id')) {
      return deleteTrigger(this.props.trigger.get('id')).then(() =>
        this.setState({
          showTriggerMode: !this.props.canManageSchedule && this.props.canManageTriggers,
          trigger: fromJS(defaultTrigger),
        }),
      );
    }
  };

  findScheduler() {
    return getSingleScheduler(this.props.orchestration.get('schedulers'));
  }
}

export default ScheduleModal;
