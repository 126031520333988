import { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { Button, Icon, Tooltip } from '@keboola/design';

import { canUnlinkBucket } from '@/modules/admin/privileges';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import DeleteBucketModal from '@/modules/storage/components/DeleteBucketModal';

class UnlinkButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      mouseEnter: false,
      hasFocus: false,
      unlinkingBucket: false,
    };

    this.handleUnlink = this.handleUnlink.bind(this);
  }

  render() {
    if (!canUnlinkBucket(this.props.token, this.props.bucket)) {
      return (
        <Tooltip
          tooltip="You don't have permission to unlink this bucket."
          placement="top"
          type="explanatory"
        >
          <Button size={this.props.size} disabled>
            <Icon icon="circle-check" />
            Linked
          </Button>
        </Tooltip>
      );
    }

    const unlinkButton = this.state.mouseEnter || this.state.hasFocus || this.state.showModal;

    return (
      <>
        <Button
          size={this.props.size}
          variant={unlinkButton ? 'danger' : 'primary'}
          onClick={() => this.setState({ showModal: true })}
          onMouseEnter={() => this.setState({ mouseEnter: true })}
          onMouseLeave={() => this.setState({ mouseEnter: false })}
          onFocus={() => this.setState({ hasFocus: true })}
          onBlur={() => this.setState((state) => ({ hasFocus: state.showModal }))}
          className="tw-min-w-[84px]"
        >
          {unlinkButton ? (
            <>
              {!this.props.hideIcon && <Icon icon="trash" />}
              Unlink
            </>
          ) : (
            <>
              {!this.props.hideIcon && <Icon icon="circle-check" />}
              Linked
            </>
          )}
        </Button>
        <DeleteBucketModal
          show={this.state.showModal}
          bucket={this.props.bucket}
          deleting={this.state.unlinkingBucket}
          onConfirm={this.handleUnlink}
          onHide={() => this.setState({ showModal: false, hasFocus: false, mouseEnter: false })}
        />
      </>
    );
  }

  handleUnlink() {
    this.setState({ unlinkingBucket: true });
    return StorageActionCreators.deleteBucket(this.props.bucket.get('id')).finally(() => {
      this.setState({ unlinkingBucket: false });
    });
  }
}

UnlinkButton.propTypes = {
  token: PropTypes.instanceOf(Map).isRequired,
  bucket: PropTypes.instanceOf(Map).isRequired,
  size: PropTypes.string,
  hideIcon: PropTypes.bool,
};

export default UnlinkButton;
