import { Alert } from '@keboola/design';

const ReadOnlyAccessBigQuery = () => {
  return (
    <Alert title="Read-Only Access" className="tw-mb-5">
      By default, the BigQuery workspace has read-only access to all project data.
    </Alert>
  );
};

export default ReadOnlyAccessBigQuery;
