import type { Map } from 'immutable';

import { User } from '@/react/common';
import { findAdmin } from './findAdmin';
import { getUserDetails } from './getUserDetails';

type CommonProps = { admins: Map<string, any>; person: Map<string, any> };

export const renderUser = ({ admins, person }: CommonProps) => {
  const { name, email } = getUserDetails(person, admins);
  if (!name && !email) return <div className="font-medium">-</div>;

  const admin = findAdmin(admins, email, person.get('id'));
  if (!admin) return <div className="font-medium">{name || email}</div>;

  return <User user={admin} size={24} />;
};
