import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { List, Map } from 'immutable';

import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { getTableAliases, getTableLinks } from '@/modules/storage/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import DevBranchStorageWarning from './DevBranchStorageWarning';
import TableAliasesAndLinks from './TableAliasesAndLinks';

const DeleteMultipleTablesModal = ({
  bucket,
  tables,
  allTables,
  sapiToken,
  urlTemplates,
  show,
  deleting,
  onConfirm,
  onHide,
}) => {
  const tablesAliases = tables.reduce(
    (tableAliases, table) => [...tableAliases, ...getTableAliases(table, allTables, sapiToken)],
    [],
  );
  const tablesLinks = tables.reduce(
    (tableLinks, table) => [...tableLinks, ...getTableLinks(table, bucket)],
    [],
  );
  const hasAliasesOrLinks = tablesAliases.length + tablesLinks.length > 0;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Selected Tables</Modal.Title>
        <ModalIcon.Trash />
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete the selected tables?</p>
        <DevBranchStorageWarning
          message="The tables will also be deleted in production."
          hasProductionEntity={tables.some((table) => !isCreatedInDevBranch(table.get('bucket')))}
        />
        {hasAliasesOrLinks && (
          <>
            <strong>All aliases and links will also be deleted.</strong>
            <TableAliasesAndLinks
              sapiToken={sapiToken}
              urlTemplates={urlTemplates}
              tableAliases={tablesAliases}
              tableLinks={tablesLinks}
              onLinkClick={onHide}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ConfirmButtons
          block
          isSaving={deleting}
          isDisabled={deleting}
          saveLabel={deleting ? 'Deleting...' : 'Delete'}
          saveStyle="danger"
          onSave={() => onConfirm().then(onHide)}
        />
      </Modal.Footer>
    </Modal>
  );
};

DeleteMultipleTablesModal.propTypes = {
  bucket: PropTypes.instanceOf(Map).isRequired,
  tables: PropTypes.instanceOf(List).isRequired,
  allTables: PropTypes.instanceOf(Map).isRequired,
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  urlTemplates: PropTypes.instanceOf(Map).isRequired,
  show: PropTypes.bool.isRequired,
  deleting: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default DeleteMultipleTablesModal;
