import { useState } from 'react';
import type { KeyboardEvent } from 'react';
import type { Map } from 'immutable';

import { Icon } from '@keboola/design';

import MetadataActionCreators from '@/modules/components/MetadataActionCreators';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { getDescriptionValue } from '@/modules/storage/helpers';
import { RowActionMenuItem } from '@/react/common';
import DescriptionModal from '@/react/common/DescriptionModal';
import DevBranchStorageWarning from './DevBranchStorageWarning';

const DescriptionButton = (props: {
  entity: 'bucket' | 'table';
  data: Map<string, any>;
  readOnly: boolean;
  onKeyDown?: (e: KeyboardEvent) => void;
}) => {
  const [show, setShow] = useState(false);
  const description = getDescriptionValue(props.data.get('metadata'));
  const bucket = props.entity === 'bucket' ? props.data : props.data.get('bucket');

  return (
    <>
      <RowActionMenuItem onSelect={() => setShow(true)} onKeyDown={props.onKeyDown}>
        <Icon fixedWidth icon={!!description ? 'note-sticky' : ['far', 'note-sticky']} />
        {!!description || props.readOnly ? 'Show' : 'Add'} description
      </RowActionMenuItem>
      <DescriptionModal
        show={show}
        onHide={() => setShow(false)}
        entity={props.entity}
        description={description}
        onSave={(newDescription) => {
          return MetadataActionCreators.saveMetadata(
            props.entity,
            props.data.get('id'),
            MetadataKeys.DESCRIPTION,
            newDescription.trim(),
          );
        }}
        readOnly={props.readOnly}
        warning={
          <DevBranchStorageWarning
            message="The description will also change in production."
            hasProductionEntity={!isCreatedInDevBranch(bucket)}
          />
        }
      />
    </>
  );
};

export default DescriptionButton;
