import { useState } from 'react';
import PropTypes from 'prop-types';
import Promise from 'bluebird';
import { List, Map } from 'immutable';

import InstalledComponentsApi from '@/modules/components/InstalledComponentsApi';
import { createSimpleRow } from '@/modules/configurations/ConfigurationRowsActionCreators';
import {
  getPredefinedQueries,
  getSelectedPredefinedQuery,
} from '@/modules/ex-google-analytics-v4/helpers';
import { hasOldProfiles } from '@/modules/google-utils/helpers';
import { runComponent } from '@/modules/simplified-ui/actions';
import Select from '@/react/common/Select';

const OPTIONS = {
  OLD_API: [
    {
      label: 'Conversions - Ecommerce - Overview',
      value: 'conversions-ecommerce-overview',
      requireEcommerce: true,
    },
    {
      label: 'Conversions - Ecommerce - Product Performance',
      value: 'conversions-ecommerce-product-performance',
      requireEcommerce: true,
    },
    {
      label: 'Conversions - Multi-Channel Funnels - Model Comparison Tool ',
      value: 'conversions-multi-channel-funnels-model-comparison-tool',
      requireEcommerce: false,
    },
    {
      label: 'Audience - Overview',
      value: 'audience-overview',
      requireEcommerce: false,
    },
    {
      label: 'Behavior - Site Content - All Pages',
      value: 'behavior-site-content-All-Pages',
      requireEcommerce: false,
    },
    {
      label: 'Acquisition - All Traffic - Source & Medium',
      value: 'acquisition-all-traffic-source-medium',
      requireEcommerce: false,
    },
    {
      label: 'Conversion - Goals - Overview',
      value: 'conversion-goals-overview',
      requireEcommerce: false,
    },
  ],
  NEW_API: [
    {
      label: 'Acquisition - Overview',
      value: 'acquisition-overview',
    },
    {
      label: 'Acquisition - User acquisition',
      value: 'acquisition-user-acquisition',
    },
    {
      label: 'Engagement - Overview',
      value: 'engagement-overview',
    },
    {
      label: 'Engagement - Events',
      value: 'engagement-events',
    },
    {
      label: 'Monetization - Overview',
      value: 'monetization-overview',
    },
    {
      label: 'Demographic - Overview',
      value: 'demographic-overview',
    },
    {
      label: 'Demographic - Details',
      value: 'demographic-details',
    },
    {
      label: 'Tech - Overview',
      value: 'tech-overview',
    },
  ],
};

const PredefinedQuerySelector = ({ componentId, configId, config, readOnly, hasNewQueue }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [selectedQueryKey, setSelectedQueryKey] = useState(getSelectedPredefinedQuery(config));
  const eCommerceTracking = config
    .getIn(['configuration', 'parameters', 'profiles'], List())
    .every((profil) => profil.get('eCommerceTracking'));

  const profileType = hasOldProfiles(config.get('configuration', Map())) ? 'OLD_API' : 'NEW_API';

  const changeQueryHandler = (newQueryKey) => {
    if (newQueryKey === selectedQueryKey) {
      return;
    }

    setSelectedQueryKey(newQueryKey);
    setIsSaving(true);

    return Promise.each(config.get('rows', List()), (row) =>
      InstalledComponentsApi.deleteConfigurationRow(
        componentId,
        configId,
        row.get('id'),
        'Change data usecase',
      ),
    )
      .then(() => {
        return Promise.each(getPredefinedQueries(config)[newQueryKey], (query) =>
          createSimpleRow(
            componentId,
            configId,
            {
              name: OPTIONS[profileType].find((option) => option.value === newQueryKey).label,
              configuration: JSON.stringify({ parameters: query }),
            },
            `Change data usecase`,
          ),
        );
      })
      .then(() => runComponent(componentId, configId, hasNewQueue))
      .finally(() => setIsSaving(false));
  };

  return (
    <div className="flex-container justify-center align-top">
      <div className="max-w-700">
        <div className="text-center mb-2">
          <p className="uppercase text-muted font-medium">
            Let&apos;s see what you can do with data
          </p>
          <p className="f-24">
            Your data from Google Analytics are pure gold.
            <br />
            Do you know that?
          </p>
        </div>
        <div className="f-16">
          <Select
            value={
              OPTIONS[profileType].some(({ value }) => value === selectedQueryKey)
                ? selectedQueryKey
                : ''
            }
            options={OPTIONS[profileType]
              .slice()
              .sort((a, b) => a.label.localeCompare(b.label))
              .filter((option) => !option.requireEcommerce || eCommerceTracking)}
            onChange={changeQueryHandler}
            isLoading={isSaving}
            disabled={readOnly}
            noResultsText="No usecase found"
            placeholder="Select Data Usecase"
            clearable={false}
          />
        </div>
      </div>
    </div>
  );
};

PredefinedQuerySelector.propTypes = {
  componentId: PropTypes.string.isRequired,
  configId: PropTypes.string.isRequired,
  config: PropTypes.instanceOf(Map).isRequired,
  readOnly: PropTypes.bool.isRequired,
  hasNewQueue: PropTypes.bool.isRequired,
};

export default PredefinedQuerySelector;
