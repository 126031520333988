import { DOCUMENTATION_URL } from '@keboola/constants';
import { Link } from '@keboola/design';

import InfoTooltip from '@/react/common/InfoTooltip';

type Props = {
  isSnowflakeBackend: boolean;
};

export const BackendEntityInfoTooltip = ({ isSnowflakeBackend }: Props) => {
  const backendName = isSnowflakeBackend ? 'Snowflake' : 'BigQuery';
  const entityName = isSnowflakeBackend ? 'schema' : 'dataset';

  return (
    <InfoTooltip
      className="tw-m-0"
      tooltip={
        <>
          This is the name of the {entityName} representing this bucket in {backendName}. Use it for
          direct access to data via{' '}
          <Link href={DOCUMENTATION_URL.READ_ONLY_INPUT_MAPPING}>read-only input mapping.</Link>
        </>
      }
    ></InfoTooltip>
  );
};
