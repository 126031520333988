import type { ChangeEvent } from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

import { HelpBlock } from '@keboola/design';

import { nameWarning } from '@/modules/storage/constants';
import Checkbox from '@/react/common/Checkbox';
import CsvDelimiterInput from '@/react/common/CsvDelimiterInput';
import Select from '@/react/common/Select';
import string from '@/utils/string';

type ValueProps = {
  path: string;
  decompress: boolean;
  name: string;
  incremental: boolean;
  delimiter: string;
  enclosure: string;
  columnsFrom: 'manual' | 'header' | 'auto';
  columns: string[];
  primaryKey: string[];
};

type Props = {
  value: ValueProps;
  onChange: (data: Partial<ValueProps>) => void;
  disabled: boolean;
};

const columnsFromOptions = [
  {
    label: 'Set the header manually',
    value: 'manual',
  },
  {
    label: 'Read the header from the file(s) header',
    value: 'header',
  },
  {
    label: 'Create the header automatically',
    value: 'auto',
  },
];

const Configuration = (props: Props) => {
  const renderDownloadSettings = () => {
    return (
      <>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Path</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              value={props.value.path}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                props.onChange({ path: e.target.value });
              }}
              placeholder="/myfolder/myfile.csv"
              disabled={props.disabled}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-8 col-xs-offset-4">
            <Checkbox
              checked={props.value.decompress}
              onChange={(checked) => props.onChange({ decompress: checked })}
              disabled={props.disabled}
            >
              Decompress
            </Checkbox>
            <HelpBlock className="tw-mt-1">
              Decompress the downloaded file(s). All files in all archives will be imported into a
              single Storage table.
            </HelpBlock>
          </div>
        </FormGroup>
      </>
    );
  };

  const renderSaveSettings = () => {
    return (
      <>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Table name</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              value={props.value.name}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                props.onChange({ name: string.sanitizeKbcTableIdString(e.target.value) });
              }}
              placeholder="mytable"
              disabled={props.disabled}
            />
            <HelpBlock className="tw-mt-1">
              Name of the table stored in Storage. {nameWarning}
            </HelpBlock>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-8 col-xs-offset-4">
            <Checkbox
              checked={props.value.incremental}
              onChange={(checked) => props.onChange({ incremental: checked })}
              disabled={props.disabled}
            >
              Incremental load
            </Checkbox>
            <HelpBlock className="tw-mt-1">
              If incremental load is turned on, the table will be updated instead of rewritten.{' '}
              Tables with a primary key will have rows updated, tables without a primary key will
              have rows appended.
            </HelpBlock>
          </div>
        </FormGroup>
        <CsvDelimiterInput
          value={props.value.delimiter}
          onChange={(value) => {
            props.onChange({ delimiter: value });
          }}
          disabled={props.disabled}
        />
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Enclosure</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              value={props.value.enclosure}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                props.onChange({ enclosure: e.target.value });
              }}
              placeholder={'"'}
              disabled={props.disabled}
            />
            <HelpBlock className="tw-mt-1">
              Field enclosure used in the CSV file. The default value is <code>&quot;</code>.
            </HelpBlock>
          </div>
        </FormGroup>
      </>
    );
  };

  const renderHeaderPrimaryKey = () => {
    return (
      <>
        <div className="form-group">
          <label htmlFor="columnsFor" className="col-xs-4 control-label">
            Read Header
          </label>
          <div className="col-xs-8">
            <Select
              id="columnsFor"
              searchable={false}
              clearable={false}
              disabled={props.disabled}
              options={columnsFromOptions}
              value={props.value.columnsFrom}
              onChange={(selected: ValueProps['columnsFrom']) => {
                props.onChange({
                  columnsFrom: selected,
                  ...(selected !== 'manual' && { columns: [] }),
                });
              }}
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="column" className="col-xs-4 control-label">
            Set Header
          </label>
          <div className="col-xs-8">
            <Select
              id="column"
              multi
              allowCreate
              placeholder="Add a column"
              disabled={props.value.columnsFrom !== 'manual' || props.disabled}
              value={props.value.columns}
              onChange={(values) => props.onChange({ columns: values.toArray() })}
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="primaryKey" className="col-xs-4 control-label">
            Primary Key
          </label>
          <div className="col-xs-8">
            <Select
              id="primaryKey"
              multi
              allowCreate
              placeholder="Add a column to the primary key"
              disabled={props.disabled}
              value={props.value.primaryKey}
              onChange={(values) => props.onChange({ primaryKey: values.toArray() })}
            />
            <div className="help-block">
              If a primary key is set, updates can be done on the table by selecting{' '}
              <strong>incremental load</strong>. The primary key can consist of multiple columns.
              The primary key of an existing table cannot be changed.
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Download Settings</h3>
        </div>
        <div className="box-content">{renderDownloadSettings()}</div>
      </div>
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Save Settings</h3>
        </div>
        <div className="box-content">{renderSaveSettings()}</div>
      </div>
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Header &amp; Primary Key</h3>
        </div>
        <div className="box-content">{renderHeaderPrimaryKey()}</div>
      </div>
    </>
  );
};

export default Configuration;
