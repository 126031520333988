import { Alert } from '@keboola/design';

import ReadOnlyDocumentationLink from '@/modules/sandboxes/components/ReadOnlyDocumentationLink';

const TableInputMappingReadOnlyInfo = () => {
  return (
    <Alert title="Table Input Mapping" className="tw-mb-5 tw-mt-6">
      All tables are already included in your transformations - no manual addition required. You
      just need to reference them in the code. <ReadOnlyDocumentationLink />
    </Alert>
  );
};

export default TableInputMappingReadOnlyInfo;
