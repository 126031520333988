import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { Icon } from '@keboola/design';

import Loader from '@/react/common/Loader';

const ExtendCredentialsButton = createReactClass({
  propTypes: {
    isExtending: PropTypes.bool.isRequired,
    onExtend: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    canExtend: PropTypes.bool.isRequired,
    buttonStyle: PropTypes.string.isRequired,
  },

  render() {
    if (this.props.isExtending) {
      return (
        <Button bsStyle={this.props.buttonStyle} title="Extend" type="submit" disabled>
          <Loader /> Extending
        </Button>
      );
    }

    return (
      <Button
        bsStyle={this.props.buttonStyle}
        title="Extend"
        type="submit"
        onClick={this.props.onExtend}
        disabled={this.props.disabled || !this.props.canExtend}
      >
        <Icon icon="clock" className="icon-addon-right" />
        Extend expiration
      </Button>
    );
  },
});

export default ExtendCredentialsButton;
