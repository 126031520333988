import { useCallback, useState } from 'react';
import { Map } from 'immutable';

import { sortEntities } from '@/constants';

const getSortValue = (row: Map<string, any>, sortEntity: string) => {
  if (sortEntity === sortEntities.LAST_CHANGE) {
    return new Date(row.get('lastChangeDate') ?? row.get('created')).valueOf();
  }
  if (sortEntity === sortEntities.LAST_SHARE) {
    return new Date(row.getIn(['sharedBy', 'date'])).valueOf();
  }
  if (sortEntity === sortEntities.SIZE) {
    return row.get('dataSizeBytes') ?? 0;
  }
  if (sortEntity === sortEntities.OWNER) {
    const owner = row.get('owner');
    const ownerName = owner?.get('name');
    return ownerName ?? '-';
  }
  if (sortEntity === sortEntities.SHARED) {
    const sharing = row.get('sharing');
    return sharing;
  }

  return row.get('displayName');
};

const useSorter = (initialState: any, updateFunction?: (sort: any) => void) => {
  const [sort, setSort] = useState(initialState);

  const sorter = useCallback(
    (a: Map<string, any>, b: Map<string, any>) => {
      const valueA = getSortValue(a, sort.get('entity'));
      const valueB = getSortValue(b, sort.get('entity'));

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase()) * sort.get('order');
      }

      return (valueB - valueA) * sort.get('order');
    },
    [sort],
  );

  const setSortFn = useCallback(
    (sort: any) => {
      setSort(Map(sort));
      if (updateFunction) {
        updateFunction(sort);
      }
    },
    [updateFunction],
  );

  return { sorter, sort, setSort: setSortFn };
};

export { useSorter };
