import { IconButton, Tooltip } from '@keboola/design';

import { TABLE_COLUMNS_ORDER } from '@/constants/localStorageKeys';
import * as localStorage from '@/utils/localStorage';

const DataSampleColumnOrderInfo = (props: { tableId: string; onResetColumnsOrder: () => void }) => {
  const localStorageKey = `${TABLE_COLUMNS_ORDER}-${props.tableId}`;
  const columnOrder = localStorage.getItem(localStorageKey);

  if (!columnOrder) {
    return null;
  }

  return (
    <div className="flex-container flex-start inline-flex">
      <Tooltip
        placement="top"
        type="explanatory"
        tooltip="Columns are reordered only in the Data Sample preview. They are not reordered in the original table."
        triggerClassName="f-12 cursor-help dashed-underline border-color-inherit icon-addon-right"
      >
        Columns are reordered
      </Tooltip>
      <Tooltip placement="top" tooltip="Reset column order">
        <IconButton
          size="small"
          variant="inline"
          onClick={() => {
            localStorage.removeItem(localStorageKey);
            props.onResetColumnsOrder();
          }}
          icon="arrow-rotate-left"
        />
      </Tooltip>
    </div>
  );
};

export default DataSampleColumnOrderInfo;
