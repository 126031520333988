import { PureComponent } from 'react';
import type { Map } from 'immutable';

import ComponentType from '@/modules/flows/components/ComponentType';
import Row from '@/modules/flows/components/Row';
import { Truncated } from '@/react/common';
import ComponentName from '@/react/common/ComponentName';
import MarkedText from '@/react/common/MarkedText';
import string from '@/utils/string';

type Props = {
  component: Map<string, any>;
  configurations: number;
  query: string;
  onSelect: (component: Map<string, any>) => void;
};

class AddTaskComponentRow extends PureComponent<Props> {
  render() {
    return (
      <Row
        component={this.props.component}
        onClick={() => this.props.onSelect(this.props.component)}
      >
        <div className="tw-flex tw-flex-col tw-gap-0.5">
          <ComponentName component={this.props.component}>
            {(name) => (
              <Truncated
                tooltip={name}
                text={<MarkedText className="tw-text-sm" source={name} mark={this.props.query} />}
              />
            )}
          </ComponentName>
          <div className="tw-flex tw-gap-2 tw-text-xs">
            <ComponentType component={this.props.component} />
            <span className="tw-text-neutral-400">
              {this.props.configurations}{' '}
              {string.pluralize(this.props.configurations, 'configuration')}
            </span>
          </div>
        </div>
      </Row>
    );
  }
}

export default AddTaskComponentRow;
