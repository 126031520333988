import { useState } from 'react';
import type { FormEvent } from 'react';
import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';
import type { Map } from 'immutable';
import { List } from 'immutable';

import { Alert } from '@keboola/design';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';

type Props = {
  show: boolean;
  onSubmit: (params: { operator: string; column: string; values: string[] }) => Promise<void>;
  onHide: () => void;
  isSaving: boolean;
  table: Map<string, any>;
};

const AliasFilterModal = (props: Props) => {
  const defaultValues = {
    operator: props.table.getIn(['aliasFilter', 'operator'], 'eq'),
    column: props.table.getIn(['aliasFilter', 'column'], ''),
    values: props.table.getIn(['aliasFilter', 'values'], List()),
    error: null,
  };
  const [operator, setOperator] = useState<string>(defaultValues.operator);
  const [column, setColumn] = useState<string>(defaultValues.column);
  const [values, setValues] = useState<List<any>>(defaultValues.values);
  const [error, setError] = useState(defaultValues.error);

  const handleHide = () => {
    setOperator(defaultValues.operator);
    setColumn(defaultValues.column);
    setValues(defaultValues.values);
    setError(defaultValues.error);
    props.onHide();
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (error) {
      setError(null);
    }

    const params = {
      operator: operator,
      column: column,
      values: values.toArray(),
    };

    props.onSubmit(params).then(handleHide, (message) => {
      setError(message);
    });
  };

  const tableColumns = props.table
    .get('columns', List())
    .map((column: string) => ({
      label: column,
      value: column,
    }))
    .toArray();

  return (
    <Modal show={props.show} onHide={handleHide}>
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Update Filter</Modal.Title>
          <ModalIcon.Edit />
        </Modal.Header>
        <Modal.Body>
          {error ? (
            <Alert variant="error" className="tw-mb-5">
              {error}
            </Alert>
          ) : null}
          <p>
            You can specify a column to filter by, and comma-separated values you&apos;re looking
            for. The alias table will contain only the matching rows.
          </p>
          <br />
          <FormGroup>
            <ControlLabel>Column</ControlLabel>
            <Select
              clearable={false}
              value={column}
              onChange={(newColumn: string) => setColumn(newColumn)}
              options={tableColumns}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Operator</ControlLabel>
            <Select
              searchable={false}
              clearable={false}
              value={operator}
              onChange={(newOperator: string) => setOperator(newOperator)}
              options={[
                { label: '= (IN)', value: 'eq' },
                { label: '!= (NOT IN)', value: 'ne' },
              ]}
            />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Values</ControlLabel>
            <Select
              multi
              allowCreate
              emptyStrings
              placeholder="Add a value"
              value={values}
              onChange={(newValues) => setValues(newValues)}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isSaving={props.isSaving}
            isDisabled={!operator || !column || !values}
            saveLabel="Update"
            saveButtonType="submit"
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AliasFilterModal;
