import { useState } from 'react';
import { Map } from 'immutable';

import { cn, Icon, Tooltip } from '@keboola/design';

import { canWriteBucket } from '@/modules/admin/privileges';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { updateTable } from '@/modules/storage/actions';
import { validateTableName } from '@/modules/storage/helpers';
import InlineEditTextInput from '@/react/common/InlineEditTextInput';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import nextTick from '@/utils/nextTick';
import EditNameWithDevBranchWarningModal from './EditNameWithDevBranchWarningModal';

type Props = {
  tableName: string;
  bucketId: string;
};

const TableNameEdit = (props: Props) => {
  const store = useStores(
    () => {
      const table = TablesStore.getTableByName(props.tableName, props.bucketId);

      return {
        table,
        isDevModeActive: DevBranchesStore.isDevModeActive(),
        hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
        readOnly: !canWriteBucket(ApplicationStore.getSapiToken(), table.get('bucket', Map())),
        originalValue: table.get('displayName', props.tableName),
        bucketTables: (TablesStore.getAll() as Map<string, any>).filter((table) => {
          return (
            table.getIn(['bucket', 'id']) === props.bucketId &&
            table.get('name') !== props.tableName
          );
        }) as Map<string, any>,
      };
    },
    [props.bucketId, props.tableName],
    [ApplicationStore, DevBranchesStore, TablesStore],
  );

  const table = TablesStore.getTableByName(props.tableName, props.bucketId);

  const [value, setValue] = useState(table.get('displayName', props.tableName));
  const [isEditingInModal, setIsEditingInModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleEditStart = () => {
    if (
      store.isDevModeActive &&
      !store.hasProtectedDefaultBranch &&
      !isCreatedInDevBranch(store.table.get('bucket'))
    ) {
      setIsEditingInModal(true);
      setError(null);
      return;
    }

    setIsEditing(true);
    setError(null);
  };

  const handleEditChange = (newValue: string) => {
    setValue(newValue);
    setError(validateTableName(newValue, store.bucketTables, { strict: false }));
  };

  const handleEditCancel = () => {
    setError(null);
    setIsSaving(false);
    setIsEditing(false);
    setIsEditingInModal(false);
    setValue(store.originalValue);
  };

  const handleEditSubmit = () => {
    setError(null);
    setIsSaving(true);
    return updateTable(store.table.get('id'), { displayName: value })
      .catch((error) => setError(error))
      .finally(() => {
        setIsSaving(false);
        setIsEditing(false);
      });
  };

  const renderTableName = () => {
    if (store.readOnly) {
      return value;
    }

    if (isEditingInModal) {
      return (
        <>
          {store.originalValue}
          <EditNameWithDevBranchWarningModal
            entity="tableName"
            name={value}
            onSubmit={handleEditSubmit}
            onChange={handleEditChange}
            onHide={() => nextTick(handleEditCancel)}
            isSaving={isSaving}
            error={error}
          />
        </>
      );
    }

    return (
      <span className={cn({ 'input-error': !!error })}>
        <Tooltip
          placement="bottom"
          type="explanatory"
          tooltip={error}
          forceShow={!!error}
          forceHide={!error}
        >
          <InlineEditTextInput
            isValid={!error}
            isChanged={value !== store.originalValue}
            text={value}
            isSaving={isSaving}
            isEditing={isEditing}
            onEditStart={handleEditStart}
            onEditCancel={handleEditCancel}
            onEditChange={handleEditChange}
            onEditSubmit={handleEditSubmit}
          />
        </Tooltip>
      </span>
    );
  };

  return (
    <>
      <Icon
        icon="table"
        fixedWidth
        style={{ fontSize: '0.75em' }}
        className="text-muted icon-addon-right"
      />
      {renderTableName()}
    </>
  );
};

export default TableNameEdit;
