import Checkbox from '@/react/common/Checkbox';
import type { RowData } from '@/types/types';

type Props = {
  row: RowData;
};

export const CellSelection = ({ row }: Props) => {
  if (!row.original.item.has('stage')) {
    return (
      <Checkbox
        checked={row.getIsSelected()}
        onChange={() => {
          const parent = row.getParentRow?.();

          if (row.getIsSelected() && parent && parent.getIsSelected()) {
            parent.toggleSelected(false, { selectChildren: false });
          }

          return row.toggleSelected();
        }}
        tooltip={row.getIsSelected() ? 'Deselect table' : 'Select table'}
      />
    );
  }

  const isChecked = row.getIsSelected();
  const isSomeSelected = row.getIsSomeSelected() || row.getIsAllSubRowsSelected();

  return (
    <Checkbox
      checked={isChecked}
      indeterminate={!isChecked && isSomeSelected}
      onChange={() => {
        row.toggleSelected(!(isChecked || isSomeSelected));
        row.subRows.forEach((subRow) => {
          subRow.toggleSelected(!(isChecked || isSomeSelected));
        });
      }}
      tooltip={`${isChecked || isSomeSelected ? 'Deselect' : 'Select'} ${
        isSomeSelected ? 'tables in bucket' : 'bucket'
      }`}
    />
  );
};
