export const isInteractiveElement = (target: HTMLElement): boolean => {
  return (
    target.tagName === 'BUTTON' ||
    target.tagName === 'A' ||
    target.classList.contains('interactive') ||
    target.isContentEditable ||
    target.closest('button') !== null ||
    target.closest('a') !== null
  );
};
