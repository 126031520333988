import { KeboolaHttpHeader } from '../../constants';
import { createCallbackMiddleware, createGenericFetchClient } from '../../fetchClient';
import type { ClientInitOptions } from '../types';

import { createBranches } from './branches';
import { createFiles } from './files';
import { createTables } from './tables';
import type { GetStackInfoQuery, StackInfo } from './types';
import { getServiceMap } from './utils';
import { createWorkspaces } from './workspaces';

export type GenericFetchClient = ReturnType<typeof createGenericFetchClient>;

export const createStorageClient = ({ baseUrl, token, callbacks }: ClientInitOptions) => {
  const client = createGenericFetchClient({
    baseUrl: `${baseUrl}/v2/storage`,
    middlewares: [createCallbackMiddleware(callbacks)],
    headers: new Headers({ [KeboolaHttpHeader.STORAGE_API_TOKEN]: token }),
  });

  const getStackInfo = async (query?: GetStackInfoQuery, signal?: AbortSignal) => {
    const { data } = await client.get<StackInfo, never, GetStackInfoQuery>(
      '',
      { query },
      { signal },
    );

    return { stackInfo: data, serviceMap: getServiceMap(data.services) };
  };

  return {
    files: createFiles(client),
    tables: createTables(client),
    branches: createBranches(client),
    workspaces: createWorkspaces(client),
    getStackInfo,
  };
};
