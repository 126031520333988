import { Component } from 'react';

import blankOrchestrationImage from '../../../../images/illustrations/blank-orchestrations.png';

import { fileUrl } from '@/utils/fileHelpers';

class BlankOrchestrationPage extends Component {
  render() {
    return (
      <div className="blank-page">
        <img
          src={fileUrl(blankOrchestrationImage)}
          className="intro-image"
          alt="Orchestrations introduction"
        />
        <h2>Now you can orchestrate and schedule everything.</h2>
        <p className="intro-text">
          Orchestration is a way to put operations together and schedule them regularly.
        </p>
      </div>
    );
  }
}

export default BlankOrchestrationPage;
