import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Card, cn, FakeProgressBar } from '@keboola/design';

import PromptInput from '@/react/common/AI/PromptInput';
import { AiIcon } from '@/react/common/AI/svgGradient';
import PredefinedExamples from './Examples';
import { redirectToAutomation, useCreateAutomation } from './queries';

const NewAutomation = ({
  hideExamples,
  className,
}: {
  hideExamples?: boolean;
  className?: string;
}) => {
  const [prompt, setPrompt] = useState('');
  const { mutateAsync: createAutomation, isPending } = useCreateAutomation();

  const handleSubmit = async (message: string) => {
    if (isPending) return;

    if (message !== prompt) {
      setPrompt(message);
    }

    const automation = await createAutomation(message);
    redirectToAutomation(automation);
  };

  return (
    <>
      <Card className={cn('tw-flex-1', className)}>
        <Card.Header>
          <Card.Title>Use AI to Design Your Flow</Card.Title>
        </Card.Header>
        <Card.Content>
          <p className="tw-mb-8">
            Let our AI create a pipeline for you. Provide a prompt with your requirements, and
            we&apos;ll generate it for you.
          </p>
          <PromptInput
            highlight
            autoFocus
            multiLine
            prompt={prompt}
            onChange={setPrompt}
            onSubmit={() => handleSubmit(prompt.trim())}
            isLoading={isPending}
            isDisabled={isPending || !prompt.trim()}
            isDisabledInput={isPending}
            loadingLabel="Processing..."
            placeholder="e.g. I'd like to see how different restaurants from my chain perform"
          />
          <p className="tw-mb-0 tw-mt-3 tw-text-xs tw-font-medium tw-text-neutral-400">
            What is your <span className="tw-text-neutral-800">intent</span>? Please provide as much
            detail as possible, including your{' '}
            <span className="tw-text-neutral-800">data source</span>,{' '}
            <span className="tw-text-neutral-800">destination</span>, and any data that needs to be{' '}
            <span className="tw-text-neutral-800">transformed</span>.<br />
            The more details you can provide, the better I can assist you!
          </p>
          {!hideExamples && <PredefinedExamples onClick={handleSubmit} disabled={isPending} />}
        </Card.Content>
      </Card>
      <ProgressModal show={isPending} />
    </>
  );
};

const ProgressModal = ({ show }: { show: boolean }) => {
  return (
    <Modal
      show={show}
      className="[&_.modal-content]:tw-rounded-2xl [&_.modal-content]:tw-bg-gradient-to-br [&_.modal-content]:tw-from-[#0975E033] [&_.modal-content]:tw-to-[#6F36E033]"
      dialogClassName="tw-mt-[30vh] tw-max-w-[90%] tw-w-[800px] tw-rounded-2xl"
    >
      <Modal.Body className="!tw-px-12 !tw-pb-6 tw-pt-5">
        <h3 className="tw-mb-8 tw-mt-2">
          Generating your first flow with the help of our AI Copilot.
          <AiIcon className="tw-ml-2" />
        </h3>
        <FakeProgressBar barClassName="tw-bg-gradient-to-br tw-from-[#0975E0] tw-to-[#6F36E0]" />
      </Modal.Body>
    </Modal>
  );
};

export default NewAutomation;
