import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import PasswordControl from '@/react/common/PasswordControl';

const Connection = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      account_name: PropTypes.string.isRequired,
      account_key: PropTypes.string.isRequired,
      file_system: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    return (
      <div className="form-horizontal">
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Account Name</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              disabled={this.props.disabled}
              value={this.props.value.account_name}
              onChange={(e) => {
                this.props.onChange({ account_name: e.target.value });
              }}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Account Key</ControlLabel>
          </div>
          <div className="col-xs-8">
            <PasswordControl
              value={this.props.value.account_key}
              disabled={this.props.disabled}
              onChange={(e) => this.props.onChange({ account_key: e.target.value })}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>File System</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl
              type="text"
              value={this.props.value.file_system}
              disabled={this.props.disabled}
              onChange={(e) => {
                this.props.onChange({ file_system: e.target.value });
              }}
            />
          </div>
        </FormGroup>
      </div>
    );
  },
});

export default Connection;
