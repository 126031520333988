import { cn, Icon } from '@keboola/design';

import { ObjectTypes } from '@/modules/components/MetadataConstants';
import { routeNames } from '@/modules/storage/constants';
import { RouterLink as Link, TableLabels, Truncated } from '@/react/common';
import type { RowData } from '@/types/types';
import { Description } from './Description';

type Props = {
  row: RowData;
  canWrite: boolean;
};

export const TableCell = ({ row, canWrite }: Props) => {
  const table = row.original.item;

  return (
    <span className="flex-container flex-start overflow-break-anywhere">
      <Icon icon="table" className="text-muted" />
      <div>
        <div className="flex-container flex-start">
          <Link
            to={routeNames.TABLE}
            params={{ bucketId: table.getIn(['bucket', 'id']), tableName: table.get('name') }}
            className={cn('link-inherit tw-mr-2.5', {
              'dotted-underline': table.get('isAlias', false),
            })}
          >
            <Truncated text={table.get('displayName')} />
          </Link>
          <TableLabels table={table} />
        </div>
        <Description row={row} entity={ObjectTypes.TABLE} readOnly={!canWrite} />
      </div>
    </span>
  );
};
