import { useState } from 'react';
import type { Map } from 'immutable';

import { Search } from '@keboola/design';

import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { prepareCreatingSandboxes, prepareSandboxes } from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { matchByWords } from '@/utils';
import Sandboxes from './Sandboxes';

const SandboxesIndex = () => {
  const state = useStores(
    () => {
      const hasNewQueue = ApplicationStore.hasNewQueue();
      const sandboxes = SandboxesStore.getSandboxes();
      const configurations = InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SANDBOXES);

      return {
        hasNewQueue,
        sapiToken: ApplicationStore.getSapiToken(),
        isLoadingSandboxes: SandboxesStore.getIsLoading(),
        sandboxesPendingActions: SandboxesStore.getPendingActions(),
        sandboxes: prepareSandboxes(sandboxes, configurations),
        creatingSandboxes: prepareCreatingSandboxes(
          SandboxesStore.getProcessingJobs(),
          sandboxes,
          configurations,
          hasNewQueue,
        ),
        admins: ApplicationStore.getAdmins(),
        currentAdmin: ApplicationStore.getCurrentAdmin(),
        allConfigurations: InstalledComponentsStore.getAll() as Map<string, any>,
        componentsMetadata: InstalledComponentsStore.getAllMetadata() as Map<string, any>,
        transformationComponents: ComponentsStore.getAllForType(
          componentTypes.TRANSFORMATION,
        ) as Map<string, any>,
        sandboxComponent: ComponentsStore.getComponent(KEBOOLA_SANDBOXES),
        mlflowInstanceUrl: SandboxesStore.getMlflowInstanceUrl(),
        readOnly: ApplicationStore.isReadOnly(),
        isDevModeActive: DevBranchesStore.isDevModeActive(),
        hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
        hasFlows: ApplicationStore.hasFlows(),
      };
    },
    [],
    [ApplicationStore, InstalledComponentsStore, ComponentsStore, SandboxesStore, DevBranchesStore],
  );

  const [filterQuery, setFilterQuery] = useState(
    RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
  );

  const getSandboxesFiltered = () => {
    return state.sandboxes.filter((sandbox: Map<string, any>) => {
      return (
        sandbox.get('id') === filterQuery ||
        sandbox.getIn(['configuration', 'id']) === filterQuery ||
        matchByWords(
          [
            sandbox.getIn(['configuration', 'name']),
            sandbox.getIn(['configuration', 'description']),
          ],
          filterQuery,
        )
      );
    }) as Map<string, any>;
  };

  const sandboxes = getSandboxesFiltered();

  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <Search
        placeholder={`Search workspaces (${state.sandboxes.count()})`}
        defaultValue={filterQuery}
        onChange={(query) => {
          setFilterQuery(query);
          RoutesStore.getRouter().updateQuery({ q: query });
        }}
      />

      <Sandboxes
        sandboxes={sandboxes}
        admins={state.admins}
        currentAdmin={state.currentAdmin}
        creatingSandboxes={state.creatingSandboxes}
        sapiToken={state.sapiToken}
        isLoading={state.isLoadingSandboxes}
        sandboxComponent={state.sandboxComponent}
        transformationComponents={state.transformationComponents}
        pendingActions={state.sandboxesPendingActions}
        allConfigurations={state.allConfigurations}
        componentsMetadata={state.componentsMetadata}
        isDevModeActive={state.isDevModeActive}
        mlflowInstanceUrl={state.mlflowInstanceUrl}
        hasPayAsYouGo={state.hasPayAsYouGo}
        hasNewQueue={state.hasNewQueue}
        hasFlows={state.hasFlows}
        readOnly={state.readOnly}
      />
    </div>
  );
};

export default SandboxesIndex;
