import { ProgressBar } from '@keboola/design';

import string from '@/utils/string';

const UploadProgress = (props: { progress: number; files: number; uploadedFiles: number }) => {
  return (
    <div>
      <div className="flex-container mbp-2">
        <p className="f-14 line-height-20 m-0">
          Uploading {props.files} {string.pluralize(props.files, 'file')}
        </p>
        <p className="f-12 line-height-16 font-medium text-muted m-0">
          {props.files - props.uploadedFiles} of {props.files} left
        </p>
      </div>
      <ProgressBar
        type="success"
        progress={(100 / props.files) * props.uploadedFiles + props.progress / props.files}
      />
    </div>
  );
};

export default UploadProgress;
