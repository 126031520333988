import { Button } from 'react-bootstrap';
import { Map } from 'immutable';

import { Icon, Tooltip } from '@keboola/design';

import type { PARAM_VALUE } from '@/modules/ex-generic/constants';
import { preparePropertyName } from '@/modules/ex-generic/helpers';
import { Truncated } from '@/react/common';
import EncryptedValue from '@/react/common/EncryptedValue';
import Loader from '@/react/common/Loader';

const PropertyRow = (props: {
  name: string;
  value: PARAM_VALUE;
  onEdit: () => void;
  onDelete: (name: string) => void;
  isDeleting: boolean;
  readOnly: boolean;
}) => {
  return (
    <div className="tw-group tw-rounded tw-border tw-border-solid tw-border-neutral-150 tw-p-4">
      <div className="tw-grid tw-grid-cols-3 tw-items-start tw-gap-4">
        <div className="tw-text-sm tw-text-neutral-800">{preparePropertyName(props.name)}</div>
        <div className="tw-col-span-2 tw-flex tw-justify-between">
          <span className="tw-mr-4 tw-text-sm tw-text-neutral-600">
            {Map.isMap(props.value) ? (
              <>
                <Icon icon="code" className="btn-icon tw-text-purple-500" />
                Function
              </>
            ) : props.name.startsWith('#') ? (
              <EncryptedValue />
            ) : (
              <Truncated text={String(props.value)} />
            )}
          </span>
          {!props.readOnly && (
            <div className="tw-inline-flex tw-justify-end tw-gap-4 tw-opacity-0 tw-transition-opacity group-hover:tw-opacity-100">
              <Tooltip tooltip="Edit row" placement="top">
                <Button
                  bsStyle="link"
                  disabled={props.isDeleting}
                  className="btn-link-inline text-muted"
                  onClick={props.onEdit}
                >
                  <Icon icon="pen" fixedWidth />
                </Button>
              </Tooltip>
              <Tooltip tooltip="Delete row" placement="top">
                <Button
                  bsStyle="link"
                  disabled={props.isDeleting}
                  className="btn-link-inline text-muted"
                  onClick={props.onDelete}
                >
                  {props.isDeleting ? <Loader /> : <Icon icon="trash" fixedWidth />}
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PropertyRow;
