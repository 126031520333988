import PropTypes from 'prop-types';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import { FormGroup, Label, TextInput } from '@keboola/design';

import PasswordControl from '@/react/common/PasswordControl';

const FORM_GROUP_CLASS_NAMES = 'tw-grid tw-grid-cols-3 tw-items-baseline tw-gap-4';

const Connection = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      account_name: PropTypes.string.isRequired,
      account_key: PropTypes.string.isRequired,
      container_name: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    return (
      <div className="tw-flex tw-flex-col tw-gap-4">
        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="account-name">Account Name</Label>
          <TextInput
            id="account-name"
            className="tw-col-span-2"
            variant="secondary"
            disabled={this.props.disabled}
            value={this.props.value.account_name}
            onChange={(value) => {
              this.props.onChange({ account_name: value });
            }}
          />
        </FormGroup>

        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlfor="account-key">Account Key / SAS token</Label>
          <PasswordControl
            id="account-key"
            className="tw-col-span-2"
            value={this.props.value.account_key}
            disabled={this.props.disabled}
            onChange={(e) => this.props.onChange({ account_key: e.target.value })}
          />
        </FormGroup>

        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="container-name">Container Name</Label>
          <TextInput
            id="container-name"
            variant="secondary"
            className="tw-col-span-2"
            value={this.props.value.container_name}
            disabled={this.props.disabled}
            onChange={(value) => {
              this.props.onChange({ container_name: value });
            }}
          />
        </FormGroup>
      </div>
    );
  },
});

export default Connection;
