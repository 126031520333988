import { cn, Icon } from '@keboola/design';

import type dayjs from '@/date';
import { DATE_FORMAT, TIME_FORMAT } from '@/date';
import { getNextSchedule } from '@/modules/scheduler/helpers';
import { displayTimezone } from '@/modules/scheduler/timezones';

type Props = {
  crontabRecord: string;
  timezone: string;
};

const CronSchedulerPreview = (props: Props) => {
  const next = getNextSchedule(props.crontabRecord, props.timezone);

  if (!next || !next.length) {
    return null;
  }

  return (
    <div className="cron-preview">
      <p className="text-muted f-12 font-medium">
        Schedule Preview (in {displayTimezone(props.timezone)} Time Zone)
      </p>
      <ul className="list-unstyled mb-0">
        {next.map((date: dayjs.Dayjs, index: number) => (
          <li
            key={index}
            className={cn({
              'mb-1': index !== next.length - 1,
            })}
          >
            <Icon icon={['far', 'calendar-check']} className="icon-addon-right text-muted f-16" />
            {date.format(DATE_FORMAT)} at {date.format(TIME_FORMAT)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CronSchedulerPreview;
