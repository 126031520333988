import { Alert } from '@keboola/design';

const RunUnsavedWarning = (props: { section: string; entity: string }) => {
  return (
    <Alert variant="warning" className="tw-mb-5">
      You have unsaved changes in the <strong className="tw-capitalize">{props.section}</strong>{' '}
      section! Running the {props.entity} will use the last saved version.
    </Alert>
  );
};

export default RunUnsavedWarning;
