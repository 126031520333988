import { cn } from '@keboola/design';

const TaskIcon = ({
  src,
  size = 40,
  className,
}: {
  src: string;
  size?: number;
  className?: string;
}) => {
  return (
    <img
      className={cn('task-icon pointer-events-none user-select-none', className)}
      src={src}
      alt="Task icon"
      width={size}
      height={size}
      loading="lazy"
    />
  );
};

export default TaskIcon;
