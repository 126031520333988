import {
  KEBOOLA_NO_CODE_DBT_TRANSFORMATION,
  KEBOOLA_R_TRANSFORMATION_V_2,
} from '@/constants/componentIds';
import { DBT_COMPONENTS } from '@/modules/transformations-v2/constants';

export const routeNames = {
  LINEAGE: 'lineage',
};

export const TableSubtype = {
  LINKED_FROM: 'linked-from',
  LINKED_TO: 'linked-to',
  TABLE: 'table',
} as const;

export const NOT_SUPPORTED_COMPONENTS = [
  KEBOOLA_NO_CODE_DBT_TRANSFORMATION,
  KEBOOLA_R_TRANSFORMATION_V_2,
  ...DBT_COMPONENTS,
];

export const ERRORS = {
  EMPTY: 204,
  NOT_FOUND: 404,
  UNKNOWN: 520,
} as const;

export type ErrorCode = (typeof ERRORS)[keyof typeof ERRORS];
