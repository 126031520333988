import type { GenericFetchClient } from '../storageClient';

import type { File, GetFilesQuery } from './types';

export const createFiles = (client: GenericFetchClient) => {
  const getFiles = async (query?: GetFilesQuery, signal?: AbortSignal) => {
    const { data } = await client.get<File[], never, GetFilesQuery>(
      '/files',
      { query },
      { signal },
    );

    return data;
  };

  return {
    getFiles,
  };
};
