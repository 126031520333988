import { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { ButtonGroup, FormGroup, HelpBlock, Label, TextInput } from '@keboola/design';

import { nameWarning } from '@/modules/storage/constants';
import string from '@/utils/string';
import FilePicker from './FilePicker';
import FileSearch from './FileSearch';
import LoadWorksheets from './LoadWorksheets';

class Row extends Component {
  render() {
    const { value, onChange, disabled, context } = this.props;

    return (
      <>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">Workbook Configuration</h2>
            <ButtonGroup>
              <FilePicker
                disabled={disabled}
                onSelect={({ name, driveId, fileId }) =>
                  onChange({ workbookName: name, driveId, fileId })
                }
              />
              <FileSearch
                disabled={disabled}
                configId={context.get('configurationId')}
                onSelect={({ name, driveId, fileId }) =>
                  onChange({ workbookName: name, driveId, fileId })
                }
              />
            </ButtonGroup>
          </div>
          <div className="box-content tw-flex tw-flex-col tw-gap-4">
            <FormGroup className="tw-grid tw-grid-cols-3 tw-items-baseline">
              <Label htmlFor="file-name">File Name</Label>
              <TextInput
                id="file-name"
                className="tw-col-span-2"
                variant="secondary"
                value={value.workbookName || ''}
                disabled={disabled}
                onChange={(workbookName) => onChange({ workbookName })}
              />
            </FormGroup>

            <FormGroup className="tw-grid tw-grid-cols-3 tw-items-baseline">
              <Label htmlFor="drive-id">Drive ID</Label>
              <TextInput
                id="drive-id"
                className="tw-col-span-2"
                variant="secondary"
                disabled={disabled}
                onChange={(driveId) => onChange({ driveId })}
                value={value.driveId || ''}
              />
            </FormGroup>

            <FormGroup className="tw-grid tw-grid-cols-3 tw-items-baseline">
              <Label htmlFor="file-id">File ID</Label>
              <TextInput
                id="file-id"
                className="tw-col-span-2"
                variant="secondary"
                disabled={disabled}
                value={value.fileId || ''}
                onChange={(fileId) => onChange({ fileId })}
              />
            </FormGroup>
          </div>
        </div>

        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">Worksheet Configuration</h2>
            <LoadWorksheets
              disabled={disabled}
              configId={context.get('configurationId')}
              fileId={value.fileId}
              driveId={value.driveId}
              onSelect={({ name, id }) => {
                onChange({
                  worksheetName: name,
                  name: value.name || string.sanitizeKbcTableIdString(name),
                  id,
                });
              }}
            />
          </div>
          <div className="box-content tw-flex tw-flex-col tw-gap-4">
            <FormGroup className="tw-grid tw-grid-cols-3 tw-items-baseline">
              <Label htmlFor="worksheet-name">Worksheet Name</Label>
              <TextInput
                id="worksheet-name"
                className="tw-col-span-2"
                variant="secondary"
                disabled={disabled}
                value={value.worksheetName || ''}
                onChange={(worksheetName) => onChange({ worksheetName })}
              />
            </FormGroup>

            <FormGroup className="tw-grid tw-grid-cols-3 tw-items-baseline">
              <Label htmlFor="worksheet-id">Worksheet ID</Label>

              <TextInput
                id="worksheet-id"
                className="tw-col-span-2"
                variant="secondary"
                disabled={disabled}
                value={value.id || ''}
                onChange={(id) => onChange({ id })}
              />
            </FormGroup>
          </div>
        </div>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">Destination</h2>
          </div>
          <div className="box-content">
            <FormGroup className="tw-grid tw-grid-cols-3 tw-items-baseline">
              <Label htmlFor="storage-table-name">Storage Table Name</Label>
              <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
                <TextInput
                  id="storage-table-name"
                  className="tw-col-span-2"
                  variant="secondary"
                  disabled={disabled}
                  value={value.name || ''}
                  onChange={(value) => {
                    onChange({ name: string.sanitizeKbcTableIdString(value) });
                  }}
                />

                <HelpBlock>
                  Table in Storage where the CSV file will be imported. If the table does not exist,
                  it will be created. {nameWarning}
                </HelpBlock>
              </div>
            </FormGroup>
          </div>
        </div>
      </>
    );
  }
}

Row.propTypes = {
  context: PropTypes.instanceOf(Map).isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Row;
