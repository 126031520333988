import { Component } from 'react';

import { Icon, Tooltip } from '@keboola/design';

class InlineTooltipWarningIcon extends Component<{ message: string }> {
  render() {
    return (
      <Tooltip tooltip={this.props.message} placement="top" type="explanatory">
        <Icon icon="triangle-exclamation" className="icon-addon-left inline text-warning" />
      </Tooltip>
    );
  }
}

export default InlineTooltipWarningIcon;
