import type { ComponentProps } from 'react';
import * as RSeparator from '@radix-ui/react-separator';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

const separatorVariants = cva(
  [
    // position
    'tw-flex',
    'tw-items-center',
    'tw-justify-center',

    // before
    'before:tw-content-[""]',
    'before:tw-bg-neutral-150',
  ],
  {
    defaultVariants: {
      orientation: 'vertical',
      size: 'full',
    },
    compoundVariants: [
      {
        orientation: 'horizontal',
        size: 'full',
        className: ['before:tw-w-full'],
      },
      {
        orientation: 'horizontal',
        size: '1/2',
        className: ['before:tw-w-1/2'],
      },
      {
        orientation: 'horizontal',
        size: '3/4',
        className: ['before:tw-w-3/4'],
      },
      {
        orientation: 'vertical',
        size: 'full',
        className: ['before:tw-h-full'],
      },
      {
        orientation: 'vertical',
        size: '1/2',
        className: ['before:tw-h-1/2'],
      },
      {
        orientation: 'vertical',
        size: '3/4',
        className: ['before:tw-h-3/4'],
      },
    ],
    variants: {
      size: {
        full: ['before:tw-h-full'],
        '1/2': [],
        '3/4': [],
      },
      orientation: {
        vertical: ['before:tw-w-px'],
        horizontal: ['before:tw-h-px'],
      },
    },
  },
);

type Props = VariantProps<typeof separatorVariants> &
  Omit<ComponentProps<typeof RSeparator.Root>, 'decorative'>;

export const Separator = ({
  className,
  orientation = 'vertical',
  size = 'full',
  ...props
}: Props) => (
  <RSeparator.Root
    {...props}
    decorative={true}
    orientation={orientation}
    className={separatorVariants({ size, orientation, className })}
  />
);
