import { Component } from 'react';
import later from '@breejs/later';
import type { List } from 'immutable';
import _ from 'underscore';

import { getScheduleFromCrontab } from '@/modules/scheduler/helpers';
import CronSchedulerInputs from './CronSchedulerInputs';
import CronSchedulerPreview from './CronSchedulerPreview';

type Props = {
  period: string;
  crontabRecord: string;
  onChange: (value: string, period?: string) => void;
  crontabTimezone?: string;
  showPreview?: boolean;
};

class CronScheduler extends Component<Props> {
  render() {
    return (
      <>
        {this.renderInputs()}
        {this.renderPreview()}
      </>
    );
  }

  renderInputs() {
    const schedule = getScheduleFromCrontab(this.props.crontabRecord);

    return (
      <CronSchedulerInputs
        period={this.props.period}
        M={schedule.M || []}
        D={schedule.D || []}
        d={schedule.d || []}
        h={schedule.h || []}
        m={schedule.m || []}
        onChange={this.handleChange}
        onPeriodChange={this.handlePeriodChange}
        timezone={this.props.crontabTimezone || 'UTC'}
      />
    );
  }

  renderPreview() {
    if (!this.props.showPreview) {
      return null;
    }

    return (
      <CronSchedulerPreview
        crontabRecord={this.props.crontabRecord}
        timezone={this.props.crontabTimezone || 'UTC'}
      />
    );
  }

  handleChange = (propName: string, newValue: List<any>) => {
    const schedule = getScheduleFromCrontab(this.props.crontabRecord);

    if (newValue.count() > 0) {
      schedule[propName] = _.unique(newValue.toArray());
    } else {
      delete schedule[propName];
    }
    return this.props.onChange(this.scheduleToCron(schedule));
  };

  handlePeriodChange = (value: later.TimePeriod['name']) => {
    // change current schedule
    const schedule = getScheduleFromCrontab(this.props.crontabRecord);
    let toDelete: string[] = [];

    switch (value) {
      case later.hour.name:
        toDelete = ['M', 'D', 'h', 'd'];
        break;

      case later.day.name:
        toDelete = ['M', 'D', 'd'];
        break;

      case later.dayOfWeek.name:
        toDelete = ['M', 'D'];
        break;

      case later.month.name:
        toDelete = ['M', 'd'];
        break;

      default:
        break;
    }

    toDelete.forEach((key) => delete schedule[key]);

    this.props.onChange(this.scheduleToCron(schedule), value);
  };

  scheduleToCron = (schedule: later.Recurrence) => {
    const flatten = (
      part:
        | later.Recurrence['m']
        | later.Recurrence['h']
        | later.Recurrence['D']
        | later.Recurrence['M'],
    ) => {
      if (!part) {
        return '*';
      } else {
        return part.sort().join(',');
      }
    };

    const flattenDayOfWeek = (part: later.Recurrence['d']) => {
      if (!part) {
        return '*';
      } else {
        return part.map((value) => value - 1).join(',');
      }
    };

    const parts = [];
    parts.push(flatten(schedule.m));
    parts.push(flatten(schedule.h));
    parts.push(flatten(schedule.D));
    parts.push(flatten(schedule.M));
    parts.push(flattenDayOfWeek(schedule.d));

    return parts.join(' ');
  };
}

export default CronScheduler;
