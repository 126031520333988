import { Icon } from '@keboola/design';

import { ioType } from '@/modules/components/Constants';

const InputOutputTypeIcon = ({ type }: { type: (typeof ioType)[keyof typeof ioType] }) => {
  if (type === ioType.FILE) {
    return (
      <>
        <Icon className="icon-addon-right text-muted f-16" icon="file-lines" title="File" />
        &#65279;{/*zero width no-break space char to prevent direct text wrap behind the icon*/}
      </>
    );
  }
  return (
    <>
      <Icon className="icon-addon-right text-muted f-16" icon="table" title="Table" />
      &#65279;{/*zero width no-break space char to prevent direct text wrap behind the icon*/}
    </>
  );
};

export default InputOutputTypeIcon;
