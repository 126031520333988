import type { ReactNode } from 'react';

import { FILTERS, FILTERS_GROUP } from '@/constants';

type Props = {
  bucketsCount: number;
  renderFilterButton: (type: string, group: string, label: string) => ReactNode;
};

export const FilterButtons = ({ bucketsCount, renderFilterButton }: Props) => {
  if (bucketsCount === 0) return null;

  return (
    <div className="predefined-search-list tw-flex-nowrap">
      {renderFilterButton(FILTERS.ALL, FILTERS.ALL, 'ALL')}
      <span className="group-separator" />
      {renderFilterButton(FILTERS.LINKED, FILTERS_GROUP.SHARING, 'LINKED')}
      <span className="group-separator" />
      {renderFilterButton(FILTERS.MINE, FILTERS_GROUP.OWNERSHIP, 'MINE')}
    </div>
  );
};
