import { useState } from 'react';
import type { List } from 'immutable';

import MetadataActionCreators from '@/modules/components/MetadataActionCreators';
import type { ObjectTypes } from '@/modules/components/MetadataConstants';
import { getDescriptionValue } from '@/modules/storage/helpers';
import DescriptionBox from '@/react/common/DescriptionBox/DescriptionBox';
import string from '@/utils/string';
import DevBranchStorageWarning from './DevBranchStorageWarning';

type Props = {
  objectType: (typeof ObjectTypes)[keyof typeof ObjectTypes];
  objectId: string;
  metadata: List<any>;
  metadataKey: string;
  fallbackMetadataKey?: string;
  readOnly: boolean;
  isDevBucket?: boolean;
};

const StorageDescription = (props: Props) => {
  const [editorValue, setEditorValue] = useState<string | null>(null);
  const [description, setDescription] = useState(
    getDescriptionValue(props.metadata, props.metadataKey) ||
      (props.fallbackMetadataKey
        ? getDescriptionValue(props.metadata, props.fallbackMetadataKey)
        : null),
  );

  const handleEditSubmit = () => {
    return MetadataActionCreators.saveMetadata(
      props.objectType,
      props.objectId,
      props.metadataKey,
      editorValue?.trim(),
    ).then(() => {
      setDescription(editorValue);
    });
  };

  return (
    <DescriptionBox
      editorValue={editorValue}
      description={description}
      onSetEditorValue={(newEditorValue) => setEditorValue(newEditorValue)}
      onSave={handleEditSubmit}
      readOnly={props.readOnly}
      placeholder={`${string.capitalize(props.objectType)} Description`}
      warning={
        <DevBranchStorageWarning
          message="The description will also change in production."
          hasProductionEntity={!props.isDevBucket}
        />
      }
    />
  );
};

export default StorageDescription;
