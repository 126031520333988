import { useState } from 'react';
import type { Map } from 'immutable';

import { Button, Icon } from '@keboola/design';

import { NEW_ENTITY_BUTTON } from '@/constants/external';
import SandboxesActions from '@/modules/sandboxes/Actions';
import AddSandboxModal from './AddSandboxModal/AddSandboxModal';

type Props = {
  allowedComponents: Map<string, any>;
  hasPayAsYouGo: boolean;
};

const AddSandboxButton = ({ allowedComponents, hasPayAsYouGo }: Props) => {
  const [openModal, setOpenModal] = useState(false);

  const handleSubmit = (
    name: string,
    type: string,
    options: Map<string, any>,
    params: Map<string, any>,
    description?: string,
  ) => {
    return SandboxesActions.createSandbox({ name, description }, type, options, params);
  };

  return (
    <>
      <Button className={NEW_ENTITY_BUTTON} onClick={() => setOpenModal(true)}>
        <Icon icon="plus" />
        Create Workspace
      </Button>
      <AddSandboxModal
        simple
        show={openModal}
        onHide={() => setOpenModal(false)}
        allowedComponents={allowedComponents}
        hasPayAsYouGo={hasPayAsYouGo}
        onSubmit={handleSubmit}
      />
    </>
  );
};

export default AddSandboxButton;
