import { Button, Modal } from 'react-bootstrap';

import ModalIcon from '@/react/common/ModalIcon';
import RoutesStore from '@/stores/RoutesStore';

type Props = { location: Record<string, any> };

const WelcomeModal = ({ location }: Props) => {
  const handleHide = () => {
    const query = { ...location?.query };
    delete query.welcome;

    RoutesStore.getRouter().replaceTo(location.pathname, null, query, location.hash);
  };

  return (
    <Modal show onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title>Welcome to Keboola</Modal.Title>
        <ModalIcon icon="party-horn" />
      </Modal.Header>
      <Modal.Body>
        <p>
          Your Google BigQuery backend is now successfully connected to your Keboola project!
          You&apos;ve unlocked a powerful suite of capabilities that will transform the way you work
          with your data. 🚀
        </p>

        <p>
          We&apos;re here to support you every step of the way. If you have any questions, need
          assistance, or want to explore more ways to make the most out of your Keboola and BigQuery
          setup, don&apos;t hesitate to reach out.
        </p>

        <Button bsStyle="success" block className="tw-mt-6" onClick={handleHide}>
          Get started
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default WelcomeModal;
