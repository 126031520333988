import { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import _ from 'underscore';

import { Alert, ButtonGroup, ClipboardButton, cn } from '@keboola/design';

import { KEBOOLA_SNOWFLAKE_TRANSFORMATION } from '@/constants/componentIds.js';
import { NEW_EDITOR_OPEN } from '@/constants/customEvents';
import { CODE_EDITOR_MODE } from '@/constants/localStorageKeys';
import { SnowflakeSqlTableExplainTooltip } from '@/modules/components/react/components/generic/SnowflakeSqlTableExplainTooltip';
import { getItem, setItem } from '@/utils/localStorage';
import nextTick from '@/utils/nextTick';
import CatchUnsavedChanges from './CatchUnsavedChanges';
import CatchUnsavedChangesModal from './CatchUnsavedChangesModal';
import CodeEditor from './CodeEditor';
import CodeEditorCloseButton from './CodeEditorCloseButton';
import CodeEditorExpandButton from './CodeEditorExpandButton';
import { CODE_EDITOR_MODAL_MODES as MODES } from './constants';
import FullScreenModal from './FullScreenModal';

class CodeEditorModal extends Component {
  state = {
    show: true,
    showConfirm: false,
    mode: getItem(CODE_EDITOR_MODE, MODES.HALF_SCREEN),
  };

  componentDidMount() {
    document.dispatchEvent(
      new CustomEvent(NEW_EDITOR_OPEN, { detail: { closeNewEditor: this.props.onClose } }),
    );
    document.addEventListener(NEW_EDITOR_OPEN, this.onHide);
  }

  componentWillUnmount() {
    document.removeEventListener(NEW_EDITOR_OPEN, this.onHide);
  }

  render() {
    const isHalfScreen = this.state.mode === MODES.HALF_SCREEN;
    const isSnowflakeTransformation = this.props.componentId === KEBOOLA_SNOWFLAKE_TRANSFORMATION;

    return (
      <>
        <CSSTransition appear timeout={300} in={this.state.show} classNames="slide-up">
          <FullScreenModal
            onHide={this.onHide}
            hideOverflow={!isHalfScreen}
            className={cn('full-screen-editor CodeMirror-search-dialog-root', {
              'half-screen': isHalfScreen,
            })}
          >
            <Modal.Header>
              <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-x-8 tw-gap-y-2 md-legacy:tw-flex-nowrap">
                <Modal.Title className="tw-flex !tw-max-w-full tw-items-center md-legacy:!tw-max-w-[70%]">
                  {this.props.title}
                </Modal.Title>
                <div className="CodeMirror-search-dialog-wrapper tw-ml-auto tw-inline-flex tw-gap-4">
                  <ButtonGroup>
                    <ClipboardButton
                      label="Copy Code"
                      text={this.props.value}
                      tooltipText=""
                      tooltipPlacement="left"
                    />
                    <CodeEditorExpandButton
                      isCollapsed={isHalfScreen}
                      onClick={() => {
                        this.setMode(isHalfScreen ? MODES.FULL_SCREEN : MODES.HALF_SCREEN);
                      }}
                    />
                    {this.props.renderAdditionalButtons?.()}
                    <CodeEditorCloseButton onClick={this.onHide} />
                  </ButtonGroup>
                </div>
              </div>
              {this.props.warning && (
                <Alert variant="warning" className="tw-mt-3.5">
                  {this.props.warning}
                </Alert>
              )}
            </Modal.Header>
            <Modal.Body>
              <CatchUnsavedChanges
                isDirty={!!this.props.isChanged}
                onSave={this.props.onSave ?? _.noop}
                onDirtyLeave={this.props.onReset}
              >
                <CodeEditor
                  withToggleComment
                  withSearch={!this.props.withoutSearch}
                  withAutocomplete={!!this.props.withAutocomplete}
                  key={this.props.editorKey}
                  editorDidMount={this.editorDidMount}
                  value={this.props.value}
                  onChange={this.props.onChange}
                  options={this.getCodemirrorOptions()}
                  helpPostfix={
                    isSnowflakeTransformation ? (
                      <>
                        <div className="tw-mx-2 tw-text-[10px] tw-text-neutral-200">|</div>
                        <SnowflakeSqlTableExplainTooltip />
                      </>
                    ) : null
                  }
                />
              </CatchUnsavedChanges>
            </Modal.Body>
          </FullScreenModal>
        </CSSTransition>
        <CatchUnsavedChangesModal
          show={this.state.showConfirm}
          onHide={() => this.setState({ showConfirm: false })}
          onLeave={this.handleCloseModal}
          onSave={() => this.props.onSave?.().then(this.handleCloseModal)}
          text="You have unsaved changes! If you close the editor, your unsaved changes will be discarded and your work will be lost."
          leaveLabel="Close without saving"
        />
      </>
    );
  }

  getCodemirrorOptions = () => {
    const options = {
      extraKeys: {
        ...(this.props.onSave && {
          'Ctrl-Enter': this.props.onSave,
          'Cmd-Enter': this.props.onSave,
          'Shift-Ctrl-Enter': () => this.props.onSave().then(this.handleCloseModal),
          'Shift-Cmd-Enter': () => this.props.onSave().then(this.handleCloseModal),
        }),
        'Ctrl-/': 'toggleComment',
        'Cmd-/': 'toggleComment',
        'Ctrl-Space': 'autocomplete',
        'Alt-Space': 'autocomplete',
      },
      ...(this.props.codeMirrorOptions || {}),
    };

    return options;
  };

  editorDidMount = (editor) => {
    if (this.props.value) {
      nextTick(() => editor.refresh());
    }

    editor.focus();
  };

  onHide = (event) => {
    if (
      !this.state.showConfirm &&
      this.props.isChanged &&
      !document.body.classList.contains('modal-open')
    ) {
      event?.detail?.closeNewEditor();
      this.setState({ showConfirm: true });
    } else if (!this.props.isChanged) {
      this.handleCloseModal();
    }
  };

  setMode = (mode) => {
    this.setState({ mode });
    setItem(CODE_EDITOR_MODE, mode);
  };

  handleCloseModal = () => {
    if (this.state.mode === MODES.FULL_SCREEN) {
      return this.props.onClose();
    }

    this.setState({ show: false }, () => {
      setTimeout(this.props.onClose, 300);
    });
  };
}

CodeEditorModal.propTypes = {
  title: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  onReset: PropTypes.func,
  isChanged: PropTypes.bool,
  renderAdditionalButtons: PropTypes.func,
  withAutocomplete: PropTypes.bool,
  withoutSearch: PropTypes.bool,
  value: PropTypes.string,
  editorKey: PropTypes.string,
  codeMirrorOptions: PropTypes.object,
  warning: PropTypes.string,
};

export default CodeEditorModal;
