import type { UseQueryResult } from '@tanstack/react-query';

import type { Feature, Project } from '@keboola/api-client';

import {
  BETA_FEATURES,
  DISABLE_AUTOSAVING_IN_FLOWS,
  FEATURE_AI_COMPONENT_SUGGESTION,
  FEATURE_EXTERNAL_BUCKETS,
  FEATURE_HAS_FLOWS_ONLY,
  FEATURE_NEW_NATIVE_TYPES,
  FEATURE_OVERRIDES,
  FEATURE_POWER_USER,
  FEATURE_SANDBOXES_DATA_APP,
  FEATURE_SNOWPARK_PYTHON,
  type FeatureOverrides,
} from '@/constants/features';
import SettingsStore from '@/modules/settings/SettingsStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import ApplicationStore from '@/stores/ApplicationStore';
import { matchByWords } from '@/utils';
import string from '@/utils/string';
import type { FeatureFilter, FeatureFilterType, FeFeature } from './types';

export const FEATURE_FILTER_TYPE = {
  ALL: 'all',
  ENABLED: 'enabled',
  DISABLED: 'disabled',
  BETA: 'beta',
} as const;

const createFeFeatureMapper =
  (options: { beta: string[]; overrides: FeatureOverrides; active: string[] }) =>
  (feature: Feature): FeFeature => {
    const override = options.overrides[feature.name];

    const humanizedTitle =
      feature.title !== feature.name
        ? feature.title
        : string.capitalize(feature.name.replaceAll('-', ' '));

    const title = override?.title ?? humanizedTitle;
    const description = override?.description ?? feature.description;
    const isReversed = override?.reverse ?? false;

    const isEnabled = options.active.includes(feature.name);
    const isActive = isReversed ? !isEnabled : isEnabled;
    const isBeta = options.beta.includes(feature.name);

    return {
      id: feature.id,
      name: feature.name,
      type: feature.type,
      title,
      description,
      isBeta,
      isReversed,
      isActive,
    };
  };

const isProjectFeatureApproved = (featureName: string) => {
  if (!ApplicationStore.hasNewQueue()) return false;

  const validator: Record<string, boolean> = {
    [FEATURE_HAS_FLOWS_ONLY]: true,
    [FEATURE_NEW_NATIVE_TYPES]: true,
    [FEATURE_SNOWPARK_PYTHON]: ApplicationStore.hasReadOnlyStorage(),
    [FEATURE_SANDBOXES_DATA_APP]: !ApplicationStore.hasProtectedDefaultBranch(), // Data Apps are not available for SOX projects
    [FEATURE_EXTERNAL_BUCKETS]: !SettingsStore.hasProjectOwnBackend(),
    [FEATURE_AI_COMPONENT_SUGGESTION]: StackFeaturesStore.hasAllowedAi(),
  };

  return validator[featureName] ?? false;
};

export const isFeatureApproved = (feature: FeFeature) =>
  feature.type === 'project'
    ? isProjectFeatureApproved(feature.name)
    : [DISABLE_AUTOSAVING_IN_FLOWS, FEATURE_POWER_USER].includes(feature.name);

export const isFeatureFiltered = (feature: FeFeature, filter: FeatureFilter) => {
  const filterTypeMap: Record<FeatureFilterType, boolean> = {
    all: true,
    beta: feature.isBeta,
    enabled: feature.isActive,
    disabled: !feature.isActive,
  };

  const textMatching =
    matchByWords(feature.title, filter.query) || matchByWords(feature.description, filter.query);

  return filterTypeMap[filter.type ?? 'all'] && textMatching;
};

export const combineProjectFeaturesQueries = ([projectQuery, allFeaturesQuery]: [
  UseQueryResult<Project>,
  UseQueryResult<Feature[]>,
]) => {
  const activeFeatures = projectQuery.data?.features ?? [];
  const allFeatures = allFeaturesQuery.data ?? [];

  return {
    isPending: projectQuery.isPending || allFeaturesQuery.isPending,
    isError: projectQuery.isError || allFeaturesQuery.isError,
    data: allFeatures
      .map(
        createFeFeatureMapper({
          beta: BETA_FEATURES,
          overrides: FEATURE_OVERRIDES,
          active: activeFeatures,
        }),
      )
      .toSorted((a, b) => a.title.localeCompare(b.title)),
  };
};

export const selectAdminFeatures = (data: Feature[], active: string[]) => {
  return data
    .map(
      createFeFeatureMapper({
        beta: BETA_FEATURES,
        overrides: FEATURE_OVERRIDES,
        active: active,
      }),
    )
    .toSorted((a, b) => a.title.localeCompare(b.title));
};
