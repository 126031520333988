import type { Map } from 'immutable';

import { componentTypes } from '@/constants/componentTypes';
import {
  getComponentTypeColorClassName,
  getNewComponentTypeLabel,
} from '@/modules/components/helpers';

const VALID_TYPES = Object.values(componentTypes);

type Props = {
  component: Map<string, any>;
};

const ComponentType = ({ component }: Props) => {
  if (!VALID_TYPES.includes(component.get('type'))) {
    return '';
  }

  return (
    <span className={getComponentTypeColorClassName(component.get('type'))}>
      {getNewComponentTypeLabel(component.get('type'))}
    </span>
  );
};

export default ComponentType;
