import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import * as componentFlags from '@/constants/componentFlags';
import { componentTypes } from '@/constants/componentTypes';
import { SHOW_CHILD_JOBS } from '@/constants/localStorageKeys';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { mergeSampleDataToConfigurations } from '@/modules/components-directory/helpers';
import NotificationsStore from '@/modules/notifications/store';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { setItem } from '@/utils/localStorage';
import JobsSearch from './components/JobsSearch';
import JobsTable from './components/JobsTable';
import { routeNames } from './constants';
import { filterLatestJobs, parseJobsQuery } from './helpers';
import JobsStore from './store';

const Index = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      JobsStore,
      RoutesStore,
      ComponentsStore,
      NotificationsStore,
      InstalledComponentsStore,
    ),
  ],

  getStateFromStores() {
    const jobs = JobsStore.getAll();
    const queryRow = RoutesStore.getRouterState().getIn(['location', 'query', 'row']);

    return {
      allJobs: jobs,
      jobsParams: JobsStore.getParams(),
      isLoading: JobsStore.getIsLoading(),
      jobs: !!queryRow ? filterLatestJobs(jobs, queryRow) : jobs,
      isLoadMore: JobsStore.getIsLoadMore(),
      admins: ApplicationStore.getAdmins(),
      notifications: NotificationsStore.getAll(),
      sapiToken: ApplicationStore.getSapiToken(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      installedComponents: mergeSampleDataToConfigurations(
        InstalledComponentsStore.getAll().filter(
          (component) => !component.get('flags').includes(componentFlags.EXCLUDE_RUN),
        ),
        ComponentsStore.getAll(),
      ),
      readOnly: ApplicationStore.isReadOnly(),
    };
  },

  render() {
    return (
      <>
        <JobsSearch
          query={parseJobsQuery(this.state.jobsParams)}
          changeQuery={this.changeQuery}
          admins={this.state.admins}
          installedComponents={this.state.installedComponents}
          currentUserEmail={this.state.currentAdmin.get('email', '')}
          readOnly={this.state.readOnly}
        />
        <JobsTable
          jobs={this.state.jobs}
          admins={this.state.admins}
          sapiToken={this.state.sapiToken}
          currentAdmin={this.state.currentAdmin}
          isLoading={this.state.isLoading}
          isLoadMore={this.state.isLoadMore}
          notifications={this.state.notifications}
          query={this.state.jobsParams}
          changeQuery={this.changeQuery}
          offset={this.state.allJobs.count()}
        />
      </>
    );
  },

  changeQuery(key, value) {
    let params = Map();

    if (key !== 'runId' || !value) {
      params = this.state.jobsParams.delete('runId');
    }

    if (key === 'timeRange') {
      params = value?.end ? params.set('endTimeTo', value.end) : params.delete('endTimeTo');
      key = 'createdTimeFrom';
      value = value?.start;
    }

    if (key === 'duration') {
      params = value?.end
        ? params.set('durationSecondsTo', value.end)
        : params.delete('durationSecondsTo');
      key = 'durationSecondsFrom';
      value = value?.start;
    }

    if (key === 'user') {
      key = 'tokenDescription';
    }

    if (key === 'component') {
      params = params.delete('componentType').delete('component').delete('config').delete('row');

      if (value && [...Object.values(componentTypes), 'other'].includes(value)) {
        key = 'componentType';
      }
    }

    if (key === 'config') {
      params = params.delete('config').delete('row');
    }

    if (key === 'excludeChildJobs') {
      params = value ? params.set('parentRunId', '') : params.delete('parentRunId');
      setItem(SHOW_CHILD_JOBS, !value);
    } else if (!!value && key === 'config') {
      if (value.includes(':')) {
        const [componentId, configId] = value.split(':');
        params = params.set('component', componentId).set('config', configId);
      } else {
        params = params.set('config', value);
      }
    } else {
      params = value ? params.set(key, value) : params.delete(key);
    }

    RoutesStore.getRouter().replaceTo(routeNames.JOBS, null, params.toJS());
  },
});

export default Index;
