import { Component } from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { List, Map } from 'immutable';
import _ from 'underscore';

import { Alert } from '@keboola/design';

import keyCodes from '@/constants/keyCodes';
import { canManageSharedBucket } from '@/modules/admin/privileges';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import DataCatalogActions from '@/modules/data-catalog/actions';
import { SHARED_TYPES } from '@/modules/data-catalog/constants';
import { couldBeProjectRemovedFromBucketSharingSettings } from '@/modules/data-catalog/helpers';
import { BucketColorSelect } from '@/modules/data-catalog/react/BucketColorSelect';
import { DataShareOwnerSelect } from '@/modules/data-catalog/react/DataShareOwnerSelect';
import ShareWithSelect from '@/modules/data-catalog/react/ShareWithSelect';
import { getDescriptionValue } from '@/modules/storage/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import { findAdminByUser, getFormattedAdminsForSelect } from '@/utils/getFormattedAdmins';

export class EditSharingModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      sharedType: '',
      targetUsers: List(),
      targetProjects: List(),
      color: void 0,
      ownerId: void 0,
      description: '',
      current: {
        sharedType: '',
        targetUsers: List(),
        targetProjects: List(),
        color: void 0,
        ownerId: void 0,
        description: '',
      },
    };

    this.onSave = this.onSave.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onEnter = this.onEnter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static defaultProps = {
    disableAnimation: false,
  };

  getLinkedProjectIds() {
    return this.props.bucket
      .get('linkedBy', List())
      .toJS()
      .map((item) => Number(item?.project?.id));
  }

  hasSharedAndLinkedProjects() {
    return (
      _.intersection(this.getLinkedProjectIds(), this.state.current.targetProjects.toJS()).length >
      0
    );
  }

  isProjectLinked(id) {
    return this.getLinkedProjectIds().includes(id);
  }

  renderWarning() {
    if (this.hasSharedAndLinkedProjects()) {
      return (
        <Alert variant="warning" className="tw-mb-5">
          There are{' '}
          <strong className="tw-font-medium">projects which have linked the bucket (green)</strong>.
          Deselecting the project won&apos;t unlink it and the consumer will still see it. If you
          deselect project that has linked bucket, you may unlink it as well in the next step.
        </Alert>
      );
    }

    if (this.isUsed()) {
      if (this.state.current.sharedType === SHARED_TYPES.ORGANIZATION_MEMBER) {
        return (
          <Alert variant="warning" className="tw-mb-5">
            The bucket is shared with all users in the organization. There is at least one project
            where the bucket is linked and therefore you cannot change the group. If you want to
            edit the sharing option, unlink the bucket first.
          </Alert>
        );
      }

      return (
        <Alert variant="warning" className="tw-mb-5">
          There are projects which have linked the bucket. You cannot change the group with which
          the bucket is shared.
          {[SHARED_TYPES.SELECTED_PEOPLE, SHARED_TYPES.SELECTED_PROJECT].includes(
            this.props.bucket.get('sharing'),
          ) && (
            <> But you can change the current sharing settings (e.g.,add more projects or users).</>
          )}
        </Alert>
      );
    }
  }

  render() {
    if (!canManageSharedBucket(this.props.sapiToken)) {
      return null;
    }

    return (
      <span
        className="tw-hidden"
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.key === keyCodes.ENTER && e.stopPropagation()}
      >
        <Modal
          animation={!this.props.disableAnimation}
          show={this.props.show}
          onHide={this.onHide}
          onEnter={this.onEnter}
        >
          <form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Sharing {this.props.bucket.get('displayName')}</Modal.Title>
              <ModalIcon.Edit />
            </Modal.Header>
            <Modal.Body>
              {this.state.error && (
                <Alert variant="error" className="tw-mb-5">
                  {this.state.error}
                </Alert>
              )}
              {this.renderWarning()}

              <ShareWithSelect
                value={this.state.sharedType}
                onChange={(type) => this.setState({ sharedType: type })}
                disabled={this.isUsed() || this.state.isLoading}
                hasUsersOptions={this.props.availableUsersOptions.length > 0}
                hasProjectsOptions={this.props.availableProjectsOptions.length > 0}
              />

              {this.renderAdditionalControls(this.isUsed())}

              <FormGroup controlId="formDescriptionConrol">
                <ControlLabel>Description</ControlLabel>
                <FormControl
                  rows={3}
                  componentClass="textarea"
                  value={this.state.description}
                  onChange={(e) => this.setState({ description: e.target.value })}
                  disabled={this.state.isLoading}
                />
              </FormGroup>

              <BucketColorSelect
                value={this.state.color}
                onChange={(color) => this.setState({ color })}
                disabled={this.state.isLoading}
              />

              <DataShareOwnerSelect
                options={getFormattedAdminsForSelect()}
                value={this.state.ownerId}
                onChange={(ownerId) => this.setState({ ownerId })}
                disabled={this.state.isLoading}
              />
            </Modal.Body>
            <Modal.Footer>
              <ConfirmButtons
                block
                saveButtonType="submit"
                saveLabel={this.state.isLoading ? 'Saving changes...' : 'Save changes'}
                isSaving={this.state.isLoading}
                isDisabled={this.isDisabled()}
              />
            </Modal.Footer>
          </form>
        </Modal>
      </span>
    );
  }

  isUsed() {
    return this.props.bucket.get('linkedBy', List()).count() > 0;
  }

  onEnter() {
    const sharedType = this.props.bucket.get('sharing');
    const targetUsers = this.props.bucket
      .getIn(['sharingParameters', 'users'], List())
      .map((option) => option.get('id'));
    const targetProjects = this.props.bucket
      .getIn(['sharingParameters', 'projects'], List())
      .map((option) => option.get('id'));

    const description = getDescriptionValue(
      this.props.bucket.get('metadata', List()),
      MetadataKeys.SHARED_DESCRIPTION,
    );

    const ownerPrev = this.props.bucket?.get('owner');
    const owner = findAdminByUser(ownerPrev);
    const ownerId = owner?.get('id') ?? this.state.current.ownerId;

    const color = this.props.bucket?.get('color') || void 0;

    this.setState({
      sharedType,
      targetUsers,
      targetProjects,
      description,
      ownerId,
      color,
      current: {
        sharedType,
        targetUsers,
        targetProjects,
        description,
        ownerId,
        color,
      },
    });
  }

  renderAdditionalControls(isUsed) {
    if (this.state.sharedType === SHARED_TYPES.SELECTED_PEOPLE) {
      const availableOptions = this.props.availableUsersOptions.map((option) => {
        return { clearableValue: !isUsed, ...option };
      });

      const deletedUsers = this.props.bucket
        .getIn(['sharingParameters', 'users'], List())
        .filter((user) => !availableOptions.some((option) => option.value === user.get('id')))
        .map((user) => ({
          value: user.get('id'),
          label: `${user.get('name', '').trim() || user.get('email')} (Deleted)`,
        }))
        .toArray();

      return (
        <FormGroup controlId="form-shared-with-selected-people">
          <ControlLabel>Users</ControlLabel>
          <Select
            multi
            clearable={!isUsed}
            placeholder="Select users"
            options={[...availableOptions, ...deletedUsers]}
            value={this.sortDeletedToBottom(this.state.targetUsers, deletedUsers)}
            onChange={(targetUsers) => this.setState({ targetUsers })}
            classNames={{ multiValue: this.handleMultiValueClassName }}
          />
        </FormGroup>
      );
    }

    if (this.state.sharedType === SHARED_TYPES.SELECTED_PROJECT) {
      const availableOptions = this.props.availableProjectsOptions.map((option) => {
        return {
          clearableValue: couldBeProjectRemovedFromBucketSharingSettings(
            this.props.bucket,
            option.value,
          ),
          ...option,
        };
      });

      const deletedProjects = this.props.bucket
        .getIn(['sharingParameters', 'projects'], List())
        .filter((project) => !availableOptions.some((option) => option.value === project.get('id')))
        .map((project) => ({
          value: project.get('id'),
          label: `${project.get('name')} (Deleted)`,
        }))
        .toArray();

      return (
        <FormGroup controlId="form-shared-with-selected-projects">
          <ControlLabel>Projects</ControlLabel>
          <Select
            multi
            clearable={!isUsed}
            placeholder="Select projects"
            options={[...availableOptions, ...deletedProjects]}
            value={this.sortDeletedToBottom(this.state.targetProjects, deletedProjects)}
            onChange={(targetProjects) => this.setState({ targetProjects })}
            classNames={{ multiValue: this.handleMultiValueClassName }}
          />
        </FormGroup>
      );
    }

    return null;
  }

  handleMultiValueClassName = (data) => {
    if (data.data?.label?.endsWith('(Deleted)') && !this.isProjectLinked(data.data?.value)) {
      return '!tw-bg-neutral-300 [&_div[role="button"]:hover]:!tw-bg-neutral-400';
    }

    if (this.isProjectLinked(data.data?.value)) {
      return '!tw-bg-primary-500 [&_div[role="button"]:hover]:!tw-bg-primary-700';
    }

    return '';
  };

  sortDeletedToBottom = (selected, deleted) => {
    return selected.sort((valueA, valueB) => {
      if (deleted.some((item) => item.value === valueA)) {
        return 1;
      }

      if (deleted.some((item) => item.value === valueB)) {
        return -1;
      }

      return 0;
    });
  };

  handleSubmit(e) {
    e.preventDefault();

    const params = {
      targetProjectIds: this.state.targetProjects.toJS(),
      targetUsers: this.state.targetUsers.toJS(),
    };

    this.setState({ isLoading: true });
    DataCatalogActions.shareBucketSimple({
      bucketId: this.props.bucket.get('id'),
      sharedType: this.state.sharedType,
      description: this.state.description,
      ownerId: this.state.ownerId,
      color: this.state.color,
      params,
    })
      .then(() => {
        this.onSave();
        this.onHide();
      })
      .catch((error) => {
        this.setState({ error, isLoading: false });
        return null;
      });
  }

  isDisabled() {
    if (this.state.ownerId && this.state.ownerId !== this.state.current.ownerId) return false;
    if (this.state.description && this.state.description !== this.state.current.description)
      return false;
    if (this.state.color && this.state.color !== this.state.current.color) return false;

    if (
      (this.state.sharedType === SHARED_TYPES.ORGANIZATION_MEMBER &&
        this.props.bucket.get('sharing') === SHARED_TYPES.ORGANIZATION_MEMBER) ||
      (this.state.sharedType === SHARED_TYPES.PROJECT_MEMBERS &&
        this.props.bucket.get('sharing') === SHARED_TYPES.PROJECT_MEMBERS)
    ) {
      return true;
    }

    if (this.state.sharedType === SHARED_TYPES.SELECTED_PEOPLE) {
      return (
        !this.state.targetUsers.count() ||
        this.state.current.targetUsers.equals(this.state.targetUsers)
      );
    }

    if (this.state.sharedType === SHARED_TYPES.SELECTED_PROJECT) {
      return (
        !this.state.targetProjects.count() ||
        this.state.current.targetProjects.equals(this.state.targetProjects)
      );
    }

    return false;
  }

  onSave() {
    if (this.props.onSaveFn) {
      const savedProjects = this.state.current.targetProjects.toJS();
      const newProjects = this.state.targetProjects.toJS();
      const hasUserRemovedProjects = savedProjects.length > newProjects.length;
      const hasUserRemovedLinkedProject = _.difference(savedProjects, newProjects).some((id) =>
        this.isProjectLinked(id),
      );

      this.props.onSaveFn(hasUserRemovedProjects && hasUserRemovedLinkedProject);
    }
  }

  onHide() {
    this.setState({ isLoading: false, error: null }, () => {
      this.props.closeModalFn();
    });
  }
}

EditSharingModal.propTypes = {
  bucket: PropTypes.instanceOf(Map).isRequired,
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  availableUsersOptions: PropTypes.array.isRequired,
  availableProjectsOptions: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  closeModalFn: PropTypes.func.isRequired,
};
