import { CopyWorkspaceIcon } from '@/react/common/icons';
import { TableIconAction } from './TableIconAction';

type Props = {
  onClick: () => void;
  tooltip?: string | null;
  disabled?: boolean;
};

export const CopyToWorkspace = ({ onClick, tooltip = null, disabled = false }: Props) => (
  <TableIconAction
    tooltip={tooltip || 'Copy to an existing workspace'}
    onClick={onClick}
    disabled={disabled}
    icon={<CopyWorkspaceIcon />}
  />
);
