import { memo } from 'react';
import type Promise from 'bluebird';
import { List, Map } from 'immutable';

import { cn, Tooltip } from '@keboola/design';
import * as Flow from '@keboola/flow-builder';

import { componentTypes } from '@/constants/componentTypes';
import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import { resolveComponentId } from '@/modules/flows/helpers';
import ContinueOnFailureIcon from '@/modules/queue/components/ContinueOnFailureIcon';
import { JOB_FAILED_STATUSES, JOBS_STATUS, routeNames } from '@/modules/queue/constants';
import { CircleIcon } from '@/react/common/CircleIcon';
import RoutesStore from '@/stores/RoutesStore';
import { windowOpen } from '@/utils/windowOpen';
import TaskDetailModal from './TaskDetailModal/TaskDetailModal';
import SpecificRowsIcon from './SpecificRowsIcon';
import Spinner from './Spinner';
import Status from './Status';
import TaskActions from './TaskActions';
import TaskIcon from './TaskIcon';
import TaskName from './TaskName';

type Props = {
  task: Map<string, any>;
  phase: Map<string, any>;
  tablesMetadataMap: Map<string, any>;
  allConfigurations: Map<string, any>;
  allComponents: Map<string, any>;
  taskStatus?: Map<string, any>;
  isRunning: boolean;
  readOnly: boolean;
  hasPayAsYouGo: boolean;
  onSelect: (taskId?: string) => void;
  onDragTask: (
    phase: Map<string, any>,
    task: Map<string, any>,
    state: 'begin' | 'move' | 'end',
    position?: [number, number],
  ) => void;
  onExploreData: (task: Map<string, any>, buckets: Map<string, any>) => void;
  configId: string;
  automationId?: string;
  isDragged: boolean;
  isSelected: boolean;
  folders: Map<string, any>;
  onDeleteTask: (id: string) => void;
  onSetTaskParams: (taskId: string) => void;
  onEditTask: (id: string, field: string | string[], value: any) => void;
  onSelectTaskConfig: (
    taskId: string,
    componentId: string,
    configId: string | null,
    options?: { autosave: boolean },
  ) => Promise<any>;
  patternComponents: Map<string, any>;
  showBackendSize: boolean;
  isDevModeActive: boolean;
  hasSnowflakeDynamicBackendSize: boolean;
  hasJobsDynamicBackendSize: boolean;
  onHide: () => void;
  showTaskDetailModal: string;
};

const Task = (props: Props) => {
  const status = props.taskStatus?.get('status');
  const realComponentId = resolveComponentId(props.allConfigurations, props.task);
  const buckets = props.tablesMetadataMap
    .get(`${realComponentId}:${props.task.get('configId')}`, Map())
    .groupBy((table: Map<string, any>) => table.getIn(['bucket', 'id']));

  const onClickIcon = () => {
    if (props.taskStatus) {
      const href = RoutesStore.getRouter().createHref(routeNames.JOB_DETAIL, {
        jobId: props.taskStatus.get('jobId'),
      });
      windowOpen(href);
    }
  };

  return (
    <>
      <Flow.Node
        onSelect={() => props.onSelect(props.task.get('id'))}
        onDragStart={(position) => props.onDragTask(props.phase, props.task, 'begin', position)}
        onDragMove={(position) => props.onDragTask(props.phase, props.task, 'move', position)}
        onDragEnd={() => props.onDragTask(props.phase, props.task, 'end')}
        className={cn('with-border', 'clickable', {
          'has-blank-configuration': !props.task.get('configId'),
          'has-deleted-configuration': props.task.get('hasDeletedConfiguration'),
          'continue-on-failure': props.task.get('continueOnFailure', false),
          'run-specific-rows': !props.task.get('specificRows', List()).isEmpty(),
        })}
        draggable={!props.readOnly}
        key={props.task.get('id')}
        name={props.task.get('id')}
      >
        <div className={cn('node', { disabled: !props.task.get('enabled', true) })}>
          {!props.isDragged && (
            <>
              <Tooltip
                placement="top"
                tooltip="Click to debug"
                forceHide={!status || !JOB_FAILED_STATUSES.includes(status)}
              >
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={onClickIcon}
                  onClick={onClickIcon}
                  onMouseDown={(e) => status === JOBS_STATUS.ERROR && e.stopPropagation()}
                  className="task-status-spinner"
                >
                  <Spinner show={props.isRunning}>
                    <Status status={!props.isRunning ? status : null}>
                      {props.task.get('iconUrl') ? (
                        <TaskIcon size={24} src={props.task.get('iconUrl')} />
                      ) : (
                        <CircleIcon icon="table" bold smaller />
                      )}
                    </Status>
                  </Spinner>
                </div>
              </Tooltip>
              <TaskName
                name={props.task.get('name')}
                componentName={props.task.get('component')}
                componentType={props.task.get('type')}
                isBlank={!props.task.get('configId') && props.task.get('type') !== 'Table'}
                isDeleted={props.task.get('hasDeletedConfiguration')}
                isDragged={props.isDragged}
              />
              <SpecificRowsIcon rows={props.task.get('specificRows')} />
              {props.task.get('continueOnFailure', false) && <ContinueOnFailureIcon />}
              {!props.readOnly && (
                <TaskActions
                  onOpen={() => props.onSelect(props.task.get('id'))}
                  onDelete={() => props.onDeleteTask(props.task.get('id'))}
                  hasData={!buckets.isEmpty()}
                  onExploreData={
                    !!props.task.get('configId') &&
                    props.task.get('type') !== getNewComponentTypeLabel(componentTypes.WRITER)
                      ? () => props.onExploreData(props.task, buckets)
                      : void 0
                  }
                />
              )}
            </>
          )}
        </div>
      </Flow.Node>
      <TaskDetailModal
        task={props.task}
        lastJob={props.taskStatus}
        readOnly={props.readOnly}
        hasPayAsYouGo={props.hasPayAsYouGo}
        show={props.showTaskDetailModal === props.task.get('id')}
        onEditTask={props.onEditTask}
        onSelectConfig={props.onSelectTaskConfig}
        allConfigurations={props.allConfigurations}
        allComponents={props.allComponents}
        folders={props.folders}
        showBackendSize={props.showBackendSize}
        patternComponents={props.patternComponents}
        hasSnowflakeDynamicBackendSize={props.hasSnowflakeDynamicBackendSize}
        hasJobsDynamicBackendSize={props.hasJobsDynamicBackendSize}
        onHide={props.onHide}
        configId={props.configId}
        automationId={props.automationId}
        onSetTaskParams={props.onSetTaskParams}
        isDevModeActive={props.isDevModeActive}
        onDeleteTask={() => props.onDeleteTask(props.task.get('id'))}
      />
    </>
  );
};

const TaskMemoized = memo(Task);

export default TaskMemoized;
