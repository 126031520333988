import { useRef, useState } from 'react';
import type { ReactNode, SyntheticEvent } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';

import { ACTION_TOOLTIP_LIMIT, DELAY_SHOW_TOOLTIP } from '../constants';
import { keyCodes } from '../constants/keyCodes';
import { cn } from '../utils';

const TYPE_STYLES = {
  action:
    'tw-font-medium tw-text-xs tw-text-center tw-uppercase tw-tracking-wider tw-px-3 tw-py-2 tw-max-w-fit',
  explanatory: 'tw-font-normal tw-text-sm tw-text-left tw-px-4 tw-py-3 tw-max-w-80',
  'static-explanatory': 'tw-font-normal tw-text-sm tw-text-left tw-px-4 tw-py-3 tw-max-w-80',
};

type TooltipType = 'action' | 'explanatory' | 'static-explanatory' | 'auto';
type TooltipPlacement = 'top' | 'right' | 'bottom' | 'left';

export type Props = {
  tooltip: ReactNode;
  children: ReactNode;
  type?: TooltipType;
  placement?: TooltipPlacement;
  forceHide?: boolean;
  forceShow?: boolean;
  className?: string;
  triggerClassName?: string;
  triggerOnClick?: (e: SyntheticEvent) => void;
};

const TooltipProvider = RadixTooltip.Provider;

const stopPropagationHandler = (event: SyntheticEvent) => event.stopPropagation();
const preventDefaultHandler = (event: SyntheticEvent | CustomEvent) => event.preventDefault();

const Tooltip = ({
  tooltip,
  children,
  className,
  triggerClassName,
  type = 'action',
  placement = 'right',
  forceHide = false,
  forceShow = false,
  triggerOnClick,
}: Props) => {
  const triggerRef = useRef<HTMLSpanElement | null>(null);
  const [open, setOpen] = useState(false);

  const resolvedType = resolveType(type, tooltip);
  const keepOpenOnActivation = !triggerOnClick && resolvedType === 'static-explanatory';

  return (
    <RadixTooltip.Root
      open={!!tooltip && (open || forceShow) && !forceHide}
      onOpenChange={setOpen}
      delayDuration={DELAY_SHOW_TOOLTIP}
    >
      <RadixTooltip.Trigger asChild>
        <span
          ref={triggerRef}
          data-tooltip-trigger
          className={triggerClassName}
          {...(triggerOnClick && {
            tabIndex: 0,
            role: 'button',
            onClick: triggerOnClick,
            onKeyUp: (event) => {
              if ([keyCodes.ENTER, keyCodes.SPACE].includes(event.key)) {
                stopPropagationHandler(event);
                triggerOnClick(event);
              }
            },
          })}
          {...(keepOpenOnActivation && { onClick: preventDefaultHandler })}
        >
          {children}
        </span>
      </RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <RadixTooltip.Content
          data-tooltip-content
          hideWhenDetached
          sideOffset={8}
          collisionPadding={8}
          side={placement}
          className={cn(
            'tw-inline-block tw-rounded tw-bg-neutral-800 tw-text-white tw-break-anywhere',
            TYPE_STYLES[resolvedType],
            className,
          )}
          onClick={stopPropagationHandler}
          {...(keepOpenOnActivation && {
            onPointerDownOutside: (event) => {
              if (event.target instanceof Node && triggerRef.current?.contains(event.target)) {
                preventDefaultHandler(event);
              }
            },
          })}
        >
          {tooltip}
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
};

const resolveType = (type: TooltipType, tooltip: ReactNode) => {
  if (type !== 'auto') return type;
  return typeof tooltip === 'string' && tooltip.length > ACTION_TOOLTIP_LIMIT
    ? 'explanatory'
    : 'action';
};

export { Tooltip, TooltipProvider };
