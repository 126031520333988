import type { Map } from 'immutable';

import { cn } from '@keboola/design';

import phaseIcon from '../../../images/phase-icon.png';

import { getComponentIconUrl } from '@/utils/componentIconFinder';

type Props = {
  component?: Map<string, any>;
  size?: string;
  className?: string;
  isPhase?: boolean;
};

const ComponentIcon = ({ component, size = '64', isPhase, className }: Props) => {
  if (!component) {
    return null;
  }

  return (
    <img
      width={size}
      height={size}
      loading="lazy"
      title={component.get('name', '')}
      className={cn('component-icon img-circle bg-color-white', className)}
      src={isPhase ? phaseIcon : getComponentIconUrl(component)}
      alt={component.get('name', '')}
    />
  );
};

export default ComponentIcon;
