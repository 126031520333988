import { useState } from 'react';
import type { FormEvent } from 'react';
import { Modal } from 'react-bootstrap';
import { fromJS, Map } from 'immutable';

import { Alert } from '@keboola/design';

import { isVatIdValid, parseBillingInformation } from '@/modules/billing/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import { HTTP_STATUS_CODE_BAD_REQUEST } from '@/utils/errors/helpers';
import type HttpError from '@/utils/errors/HttpError';
import isEmailValid from '@/utils/isEmailValid';
import type { Fields, FieldsState } from './BillingEditFields';
import BillingEditFields from './BillingEditFields';

type Props = {
  billing: Map<string, any>;
  onSubmit: (data: Record<Fields, any>) => Promise<void>;
  onHide: () => void;
  show: boolean;
};

export const BillingEditModal = (props: Props) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [tempData, setTempData] = useState<FieldsState>(Map());
  const [error, setError] = useState<string | null>(null);

  const isDisabled = () => {
    if (!isEmailValid(tempData.get('email') as string)) {
      return true;
    }

    if (!isVatIdValid(tempData.get('vatId'), tempData.get('addressCountry'))) {
      return true;
    }

    return (
      !tempData.get('addressCountry') ||
      !tempData.get('addressLine1') ||
      !tempData.get('addressCity') ||
      !tempData.get('addressPostalCode') ||
      tempData.equals(getInitTempData())
    );
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsProcessing(true);
    props
      .onSubmit(tempData.toJS())
      .then(() => {
        props.onHide();
        setTempData(getInitTempData());
      })
      .catch((error: HttpError) => {
        if (error.response?.status === HTTP_STATUS_CODE_BAD_REQUEST) {
          return setError(error.message);
        }

        throw error;
      })
      .finally(() => setIsProcessing(false));
  };

  const getInitTempData = () => {
    return fromJS(parseBillingInformation(props.billing));
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      onEnter={() => {
        setTempData(getInitTempData());
        setError(null);
      }}
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Billing Details
            <ModalIcon icon="file-invoice" color="blue" bold />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && (
            <Alert variant="error" className="tw-mb-5">
              {error}
            </Alert>
          )}
          <BillingEditFields
            state={tempData}
            setState={(tempData, error) => {
              setTempData(tempData);
              if (typeof error !== 'undefined') {
                setError(error);
              }
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveButtonType="submit"
            saveLabel={isProcessing ? 'Saving changes...' : 'Save Changes'}
            isSaving={isProcessing}
            isDisabled={isDisabled()}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};
