import { Button, Icon } from '@keboola/design';

const PredefinedExamples = ({
  onClick,
  disabled,
}: {
  onClick: (prompt: string) => void;
  disabled: boolean;
}) => (
  <>
    <h3 className="tw-mb-6 tw-mt-12 tw-text-base">Try our examples</h3>
    <div className="tw-grid tw-grid-cols-3 tw-gap-6">
      <Example
        onClick={onClick}
        prompt="I aim to collect YouTube comments, apply natural language processing, and save the data in Power BI."
        disabled={disabled}
      />
      <Example
        onClick={onClick}
        prompt="Our team aims to gather customer support tickets from Jira, analyze them for urgency levels, and push the data to Looker."
        disabled={disabled}
      />
      <Example
        onClick={onClick}
        prompt="I want to have a report in Looker for most often sold product in our inventory from Shopify."
        disabled={disabled}
      />
    </div>
  </>
);

const Example = ({
  prompt,
  onClick,
  disabled,
}: {
  prompt: string;
  onClick: (prompt: string) => void;
  disabled: boolean;
}) => (
  <Button
    onClick={() => onClick(prompt)}
    variant="outline"
    className="tw-max-h-none tw-px-4 tw-py-3 tw-text-left tw-text-sm tw-normal-case tw-text-neutral-400"
    disabled={disabled}
  >
    <span>{prompt}</span>
    <Icon icon="angle-right" />
  </Button>
);

export default PredefinedExamples;
