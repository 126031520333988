import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { Types } from '@/modules/wr-google-bigquery-v2/helpers/constants';
import makeColumnDefinition from '@/modules/wr-google-bigquery-v2/helpers/makeColumnDefinition';
import Select from '@/react/common/Select';

const PreferencesColumn = createReactClass({
  propTypes: {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    column: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    showAdvanced: PropTypes.bool,
  },

  renderDbName() {
    return (
      <FormGroup>
        <div className="col-xs-4">
          <ControlLabel>BigQuery Column</ControlLabel>
        </div>
        <div className="col-xs-8">
          <FormControl
            type="text"
            disabled={this.props.disabled}
            onChange={(e) => this.onChangeColumn('dbName', e.target.value)}
            value={this.props.column.dbName}
          />
        </div>
      </FormGroup>
    );
  },

  render() {
    const { fields } = makeColumnDefinition(this.props.column);
    return (
      <>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Data Type</ControlLabel>
          </div>
          <div className="col-xs-8">
            <Select
              clearable={false}
              value={this.props.column.type}
              options={Object.keys(Types).map((value) => ({ value, label: value }))}
              onChange={(value) => this.onChangeColumn('type', value)}
              disabled={this.props.disabled}
            />
          </div>
        </FormGroup>
        {fields.dbName.show && this.renderDbName()}
      </>
    );
  },

  onChangeColumn(property, value) {
    const newColumn = makeColumnDefinition(this.props.column).updateColumn(property, value).column;
    this.props.onChange(newColumn);
  },
});

export default PreferencesColumn;
