import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import FileTagsSelector from '@/modules/components/react/components/generic/FileTagsSelector';

const FileOutputSettings = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      fileName: PropTypes.string.isRequired,
      fileTags: PropTypes.array.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Output Settings</h2>
        </div>
        <div className="box-content">
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>File name</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl
                type="text"
                value={this.props.value.fileName}
                onChange={(e) => {
                  this.props.onChange({ fileName: e.target.value.trim() });
                }}
                disabled={this.props.disabled}
              />
            </div>
          </FormGroup>
          <FileTagsSelector
            tags={List(this.props.value.fileTags)}
            onChange={(fileTags) => {
              this.props.onChange({
                fileTags,
              });
            }}
            disabled={this.props.disabled}
            inline
          />
        </div>
      </div>
    );
  },
});

export default FileOutputSettings;
