import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { cn, Icon, Link, Tooltip } from '@keboola/design';

import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import StorageTableLink from '@/modules/components/react/components/StorageApiTableLinkEx';
import * as common from '@/modules/ex-google-drive/common';
import { RowActionDropdown, RowActionMenuItem } from '@/react/common';
import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';
import Loader from '@/react/common/Loader';

const COMPONENT_ID = 'keboola.ex-google-drive';

function getDocumentTitle(sheet) {
  return common.sheetFullName(sheet, ' / ');
}

const SheetsTable = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    sheets: PropTypes.object.isRequired,
    configId: PropTypes.string.isRequired,
    outputBucket: PropTypes.string.isRequired,
    deleteSheetFn: PropTypes.func.isRequired,
    onStartEdit: PropTypes.func.isRequired,
    isPendingFn: PropTypes.func.isRequired,
    toggleSheetEnabledFn: PropTypes.func.isRequired,
    getRunSingleSheetDataFn: PropTypes.func.isRequired,
  },

  render() {
    return (
      <div className="table table-hover">
        <div className="thead">
          <div className="tr">
            <div className="th">Document / Sheet</div>
            <div className="th">{/* right arrow */}</div>
            <div className="th">Output Table</div>
            <div className="th">{/* action buttons */}</div>
          </div>
        </div>
        <div className="tbody overflow-break-anywhere">
          {this.props.sheets.map(this.renderSheetRow).toArray()}
        </div>
      </div>
    );
  },

  renderGoogleLink(sheet) {
    const url = `https://docs.google.com/spreadsheets/d/${sheet.get('fileId')}/edit#gid=${sheet.get(
      'sheetId',
    )}`;
    const documentTitle = getDocumentTitle(sheet);
    return <Link href={url}>{documentTitle}</Link>;
  },

  renderSheetRow(sheet) {
    const outTableId = this.props.outputBucket + '.' + sheet.get('outputTable');
    const documentTitle = getDocumentTitle(sheet);
    const isDeleting = !!this.props.isPendingFn(['delete', sheet.get('id')]);
    const isPending = !!this.props.isPendingFn(['toggle', sheet.get('id')]);

    return (
      <div
        key={sheet.get('id')}
        className={cn('tr hoverable-actions', { 'row-disabled': !sheet.get('enabled') })}
      >
        <div className="td">{this.renderGoogleLink(sheet)}</div>
        <div className="td">
          <Icon icon="angle-right" fixedWidth />
        </div>
        <div className="td">
          <StorageTableLink tableId={outTableId} />
        </div>
        <div className="td pl-0 pr-1 no-wrap">
          {!this.props.readOnly && (
            <>
              <Tooltip tooltip="Edit Sheet" placement="top">
                <Button
                  bsStyle="link"
                  className="text-muted"
                  onClick={() => this.props.onStartEdit(sheet)}
                >
                  <Icon icon="pen" fixedWidth />
                </Button>
              </Tooltip>
              <Tooltip tooltip="Delete Sheet" placement="top">
                <Button
                  bsStyle="link"
                  className="text-muted"
                  disabled={isDeleting}
                  onClick={() => this.props.deleteSheetFn(sheet.get('id'))}
                >
                  {isDeleting ? <Loader /> : <Icon icon="trash" fixedWidth />}
                </Button>
              </Tooltip>
              <RowActionDropdown showLoading={isPending}>
                <RunComponentButton
                  mode="menuitem"
                  title="Run Extraction"
                  componentId={COMPONENT_ID}
                  runParams={() => {
                    return {
                      config: this.props.configId,
                      configData: this.props.getRunSingleSheetDataFn(sheet.get('id')),
                    };
                  }}
                >
                  You are about to run an extraction of {documentTitle}.
                </RunComponentButton>
                <RowActionMenuItem divider />
                <ActivateDeactivateSwitch
                  mode="menuitem"
                  isActive={sheet.get('enabled')}
                  isPending={isPending}
                  onChange={() => this.props.toggleSheetEnabledFn(sheet.get('id'))}
                />
              </RowActionDropdown>
            </>
          )}
        </div>
      </div>
    );
  },
});

export default SheetsTable;
