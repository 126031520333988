import type { ReactNode } from 'react';

import { JOB_RUNNING_STATUSES } from '@/modules/queue/constants';
import JobStatusIcon from '@/react/common/JobStatusIcon';

type Props = {
  status?: string | null;
  children?: ReactNode;
};

const Status = ({ status, children }: Props) => (
  <div className="task-icon-wrapper">
    {children}
    {status && !JOB_RUNNING_STATUSES.some((jobRunningStatus) => jobRunningStatus === status) && (
      <JobStatusIcon className="task-sub-icon" status={status} />
    )}
  </div>
);

export default Status;
