import { memo, useEffect, useMemo, useState } from 'react';
import type { KeyboardEvent, MouseEvent } from 'react';
import { shallowEqualImmutable } from 'react-immutable-render-mixin';
import type { Row, Table } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { Map } from 'immutable';
import _ from 'underscore';

import { ButtonInline, cn, Icon } from '@keboola/design';

import JobsActionCreators from '@/modules/queue/actions';
import { BEHAVIOR_TYPES, JOBS_STATUS, JOBS_TYPES, routeNames } from '@/modules/queue/constants';
import { isContainerJob, isPhaseJob, prepareSortedChildJobs } from '@/modules/queue/helpers';
import {
  getComponentByJob,
  getConfigurationName,
  getConfigurationRowName,
  getOrchestratorPhaseName,
} from '@/modules/queue/jobResolver';
import { CreatedDate, RouterLink, Truncated } from '@/react/common';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import { onTableRowKeyDown } from '@/react/common/ConfigurationsTable/helpers';
import JobDuration from '@/react/common/JobDuration';
import JobStatusLabel from '@/react/common/JobStatusLabel';
import Loader from '@/react/common/Loader';
import TableCollapseButton from '@/react/common/TableCollapseButton';
import RoutesStore from '@/stores/RoutesStore';
import hasSelections from '@/utils/hasSelections';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen,
} from '@/utils/windowOpen';
import ContinueOnFailureIcon from './ContinueOnFailureIcon';

type Data = { job: Map<string, any>; subRows: { job: Map<string, any> } };
type DataRow = Row<Data>;

const ChildJobs = (props: { job: Map<string, any>; childJobs: Map<string, any> }) => {
  const renderComponentDetail = (component: Map<string, any>, job: Map<string, any>) => {
    return (
      <>
        <ComponentIcon component={component} size="28" />
        <div className="ml-1">
          <div className="line-height-18">
            <Truncated text={getConfigurationName(job)} />
          </div>
          <div className="f-11 line-height-1 text-muted">
            <ComponentName component={component} capitalize showType>
              {(nameAndType: string) => nameAndType}
            </ComponentName>
          </div>
        </div>
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        header: ({ table }: { table: Table<Data> }) => {
          if (props.job.get('type') === JOBS_TYPES.ORCHESTRATION_CONTAINER) {
            return (
              <div className="flex-container flex-start">
                Phases & Tasks
                <ButtonInline
                  className="font-normal ml-1 tw-text-secondary-500"
                  onClick={table.getToggleAllRowsExpandedHandler()}
                >
                  <Icon
                    fixedWidth
                    className="f-14 !tw-mr-0"
                    icon={table.getIsAllRowsExpanded() ? 'angle-down' : 'angle-right'}
                  />
                  {table.getIsAllRowsExpanded() ? 'Collapse' : 'Expand'} All
                </ButtonInline>
              </div>
            );
          }

          if (isPhaseJob(props.job)) {
            return 'Tasks';
          }

          return 'Rows';
        },
        cell: ({ row }: { row: DataRow }) => {
          const job = row.original.job;

          if (props.job.get('type') === JOBS_TYPES.ORCHESTRATION_CONTAINER) {
            const component = getComponentByJob(job);

            return (
              <span
                className={cn('with-expand flex-container flex-start', {
                  '!tw-ml-14': row.depth === 1,
                })}
              >
                {row.depth === 0 && (
                  <>
                    {row.getCanExpand() && (
                      <TableCollapseButton entity="job" isCollapsed={!row.getIsExpanded()} />
                    )}
                    <ComponentIcon isPhase component={component} size="28" className="mr-1" />
                  </>
                )}
                <RouterLink
                  to={routeNames.JOB_DETAIL}
                  params={{ jobId: job.get('id') }}
                  className={cn('mr-1', {
                    'link-inherit': row.depth === 0,
                    'color-inherit no-underline': row.depth === 1,
                  })}
                >
                  <div className="flex-container flex-start">
                    {row.depth === 0 ? (
                      <Truncated text={getOrchestratorPhaseName(job)} />
                    ) : (
                      renderComponentDetail(component, job)
                    )}
                  </div>
                </RouterLink>
                {job.getIn(['behavior', 'onError']) === BEHAVIOR_TYPES.WARNING && (
                  <ContinueOnFailureIcon />
                )}
              </span>
            );
          }

          if (isPhaseJob(props.job)) {
            const component = getComponentByJob(job);

            return (
              <div className="flex-container flex-start">
                <div className="mr-1 flex-container flex-start">
                  {renderComponentDetail(component, job)}
                </div>
                {job.getIn(['behavior', 'onError']) === BEHAVIOR_TYPES.WARNING && (
                  <ContinueOnFailureIcon />
                )}
              </div>
            );
          }

          return (
            <div className="flex-container flex-start">
              <Truncated text={getConfigurationRowName(job)} />
            </div>
          );
        },
        accessorKey: 'job',
      },
      {
        header: 'Duration',
        cell: ({ row }: { row: DataRow }) => {
          const job = row.original.job;

          return (
            <JobDuration
              status={job.get('status')}
              startTime={job.get('startTime')}
              endTime={job.get('endTime')}
            />
          );
        },
        accessorKey: 'duration',
      },
      {
        header: 'Created',
        cell: ({ row }: { row: DataRow }) => {
          return <CreatedDate createdTime={row.original.job.get('createdTime')} />;
        },
        accessorKey: 'created',
      },
      {
        header: 'Status',
        cell: ({ row }: { row: DataRow }) => {
          return <JobStatusLabel status={row.original.job.get('status')} />;
        },
        accessorKey: 'status',
      },
    ],
    [props.job],
  );

  const data: Data[] = useMemo(() => {
    return prepareSortedChildJobs(props.childJobs, props.job)
      .map((job: Map<string, any>): Data => {
        return {
          job,
          subRows: prepareSortedChildJobs(props.childJobs, job)
            .map((childJob: Map<string, any>) => {
              return { job: childJob };
            })
            .toArray(),
        };
      })
      .toArray();
  }, [props.childJobs, props.job]);

  const [isLoading, setIsLoading] = useState(false);

  const tableInstance = useReactTable<Data>({
    columns,
    data,
    autoResetExpanded: false,
    getSubRows: (row: any) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });
  const rows = tableInstance.getRowModel().rows;

  const isStartingJob = [JOBS_STATUS.CREATED, JOBS_STATUS.WAITING].includes(
    props.job.get('status'),
  );
  const showLoading = !rows.length && (isLoading || isStartingJob);

  useEffect(() => {
    setIsLoading(true);
    JobsActionCreators.loadChildJobsForce(props.job).finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.job?.get('id')]); // We want to reload child jobs only when parent job changes

  if (!isContainerJob(props.job) || (!rows.length && !isLoading && !isStartingJob)) {
    return null;
  }

  return (
    <div className="box mb-2">
      <div className="table table-hover react-table">
        <div className="thead">
          {tableInstance.getHeaderGroups().map((headerGroup) => {
            return (
              <div key={headerGroup.id} className="tr">
                {headerGroup.headers.map((header) => {
                  return (
                    <div
                      key={header.id}
                      className={cn('th', {
                        'color-gray': header.column.id !== 'job' && showLoading,
                        'w-150 text-right': header.column.id === 'duration',
                        'w-250 text-right': header.column.id === 'created',
                        'w-125': header.column.id === 'status',
                      })}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="tbody">
          {showLoading ? (
            <div className="tr no-hover">
              <div className="td">
                <div className="flex-container flex-start color-base">
                  <Loader
                    className={cn('icon-addon-right', {
                      'color-orange': !isStartingJob,
                    })}
                  />
                  {isStartingJob &&
                    `${
                      props.job.get('type') === JOBS_TYPES.ORCHESTRATION_CONTAINER
                        ? 'Phases'
                        : isPhaseJob(props.job)
                          ? 'Tasks'
                          : 'Rows'
                    } will be created when job starts processing.`}
                </div>
              </div>
              {_.range(3).map((i) => (
                <div key={i} className="td text-right">
                  <div className="bg-color-gray tw-h-2 tw-rounded" />
                </div>
              ))}
            </div>
          ) : (
            rows.map((row) => {
              const job = row.original.job;
              const rowAction = (e?: MouseEvent | KeyboardEvent) => {
                if (hasSelections()) {
                  return;
                }

                if (shouldUseNewWindow(e)) {
                  const href = RoutesStore.getRouter().createHref(routeNames.JOB_DETAIL, {
                    jobId: job.get('id'),
                  });

                  return windowOpen(href);
                }

                if (
                  row.depth === 0 &&
                  props.job.get('type') === JOBS_TYPES.ORCHESTRATION_CONTAINER
                ) {
                  return row.toggleExpanded(row.getCanExpand() && !row.getIsExpanded());
                }

                return RoutesStore.getRouter().transitionTo(routeNames.JOB_DETAIL, {
                  jobId: job.get('id'),
                });
              };

              return (
                <div
                  key={row.id}
                  tabIndex={0}
                  role="button"
                  className="tr clickable"
                  onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
                  onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
                  onClick={rowAction}
                  onKeyDown={onTableRowKeyDown(rowAction)}
                >
                  {row.getAllCells().map((cell) => {
                    return (
                      <div
                        key={cell.id}
                        className={cn('td', {
                          'text-right': ['duration', 'created'].includes(cell.column.id),
                        })}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </div>
                    );
                  })}
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

const MemoizedChildJobs = memo(ChildJobs, shallowEqualImmutable);

export default MemoizedChildJobs;
