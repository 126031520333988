import type {
  ApiResponse,
  BaseFetchParams,
  CoreFetchFn,
  CreateFetchClientOptions,
  FetchClientOptions,
  FetchRequest,
  HttpMethod,
  MiddlewareFn,
} from './types';
import { createFetchRequest, parseData } from './utils';

export class ApiError extends Error {
  response: Response;
  request: Request;
  data: unknown;

  constructor({ response, request, data }: ApiResponse) {
    super(response.statusText);
    this.response = response;
    this.request = request;
    this.data = data;
  }
}

export type FetchClientError = TypeError | ApiError | SyntaxError | DOMException;

export const isApiError = (error: unknown): error is ApiError => error instanceof ApiError;
export const isAbortError = (error: unknown): error is DOMException =>
  error instanceof DOMException && error.name === 'AbortError';

export const isFetchClientError = (error: unknown): error is FetchClientError =>
  error instanceof TypeError || error instanceof SyntaxError || isApiError(error);

const coreFetch: CoreFetchFn = async ({ request, validateStatus }) => {
  const response = await fetch(request);
  const data = await parseData(response);

  const apiResponse = {
    request,
    response,
    data,
  };

  const boolOrError = validateStatus(apiResponse);
  if (isApiError(boolOrError)) throw boolOrError;
  if (!boolOrError) throw new ApiError(apiResponse);

  return apiResponse;
};

const wrapMiddlewares = (middlewares: MiddlewareFn[], fetchFn: CoreFetchFn) => {
  type Handler = (request: FetchRequest, index: number) => Promise<ApiResponse>;

  const handler: Handler = async (request, index) => {
    if (index === middlewares.length) return fetchFn(request);

    const currentMiddleware = middlewares[index];
    return await currentMiddleware(request, (nextRequest) => handler(nextRequest, index + 1));
  };

  return (request: FetchRequest) => handler(request, 0);
};

export const createFetchClient = ({
  middlewares = [],
  ...defaultOptions
}: CreateFetchClientOptions) => {
  const fetchWithMiddleware = wrapMiddlewares(middlewares, coreFetch);

  const createFetchMethod =
    (method: HttpMethod) =>
    async (url: string, params: BaseFetchParams, options?: FetchClientOptions) => {
      const input = createFetchRequest({
        url,
        method,
        params,
        defaultOptions,
        options,
      });

      return fetchWithMiddleware(input);
    };

  return {
    get: createFetchMethod('get'),
    post: createFetchMethod('post'),
    put: createFetchMethod('put'),
    path: createFetchMethod('patch'),
    delete: createFetchMethod('delete'),
  };
};
