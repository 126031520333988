import type { ComponentProps } from 'react';
import { Button, FormGroup } from 'react-bootstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import type { List, Map } from 'immutable';

import { Icon, Tooltip } from '@keboola/design';

import { CHANNEL_EMAIL, EVENT_JOB_PROCESSING } from '@/modules/notifications/constants';
import { CircleIcon } from '@/react/common';
import type { IconColor } from '@/react/common/CircleIcon';
import Gravatar from '@/react/common/Gravatar';
import MarkedText from '@/react/common/MarkedText';
import Select from '@/react/common/Select';
import isEmailValid from '@/utils/isEmailValid';
import string from '@/utils/string';
import ToleranceInput from './ToleranceInput';

type Props = {
  title: string;
  text: string;
  icon: ComponentProps<typeof Icon>['icon'];
  iconColor: IconColor;
  type: string;
  isReadOnly: boolean;
  allNotifications: List<any>;
  admins: Map<string, any>;
  savingType: string | null;
  onAddNotification: (type: string, email: string) => void;
  onDeleteNotification: (type: string, notification: Map<string, any>) => void;
  onGetValue: (type: string) => List<any>;
  emails: List<string>;
  onChange: (type: string, callback: () => Promise<void>) => void;
};

const NotificationBox = ({
  title,
  text,
  icon,
  iconColor,
  type,
  isReadOnly,
  allNotifications,
  admins,
  savingType,
  onAddNotification,
  onDeleteNotification,
  onGetValue,
  emails,
  onChange,
}: Props) => {
  const options = allNotifications
    .filter((notification) => notification.getIn(['recipient', 'channel']) === CHANNEL_EMAIL)
    .groupBy((notification) => notification.getIn(['recipient', 'address']))
    .keySeq()
    .concat(admins.map((admin) => admin.get('email')))
    .map((email) => email.toLowerCase())
    .toSet()
    .filter((email) => !emails.includes(email))
    .map((email) => {
      const admin = admins.get(email);

      return {
        value: email,
        label: (inputString: string) => (
          <div className="flex-container flex-start">
            <Gravatar user={admin} className="mr-1" />
            <div className="flex-container align-top flex-column">
              <strong className="font-medium">
                <MarkedText source={admin ? admin.get('name', '') : 'Guest'} mark={inputString} />
              </strong>
              <small className="text-muted">
                <MarkedText source={email} mark={inputString} />
              </small>
            </div>
          </div>
        ),
        name: admin ? admin.get('name', '') : 'Guest',
      };
    })
    .sortBy((option) => option!.name.toLowerCase())
    .toArray();

  const isLoading = savingType === type;
  const isDisabled = isReadOnly || savingType === type;

  return (
    <>
      <CircleIcon className="box-icon smaller" icon={icon} color={iconColor} bold />
      <div className="box">
        <div className="box-content">
          <h2 className="font-medium f-16 mt-1">{title}</h2>
          <p className="text-muted">{text}</p>
          <FormGroup className="mb-0">
            <Select
              allowCreate
              noResultsText="Enter valid email address"
              placeholder="Choose from your colleagues or enter an email address"
              promptTextCreator={(label) =>
                `Add ${string.pluralize(label?.split(',').length, 'email')}`
              }
              isLoading={isLoading}
              disabled={isDisabled}
              options={options}
              onChange={(email: string) => onAddNotification(type, email)}
              isValidNewOption={(inputValue) => inputValue.split(',').every(isEmailValid)}
            />
          </FormGroup>
          <TransitionGroup>
            {onGetValue(type)
              .sortBy((notification) => notification.getIn(['recipient', 'address']))
              .map((notification) => {
                const email = notification.getIn(['recipient', 'address']);
                const admin = admins.get(email);

                return (
                  <CSSTransition key={email} timeout={300} exit={false} classNames="fade">
                    <div className="notification-row">
                      <div className="flex-container">
                        <div className="flex-container flex-start">
                          <Gravatar user={admin} className="mr-1" />
                          <div className="flex-container align-top flex-column">
                            <strong className="f-16 font-medium">
                              {admin ? admin.get('name', '') : 'Guest'}
                            </strong>
                            <span className="text-muted">{email}</span>
                          </div>
                        </div>
                        <div className="flex-container flex-end">
                          {notification.get('event') === EVENT_JOB_PROCESSING && (
                            <ToleranceInput
                              savingType={savingType}
                              isReadOnly={isReadOnly}
                              email={email}
                              notification={notification}
                              onChange={onChange}
                            />
                          )}
                          <Tooltip placement="top" tooltip="Remove notification">
                            <Button
                              bsStyle="link"
                              className="btn-link-inline text-muted-light"
                              onClick={() => onDeleteNotification(type, notification)}
                              disabled={isDisabled}
                            >
                              <Icon icon="circle-xmark" />
                            </Button>
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </CSSTransition>
                );
              })
              .toArray()}
          </TransitionGroup>
        </div>
      </div>
    </>
  );
};

export default NotificationBox;
