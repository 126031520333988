import { PureComponent } from 'react';
import type { ReactNode } from 'react';
import _ from 'underscore';

import type { TooltipProps } from '@keboola/design';
import { cn, Tooltip } from '@keboola/design';

type Props = {
  checked?: boolean;
  id?: string;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  indeterminate?: boolean;
  tooltip?: TooltipProps['tooltip'];
  className?: string;
  children?: ReactNode;
};

class Checkbox extends PureComponent<Props> {
  checkboxId = _.uniqueId('checkbox_');

  render() {
    if (this.props.tooltip) {
      return (
        <Tooltip
          type="auto"
          placement="top"
          tooltip={this.props.tooltip}
          triggerClassName="tw-inline-flex"
        >
          {this.renderCheckbox()}
        </Tooltip>
      );
    }

    return this.renderCheckbox();
  }

  renderCheckbox = () => {
    return (
      <div
        onClick={(event) => event.stopPropagation()}
        className={cn('common-checkbox', this.props.className, {
          'disabled not-allowed': this.props.disabled,
        })}
      >
        <div className="tw-relative tw-flex">
          <input
            id={this.checkboxId}
            type="checkbox"
            disabled={!!this.props.disabled}
            checked={!!this.props.checked}
            onChange={() =>
              this.props.onChange?.(
                !!this.props.checked || !!this.props.indeterminate ? false : true,
              )
            }
            ref={(input) => {
              if (input) {
                input.indeterminate = !!this.props.indeterminate;
              }
            }}
          />
          <span className="checkbox-ui">
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.10356 10.3625L11.8536 4.96499C12.0488 4.78171 12.0488 4.48452 11.8536 4.30124L11.1465 3.63748C10.9512 3.45417 10.6346 3.45417 10.4393 3.63748L5.75 8.03936L3.56066 5.98422C3.36541 5.80093 3.04881 5.80093 2.85353 5.98422L2.14644 6.64797C1.95119 6.83125 1.95119 7.12844 2.14644 7.31172L5.39644 10.3625C5.59172 10.5458 5.90828 10.5458 6.10356 10.3625Z"
                fill="white"
              />
            </svg>
          </span>
        </div>

        {this.props.children && <label htmlFor={this.checkboxId}>{this.props.children}</label>}
      </div>
    );
  };
}

export default Checkbox;
