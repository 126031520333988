import type { GenericFetchClient } from '../managementClient';

import type { AddUserFeatureBody, AddUserFeaturePath, RemoveUserFeaturePath, User } from './types';

export const createUsers = (client: GenericFetchClient) => {
  const addUserAdminFeature = async (
    { feature, userIdOrMail }: AddUserFeatureBody & AddUserFeaturePath,
    signal?: AbortSignal,
  ) => {
    const { data } = await client.post<User, AddUserFeaturePath, void, AddUserFeatureBody>(
      '/users/{userIdOrMail}/features',
      { path: { userIdOrMail: encodeURIComponent(userIdOrMail) }, body: { feature } },
      { signal },
    );
    return data;
  };

  const removeUserAdminFeature = async (
    { userIdOrMail, feature }: RemoveUserFeaturePath,
    signal?: AbortSignal,
  ) => {
    const { data } = await client.delete<User, RemoveUserFeaturePath>(
      '/users/{userIdOrMail}/features/{feature}',
      { path: { userIdOrMail: encodeURIComponent(userIdOrMail), feature } },
      { signal },
    );
    return data;
  };

  return {
    addUserAdminFeature,
    removeUserAdminFeature,
  };
};
