import createReactClass from 'create-react-class';
import { List } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { FEATURE_IS_SINGLE_TENANT } from '@/constants/features';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import TasksTable from './components/TasksTable';
import {
  addEmptyPhase,
  createTask,
  groupTasksToPhasesByComponentType,
  preparePhasesWithTasks,
  shouldAddEmptyPhase,
} from './helpers';
import { clearLocalState, getLocalStateValue, updateLocalStateValue } from './localState';

const Tasks = createReactClass({
  mixins: [
    createStoreMixin(
      StackFeaturesStore,
      ApplicationStore,
      ComponentsStore,
      InstalledComponentsStore,
    ),
  ],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const config = InstalledComponentsStore.getConfig(KEBOOLA_ORCHESTRATOR, configId);

    const tasksFromConfig = config.getIn(['configuration', 'tasks'], List());
    const phasesFromConfig = config.getIn(['configuration', 'phases'], List());
    const tasks = getLocalStateValue(configId, ['tasks'], tasksFromConfig);
    const initialPhases = getLocalStateValue(configId, ['phases'], phasesFromConfig);

    const phases = shouldAddEmptyPhase(initialPhases, tasks)
      ? addEmptyPhase(initialPhases)
      : initialPhases;

    return {
      readOnly: ApplicationStore.isReadOnly(),
      configId,
      phases,
      tasks,
      phasesWithTasks: preparePhasesWithTasks(phases, tasks),
      components: ComponentsStore.getAll(),
      configurations: InstalledComponentsStore.getAll(),
      hasFlows: ApplicationStore.hasFlows(),
      hasSnowflakeDynamicBackendSize: ApplicationStore.hasSnowflakeDynamicBackendSize(),
      hasJobsDynamicBackendSize: ApplicationStore.hasJobsDynamicBackendSize(),
      isSingleTenant: StackFeaturesStore.hasStackFeature(FEATURE_IS_SINGLE_TENANT),
    };
  },

  componentDidMount() {
    clearLocalState(this.state.configId);
  },

  render() {
    return (
      <div className="box pb-1">
        <TasksTable
          components={this.state.components}
          readOnly={this.state.readOnly}
          configurations={this.state.configurations}
          phasesWithTasks={this.state.phasesWithTasks}
          phaseNameUpdateFn={this.handlePhaseNameUpdate}
          addTaskFn={this.handleAddTask}
          changeTaskFn={this.handleChangeTask}
          removeTaskFn={this.handleRemoveTask}
          movePhaseFn={this.handlePhaseMove}
          mergePhasesFn={this.handleMergePhasesFn}
          moveTasksToPhaseFn={this.handleMoveTasksToPhaseFn}
          groupTasksByComponentTypeFn={this.handleGroupTasksByComponentType}
          hasFlows={this.state.hasFlows}
          hasSnowflakeDynamicBackendSize={this.state.hasSnowflakeDynamicBackendSize}
          hasJobsDynamicBackendSize={this.state.hasJobsDynamicBackendSize}
          isSingleTenant={this.state.isSingleTenant}
        />
      </div>
    );
  },

  handlePhaseNameUpdate(phaseId, phaseName) {
    const phaseIdx = this.state.phases.findIndex((phase) => phase.get('id') === phaseId);
    this.updateLocalState(['phases'], this.state.phases.setIn([phaseIdx, 'name'], phaseName));
  },

  handlePhaseMove(oldIndex, newIndex) {
    this.updateLocalState(
      ['phases'],
      this.state.phases.splice(oldIndex, 1).splice(newIndex, 0, this.state.phases.get(oldIndex)),
    );
  },

  handleAddTask(component, configuration, phaseId) {
    this.syncPhases();

    this.updateLocalState(
      ['tasks'],
      this.state.tasks.push(
        createTask(
          component,
          configuration,
          phaseId,
          this.state.tasks.map((task) => task.get('id')),
        ),
      ),
    );
  },

  handleChangeTask(taskId, path, value) {
    return this.updateLocalState(
      ['tasks'],
      this.state.tasks.map((task) => (task.get('id') === taskId ? task.setIn(path, value) : task)),
    );
  },

  handleRemoveTask(taskId) {
    this.updateLocalState(
      ['tasks'],
      this.state.tasks.filter((task) => task.get('id') !== taskId),
    );
  },

  handleMergePhasesFn(fromPhaseIds, toPhaseId) {
    this.syncPhases();

    this.updateLocalState(
      ['tasks'],
      this.state.tasks.map((task) => {
        if (fromPhaseIds.includes(task.get('phase'))) {
          return task.set('phase', toPhaseId);
        }
        return task;
      }),
    );
  },

  handleMoveTasksToPhaseFn(taskIds, toPhaseId) {
    this.syncPhases();

    this.updateLocalState(
      ['tasks'],
      this.state.tasks.map((task) => {
        if (taskIds.includes(task.get('id'))) {
          return task.set('phase', toPhaseId);
        }
        return task;
      }),
    );
  },

  handleGroupTasksByComponentType() {
    const tasksByComponentType = groupTasksToPhasesByComponentType(
      this.state.components,
      this.state.tasks,
    );
    this.updateLocalState(['phases'], tasksByComponentType.get('phases'));
    this.updateLocalState(['tasks'], tasksByComponentType.get('tasks'));
  },

  syncPhases() {
    // Sync phases, so moving/merging/adding tasks to "dummy" phase will work
    this.updateLocalState(['phases'], this.state.phases);
  },

  updateLocalState(path, data) {
    return updateLocalStateValue(this.state.configId, path, data);
  },
});

export default Tasks;
