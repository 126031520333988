import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { routeNames } from '@/modules/storage/constants';
import { tableName } from '@/modules/storage/helpers';
import { RouterLink as Link } from '@/react/common';
import ProjectAliasLink from './ProjectAliasLink';

const TableAliasesAndLinks = createReactClass({
  mixins: [PureRenderMixin],

  propTypes: {
    sapiToken: PropTypes.object.isRequired,
    urlTemplates: PropTypes.instanceOf(Map).isRequired,
    tableAliases: PropTypes.array.isRequired,
    tableLinks: PropTypes.array.isRequired,
    onLinkClick: PropTypes.func.isRequired,
  },

  render() {
    if (!this.hasAliasesOrLinks()) {
      return null;
    }

    return (
      <div className="table-responsive">
        <table className="table table-striped compact">
          <thead>
            <tr>
              <th>Alias tables</th>
            </tr>
          </thead>
          <tbody>
            {this.props.tableAliases.map((alias) => (
              <tr key={`alias-${alias.get('id')}`}>
                <td>
                  <Link
                    to={routeNames.TABLE}
                    params={{
                      bucketId: alias.getIn(['bucket', 'id']),
                      tableName: alias.get('name'),
                    }}
                    onClick={this.props.onLinkClick}
                  >
                    {tableName(alias)}
                  </Link>
                </td>
              </tr>
            ))}

            {this.props.tableLinks.map((alias) => (
              <tr key={`link-${alias.get('id')}`}>
                <td>
                  <ProjectAliasLink
                    sapiToken={this.props.sapiToken}
                    urlTemplates={this.props.urlTemplates}
                    alias={alias}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  },

  hasAliasesOrLinks() {
    return this.props.tableAliases.length > 0 || this.props.tableLinks.length > 0;
  },
});

export default TableAliasesAndLinks;
