import PropTypes from 'prop-types';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import CopyVersionButton from '@/react/common/CopyVersionButton';
import JobStatusIcon from '@/react/common/JobStatusIcon';
import RawConfigurationButton from '@/react/common/RawConfigurationButton';
import RollbackVersionButton from '@/react/common/RollbackVersionButton';
import TimeAndUser from '@/react/common/TimeAndUser';
import DiffVersionButton from './DiffVersionButton';

const VersionRow = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    version: PropTypes.object.isRequired,
    versionConfig: PropTypes.object.isRequired,
    previousVersion: PropTypes.object.isRequired,
    previousVersionConfig: PropTypes.object.isRequired,
    admins: PropTypes.object.isRequired,
    component: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    configId: PropTypes.string.isRequired,
    row: PropTypes.object,
    rowId: PropTypes.string,
    hideRollback: PropTypes.bool,
    hideCopy: PropTypes.bool,
    isRollbackPending: PropTypes.bool,
    isRollbackDisabled: PropTypes.bool,
    isCopyPending: PropTypes.bool,
    isCopyDisabled: PropTypes.bool,
    isDiffPending: PropTypes.bool,
    isDiffDisabled: PropTypes.bool,
    hasFlows: PropTypes.bool,
    onPrepareVersionsDiffData: PropTypes.func,
    isLast: PropTypes.bool.isRequired,
    sharedCodes: PropTypes.object,
    onCopy: PropTypes.func,
    onRollback: PropTypes.func,
  },

  getDefaultProps() {
    return {
      sharedCodes: List(),
    };
  },

  render() {
    return (
      <tr>
        <td>
          <JobStatusIcon status={this.props.isLast ? 'success' : 'default'} className="f-16" />
        </td>
        <td>
          <span className="line-height-24">Version {this.props.version.get('version')}</span>
          <div className="overflow-break-anywhere text-muted">
            {this.props.version.get('changeDescription') || 'No description'}
          </div>
        </td>
        <td className="no-wrap text-right">
          <TimeAndUser
            time={this.props.version.get('created')}
            fallbackName={this.props.version.getIn(['creatorToken', 'description'])}
            admin={this.props.admins.get(this.props.version.getIn(['creatorToken', 'description']))}
          />
        </td>
        <td>{this.renderButtons()}</td>
      </tr>
    );
  },

  renderButtons() {
    return (
      <>
        <RawConfigurationButton
          version={this.props.version}
          component={this.props.component}
          configId={this.props.configId}
          rowId={this.props.rowId}
        />
        {!this.props.isLast && !this.props.hideRollback && (
          <RollbackVersionButton
            version={this.props.version}
            onRollback={this.props.onRollback}
            isDisabled={this.props.isRollbackDisabled}
            isPending={this.props.isRollbackPending}
            componentId={this.props.component.get('id')}
          />
        )}
        <DiffVersionButton
          component={this.props.component}
          config={this.props.config}
          row={this.props.row}
          isDisabled={this.props.isDiffDisabled}
          isPending={this.props.isDiffPending}
          hideRollback={this.props.hideRollback}
          onLoadVersionConfig={this.props.onPrepareVersionsDiffData}
          versionConfig={this.props.versionConfig}
          previousVersionConfig={this.props.previousVersionConfig}
          sharedCodes={this.props.sharedCodes}
          admins={this.props.admins}
          buttonClassName="text-muted"
          tooltipText="Compare with previous"
        />
        {!this.props.hideCopy && (
          <CopyVersionButton
            isRow={!!this.props.rowId}
            configId={this.props.configId}
            componentId={this.props.component.get('id')}
            componentType={this.props.component.get('type')}
            version={this.props.version}
            onCopy={this.props.onCopy}
            isDisabled={this.props.isCopyDisabled}
            isPending={this.props.isCopyPending}
            isLast={this.props.isLast}
            hasFlows={this.props.hasFlows}
          />
        )}
      </>
    );
  },
});

export default VersionRow;
