import { Button } from 'react-bootstrap';

import type { Stack } from '@keboola/constants';
import { Alert, Link } from '@keboola/design';

import { PLATFORM_CHANGELOG_URL } from '@/constants/KbcConstants';
import ApplicationStore from '@/stores/ApplicationStore';
import contactSupport from '@/utils/contactSupport';

const NewQueueMigration = () => {
  const stackId = ApplicationStore.getCurrentStackId() as Stack['id'];
  const organizationId = ApplicationStore.getCurrentOrganization().get('id');

  // show for all projects with legacy queue with exeption for one organization
  if (ApplicationStore.hasNewQueue() || `${organizationId}_${stackId}` === '13_kbc-eu-central-1') {
    return null;
  }

  return (
    <Alert variant="warning" title="Migration to new queue infrastructure" className="tw-mb-6">
      We&apos;re upgrading to a faster, more reliable infrastructure for running jobs (new queue).
      The current system will soon be deprecated, so migrate your projects to the new queue at your
      earliest convenience to ensure uninterrupted service. Learn{' '}
      <Link href={`${PLATFORM_CHANGELOG_URL}/migrating-old-projects-to-queue-v2/`}>more</Link> or{' '}
      <Button onClick={() => contactSupport()} bsStyle="link" className="btn-link-inline">
        contact support
      </Button>{' '}
      for help.
    </Alert>
  );
};

export default NewQueueMigration;
