import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

import { cn, Icon } from '@keboola/design';

import date from '@/date';
import { routeNames as jobsRouteNames } from '@/modules/jobs/Constants';
import { isPartialJob as oldIsPartialJob } from '@/modules/jobs/helpers';
import OneTimeNotificationButton from '@/modules/notifications/components/OneTimeNotificationButton';
import InternalDataAppJob from '@/modules/queue/components/InternalDataAppJob';
import ScheduledJob from '@/modules/queue/components/ScheduledJob';
import TriggeredJob from '@/modules/queue/components/TriggeredJob';
import {
  JOB_RUNNING_STATUSES,
  JOBS_STATUS,
  routeNames as queueRouteNames,
} from '@/modules/queue/constants';
import { isInternalDataAppJob, isScheduledJob, isTriggeredJob } from '@/modules/queue/helpers';
import { RouterLink as Link } from '@/react/common';
import JobDuration from '@/react/common/JobDuration';
import JobStatusIcon from '@/react/common/JobStatusIcon';
import string from '@/utils/string';

const SidebarJobsRow = ({
  job,
  admin,
  currentAdmin,
  notifications,
  allConfigurations,
  hasNewQueue,
}) => {
  const renderDescription = () => {
    if (isScheduledJob(job)) {
      return <ScheduledJob noIcon job={job} allConfigurations={allConfigurations} />;
    }

    if (isTriggeredJob(job)) {
      return <TriggeredJob job={job} allConfigurations={allConfigurations} />;
    }

    if (isInternalDataAppJob(job)) {
      return <InternalDataAppJob />;
    }

    if (admin) {
      return admin.get('name') || admin.get('email');
    }

    return job.getIn(['token', 'description']);
  };

  const renderStatus = (status) => {
    if (status === JOBS_STATUS.ERROR) {
      return 'Failed';
    }

    return string.capitalize(status);
  };

  return (
    <div
      className={cn('job-row', {
        highlight: JOB_RUNNING_STATUSES.includes(job.get('status')),
      })}
    >
      <div className="flex-container flex-start align-top">
        <JobStatusIcon status={job.get('status')} className="status-info icon-addon-right" />
        <span>
          <Link
            to={hasNewQueue ? queueRouteNames.JOB_DETAIL : jobsRouteNames.DETAIL}
            params={{ jobId: job.get('id') }}
            className="flex-container flex-start inline-flex"
          >
            {renderStatus(job.get('status'))}
            {!hasNewQueue && oldIsPartialJob(job) ? ' partial job' : ' job'}
            {hasNewQueue && (
              <OneTimeNotificationButton
                inline
                job={job}
                admin={currentAdmin}
                notifications={notifications}
                className="mlp-2"
              />
            )}
          </Link>
          {job.get('endTime') && (
            <span className="text-muted job-duration">
              (Duration{' '}
              <JobDuration startTime={job.get('startTime')} endTime={job.get('endTime')} />)
            </span>
          )}
        </span>
      </div>
      <div className="flex-container flex-start align-top text-muted">
        <Icon icon="clock" className="time-info icon-addon-right" />
        <div className="font-medium overflow-break-anywhere m-0">
          {date(job.get('createdTime')).fromNow()} by {renderDescription()}
        </div>
      </div>
    </div>
  );
};

SidebarJobsRow.propTypes = {
  job: PropTypes.object.isRequired,
  hasNewQueue: PropTypes.bool.isRequired,
  currentAdmin: PropTypes.instanceOf(Map).isRequired,
  notifications: PropTypes.instanceOf(List).isRequired,
  allConfigurations: PropTypes.instanceOf(Map),
  admin: PropTypes.instanceOf(Map),
};

export default SidebarJobsRow;
