import type { Map } from 'immutable';

import { findAdmin } from './findAdmin';

export const getUserDetails = (entity: Map<string, any>, admins: Map<string, any>) => {
  if (!entity) return { name: null, email: null };

  const name = entity.get('name') || null;
  const email = entity.get('email') || null;
  const id = entity.get('id');

  const admin = findAdmin(admins, email, id);
  const emailFromAdmins = admin?.get('email') || null;

  return {
    id,
    name,
    email: email || emailFromAdmins,
  };
};
