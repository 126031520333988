import { useState } from 'react';
import type { FormEvent, ReactNode } from 'react';
import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';
import type { FileWithPath } from 'react-dropzone';
import { Promise } from 'bluebird';
import type { Map } from 'immutable';
import { List } from 'immutable';
import _ from 'underscore';

import { Alert, HelpBlock } from '@keboola/design';

import { uploadFile } from '@/modules/storage/actions';
import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import FilesDropZone from '@/react/common/FilesDropZone';
import ModalIcon from '@/react/common/ModalIcon';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import Select from '@/react/common/Select';
import UploadProgress from '@/react/common/UploadProgress';

type InitialState = {
  files: (typeof FileWithPath)[];
  uploadedFiles: (typeof FileWithPath)[];
  tags: List<string>;
  permanent: boolean;
  uploading: null | typeof FileWithPath;
  error: ReactNode | null;
};

const INITIAL_STATE = {
  files: [],
  uploadedFiles: [],
  tags: List<string>(),
  permanent: false,
  uploading: null,
  error: null,
};

type Props = {
  show: boolean;
  uploadingProgress: Map<string, number>;
  onUpload: () => void;
  onHide: () => void;
};

const UploadModal = (props: Props) => {
  const [state, setState] = useState<InitialState>(INITIAL_STATE);

  const isUploading = state.uploading && !state.error;

  const renderBody = () => {
    if (isUploading) {
      return (
        <UploadProgress
          files={state.files.length}
          uploadedFiles={state.uploadedFiles.length}
          progress={props.uploadingProgress.get(state.uploading.path, 0)}
        />
      );
    }

    return (
      <>
        {state.error && (
          <Alert variant="error" className="tw-mb-5">
            <p>
              Upload of the file <b>{state.uploading.path}</b> failed.
            </p>
            <p>{state.error}</p>
            {state.uploadedFiles.length > 0 && (
              <>
                <hr />
                <p>
                  Some files were successfully uploaded and removed from the list of selected files.
                  You can try to upload the remaining files again.
                </p>
              </>
            )}
          </Alert>
        )}
        <FormGroup>
          <FilesDropZone
            multiple
            allowAllFiles
            files={state.files}
            onDrop={(files, options) =>
              setState((prevState) => ({
                ...prevState,
                files: options?.force
                  ? files
                  : _.uniq([...state.files, ...files], (file) => file.name),
              }))
            }
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>
            Tags <OptionalFormLabel />
          </ControlLabel>
          <Select
            multi
            allowCreate
            placeholder="Enter tags"
            promptTextCreator={() => 'Add tag'}
            value={state.tags}
            onChange={(tags) => setState((prevState) => ({ ...prevState, tags }))}
          />
        </FormGroup>
        <FormGroup>
          <Checkbox
            checked={state.permanent}
            onChange={(permanent) => setState((prevState) => ({ ...prevState, permanent }))}
          >
            Store permanently
          </Checkbox>
          <HelpBlock className="tw-mt-1">
            Otherwise, the file will be deleted after <strong>15 days</strong>.
          </HelpBlock>
        </FormGroup>
      </>
    );
  };

  const renderFooter = () => {
    if (isUploading) {
      return null;
    }

    return (
      <Modal.Footer>
        <ConfirmButtons
          block
          saveButtonType="submit"
          saveLabel={state.error ? 'Try again' : 'Start upload'}
          isDisabled={!state.files.length}
        />
      </Modal.Footer>
    );
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setState((prevState) => ({ ...prevState, uploadedFiles: [], uploading: null, error: null }));
    return Promise.each(state.files, (file) => {
      setState((prevState) => ({ ...prevState, uploading: file }));
      return uploadFile(file.path, file, {
        isPermanent: state.permanent,
        tags: state.tags.toArray(),
      }).then(() =>
        setState((prevState) => ({ ...prevState, uploadedFiles: [...state.uploadedFiles, file] })),
      );
    })
      .then(() => {
        props.onHide();
        props.onUpload();
        setState((prevState) => ({ ...prevState, uploading: null }));
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          error,
          files: _.difference(state.files, state.uploadedFiles),
        }));
      });
  };

  return (
    <Modal show={props.show} onHide={props.onHide} onEnter={() => setState(INITIAL_STATE)}>
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Upload New Files</Modal.Title>
          <ModalIcon.Upload />
        </Modal.Header>
        <Modal.Body>{renderBody()}</Modal.Body>
        {renderFooter()}
      </form>
    </Modal>
  );
};

export default UploadModal;
