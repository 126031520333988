import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { Icon } from '@keboola/design';

import { RowActionMenuItem } from '@/react/common';
import CodeEditor from '@/react/common/CodeEditor';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import { isValidJsonConfig } from '@/utils/validation';

const TaskParametersEdit = createReactClass({
  propTypes: {
    parameters: PropTypes.object.isRequired,
    onSet: PropTypes.func,
    isEditable: PropTypes.bool,
    onKeyDown: PropTypes.func,
  },

  getInitialState() {
    return {
      showModal: false,
      parametersString: '',
    };
  },

  close() {
    return this.setState({
      showModal: false,
    });
  },

  open() {
    return this.setState({
      showModal: true,
      parametersString: JSON.stringify(this.props.parameters, null, '\t'),
    });
  },

  render() {
    return (
      <>
        {this.renderOpenButton()}
        <Modal show={this.state.showModal} onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>Task Parameters</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CodeEditor
              value={this.state.parametersString}
              onChange={this._handleChange}
              options={{
                cursorHeight: !this.props.isEditable ? 0 : 1,
                height: 'auto',
                readOnly: !this.props.isEditable,
                autofocus: this.props.isEditable,
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            {this.props.isEditable && (
              <ConfirmButtons
                block
                saveLabel="Set"
                saveStyle="primary"
                isDisabled={!isValidJsonConfig(this.state.parametersString)}
                onSave={this._handleSet}
              />
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  },

  renderOpenButton() {
    return (
      <RowActionMenuItem onSelect={this.open} onKeyDown={this.props.onKeyDown}>
        <Icon icon={this.props.isEditable ? 'pen' : 'eye'} fixedWidth />
        {this.props.isEditable ? 'Edit' : 'Show'}task parameters
      </RowActionMenuItem>
    );
  },

  _handleChange(value) {
    this.setState({ parametersString: value });
  },

  _handleSet() {
    this.close();
    return this.props.onSet(JSON.parse(this.state.parametersString));
  },
});

export default TaskParametersEdit;
