import Promise from 'bluebird';

import IntalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import VersionsActionCreators from '@/modules/components/VersionsActionCreators';
import RowVersionsActionCreators from '@/modules/configurations/RowVersionsActionCreators';
import SimplifiedUiHeaderButtons from '@/modules/simplified-ui/HeaderButtons';
import { configPoll } from '@/utils/genericPolls';
import { configRequiredData } from '@/utils/genericRequiredData';
import ExDbQueryHeaderButtons from './react/components/QueryActionButtons';
import ExDbQueryName from './react/components/QueryName';
import ExDbCredentialsPage from './react/pages/credentials/CredentialsPage';
import ExDbIndex from './react/pages/index/Index';
import ExDbQueryDetail from './react/pages/query-detail/QueryDetail';
import * as credentialsTemplate from './templates/credentials';
import * as actionsProvisioning from './actionsProvisioning';
import * as storeProvisioning from './storeProvisioning';

export default function routes(componentsIds) {
  return componentsIds.map((componentId) => {
    return {
      name: componentId,
      path: `${componentId}/:config`,
      requireData: [(params) => configRequiredData(componentId, params.config)],
      title: (routerState) => {
        const configId = routerState.getIn(['params', 'config']);
        return IntalledComponentsStore.getConfig(componentId, configId).get('name');
      },
      poll: configPoll(componentId),
      defaultRouteHandler: ExDbIndex(componentId),
      headerButtonsHandler: SimplifiedUiHeaderButtons,
      childRoutes: [
        {
          name: 'ex-db-generic-' + componentId + '-query',
          path: 'query/:query',
          title: (routerState) => {
            const configId = routerState.getIn(['params', 'config']);
            const queryId = routerState.getIn(['params', 'query']);
            const ExDbStore = storeProvisioning.createStore(componentId, configId);
            return 'Query ' + ExDbStore.getConfigQuery(queryId).get('name');
          },
          nameEdit: (params) => {
            const ExDbQueryNameElement = ExDbQueryName(componentId, storeProvisioning);

            return (
              <ExDbQueryNameElement
                key={`${componentId}-${params.config}-${params.query}`}
                configId={params.config}
                queryId={params.query}
              />
            );
          },
          poll: {
            action: (params) => {
              return VersionsActionCreators.reloadVersionsAndNotifyIfDoNotMatch(
                componentId,
                params.config,
              );
            },
          },
          requireData: [
            ({ config, query }) => {
              const store = storeProvisioning.createStore(componentId, config);

              if (!store.isRowConfiguration() || store.isNewQuery(query)) {
                return Promise.resolve();
              }

              return RowVersionsActionCreators.loadVersions(componentId, config, query);
            },
          ],
          defaultRouteHandler: ExDbQueryDetail(componentId, actionsProvisioning, storeProvisioning),
          headerButtonsHandler: ExDbQueryHeaderButtons(
            componentId,
            actionsProvisioning,
            storeProvisioning,
          ),
        },
        {
          name: 'ex-db-generic-' + componentId + '-credentials',
          path: 'credentials',
          title: 'Credentials',
          defaultRouteHandler: ExDbCredentialsPage(
            componentId,
            actionsProvisioning,
            storeProvisioning,
            credentialsTemplate,
          ),
        },
      ],
    };
  });
}
