import type { Map } from 'immutable';

import { renderUser } from '@/modules/data-catalog/utils';

type CommonProps = { admins: Map<string, any>; person: Map<string, any> };
type Props = CommonProps & { label?: string };

export const UserRow = ({ admins, person, label }: Props) => (
  <div className="first-line font-medium flex-container">
    <span>{label}</span>
    <span className="flex-container flex-end font-normal text-muted">
      {renderUser({ admins, person })}
    </span>
  </div>
);
