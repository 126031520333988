import { useState } from 'react';
import type { FormEvent } from 'react';
import { Modal } from 'react-bootstrap';
import { fromJS, List, Map } from 'immutable';

import { FormGroup, Label, TextInput } from '@keboola/design';

import { KEBOOLA_WR_DB_SNOWFLAKE, KEBOOLA_WR_GOOGLE_BIGQUERY_V_2 } from '@/constants/componentIds';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { backends, nameWarning } from '@/modules/storage/constants';
import type { Backend } from '@/modules/storage/types';
import { getComponentDataTypes } from '@/modules/wr-db/templates/dataTypes';
import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import { isValidName } from '@/react/common/helpers';
import InputValidation from '@/react/common/InputValidation';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import DevBranchStorageWarning from './DevBranchStorageWarning';

type State = {
  name: string;
  definition: Map<string, any>;
  warning: string | null;
};

const INITIAL_STATE: State = {
  name: '',
  definition: Map(),
  warning: null,
};

const BACKEND_MAPPING = {
  [backends.SNOWFLAKE]: KEBOOLA_WR_DB_SNOWFLAKE,
  [backends.BIGQUERY]: KEBOOLA_WR_GOOGLE_BIGQUERY_V_2,
};

const HAS_NULLABLE = [backends.SNOWFLAKE];
const HAS_DEFAULT = [backends.SNOWFLAKE];

type Props = {
  show: boolean;
  table: Map<string, any>;
  tables: Map<string, any>;
  onSubmit: (data: Record<string, any>) => Promise<any>;
  onHide: () => void;
  addingColumn: boolean;
};

const CreateColumnModal = (props: Props) => {
  const [state, setState] = useState<State>(INITIAL_STATE);

  const shouldDefineType =
    props.table.get('isTyped', false) &&
    Object.keys(BACKEND_MAPPING).includes(props.table.getIn(['bucket', 'backend']));

  const getTypes = (backend: Backend) => {
    return fromJS(
      // @ts-expect-error TS does not recognize in operator
      getComponentDataTypes(backend in BACKEND_MAPPING ? BACKEND_MAPPING[backend] : null),
    )
      .map((type: string | Map<string, any>) => {
        if (typeof type === 'string') {
          return Map({ name: type.toUpperCase() });
        }

        const name = type.keySeq().first();
        return type.get(name).set('name', name.toUpperCase());
      })
      .toMap()
      .mapKeys((key: string, type: Map<string, any>) => type.get('name'));
  };

  const renderTypeDefinition = () => {
    if (!shouldDefineType) {
      return null;
    }

    const type = state.definition.get('type', '');
    const backend = props.table.getIn(['bucket', 'backend']);
    const types = getTypes(backend);

    return (
      <>
        <FormGroup>
          <Label htmlFor="type">Type</Label>
          <Select
            id="type"
            clearable={false}
            value={type}
            onChange={(type: string) => {
              let definition = state.definition.set('type', type);

              if (types.hasIn([type, 'defaultSize'])) {
                definition = definition.set('length', types.getIn([type, 'defaultSize']));
              } else {
                definition = definition.delete('length');
              }

              setState((prevState) => ({
                ...prevState,
                definition,
              }));
            }}
            options={types
              .sortBy((type: Map<string, any>) => type.get('name'))
              .map((type: unknown, name: string) => ({ label: name, value: name }))
              .toArray()}
          />
        </FormGroup>

        {types.hasIn([type, 'defaultSize']) && (
          <FormGroup>
            <Label htmlFor="length">Length</Label>
            <TextInput
              id="length"
              variant="secondary"
              value={state.definition.get('length', '')}
              onChange={(value) => {
                setState((prevState) => ({
                  ...prevState,
                  definition: value
                    ? prevState.definition.set('length', value)
                    : prevState.definition.delete('length'),
                }));
              }}
              placeholder={type === 'STRING' ? 'eg. 255' : 'eg. 38,0'}
            />
          </FormGroup>
        )}

        {HAS_NULLABLE.includes(backend) && (
          <FormGroup>
            <Checkbox
              disabled={!type}
              checked={state.definition.get('nullable', true)}
              onChange={(checked) => {
                setState((prevState) => ({
                  ...prevState,
                  definition: prevState.definition.set('nullable', checked),
                }));
              }}
            >
              Nullable
            </Checkbox>
          </FormGroup>
        )}

        {HAS_DEFAULT.includes(backend) && (
          <FormGroup>
            <Label htmlFor="default-value">Default value</Label>
            <TextInput
              id="default-value"
              variant="secondary"
              value={state.definition.get('default', '')}
              onChange={(value) => {
                setState((prevState) => ({
                  ...prevState,
                  definition: value
                    ? prevState.definition.set('default', value ?? '')
                    : prevState.definition.delete('default'),
                }));
              }}
              disabled={!type}
            />
          </FormGroup>
        )}
      </>
    );
  };

  const columnsOptions = () => {
    const sourceTable = props.tables.get(props.table.getIn(['sourceTable', 'id']));

    if (!sourceTable) {
      return null;
    }

    return sourceTable
      .get('columns')
      .filter((column: string) => !props.table.get('columns').includes(column))
      .map((column: string) => ({
        label: column,
        value: column,
      }))
      .toArray();
  };

  const onHide = () => {
    props.onHide();
    setState(INITIAL_STATE);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    props
      .onSubmit({
        name: state.name,
        ...(!state.definition.isEmpty() && {
          definition: state.definition.toJS(),
        }),
      })
      .then(onHide);
  };

  const validateName = () => {
    setState((prevState) => ({
      ...prevState,
      warning: props.table.get('columns', List()).includes(prevState.name)
        ? `The column "${prevState.name}" already exists.`
        : null,
    }));
  };

  const isDisabled =
    !state.name ||
    !isValidName(state.name) ||
    !!state.warning ||
    (shouldDefineType && !state.definition.get('type'));

  return (
    <Modal show={props.show} onHide={onHide}>
      <form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Create Column</Modal.Title>
          <ModalIcon.Plus />
        </Modal.Header>
        <Modal.Body className="tw-flex tw-flex-col tw-gap-4">
          <DevBranchStorageWarning
            message="The column will also be added to the table in production."
            hasProductionEntity={!isCreatedInDevBranch(props.table.get('bucket'))}
          />
          <InputValidation predefined="columnName" value={state.name}>
            {(inputState: 'error' | null) => {
              const formState = !!state.warning || inputState === 'error' ? 'error' : 'default';
              const messages = {
                default: nameWarning,
                error: state.warning ?? nameWarning,
              };

              return (
                <FormGroup state={formState}>
                  <Label htmlFor="name">Name</Label>
                  {props.table.get('sourceTable') ? (
                    <Select
                      id="name"
                      clearable={false}
                      placeholder="Column name"
                      value={state.name}
                      onChange={(column: string) => {
                        setState((prevState) => ({ ...prevState, name: column }));
                      }}
                      options={columnsOptions()}
                    />
                  ) : (
                    <>
                      <FormGroup.TextInput
                        id="name"
                        variant="secondary"
                        autoFocus
                        value={state.name}
                        onChange={(name) => {
                          setState((prevState) => ({ ...prevState, name }));
                          validateName();
                        }}
                        placeholder="Column name"
                      />

                      <FormGroup.Help>{messages[formState]}</FormGroup.Help>
                    </>
                  )}
                </FormGroup>
              );
            }}
          </InputValidation>
          {renderTypeDefinition()}
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isDisabled={isDisabled}
            isSaving={props.addingColumn}
            saveLabel={props.addingColumn ? 'Creating column...' : 'Create column'}
            saveButtonType="submit"
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CreateColumnModal;
