import type { GenericFetchClient } from '../managementClient';

import type {
  CreateProjectFeatureBody,
  CreateProjectFeaturePath,
  GetProjectPath,
  Project,
  RemoveProjectFeaturePath,
} from './types';

export const createProjects = (client: GenericFetchClient) => {
  const getProject = async (projectId: GetProjectPath['projectId'], signal?: AbortSignal) => {
    const { data } = await client.get<Project, GetProjectPath>(
      '/projects/{projectId}',
      { path: { projectId } },
      { signal },
    );
    return data;
  };

  const addProjectFeature = async ({
    projectId,
    feature,
  }: CreateProjectFeaturePath & CreateProjectFeatureBody) => {
    const { data } = await client.post<
      Project,
      CreateProjectFeaturePath,
      void,
      CreateProjectFeatureBody
    >('/projects/{projectId}/features', { path: { projectId }, body: { feature } });
    return data;
  };

  const removeProjectFeature = async (path: RemoveProjectFeaturePath) => {
    const { data } = await client.delete<null, RemoveProjectFeaturePath>(
      '/projects/{projectId}/features/{feature}',
      { path },
    );
    return data;
  };

  return {
    getProject,
    addProjectFeature,
    removeProjectFeature,
  };
};
