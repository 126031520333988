import { routeNames } from '@/modules/settings/constants';
import { TabLink, TabNav } from '@/react/common';
import ApplicationStore from '@/stores/ApplicationStore';

const NAV_TABS = [
  { title: 'Project', routeName: routeNames.PROJECT },
  { title: 'Users', routeName: routeNames.USERS },
  { title: 'API Tokens', routeName: routeNames.TOKENS },
  { title: 'CLI Sync', routeName: routeNames.CLI },
  { title: 'Features', routeName: routeNames.FEATURES },
];

const SettingsTabs = (props) => {
  return (
    <>
      <div className="tabs-with-border-wrapper">
        <TabNav>
          {NAV_TABS.filter((tab) => {
            if (tab.routeName === routeNames.CLI && ApplicationStore.hasProtectedDefaultBranch()) {
              return false;
            }

            return true;
          }).map((tab) => (
            <TabLink
              key={tab.routeName}
              to={tab.routeName}
              active={tab.routeName === props.activeTab}
            >
              {tab.title}
            </TabLink>
          ))}
        </TabNav>
      </div>
      {props.children}
    </>
  );
};

export default SettingsTabs;
