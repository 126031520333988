import type { ComponentProps } from 'react';

import { Button, Icon, IconButton } from '@keboola/design';

const DescriptionButton = (props: {
  onClick: () => void;
  isFilled: boolean;
  readOnly?: boolean;
  iconOnly?: boolean;
  showsInModalOnly?: boolean;
}) => {
  if (props.readOnly && !props.isFilled) return null;

  const icon: ComponentProps<typeof Icon>['icon'] = props.isFilled
    ? 'note-sticky'
    : ['far', 'note-sticky'];

  if (props.iconOnly) {
    return <IconButton icon={icon} onClick={props.onClick} />;
  }

  return (
    <Button onClick={props.onClick} variant="outline">
      <Icon icon={icon} />
      <>
        {props.isFilled ? (props.showsInModalOnly || props.readOnly ? 'Show' : 'Edit') : 'Add'}{' '}
        Description
      </>
    </Button>
  );
};

export default DescriptionButton;
