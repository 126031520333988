import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';
import _ from 'underscore';

import { Icon } from '@keboola/design';

import { CircleIcon } from '@/react/common';
import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import Select from '@/react/common/Select';
import ColumnRow from './ColumnRow';

const columnTdeTypes = ['string', 'boolean', 'number', 'decimal', 'date', 'datetime', 'IGNORE'];
const defaults = {
  date: '%Y-%m-%d',
  datetime: '%Y-%m-%d %H:%M:%S',
};

const ColumnsTable = createReactClass({
  propTypes: {
    table: PropTypes.object.isRequired,
    columnsTypes: PropTypes.object.isRequired,
    isSaving: PropTypes.bool.isRequired,
    loadPreviewFn: PropTypes.func.isRequired,
    loadingPreview: PropTypes.bool.isRequired,
    dataPreview: PropTypes.object,
    editingData: PropTypes.object,
    onChange: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      hideIgnored: false,
    };
  },

  render() {
    if (!this.props.editingData) {
      return null;
    }

    return (
      <>
        <CircleIcon className="box-icon" icon="line-columns" color="blue" bold />
        <div className="box pt-1">
          <div className="box-content pt-0 pb-0 flex-container">
            <h2>Columns</h2>
            {this.renderEditButtons()}
          </div>
          <table className="table">
            <thead>
              <tr>
                <th className="tw-w-80">Column</th>
                <th>
                  TDE Data Type
                  {this.renderSetColumnsType()}
                </th>
                <th className="tw-w-20" />
              </tr>
            </thead>
            <tbody>{this.renderTableBody()}</tbody>
          </table>
        </div>
      </>
    );
  },

  renderTableBody() {
    const isEditing = !!this.props.editingData;

    const columns = this.props.table.get('columns', List()).filterNot((c) => {
      const isEditingIgnored =
        (this.props.editingData && this.props.editingData.getIn([c, 'type'])) === 'IGNORE';
      if (isEditing) {
        return this.state.hideIgnored && isEditingIgnored;
      }

      const isStaticIgnored = !!!this.props.columnsTypes.getIn([c, 'type']);
      return this.state.hideIgnored && isStaticIgnored;
    });

    if (!columns.count()) {
      return (
        <tr>
          <td colSpan="3">
            <div className="text-center">No Columns.</div>
          </td>
        </tr>
      );
    }

    return columns.map((column, index) => {
      return (
        <ColumnRow
          key={index}
          column={column}
          tdeType={this.props.columnsTypes.get(column, Map())}
          editing={this.props.editingData && this.props.editingData.get(column)}
          isSaving={this.props.isSaving}
          dataPreview={this.props.dataPreview}
          onChange={(data) => {
            const newData = this.props.editingData.set(column, data);
            this.props.onChange(newData);
          }}
          loadingPreview={this.props.loadingPreview}
          loadPreviewFn={this.props.loadPreviewFn}
        />
      );
    });
  },

  renderEditButtons() {
    const hasColumns = this.props.editingData.filter((column) => column.get('type') !== 'IGNORE');

    return (
      <ConfirmButtons
        saveStyle="primary"
        cancelLabel="Reset"
        showCancel={true}
        isSaving={this.props.isSaving}
        isDisabled={!hasColumns.count()}
        onCancel={this.props.onReset}
        onSave={this.props.onSave}
      />
    );
  },

  renderSetColumnsType() {
    return (
      <Dropdown id="column-editor-datatype" dropup pullRight>
        <Dropdown.Toggle noCaret bsStyle="link">
          <Icon icon={['far', 'ellipsis']} className="text-muted" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="w-250 p-1">
          <div>
            <Checkbox
              className="pb-1"
              checked={this.state.hideIgnored}
              onChange={() => this.setState({ hideIgnored: !this.state.hideIgnored })}
            >
              Hide Ignored
            </Checkbox>
          </div>
          <Select
            clearable={false}
            placeholder="Set All Types to"
            options={columnTdeTypes.map((type) => ({ value: type, label: type }))}
            onChange={this._prefillSelectedType}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  },

  _prefillSelectedType(value) {
    const newColumns = this.props.editingData.map((ec) => {
      let newColumn = ec.set('type', value);

      if (_.keys(defaults).includes(value)) {
        newColumn = newColumn.set('format', defaults[value]);
        return newColumn;
      }

      newColumn = newColumn.set('format', null);
      return newColumn;
    });

    this.props.onChange(newColumns);
  },
});

export default ColumnsTable;
