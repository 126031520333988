import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { cn } from '@keboola/design';

import {
  KEBOOLA_LEGACY_TRANSFORMATION,
  KEBOOLA_ORCHESTRATOR,
  KEBOOLA_SHARED_CODE,
  ORCHESTRATOR,
  TRANSFORMATION,
} from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import {
  hasDisabledCopy,
  hasDisabledRollback,
  isComponentDeprecated,
} from '@/modules/components/helpers';
import VersionRow from '@/modules/components/react/components/VersionRow';
import VersionsTable from '@/modules/components/react/components/VersionsTable';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import VersionsStore from '@/modules/components/stores/VersionsStore';
import VersionsActionCreators from '@/modules/components/VersionsActionCreators';
import { routeNames as transformationRoutes } from '@/modules/transformations-v2/constants';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { matchByWords } from '@/utils';
import createVersionOnRollback from '@/utils/createVersionOnRollback';
import { getPreviousVersion } from '@/utils/VersionsDiffUtils';

const Versions = function (componentIdValue, configIdParam, links) {
  return createReactClass({
    mixins: [
      createStoreMixin(ApplicationStore, VersionsStore, InstalledComponentsStore, ComponentsStore),
      immutableMixin,
    ],

    getStateFromStores() {
      const configId = RoutesStore.getCurrentRouteParam(configIdParam);
      const componentId = RoutesStore.getCurrentRouteParam('component') || componentIdValue;
      const component = ComponentsStore.getComponent(componentId);
      const sharedCodes = InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SHARED_CODE)
        .find(
          (config) => config.getIn(['configuration', 'componentId']) === componentId,
          null,
          Map(),
        )
        .get('rows', List());

      return {
        configId,
        sharedCodes,
        component,
        config: InstalledComponentsStore.getConfig(componentId, configId),
        versions: VersionsStore.getVersions(componentId, configId),
        versionsConfigs: VersionsStore.getVersionsConfigs(componentId, configId),
        isPending: VersionsStore.isPendingConfig(componentId, configId),
        pendingActions: VersionsStore.getPendingVersions(componentId, configId),
        pendingMultiLoad: VersionsStore.getPendingMultiLoad(componentId, configId),
        rollbackDisabled: hasDisabledRollback(component.get('id')),
        copyDisabled: hasDisabledCopy(component.get('id')),
        deprecated: isComponentDeprecated(component),
        readOnly: ApplicationStore.isReadOnly(),
        hasFlows: ApplicationStore.hasFlows(),
        admins: ApplicationStore.getAdmins(),
      };
    },

    getInitialState() {
      return {
        query: '',
      };
    },

    render() {
      const showTabs = ![
        KEBOOLA_ORCHESTRATOR,
        ORCHESTRATOR,
        TRANSFORMATION,
        KEBOOLA_LEGACY_TRANSFORMATION,
      ].includes(this.state.component.get('id'));

      const resolvedLinks = (() => {
        if (this.state.component.get('type') === componentTypes.TRANSFORMATION)
          return {
            versionsLinkTo: transformationRoutes.GENERIC_TRANSFORMATION_VERSIONS,
            notificationsLinkTo: transformationRoutes.GENERIC_TRANSFORMATION_NOTIFICATIONS,
          };

        return {
          versionsLinkTo: links?.versionsLinkTo,
          notificationsLinkTo: links?.notificationsLinkTo,
        };
      })();

      return (
        <>
          {showTabs && (
            <ConfigurationTabs
              componentId={this.state.component.get('id')}
              configId={this.state.configId}
              {...resolvedLinks}
            />
          )}
          <div className={cn({ 'box-separator': showTabs })}>
            <VersionsTable
              versions={this.state.versions}
              filteredVersions={this.getFilteredVersions()}
              renderRows={this.renderVersionRows}
              onSearchChange={(query) => this.setState({ query })}
              query={this.state.query}
            />
          </div>
        </>
      );
    },

    renderVersionRows(versions) {
      return versions
        .map((version) => {
          const previousVersion = getPreviousVersion(this.state.versions, version);
          const previousVersionConfig =
            getPreviousVersion(this.state.versionsConfigs, version) || Map();
          const isMultiPending = this.state.pendingMultiLoad.get(version.get('version'), false);
          const currentVersionConfig =
            this.state.versionsConfigs
              .filter((currentVersion) => {
                return version.get('version') === currentVersion.get('version');
              })
              .first() || Map();

          return (
            <VersionRow
              key={version.get('version')}
              version={version}
              versionConfig={currentVersionConfig}
              configId={this.state.configId}
              component={this.state.component}
              config={this.state.config}
              admins={this.state.admins}
              isCopyPending={this.state.pendingActions.getIn(
                [version.get('version'), 'copy'],
                false,
              )}
              isCopyDisabled={this.state.isPending}
              isRollbackPending={this.state.pendingActions.getIn(
                [version.get('version'), 'rollback'],
                false,
              )}
              isRollbackDisabled={this.state.rollbackDisabled || this.state.isPending}
              hideRollback={this.state.rollbackDisabled || this.state.readOnly}
              hideCopy={this.state.copyDisabled || this.state.deprecated || this.state.readOnly}
              isDiffPending={isMultiPending}
              isDiffDisabled={this.state.isPending || isMultiPending}
              previousVersion={previousVersion}
              previousVersionConfig={previousVersionConfig}
              onPrepareVersionsDiffData={() =>
                this.prepareVersionsDiffData(version, previousVersion)
              }
              isLast={this.state.versions.first().get('version') === version.get('version')}
              onCopy={(name, excludeRows) =>
                VersionsActionCreators.copyVersion(
                  this.state.component.get('id'),
                  this.state.configId,
                  version.get('version'),
                  name,
                  excludeRows,
                )
              }
              onRollback={createVersionOnRollback(
                this.state.component.get('id'),
                this.state.configId,
                version.get('version'),
              )}
              sharedCodes={this.state.sharedCodes}
              hasFlows={this.state.hasFlows}
            />
          );
        })
        .toArray();
    },

    getFilteredVersions() {
      if (!this.state.query) {
        return this.state.versions;
      }

      return this.state.versions.filter((version) => {
        return matchByWords(
          [
            String(version.get('version')),
            version.get('changeDescription'),
            version.getIn(['creatorToken', 'description']),
            version.get('created'),
          ],
          this.state.query,
        );
      });
    },

    prepareVersionsDiffData(version1, version2) {
      return VersionsActionCreators.loadTwoComponentConfigVersions(
        this.state.component.get('id'),
        this.state.configId,
        version1.get('version'),
        version2.get('version'),
      );
    },
  });
};

export default Versions;
