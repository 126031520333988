import { fromJS, Map } from 'immutable';

import Dispatcher from '@/Dispatcher';
import * as constants from '@/modules/components/Constants';
import * as metadataConstants from '@/modules/components/MetadataConstants';
import { prepareSharedBuckets } from '@/modules/data-catalog/helpers';
import StoreUtils, { initStore } from '@/utils/StoreUtils';

let _store = initStore(
  'StorageBucketsStore',
  Map({
    buckets: Map(),
    pendingBuckets: Map(),
    sharedBuckets: Map(),
  }),
);

const StorageBucketsStore = StoreUtils.createStore({
  getAll() {
    return _store.get('buckets');
  },

  getSharedBuckets() {
    return _store.get('sharedBuckets');
  },

  getSharedBucket(bucketId, currentProjectId, sharedProjectId) {
    const allSharedBuckets = prepareSharedBuckets(
      this.getSharedBuckets(),
      this.getLinkedBuckets(),
      currentProjectId,
    );
    return allSharedBuckets.get(`${sharedProjectId}-${bucketId}`, Map());
  },

  getLinkedBuckets() {
    return _store.get('buckets').filter((bucket) => bucket.has('sourceBucket'));
  },

  hasBucket(bucketId) {
    return _store.get('buckets').has(bucketId);
  },

  getBucket(bucketId, defaultValue) {
    return _store.getIn(['buckets', bucketId], defaultValue);
  },

  getPendingBucketsActions() {
    return _store.get('pendingBuckets');
  },

  isCreatingBucket() {
    return _store.getIn(['pendingBuckets', 'creating'], false);
  },

  deletingBuckets(bucketId) {
    return _store.getIn(['pendingBuckets', 'deleting', bucketId], false);
  },
});

StorageBucketsStore.dispatchToken = Dispatcher.register(function (payload) {
  const { action } = payload;

  switch (action.type) {
    case constants.ActionTypes.STORAGE_BUCKETS_AND_TABLES_LOAD_SUCCESS: {
      _store = _store.set(
        'buckets',
        fromJS(action.buckets)
          .toMap()
          .mapKeys((key, bucket) => bucket.get('id')),
      );
      return StorageBucketsStore.emitChange();
    }

    case constants.ActionTypes.STORAGE_BUCKET_CREATE:
      _store = _store.setIn(['pendingBuckets', 'creating'], true);
      return StorageBucketsStore.emitChange();

    case constants.ActionTypes.STORAGE_BUCKET_CREATE_SUCCESS:
      _store = _store.setIn(['pendingBuckets', 'creating'], false);
      _store = _store.setIn(['buckets', action.bucket.id], fromJS(action.bucket));
      return StorageBucketsStore.emitChange();

    case constants.ActionTypes.STORAGE_BUCKET_CREATE_ERROR:
      _store = _store.setIn(['pendingBuckets', 'creating'], false);
      return StorageBucketsStore.emitChange();

    case constants.ActionTypes.STORAGE_BUCKET_DETAIL_LOAD_SUCCESS:
      _store = _store.setIn(['buckets', action.bucket.id], fromJS(action.bucket).remove('tables'));
      return StorageBucketsStore.emitChange();

    case constants.ActionTypes.STORAGE_BUCKET_DELETE:
      _store = _store.setIn(['pendingBuckets', 'deleting', action.bucketId], true);
      return StorageBucketsStore.emitChange();

    case constants.ActionTypes.STORAGE_BUCKET_DELETE_SUCCESS:
      _store = _store.deleteIn(['pendingBuckets', 'deleting', action.bucketId]);
      _store = _store.deleteIn(['buckets', action.bucketId]);
      return StorageBucketsStore.emitChange();

    case constants.ActionTypes.STORAGE_BUCKET_DELETE_ERROR:
      _store = _store.deleteIn(['pendingBuckets', 'deleting', action.bucketId]);
      return StorageBucketsStore.emitChange();

    case constants.ActionTypes.STORAGE_SHARED_BUCKETS_LOAD_SUCCESS:
      _store = _store.set('sharedBuckets', fromJS(action.sharedBuckets));
      return StorageBucketsStore.emitChange();

    case metadataConstants.ActionTypes.METADATA_SAVE_SUCCESS:
      if (action.objectType === metadataConstants.ObjectTypes.BUCKET) {
        _store = _store.setIn(['buckets', action.objectId, 'metadata'], fromJS(action.metadata));
        return StorageBucketsStore.emitChange();
      }
      break;

    default:
  }
});

export default StorageBucketsStore;
