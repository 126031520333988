import { memo } from 'react';
import type { MouseEvent } from 'react';
import type { Map } from 'immutable';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  LabelList,
  ResponsiveContainer,
  Text,
  Tooltip as ChartTooltip,
  XAxis,
  YAxis,
} from 'recharts';

import dayjs, { DATE_FORMAT_WITHOUT_YEAR, TIME_FORMAT } from '@/date';
import { routeNames } from '@/modules/queue/constants';
import { JOBS_GRAPH_COLORS, JOBS_GRAPH_COLORS_HOVER } from '@/react/common/Graph/constants';
import JobsGraphTooltip from '@/react/common/Graph/JobsGraphTooltip';
import StatusIndicator from '@/react/common/Graph/StatusIndicator';
import type { GraphJob } from '@/react/common/Graph/types';
import RoutesStore from '@/stores/RoutesStore';
import { durationInWords } from '@/utils/duration';
import { shouldUseNewWindow, windowOpen } from '@/utils/windowOpen';

const margin = { top: 20, right: 20, left: 20, bottom: 20 };

export type State = {
  graphData: Map<string, any> | null;
  allJobRunsPaged: Map<string, any>;
  currentJobRunsPage: number;
  loadingJobs: boolean;
  activeIndex: number | null;
};

const TickY = (props: { jobs: GraphJob[]; x?: string; y?: string; payload?: any }) => {
  const referenceJob = props.jobs.find((job) => !!job.status);

  return (
    <Text {...props} fontSize={'12'} fill={'#888'}>
      {referenceJob ? durationInWords(props.payload.value, referenceJob.unit) : ''}
    </Text>
  );
};

const TickX = (props: { jobs: GraphJob[]; x?: number; y?: number; payload?: any }) => {
  const job = props.jobs[props.payload?.index];

  if (!job.date) {
    return null;
  }

  return (
    <g className="tick-date">
      <text x={props.x} y={props.y} textAnchor="center">
        <tspan x={props.x} y={props.y}>
          {dayjs(job.date).format(DATE_FORMAT_WITHOUT_YEAR)}
        </tspan>
        <tspan x={props.x} y={props.y || 0 + 2} dy={10}>
          {dayjs(job.date).format(TIME_FORMAT)}
        </tspan>
      </text>
    </g>
  );
};

const JobsGraphMemo = memo(
  ({
    jobsData,
    jobId,
    activeIndex,
    setState,
  }: {
    jobsData: GraphJob[];
    jobId: string;
    activeIndex: number | null;
    setState: (state: Partial<State>) => void;
  }) => {
    const getBarFill = (job: GraphJob, i: number) => {
      return i === activeIndex
        ? JOBS_GRAPH_COLORS_HOVER[job.status]
        : jobId === job.jobId
          ? JOBS_GRAPH_COLORS[job.status]
          : '#c5cbd6';
    };

    const goToJob = (event: MouseEvent, jobId: string) => {
      if (shouldUseNewWindow(event)) {
        return windowOpen(RoutesStore.getRouter().createHref(routeNames.JOB_DETAIL, { jobId }));
      }

      return RoutesStore.getRouter().transitionTo(routeNames.JOB_DETAIL, { jobId });
    };

    return (
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart data={jobsData} dataKey="duration" margin={margin}>
          <YAxis
            dataKey="duration"
            axisLine={false}
            tickLine={false}
            textAnchor="end"
            tickCount={4}
            allowDuplicatedCategory={false}
            allowDecimals={false}
            tick={<TickY jobs={jobsData} />}
          />

          <XAxis
            className="xAxis"
            axisLine={false}
            tickLine={false}
            tick={<TickX jobs={jobsData} />}
          />

          <CartesianGrid vertical={false} opacity={0.5} />

          <Bar
            data={jobsData as (GraphJob & { x: number })[]}
            dataKey="duration"
            isAnimationActive={false}
            barSize={12}
          >
            <LabelList
              dataKey="duration"
              position="top"
              content={(props) => {
                const index = Number(props?.index);
                const job = jobsData[index];
                const jobStatus = job?.status;

                if (jobStatus) {
                  return (
                    <StatusIndicator
                      x={Number(props?.x)}
                      y={Number(props?.y) - 6}
                      radius={4}
                      width={Number(props?.width)}
                      color={JOBS_GRAPH_COLORS[jobStatus]}
                      onClick={(event) => goToJob(event, job?.jobId)}
                      onMouseEnter={() => {
                        setState({ activeIndex: index });
                      }}
                      onMouseLeave={() => {
                        setState({ activeIndex: null });
                      }}
                    />
                  );
                }

                return null;
              }}
            />
            {jobsData.map((entry, i) => (
              <Cell
                key={entry.jobId || i}
                fill={getBarFill(entry, i)}
                style={{ cursor: 'pointer' }}
                stroke="transparent"
                strokeWidth={8}
                onMouseEnter={() => {
                  setState({ activeIndex: i });
                }}
                onMouseLeave={() => {
                  setState({ activeIndex: null });
                }}
                onClick={(event) => goToJob(event, entry.jobId)}
              />
            ))}
          </Bar>

          <ChartTooltip
            cursor={false}
            isAnimationActive={false}
            content={() => {
              const job = activeIndex !== null && jobsData[activeIndex];
              if (!job) {
                return null;
              }
              return <JobsGraphTooltip job={job} />;
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  },
);

export default JobsGraphMemo;
