import { Separator } from '../Separator';

import { useRadioGroupContext } from './context';

export const RadioGroupSeparator = () => {
  const { orientation } = useRadioGroupContext();
  const invertedOrientation = orientation === 'horizontal' ? 'vertical' : 'horizontal';
  const size = orientation === 'horizontal' ? '1/2' : 'full';
  const className = orientation === 'horizontal' ? 'tw-mx-2' : 'tw-my-2';

  return <Separator orientation={invertedOrientation} size={size} className={className} />;
};
