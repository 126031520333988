import { KEBOOLA_APP_MERGE_BRANCH_STORAGE, TRANSFORMATION } from '@/constants/componentIds';

const ActionTypes = {
  // Components
  COMPONENTS_LOAD_SUCCESS: 'COMPONENTS_LOAD_SUCCESS',
  COMPONENT_LOAD_SUCCESS: 'COMPONENT_LOAD_SUCCESS',
  COMPONENT_SET_OVERRIDES: 'COMPONENT_SET_OVERRIDES',

  // Installed components
  INSTALLED_COMPONENTS_LOAD: 'INSTALLED_COMPONENTS_LOAD',
  INSTALLED_COMPONENTS_LOAD_SUCCESS: 'INSTALLED_COMPONENTS_LOAD_SUCCESS',
  INSTALLED_COMPONENTS_LOAD_ERROR: 'INSTALLED_COMPONENTS_LOAD_ERROR',

  // Deleted components
  DELETED_COMPONENTS_LOAD: 'DELETED_COMPONENTS_LOAD',
  DELETED_COMPONENTS_LOAD_SUCCESS: 'DELETED_COMPONENTS_LOAD_SUCCESS',
  DELETED_COMPONENTS_LOAD_ERROR: 'DELETED_COMPONENTS_LOAD_ERROR',

  // Components metadata
  INSTALLED_COMPONENTS_METADATA_LOAD_SUCCESS: 'INSTALLED_COMPONENTS_METADATA_LOAD_SUCCESS',
  CONFIGURATION_METADATA_SAVE_SUCCESS: 'CONFIGURATION_METADATA_SAVE_SUCCESS',
  CONFIGURATION_METADATA_DELETE_SUCCESS: 'CONFIGURATION_METADATA_DELETE_SUCCESS',
  TOGGLE_EXPAND_FOLDER: 'TOGGLE_EXPAND_FOLDER',

  // Installed components data
  INSTALLED_COMPONENTS_CONFIGDATA_LOAD_SUCCESS: 'INSTALLED_COMPONENTS_CONFIGDATA_LOAD_SUCCESS',
  INSTALLED_COMPONENTS_CONFIGSDATA_LOAD: 'INSTALLED_COMPONENTS_CONFIGSDATA_LOAD',
  INSTALLED_COMPONENTS_CONFIGSDATA_LOAD_SUCCESS: 'INSTALLED_COMPONENTS_CONFIGSDATA_LOAD_SUCCESS',
  INSTALLED_COMPONENTS_CONFIGDATA_SAVE_START: 'INSTALLED_COMPONENTS_CONFIGDATA_SAVE_START',
  INSTALLED_COMPONENTS_CONFIGDATA_SAVE_SUCCESS: 'INSTALLED_COMPONENTS_CONFIGDATA_SAVE_SUCCESS',
  INSTALLED_COMPONENTS_CONFIGDATA_SAVE_ERROR: 'INSTALLED_COMPONENTS_CONFIGDATA_SAVE_ERROR',

  INSTALLED_COMPONENTS_RAWCONFIGDATA_EDIT_UPDATE: 'INSTALLED_COMPONENTS_RAWCONFIGDATA_EDIT_UPDATE',
  INSTALLED_COMPONENTS_RAWCONFIGDATA_EDIT_CANCEL: 'INSTALLED_COMPONENTS_RAWCONFIGDATA_EDIT_CANCEL',

  INSTALLED_COMPONENTS_RAWCONFIGDATAPARAMETERS_EDIT_UPDATE:
    'INSTALLED_COMPONENTS_RAWCONFIGDATAPARAMETERS_EDIT_UPDATE',
  INSTALLED_COMPONENTS_RAWCONFIGDATAPARAMETERS_EDIT_CANCEL:
    'INSTALLED_COMPONENTS_RAWCONFIGDATAPARAMETERS_EDIT_CANCEL',

  INSTALLED_COMPONENTS_LOCAL_STATE_UPDATE: 'INSTALLED_COMPONENTS_LOCAL_STATE_UPDATE',

  INSTALLED_COMPONENTS_UPDATE_CONFIGURATION_SUCCESS:
    'INSTALLED_COMPONENTS_UPDATE_CONFIGURATION_SUCCESS',

  INSTALLED_COMPONENTS_DELETE_CONFIGURATION_SUCCESS:
    'INSTALLED_COMPONENTS_DELETE_CONFIGURATION_SUCCESS',

  INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_CANCEL:
    'INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_CANCEL',
  INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_UPDATE_TEMPLATE:
    'INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_UPDATE_TEMPLATE',
  INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_UPDATE_PARAMS:
    'INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_UPDATE_PARAMS',
  INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_UPDATE_STRING:
    'INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_UPDATE_STRING',

  // New configurations
  INSTALLED_COMPONENTS_NEW_CONFIGURATION_SAVE_SUCCESS:
    'INSTALLED_COMPONENTS_NEW_CONFIGURATION_SAVE_SUCCESS',

  // Storage
  STORAGE_BUCKETS_AND_TABLES_LOAD_START: 'STORAGE_BUCKETS_AND_TABLES_LOAD_START',
  STORAGE_BUCKETS_AND_TABLES_LOAD_SUCCESS: 'STORAGE_BUCKETS_AND_TABLES_LOAD_SUCCESS',
  STORAGE_BUCKETS_AND_TABLES_LOAD_DONE: 'STORAGE_BUCKETS_AND_TABLES_LOAD_DONE',

  STORAGE_TABLE_DETAIL_LOAD_SUCCESS: 'STORAGE_TABLE_DETAIL_LOAD_SUCCESS',
  STORAGE_BUCKET_DETAIL_LOAD_SUCCESS: 'STORAGE_BUCKET_DETAIL_LOAD_SUCCESS',

  STORAGE_FILES_LOAD: 'STORAGE_FILES_LOAD',
  STORAGE_FILES_LOAD_SUCCESS: 'STORAGE_FILES_LOAD_SUCCESS',
  STORAGE_FILES_LOAD_ERROR: 'STORAGE_FILES_LOAD_ERROR',

  STORAGE_FILES_LOAD_MORE: 'STORAGE_FILES_LOAD_MORE',
  STORAGE_FILES_LOAD_MORE_SUCCESS: 'STORAGE_FILES_LOAD_MORE_SUCCESS',
  STORAGE_FILES_LOAD_MORE_ERROR: 'STORAGE_FILES_LOAD_MORE_ERROR',

  STORAGE_FILES_RESET: 'STORAGE_FILES_RESET',

  STORAGE_FILE_DELETE: 'STORAGE_FILE_DELETE',
  STORAGE_FILE_DELETE_SUCCESS: 'STORAGE_FILE_DELETE_SUCCESS',
  STORAGE_FILE_DELETE_ERROR: 'STORAGE_FILE_DELETE_ERROR',

  STORAGE_FILE_PREPARE_DOWNLOAD: 'STORAGE_FILE_PREPARE_DOWNLOAD',
  STORAGE_FILE_PREPARE_DOWNLOAD_DONE: 'STORAGE_FILE_PREPARE_DOWNLOAD_DONE',

  STORAGE_FILE_ADD_TAG_SUCCESS: 'STORAGE_FILE_ADD_TAG_SUCCESS',
  STORAGE_FILE_DELETE_TAG_SUCCESS: 'STORAGE_FILE_DELETE_TAG_SUCCESS',

  STORAGE_BUCKET_CREATE: 'STORAGE_BUCKET_CREATE',
  STORAGE_BUCKET_CREATE_SUCCESS: 'STORAGE_BUCKET_CREATE_SUCCESS',
  STORAGE_BUCKET_CREATE_ERROR: 'STORAGE_BUCKET_CREATE_ERROR',

  STORAGE_BUCKET_DELETE: 'STORAGE_BUCKET_DELETE',
  STORAGE_BUCKET_DELETE_SUCCESS: 'STORAGE_BUCKET_DELETE_SUCCESS',
  STORAGE_BUCKET_DELETE_ERROR: 'STORAGE_BUCKET_DELETE_ERROR',

  STORAGE_SHARED_BUCKETS_LOAD: 'STORAGE_SHARED_BUCKETS_LOAD',
  STORAGE_SHARED_BUCKETS_LOAD_SUCCESS: 'STORAGE_SHARED_BUCKETS_LOAD_SUCCESS',
  STORAGE_SHARED_BUCKETS_LOAD_ERROR: 'STORAGE_SHARED_BUCKETS_LOAD_ERROR',

  STORAGE_TABLE_CREATE: 'STORAGE_TABLE_CREATE',
  STORAGE_TABLE_CREATE_SUCCESS: 'STORAGE_TABLE_CREATE_SUCCESS',
  STORAGE_TABLE_CREATE_ERROR: 'STORAGE_TABLE_CREATE_ERROR',

  STORAGE_ADD_TABLE_COLUMN: 'STORAGE_ADD_TABLE_COLUMN',
  STORAGE_ADD_TABLE_COLUMN_SUCCESS: 'STORAGE_ADD_TABLE_COLUMN_SUCCESS',
  STORAGE_ADD_TABLE_COLUMN_ERROR: 'STORAGE_ADD_TABLE_COLUMN_ERROR',

  STORAGE_DELETE_TABLE_COLUMN: 'STORAGE_DELETE_TABLE_COLUMN',
  STORAGE_DELETE_TABLE_COLUMN_SUCCESS: 'STORAGE_DELETE_TABLE_COLUMN_SUCCESS',
  STORAGE_DELETE_TABLE_COLUMN_ERROR: 'STORAGE_DELETE_TABLE_COLUMN_ERROR',

  STORAGE_DELETE_TABLE_COLUMNS: 'STORAGE_DELETE_TABLE_COLUMNS',

  STORAGE_ALIAS_TABLE_CREATE: 'STORAGE_ALIAS_TABLE_CREATE',
  STORAGE_ALIAS_TABLE_CREATE_SUCCESS: 'STORAGE_ALIAS_TABLE_CREATE_SUCCESS',
  STORAGE_ALIAS_TABLE_CREATE_ERROR: 'STORAGE_ALIAS_TABLE_CREATE_ERROR',

  STORAGE_SET_ALIAS_TABLE_FILTER: 'STORAGE_SET_ALIAS_TABLE_FILTER',
  STORAGE_SET_ALIAS_TABLE_FILTER_SUCCESS: 'STORAGE_SET_ALIAS_TABLE_FILTER_SUCCESS',
  STORAGE_SET_ALIAS_TABLE_FILTER_ERROR: 'STORAGE_SET_ALIAS_TABLE_FILTER_ERROR',

  STORAGE_REMOVE_ALIAS_TABLE_FILTER: 'STORAGE_REMOVE_ALIAS_TABLE_FILTER',
  STORAGE_REMOVE_ALIAS_TABLE_FILTER_SUCCESS: 'STORAGE_REMOVE_ALIAS_TABLE_FILTER_SUCCESS',
  STORAGE_REMOVE_ALIAS_TABLE_FILTER_ERROR: 'STORAGE_REMOVE_ALIAS_TABLE_FILTER_ERROR',

  STORAGE_TABLE_LOAD: 'STORAGE_TABLE_LOAD',
  STORAGE_TABLE_LOAD_SUCCESS: 'STORAGE_TABLE_LOAD_SUCCESS',
  STORAGE_TABLE_LOAD_ERROR: 'STORAGE_TABLE_LOAD_ERROR',

  STORAGE_TABLE_UPDATE: 'STORAGE_TABLE_UPDATE',
  STORAGE_TABLE_UPDATE_SUCCESS: 'STORAGE_TABLE_UPDATE_SUCCESS',
  STORAGE_TABLE_UPDATE_ERROR: 'STORAGE_TABLE_UPDATE_ERROR',

  STORAGE_TABLE_CREATE_SNAPSHOTS: 'STORAGE_TABLE_CREATE_SNAPSHOTS',
  STORAGE_TABLE_CREATE_SNAPSHOTS_SUCCESS: 'STORAGE_TABLE_CREATE_SNAPSHOTS_SUCCESS',
  STORAGE_TABLE_CREATE_SNAPSHOTS_ERROR: 'STORAGE_TABLE_CREATE_SNAPSHOTS_ERROR',

  STORAGE_RESTORE_TIME_TRAVEL: 'STORAGE_RESTORE_TIME_TRAVEL',
  STORAGE_RESTORE_TIME_TRAVEL_SUCCESS: 'STORAGE_RESTORE_TIME_TRAVEL_SUCCESS',
  STORAGE_RESTORE_TIME_TRAVEL_ERROR: 'STORAGE_RESTORE_TIME_TRAVEL_ERROR',

  STORAGE_TABLE_CREATE_FROM_SNAPSHOT: 'STORAGE_TABLE_CREATE_FROM_SNAPSHOT',
  STORAGE_TABLE_CREATE_FROM_SNAPSHOT_SUCCESS: 'STORAGE_TABLE_CREATE_FROM_SNAPSHOT_SUCCESS',
  STORAGE_TABLE_CREATE_FROM_SNAPSHOT_ERROR: 'STORAGE_TABLE_CREATE_FROM_SNAPSHOT_ERROR',

  STORAGE_TABLE_REMOVE_SNAPSHOT: 'STORAGE_TABLE_REMOVE_SNAPSHOT',
  STORAGE_TABLE_REMOVE_SNAPSHOT_SUCCESS: 'STORAGE_TABLE_REMOVE_SNAPSHOT_SUCCESS',
  STORAGE_TABLE_REMOVE_SNAPSHOT_ERROR: 'STORAGE_TABLE_REMOVE_SNAPSHOT_ERROR',

  STORAGE_TRUNCATE_TABLE: 'STORAGE_TRUNCATE_TABLE',
  STORAGE_TRUNCATE_TABLE_SUCCESS: 'STORAGE_TRUNCATE_TABLE_SUCCESS',
  STORAGE_TRUNCATE_TABLE_ERROR: 'STORAGE_TRUNCATE_TABLE_ERROR',

  STORAGE_DELETE_TABLE: 'STORAGE_DELETE_TABLE',
  STORAGE_DELETE_TABLE_SUCCESS: 'STORAGE_DELETE_TABLE_SUCCESS',
  STORAGE_DELETE_TABLE_ERROR: 'STORAGE_DELETE_TABLE_ERROR',

  STORAGE_PULL_TABLE: 'STORAGE_PULL_TABLE',
  STORAGE_PULL_TABLE_SUCCESS: 'STORAGE_PULL_TABLE_SUCCESS',
  STORAGE_PULL_TABLE_ERROR: 'STORAGE_PULL_TABLE_ERROR',

  STORAGE_TABLE_SNAPSHOTS_LOAD_SUCCESS: 'STORAGE_TABLE_SNAPSHOTS_LOAD_SUCCESS',

  STORAGE_TABLES_EXPORT: 'STORAGE_TABLES_EXPORT',
  STORAGE_TABLES_EXPORT_SUCCESS: 'STORAGE_TABLES_EXPORT_SUCCESS',
  STORAGE_TABLES_EXPORT_ERROR: 'STORAGE_TABLES_EXPORT_ERROR',

  STORAGE_FILE_UPLOAD: 'STORAGE_FILE_UPLOAD',
  STORAGE_FILE_UPLOAD_SUCCESS: 'STORAGE_FILE_UPLOAD_SUCCESS',
  STORAGE_FILE_UPLOAD_ERROR: 'STORAGE_FILE_UPLOAD_ERROR',

  STORAGE_JOBS_LOAD: 'STORAGE_JOBS_LOAD',
  STORAGE_JOBS_LOAD_SUCCESS: 'STORAGE_JOBS_LOAD_SUCCESS',
  STORAGE_JOBS_LOAD_ERROR: 'STORAGE_JOBS_LOAD_ERROR',

  STORAGE_JOB_LOAD_SUCCESS: 'STORAGE_JOB_LOAD_SUCCESS',

  STORAGE_JOBS_LOAD_MORE: 'STORAGE_JOBS_LOAD_MORE',
  STORAGE_JOBS_LOAD_MORE_SUCCESS: 'STORAGE_JOBS_LOAD_MORE_SUCCESS',
  STORAGE_JOBS_LOAD_MORE_ERROR: 'STORAGE_JOBS_LOAD_MORE_ERROR',

  STORAGE_DEV_BRANCHES_LOAD_SUCCESS: 'STORAGE_DEV_BRANCHES_LOAD_SUCCESS',
  STORAGE_DEV_BRANCH_UPDATE_SUCCESS: 'STORAGE_DEV_BRANCH_UPDATE_SUCCESS',
};

const stagingStorageType = {
  WORKSPACE_SNOWFLAKE: 'workspace-snowflake',
  WORKSPACE_REDSHIFT: 'workspace-redshift',
  WORKSPACE_SYNAPSE: 'workspace-synapse',
  WORKSPACE_EXASOL: 'workspace-exasol',
  WORKSPACE_TERADATA: 'workspace-teradata',
  WORKSPACE_BIGQUERY: 'workspace-bigquery',
};

const ioType = {
  TABLE: 'table',
  FILE: 'file',
} as const;

const uiModes = {
  DEFAULT: 'default',
  SIMPLE: 'simple',
};

const features = {
  DEV_BRANCH_JOB_BLOCKED: 'dev-branch-job-blocked',
  DEV_BRANCH_CONFIGURATION_UNSAFE: 'dev-branch-configuration-unsafe',
  ALLOW_USE_FILE_STORAGE_ONLY: 'allow-use-file-storage-only',
  ALLOW_TAG_OVERRIDE: 'allow-tag-override',
  HAS_SAMPLE_DATA: 'has-sample-data',
  HAS_SIMPLIFIED_UI: 'has-simplified-ui',
  MLFLOW_ARTIFACTS_ACCESS: 'mlflow-artifacts-access',
};

const BACKEND_SIZES = {
  XSMALL: 'xsmall',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const transformationBackendSizes = {
  snowflake: [BACKEND_SIZES.SMALL, BACKEND_SIZES.MEDIUM, BACKEND_SIZES.LARGE],
  common: [BACKEND_SIZES.XSMALL, BACKEND_SIZES.SMALL, BACKEND_SIZES.MEDIUM, BACKEND_SIZES.LARGE],
};

const DATA_TYPE_SUPPORT = {
  NONE: 'none',
  HINTS: 'hints',
  AUTHORITATIVE: 'authoritative',
};

const defaultTransformationBackendSize = BACKEND_SIZES.SMALL;

const FILE_INPUT_MAPPING_DEFAULT_LIMIT = 100;

const HIDE_PARALLELISM_BUTTON = [TRANSFORMATION, KEBOOLA_APP_MERGE_BRANCH_STORAGE];

export {
  ActionTypes,
  stagingStorageType,
  uiModes,
  ioType,
  features,
  BACKEND_SIZES,
  DATA_TYPE_SUPPORT,
  transformationBackendSizes,
  defaultTransformationBackendSize,
  FILE_INPUT_MAPPING_DEFAULT_LIMIT,
  HIDE_PARALLELISM_BUTTON,
};
