import { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import dayjs from '@/date';
import Events from '@/modules/sapi-events/react/Events';

class JobLogPanel extends Component {
  render() {
    return (
      <Events
        params={{ runId: this.props.job.get('runId') }}
        autoReload={this.shouldAutoReload()}
        admins={this.props.admins}
      />
    );
  }

  shouldAutoReload() {
    if (['canceled', 'cancelled'].includes(this.props.job.get('status'))) {
      return false;
    }

    if (this.props.job.get('isFinished') === false) {
      return true;
    }

    return dayjs().diff(this.props.job.get('endTime'), 'minutes') < 5;
  }
}

JobLogPanel.propTypes = {
  job: PropTypes.instanceOf(Map).isRequired,
  admins: PropTypes.instanceOf(Map).isRequired,
};

export default JobLogPanel;
