import createReactClass from 'create-react-class';

import { cn, Link, Search } from '@keboola/design';

import { KEBOOLA_PYTHON_MLFLOW_TRANSFORMATION } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import {
  FEATURE_IS_SINGLE_TENANT,
  FEATURE_SANDBOXES_PYTHON_MLFLOW,
  REQUESTABLE_FEATURES,
} from '@/constants/features';
import { getAllowedTransformations } from '@/modules/components/helpers';
import TransformationTabs from '@/modules/components/react/pages/TransformationTabs';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import { routeNames as transformationRouteNames } from '@/modules/transformations-v2/constants';
import ComponentIcon from '@/react/common/ComponentIcon';
import FeatureRequestButton from '@/react/common/FeatureRequestButton';
import NoResultsFound from '@/react/common/NoResultsFound';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { matchByWords } from '@/utils';
import ModelsTable from './components/ModelsTable';
import {
  DUMMY_PREVIEW_ADMINS,
  DUMMY_PREVIEW_MODELS,
  DUMMY_PREVIEW_TRANSFORMATIONS,
  HELP_LINK,
} from './Constants';
import { isProperlyDeployedModel, prepareDeployingModels } from './helper';
import ModelsStore from './ModelsStore';

const ModelServicesIndex = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      ComponentsStore,
      InstalledComponentsStore,
      StackFeaturesStore,
      ModelsStore,
    ),
  ],

  getStateFromStores() {
    const allModels = ModelsStore.getAll();
    const hasNewQueue = ApplicationStore.hasNewQueue();

    return {
      hasNewQueue,
      isLoading: ModelsStore.getIsLoading(),
      allowedTransformationComponents: getAllowedTransformations(
        ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
        ApplicationStore.getSapiToken(),
        ApplicationStore.getCurrentProjectFeatures(),
        StackFeaturesStore.getAll(),
      ),
      deployedModels: allModels.filter(isProperlyDeployedModel),
      deployingModels: prepareDeployingModels(
        ModelsStore.getProcessingJobs(),
        allModels,
        hasNewQueue,
      ),
      transformations: InstalledComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
      pendingActions: ModelsStore.getPendingActions(),
      admins: ApplicationStore.getAdmins(),
      readOnly: ApplicationStore.isReadOnly(),
      hasFlows: ApplicationStore.hasFlows(),
      showDummyPreview:
        !ApplicationStore.hasCurrentProjectFeature(FEATURE_SANDBOXES_PYTHON_MLFLOW) &&
        !StackFeaturesStore.hasStackFeature(FEATURE_IS_SINGLE_TENANT),
      pythonMlflowComponent: ComponentsStore.getComponent(KEBOOLA_PYTHON_MLFLOW_TRANSFORMATION),
    };
  },

  getInitialState() {
    return {
      filterQuery: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
    };
  },

  render() {
    return (
      <TransformationTabs activeTab={transformationRouteNames.MODELS}>
        <div className={cn({ 'overlay-opacity-wrapper': this.state.showDummyPreview })}>
          <Search
            placeholder={this.getPlaceholder()}
            defaultValue={this.state.filterQuery}
            onChange={(query) => {
              this.setState({ filterQuery: query });
              RoutesStore.getRouter().updateQuery({ q: query });
            }}
            className="tw-mb-6"
          />

          {this.renderModels()}
          {this.state.showDummyPreview && this.renderModelsFeatureRequestOverlay()}
        </div>
      </TransformationTabs>
    );
  },

  renderModels() {
    const { models, transformations, admins } = this.prepareData();

    if (this.state.filterQuery && !models.count()) {
      return <NoResultsFound entityName="models" />;
    }

    return (
      <ModelsTable
        hasAnyModels={
          !this.state.deployedModels.isEmpty() ||
          !this.state.deployingModels.isEmpty() ||
          this.state.showDummyPreview
        }
        models={models}
        admins={admins}
        transformations={transformations}
        readOnly={this.state.readOnly || this.state.showDummyPreview}
        isLoading={this.state.isLoading}
        isSearching={!!this.state.filterQuery}
        pendingActions={this.state.pendingActions}
        deployingModels={this.state.deployingModels}
        hasNewQueue={this.state.hasNewQueue}
        allowedTransformationComponents={this.state.allowedTransformationComponents}
        hasFlows={this.state.hasFlows}
      />
    );
  },

  renderModelsFeatureRequestOverlay() {
    const feature = REQUESTABLE_FEATURES.FEATURE_SANDBOXES_PYTHON_MLFLOW;

    return (
      <div className="overlay-opacity-content mt-1 pt-2">
        <div className="pt-2">
          <ComponentIcon component={this.state.pythonMlflowComponent} size="48" />
          <h3>Accelerate the end-to-end machine learning lifecycle</h3>
          <p className="pl-2 pr-2 text-muted">
            Build, train, and deploy machine learning models and use them in new or existing
            transformations.
          </p>
          <p>
            <Link href={HELP_LINK}>How it works?</Link>
          </p>
          <FeatureRequestButton feature={feature}>{feature.buttonLabel}</FeatureRequestButton>
        </div>
      </div>
    );
  },

  getPlaceholder() {
    return `Search models (${
      this.state.deployedModels.count() + this.state.deployingModels.count()
    })`;
  },

  getModelsFiltered() {
    return this.state.deployedModels.filter((model) => {
      return matchByWords(model.get('name'), this.state.filterQuery);
    });
  },

  prepareData() {
    if (this.state.showDummyPreview) {
      return {
        admins: DUMMY_PREVIEW_ADMINS,
        models: DUMMY_PREVIEW_MODELS,
        transformations: DUMMY_PREVIEW_TRANSFORMATIONS,
      };
    }

    return {
      admins: this.state.admins,
      models: this.getModelsFiltered(),
      transformations: this.state.transformations,
    };
  },
});

export default ModelServicesIndex;
