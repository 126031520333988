import { useState } from 'react';
import { Map } from 'immutable';

import { cn, Icon, Tooltip } from '@keboola/design';

import { canManageBucket } from '@/modules/admin/privileges';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { updateBucket } from '@/modules/storage/actions';
import { validateBucketName } from '@/modules/storage/helpers';
import InlineEditTextInput from '@/react/common/InlineEditTextInput';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import nextTick from '@/utils/nextTick';
import EditNameWithDevBranchWarningModal from './EditNameWithDevBranchWarningModal';

type Props = {
  bucketId: string;
};

const BucketNameEdit = ({ bucketId }: Props) => {
  const store = useStores(
    () => {
      const bucket = StorageBucketsStore.getBucket(bucketId, Map());

      return {
        bucket,
        isDevModeActive: DevBranchesStore.isDevModeActive(),
        hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
        originalValue: bucket.get('displayName', bucketId),
        readOnly: !canManageBucket(ApplicationStore.getSapiToken(), bucket),
        allOtherBuckets: StorageBucketsStore.getAll().filter(
          (bucket) => bucketId !== bucket.get('id'),
        ) as Map<string, any>,
        hasAnyTable: (StorageTablesStore.getAll() as Map<string, any>).some(
          (table) => table.getIn(['bucket', 'id']) === bucketId,
        ),
      };
    },
    [bucketId],
    [ApplicationStore, DevBranchesStore, StorageBucketsStore, StorageTablesStore],
  );

  const [state, setState] = useState<{
    value: string;
    isEditingInModal: boolean;
    isEditing: boolean;
    isSaving: boolean;
    error: string | null;
  }>({
    value: StorageBucketsStore.getBucket(bucketId, Map()).get('displayName', bucketId),
    isEditingInModal: false,
    isEditing: false,
    isSaving: false,
    error: null,
  });

  const handleEditStart = () => {
    if (
      store.isDevModeActive &&
      !store.hasProtectedDefaultBranch &&
      !isCreatedInDevBranch(store.bucket)
    ) {
      return setState((prevState) => ({ ...prevState, isEditingInModal: true, error: null }));
    }

    setState((prevState) => ({ ...prevState, isEditing: true, error: null }));
  };

  const handleEditChange = (newValue: string) => {
    setState((prevState) => ({
      ...prevState,
      value: newValue,
      error: validateBucketName(
        newValue,
        store.bucket.get('stage'),
        store.allOtherBuckets,
        DevBranchesStore.getCurrentId() as number | null,
        { strict: false },
      ),
    }));
  };

  const handleEditCancel = () => {
    setState((prevState) => ({
      ...prevState,
      error: null,
      isSaving: false,
      isEditing: false,
      isEditingInModal: false,
      value: store.originalValue,
    }));
  };

  const handleEditSubmit = () => {
    setState((prevState) => ({ ...prevState, error: null, isSaving: true }));
    return updateBucket(bucketId, { displayName: state.value })
      .catch((error) => setState((prevState) => ({ ...prevState, error })))
      .finally(() =>
        setState((prevState) => ({ ...prevState, isSaving: false, isEditing: false })),
      );
  };

  const renderTableName = () => {
    if (store.readOnly) {
      return state.value;
    }

    if (state.isEditingInModal) {
      return (
        <>
          {store.originalValue}
          <EditNameWithDevBranchWarningModal
            entity="bucketName"
            name={state.value}
            onSubmit={handleEditSubmit}
            onChange={handleEditChange}
            onHide={() => nextTick(handleEditCancel)}
            isSaving={state.isSaving}
            error={state.error}
          />
        </>
      );
    }

    return (
      <span className={cn({ 'input-error': !!state.error })}>
        <Tooltip
          placement="bottom"
          type="explanatory"
          tooltip={state.error}
          forceShow={!!state.error}
          forceHide={!state.error}
        >
          <InlineEditTextInput
            isValid={!state.error}
            isChanged={state.value !== store.originalValue}
            text={state.value}
            isSaving={state.isSaving}
            isEditing={state.isEditing}
            onEditStart={handleEditStart}
            onEditCancel={handleEditCancel}
            onEditChange={handleEditChange}
            onEditSubmit={handleEditSubmit}
          />
        </Tooltip>
      </span>
    );
  };

  return (
    <>
      <Icon
        icon={store.hasAnyTable ? 'folder' : ['far', 'folder']}
        style={{ fontSize: '0.9em' }}
        className={cn('text-muted', 'icon-addon-right', {
          'dev-bucket': isCreatedInDevBranch(store.bucket),
        })}
      />
      {renderTableName()}
    </>
  );
};

export default BucketNameEdit;
