import { Component } from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';
import { Map } from 'immutable';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';

class DeployModelModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modelId: null,
      isLoading: false,
    };

    this.onHide = this.onHide.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.onHide}>
        <form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Deploy Model</Modal.Title>
            <ModalIcon icon="plus" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Select a model to deploy</ControlLabel>
              <Select
                value={this.state.modelId}
                onChange={(selected) =>
                  this.setState({
                    modelId: selected,
                  })
                }
                options={this.props.models
                  .map((model) => ({
                    value: model.get('id'),
                    label: `${model.get('name')} / ${model.get('stage')} / ${model.get('version')}`,
                  }))
                  .toArray()}
                clearable={false}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveLabel={this.state.isLoading ? 'Deploying model...' : 'Deploy model'}
              isSaving={this.state.isLoading}
              isDisabled={this.state.modelId === null}
            />
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    return this.props.onSubmit(this.props.models.get(this.state.modelId)).then(this.onHide);
  }

  onHide() {
    this.setState({
      modelId: null,
      isLoading: false,
    });
    this.props.onHide();
  }
}

DeployModelModal.propTypes = {
  models: PropTypes.instanceOf(Map).isRequired,
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default DeployModelModal;
