import type { Map } from 'immutable';

import { Icon } from '@keboola/design';

import { ObjectTypes } from '@/modules/components/MetadataConstants';
import DescriptionButton from '@/modules/storage/components/DescriptionButton';
import { tableModalTabs } from '@/modules/storage/constants';
import { getLoadingActionName } from '@/modules/storage/helpers';
import { RowActionDropdown, RowActionMenuItem } from '@/react/common';
import ActionLoader from '@/react/common/ActionLoader';

type Props = {
  table: Map<string, any>;
  isDeleting: boolean;
  isTruncating: boolean;
  isExporting: boolean;
  isCreatingSnapshot: boolean;
  canExportTable: boolean;
  canWriteBucket: boolean;
  onSetState: (
    selectedTable: Map<string, any>,
    activeActionModal: (typeof tableModalTabs)[keyof typeof tableModalTabs],
  ) => void;
};

const TableActions = ({
  table,
  isDeleting,
  isTruncating,
  isExporting,
  isCreatingSnapshot,
  canExportTable,
  canWriteBucket,
  onSetState,
}: Props) => {
  const isLoading = isCreatingSnapshot || isDeleting || isExporting || isTruncating;

  return isLoading ? (
    <ActionLoader
      loadingActionName={getLoadingActionName({
        isCreatingSnapshot,
        isDeleting,
        isExporting,
        isTruncating,
      })}
    />
  ) : (
    <RowActionDropdown>
      {canExportTable && (
        <RowActionMenuItem onSelect={() => onSetState(table, tableModalTabs.EXPORT)}>
          <Icon fixedWidth icon="down-to-line" />
          Export table
        </RowActionMenuItem>
      )}
      {canWriteBucket && (
        <RowActionMenuItem
          onSelect={() => onSetState(table, tableModalTabs.SNAPSHOT)}
          disabled={table.get('isAlias')}
          disabledReason="Snapshot is not available for alias tables."
        >
          <Icon fixedWidth icon="camera-viewfinder" />
          Create snapshot
        </RowActionMenuItem>
      )}
      <DescriptionButton data={table} entity={ObjectTypes.TABLE} readOnly={!canWriteBucket} />
      {canWriteBucket && (
        <>
          <RowActionMenuItem divider />
          {!table.get('isAlias') && (
            <>
              <RowActionMenuItem onSelect={() => onSetState(table, tableModalTabs.DELETE_ROWS)}>
                <Icon icon="table-rows" fixedWidth />
                Delete rows
              </RowActionMenuItem>
              <RowActionMenuItem onSelect={() => onSetState(table, tableModalTabs.TRUNCATE)}>
                <Icon icon="xmark" fixedWidth />
                Truncate table
              </RowActionMenuItem>
            </>
          )}
          <RowActionMenuItem onSelect={() => onSetState(table, tableModalTabs.DELETE)}>
            <Icon icon="trash" fixedWidth />
            Delete table
          </RowActionMenuItem>
        </>
      )}
    </RowActionDropdown>
  );
};

export default TableActions;
