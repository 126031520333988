import type { ReactNode } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

const helpVariants = cva(['tw-m-0', 'tw-text-xs'], {
  defaultVariants: {
    state: 'default',
  },
  variants: {
    state: {
      default: ['tw-text-neutral-500'],
      error: ['tw-text-error-500'],
      warning: ['tw-text-warning-500'],
    },
  },
});

type Props = {
  className?: string;
  children?: ReactNode;
  testId?: string;
} & VariantProps<typeof helpVariants>;

export const HelpBlock = ({ children, className, state = 'default', testId }: Props) => {
  if (!children) return null;

  return (
    <p data-testid={testId} className={helpVariants({ state, className })}>
      {children}
    </p>
  );
};
