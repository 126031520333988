import type { ChangeEvent } from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import type { Map } from 'immutable';

import { LIMIT_HELP, LIMIT_MAX, LIMIT_MIN, RUNBYID_HELP } from '@/modules/ex-facebook/constants';
import Checkbox from '@/react/common/Checkbox';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import InfoTooltip from '@/react/common/InfoTooltip';

type Props = {
  config: Map<string, any>;
  readOnly: boolean;
  updateLocalState: (path: string[], newValue: Map<string, any> | boolean | string) => void;
};

const AdvancedSettings = ({ config, readOnly, updateLocalState }: Props) => {
  const limit = config.getIn(['query', 'limit']);
  const runById = config.get('run-by-id', false);

  return (
    <CollapsibleBox title="Advanced Settings" defaultOpen>
      <FormGroup>
        <ControlLabel>
          Limit number of results per page
          <InfoTooltip tooltip={LIMIT_HELP} />
        </ControlLabel>

        <FormControl
          type="number"
          min={LIMIT_MIN}
          max={LIMIT_MAX}
          value={limit}
          disabled={readOnly}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            updateLocalState(['query', 'limit'], e.target.value);
          }}
        />
      </FormGroup>
      <FormGroup>
        <Checkbox
          disabled={readOnly}
          checked={runById}
          onChange={(checked) => updateLocalState(['run-by-id'], checked)}
        >
          Run the query separately for each account
          <InfoTooltip tooltip={RUNBYID_HELP} />
        </Checkbox>
      </FormGroup>
    </CollapsibleBox>
  );
};

export default AdvancedSettings;
