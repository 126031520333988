import type { Map } from 'immutable';

import { cn, IconButton, Tooltip } from '@keboola/design';

import { BEHAVIOR_TYPES } from '@/modules/queue/constants';

const PhaseActions = (props: {
  phase: Map<string, any>;
  behaviorOnError: string;
  newTaskPosition: null | string;
  handleShowAddTaskModal: (position: string | false, isOpen: boolean) => void;
  toggleBehaviorOnErrorChange: (phase: Map<string, any>) => void;
  onDelete: (phase: Map<string, any>) => void;
}) => {
  const isActive = props.newTaskPosition === props.phase.get('id').toString();

  return (
    <span
      className={cn('actions phase-actions', { active: isActive })}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <Tooltip placement="top" tooltip="Delete Phase">
        <IconButton
          variant="inline"
          className="circle-button tw-flex"
          onClick={() => props.onDelete(props.phase)}
          icon="trash"
        />
      </Tooltip>
      {props.phase.get('tasks').count() > 1 && (
        <>
          <Tooltip
            placement="top"
            tooltip={
              props.behaviorOnError === BEHAVIOR_TYPES.WARNING
                ? 'All to Finish'
                : 'Continue on Failure'
            }
          >
            <IconButton
              variant="inline"
              className="circle-button"
              onClick={() => props.toggleBehaviorOnErrorChange(props.phase)}
              icon={
                props.behaviorOnError === BEHAVIOR_TYPES.WARNING
                  ? 'down-from-dotted-line'
                  : 'rotate-exclamation'
              }
            />
          </Tooltip>
        </>
      )}
      <Tooltip
        placement="top"
        tooltip={isActive ? 'Close' : 'Add Task to Phase'}
        triggerClassName="add-task-inline-container"
      >
        <IconButton
          variant="inline"
          className="add-task-inline circle-button tw-flex [&_svg]:!tw-text-white"
          onClick={() => props.handleShowAddTaskModal(props.phase.get('id').toString(), !isActive)}
          icon={isActive ? 'xmark' : 'plus'}
        />
      </Tooltip>
    </span>
  );
};

export default PhaseActions;
