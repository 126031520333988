import { ControlLabel, FormGroup } from 'react-bootstrap';

import Select from '@/react/common/Select';
import { BACKGROUND_COLORS } from '@/react/constants';

export const BucketColorSelect = (props: {
  value?: string;
  onChange: (selected: string) => void;
  disabled?: boolean;
}) => {
  const options = BACKGROUND_COLORS.map((color) => ({
    value: color.color,
    name: color.name,
    label: (
      <div className="tw-flex tw-items-center">
        <span
          className="tw-mr-2 tw-h-4 tw-w-4 tw-rounded-sm"
          style={{ backgroundColor: color.color }}
        ></span>
        <span className="tw-text-sm">{color.name}</span>
      </div>
    ),
  }));

  return (
    <FormGroup controlId="formBucketColorControl">
      <ControlLabel>Bucket Color</ControlLabel>
      <Select
        id="formBucketColorControl"
        clearable={Boolean(props.value)}
        value={props.value}
        onChange={props.onChange}
        options={options}
        disabled={props.disabled}
        placeholder="Select color"
      />
    </FormGroup>
  );
};
