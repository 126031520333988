import { Component } from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { List } from 'immutable';

import { URLS } from '@keboola/constants';
import { Alert, Link } from '@keboola/design';

import {
  filterProductionAndCurrentDevBranchBuckets,
  filterProductionAndCurrentDevBranchTables,
} from '@/modules/dev-branches/helpers';
import { tableName } from '@/modules/storage/helpers';
import { backends } from '@/modules/transformations/Constants';
import Checkbox from '@/react/common/Checkbox';
import Select from '@/react/common/Select';
import { bucketLabel, tableLabel } from '@/react/common/selectLabels';

class ConfigureSandbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preserve: false,
      backend: this.props.backend,
      include: List(),
      rows: 0,
    };

    this.setRows = this.setRows.bind(this);
    this.setInclude = this.setInclude.bind(this);
    this.setPreserve = this.setPreserve.bind(this);
  }

  render() {
    return (
      <div className="form-horizontal">
        {this.renderSnowflakeSandboxInfo()}
        <FormGroup>
          <div className="col-xs-3">
            <ControlLabel>Backend</ControlLabel>
          </div>
          <div className="col-xs-9">
            <FormControl.Static>{this.state.backend}</FormControl.Static>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-3">
            <ControlLabel>Data</ControlLabel>
          </div>
          <div className="col-xs-9">
            <Select
              multi
              value={this.state.include}
              options={this.bucketsAndTables()}
              onChange={this.setInclude}
              placeholder="Select buckets and tables..."
            />
          </div>
        </FormGroup>
        {this.renderRowsInput()}
        <FormGroup>
          <div className="col-xs-9 col-xs-offset-3">
            <Checkbox checked={this.state.preserve} onChange={this.setPreserve}>
              Preserve existing data
            </Checkbox>
          </div>
        </FormGroup>
      </div>
    );
  }

  renderSnowflakeSandboxInfo() {
    if (this.props.backend !== backends.SNOWFLAKE) {
      return null;
    }

    return (
      <Alert variant="warning" className="tw-mb-5">
        Tables are loaded into the Snowflake sandbox using{' '}
        <Link href={`${URLS.USER_DOCUMENTATION}/transformations/snowflake/#clone-table`}>
          <strong>CLONE TABLE</strong> load type
        </Link>
        . Alias Tables with filter cannot be loaded to Sandbox using this method.
      </Alert>
    );
  }

  renderRowsInput() {
    if (this.props.backend === backends.SNOWFLAKE) {
      return null;
    }

    return (
      <FormGroup>
        <div className="col-xs-3">
          <ControlLabel>Sample rows</ControlLabel>
        </div>
        <div className="col-xs-9">
          <FormControl
            type="number"
            value={this.state.rows}
            onChange={this.setRows}
            placeholder="Number of rows"
          />
        </div>
      </FormGroup>
    );
  }

  setInclude(selected) {
    return this.setState({ include: selected }, () => this.props.onChange(this.state));
  }

  setRows(e) {
    const rows = e.target.value.trim();
    return this.setState({ rows }, () => this.props.onChange(this.state));
  }

  setPreserve(checked) {
    return this.setState({ preserve: checked }, () => this.props.onChange(this.state));
  }

  bucketsAndTables() {
    const buckets = filterProductionAndCurrentDevBranchBuckets(this.props.buckets)
      .filter((bucket) => bucket.get('id').startsWith('in.') || bucket.get('id').startsWith('out.'))
      .map((bucket) => ({
        value: bucket.get('id'),
        label: bucketLabel(bucket),
        name: bucket.get('displayName'),
      }))
      .toSet();

    const tables = filterProductionAndCurrentDevBranchTables(this.props.tables, this.props.buckets)
      .filter((table) => table.get('id').startsWith('in.') || table.get('id').startsWith('out.'))
      .filter((table) => {
        return (
          (this.props.backend === backends.SNOWFLAKE && !table.has('aliasFilter')) ||
          this.props.backend !== backends.SNOWFLAKE
        );
      })
      .map((table) => ({
        value: table.get('id'),
        label: tableLabel(table),
        name: tableName(table),
      }))
      .toSet();

    return buckets
      .union(tables)
      .sortBy((option) => option.name)
      .toArray();
  }
}

ConfigureSandbox.propTypes = {
  backend: PropTypes.string.isRequired,
  tables: PropTypes.object.isRequired,
  buckets: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ConfigureSandbox;
