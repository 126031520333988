import { useState } from 'react';
import type { FormEvent } from 'react';
import { Modal } from 'react-bootstrap';
import type { Map } from 'immutable';
import { fromJS, List } from 'immutable';

import { Alert, FormGroup, Label } from '@keboola/design';

import SapiTableSelector from '@/modules/components/react/components/SapiTableSelector';
import { ALIAS_SUFFIX } from '@/modules/storage/constants';
import { validateTableName } from '@/modules/storage/helpers';
import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import { isValidName } from '@/react/common/helpers';
import InfoTooltip from '@/react/common/InfoTooltip';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import whereOperatorConstants from '@/react/common/whereOperatorConstants';
import string from '@/utils/string';
import PredefinedInput from './PredefinedInput';

const initialNewTableAlias = {
  sourceTable: '',
  name: '',
  aliasFilter: {
    column: '',
    operator: whereOperatorConstants.EQ_VALUE,
    values: List(),
  },
  aliasColumnsAutosync: true,
};

type State = {
  newTableAlias: Map<string, any>;
  tableColumns: { label: string; value: string }[];
  error: string | null;
  warning: string | null;
};

const INITIAL_STATE: State = {
  newTableAlias: fromJS(initialNewTableAlias),
  tableColumns: [],
  error: null,
  warning: null,
};

type Props = {
  bucket: Map<string, any>;
  openModal: boolean;
  onSubmit: (tableAlias: Record<string, any>) => Promise<void>;
  onHide: () => void;
  isSaving: boolean;
  buckets: Map<string, any>;
  tables: Map<string, any>;
};

const CreateAliasTableModal = (props: Props) => {
  const [state, setState] = useState<State>(INITIAL_STATE);

  const validateName = () => {
    setState((prevState) => ({
      ...prevState,
      warning: validateTableName(
        prevState.newTableAlias.get('name'),
        props.tables.filter(
          (table) => table.getIn(['bucket', 'id']) === props.bucket.get('id'),
        ) as Map<string, any>,
      ),
    }));
  };

  const handleSourceTable = (tableId: string, table: Map<string, any>) => {
    const tableColumns = table
      .get('columns')
      .map((column: string) => ({ label: column, value: column }))
      .toArray();

    const newTableAlias = state.newTableAlias
      .set('sourceTable', tableId)
      .set('name', string.webalize(`${table.get('displayName')}${ALIAS_SUFFIX}`))
      .remove('aliasColumns');

    setState((prevState) => ({
      ...prevState,
      tableColumns,
      newTableAlias,
    }));
    validateName();
  };

  const onHide = () => {
    props.onHide();
    setState(INITIAL_STATE);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const tableAlias = state.newTableAlias
      .update((tableAlias) => {
        if (!tableAlias.getIn(['aliasFilter', 'column'])) {
          return tableAlias.delete('aliasFilter');
        }
        return tableAlias;
      })
      .toJS();

    props.onSubmit(tableAlias).then(onHide, (message) => {
      setState((prevState) => ({
        ...prevState,
        error: message,
      }));
    });
  };

  const isDisabled = () => {
    const tableAlias = state.newTableAlias;
    const aliasFilter = tableAlias.get('aliasFilter');

    if (
      !tableAlias.get('name') ||
      !isValidName(tableAlias.get('name')) ||
      !tableAlias.get('sourceTable')
    ) {
      return true;
    }

    if (
      aliasFilter.get('column') &&
      (!aliasFilter.get('operator') || !aliasFilter.get('values').count())
    ) {
      return true;
    }

    if (
      !tableAlias.get('aliasColumnsAutosync', true) &&
      tableAlias.get('aliasColumns', List()).isEmpty()
    ) {
      return true;
    }

    return props.isSaving || !!state.warning;
  };

  return (
    <Modal show={props.openModal} onHide={onHide}>
      <form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Create Alias Table in Bucket {props.bucket.get('displayName')}</Modal.Title>
          <ModalIcon icon="table" color="green" bold />
        </Modal.Header>
        <Modal.Body className="tw-flex tw-flex-col tw-gap-4">
          {state.error && (
            <Alert variant="error" className="tw-mb-5">
              {state.error}
            </Alert>
          )}
          <FormGroup>
            <Label htmlFor="source-table">Source table</Label>
            <SapiTableSelector
              id="source-table"
              autoFocus
              placeholder="Source table"
              buckets={props.buckets}
              tables={props.tables}
              value={state.newTableAlias.get('sourceTable')}
              onSelectTableFn={handleSourceTable}
            />
          </FormGroup>
          <PredefinedInput
            entity="bucketName"
            value={state.newTableAlias.get('name')}
            warning={state.warning}
            onChange={(name) => {
              setState((prevState) => ({
                ...prevState,
                newTableAlias: prevState.newTableAlias.set('name', name),
              }));
              validateName();
            }}
          />
          {!!props.bucket.get('sharing') ? (
            <Alert variant="warning" className="tw-mb-5">
              Filtering and synchronization of specific columns is not available when creating an
              alias table in a shared bucket.
            </Alert>
          ) : (
            <>
              <FormGroup>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <Label>
                  Filtering <Label.Optional />
                  <InfoTooltip
                    tooltip={
                      <>
                        You can specify a column to filter by, and comma-separated values
                        you&apos;re looking for. The alias table will contain only the matching
                        rows.
                      </>
                    }
                  />
                </Label>
                <div className="tw-grid tw-grid-cols-2 tw-gap-0.5">
                  <Select
                    ariaLabel="Filtering column"
                    clearable
                    placeholder="Column..."
                    value={state.newTableAlias.getIn(['aliasFilter', 'column'])}
                    onChange={(option: string) => {
                      setState((prevState) => ({
                        ...prevState,
                        newTableAlias: option
                          ? prevState.newTableAlias.setIn(['aliasFilter', 'column'], option)
                          : prevState.newTableAlias.deleteIn(['aliasFilter', 'column']),
                      }));
                    }}
                    options={state.tableColumns}
                    disabled={state.tableColumns.length === 0}
                  />
                  <Select
                    ariaLabel="Filtering operator"
                    searchable={false}
                    clearable={false}
                    disabled={state.tableColumns.length === 0}
                    value={state.newTableAlias.getIn(['aliasFilter', 'operator'])}
                    onChange={(value: string) => {
                      setState((prevState) => ({
                        ...prevState,
                        newTableAlias: prevState.newTableAlias.setIn(
                          ['aliasFilter', 'operator'],
                          value,
                        ),
                      }));
                    }}
                    options={[
                      {
                        label: whereOperatorConstants.EQ_LABEL,
                        value: whereOperatorConstants.EQ_VALUE,
                      },
                      {
                        label: whereOperatorConstants.NOT_EQ_LABEL,
                        value: whereOperatorConstants.NOT_EQ_VALUE,
                      },
                    ]}
                  />
                </div>
                <Select
                  ariaLabel="Filtering value"
                  multi
                  allowCreate
                  emptyStrings
                  placeholder="Add a value"
                  value={state.newTableAlias.getIn(['aliasFilter', 'values'])}
                  disabled={state.tableColumns.length === 0}
                  onChange={(value) => {
                    setState((prevState) => ({
                      ...prevState,
                      newTableAlias: prevState.newTableAlias.setIn(
                        ['aliasFilter', 'values'],
                        value,
                      ),
                    }));
                  }}
                />
              </FormGroup>
              <FormGroup>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <Label>
                  Columns
                  <InfoTooltip
                    tooltip={
                      <>
                        <p>By default, columns are synchronized with the source table.</p>
                        <p>
                          You can disable this behavior and select only particular columns to be
                          included in the alias table.
                        </p>
                      </>
                    }
                  />
                </Label>
                <Checkbox
                  checked={state.newTableAlias.get('aliasColumnsAutosync')}
                  onChange={() => {
                    setState((prevState) => ({
                      ...prevState,
                      newTableAlias: prevState.newTableAlias
                        .update(
                          'aliasColumnsAutosync',
                          (aliasColumnsAutosync) => !aliasColumnsAutosync,
                        )
                        .set('aliasColumns', List()),
                    }));
                  }}
                >
                  Synchronize columns with the source table
                </Checkbox>
              </FormGroup>
              {!state.newTableAlias.get('aliasColumnsAutosync') && (
                <FormGroup>
                  <Select
                    ariaLabel="Select alias table columns"
                    multi
                    clearable
                    allowCreate
                    placeholder="Select alias table columns"
                    value={state.newTableAlias.get('aliasColumns', List())}
                    onChange={(columns) => {
                      setState((prevState) => ({
                        ...prevState,
                        newTableAlias: prevState.newTableAlias.set('aliasColumns', columns),
                      }));
                    }}
                    options={state.tableColumns}
                    disabled={state.tableColumns.length === 0}
                  />
                </FormGroup>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isSaving={props.isSaving}
            isDisabled={isDisabled()}
            saveLabel={props.isSaving ? 'Creating alias table...' : 'Create alias table'}
            saveButtonType="submit"
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CreateAliasTableModal;
