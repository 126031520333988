import { useMemo, useState } from 'react';
import type { FormEvent } from 'react';
import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';
import type { Map } from 'immutable';

import { Alert } from '@keboola/design';

import { STAGE } from '@/constants';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { backends } from '@/modules/storage/constants';
import { prepareBackendLabel, validateBucketName } from '@/modules/storage/helpers';
import type { Backend, Stage } from '@/modules/storage/types';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import { isValidName } from '@/react/common/helpers';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import PredefinedInput from './PredefinedInput';

type Bucket = {
  name: string;
  displayName: string;
  stage: Stage;
  backend: Backend;
};

type InitialState = {
  displayName: string;
  stage: Stage;
  backend: Backend | null;
  error: string | null;
  warning: string | null;
};

const INITIAL_STATE: InitialState = {
  displayName: '',
  stage: 'in',
  backend: null,
  error: null,
  warning: null,
};

type Props = {
  sapiToken: Map<string, any>;
  openModal: boolean;
  onSubmit: (newBucket: Bucket) => Promise<unknown>;
  onHide: () => void;
  isSaving: boolean;
  buckets: Map<string, any>;
};
const CreateBucketModal = (props: Props) => {
  const [state, setState] = useState<InitialState>(INITIAL_STATE);

  const backendOptions = useMemo(() => {
    const options: Backend[] = [];

    if (props.sapiToken.getIn(['owner', 'hasRedshift'], false)) {
      options.push(backends.REDSHIFT);
    }

    if (props.sapiToken.getIn(['owner', 'hasSnowflake'], false)) {
      options.push(backends.SNOWFLAKE);
    }

    if (props.sapiToken.getIn(['owner', 'hasSynapse'], false)) {
      options.push(backends.SYNAPSE);
    }

    if (props.sapiToken.getIn(['owner', 'hasExasol'], false)) {
      options.push(backends.EXASOL);
    }

    if (props.sapiToken.getIn(['owner', 'hasTeradata'], false)) {
      options.push(backends.TERADATA);
    }

    if (props.sapiToken.getIn(['owner', 'hasBigquery'], false)) {
      options.push(backends.BIGQUERY);
    }

    return options;
  }, [props.sapiToken]);

  const defaultBackend: Backend = props.sapiToken.getIn(['owner', 'defaultBackend']);

  const handleHide = () => {
    props.onHide();
    setState(INITIAL_STATE);
  };

  const isDisabled = () => {
    const backend = state.backend || defaultBackend;

    if (
      !state.displayName ||
      !isValidName(state.displayName) ||
      !state.stage ||
      !backend ||
      state.warning
    ) {
      return true;
    }

    return props.isSaving;
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newBucket = {
      name: state.displayName,
      displayName: state.displayName,
      stage: state.stage,
      backend: state.backend || defaultBackend,
    };

    props.onSubmit(newBucket).then(handleHide, (message) => {
      setState((prevState) => ({ ...prevState, error: message }));
    });
  };

  return (
    <Modal show={props.openModal} onHide={handleHide}>
      <form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Create Bucket</Modal.Title>
          <ModalIcon icon="folder" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          {state.error && (
            <Alert variant="error" className="tw-mb-5">
              {state.error}
            </Alert>
          )}
          <PredefinedInput
            autoFocus
            entity="bucketName"
            value={state.displayName}
            warning={state.warning}
            onChange={(displayName) => {
              setState((prevState) => ({
                ...prevState,
                displayName,
                warning: validateBucketName(
                  displayName,
                  prevState.stage,
                  props.buckets,
                  DevBranchesStore.getCurrentId() as number | null,
                ),
              }));
            }}
          />
          <FormGroup>
            <ControlLabel>Stage</ControlLabel>
            <Select
              clearable={false}
              placeholder="Select stage..."
              onChange={(value: Stage) => {
                setState((prevState) => ({
                  ...prevState,
                  stage: value,
                  warning: validateBucketName(
                    prevState.displayName,
                    value,
                    props.buckets,
                    DevBranchesStore.getCurrentId() as number | null,
                  ),
                }));
              }}
              options={Object.values(STAGE).map((stage) => ({
                value: stage,
                label: stage.toUpperCase(),
              }))}
              value={state.stage}
            />
          </FormGroup>
          {backendOptions.length > 1 && (
            <FormGroup>
              <ControlLabel>Backend</ControlLabel>
              <Select
                clearable={false}
                placeholder="Select backend..."
                onChange={(backend: Backend) =>
                  setState((prevState) => ({ ...prevState, backend }))
                }
                value={state.backend || defaultBackend}
                options={backendOptions.map((backend) => ({
                  value: backend,
                  label: prepareBackendLabel(backend),
                }))}
              />
            </FormGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isSaving={props.isSaving}
            isDisabled={isDisabled()}
            saveLabel={props.isSaving ? 'Creating bucket...' : 'Create bucket'}
            saveButtonType="submit"
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CreateBucketModal;
