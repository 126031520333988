import type { ReactNode } from 'react';
import { type Iterable, List, type Map } from 'immutable';

import Protected from './Protected';

type ValueRenderer = Record<string, (value: any) => ReactNode>;

const Tree = (props: { data: Map<string, any>; valueRenderer?: ValueRenderer }) => {
  const valueRenderer: ValueRenderer = {
    password: (value) => <Protected>{value}</Protected>,
    ...props.valueRenderer,
  };

  const renderValue = (value: string | number | boolean | null, key?: keyof ValueRenderer) => {
    if (key && key in valueRenderer) {
      return valueRenderer[key](value);
    }

    return String(value);
  };

  const renderRow = (
    value: string | number | boolean | null | Iterable<string, any>,
    key?: string,
    iter?: Iterable<string, any>,
  ) => {
    return (
      <li key={key}>
        {typeof value === 'string' ||
        typeof value === 'number' ||
        typeof value === 'boolean' ||
        value === null ? (
          <>
            {!List.isList(iter) && <strong>{key}: </strong>}
            {renderValue(value, key)}
          </>
        ) : (
          <>
            {!List.isList(iter) && <strong>{key}</strong>}
            <ul className="data-list">{value.map(renderRow).toArray()}</ul>
          </>
        )}
      </li>
    );
  };

  return <ul className="data-list">{props.data.map(renderRow).toArray()}</ul>;
};

export default Tree;
