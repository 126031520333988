import { Badge } from '@keboola/design';

import ApplicationStore from '@/stores/ApplicationStore';

export const TypedTableLabel = (props: { isTyped: boolean; className?: string }) => {
  const hasNewNativeTypes = ApplicationStore.hasNewNativeTypes();

  if (!hasNewNativeTypes && props.isTyped) {
    return (
      <Badge variant="purple-inverse" className={props.className} placement="right">
        Native Types
      </Badge>
    );
  }

  if (hasNewNativeTypes && !props.isTyped) {
    return (
      <Badge variant="orange-inverse" className={props.className} placement="right">
        Non-typed
      </Badge>
    );
  }

  return null;
};
