import React, { type ComponentProps } from 'react';
import type Promise from 'bluebird';
import { Map } from 'immutable';

import { Button, Tooltip } from '@keboola/design';

import { isComponentDeprecated } from '@/modules/components/helpers';
import NewConfigurationModal from '@/modules/flows/components/NewConfigurationModal';
import { disabledInBranch } from '@/modules/flows/constants';

type Props = {
  task: Map<string, any>;
  component: Map<string, any>;
  readOnly: boolean;
  hasPayAsYouGo: boolean;
  allConfigurations: Map<string, any>;
  allComponents: Map<string, any>;
  folders: Map<string, any>;
  patternComponents: Map<string, any>;
  isDevModeActive: boolean;
  variant: ComponentProps<typeof Button>['variant'];
  query?: string;
  onCreate: (componentId: string, configId: string) => Promise<void>;
  customName?: string | null;
};

const CreateConfiguration = ({
  task,
  component,
  readOnly,
  allConfigurations,
  folders,
  isDevModeActive,
  variant,
  patternComponents,
  allComponents,
  hasPayAsYouGo,
  query,
  onCreate,
  customName,
}: Props) => {
  const [showNewConfigurationModal, setShowNewConfigurationModal] = React.useState(false);

  const isDeprecated = isComponentDeprecated(component);
  const isDisabledInBranch = isDevModeActive && disabledInBranch.includes(component.get('id'));

  return (
    !readOnly && (
      <>
        <Tooltip
          placement="top"
          type="explanatory"
          forceHide={!isDeprecated && !isDisabledInBranch}
          tooltip={
            isDeprecated
              ? 'This component has been deprecated. New configuration cannot be created.'
              : 'This component is disabled in branches. New configuration cannot be created.'
          }
        >
          <Button
            variant={variant}
            disabled={isDeprecated || isDisabledInBranch}
            block
            className="tw-mx-2"
            onClick={() => {
              if (isDeprecated || isDisabledInBranch) {
                return;
              }

              setShowNewConfigurationModal(true);
            }}
          >
            Create {customName ?? 'Configuration'}
          </Button>
        </Tooltip>
        <NewConfigurationModal
          show={showNewConfigurationModal}
          forceTransformationName={query}
          onHide={() => setShowNewConfigurationModal(false)}
          onCreate={onCreate}
          task={task}
          hasConfigurations={
            !allConfigurations.getIn([task.get('componentId'), 'configurations'], Map()).isEmpty()
          }
          patternComponents={patternComponents}
          allComponents={allComponents}
          readOnly={readOnly}
          hasPayAsYouGo={hasPayAsYouGo}
          folders={folders}
        />
      </>
    )
  );
};

export default CreateConfiguration;
