import type Promise from 'bluebird';
import type { Map } from 'immutable';

import { type ACTIVE_MENU, MENUS } from '@/modules/ex-generic/constants';
import AdvancedSettings from './AdvancedSettings';
import Base from './Base';
import Endpoint from './Endpoint';
import Paginations from './Paginations';
import Parameters from './Parameters';
import UserData from './UserData';

const DetailContent = (props: {
  menu: ACTIVE_MENU;
  readOnly: boolean;
  parameters: Map<string, any>;
  allBuckets: Map<string, any>;
  allTables: Map<string, any>;
  setMenu: (menu: ACTIVE_MENU) => void;
  onSave: (
    parameters: Map<string, any>,
    changeDescription: string,
    newMenu?: ACTIVE_MENU,
  ) => Promise<any>;
}) => {
  if (props.menu === MENUS.BASE) {
    return (
      <Base
        parameters={props.parameters}
        onSave={props.onSave}
        allBuckets={props.allBuckets}
        readOnly={props.readOnly}
      />
    );
  }

  if (props.menu === MENUS.ADVANCED) {
    return (
      <AdvancedSettings
        parameters={props.parameters}
        onSave={props.onSave}
        readOnly={props.readOnly}
      />
    );
  }

  if (props.menu === MENUS.PARAMETERS) {
    return (
      <Parameters parameters={props.parameters} onSave={props.onSave} readOnly={props.readOnly} />
    );
  }

  if (props.menu === MENUS.USER_DATA) {
    return (
      <UserData parameters={props.parameters} onSave={props.onSave} readOnly={props.readOnly} />
    );
  }

  if (props.menu === MENUS.PAGINATIONS) {
    return (
      <Paginations parameters={props.parameters} onSave={props.onSave} readOnly={props.readOnly} />
    );
  }

  if (Array.isArray(props.menu)) {
    return (
      <Endpoint
        key={props.menu.join('|')}
        endpointPath={props.menu}
        allTables={props.allTables}
        parameters={props.parameters}
        readOnly={props.readOnly}
        setMenu={props.setMenu}
        onSave={props.onSave}
      />
    );
  }

  return null;
};

export default DetailContent;
