import type { NodeProps } from '@xyflow/react';

import BaseNode from '@/modules/lineage/components/CustomNode/BaseNode';
import { TableSubtype } from '@/modules/lineage/constants';
import { isTableAlias } from '@/modules/lineage/helpers';
import type { OnSelectProject, OnSetShownHandles, TableNode } from '@/modules/lineage/rfTypes';
import Columns from './Columns';
import SourceProjectLink from './SourceProjectLink';

const ColorMap = {
  [TableSubtype.LINKED_FROM]: 'tw-bg-yellow-200',
  [TableSubtype.LINKED_TO]: 'tw-bg-purple-200',
  [TableSubtype.TABLE]: 'tw-bg-secondary-200',
};

const Table = ({
  data,
  graphId,
  onHighlight,
  onSetShownHandles,
  onClick,
  onSelectProject,
  onSelectColumn,
  onResetColumn,
}: NodeProps<TableNode> & {
  graphId: string;
  onHighlight: (nodeId: string) => void;
  onSetShownHandles: OnSetShownHandles;
  onClick: (nodeId: string) => void;
  onSelectProject: OnSelectProject;
  onSelectColumn: (nodeId: string, column: string) => void;
  onResetColumn: () => void;
}) => {
  return (
    <BaseNode
      nodeId={data.fqid}
      onClick={onClick}
      onHighlight={onHighlight}
      nodeTitle={data.displayName || data.name}
      nodeType="TBL"
      iconName="table"
      iconClassName="tw-text-neutral-400 tw-fill-neutral-400"
      backgroundColor={ColorMap[data.subtype]}
      bottomContent={
        data.columns.length > 0 && (
          <Columns
            nodeId={data.fqid}
            onSetShownHandles={onSetShownHandles}
            columns={data.columns}
            onSelectColumn={onSelectColumn}
            onResetColumn={onResetColumn}
          />
        )
      }
      isAlias={isTableAlias(data.metadata)}
      graphId={graphId}
    >
      {data.subtype !== 'table' && (
        <SourceProjectLink data={data} onSelectProject={onSelectProject} />
      )}
    </BaseNode>
  );
};

export default Table;
