import { useState } from 'react';

import type { AutomationMessage, UserMessage } from '@/api/routes/aiService';
import ComponentSelection from './ComponentSelection';
import IntentConfirmation from './IntentConfirmation';
import Merge from './Merge';
import TableSelection from './TableSelection';

const InteractiveElement = ({
  messageObject,
  submitMessage,
  automationId,
  flowConfigId,
}: {
  messageObject: AutomationMessage;
  submitMessage: (messageObject: Partial<UserMessage>) => void;
  automationId?: string;
  flowConfigId?: string;
}) => {
  const [wasSubmitted, setWasSubmitted] = useState(false);

  const submit = (data?: UserMessage['data']) => {
    submitMessage({
      type: messageObject.type.replace('Request', 'Response') as UserMessage['type'],
      data,
    });
    setWasSubmitted(true);
  };

  if (wasSubmitted) return null;

  switch (messageObject.type) {
    case 'componentSelectionRequest':
      return (
        <ComponentSelection
          componentsIds={messageObject.data.options}
          onSubmit={(componentIds) => submit({ selectedValues: componentIds })}
        />
      );

    case 'sourceTableSelectionRequest':
      return (
        <TableSelection
          tablesIds={messageObject.data.options}
          onClick={(tablesIds) => submit({ selectedValues: tablesIds })}
        />
      );

    case 'intentConfirmationRequest':
      return <IntentConfirmation onClick={(confirm) => submit({ selectedValue: confirm })} />;

    case 'flowGenerated':
      return <Merge automationId={automationId} flowConfigId={flowConfigId} />;

    default:
      return null;
  }
};

export default InteractiveElement;
