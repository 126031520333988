import type { Map } from 'immutable';

import { RowActionDropdown, RowActionMenuItem } from '@/react/common';
import { DisableSharing } from './DisableSharing';
import { EditSharing } from './EditSharing';
import { ForceUnlink } from './ForceUnlink';

type Props = {
  bucket: Map<string, any>;
  sapiToken: Map<string, any>;
  urlTemplates: Map<string, any>;
  availableUsersOptions: { id: number; name: string }[];
  availableProjectsOptions: { id: number; name: string }[];
};

export const ActionsDropdown = ({
  bucket,
  sapiToken,
  urlTemplates,
  availableUsersOptions,
  availableProjectsOptions,
}: Props) => {
  return (
    <div className="actions">
      <RowActionDropdown isCompact>
        <EditSharing
          bucket={bucket}
          sapiToken={sapiToken}
          urlTemplates={urlTemplates}
          availableUsersOptions={availableUsersOptions}
          availableProjectsOptions={availableProjectsOptions}
        />

        <RowActionMenuItem divider />
        <DisableSharing bucket={bucket} sapiToken={sapiToken} urlTemplates={urlTemplates} />
        <ForceUnlink bucket={bucket} sapiToken={sapiToken} urlTemplates={urlTemplates} />
      </RowActionDropdown>
    </div>
  );
};
