import { useMemo, useState } from 'react';
import type { Map } from 'immutable';

import { Search } from '@keboola/design';

import { KEBOOLA_DATA_APPS } from '@/constants/componentIds';
import BlankComponentsPage from '@/modules/components/react/pages/BlankComponentsPage';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import NoResultsFound from '@/react/common/NoResultsFound';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { matchByWords } from '@/utils';
import DataApps from './components/DataApps';
import DataAppsStore from './DataAppsStore';
import { prepareDataApps } from './helpers';

const Index = () => {
  const store = useStores(
    () => {
      const apps = DataAppsStore.getDataApps();

      return {
        readOnly: ApplicationStore.isReadOnly(),
        component: ComponentsStore.getComponent(KEBOOLA_DATA_APPS),
        dataApps: prepareDataApps(
          InstalledComponentsStore.getComponentConfigurations(KEBOOLA_DATA_APPS),
          apps,
        ),
        allConfigurations: InstalledComponentsStore.getAll() as Map<string, any>,
        componentsMetadata: InstalledComponentsStore.getAllMetadata() as Map<string, any>,
        expandedFolders: InstalledComponentsStore.getExpandedFolders('data app'),
        hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
        hasFlows: ApplicationStore.hasFlows(),
        hasNewQueue: ApplicationStore.hasNewQueue(),
        admins: ApplicationStore.getAdmins(),
        currentAdmin: ApplicationStore.getCurrentAdmin(),
      };
    },
    [],
    [DataAppsStore, InstalledComponentsStore, ComponentsStore, ApplicationStore],
  );
  const [filterQuery, setFilterQuery] = useState(
    RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
  );

  const filteredDataApps = useMemo(
    () =>
      store.dataApps.filter((dataApp: Map<string, any>) => {
        return (
          dataApp.get('id') === filterQuery ||
          matchByWords([dataApp.get('name'), dataApp.get('description')], filterQuery)
        );
      }) as Map<string, any>,
    [store.dataApps, filterQuery],
  );

  if (store.dataApps.isEmpty()) {
    return <BlankComponentsPage type="data-app" />;
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <Search
        placeholder={`Search data apps (${filteredDataApps.count()})`}
        defaultValue={filterQuery}
        onChange={(query) => {
          setFilterQuery(query);
          RoutesStore.getRouter().updateQuery({ q: query });
        }}
      />

      {filterQuery && filteredDataApps.isEmpty() ? (
        <NoResultsFound entityName="data app" />
      ) : (
        <DataApps
          dataApps={filteredDataApps}
          filterQuery={filterQuery}
          component={store.component}
          allConfigurations={store.allConfigurations}
          componentsMetadata={store.componentsMetadata}
          readOnly={store.readOnly}
          hasNewQueue={store.hasNewQueue}
          hasFlows={store.hasFlows}
          hasPayAsYouGo={store.hasPayAsYouGo}
          admins={store.admins}
          currentAdmin={store.currentAdmin}
        />
      )}
    </div>
  );
};

export default Index;
