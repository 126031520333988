import { forwardRef, useRef, useState } from 'react';
import type { ChangeEventHandler, ElementRef, FocusEventHandler, MouseEventHandler } from 'react';

import { cn, mergeRefs, sanitizeTextInputValue } from '../../utils';

import InputClearButton from './InputClearButton';
import { inputClassnames, inputWrapperVariants } from './styles';
import type { TextInputProps } from './types';
import { genTextInputTestId } from './utils';

export const TextInput = forwardRef<ElementRef<'input'>, TextInputProps>(
  (
    {
      state = 'default',
      className,
      prefix = null,
      suffix = null,
      allowClear = false,
      onChange,
      onBlur,
      value,
      defaultValue,
      disabled = false,
      testId: prefixTestId = '',
      autoFocus = false,
      id,
      name,
      variant,
      readOnly,
      type = 'text',
      disableFocusEffect,
      ...rest
    },
    forwardedRef,
  ) => {
    const [hasValue, setHasValue] = useState(() => {
      const val = sanitizeTextInputValue(value);
      const defaultVal = sanitizeTextInputValue(defaultValue);
      return val != null || defaultVal != null;
    });
    const inputRef = useRef<HTMLInputElement>(null);

    const testId = genTextInputTestId(prefixTestId);
    const isClearButtonVisible = allowClear && hasValue && !disabled && !readOnly;

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      const val = event.target.value;
      setHasValue(val !== '');
      onChange?.(val, event);
    };

    const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
      const val = event.target.value;
      setHasValue(val !== '');
      onBlur?.(val, event);
    };

    const handleClear: MouseEventHandler<HTMLButtonElement> = (event) => {
      if (inputRef.current === null) return;
      inputRef.current.value = ''; // necessary for uncontrolled state
      onChange?.('', event);
      setHasValue(false);
    };

    return (
      <div
        className={cn(inputWrapperVariants({ state, variant, className, disableFocusEffect }), {
          'tw-cursor-not-allowed tw-opacity-40': disabled,
        })}
        data-testid={testId.inputWrapper}
      >
        {prefix}

        <input
          {...rest}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={autoFocus}
          type={type}
          value={value ?? void 0}
          defaultValue={defaultValue ?? void 0}
          onChange={handleChange}
          onBlur={handleBlur}
          ref={mergeRefs(forwardedRef, inputRef)}
          className={inputClassnames}
          disabled={disabled}
          id={id}
          name={name}
          readOnly={readOnly}
          data-testid={testId.input}
        />

        {isClearButtonVisible && (
          <InputClearButton testId={testId.clearButton} onClick={handleClear} state={state} />
        )}

        {suffix}
      </div>
    );
  },
);
