import { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Map } from 'immutable';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import DescriptionControl from './DescriptionControl';
import NameControl from './NameControl';

const INITIAL_STATE = {
  name: '',
  description: '',
  isCreating: false,
  warning: '',
  error: '',
};

class CreateDevBranchModal extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.handleNameChange = this.handleNameChange.bind(this);
    this.onHide = this.onHide.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.onHide}
        onEnter={() => this.setState(INITIAL_STATE)}
      >
        <form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Create Development Branch</Modal.Title>
            <ModalIcon icon={['far', 'code-branch']} color="orange" bold />
          </Modal.Header>
          <Modal.Body>
            <NameControl
              value={this.state.name}
              onChange={this.handleNameChange}
              error={this.state.error}
              warning={this.state.warning}
            />
            <DescriptionControl
              value={this.state.description}
              onChange={(description) => this.setState({ description })}
            />
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveLabel={
                this.state.isCreating
                  ? 'Creating development branch...'
                  : 'Create development branch'
              }
              isSaving={this.state.isCreating}
              isDisabled={!this.state.name.trim().length || !!this.state.warning}
            />
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  handleNameChange(value) {
    let warning = '';

    if (
      this.props.devBranches.find(
        (branch) => branch.get('name').toLowerCase() === value.toLowerCase().trim(),
      )
    ) {
      warning = `There already is a branch with the name "${value.trim()}"`;
    }

    this.setState({ name: value, warning });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ isCreating: true });

    return this.props
      .onSubmit(this.state.name.trim(), this.state.description)
      .then(this.onHide)
      .catch((error) => this.setState({ ...(error && { error: error.message }) }))
      .finally(() => this.setState({ isCreating: false }));
  }

  onHide() {
    this.setState(INITIAL_STATE);
    this.props.onHide();
  }
}

CreateDevBranchModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  devBranches: PropTypes.instanceOf(Map).isRequired,
};

export default CreateDevBranchModal;
