/*
  Edit buttons
  When editing Save and Cancel buttons are shown. These buttons are disabled and loader is shown when saving.
  Edit button is shown when editing mode is disabled.
*/
import { Button } from 'react-bootstrap';

import ConfirmButtons from './ConfirmButtons';

type Props = {
  isEditing: boolean;
  isSaving: boolean;
  onCancel: () => void;
  onSave: () => void;
  onEditStart?: () => void;
  isDisabled?: boolean;
  bsStyle?: string;
  editLabel?: string;
  cancelLabel?: string;
  saveLabel?: string;
};

const EditButtons = ({
  isEditing,
  isSaving,
  onCancel,
  onSave,
  onEditStart,
  isDisabled = false,
  bsStyle = 'success',
  editLabel = 'Edit',
  cancelLabel = 'Cancel',
  saveLabel = 'Save',
}: Props) => {
  if (isEditing) {
    return (
      <ConfirmButtons
        saveStyle={bsStyle}
        isSaving={isSaving}
        isDisabled={isDisabled}
        cancelLabel={cancelLabel}
        saveLabel={saveLabel}
        onCancel={onCancel}
        onSave={onSave}
      />
    );
  }

  return (
    <Button bsStyle={bsStyle} onClick={onEditStart}>
      {editLabel}
    </Button>
  );
};

export default EditButtons;
