import PropTypes from 'prop-types';
import _ from 'underscore';

import { cn, Link } from '@keboola/design';

const Help = ({ title, text, documentation, style, down = false }) => {
  return (
    <div className={cn('well step-help', { 'is-down': down })} style={style}>
      {title && <h3>{title}</h3>}
      {_.isString(text) ? <p>{text}</p> : text}
      {documentation && (
        <Link className="display-block mt-2 f-12 font-medium" href={documentation}>
          Read more in documentation
        </Link>
      )}
    </div>
  );
};

Help.propTypes = {
  text: PropTypes.node.isRequired,
  documentation: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object,
  down: PropTypes.bool,
};

export default Help;
