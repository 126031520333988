import { useState } from 'react';
import type { ReactNode } from 'react';

import { IconButton, Tooltip } from '@keboola/design';

import ConfirmModal from '@/react/common/ConfirmModal';

type Props = {
  title?: string;
  text?: ReactNode;
  isPending: boolean;
  disabledReason?: string;
  onConfirm: () => Promise<any>;
};

const RestoreConfigurationButton = ({
  title = 'Restore Configuration',
  text = 'Are you sure you want to restore the configuration?',
  disabledReason,
  isPending,
  onConfirm,
}: Props) => {
  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <>
      <Tooltip tooltip={disabledReason || title} placement="top" type="explanatory">
        <IconButton
          variant="invisible"
          disabled={isPending || !!disabledReason}
          isLoading={isPending}
          icon="reply"
          onClick={() => !isPending && !disabledReason && setModalVisible(true)}
        />
      </Tooltip>
      <ConfirmModal
        show={isModalVisible}
        onHide={() => setModalVisible(false)}
        icon="reply"
        buttonLabel="Restore"
        title={title}
        buttonType="success"
        text={text}
        onConfirm={onConfirm}
        isDisabled={isPending}
      />
    </>
  );
};

export default RestoreConfigurationButton;
