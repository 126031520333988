import { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Map } from 'immutable';

import { Icon, Tooltip } from '@keboola/design';

import Checkbox from '@/react/common/Checkbox';

class PhaseRow extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.readOnly) {
      return (
        <tr className="bg-color-input">
          <td />
          <td />
          <td colSpan="2" className="text-center">
            {this.renderPhaseName()}
          </td>
          <td />
          <td />
          {this.props.showBackendSize && <td />}
          <td />
        </tr>
      );
    }

    return (
      <tr className="bg-color-input">
        <td>
          {!this.props.readOnly && this.props.allowDragAndDrop && (
            <Tooltip tooltip="Move phase" triggerClassName="dragable drag-handle">
              <Icon icon="bars" />
            </Tooltip>
          )}
        </td>
        <td>
          <Checkbox
            checked={this.props.isSelected}
            onChange={this.props.onPhaseToggleFn}
            disabled={this.props.readOnly}
          />
        </td>
        <td colSpan="2" className="text-center">
          {this.renderPhaseName()}
          <Tooltip tooltip="Rename phase">
            <Button
              bsStyle="link"
              bsSize="sm"
              className="btn-link-inline"
              onClick={this.props.showPhaseNameModalFn}
            >
              <Icon icon="pen" className="icon-addon-left icon-addon-right" />
            </Button>
          </Tooltip>
        </td>
        <td />
        <td />
        {this.props.showBackendSize && <td />}
        <td>
          <Button bsStyle="link" className="btn-link-inline" onClick={this.props.addTaskToPhaseFn}>
            <Icon icon="plus" className="icon-addon-right" />
            New task
          </Button>
        </td>
      </tr>
    );
  }

  renderPhaseName() {
    return (
      <>
        Phase: <strong>{this.props.phase.get('name')}</strong>{' '}
      </>
    );
  }
}

PhaseRow.propTypes = {
  phase: PropTypes.instanceOf(Map).isRequired,
  showPhaseNameModalFn: PropTypes.func.isRequired,
  addTaskToPhaseFn: PropTypes.func.isRequired,
  allowDragAndDrop: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onPhaseToggleFn: PropTypes.func.isRequired,
  showBackendSize: PropTypes.bool.isRequired,
};

export default PhaseRow;
