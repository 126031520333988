import { Component } from 'react';
import type { ComponentProps, ReactNode, SyntheticEvent } from 'react';
import { Modal } from 'react-bootstrap';

import type { Icon } from '@keboola/design';

import keyCodes from '@/constants/keyCodes';
import type { IconColor } from './CircleIcon';
import ConfirmButtons from './ConfirmButtons';
import Loader from './Loader';
import ModalIcon from './ModalIcon';

type Props = {
  buttonLabel: string;
  text: ReactNode;
  title: string;
  onConfirm: () => Promise<any>;
  onHide: (onConfirmResult?: any) => void;
  show: boolean;
  buttonType?: string;
  buttonClass?: string;
  modalClass?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  closeAfterResolve?: boolean;
  handleErrorInModal?: boolean;
  icon?: ComponentProps<typeof Icon>['icon'];
  iconUrl?: string;
  iconColor?: IconColor;
  onEnter?: () => void;
  loadOnEnter?: () => Promise<any>;
  size?: 'lg' | 'large' | 'sm' | 'small';
};

class ConfirmModal extends Component<Props> {
  state = {
    isLoadingData: false,
  };

  render() {
    return (
      <span
        className="tw-hidden"
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.key === keyCodes.ENTER && e.stopPropagation()}
      >
        <Modal
          bsSize={this.props.size}
          onHide={this.props.onHide}
          show={this.props.show}
          onEnter={this.onEnter}
          className={this.props.modalClass}
        >
          <form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{this.props.title}</Modal.Title>
              {this.renderModalIcon()}
            </Modal.Header>
            <Modal.Body>
              {this.state.isLoadingData ? (
                <>
                  <Loader className="icon-addon-right" />
                  Loading required data...
                </>
              ) : (
                this.props.text
              )}
            </Modal.Body>
            <Modal.Footer>
              <ConfirmButtons
                block
                saveButtonType="submit"
                saveStyle={this.props.buttonType}
                saveLabel={this.props.buttonLabel}
                saveButtonClass={this.props.buttonClass}
                isSaving={this.props.isLoading}
                isDisabled={
                  this.props.isLoading || this.state.isLoadingData || this.props.isDisabled
                }
              />
            </Modal.Footer>
          </form>
        </Modal>
      </span>
    );
  }

  renderModalIcon() {
    if (!this.props.icon && !this.props.iconUrl) {
      return null;
    }

    switch (this.props.icon) {
      case 'trash':
        return <ModalIcon.Trash />;

      case 'play':
        return <ModalIcon.Play />;

      case 'plus':
        return <ModalIcon.Plus />;

      case 'clock':
        return <ModalIcon.Clock />;

      case 'upload':
        return <ModalIcon.Upload />;

      case 'reply':
        return <ModalIcon.Reply />;

      default:
        return (
          <ModalIcon
            icon={this.props.icon}
            iconUrl={this.props.iconUrl}
            color={this.getModalIconColor()}
            bold
          />
        );
    }
  }

  getModalIconColor() {
    if (this.props.iconColor) {
      return this.props.iconColor;
    }

    switch (this.props.buttonType) {
      case 'danger':
        return 'red';

      case 'primary':
        return 'blue';

      default:
        return 'green';
    }
  }

  onEnter = () => {
    if (this.props.onEnter) {
      this.props.onEnter();
    }

    if (!this.props.loadOnEnter) {
      return;
    }

    this.setState({ isLoadingData: true });
    return this.props.loadOnEnter().then(() => {
      this.setState({ isLoadingData: false });
    });
  };

  handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.props.closeAfterResolve) {
      return this.props.onConfirm().then(this.props.onHide, (error) => {
        if (this.props.handleErrorInModal) {
          return;
        }

        throw error;
      });
    }

    this.props.onHide();
    this.props.onConfirm();
  };
}

export default ConfirmModal;
