import { cn } from '@keboola/design';

import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { CONTAINER_BASED } from '@/modules/sandboxes/Constants';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';

const SandboxStatusLabel = () => {
  const state = useStores(
    () => {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const configData = InstalledComponentsStore.getConfigData(KEBOOLA_SANDBOXES, configId);

      return {
        sandbox: SandboxesStore.getSandbox(configData.getIn(['parameters', 'id'])),
      };
    },
    [],
    [RoutesStore, InstalledComponentsStore, SandboxesStore],
  );

  if (!state.sandbox.count()) {
    return null;
  }

  const isActive = state.sandbox.get('active', false);

  return (
    <span
      className={cn('workspace-status-label', {
        'text-success': isActive,
        'bg-color-purple': !isActive && CONTAINER_BASED.includes(state.sandbox.get('type')),
      })}
    >
      {state.sandbox.get('active', false)
        ? 'ACTIVE'
        : CONTAINER_BASED.includes(state.sandbox.get('type'))
          ? 'SLEEPING'
          : 'INACTIVE'}
    </span>
  );
};

export default SandboxStatusLabel;
