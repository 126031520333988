import { useEffect, useRef, useState } from 'react';
import { fromJS, List, Map } from 'immutable';

import { Icon } from '@keboola/design';

import { KEBOOLA_EX_SAMPLE_DATA } from '@/constants/componentIds';
import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import StorageApi from '@/modules/components/StorageApi';
import LatestJobsStore from '@/modules/jobs/stores/LatestJobsStore';
import { JOB_FINISHED_STATUSES, JOB_RUNNING_STATUSES } from '@/modules/queue/constants';
import JobsStore from '@/modules/queue/store';
import { FileSize } from '@/react/common';
import Duration from '@/react/common/Duration';
import JobStatusIcon from '@/react/common/JobStatusIcon';
import Loader from '@/react/common/Loader';
import RowsCount from '@/react/common/RowsCount';
import useStores from '@/react/hooks/useStores';
import RunComponentButton from './RunComponentButton';
import StorageTableLink from './StorageApiTableLinkEx';

const SampleDataDetail = (props: {
  allTables: Map<string, any>;
  component: Map<string, any>;
  configurationId: string;
  hasNewQueue: boolean;
  name: string;
}) => {
  const timeout: { current: NodeJS.Timeout | null } = useRef(null);
  const [stats, setStats] = useState(Map());
  const { job, areJobsLoaded } = useStores(
    () => {
      const latestJobs = props.hasNewQueue
        ? JobsStore.getLatestJobs(KEBOOLA_EX_SAMPLE_DATA, props.configurationId)
        : LatestJobsStore.getJobs(KEBOOLA_EX_SAMPLE_DATA, props.configurationId);

      return {
        job: latestJobs.get('jobs', List()).first() || Map(),
        areJobsLoaded: latestJobs.get('isLoaded', false),
      };
    },
    [props.configurationId, props.hasNewQueue],
    [JobsStore, LatestJobsStore],
  );

  const isJobRunning = JOB_RUNNING_STATUSES.includes(job.get('status'));
  const isJobFinished = JOB_FINISHED_STATUSES.includes(job.get('status'));

  useEffect(() => {
    if (timeout.current) {
      clearInterval(timeout.current);
    }

    timeout.current = setInterval(() => {
      if (
        job.isEmpty() ||
        (JOB_FINISHED_STATUSES.includes(job.get('status')) && !stats.isEmpty())
      ) {
        return;
      }

      StorageApi.getRunIdStats(props.hasNewQueue ? job.get('id') : job.get('runId'))
        .tap(() => StorageActionCreators.loadBucketsAndTablesForce())
        .then((data) => setStats(fromJS(data)));
    }, 3000);

    return () => {
      if (timeout.current) {
        clearInterval(timeout.current);
      }
    };
  }, [props.hasNewQueue, job, stats]);

  const renderHeading = () => {
    if (isJobRunning) {
      return (
        <h2 className="box-title">
          <JobStatusIcon status={job.get('status')} className="icon-addon-right" />
          Loading Demo Data...
        </h2>
      );
    }

    if (isJobFinished) {
      const anyDeletedTable = stats
        .getIn(['tables', 'import', 'tables'], List())
        .some((table: Map<string, any>) => !props.allTables.has(table.get('id')));

      return (
        <div>
          <h2 className="box-title">
            {anyDeletedTable ? (
              <Icon icon="triangle-exclamation" className="icon-addon-right f-16 text-danger" />
            ) : stats.isEmpty() ? (
              <Loader className="icon-addon-right text-muted" />
            ) : (
              <JobStatusIcon status={job.get('status')} className="icon-addon-right" />
            )}
            {stats.isEmpty() ? 'Loading Data' : 'Loaded Demo Data'}
          </h2>
          {anyDeletedTable && (
            <p className="m-0 text-muted">
              Some Demo Data have been deleted. If you want to restore it, run configuration again.
            </p>
          )}
        </div>
      );
    }

    return <h2 className="box-title">Demo Data</h2>;
  };

  const renderTableStatus = (table: Map<string, any>) => {
    if (isJobRunning && table.isEmpty()) {
      return <span className="text-primary">Processing</span>;
    }

    if (isJobFinished && table.isEmpty()) {
      return <span className="text-danger">Deleted</span>;
    }

    if (isJobFinished && !table.isEmpty()) {
      return <span className="text-success">Finished</span>;
    }

    return <span className="text-muted">N/A</span>;
  };

  const renderTables = () => {
    const tables = stats.getIn(['tables', 'import', 'tables'], List());

    if (tables.isEmpty()) {
      return (
        <tr>
          <td colSpan={5} className="text-muted">
            {stats.isEmpty() ? 'Loading tables information...' : 'No table imported yet.'}
          </td>
        </tr>
      );
    }

    return tables
      .sortBy((table: Map<string, any>) => table.get('id').toLowerCase())
      .map((table: Map<string, any>) => {
        return {
          tableId: table.get('id'),
          storageTable: props.allTables.get(table.get('id'), Map()),
          statsTable: table,
        };
      })
      .map(
        (data: {
          tableId: string;
          storageTable: Map<string, any>;
          statsTable: Map<string, any>;
        }) => {
          return (
            <tr key={data.tableId}>
              <td className="no-wrap">
                <Icon icon="table" fixedWidth className="text-muted icon-addon-right" />
                <StorageTableLink tableId={data.tableId} showLabels={false} />
              </td>
              <td className="text-right">
                <FileSize size={data.storageTable.get('dataSizeBytes')} />
              </td>
              <td className="text-right">
                <RowsCount count={data.storageTable.get('rowsCount')} />
              </td>
              <td className="text-right">
                <Duration duration={data.statsTable.get('durationTotalSeconds')} />
              </td>
              <td className="text-right font-medium">{renderTableStatus(data.storageTable)}</td>
            </tr>
          );
        },
      )
      .toArray();
  };

  const renderBody = () => {
    if (job.isEmpty() && !areJobsLoaded) {
      return (
        <div className="box-content">
          <Loader className="icon-addon-right" />
          Loading data...
        </div>
      );
    }

    if (job.isEmpty()) {
      return (
        <>
          <div className="box-header big-padding above-table">
            <h2 className="box-title">Demo Data</h2>
          </div>
          <div className="box-content pt-1 pb-2 text-center">
            <p className="ml-2 mr-2 pl-2 pr-2">
              This is a configuration of the{' '}
              <strong>
                {props.component.get('name')}{' '}
                {getNewComponentTypeLabel(props.component.get('type'))}
              </strong>{' '}
              component that produces <strong>demo data</strong> when run.
            </p>
            <p className="mb-1 ml-2 mr-2 pl-2 pr-2">
              The produced data will be very similar to the output of the regular component.
            </p>
            <div className="mt-2">
              <RunComponentButton
                noTooltip
                componentId={KEBOOLA_EX_SAMPLE_DATA}
                runParams={() => ({ config: props.configurationId })}
                label="Run component"
                buttonIcon="circle-play"
                buttonBsStyle="success"
              >
                You are about to run the component.
              </RunComponentButton>
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="box-header big-padding above-table">{renderHeading()}</div>
        <table className="table">
          <thead>
            <tr>
              <th>Table</th>
              <th className="w-100 text-right">Size</th>
              <th className="w-100 text-right">Rows</th>
              <th className="w-100 text-right">Duration</th>
              <th className="w-100 text-right">Status</th>
            </tr>
          </thead>
          <tbody>{renderTables()}</tbody>
        </table>
      </>
    );
  };

  return <div className="box">{renderBody()}</div>;
};

export default SampleDataDetail;
