import type { ReactNode } from 'react';

import type { ValidityState } from '@keboola/design';
import { Alert, FormGroup, Label } from '@keboola/design';

const NameControl = (props: {
  value: string;
  onChange: (newValue: string) => void;
  error?: string;
  warning?: string;
}) => {
  const state = props.warning ? 'error' : 'default';
  const messages: Partial<Record<ValidityState, ReactNode>> = {
    error: props.warning,
  };

  return (
    <>
      {props.error && (
        <Alert variant="error" className="tw-mb-5">
          {props.error}
        </Alert>
      )}

      <FormGroup className="tw-mb-4" state={state}>
        <Label htmlFor="name">Name</Label>
        <FormGroup.TextInput
          id="name"
          autoFocus
          placeholder="Type a name"
          value={props.value}
          onChange={(value) => props.onChange(value)}
          variant="secondary"
        />
        <FormGroup.Help>{messages[state]}</FormGroup.Help>
      </FormGroup>
    </>
  );
};

export default NameControl;
