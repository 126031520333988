import { ControlLabel, FormGroup } from 'react-bootstrap';
import type { Map } from 'immutable';

import { URLS } from '@keboola/constants';
import { HelpBlock, Link } from '@keboola/design';

import CodeEditor from '@/react/common/CodeEditor';
import InferMapping from './InferMapping';

const MappingEditor = (props: {
  readOnly: boolean;
  endpointPath: number[];
  parameters: Map<string, any>;
  editing: Map<string, any>;
  allTables: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
}) => {
  return (
    <FormGroup>
      <div className="tw-flex tw-justify-between tw-gap-6">
        <ControlLabel>JSON</ControlLabel>
        <InferMapping
          readOnly={props.readOnly}
          endpointPath={props.endpointPath}
          parameters={props.parameters}
          editing={props.editing}
          setEditing={props.setEditing}
          allTables={props.allTables}
        />
      </div>
      <CodeEditor
        value={props.editing.get('mapping')}
        onChange={(value: string) => {
          props.setEditing(props.editing.set('mapping', value));
        }}
        options={{ readOnly: props.readOnly }}
      />
      <HelpBlock className="tw-mt-1">
        Read more about mapping in the{' '}
        <Link
          href={`${URLS.DEVELOPERS_DOCUMENTATION}/extend/generic-extractor/configuration/config/mappings/`}
        >
          documentation
        </Link>
        .
      </HelpBlock>
    </FormGroup>
  );
};

export default MappingEditor;
