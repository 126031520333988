import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { fromJS, List } from 'immutable';

import JobActionCreators from '@/modules/orchestrations/ActionCreators';
import TaskSelectModal from '@/modules/orchestrations/react/modals/TaskSelect';
import JobsStore from '@/modules/orchestrations/stores/OrchestrationJobsStore';

const JobRetryButton = createReactClass({
  propTypes: {
    job: PropTypes.object.isRequired,
    isSaving: PropTypes.bool.isRequired,
    notify: PropTypes.bool,
  },

  _handleRun() {
    return JobActionCreators.retryOrchestrationJob(
      this.props.job.get('id'),
      this.props.job.get('orchestrationId'),
      this.props.notify,
    );
  },

  _canBeRetried() {
    const status = this.props.job.get('status');

    return (
      status === 'success' ||
      status === 'error' ||
      status === 'cancelled' ||
      status === 'canceled' ||
      status === 'terminated' ||
      status === 'warning' ||
      status === 'warn'
    );
  },

  render() {
    if (this._canBeRetried() && this.props.job.get('tasks')) {
      const editingTasks = JobsStore.getEditingValue(this.props.job.get('id'), 'tasks') || List();

      return (
        <TaskSelectModal
          job={this.props.job}
          tasks={fromJS(JobActionCreators.rephaseTasks(editingTasks.toJS()))}
          onTaskUpdate={this._handleTaskChange}
          onTasksUpdate={this._handleTasksChange}
          onRun={this._handleRun}
          onOpen={this._handleRetrySelectStart}
          isSaving={this.props.isSaving}
        />
      );
    }

    return null;
  },

  reactivateJobTasks() {
    const enabledTaskStatuses = this.props.job
      .getIn(['results', 'tasks'], List())
      .filter((task) => task.get('active'));
    const jobSucceeded = this.props.job.get('status') === 'success';
    let failedPhaseId = null;
    return enabledTaskStatuses.forEach((task) => {
      const isTaskFailed = task.get('status') !== 'success';
      if (isTaskFailed && !failedPhaseId) {
        failedPhaseId = task.get('phase');
      }
      const previouslyFailed = failedPhaseId && failedPhaseId !== task.get('phase');
      return this._handleTaskChange(
        task.set('active', jobSucceeded || isTaskFailed || previouslyFailed),
      );
    });
  },

  _handleRetrySelectStart() {
    JobActionCreators.startJobRetryTasksEdit(this.props.job.get('id'));
    return this.reactivateJobTasks();
  },

  _handleTaskChange(updatedTask) {
    const tasks = JobsStore.getEditingValue(this.props.job.get('id'), 'tasks');
    if (tasks) {
      const index = tasks.findIndex((task) => task.get('id') === updatedTask.get('id'));
      return JobActionCreators.updateJobRetryTasksEdit(
        this.props.job.get('id'),
        tasks.set(index, tasks.get(index).set('active', updatedTask.get('active'))),
      );
    }
  },

  _handleTasksChange(updatedTasks) {
    let tasks = List();

    updatedTasks.forEach((phase) => {
      tasks = tasks.concat(phase.get('tasks'));
    });

    return JobActionCreators.updateJobRetryTasksEdit(this.props.job.get('id'), tasks);
  },
});

export default JobRetryButton;
