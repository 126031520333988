import createReactClass from 'create-react-class';

import ActionCreators from '@/modules/orchestrations/ActionCreators';
import JobsStore from '@/modules/orchestrations/stores/OrchestrationJobsStore';
import TerminateButton from '@/react/common/JobTerminateButton';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import JobRetryButton from './JobRetryButton';

const JobDetailButtons = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, JobsStore)],

  getStateFromStores() {
    const jobId = RoutesStore.getCurrentRouteIntParam('jobId');

    return {
      job: JobsStore.getJob(jobId),
      isSaving: JobsStore.getIsJobRetrying(jobId),
      isTerminating: JobsStore.getIsJobTerminating(jobId),
      sapiToken: ApplicationStore.getSapiToken(),
      readOnly: ApplicationStore.isReadOnly(),
    };
  },

  render() {
    if (!this.state.job || this.state.readOnly) {
      return null;
    }

    return (
      <>
        <JobRetryButton job={this.state.job} notify isSaving={this.state.isSaving} />
        <TerminateButton
          job={this.state.job}
          sapiToken={this.state.sapiToken}
          isTerminating={this.state.isTerminating}
          onTerminate={this.handleTerminate}
        />
      </>
    );
  },

  handleTerminate() {
    return ActionCreators.terminateJob(this.state.job.get('id'));
  },
});

export default JobDetailButtons;
