import { useEffect } from 'react';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import contactSupport from '@/utils/contactSupport';

const DEFAULT_MESSAGE =
  'Unable to load a section of the application. Refresh the page to resolve the issue. If the problem persists, please contact our support.';

export const ErrorBoundaryFallback = ({
  message = DEFAULT_MESSAGE,
  type = 'error',
  callback,
}: {
  message?: string;
  type?: 'info' | 'error' | 'warning';
  callback?: () => void;
}) => {
  useEffect(() => {
    ApplicationActionCreators.sendNotification({
      type,
      id: 'error-boundary-fallback',
      message: () => message,
      ...(type !== 'info' && {
        button: {
          label: 'Contact support',
          action: () => contactSupport(),
        },
      }),
    });

    callback?.();
  }, [message, callback, type]);

  return null;
};
