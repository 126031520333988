import { useState } from 'react';
import type { Map } from 'immutable';

import { canManageSharedBucket } from '@/modules/admin/privileges';
import { RowActionMenuItem } from '@/react/common';
import { DisableSharingModal } from './modals';

type Props = {
  bucket: Map<string, any>;
  sapiToken: Map<string, any>;
  urlTemplates: Map<string, any>;
};

export const DisableSharing = ({ bucket, sapiToken, urlTemplates }: Props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <RowActionMenuItem
        onSelect={() => setShowModal(true)}
        disabled={!canManageSharedBucket(sapiToken)}
        disabledReason="You don't have access rights to disable sharing. Ask your organization administrator for the permission."
      >
        Disable sharing
      </RowActionMenuItem>
      <DisableSharingModal
        show={showModal}
        closeModalFn={() => setShowModal(false)}
        urlTemplates={urlTemplates}
        bucket={bucket}
        sapiToken={sapiToken}
      />
    </>
  );
};
