import { useCallback, useEffect, useState } from 'react';
import type Promise from 'bluebird';
import { Map } from 'immutable';

import Loader from '@/react/common/Loader';
import RoutesStore from '@/stores/RoutesStore';
import EventDetailModal from './EventDetailModal';
import TableRow from './EventsTableRow';

const EventsTable = ({
  events,
  admins,
  isLoading,
  loadEvent,
  searchQuery = '',
}: {
  events: Map<string, any>;
  admins: Map<string, any>;
  isLoading: boolean;
  loadEvent: (eventId: string) => Promise<any>;
  searchQuery?: string;
}) => {
  const [detail, setDetail] = useState<Map<string, any>>(Map());

  useEffect(() => {
    const eventId = RoutesStore.getRouterState().getIn(['location', 'query', 'eventId']);

    if (eventId) {
      loadEvent(eventId).then(setDetail);
    }
  }, [loadEvent]);

  const onSelect = useCallback((event: Map<string, any>) => {
    setDetail(event);

    RoutesStore.getRouter().updateQuery({ eventId: event.get('id') });
  }, []);

  const onHide = useCallback(() => {
    setDetail(Map());

    RoutesStore.getRouter().updateQuery({ eventId: '' });
  }, []);

  const renderRows = () => {
    if (events.isEmpty()) {
      return (
        <div className="tr">
          <div className="td">
            {isLoading ? (
              <>
                <Loader className="icon-addon-right" />
                Loading events...
              </>
            ) : (
              'No events found'
            )}
          </div>
        </div>
      );
    }

    return events
      .map((event: Map<string, any>) => {
        return (
          <TableRow
            key={event.get('id')}
            onClick={onSelect}
            admins={admins}
            event={event}
            searchQuery={searchQuery}
          />
        );
      })
      .toArray();
  };

  return (
    <>
      <div className="table table-hover">
        <div className="thead">
          <div className="tr">
            <div className="th">Component</div>
            <div className="th">Event {isLoading && !events.isEmpty() && <Loader />}</div>
            <div className="th text-right">Created</div>
          </div>
        </div>
        <div className="tbody">{renderRows()}</div>
      </div>
      <EventDetailModal onHide={onHide} event={detail} />
    </>
  );
};

export default EventsTable;
