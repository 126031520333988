import { componentTypes } from '@/constants/componentTypes';
import { getAllowedTransformations } from '@/modules/components/helpers';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import AddSandboxButton from './AddSandboxButton';

const SandboxesHeaderButton = () => {
  const state = useStores(
    () => {
      return {
        hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
        readOnly: ApplicationStore.isReadOnly(),
        allowedTransformationComponents: getAllowedTransformations(
          ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
          ApplicationStore.getSapiToken(),
          ApplicationStore.getCurrentProjectFeatures(),
          StackFeaturesStore.getAll(),
        ),
      };
    },
    [],
    [ApplicationStore, StackFeaturesStore, ComponentsStore, InstalledComponentsStore],
  );

  if (state.readOnly) {
    return null;
  }

  return (
    <AddSandboxButton
      allowedComponents={state.allowedTransformationComponents}
      hasPayAsYouGo={state.hasPayAsYouGo}
    />
  );
};

export default SandboxesHeaderButton;
