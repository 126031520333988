import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import {
  isDataTypeAsString,
  parseDataTypeFromString,
} from '@/modules/transformations/utils/parseDataType';
import Checkbox from '@/react/common/Checkbox';
import DatatypeFormRow from './DatatypeFormRow';

const INITIAL_STATE = {
  convertToType: '',
  convertAll: false,
};

const DatatypeForm = createReactClass({
  propTypes: {
    datatypes: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired,
    datatypesMap: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    sourceTable: PropTypes.string,
    sourceTableData: PropTypes.instanceOf(Map),
    disabled: PropTypes.bool,
  },

  getInitialState() {
    return INITIAL_STATE;
  },

  componentDidUpdate(prevProps) {
    if (this.props.sourceTable !== prevProps.sourceTable) {
      this.setState(INITIAL_STATE);
    }
  },

  render() {
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Column</th>
            <th>
              Type{' '}
              <select value={this.state.convertToType} onChange={this.handleConvertToType}>
                <option disabled value="">
                  Set All Columns To
                </option>
                {this.props.datatypesMap
                  .sortBy((type) => type.get('name'))
                  .map((type, index) => {
                    return (
                      <option value={type.get('name')} key={index}>
                        {type.get('name')}
                        {type.get('size') &&
                          type.get('maxLength') &&
                          '(' + type.get('maxLength') + ')'}
                      </option>
                    );
                  })
                  .toArray()}
              </select>
            </th>
            <th>Length</th>
            <th>
              <Checkbox checked={this.state.convertAll} onChange={this.handleConvertAllChange}>
                Set all empty values to <code>null</code>
              </Checkbox>
            </th>
          </tr>
        </thead>
        <tbody>{this.props.columns.map(this.renderColumn)}</tbody>
      </table>
    );
  },

  renderColumn(column) {
    let columnDataType = this.props.datatypes.find((type, columnName) => {
      const computedName = typeof type === 'string' ? columnName : type.get('column');
      return computedName === column;
    });
    if (isDataTypeAsString(columnDataType)) {
      columnDataType = parseDataTypeFromString(columnDataType, column);
    }
    return (
      <DatatypeFormRow
        key={column}
        columnName={column}
        datatype={columnDataType}
        datatypesMap={this.props.datatypesMap}
        onChange={this.handleDatatypeChange}
        disabled={this.props.disabled}
        isPrimaryKey={this.props.sourceTableData.get('primaryKey', List()).includes(column)}
      />
    );
  },

  handleDatatypeChange(newType) {
    this.props.onChange(this.props.datatypes.set(newType.get('column'), newType));
  },

  handleConvertAllChange(checked) {
    this.setState({ convertAll: checked });
    this.props.onChange(
      this.props.datatypes.map((datatype) => {
        if (this.props.sourceTableData.get('primaryKey', List()).includes(datatype.get('column'))) {
          return datatype;
        }
        return datatype.set('convertEmptyValuesToNull', checked);
      }),
    );
  },

  handleConvertToType(e) {
    const selected = this.props.datatypesMap.find(
      (datatype) => datatype.get('name') === e.target.value,
    );
    this.setState({ convertToType: selected.get('name') });
    this.props.onChange(
      this.props.datatypes.map((datatype) => {
        return datatype.merge({
          type: selected.get('name'),
          length: selected.get('maxLength', null),
        });
      }),
    );
  },
});

export default DatatypeForm;
