import { useState } from 'react';

import { IconButton, Tooltip } from '@keboola/design';

import ConfirmModal from '@/react/common/ConfirmModal';

type Props = {
  configName: string;
  isPending: boolean;
  onConfirm: () => Promise<any>;
};

const DeleteConfigurationButton = ({ configName, isPending, onConfirm }: Props) => {
  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <>
      <Tooltip tooltip="Delete Forever" placement="top">
        <IconButton
          variant="invisible"
          disabled={isPending}
          onClick={() => setModalVisible(true)}
          isLoading={isPending}
          icon="trash"
        />
      </Tooltip>
      <ConfirmModal
        show={isModalVisible}
        onHide={() => setModalVisible(false)}
        icon="trash"
        buttonLabel="Delete"
        buttonType="danger"
        title="Delete Forever"
        text={
          <>
            Are you sure you want to permanently delete the configuration{' '}
            <strong>{configName}</strong>?
          </>
        }
        onConfirm={onConfirm}
        isDisabled={isPending}
      />
    </>
  );
};

export default DeleteConfigurationButton;
