import PropTypes from 'prop-types';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import { getCurrentBranchTableWithProductionFallback } from '@/modules/storage/helpers';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import Select from '@/react/common/Select';
import { getColumnsOptions } from './helpers';

const ColumnsSelectRow = createReactClass({
  propTypes: {
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    allTables: PropTypes.object.isRequired,
    formType: PropTypes.oneOf(['normal', 'horizontal']),
    disabled: PropTypes.bool,
    componentId: PropTypes.string,
  },

  getDefaultProps() {
    return {
      formType: 'horizontal',
    };
  },

  render() {
    if (this.props.formType === 'horizontal') {
      return (
        <div className="form-group">
          <label htmlFor="columns" className="col-xs-2 control-label">
            Columns
          </label>
          <div className="col-xs-10">
            <Select
              multi
              id="columns"
              name="columns"
              value={this.props.value.get('columns', List()).toJS()}
              disabled={this.props.disabled || !this.props.value.get('source')}
              placeholder="All columns will be imported"
              onChange={this._handleChangeColumns}
              options={this._getColumnsOptions()}
            />
            {this.renderHelpBlock()}
          </div>
        </div>
      );
    }

    return (
      <FormGroup>
        <ControlLabel>
          Columns <OptionalFormLabel />
        </ControlLabel>
        <Select
          multi
          name="columns"
          value={this.props.value.get('columns', List()).toJS()}
          disabled={this.props.disabled || !this.props.value.get('source')}
          placeholder="All columns will be imported"
          onChange={this._handleChangeColumns}
          options={this._getColumnsOptions()}
        />
        {this.renderHelpBlock()}
      </FormGroup>
    );
  },

  renderHelpBlock() {
    return <div className="help-block">Specify columns to import or leave empty to import all</div>;
  },

  _handleChangeColumns(columns) {
    return this.props.onChange(this.props.value.set('columns', columns));
  },

  _getColumnsOptions() {
    const columns = getCurrentBranchTableWithProductionFallback(
      this.props.allTables,
      this.props.value.get('source'),
    ).get('columns', List());

    return getColumnsOptions(columns, this.props.componentId);
  },
});

export default ColumnsSelectRow;
