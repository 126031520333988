import { useState } from 'react';
import { Promise } from 'bluebird';
import type { Map } from 'immutable';

import ConfigurationRowsActionCreators from '@/modules/configurations/ConfigurationRowsActionCreators';
import ConfirmModal from '@/react/common/ConfirmModal';

type Props = {
  show: boolean;
  onHide: () => void;
  onDone: () => void;
  componentId: string;
  configId: string;
  selected: string[];
  rows: Map<string, any>;
};

export const RemoveSelectedRowsModal = (props: Props) => {
  const [isProcessing, setIsProcessing] = useState(false);

  return (
    <ConfirmModal
      closeAfterResolve
      isLoading={isProcessing}
      show={props.show}
      icon="trash"
      title="Remove Selected"
      text={`Are you sure you want to remove the ${
        props.selected.length > 1 ? 'selected rows' : 'row'
      }?`}
      buttonLabel="Remove"
      buttonType="danger"
      onConfirm={() => {
        setIsProcessing(true);
        return Promise.each(props.selected, (rowId) => {
          return ConfigurationRowsActionCreators.deleteSimple(
            props.componentId,
            props.configId,
            rowId,
            `Row ${props.rows.getIn([rowId, 'name'], rowId)} deleted`,
          );
        })
          .tap(props.onDone)
          .finally(() => setIsProcessing(false));
      }}
      onHide={props.onHide}
    />
  );
};
