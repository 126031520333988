import { PureComponent } from 'react';
import confetti from 'canvas-confetti';

import RoutesStore from '@/stores/RoutesStore';

const fireConfetti = () => {
  const commonOptions = {
    particleCount: 200,
    spread: 55,
    colors: ['1F8FFF', 'FFFFFF', 'C5CBD6'],
  };

  confetti({
    ...commonOptions,
    origin: { x: 1 },
    angle: 120,
  });

  confetti({
    ...commonOptions,
    origin: { x: 0 },
    angle: 60,
  });
};

type Props = { location: Record<string, any> };

class Confetti extends PureComponent<Props> {
  componentDidMount() {
    fireConfetti();

    const query = { ...this.props.location.query };
    delete query.celebrate;
    RoutesStore.getRouter().replaceTo(
      this.props.location.pathname,
      null,
      query,
      this.props.location.hash,
    );
  }

  render() {
    return null;
  }
}

export default Confetti;
