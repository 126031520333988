import { sortEntities } from '@/constants';
import { getItem, setItem } from '@/utils/localStorage';

export type SortConfig = {
  entity: string;
  order: number;
};

export const sortKeys = {
  SHARED_WITH_YOU: 'dataCatalog.sharedWithYou.sort',
  SHARED_FROM_PROJECT: 'dataCatalog.sharedFromProject.sort',
} as const;

type SortKey = (typeof sortKeys)[keyof typeof sortKeys];

export const getInitialSort = (key: SortKey): SortConfig => {
  try {
    const stored = getItem(key, null);
    if (stored) {
      return JSON.parse(stored) as SortConfig;
    }
  } catch {
    console.error('Error getting initial sort', key);
  }
  return { entity: sortEntities.NAME, order: 1 };
};

export const saveSort = (key: SortKey, sort: SortConfig) => {
  setItem(key, JSON.stringify(sort));
};
