import { useState } from 'react';
import type { ComponentProps, ReactNode } from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';

import { Icon } from '@keboola/design';

import ConfirmModal from './ConfirmModal';
import Loader from './Loader';

type Props = {
  isSaving: boolean;
  isChanged: boolean;
  disabled?: boolean;
  saveStyle?: string;
  onReset: () => void;
  onSave: () => Promise<unknown> | void;
  className?: string;
  showModal?: boolean;
  modalTitle?: string;
  modalBody?: ReactNode;
  buttonLabel?: string;
  icon?: ComponentProps<typeof Icon>['icon'];
};
const SaveButtons = ({
  isSaving,
  isChanged,
  disabled = false,
  saveStyle = 'success',
  onReset,
  onSave,
  className,
  showModal = false,
  modalTitle = '',
  modalBody = null,
  buttonLabel = 'Save',
  icon,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isSavingModal, setIsSavingModal] = useState(false);

  const handleSaveButtonClick = () => {
    if (!showModal) {
      return onSave();
    }
    setModalOpen(true);
  };

  const handleSave = () => {
    setIsSavingModal(true);
    return Promise.resolve(onSave()).finally(() => setIsSavingModal(false));
  };

  return (
    <>
      {isChanged && (
        <ButtonToolbar className={className}>
          <Button onClick={onReset} disabled={isSaving}>
            <Icon icon="arrow-rotate-left" className="icon-addon-right" />
            Reset
          </Button>
          <Button
            className="save-button"
            bsStyle={saveStyle}
            disabled={disabled || isSaving}
            onClick={handleSaveButtonClick}
          >
            {isSaving ? <Loader /> : 'Save'}
          </Button>
        </ButtonToolbar>
      )}
      {showModal && (
        <ConfirmModal
          closeAfterResolve
          buttonLabel={buttonLabel}
          text={modalBody}
          title={modalTitle}
          onConfirm={handleSave}
          onHide={() => setModalOpen(false)}
          show={modalOpen}
          isLoading={isSavingModal}
          icon={icon}
        />
      )}
    </>
  );
};

export default SaveButtons;
