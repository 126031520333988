import type { Map } from 'immutable';

import { extractErrorDetails, hasInternalError } from '@/modules/queue/helpers';
import string from '@/utils/string';

const JobError = ({ job }: { job: Map<string, any> }) => {
  if (
    job.get('status') !== 'error' ||
    (!hasInternalError(job) && !job.getIn(['result', 'message']))
  ) {
    return null;
  }

  return (
    <span className="job-status-label text-danger">
      {' - '}
      {hasInternalError(job)
        ? 'Internal Error'
        : string.truncate(extractErrorDetails(job.getIn(['result', 'message'])), 40)}
    </span>
  );
};

export default JobError;
