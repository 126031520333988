import type { ReactNode } from 'react';
import { List, type Map } from 'immutable';

import { GENERIC_DOCKER_UI_AUTHORIZATION } from '@/constants/componentFlags';
import {
  KEBOOLA_DATA_APPS,
  KEBOOLA_ORCHESTRATOR,
  KEBOOLA_SANDBOXES,
  KEBOOLA_SHARED_CODE,
} from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import { routeNames as dataAppRouteNames } from '@/modules/data-apps/constants';
import { deleteCredentialsAndConfigAuth } from '@/modules/oauth-v2/OauthUtils';
import AuthorizationRow from '@/modules/oauth-v2/react/AuthorizationRow';
import OauthStore from '@/modules/oauth-v2/Store';
import { routeNames as sanboxRouteNames } from '@/modules/sandboxes/Constants';
import { prepareTablesMetadataMap } from '@/modules/storage/helpers';
import { routeNames as transformationRouteNames } from '@/modules/transformations-v2/constants';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import ConfigurationInfoPanel from '@/react/common/ConfigurationInfoPanel';
import { getFakeComponentId } from '@/react/common/ConfigurationsTable/helpers';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import Markdown from '@/react/common/Markdown';
import useStores from '@/react/hooks/useStores';
import Sidebar from '@/react/layout/Sidebar/Sidebar';
import ApplicationStore from '@/stores/ApplicationStore';

type Props = {
  children: ReactNode;
  componentId: string;
  configId: string;
  rowId?: string;
  sidebar?: ReactNode;
  sidebarProps?: Record<string, any>;
  withConfigDescription?: boolean;
};

type StoreData = {
  config: Map<string, any>;
  configData: Map<string, any>;
  component: Map<string, any>;
  allComponents: Map<string, any>;
  componentsMetadata: Map<string, any>;
  flows: Map<string, any>;
  oauthCredentials: Map<string, any>;
  allTables: Map<string, any>;
  admins: Map<string, any>;
  readOnly: boolean;
};

export const GenericConfigBody = ({
  children,
  componentId,
  configId,
  rowId,
  sidebar,
  sidebarProps,
}: Props) => {
  const store = useStores(
    () => {
      return {
        config: InstalledComponentsStore.getConfig(componentId, configId),
        configData: InstalledComponentsStore.getConfigData(componentId, configId),
        component: ComponentsStore.getComponent(componentId),
        allComponents: ComponentsStore.getAll() as Map<string, any>,
        componentsMetadata: InstalledComponentsStore.getAllMetadata() as Map<string, any>,
        flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
        oauthCredentials: OauthStore.getCredentials(
          componentId,
          InstalledComponentsStore.getConfigData(componentId, configId),
        ),
        allTables: StorageTablesStore.getAll(),
        readOnly: ApplicationStore.isReadOnly(),
        admins: ApplicationStore.getAdmins(),
      } as StoreData;
    },
    [componentId, configId],
    [ApplicationStore, ComponentsStore, OauthStore, InstalledComponentsStore, StorageTablesStore],
  );

  return (
    <>
      <ConfigurationTabs
        componentId={componentId}
        configId={configId}
        rowId={rowId}
        {...(store.component.get('type') === componentTypes.TRANSFORMATION && {
          versionsLinkTo: transformationRouteNames.GENERIC_TRANSFORMATION_VERSIONS,
          notificationsLinkTo: transformationRouteNames.GENERIC_TRANSFORMATION_NOTIFICATIONS,
        })}
        {...(componentId === KEBOOLA_SHARED_CODE && {
          versionsLinkTo: transformationRouteNames.SHARED_CODE_VERSIONS,
        })}
        {...(componentId === KEBOOLA_DATA_APPS && {
          versionsLinkTo: dataAppRouteNames.DATA_APP_VERSIONS,
          notificationsLinkTo: dataAppRouteNames.DATA_APP_NOTIFICATIONS,
        })}
        {...(componentId === KEBOOLA_SANDBOXES && {
          versionsLinkTo: sanboxRouteNames.WORKSPACE_VERSIONS,
        })}
      />
      {![KEBOOLA_SHARED_CODE, KEBOOLA_SANDBOXES].includes(componentId) && (
        <ConfigurationInfoPanel
          config={store.config}
          component={store.component}
          {...(!rowId && {
            flows: store.flows,
            allComponents: store.allComponents,
            metadata: store.componentsMetadata,
            tablesMetadataMap: prepareTablesMetadataMap(store.allTables),
            realComponent: store.allComponents.get(
              getFakeComponentId(store.configData) || componentId,
            ),
          })}
        />
      )}
      <div className="row">
        <div className="col-sm-9 tw-mb-6">
          <ComponentDescription
            componentId={componentId}
            configId={configId}
            rowId={rowId}
            {...(componentId === KEBOOLA_SANDBOXES && { placeholderEntity: 'Workspace' })}
            {...(componentId === KEBOOLA_SHARED_CODE && { placeholderEntity: 'Shared Code' })}
          />
          {store.component.get('configurationDescription') && (
            <CollapsibleBox title="Configuration Description" entity="description">
              <Markdown
                collapsible={false}
                source={store.component.get('configurationDescription')}
              />
            </CollapsibleBox>
          )}
          {store.component.get('flags', List()).includes(GENERIC_DOCKER_UI_AUTHORIZATION) && (
            <AuthorizationRow
              configId={configId}
              componentId={componentId}
              readOnly={store.readOnly}
              credentials={store.oauthCredentials}
              onResetCredentials={() => deleteCredentialsAndConfigAuth(componentId, configId)}
              admins={store.admins}
            />
          )}
          {children}
        </div>
        <div className="col-sm-3">
          {sidebar ?? (
            <Sidebar
              componentId={componentId}
              configId={configId}
              rowId={rowId}
              {...sidebarProps}
            />
          )}
        </div>
      </div>
    </>
  );
};
