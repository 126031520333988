import { useMutation, useQueryClient } from '@tanstack/react-query';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { storageApiKey } from '@/constants/queryKeys';
import installedComponentsApi from '@/modules/components/InstalledComponentsApi';

const deleteComponent = (componentId: string, configurationId: string, configName: string) => {
  return installedComponentsApi.deleteConfiguration(componentId, configurationId).then(() => {
    ApplicationActionCreators.sendNotification({
      type: 'info',
      message: () => (
        <>
          Configuration <b>{configName}</b> was permanently deleted.
        </>
      ),
    });

    return null;
  });
};

export const useDeleteComponent = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      componentId,
      configurationId,
      configurationName,
    }: {
      componentId: string;
      configurationId: string;
      configurationName: string;
    }) => deleteComponent(componentId, configurationId, configurationName),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: storageApiKey.trash() });
    },
  });
};
