import type Promise from 'bluebird';
import type { Map } from 'immutable';
import { List } from 'immutable';

import type { ACTIVE_MENU } from '@/modules/ex-generic/constants';
import { prepareUserParameters } from '@/modules/ex-generic/helpers';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import DocumentationLink from './DocumentationLink';
import GenericPairs from './GenericPairs';

const Params = (props: {
  readOnly: boolean;
  editing: Map<string, any>;
  parameters: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
  onSave: (
    parameters: Map<string, any>,
    changeDescription: string,
    newMenu?: ACTIVE_MENU,
  ) => Promise<any>;
}) => {
  const rows = props.editing.get('params', List());

  return (
    <CollapsibleBox
      title="Default Query Parameters"
      defaultOpen={!rows.isEmpty()}
      titleSuffix={<DocumentationLink path="configuration/api/#request-parameters" />}
    >
      <GenericPairs
        entity="Param"
        rows={rows}
        onSave={props.onSave}
        readOnly={props.readOnly}
        parameters={props.parameters}
        onChange={(params) => props.setEditing(props.editing.set('params', params))}
        userParameters={prepareUserParameters(props.parameters)}
      />
    </CollapsibleBox>
  );
};

export default Params;
