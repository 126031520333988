import { useEffect, useState } from 'react';
import type { ComponentProps, KeyboardEvent, MouseEvent, ReactNode } from 'react';
import { Button, SplitButton } from 'react-bootstrap';
import Promise from 'bluebird';
import { Map } from 'immutable';
import _ from 'underscore';

import { cn, Icon, Tooltip, type TooltipProps } from '@keboola/design';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import {
  ORCHESTRATION_SETUP,
  RUN_COMPONENT_BUTTON,
  RUN_COMPONENT_MENU_ITEM,
  runComponent,
} from '@/constants/external';
import { FEATURE_POWER_USER } from '@/constants/features';
import { canRunJob } from '@/modules/admin/privileges';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { RowActionMenuItem } from '@/react/common';
import RunActionLink from '@/react/common/ActionControls/RunActionLink';
import { RunActionLinkStatus } from '@/react/common/ActionControls/RunActionLinkStatus';
import { READ_ONLY_TOOLTIP_MESSAGE } from '@/react/common/constants';
import Loader from '@/react/common/Loader';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import string from '@/utils/string';
import RunComponentButtonModal from './RunComponentButtonModal';

type Props = {
  runParams: () => Record<string, any>;
  componentId?: string;
  mode?: 'button' | 'sidebar' | 'menuitem';
  method?: string;
  title?: string;
  runLabel?: string;
  label?: string;
  tooltip?: string;
  disabled?: boolean;
  disabledReason?: string;
  tooltipPlacement?: TooltipProps['placement'];
  forceModal?: boolean;
  children: ReactNode;
  modalRunButtonDisabled?: boolean;
  buttonBsStyle?: string;
  buttonIcon?: ComponentProps<typeof Icon>['icon'];
  noTooltip?: boolean;
  onEnter?: () => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  onBeforeRun?: () => void;
  onAfterRun?: (job: Record<string, any>) => void;
  additionalActions?: ReactNode;
};

const RunComponentButton = (props: Props) => {
  const [isRunning, setIsRunning] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const {
    componentId,
    mode = 'button',
    method = 'run',
    title = 'Run Component',
    runLabel = 'Run',
    tooltip = 'Run',
    label,
    disabled = false,
    disabledReason = '',
    tooltipPlacement = 'top',
    forceModal = false,
    modalRunButtonDisabled = false,
    buttonBsStyle = 'link',
    buttonIcon = 'play',
    noTooltip = false,
    additionalActions,
    children,
    onEnter = _.noop,
    onKeyDown = _.noop,
    onBeforeRun = _.noop,
    onAfterRun = _.noop,
    runParams,
  } = props;
  const configId = runParams()?.config;

  const state = useStores(
    () => {
      return {
        readOnly: !canRunJob(ApplicationStore.getSapiToken()),
        component: ComponentStore.getComponent(componentId) || Map(),
        config: InstalledComponentsStore.getConfig(componentId, configId),
        isPowerUser: ApplicationStore.hasCurrentAdminFeature(FEATURE_POWER_USER) as boolean,
      };
    },
    [componentId, configId],
    [ApplicationStore, ComponentStore, RoutesStore, InstalledComponentsStore],
  );

  useEffect(() => {
    if (window.location.hash === '#run') {
      setShowModal(true);
    }
  }, []);

  const shouldShowOutputMappingWarning = () => {
    if (state.isPowerUser || state.component.get('type') !== componentTypes.TRANSFORMATION) {
      return false;
    }

    const output = state.config.getIn(['configuration', 'storage', 'output'], Map());

    return output.get('tables', Map()).size === 0 && output.get('files', Map()).size === 0;
  };

  const renderRunButton = () => {
    if (mode === 'sidebar') {
      return renderSidebar();
    }

    if (mode === 'menuitem') {
      return renderMenuItem();
    }

    return renderButton();
  };

  const renderModal = () => {
    return (
      <RunComponentButtonModal
        onHide={onHideModal}
        show={showModal}
        title={title}
        runLabel={runLabel}
        body={children}
        onRequestRun={handleRunStart}
        disabled={modalRunButtonDisabled || isRunning}
        shouldShowOutputMappingWarning={shouldShowOutputMappingWarning()}
        forceModal={forceModal}
        className={getExternalClass(
          mode === 'menuitem' ? RUN_COMPONENT_MENU_ITEM : RUN_COMPONENT_BUTTON,
        )}
        onEnter={onEnter}
        onExited={() => {
          if (window.location.hash === '#run') {
            window.history.replaceState({}, document.title, window.location.href.split('#')[0]);
          }
        }}
      />
    );
  };

  const renderButton = () => {
    const text = label ? (
      <>
        {isRunning ? (
          <Loader className="icon-addon-right" />
        ) : (
          <Icon icon={buttonIcon} className="icon-addon-right" />
        )}
        {label}
      </>
    ) : (
      <>{isRunning ? <Loader /> : <Icon icon="play" fixedWidth />}</>
    );
    const button = !!additionalActions ? (
      <SplitButton
        id="run-component-button-with-additional-actions"
        bsStyle={buttonBsStyle}
        onClick={onOpenButtonClick}
        className={cn({
          [getExternalClass(RUN_COMPONENT_BUTTON)]: shouldSkipModal(),
        })}
        disabled={isDisabled()}
        title={text}
        pullRight
      >
        {additionalActions}
      </SplitButton>
    ) : (
      <Button
        bsStyle={buttonBsStyle}
        onClick={(event: MouseEvent) => !isDisabled() && onOpenButtonClick(event)}
        className={cn({
          [getExternalClass(RUN_COMPONENT_BUTTON)]: shouldSkipModal(),
          disabled: isDisabled(),
        })}
      >
        {text}
      </Button>
    );
    return renderTooltipWrapper(button);
  };

  const renderTooltipWrapper = (body: ReactNode) => {
    if (noTooltip && !state.readOnly) {
      return body;
    }

    if (isDisabled()) {
      return (
        <Tooltip
          type="explanatory"
          tooltip={
            state.readOnly
              ? READ_ONLY_TOOLTIP_MESSAGE
              : isRunning
                ? 'Component is running.'
                : disabledReason
          }
          placement={tooltipPlacement}
        >
          {body}
        </Tooltip>
      );
    }

    if (mode === 'button' && (!label || !label.startsWith(tooltip))) {
      return (
        <Tooltip tooltip={tooltip} placement={tooltipPlacement}>
          {body}
        </Tooltip>
      );
    }

    return body;
  };

  const getLinkStatus = () => {
    if (disabled || state.readOnly) {
      return RunActionLinkStatus.DISABLED;
    }

    if (isRunning) {
      return RunActionLinkStatus.RUNNING;
    }

    return RunActionLinkStatus.READY;
  };

  const getLinkTooltip = (status: string) => {
    if (state.readOnly) {
      return READ_ONLY_TOOLTIP_MESSAGE;
    }

    if (status === RunActionLinkStatus.DISABLED) {
      return disabledReason;
    }

    if (status === RunActionLinkStatus.RUNNING) {
      return 'Component is running';
    }

    return tooltip;
  };

  const renderMenuItem = () => {
    return (
      <RowActionMenuItem
        className={shouldSkipModal() ? getExternalClass(RUN_COMPONENT_MENU_ITEM) : ''}
        onSelect={onShowModal}
        disabled={isDisabled()}
        onKeyDown={onKeyDown}
      >
        {renderTooltipWrapper(
          <>
            {isRunning ? <Loader /> : <Icon icon="circle-play" fixedWidth />}
            {string.capitalize(title.toLowerCase())}
          </>,
        )}
      </RowActionMenuItem>
    );
  };

  const renderSidebar = () => {
    const linkStatus = getLinkStatus();

    return (
      <>
        <RunActionLink
          className={shouldSkipModal() ? getExternalClass(RUN_COMPONENT_BUTTON) : ''}
          onClick={onShowModal}
          label={title}
          status={linkStatus}
          tooltip={getLinkTooltip(linkStatus)}
        />
        <hr />
      </>
    );
  };

  const getExternalClass = (className: string) => {
    return `${className} ${
      state.component.get('id') === KEBOOLA_ORCHESTRATOR ? ORCHESTRATION_SETUP : ''
    } ${runComponent(state.component)}`;
  };

  const handleRunStart = () => {
    setIsRunning(true);

    Promise.resolve()
      .then(onBeforeRun)
      .then(() => {
        return InstalledComponentsActionCreators.runComponent({
          method,
          component: componentId,
          data: runParams(),
        });
      })
      .then(onAfterRun)
      .finally(() => {
        setIsRunning(false);
      });
  };

  const onOpenButtonClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    onShowModal();
  };

  const onShowModal = () => {
    if (shouldSkipModal()) {
      return handleRunStart();
    }

    setShowModal(true);
  };

  const onHideModal = () => {
    setShowModal(false);
  };

  const shouldSkipModal = () => {
    return !forceModal && state.isPowerUser;
  };

  const isDisabled = () => {
    return state.readOnly || disabled || isRunning || !componentId;
  };

  return (
    <>
      {renderModal()}
      {renderRunButton()}
    </>
  );
};

export default RunComponentButton;
