import type { ButtonHTMLAttributes, ComponentProps, MouseEvent } from 'react';

import { Icon } from '../Icon';

import { buttonStyles } from './styles';
import type { CommonButtonProps } from './types';

type IconButtonProps = {
  icon: ComponentProps<typeof Icon>['icon'];
  onClick?: (e: MouseEvent) => void;
  isFilledIcon?: boolean;
  isLoading?: boolean;
};

export const IconButton = ({
  onClick,
  size = 'medium',
  variant = 'primary',
  type = 'button',
  disabled = false,
  icon,
  isFilledIcon = false,
  isLoading = false,
  className,
}: CommonButtonProps & IconButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const styles = buttonStyles({ variant, size, icon, disabled, isFilledIcon, className });
  return (
    <button
      data-button-group-id="icon-button"
      type={type}
      onClick={onClick}
      className={styles}
      disabled={disabled}
      data-disabled={disabled}
    >
      <Icon
        spin={isLoading}
        icon={isLoading ? 'spinner' : icon}
        className="tw-inline-block tw-h-4 tw-w-4 tw-align-middle"
      />
    </button>
  );
};
