import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import type { Map } from 'immutable';

import type { SandboxDetail } from '@keboola/api-client';
import { Separator } from '@keboola/design';

import SandboxesActions from '@/modules/sandboxes/Actions';
import ClientInfo from '@/modules/sandboxes/components/ClientInfo';
import { CONTAINER_BASED, NEED_CLIENT_TO_CONNECT } from '@/modules/sandboxes/Constants';
import { needsToLoadCredentials, prepareCredentialsData } from '@/modules/sandboxes/helpers';
import { ConnectToSandboxButton } from '@/react/common/ConnectToSandboxButton';
import CredentialsBox from '@/react/common/CredentialsBox';
import ModalIcon from '@/react/common/ModalIcon';
import { ModalBodySkeleton } from './ModalBodySkeleton';
import { ResetPasswordAlert } from './ResetPasswordAlert';

type Props = {
  sandbox: Map<string, any>;
  show: boolean;
  onHide: () => void;
};

export const CredentialsModal = ({ sandbox, show, onHide }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const sandboxjs = sandbox.toJS() as SandboxDetail;

  const type = sandboxjs.type!;
  const id = sandboxjs.id!;
  const configurationId = sandboxjs.configurationId!;
  const isManualConnect = NEED_CLIENT_TO_CONNECT.includes(type);
  const isContainerBased = CONTAINER_BASED.includes(type);

  const onEnter = () => {
    if (!needsToLoadCredentials(sandbox)) return;
    setIsLoading(true);
    SandboxesActions.loadSandboxForce(id).finally(() => setIsLoading(false));
  };

  return (
    <Modal show={show} onHide={onHide} onEnter={onEnter}>
      <Modal.Header closeButton>
        <Modal.Title>Workspace Credentials</Modal.Title>
        <ModalIcon icon="user" color="green" bold />
      </Modal.Header>

      <Modal.Body className="tw-flex tw-flex-col tw-gap-4">
        {isLoading ? (
          <ModalBodySkeleton />
        ) : (
          <>
            <CredentialsBox noBorder rows={prepareCredentialsData(sandbox)} />
            {isManualConnect && (
              <>
                <Separator orientation="horizontal" />
                <ClientInfo className="tw-m-0" />
              </>
            )}
            {!isContainerBased && <ResetPasswordAlert id={id} configId={configurationId} />}
          </>
        )}
      </Modal.Body>
      {!isManualConnect && (
        <Modal.Footer>
          <ConnectToSandboxButton className="tw-w-full" sandbox={sandboxjs} />
        </Modal.Footer>
      )}
    </Modal>
  );
};
