import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { Icon } from '@keboola/design';

import { KEBOOLA_ORCHESTRATOR, TRANSFORMATION } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';

const ComponentSelect = createReactClass({
  propTypes: {
    components: PropTypes.object.isRequired,
    legacyOrchestrations: PropTypes.object.isRequired,
    onComponentSelect: PropTypes.func.isRequired,
    hasFlows: PropTypes.bool.isRequired,
  },

  render() {
    if (!this.props.components.count() && !this.props.legacyOrchestrations.count()) {
      return <p>No component found.</p>;
    }

    return (
      <div>
        {this.renderSection(
          this.getTypeLabel(componentTypes.EXTRACTOR),
          this.getComponentsForType(componentTypes.EXTRACTOR),
        )}
        {this.renderSection(
          this.getTypeLabel(componentTypes.TRANSFORMATION),
          this.getComponentsForType(componentTypes.TRANSFORMATION),
        )}
        {this.renderSection(
          this.getTypeLabel(componentTypes.WRITER),
          this.getComponentsForType(componentTypes.WRITER),
        )}
        {this.renderSection(
          this.getTypeLabel(componentTypes.APPLICATION),
          this.getComponentsForType(componentTypes.APPLICATION),
        )}
        {this.renderSection(
          this.props.hasFlows ? 'Flows' : 'Orchestrations',
          this.props.components.filter((component) => component.get('id') === KEBOOLA_ORCHESTRATOR),
        )}
        {this.props.legacyOrchestrations.count() > 0 &&
          this.renderSection(
            'Orchestrations',
            this.props.components.filter((component) => component.get('id') === 'orchestrator'),
          )}
      </div>
    );
  },

  getTypeLabel(type) {
    return `${getNewComponentTypeLabel(type)}s`;
  },

  renderSection(title, section) {
    if (!section || section.count() === 0) {
      return null;
    }

    return (
      <div>
        <h2>{title}</h2>
        <table className="table table-hover">
          <tbody>
            {section
              .map((component, index) => (
                <tr key={index}>
                  <td onClick={this.handleSelect.bind(this, component)} className="clickable">
                    <div className="flex-container">
                      <span>
                        <ComponentIcon
                          component={component}
                          className="icon-addon-right"
                          size="32"
                        />
                        <ComponentName component={component} />
                      </span>
                      <Icon icon="angle-right" pull="right" fixedWidth />
                    </div>
                  </td>
                </tr>
              ))
              .toArray()}
          </tbody>
        </table>
      </div>
    );
  },

  handleSelect(component) {
    return this.props.onComponentSelect(component);
  },

  getComponentsForType(type) {
    if (type === componentTypes.TRANSFORMATION) {
      return this.props.components.filter((component) => {
        return (
          component.get('type') === componentTypes.TRANSFORMATION ||
          component.get('id') === TRANSFORMATION
        );
      });
    }
    return this.props.components.filter((component) => component.get('type') === type);
  },
});

export default ComponentSelect;
