import type { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
};

export const Graph = ({ children }: Props) => {
  if (!children) return null;

  return (
    <div data-type="graph" className="flow-builder">
      {children}
    </div>
  );
};
