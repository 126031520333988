import { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { fromJS, List } from 'immutable';

import { Alert, Icon } from '@keboola/design';

import { searchFile } from '@/modules/ex-onedrive/action';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import SyncActionError from '@/utils/errors/SyncActionError';

const INITIAL_STATE = {
  showModal: false,
  isSearching: false,
  error: null,
  warning: null,
  files: List(),
  search: '',
};

class FileSearch extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <>
        <Button bsStyle="success" onClick={this.openModal} disabled={this.props.disabled}>
          <Icon icon="magnifying-glass" className="icon-addon-right" />
          Search file
        </Button>
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <form className="form-horizontal" onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Search Excel Files</Modal.Title>
              <ModalIcon icon="magnifying-glass" color="green" bold />
            </Modal.Header>
            <Modal.Body>
              {this.state.error && (
                <Alert variant="error" className="tw-mb-5">
                  {this.state.error}
                </Alert>
              )}
              {this.state.warning && (
                <Alert variant="warning" className="tw-mb-5">
                  {this.state.warning}
                </Alert>
              )}
              <FormGroup>
                <div className="col-xs-3">
                  <ControlLabel>Search</ControlLabel>
                </div>
                <div className="col-xs-9">
                  <FormControl
                    autoFocus
                    type="text"
                    disabled={this.state.isSearching}
                    value={this.state.search}
                    onChange={(e) => this.setState({ search: e.target.value })}
                  />
                </div>
              </FormGroup>
              {this.state.files.count() > 0 && (
                <div className="well m-0 overflow-auto">
                  <p className="text-muted f-14">Select file</p>
                  {this.state.files
                    .map((file) => (
                      <Button
                        block
                        bsStyle="link"
                        className="btn-link-inline"
                        key={file.get('fileId')}
                        onClick={() => {
                          this.props.onSelect({
                            name: file.get('name'),
                            driveId: file.get('driveId'),
                            fileId: file.get('fileId'),
                          });
                          this.closeModal();
                        }}
                      >
                        <Icon icon="file-excel" className="icon-addon-right text-muted" />
                        {file.get('path')} - {file.get('name')}
                      </Button>
                    ))
                    .toArray()}
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <ConfirmButtons
                block
                saveLabel="Search"
                saveButtonType="submit"
                isSaving={this.state.isSearching}
                isDisabled={!this.state.search.trim()}
              />
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ isSearching: true, warning: null, error: null });
    return searchFile(this.props.configId, this.state.search)
      .then((response) => {
        const files = fromJS(response.files);

        if (files.count() > 0) {
          this.setState({ files });
        } else {
          this.setState({ warning: 'No files were found for the given search.' });
        }
      })
      .catch(SyncActionError, (error) => {
        this.setState({ error: error.message });
      })
      .finally(() => {
        this.setState({ isSearching: false });
      });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState(INITIAL_STATE);
  }
}

FileSearch.propTypes = {
  disabled: PropTypes.bool.isRequired,
  configId: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default FileSearch;
