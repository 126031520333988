import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { KEBOOLA_LEGACY_TRANSFORMATION } from '@/constants/componentIds';
import ConfigurationCopiedNotification from '@/modules/components/react/components/ConfigurationCopiedNotification';

const restoreTransformationBucketNotification = (bucketId, bucketName) => {
  return createReactClass({
    propTypes: {
      onClick: PropTypes.func.isRequired,
    },
    render: function () {
      return (
        <ConfigurationCopiedNotification
          entity="Bucket"
          name={bucketName}
          action="restored"
          componentId={KEBOOLA_LEGACY_TRANSFORMATION}
          configId={bucketId}
          onClick={this.props.onClick}
          hasFlows={false}
        />
      );
    },
  });
};

export default restoreTransformationBucketNotification;
