import type { ComponentProps, FormEventHandler } from 'react';

import { Search } from './Search';

type Props = {
  onSubmit: (value: string) => void;
} & Omit<ComponentProps<typeof Search>, 'onClear' | 'onChange' | 'onBlur'>;

export const SearchForm = ({ onSubmit, name = 'search-field', ...props }: Props) => {
  const handleSumbmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const searchEl = event.currentTarget.elements.namedItem(name) as HTMLInputElement;
    onSubmit(searchEl.value);
  };

  const onClear = () => {
    onSubmit('');
  };

  return (
    <form onSubmit={handleSumbmit}>
      <Search
        {...props}
        onClear={onClear}
        name={name}
        // Debounce has no meaning when we use submit, which is triggered by pressing enter.
        disableDebounce
      />
    </form>
  );
};
