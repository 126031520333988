import createReactClass from 'create-react-class';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import TokensActions from '@/modules/tokens/actionCreators';
import TokensTable from '@/modules/tokens/react/pages/Index/TokensTable';
import TokensStore from '@/modules/tokens/StorageTokensStore';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import SettingsTabs from './components/SettingsTabs';
import { routeNames } from './constants';

const Tokens = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, TokensStore)],

  getStateFromStores() {
    return {
      tokens: TokensStore.getAll(),
      isDeletingTokenFn: TokensStore.isDeletingToken,
      isSendingToken: TokensStore.isSendingToken,
      isRefreshingTokenFn: TokensStore.isRefreshingToken,
      sapiToken: ApplicationStore.getSapiToken(),
      hasNewQueue: ApplicationStore.hasNewQueue(),
    };
  },

  render() {
    return (
      <SettingsTabs activeTab={routeNames.TOKENS}>
        <TokensTable
          isDeletingFn={(t) => this.state.isDeletingTokenFn(t.get('id'))}
          onDeleteFn={TokensActions.deleteToken}
          onSendTokenFn={this.sendToken}
          isSendingTokenFn={this.state.isSendingToken}
          onRefreshFn={TokensActions.refreshToken}
          isRefreshingFn={(t) => this.state.isRefreshingTokenFn(t.get('id'))}
          sapiToken={this.state.sapiToken}
          tokens={this.state.tokens}
          hasNewQueue={this.state.hasNewQueue}
        />
      </SettingsTabs>
    );
  },

  sendToken(token, params) {
    return TokensActions.sendToken(token.get('id'), params).then(() =>
      ApplicationActionCreators.sendNotification({
        type: 'success',
        message: () => {
          return (
            <>
              Token <b>{token.get('description')}</b> sent to <b>{params.email}</b>.
            </>
          );
        },
      }),
    );
  },
});

export default Tokens;
