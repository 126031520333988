import type { ReactNode } from 'react';

import { cn } from '@keboola/design';

import { RouterLink } from './RouterLink';

export const TabLink = (props: {
  to: string;
  children: ReactNode;
  active?: boolean;
  params?: Record<string, any>;
  query?: Record<string, any>;
  hash?: string;
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <li
      role="presentation"
      className={cn(props.className, { active: props.active, disabled: props.disabled })}
    >
      <RouterLink
        role="tab"
        to={props.to}
        params={props.params}
        query={props.query}
        hash={props.hash}
        disabled={props.disabled}
      >
        {props.children}
      </RouterLink>
    </li>
  );
};
