import { KeboolaHttpHeader } from '../../constants';
import {
  createCallbackMiddleware,
  createOpenapiFetchClient,
  type OpenapiQueryType,
} from '../../fetchClient';
import type { ClientInitOptions } from '../types';

import type { paths } from './__generated__/schema';

type GetVariablesQuery = OpenapiQueryType<paths['/variables']['get']>;

export const createVaultClient = ({ baseUrl, token, callbacks }: ClientInitOptions) => {
  const client = createOpenapiFetchClient<paths>({
    baseUrl: baseUrl,
    headers: new Headers({ [KeboolaHttpHeader.STORAGE_API_TOKEN]: token }),
    middlewares: [createCallbackMiddleware(callbacks)],
  });

  const getVariables = async (query: GetVariablesQuery, signal?: AbortSignal) => {
    const { data } = await client.get(
      '/variables',
      { query },
      {
        signal,
      },
    );

    return data;
  };

  return {
    getVariables,
  };
};
