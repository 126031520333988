import { Component } from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormGroup } from 'react-bootstrap';

import { HelpBlock } from '@keboola/design';

import Select from '@/react/common/Select';
import Schema from './Configuration/Schema';
import User from './Configuration/User';

class Configuration extends Component {
  render() {
    const { onChange, value } = this.props;
    return (
      <>
        <div className="box">
          <div className="box-header with-border big-padding">
            <h3 className="box-title">Entity</h3>
          </div>
          <div className="box-content">
            <FormGroup>
              <div className="col-xs-4">
                <ControlLabel>Type</ControlLabel>
              </div>
              <div className="col-xs-8">
                <Select
                  clearable={false}
                  value={value.type}
                  onChange={(value) => onChange({ type: value })}
                  options={[
                    { value: 'schema', label: 'Schema' },
                    { value: 'user', label: 'User' },
                  ]}
                  disabled={this.props.disabled}
                />
                <HelpBlock className="tw-mt-1">
                  {value.type === 'user' ? (
                    <p>
                      User entity gets its own write access schema as well as read-only access to
                      specified schemas (created by schema entity). Snowflake user credentials will
                      be displayed in the job log and the password must be changed after the first
                      login.
                    </p>
                  ) : (
                    <p>
                      Schema entity will generate a Snowflake schema and a Snowflake user with write
                      access to the schema. This schema can be shared with user entities. Snowflake
                      user credentials will be displayed in the job log and the password must be
                      changed after the first login.
                    </p>
                  )}
                </HelpBlock>
              </div>
            </FormGroup>
          </div>
        </div>
        {value.type === 'schema' && (
          <Schema
            schema_name={value.schema_name}
            reset_password={value.reset_password}
            onChange={this.props.onChange}
            disabled={this.props.disabled}
          />
        )}
        {value.type === 'user' && (
          <User value={value} onChange={this.props.onChange} disabled={this.props.disabled} />
        )}
      </>
    );
  }
}

Configuration.propTypes = {
  value: PropTypes.shape({
    type: PropTypes.string.isRequired,
    email: PropTypes.string,
    schemas_read: PropTypes.array,
    schemas_write: PropTypes.array,
    business_schemas: PropTypes.array,
    disabled: PropTypes.bool,
    reset_password: PropTypes.bool,
    schema_name: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Configuration;
