import { useState } from 'react';
import type { Map } from 'immutable';

import { IconButton, Tooltip } from '@keboola/design';

import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { removeTablePrimaryKey } from '@/modules/storage/actions';
import ConfirmModal from '@/react/common/ConfirmModal';
import ChangePrimaryKeyWarning from './ChangePrimaryKeyWarning';
import ConfigurationUsage from './ConfigurationUsage';
import DevBranchStorageWarning from './DevBranchStorageWarning';

const DeletePrimaryKey = (props: { table: Map<string, any> }) => {
  const [showModal, setShowModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleSubmit = () => {
    setIsDeleting(true);
    return removeTablePrimaryKey(props.table.get('id')).finally(() => setIsDeleting(false));
  };

  return (
    <>
      <Tooltip tooltip="Delete primary key" placement="top">
        <IconButton
          className="tw-inline-flex tw-h-max"
          variant="inline"
          onClick={() => setShowModal(true)}
          disabled={isDeleting}
          icon="trash"
          isLoading={isDeleting}
        />
      </Tooltip>
      <ConfirmModal
        show={showModal}
        icon="trash"
        title="Delete Primary Key"
        buttonType="danger"
        buttonLabel="Remove"
        text={
          <>
            <p>Are you sure you want to delete the table primary key?</p>
            <ChangePrimaryKeyWarning action="delete" />
            <DevBranchStorageWarning
              message="The primary key will also be deleted from the table in production."
              hasProductionEntity={!isCreatedInDevBranch(props.table.get('bucket'))}
            />
            <ConfigurationUsage tableId={props.table.get('id')} />
          </>
        }
        onConfirm={handleSubmit}
        onHide={() => setShowModal(false)}
      />
    </>
  );
};

export default DeletePrimaryKey;
