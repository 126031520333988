import type { Map } from 'immutable';

import { sortEntities } from '@/constants';
import { SortIcon } from '@/react/common';
import type { SetSortType } from '@/types/types';

type Props = {
  sort: Map<string, any>;
  setSort: SetSortType;
};

export const LastChangeColumn = ({ sort, setSort }: Props) => {
  const { entity, order } = sort.toJS();
  const isSorted = entity === sortEntities.LAST_CHANGE;

  const handleSort = () => {
    setSort({
      entity: sortEntities.LAST_CHANGE,
      order: isSorted ? order * -1 : 1,
    });
  };

  return (
    <span className="clickable" title="Sort by last change" onClick={handleSort}>
      <SortIcon isSorted={isSorted} isSortedDesc={order === -1} />
      Last change
    </span>
  );
};
