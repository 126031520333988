import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import QueryEditor from '@/modules/ex-mongodb/react/components/QueryEditor';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import QueryNav from './QueryNav';

const QueryDetail = (componentId, actionsProvisioning, storeProvisioning) => {
  const ExDbActionCreators = actionsProvisioning.createActions(componentId);

  return createReactClass({
    mixins: [createStoreMixin(ApplicationStore, InstalledComponentsStore, StorageTablesStore)],

    UNSAFE_componentWillReceiveProps() {
      return this.setState(this.getStateFromStores());
    },

    getStateFromStores() {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const queryId = RoutesStore.getCurrentRouteParam('query');
      const ExDbStore = storeProvisioning.createStore(componentId, configId);
      const query = ExDbStore.getConfigQuery(queryId);

      return {
        configId,
        query,
        configState: ExDbStore.configState,
        editingQueries: ExDbStore.getEditingQueries(),
        newQueries: ExDbStore.getNewQueries(),
        newQueriesIdsList: ExDbStore.getNewQueriesIdsList(),
        queriesFilter: ExDbStore.getQueriesFilter(),
        queriesFiltered: ExDbStore.getQueriesFiltered(),
        outTableExist: ExDbStore.outTableExist(query),
        readOnly: ApplicationStore.isReadOnly(),
      };
    },

    _handleQueryChange(newQuery) {
      return ExDbActionCreators.updateEditingQuery(this.state.configId, newQuery);
    },

    _handleResetState() {
      const queryId = this.state.query.get('id').toString();
      return ExDbActionCreators.resetQueryState(this.state.configId, queryId);
    },

    render() {
      return (
        <div className="row">
          <div className="col-xs-3">
            <QueryNav
              readOnly={this.state.readOnly}
              actionCreators={ExDbActionCreators}
              componentId={componentId}
              configurationId={this.state.configId}
              editingQueries={this.state.editingQueries || List()}
              filter={this.state.queriesFilter}
              navQuery={this.state.query || Map()}
              newQueries={this.state.newQueries || List()}
              newQueriesIdsList={this.state.newQueriesIdsList}
              queries={this.state.queriesFiltered}
            />
          </div>
          <div className="col-xs-9">{this.renderEditor()}</div>
        </div>
      );
    },

    renderEditor() {
      if (!this.state.query) {
        return (
          <div className="box">
            <div className="box-content">
              Export not found. Please select an existing export or create a new one.
            </div>
          </div>
        );
      }

      return (
        <QueryEditor
          readOnly={this.state.readOnly}
          query={this.state.query}
          onChange={this._handleQueryChange}
          configId={this.state.configId}
          componentId={componentId}
          outTableExist={this.state.outTableExist}
          configState={this.state.configState}
          onResetState={this._handleResetState}
        />
      );
    },
  });
};

export default QueryDetail;
