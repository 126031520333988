import { memo } from 'react';
import type { List, Map } from 'immutable';
import _ from 'underscore';

import OneTimeNotificationButton from '@/modules/notifications/components/OneTimeNotificationButton';
import { RowActionDropdown, RowActionMenuItem } from '@/react/common';
import DeleteConfigurationButton from '@/react/common/DeleteConfigurationButton';
import CopyButton from './CopyButton';
import DescriptionButton from './DescriptionButton';
import MoveActionButton from './MoveActionButton';
import RunButton from './RunButton';

type Props = {
  children: JSX.Element;
  flows: Map<string, any>;
  configuration: Map<string, any>;
  component: Map<string, any>;
  currentAdmin: Map<string, any>;
  renderAdditionalActions: (
    configuration: Map<string, any>,
    commonProps: Omit<Props, 'renderAdditionalActions'>,
  ) => JSX.Element;
  hasFlows: boolean;
  hasNewQueue: boolean;
  readOnly: boolean;
  onMove: () => void;
  latestJob?: Map<string, any>;
} & ({ notifications: List<any>; hideCommonActions?: boolean } | { hideCommonActions: true });

const ConfigurationActions = (props: Props) => {
  if (props.readOnly) {
    return props.children;
  }

  const additionalActions = props.renderAdditionalActions(
    props.configuration,
    _.omit(props, 'renderAdditionalActions'),
  );

  if (!additionalActions && props.hideCommonActions) {
    return props.children;
  }

  return (
    <div className="actions-container">
      <div className="not-actions">{props.children}</div>
      <div className="actions">
        <RowActionDropdown>
          {props.hideCommonActions ? (
            additionalActions
          ) : (
            <>
              <RunButton
                configuration={props.configuration}
                component={props.component}
                hasFlows={props.hasFlows}
              />
              <RowActionMenuItem divider />
              {props.hasNewQueue && (
                <OneTimeNotificationButton
                  menuitem
                  job={props.latestJob}
                  admin={props.currentAdmin}
                  notifications={props.notifications}
                />
              )}
              {additionalActions}
              <CopyButton
                configuration={props.configuration}
                component={props.component}
                hasFlows={props.hasFlows}
              />
              <DescriptionButton configuration={props.configuration} component={props.component} />
              {!!props.onMove && (
                <MoveActionButton
                  mode="menuitem"
                  onClick={props.onMove}
                  component={props.component}
                  hasFlows={props.hasFlows}
                />
              )}
              <RowActionMenuItem divider />
              <DeleteConfigurationButton
                mode="menuitem"
                flows={props.flows}
                componentId={props.component.get('id')}
                config={props.configuration}
              />
            </>
          )}
        </RowActionDropdown>
      </div>
    </div>
  );
};

const MemoizedConfigurationActions = memo(ConfigurationActions);

export default MemoizedConfigurationActions;
