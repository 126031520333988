import { useState } from 'react';
import type { KeyboardEvent } from 'react';
import type { Map } from 'immutable';

import { Icon } from '@keboola/design';

import { KEBOOLA_ORCHESTRATOR, KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ConfigurationRowsActionCreators from '@/modules/configurations/ConfigurationRowsActionCreators';
import { RowActionMenuItem } from '@/react/common';
import DescriptionModal from '@/react/common/DescriptionModal';

const DescriptionButton = (props: {
  component: Map<string, any>;
  configuration: Map<string, any>;
  row?: Map<string, any>;
  onKeyDown?: (e: KeyboardEvent) => void;
}) => {
  const [show, setShow] = useState(false);

  const isFilled = props.row
    ? !!props.row.get('description')
    : !!props.configuration.get('description');

  const getEntity = () => {
    switch (props.component.get('id')) {
      case KEBOOLA_ORCHESTRATOR:
        return 'Flow';

      case KEBOOLA_SANDBOXES:
        return 'Workspace';
    }

    return getNewComponentTypeLabel(props.component.get('type'));
  };

  return (
    <>
      <RowActionMenuItem onSelect={() => setShow(true)} onKeyDown={props.onKeyDown}>
        <Icon fixedWidth icon={isFilled ? 'note-sticky' : 'note-sticky'} />
        {isFilled ? 'Show' : 'Add'} description
      </RowActionMenuItem>
      <DescriptionModal
        show={show}
        onHide={() => setShow(false)}
        onSave={(description) => {
          if (props.row) {
            return ConfigurationRowsActionCreators.updateSimple(
              props.component.get('id'),
              props.configuration.get('id'),
              props.row.get('id'),
              { description },
              'Update row description',
            );
          }

          return InstalledComponentsActionCreators.updateComponentConfiguration(
            props.component.get('id'),
            props.configuration.get('id'),
            { description },
            'Update description',
            'description',
          );
        }}
        entity={getEntity()}
        description={
          props.row ? props.row.get('description') : props.configuration.get('description')
        }
        configId={props.configuration.get('id')}
        componentId={props.component.get('id')}
        rowId={props.row?.get('id')}
      />
    </>
  );
};

export default DescriptionButton;
