import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { SortIcon } from '@/react/common';
import MappingsMultiActionsHeader, {
  MappingsHeader,
} from '@/react/common/MappingsMultiActionsHeader';
import CollapsibleMapping from './CollapsibleMapping/CollapsibleMapping';
import { getMappingCollapsion, setMappingCollapsion } from './CollapsibleMapping/helpers';
import FileOutputMappingModal from './FileOutputMappingModal';
import FileOutputMappingRow from './FileOutputMappingRow';
import {
  prepareConfigDataWithDeletedMapping,
  prepareConfigDataWithEditedMapping,
  saveMapping,
} from './helpers';

const FILE_OUTPUT_MAPPING_KEY = 'file-output';

const FileOutputMapping = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    onDeleteMappings: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    rowId: PropTypes.string,
  },

  getInitialState() {
    return {
      selectedMappings: Map(),
      sortBy: null,
      sortDesc: false,
      isCollapsed: this.props.readOnly
        ? false
        : getMappingCollapsion(
            this.props.componentId,
            this.props.configId,
            this.props.rowId,
            FILE_OUTPUT_MAPPING_KEY,
          ),
    };
  },

  setCollapsed() {
    setMappingCollapsion(
      this.props.componentId,
      this.props.configId,
      this.props.rowId,
      FILE_OUTPUT_MAPPING_KEY,
      !this.state.isCollapsed,
    );
    this.setState({ isCollapsed: !this.state.isCollapsed });
  },

  renderActions() {
    return (
      !this.props.readOnly && (
        <FileOutputMappingModal
          onSave={(editing) => this.onSaveMapping('new-mapping', editing)}
          definedSources={this.props.value.map((item) => item.get('source'))}
        />
      )
    );
  },

  render() {
    return (
      <CollapsibleMapping
        title={
          <MappingsHeader type="output" componentId={this.props.componentId} storage="files" />
        }
        entity="mapping"
        isEmpty={this.props.value.isEmpty()}
        actions={this.renderActions()}
        isCollapsed={this.state.isCollapsed}
        onToggleCollapse={this.setCollapsed}
        header={
          <>
            <MappingsMultiActionsHeader
              hide={this.props.readOnly}
              disabled={this.props.value.isEmpty()}
              componentId={this.props.componentId}
              configurationId={this.props.configId}
              rowId={this.props.rowId}
              type="output"
              storage="files"
              allMappings={this.props.value}
              selectedMappings={this.state.selectedMappings}
              updateMappingsSelection={(selectedMappings) =>
                this.setState(() => ({ selectedMappings }))
              }
              deleteMappings={this.props.onDeleteMappings}
              isSorted={this.state.sortBy === 'source'}
              isSortedDesc={this.state.sortDesc}
              onClick={() => this.handleChangeSort('source')}
            />
            <div
              className="tw-cursor-pointer tw-font-medium"
              onClick={() => this.handleChangeSort('tags')}
            >
              Assigned Tags
              <SortIcon
                className="tw-ml-2"
                isSorted={this.state.sortBy === 'tags'}
                isSortedDesc={this.state.sortDesc}
              />
            </div>
          </>
        }
      >
        {this.renderContent()}
      </CollapsibleMapping>
    );
  },

  renderContent() {
    if (this.props.value.isEmpty()) {
      return null;
    }

    return (
      <div className="box-content p-0">
        <div className="table table-hover overflow-break-anywhere">
          <div className="tbody">
            {this.props.value
              .map((output, index) => [output, index])
              .sort(this.handleSort)
              .map(([output, index]) => this.renderRow(output, index))
              .toArray()}
          </div>
        </div>
        <p className="mb-0 pl-2 pr-2 pb-1 help-block">
          All files from <code>out/files/</code> will be transferred. This allows you to configure
          the details or override the manifests.
        </p>
      </div>
    );
  },

  renderRow(output, key) {
    return (
      <FileOutputMappingRow
        key={key}
        value={output}
        readOnly={this.props.readOnly}
        onSave={(editing) => this.onSaveMapping(key, editing)}
        onDelete={() =>
          this.onDeleteMapping(key).then(() => this.setState({ selectedMappings: Map() }))
        }
        definedSources={this.props.value
          .filter((row) => row.get('source') !== output.get('source'))
          .map((item) => item.get('source'))}
        isSelected={!!this.state.selectedMappings.get(key)}
        toggleSelection={(checked) =>
          this.setState({ selectedMappings: this.state.selectedMappings.set(key, checked) })
        }
      />
    );
  },

  handleChangeSort(type) {
    if (type !== this.state.sortBy) {
      return this.setState({
        sortBy: type,
        sortDesc: false,
      });
    }

    this.setState({
      sortDesc: !this.state.sortDesc,
    });
  },

  handleSort([outputA], [outputB]) {
    if (!this.state.sortBy) {
      return 0;
    }

    let nameA = '';
    let nameB = '';

    if (this.state.sortBy === 'source') {
      nameA = outputA.get('source', '');
      nameB = outputB.get('source', '');
    }

    if (this.state.sortBy === 'tags') {
      nameA = outputA.getIn(['tags', 0], '');
      nameB = outputB.getIn(['tags', 0], '');
    }

    const sort = this.state.sortDesc ? -1 : 1;

    return nameA?.localeCompare(nameB) * sort;
  },

  setCollapsedAfterSave() {
    if (this.state.isCollapsed) {
      setMappingCollapsion(
        this.props.componentId,
        this.props.configId,
        this.props.rowId,
        FILE_OUTPUT_MAPPING_KEY,
      );
      this.setState({ isCollapsed: false });
    }
  },

  onSaveMapping(key, editing) {
    const configData = prepareConfigDataWithEditedMapping(
      this.props.componentId,
      this.props.configId,
      this.props.rowId,
      'output',
      'files',
      key,
      editing,
    );

    return this.handleSave(
      configData,
      `${key === 'new-mapping' ? 'Add' : 'Update'} file output`,
    ).then(() => this.setCollapsedAfterSave());
  },

  onDeleteMapping(key) {
    const configData = prepareConfigDataWithDeletedMapping(
      this.props.componentId,
      this.props.configId,
      this.props.rowId,
      'output',
      'files',
      key,
    );

    return this.handleSave(configData, 'Delete file output');
  },

  handleSave(configData, changeDescription) {
    return saveMapping(
      this.props.componentId,
      this.props.configId,
      this.props.rowId,
      configData,
      changeDescription,
    );
  },
});

export default FileOutputMapping;
