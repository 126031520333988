import type { List, Map } from 'immutable';

import { FormGroup, Label } from '@keboola/design';

import Select from '@/react/common/Select';

type Props = {
  task: Map<string, any>;
  readOnly: boolean;
  onEditTask: (id: string, field: string | string[], value: any) => void;
};

const SelectSpecificRows = ({ task, readOnly, onEditTask }: Props) => {
  const canSelectRows = !task.get('availableRows').isEmpty();

  if (!canSelectRows) {
    return null;
  }

  return (
    <FormGroup>
      <Label htmlFor="select-rows-for-task" className="flex-container font-medium">
        Run rows
      </Label>
      <Select
        multi
        hideSelectAllOptions
        disabled={readOnly}
        id="select-rows-for-task"
        placeholder="All rows"
        value={task.get('specificRows')}
        options={task.get('availableRows')}
        onChange={(rows: List<string>) => {
          onEditTask(task.get('id'), ['task', 'configRowIds'], rows);
        }}
      />
    </FormGroup>
  );
};

export default SelectSpecificRows;
