import type { ReactNode } from 'react';
import type { Map } from 'immutable';

import ComponentIcon from '@/react/common/ComponentIcon';

type Props = {
  component: Map<string, any>;
  onClick: () => void;
  children: ReactNode;
};

const Row = ({ onClick, component, children }: Props) => {
  return (
    <button
      className="tw-block tw-h-[46px] tw-w-full tw-rounded tw-border-0 tw-bg-transparent tw-px-2 tw-py-1 hover:tw-bg-neutral-50"
      onClick={onClick}
    >
      <div className="flex-container flex-start tw-gap-3 tw-text-left">
        <ComponentIcon component={component} size="32" />
        {children}
      </div>
    </button>
  );
};

export default Row;
