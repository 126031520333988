import { useRadioGroupContext } from './context';
import { RadioGroupItemBlock } from './RadioGroupItemBlock';
import { RadioGroupItemButton } from './RadioGroupItemButton';
import { RadioGroupItemRadio } from './RadioGroupItemRadio';
import type { RadioGroupItemProps } from './types';

export const RadioGroupItem = ({ children, ...props }: RadioGroupItemProps) => {
  const { variant } = useRadioGroupContext();

  const Item = {
    radio: RadioGroupItemRadio,
    button: RadioGroupItemButton,
    'radio-block': RadioGroupItemBlock,
  }[variant];

  return <Item {...props}>{children}</Item>;
};
