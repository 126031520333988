import createReactClass from 'create-react-class';
import { List } from 'immutable';

import OrchestrationActionCreators from '@/modules/orchestrations/ActionCreators';
import OrchestrationStore from '@/modules/orchestrations/stores/OrchestrationsStore';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import Notifications from './Notifications';

const OrchestrationNotifications = createReactClass({
  mixins: [createStoreMixin(OrchestrationStore)],

  getStateFromStores() {
    const orchestrationId = RoutesStore.getCurrentRouteIntParam('orchestrationId');
    const orchestration = OrchestrationStore.get(orchestrationId);
    const isEditing = OrchestrationStore.isEditing(orchestrationId, 'notifications');
    const editingValues = OrchestrationStore.getEditingValue(orchestrationId, 'notifications');

    return {
      orchestrationId,
      orchestration,
      notifications: isEditing ? editingValues : orchestration.get('notifications'),
      isEditing,
      isSaving: OrchestrationStore.isSaving(orchestrationId, 'notifications'),
      readOnly: ApplicationStore.isReadOnly(),
      admins: ApplicationStore.getAdmins(),
    };
  },

  render() {
    return (
      <div className="box">
        <div className="box-content">
          <Notifications
            readOnly={this.state.readOnly}
            notifications={this.state.notifications}
            emailOptions={this.emailOptions()}
            isEditing={this.state.isEditing}
            isSaving={this.state.isSaving}
            onNotificationsChange={this.handleNotificationsChange}
            onSave={this.handleSave}
            onCancel={this.handleCancel}
          />
        </div>
      </div>
    );
  },

  emailOptions() {
    const fromNotification = this.state.notifications
      .reduce((emails, item) => emails.push(item.get('email')), List())
      .toSet();

    return this.state.admins
      .map((admin) => admin.get('email'))
      .toSet()
      .concat(fromNotification)
      .map((email) => ({
        value: email,
        label: email,
      }))
      .toArray();
  },

  handleNotificationsChange(newNotifications) {
    OrchestrationActionCreators.updateOrchestrationNotificationsEdit(
      this.state.orchestrationId,
      newNotifications,
    );
  },

  handleSave(e) {
    e.preventDefault();
    OrchestrationActionCreators.saveOrchestrationNotificationsEdit(this.state.orchestrationId);
  },

  handleCancel() {
    OrchestrationActionCreators.cancelOrchestrationNotificationsEdit(this.state.orchestrationId);
  },
});

export default OrchestrationNotifications;
