import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import _ from 'underscore';

import { routeNames as jobsRouteNames } from '@/modules/jobs/Constants';
import { routeNames as queueRouteNames } from '@/modules/queue/constants';
import { RouterLink as Link } from '@/react/common';
import ComponentName from '@/react/common/ComponentName';

const jobScheduled = (component, job) => {
  return createReactClass({
    propTypes: {
      onClick: PropTypes.func.isRequired,
    },

    render() {
      return (
        <>
          <Link
            to={_.has(job, 'config') ? queueRouteNames.JOB_DETAIL : jobsRouteNames.DETAIL}
            params={{ jobId: job.id }}
            onClick={this.props.onClick}
          >
            {component ? (
              <>
                <ComponentName component={component} capitalize>
                  {(name) => name}
                </ComponentName>{' '}
                job
              </>
            ) : (
              'Job'
            )}
          </Link>{' '}
          has been scheduled.
        </>
      );
    },
  });
};

export default jobScheduled;
