import { useEffect, useState } from 'react';
import type { ChangeEvent } from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { Map } from 'immutable';

import { Link } from '@keboola/design';

import { KEBOOLA_EX_FACEBOOK_ADS } from '@/constants/componentIds';
import { QUERY_TYPE } from '@/modules/ex-facebook/constants';
import Checkbox from '@/react/common/Checkbox';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import InfoTooltip from '@/react/common/InfoTooltip';
import Select from '@/react/common/Select';

const ASYNC_QUERY_HELP =
  "The Async Insights Query option specifies the way the connector requests data. If checked, data will be extracted asynchronously; the connector will make an initial request, then by polling the FB api waits for the result and, once ready, extracts it. This allows for more data to be extracted, especially when the synchronous extraction ends with the error: Please reduce the amount of data you're asking for. Note that only data of the ads objects (Ad Account, Ad Campaign, Ad Set, and Ad) can be extracted asynchronously.";

const ENDPOINT_HELP =
  'The URL part of the Facebook Graph API request specifying node-id and/or edge-name, e.g., feed, me/photos, etc. It can be empty.';

const FIELDS_HELP =
  'The parameter of the Facebook Graph API nested request specifying fields and/or additional endpoint parameters.';

const PARAMETERS_HELP = 'The parameters of the ads insights specified in URL query string format.';

const TEMPLATE_HELP = 'Not sure how to set the query? Use our predefined templates.';

const SPLIT_BY_DAYS_HELP =
  'Applicable only if query contains date_preset or time_ranges. Extractor will break query and run by day using the time_ranges parameter. Useful if Facebook fails to return data or takes infinite time to return it.';

type Props = {
  componentId: string;
  config: Map<string, any>;
  templates: Map<string, any>;
  readOnly: boolean;
  isAsync: boolean;
  documentationUrl: string;
  resetTemplate: boolean;
  getAccountDesc: (type: string) => string;
  updateLocalState: (path: string[], newValue: Map<string, any> | boolean | string) => void;
};

const Report = ({
  componentId,
  config,
  templates,
  readOnly,
  isAsync,
  resetTemplate,
  documentationUrl,
  updateLocalState,
}: Props) => {
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>();

  useEffect(() => {
    if (resetTemplate) {
      setSelectedTemplate(null);
    }
  }, [resetTemplate]);

  let templatesOptions = templates;
  let templatesOptionsAsync = Map<string, any>();

  if (componentId === KEBOOLA_EX_FACEBOOK_ADS) {
    templatesOptions = templates.get(QUERY_TYPE.NESTED);
    templatesOptionsAsync = templates.get(QUERY_TYPE.ASYNC_INSIGHTS);
  }

  const handleAsync = (isAsync: boolean) => {
    if (isAsync) {
      return config
        .set('type', QUERY_TYPE.ASYNC_INSIGHTS)
        .setIn(['query', 'parameters'], '')
        .setIn(['query', 'split-query-time-range-by-day'], false)
        .deleteIn(['query', 'fields'])
        .deleteIn(['query', 'path']);
    } else {
      return config
        .set('type', QUERY_TYPE.NESTED)
        .setIn(['query', 'path'], '')
        .setIn(['query', 'fields'], '')
        .deleteIn(['query', 'parameters'])
        .deleteIn(['query', 'split-query-time-range-by-day']);
    }
  };

  const handleSelect = (id: string) => {
    const optionValue = templatesOptions
      .concat(templatesOptionsAsync)
      .findLast((t: Map<string, any>) => t?.get('id') === id);
    const isAsync = templatesOptionsAsync.includes(optionValue);

    const templateQuery = optionValue.get('template');
    const newConfig = handleAsync(isAsync).mergeDeep(templateQuery);

    updateLocalState([], newConfig);
    setSelectedTemplate(id);
  };

  const getSelectOptions = (templates: Map<string, any>) => {
    return templates
      .map((t: Map<string, any>) => ({
        label: t?.get('name'),
        value: t?.get('id'),
      }))
      .toArray();
  };

  return (
    <CollapsibleBox title="Report" defaultOpen>
      <FormGroup>
        <ControlLabel>
          Template
          <InfoTooltip tooltip={TEMPLATE_HELP} />
        </ControlLabel>
        <Select
          value={selectedTemplate || ''}
          placeholder="Select Template"
          options={[
            {
              label: 'Objects',
              options: getSelectOptions(templatesOptions),
            },
            templatesOptionsAsync.size > 0 && {
              label: 'Async Insights',
              options: getSelectOptions(templatesOptionsAsync),
            },
          ].filter(Boolean)}
          clearable={false}
          disabled={readOnly}
          onChange={handleSelect}
        />
      </FormGroup>
      <hr className="tw-mb-6 tw-mt-4" />
      {componentId === KEBOOLA_EX_FACEBOOK_ADS && (
        <FormGroup>
          <Checkbox
            disabled={readOnly}
            checked={isAsync}
            onChange={(checked) => {
              setSelectedTemplate(null);
              updateLocalState([], handleAsync(checked));
            }}
          >
            Async Insights Query
            <InfoTooltip tooltip={ASYNC_QUERY_HELP} />
          </Checkbox>
        </FormGroup>
      )}
      {isAsync ? (
        <FormGroup>
          <Checkbox
            disabled={readOnly}
            checked={config.getIn(['query', 'split-query-time-range-by-day'], false)}
            onChange={(checked) => {
              updateLocalState(['query', 'split-query-time-range-by-day'], checked);
            }}
          >
            Split and run query by days
            <InfoTooltip tooltip={SPLIT_BY_DAYS_HELP} />
          </Checkbox>
        </FormGroup>
      ) : (
        <FormGroup>
          <ControlLabel>
            Endpoint
            <InfoTooltip
              tooltip={
                <>
                  {ENDPOINT_HELP} <Link href={`${documentationUrl}/#endpoint`}>documentation.</Link>
                </>
              }
            />
          </ControlLabel>
          <FormControl
            value={config.getIn(['query', 'path']) || ''}
            disabled={readOnly}
            placeholder="e.g ads or empty string"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              updateLocalState(['query', 'path'], e.target.value);
            }}
          />
        </FormGroup>
      )}
      <FormGroup>
        <ControlLabel>
          {isAsync ? 'Parameters' : 'Fields'}
          <InfoTooltip
            tooltip={
              <>
                {isAsync ? PARAMETERS_HELP : FIELDS_HELP}{' '}
                <Link href={`${documentationUrl}`}>documentation.</Link>
              </>
            }
          />
        </ControlLabel>
        <FormControl
          key={config.get('type')}
          disabled={readOnly}
          componentClass="textarea"
          value={config.getIn(['query', isAsync ? 'parameters' : 'fields']) || ''}
          placeholder={isAsync ? 'campaign_name,actions' : 'id,name,adset_id'}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            updateLocalState(['query', isAsync ? 'parameters' : 'fields'], e.target.value);
          }}
        />
      </FormGroup>
    </CollapsibleBox>
  );
};

export default Report;
