import { Component } from 'react';

import { Alert, Icon } from '@keboola/design';

import Confirm from '@/react/common/Confirm';

type Props = {
  hasPassword: boolean;
  forgetPasswordFn: () => void;
  resetPasswordFn: () => Promise<void>;
  isResetting?: boolean;
};

class OnetimePasswordWarning extends Component<Props> {
  componentWillUnmount() {
    if (this.props.hasPassword) {
      setTimeout(this.props.forgetPasswordFn);
    }
  }

  render() {
    if (this.props.hasPassword) {
      return (
        <Alert variant="warning" className="tw-mt-3.5">
          Make sure to copy the password. You won&apos;t be able to see it again.
        </Alert>
      );
    }

    return (
      <p className="mt-1 mb-0">
        <Icon icon="circle-exclamation" className="icon-addon-right" />
        Password was shown when you created the credentials.
        {this.props.resetPasswordFn && (
          <Confirm
            title="Reset Password"
            text="Are you sure you want to reset the password?"
            buttonLabel="Reset"
            onConfirm={this.props.resetPasswordFn}
            isLoading={this.props.isResetting ?? false}
            closeAfterResolve
          >
            {' '}
            To reset password <button className="btn btn-link btn-link-inline">click here</button>.
          </Confirm>
        )}
      </p>
    );
  }
}

export default OnetimePasswordWarning;
