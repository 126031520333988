import { createElement } from 'react';
import type { Map } from 'immutable';
import { List } from 'immutable';

import { Badge, Tooltip } from '@keboola/design';

import { HasOverridesLabel } from '@/modules/components/react/pages/GenericComponentRaw';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import ConfigurationDisabledLabel from '@/react/common/ConfigurationDisabledLabel';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const Labels = () => {
  const store = useStores(
    () => {
      const componentId = RoutesStore.getCurrentRouteComponentId();
      const configId = RoutesStore.getCurrentRouteParam('config');
      const rowId = RoutesStore.getCurrentRouteParam('row');

      return {
        componentId,
        config: rowId
          ? ConfigurationRowsStore.get(componentId, configId, rowId)
          : InstalledComponentsStore.getConfig(componentId, configId),
        component: ComponentsStore.getComponent(componentId),
        hasOverrides: ComponentsStore.hasOverrides(componentId),
        routeConfig: RoutesStore.getCurrentRouteConfig(),
        hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
      };
    },
    [],
    [
      RoutesStore,
      ApplicationStore,
      ConfigurationRowsStore,
      InstalledComponentsStore,
      ComponentsStore,
    ],
  );

  if (store.routeConfig.has('labelHandler')) {
    return createElement(store.routeConfig.get('labelHandler'));
  }

  if (store.component && store.config) {
    return (
      <>
        {store.hasOverrides && <HasOverridesLabel componentId={store.component.get('id')} />}
        {store.hasProtectedDefaultBranch && store.component.getIn(['data', 'forward_token']) && (
          <Badge text="Forward Token" variant="teal" placement="right" />
        )}
        {store.config
          .getIn(['configuration', 'processors', 'before'], List())
          .concat(store.config.getIn(['configuration', 'processors', 'after'], List()))
          .some((processor: Map<string, any>) => processor.hasIn(['definition', 'tag'])) && (
          <Tooltip
            type="explanatory"
            placement="bottom"
            tooltip="At least one processor used in the configuration has a specific tag defined."
          >
            <Badge variant="orange-inverse" placement="right">
              Tag Overrides
            </Badge>
          </Tooltip>
        )}
        {store.config.get('isDisabled') && <ConfigurationDisabledLabel placement="right" />}
      </>
    );
  }

  return null;
};

export default Labels;
