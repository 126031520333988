import { memo, useState } from 'react';
import type { MouseEvent } from 'react';
import { Map } from 'immutable';

import { ButtonInline, Icon } from '@keboola/design';

import { routeNames as bucketBrowserRouteNames } from '@/modules/bucket-browser/constants';
import { routeNames } from '@/modules/storage/constants';
import RoutesStore from '@/stores/RoutesStore';
import { prepareLocationContext } from '@/utils/modalRoutes';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen,
} from '@/utils/windowOpen';
import { getRealComponentId } from './ConfigurationsTable/helpers';
import StorageDataModal from './StorageDataModal';

export const StorageDataForConfiguration = memo(
  (props: {
    tablesMetadataMap: Map<string, any>;
    component: Map<string, any>;
    config: Map<string, any>;
    tableMode?: boolean;
  }) => {
    const realComponentId = getRealComponentId(props.config, props.component);
    const tables = props.tablesMetadataMap.get(
      `${realComponentId}:${props.config.get('id')}`,
      Map(),
    );

    return (
      <StorageData
        tables={tables}
        component={props.component}
        config={props.config}
        tableMode={props.tableMode}
      />
    );
  },
);

export const StorageData = (props: {
  tables: Map<string, any>;
  component?: Map<string, any>;
  config?: Map<string, any>;
  tableMode?: boolean;
}) => {
  const [showModal, setShowModal] = useState(false);
  const buckets = props.tables
    .groupBy((table: Map<string, any>) => table.getIn(['bucket', 'id']))
    .toMap();

  if (buckets.isEmpty()) {
    return (
      <span className="text-muted no-wrap">
        {props.tableMode ? (
          '-'
        ) : (
          <>
            <Icon icon="warehouse" className="btn-icon" />
            None
          </>
        )}
      </span>
    );
  }

  return (
    <>
      <ButtonInline
        className="flex-container inline-flex flex-start no-wrap"
        onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
        onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
        onClick={(e: MouseEvent) => {
          e.preventDefault();
          e.stopPropagation();

          if (buckets.count() > 1) {
            return setShowModal(true);
          }

          const bucketId = buckets.keySeq().first();

          if (shouldUseNewWindow(e)) {
            return windowOpen(RoutesStore.getRouter().createHref(routeNames.BUCKET, { bucketId }));
          }

          const location = RoutesStore.getRouterState().get('location', Map());

          return RoutesStore.getRouter().transitionTo(
            bucketBrowserRouteNames.BUCKET_PREVIEW,
            { bucketId },
            { context: prepareLocationContext(location) },
          );
        }}
      >
        <Icon icon="warehouse" />
        Show Data
      </ButtonInline>
      <StorageDataModal
        show={showModal}
        onHide={() => setShowModal(false)}
        buckets={buckets}
        component={props.component}
        config={props.config}
      />
    </>
  );
};
