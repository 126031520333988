import { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Promise from 'bluebird';
import { fromJS, List, Map } from 'immutable';

import { Icon, Tooltip } from '@keboola/design';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import GooglePicker from '@/modules/google-utils/react/GooglePicker';
import ViewTemplates from '@/modules/google-utils/react/PickerViewTemplates';
import { listSheets } from '@/modules/google-utils/react/SheetsApi';
import { runComponent } from '@/modules/simplified-ui/actions';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import Loader from '@/react/common/Loader';
import generateId from '@/utils/generateId';
import getDefaultBucket from '@/utils/getDefaultBucket';
import nextTick from '@/utils/nextTick';
import string from '@/utils/string';
import Help from './Help';

class GoogleDriverSheetsPicker extends Component {
  static propTypes = {
    oauthCredentials: PropTypes.instanceOf(Map),
    configData: PropTypes.instanceOf(Map),
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    hasNewQueue: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
  };

  state = {
    files: this.prepareFiles(),
    selectedSheets: Map(),
    isSaving: false,
  };

  render() {
    return (
      <div className="flex-container align-top">
        <div className="fill-space pr-2">
          {this.renderFilePicker()}
          {this.renderFilesTable()}
          {this.renderConfirmButton()}
        </div>
        {this.renderHelp()}
      </div>
    );
  }

  renderFilesTable() {
    if (this.isAllSheetsEmpty()) {
      return (
        <div className="well empty-muted">
          <Icon icon="table" size="3x" />
          <p>Load data above to see sheets</p>
        </div>
      );
    }

    return (
      <div className="well with-table">
        <table className="table overflow-break-anywhere">
          <thead>
            <tr>
              <th>Document / Sheet</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.state.files
              .filter((file) => !file.has('sheets') || !file.get('sheets').isEmpty())
              .map(this.renderSheets)
              .toArray()}
            {this.isLoadingSheets() && (
              <tr>
                <td colSpan={2} className="text-muted">
                  <Loader /> Loading sheets...
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }

  renderSheets = (file, fileId) => {
    if (!file.has('sheets')) {
      return null;
    }

    return file
      .get('sheets')
      .map((sheet) => {
        return (
          <tr key={sheet.get('id')}>
            <td>
              <Icon icon="file-spreadsheet" className="icon-addon-right text-muted" />
              {file.get('name')} / {sheet.get('title')}
            </td>
            <td className="pl-0 pr-0">
              <Tooltip placement="top" tooltip="Remove sheet">
                <Button
                  bsStyle="link"
                  className="text-muted"
                  onClick={() => this.handleRemoveSheet(fileId, sheet.get('id'))}
                >
                  <Icon icon="trash" />
                </Button>
              </Tooltip>
            </td>
          </tr>
        );
      })
      .toArray();
  };

  renderHelp() {
    if (!this.state.isSaving && this.isSaveDisabled()) {
      return (
        <Help
          title="Just load one of the available files"
          text="You will be able to see available sheets that can be used for data operations once they are loaded. You can load more files later."
        />
      );
    }

    return (
      <Help
        down
        title="What will happen now?"
        text="We will start loading your data. It may take a few minutes for large amounts of data. In the meantime, you can load data from other sources."
      />
    );
  }

  renderFilePicker() {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <GooglePicker
        requireSheetsApi
        dialogTitle="Select a spreadsheet documents"
        buttonLabel="Load spreadsheet documents from Google Drive"
        onPickedFn={this.handleSelected}
        buttonProps={{ block: true }}
        views={[
          ViewTemplates.sheets,
          ViewTemplates.teamDriveSheets,
          ViewTemplates.sharedSheets,
          ViewTemplates.starredSheets,
          ViewTemplates.recentSheets,
        ]}
      />
    );
  }

  renderConfirmButton() {
    return (
      <ConfirmButtons
        block
        saveLabel="Save and Run configuration"
        onSave={this.handleSaveAndRun}
        isSaving={this.state.isSaving}
        isDisabled={this.isSaveDisabled()}
      />
    );
  }

  isLoadingSheets() {
    return this.state.files.some((file) => !file.has('sheets'));
  }

  isAllSheetsEmpty() {
    return this.state.files.every((file) => file.has('sheets') && file.get('sheets').isEmpty());
  }

  isSaveDisabled() {
    return (
      this.props.readOnly ||
      this.isLoadingSheets() ||
      this.isAllSheetsEmpty() ||
      this.state.files.equals(this.prepareFiles())
    );
  }

  prepareFiles() {
    return Map().withMutations((files) => {
      this.props.configData.getIn(['parameters', 'sheets'], List()).forEach((sheet) => {
        files
          .setIn([sheet.get('fileId'), 'id'], sheet.get('fileId'))
          .setIn([sheet.get('fileId'), 'name'], sheet.get('fileTitle'))
          .updateIn([sheet.get('fileId'), 'sheets'], List(), (sheets) => {
            return sheets.push(Map({ id: sheet.get('sheetId'), title: sheet.get('sheetTitle') }));
          });
      });
    });
  }

  prepareOutputTable(file, sheet) {
    return string.sanitizeKbcTableIdString(`${file.get('name')}-${sheet.get('title')}`);
  }

  handleSelected = (data) => {
    let newFiles = fromJS(
      data
        .filter((file) => file.type === 'document')
        .map((file) => ({ id: file.id, name: file.name })),
    )
      .toMap()
      .mapKeys((key, file) => file.get('id'));

    this.setState({ files: this.state.files.merge(newFiles) });

    Promise.each(newFiles.keySeq().toArray(), (fileId) => {
      return listSheets(fileId).then((data) => {
        const sheets = data.result.sheets.map((sheet) => ({
          id: sheet.properties.sheetId,
          title: sheet.properties.title,
        }));

        this.setState({ files: this.state.files.setIn([fileId, 'sheets'], fromJS(sheets)) });
      });
    });
  };

  handleRemoveSheet = (fileId, sheetId) => {
    this.setState({
      files: this.state.files.updateIn([fileId, 'sheets'], (sheets) => {
        return sheets.filter((sheet) => sheet.get('id') !== sheetId);
      }),
    });
  };

  handleSaveAndRun = () => {
    let usedIds = List();
    let existingIds = this.props.configData
      .getIn(['parameters', 'sheets'], List())
      .map((savedSheet) => savedSheet.get('id'));

    const sheets = this.state.files
      .map((file) => {
        return file.get('sheets', List()).map((sheet) => {
          const newId = generateId(existingIds.concat(usedIds));
          usedIds = usedIds.push(newId);

          return {
            id: newId,
            enabled: true,
            fileId: file.get('id'),
            fileTitle: file.get('name'),
            sheetId: sheet.get('id'),
            sheetTitle: sheet.get('title'),
            outputTable: this.prepareOutputTable(file, sheet),
            header: { rows: 1 },
          };
        });
      })
      .toList()
      .flatten(true);

    this.setState({ isSaving: true });
    return InstalledComponentsActionCreators.saveComponentConfigData(
      this.props.componentId,
      this.props.configId,
      this.props.configData
        .setIn(
          ['parameters', 'outputBucket'],
          getDefaultBucket('in', this.props.componentId, this.props.configId),
        )
        .setIn(['parameters', 'sheets'], sheets),
      `Add ${sheets.count()} ${string.pluralize(sheets.count(), 'sheet')}
      `,
    )
      .then(() => runComponent(this.props.componentId, this.props.configId, this.props.hasNewQueue))
      .finally(() => {
        return nextTick(() => this.setState({ files: this.prepareFiles(), isSaving: false }));
      });
  };
}

export default GoogleDriverSheetsPicker;
