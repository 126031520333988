import createReactClass from 'create-react-class';
import { fromJS, List } from 'immutable';

import { Tabs, TabsContent } from '@keboola/design';

import { SIDEBAR } from '@/constants/external';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import OrchestrationActions from '@/modules/orchestrations/ActionCreators';
import mergeTasksWithConfigurations from '@/modules/orchestrations/mergeTasksWithConfigurations';
import OrchestrationJobsStore from '@/modules/orchestrations/stores/OrchestrationJobsStore';
import OrchestrationStore from '@/modules/orchestrations/stores/OrchestrationsStore';
import Events from '@/modules/sapi-events/react/Events';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import JobTasks from './JobTasks';
import Overview from './Overview';
import RunHistory from './RunHistory';

const OrchestrationJobDetail = createReactClass({
  mixins: [
    createStoreMixin(
      RoutesStore,
      ComponentsStore,
      OrchestrationStore,
      OrchestrationJobsStore,
      InstalledComponentsStore,
      ApplicationStore,
    ),
  ],

  getStateFromStores() {
    const orchestrationId = RoutesStore.getCurrentRouteIntParam('orchestrationId');
    const jobId = RoutesStore.getCurrentRouteIntParam('jobId');
    let job = OrchestrationJobsStore.getJob(jobId);

    if (job && job.hasIn(['results', 'tasks'])) {
      const phasedTasks = OrchestrationActions.rephaseTasks(
        job.getIn(['results', 'tasks'], List()).toJS(),
      );
      const merged = mergeTasksWithConfigurations(
        fromJS(phasedTasks),
        InstalledComponentsStore.getAll(),
      );
      job = job.setIn(['results', 'tasks'], OrchestrationActions.dephaseTasks(merged));
    }

    return {
      job,
      jobs: OrchestrationJobsStore.getOrchestrationJobs(orchestrationId),
      openedTab: RoutesStore.getRouterState().hasIn(['location', 'query', 'eventId'])
        ? 'log'
        : 'tasks',
      admins: ApplicationStore.getAdmins(),
      allComponents: ComponentsStore.getAll(),
      hasFlows: ApplicationStore.hasFlows(),
    };
  },

  render() {
    if (!this.state.job) {
      return <p>Job not found</p>;
    }

    return (
      <>
        <Overview job={this.state.job} />
        <div className="row">
          <div className="col-sm-9">
            <Tabs
              defaultValue={this.state.openedTab}
              triggers={[
                {
                  value: 'tasks',
                  title: 'Tasks',
                },
                {
                  value: 'log',
                  title: 'Events',
                },
              ]}
            >
              <TabsContent value="tasks">
                <JobTasks
                  tasks={this.state.job.getIn(['results', 'tasks'], List())}
                  hasFlows={this.state.hasFlows}
                  allComponents={this.state.allComponents}
                />
              </TabsContent>
              <TabsContent value="log">
                <Events
                  admins={this.state.admins}
                  params={{ runId: this.state.job.get('runId') }}
                  autoReload={
                    this.state.job.get('status') === 'waiting' ||
                    this.state.job.get('status') === 'processing'
                  }
                />
              </TabsContent>
            </Tabs>
          </div>
          <div className="col-sm-3">
            <div className={`sidebar-content ${SIDEBAR}`}>
              <RunHistory jobs={this.state.jobs} activeJobId={this.state.job.get('id')} />
            </div>
          </div>
        </div>
      </>
    );
  },
});

export default OrchestrationJobDetail;
