import type { ComponentProps } from 'react';

import { cn, Icon } from '@keboola/design';

const StepStatusIcon = ({
  isConfigurable,
  isPreconfigured,
  isConfigured,
  isValid,
}: {
  isConfigurable: boolean;
  isPreconfigured: boolean;
  isConfigured: boolean;
  isValid: boolean;
}) => {
  let props: { icon: ComponentProps<typeof Icon>['icon']; className: string };

  if (isConfigurable && !isPreconfigured && !isConfigured) return null;
  if (!isConfigurable) {
    props = { icon: 'lock', className: 'bg-color-gray-dark' };
  } else if (isValid) {
    props = { icon: 'circle-check', className: 'text-success' };
  } else {
    props = { icon: 'circle-exclamation', className: 'text-danger' };
  }

  return <Icon icon={props.icon} className={cn('align-bottom small-icon', props.className)} />;
};

export default StepStatusIcon;
