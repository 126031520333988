import { useState } from 'react';
import { Button, ButtonToolbar, Modal } from 'react-bootstrap';

import { Alert, ButtonInline, Icon } from '@keboola/design';

import { routeNames } from '@/modules/snowflake-partner-connect/constants';
import { MeetingWidget } from '@/react/common/MeetingWidget';
import ModalIcon from '@/react/common/ModalIcon';
import KeboolaLogo from '@/react/layout/KeboolaLogo';
import RoutesStore from '@/stores/RoutesStore';
import contactSupport from '@/utils/contactSupport';
import { getItem, setItem } from '@/utils/localStorage';

type Props = { isUpgraded: boolean };

const WelcomeModal = ({ isUpgraded }: Props) => {
  const [show, setShow] = useState(true);
  const [showMeetingWidget, setShowMeetingWidget] = useState(false);
  const localStorageKey = `spc${isUpgraded ? '-upgraded' : ''}-welcome-modal-seen`;
  const isSeen = getItem(localStorageKey);
  const onHideHandler = () => {
    setShow(false);
    setItem(localStorageKey, true);
  };

  if (isUpgraded) {
    return (
      <>
        <Modal bsSize="large" show={!isSeen && show} onHide={onHideHandler}>
          <Modal.Header closeButton>
            <ModalIcon icon="check" color="green" bold />
            <Modal.Title>All Features Enabled</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {showMeetingWidget ? (
              <MeetingWidget type="sales" />
            ) : (
              <>
                <p className="mbp-6 font-medium">
                  Congratulations! You have successfully granted us the necessary privileges and
                  unlocked the full potential of Keboola. You&apos;re now free to run, build, and
                  play!
                </p>
                <h4>30-day trial</h4>
                <p className="mbp-6 text-muted">
                  Your 30-day trial period is ending soon. If you don&apos;t upgrade to a paid plan,
                  your project will expire and you&apos;ll lose any work that you&apos;ve completed
                  so far. Upgrade now to keep your project active and continue using Keboola.
                </p>
                <h4>Contact our support or schedule a call</h4>
                <p className="text-muted">
                  Schedule a call with our friendly sales team to learn more about our paid plans.
                  Our team will help you understand Keboola&apos;s full range of features and
                  benefits, then tailor a plan that meets your specific needs. Don&apos;t miss out
                  on the power of Keboola - upgrade today!
                </p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            {showMeetingWidget ? (
              <Button onClick={() => setShowMeetingWidget(false)} block>
                <Icon icon="chevron-left" className="icon-addon-right" />
                Back
              </Button>
            ) : (
              <ButtonToolbar className="block same-width">
                <Button onClick={() => contactSupport()}>Contact Support</Button>
                <Button onClick={() => setShowMeetingWidget(true)}>Schedule Call</Button>
              </ButtonToolbar>
            )}
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  return (
    <Modal bsSize="large" show={!isSeen && show} onHide={onHideHandler}>
      <Modal.Header closeButton>
        <KeboolaLogo width={48} height={48} className="modal-icon" withoutText />
        <Modal.Title>Welcome to Keboola!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mbp-6">
          You&apos;ve just made the first step to having a fully governed data stack as a service.
          Here&apos;s how you can make the most of our platform:
        </p>
        <ol>
          <li className="mbp-6">
            Connect your data sources: We have a wide offering of out-of-the-box connectors which
            pull data from various sources, including CRMs, marketing tools, and databases. We also
            offer a Generic Extractor and Generic Writer. Just go to the “Components” section and
            start connecting your data.
          </li>
          <li className="mbp-6">
            Transform your data: Choose Snowflake, Python, R, dbt, or even a no-code transformation
            to run your data cleaning job.
          </li>
          <li className="mbp-6">
            Automate mundane tasks: Set up automatic orchestrations in Flows, or use Data Templates
            to accelerate your data production with one-click deployment.
          </li>
          <li className="mbp-6">
            Beyond ETL: Use the Data Catalog to centralize and share data, try out the intuitive
            drag-and-drop Flow Builder, or use Development Branches as an easy way of testing
            changes without affecting production.
          </li>
        </ol>
        <p className="mbp-6">
          Take a look around - we know you&apos;ll find everything you need to successfully run a
          data-driven company!
        </p>
        <p className="mbp-6">
          If you have any questions, please don&apos;t hesitate to{' '}
          <ButtonInline variant="link" onClick={() => contactSupport()}>
            contact our support team
          </ButtonInline>
          .
        </p>
        <Alert title="Using Transformations and Workspaces" className="tw-mb-5">
          For transformations and workspaces to work, you need to grant us privileges in your
          Snowflake account. Here&apos;s how you can{' '}
          <Button
            bsStyle="link"
            className="btn-link-inline"
            onClick={() => {
              RoutesStore.getRouter().transitionTo(routeNames.UPGRADE_PAGE);
              onHideHandler();
            }}
          >
            enable all features
          </Button>
          .
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button block bsStyle="success" onClick={onHideHandler}>
          Explore Keboola
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WelcomeModal;
