import { ControlLabel, FormGroup } from 'react-bootstrap';

import Select from '@/react/common/Select';

export const DataShareOwnerSelect = (props: {
  value: string;
  options: { value: number; label: string }[];
  disabled?: boolean;
  onChange: (selected: any) => void;
}) => {
  return (
    <FormGroup controlId="formDataShareOwnerControl">
      <ControlLabel>Datashare Owner</ControlLabel>
      <Select
        id="formDataShareOwnerControl"
        placeholder="Select owner"
        options={props.options}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
      />
    </FormGroup>
  );
};
