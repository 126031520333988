import { List, Map } from 'immutable';
import memoizeOne from 'memoize-one';

import { EXCLUDE_FROM_NEW_LIST, EXCLUDE_RUN } from '@/constants/componentFlags';
import { componentTypes } from '@/constants/componentTypes';
import { getComponentsFiltered, sortComponents } from '@/modules/components-directory/helpers';
import { componentsUnavailableInTrial } from '@/modules/snowflake-partner-connect/constants';
import { matchByWords } from '@/utils/matchByWords';

export const VALID_TYPES = Object.values(componentTypes);

type ConfigData = { component: Map<string, any>; data: Map<string, any>[] }[];

export type AggregatedConfigurations = {
  data: ConfigData;
  size: number;
};

export const aggregateConfigurations = (
  configurations: Map<string, any>,
  searchQuery: string,
  validComponentTypes: readonly string[] = [],
) => {
  let size = 0;
  const values = configurations
    .valueSeq()
    .toArray()
    .map((value) => {
      if (!validComponentTypes.includes(value.get('type'))) {
        return null;
      }

      const configs = value
        .get('configurations')
        .valueSeq()
        .toArray()
        .filter((config: Map<string, any>) => matchByWords(config.get('name'), searchQuery));

      if (configs.length === 0) {
        return null;
      }

      size += configs.length;

      return {
        component: value.delete('configurations'),
        data: configs,
      };
    });

  return {
    data: values.filter(Boolean) as ConfigData,
    size,
  };
};

const sortComponentsByConfigsNumber = (
  components: Map<string, any>,
  configurations: Map<string, any>,
) =>
  sortComponents(components).sort((componentA: Map<string, any>, componentB: Map<string, any>) => {
    const configsASize = configurations
      .getIn([componentA.get('id'), 'configurations'], Map())
      .count();
    const configsBSize = configurations
      .getIn([componentB.get('id'), 'configurations'], Map())
      .count();

    return configsBSize - configsASize;
  });

export const getComponents = memoizeOne(
  (
    components,
    configurations,
    query,
    componentTypes,
    hasSnowflakePartnerConnectLimited,
    forceAllowedComponents = Map(),
  ) => {
    const allowedComponents = components.filter((component: Map<string, any>) => {
      const flags = component.get('flags', List());

      if (flags.includes(EXCLUDE_RUN)) {
        return false;
      }

      if (configurations.has(component.get('id')) || component.get('id') === query) {
        return true;
      }

      return !flags.includes(EXCLUDE_FROM_NEW_LIST) && VALID_TYPES.includes(component.get('type'));
    });

    let filteredComponents = getComponentsFiltered({
      components: allowedComponents,
      query,
      types: componentTypes ? List(componentTypes) : List(),
      fields: ['name'],
    });

    if (hasSnowflakePartnerConnectLimited) {
      filteredComponents = filteredComponents.filter((component: Map<string, any>) => {
        return !componentsUnavailableInTrial.includes(component.get('id'));
      });
    }

    if (forceAllowedComponents) {
      const forcedFiltered = getComponentsFiltered({
        components: forceAllowedComponents,
        query,
        fields: ['name'],
      });
      filteredComponents = filteredComponents.merge(forcedFiltered);
    }

    return sortComponentsByConfigsNumber(filteredComponents, configurations);
  },
);
