import { useEffect, useRef, useState } from 'react';
import type { ReactNode } from 'react';

import { cn } from '@keboola/design';

const FullScreenTable = (props: { children: ReactNode; className?: string }) => {
  const [active, setActive] = useState(false);
  const tableRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    if (!tableRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      const rect = tableRef.current?.getBoundingClientRect();
      setActive(!!rect && rect.bottom > window.innerHeight - 80);
    });

    resizeObserver.observe(tableRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <div className={cn('box full-screen-table', { active })}>
      <table ref={tableRef} className={cn('table table-hover', props.className)}>
        {props.children}
      </table>
    </div>
  );
};

export default FullScreenTable;
