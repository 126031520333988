import { useCallback } from 'react';
import {
  queryOptions,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { List } from 'immutable';

import type { Feature } from '@keboola/api-client';
import { useApiClient } from '@keboola/api-client';

import { managementApiKey } from '@/constants/queryKeys';
import ApplicationStore, { applicationStoreAction } from '@/stores/ApplicationStore';
import type { FeFeature } from './types';
import { combineProjectFeaturesQueries, selectAdminFeatures } from './utils';

export const useToggleProjectFeatureMutation = (projectId: number) => {
  const queryClient = useQueryClient();
  const api = useApiClient();

  const removeMutation = useMutation({
    mutationFn: api.management.projects.removeProjectFeature,
    onSuccess: (_, variables) => {
      applicationStoreAction.removeProjectFeature(variables.feature);
      return queryClient.invalidateQueries({
        queryKey: managementApiKey.getProject(variables.projectId),
      });
    },
  });

  const addMutation = useMutation({
    mutationFn: api.management.projects.addProjectFeature,
    onSuccess: (_, variables) => {
      applicationStoreAction.addProjectFeature(variables.feature);
      return queryClient.invalidateQueries({
        queryKey: managementApiKey.getProject(variables.projectId),
      });
    },
  });

  const toggle = (value: boolean, feature: FeFeature) => {
    const val = feature.isReversed ? !value : value;
    const mutation = val ? addMutation : removeMutation;
    return mutation.mutateAsync({ projectId, feature: feature.name });
  };

  return {
    featureName: removeMutation.variables?.feature,
    isPending: removeMutation.isPending || addMutation.isPending,
    toggle,
  };
};

export const useToggleAdminFeatureMutation = (email: string) => {
  const api = useApiClient();

  const removeMutation = useMutation({
    mutationFn: api.management.users.removeUserAdminFeature,
    onSuccess: (_, variables) => {
      applicationStoreAction.removeAdminFeature(variables.feature);
    },
  });

  const addMutation = useMutation({
    mutationFn: api.management.users.addUserAdminFeature,
    onSuccess: (_, variables) => {
      applicationStoreAction.addAdminFeature(variables.feature);
    },
  });

  const toggle = (value: boolean, feature: FeFeature) => {
    const val = feature.isReversed ? !value : value;
    const mutation = val ? addMutation : removeMutation;
    return mutation.mutateAsync({ userIdOrMail: email, feature: feature.name });
  };

  return {
    featureName: removeMutation.variables?.feature,
    isPending: removeMutation.isPending || addMutation.isPending,
    toggle,
  };
};

export const useProjectFeaturesQuery = (projectId: number) => {
  const api = useApiClient();

  return useQueries({
    queries: [
      queryOptions({
        queryKey: managementApiKey.getProject(projectId),
        queryFn: ({ signal, queryKey }) =>
          api.management.projects.getProject(queryKey[1].projectId, signal),
        retry: false,
      }),
      queryOptions({
        queryKey: managementApiKey.getAllFeatures({ type: 'project' }),
        queryFn: ({ signal, queryKey }) =>
          api.management.features.getAllFeatures(queryKey[1].query, signal),
        retry: false,
      }),
    ],
    combine: combineProjectFeaturesQueries,
  });
};

export const useUserAdminFeaturesQuery = ({ enabled }: { enabled: boolean }) => {
  const api = useApiClient();
  const active = ApplicationStore.getCurrentAdmin().get('features', List()).toJS() as string[];

  const select = useCallback((data: Feature[]) => selectAdminFeatures(data, active), [active]);

  return useQuery({
    queryKey: managementApiKey.getAllFeatures({ type: 'admin' }),
    queryFn: ({ signal, queryKey }) =>
      api.management.features.getAllFeatures(queryKey[1].query, signal),
    retry: false,
    select,
    enabled,
  });
};
