import { Modal } from 'react-bootstrap';

import { ButtonInline, Link } from '@keboola/design';

import ModalIcon from '@/react/common/ModalIcon';

type Props = {
  show: boolean;
  onHide: () => void;
  onSelectExample: (query: string) => void;
};

const examples = [
  {
    query: 'token.name:john.doe@company.com',
    description: 'Files uploaded by John Doe',
  },
  {
    query: 'name:devel',
    description: 'Files with a name that contains "devel"',
  },
  {
    query: 'isPublic:1',
    description: 'Public files only',
  },
  {
    query: '-isPublic:1',
    description: 'Everything except public files',
  },
  {
    query: 'created:>2018-01-31',
    description: 'Files created after 2018-01-31',
  },
  {
    query: 'sizeBytes:>10000',
    description: 'Files bigger than 10kB',
  },
  {
    query: 'tags:table-export',
    description: 'Files tagged "table-export"',
  },
];

const FilesSearchExamplesModal = (props: Props) => {
  const handleSelectExample = (example: { query: string; description: string }) => {
    props.onSelectExample(example.query);
    props.onHide();
  };

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Search Syntax &amp; Examples</Modal.Title>
        <ModalIcon icon="circle-question" color="green" bold />
      </Modal.Header>
      <Modal.Body>
        {examples.map((example, index) => {
          return (
            <div className="well well-sm" key={index}>
              Search:{' '}
              <ButtonInline onClick={() => handleSelectExample(example)}>
                {example.query}
              </ButtonInline>
              <br />
              Shows: <span>{example.description}</span>
            </div>
          );
        })}
        <p>
          <Link href="https://www.elastic.co/guide/en/elasticsearch/reference/5.0/query-dsl-query-string-query.html#query-string-syntax">
            Read full syntax guide
          </Link>
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default FilesSearchExamplesModal;
