import type { Map } from 'immutable';
import { List } from 'immutable';
import _ from 'underscore';

import { LOCAL_STORAGE_FAV_PROJECT_KEY_PART } from '@/constants/localStorageKeys';
import useLocalStorage from '@/react/hooks/useLocalStorage';
import { matchByWords } from '@/utils';
import Header from './Header';
import ProjectsList from './ProjectsList';
import type { Project } from './types';

const DropdownContent = ({
  organizations,
  currentOrganization,
  currentUser,
  currentProject,
  query,
  urlTemplates,
}: {
  organizations: Map<string, any>;
  currentOrganization: Map<string, any>;
  currentUser: Map<string, any>;
  currentProject: Map<string, any>;
  query: string;
  urlTemplates: Map<string, any>;
}) => {
  const [favouriteProjects] = useLocalStorage<Pick<Project, 'id'>[]>(
    `${LOCAL_STORAGE_FAV_PROJECT_KEY_PART}-${currentUser.get('id')}`,
    [],
  );

  if (organizations.isEmpty()) {
    return <li className="no-project-found text-muted mb-0">No projects or organizations found</li>;
  }

  const favouriteProjectsIds = favouriteProjects.map((project) => project.id);

  const organizationsWithoutFavouriteProjects = organizations
    .map((organization) =>
      organization.update('projects', (prevProjects: List<any>) =>
        prevProjects.filter((project) => !favouriteProjectsIds.includes(project.get('id'))),
      ),
    )
    .filter((organization) => !organization.get('projects').isEmpty());

  const favouriteProjectsFiltered: Project[] = organizations
    .flatMap((organization) => {
      return organization
        .get('projects', List())
        .filter((project: Map<string, any>) => favouriteProjectsIds.includes(project.get('id')))
        .map((project: Map<string, any>) => project.set('organization', organization));
    })
    .filter((project: any) => {
      return (
        matchByWords(project.get('name'), query) ||
        matchByWords(project.getIn(['organization', 'name']), query)
      );
    })
    .toJS();

  const getOrganizationUrlFromTemplate = (organizationId: string) => {
    return _.template(urlTemplates.get('organization'))({ organizationId });
  };

  return (
    <>
      {favouriteProjectsFiltered.length !== 0 && (
        <>
          <li className="organization-item">
            <Header text="Starred projects" />
            <ProjectsList
              projects={favouriteProjectsFiltered}
              currentProject={currentProject}
              query={query}
              currentUser={currentUser}
              urlTemplates={urlTemplates}
              withOrganizationName
              getOrganizationUrl={getOrganizationUrlFromTemplate}
            />
            {!organizationsWithoutFavouriteProjects.isEmpty() && <hr />}
          </li>
        </>
      )}
      {organizationsWithoutFavouriteProjects
        .sortBy((organization) => organization.get('id') !== currentOrganization.get('id'))
        .toArray()
        .map((organization, index) => (
          <li key={`proj-${organization.get('id')}`} className="organization-item">
            {index > 0 && <hr />}
            <Header
              text={organization.get('name')}
              href={
                organization.get('hasAccess')
                  ? getOrganizationUrlFromTemplate(organization.get('id'))
                  : null
              }
            />
            <ProjectsList
              projects={organization.get('projects').toJS()}
              currentProject={currentProject}
              query={query}
              currentUser={currentUser}
              urlTemplates={urlTemplates}
            />
          </li>
        ))}
    </>
  );
};

export default DropdownContent;
