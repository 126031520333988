import qs from 'qs';

import type { BodyParam, CreateFetchRequestFn, PathParam, ValidateStatusFn } from './types';

export const HttpHeader = {
  CONTENT_TYPE: 'content-type',
  CONTENT_DISPOSITION: 'content-disposition',
};

export const HttpContentType = {
  JSON: 'application/json',
  FORM_DATA: 'multipart/form-data',
  TEXT_HTML: 'text/html',
};

export const defaultValidateStatus: ValidateStatusFn = ({ response }) =>
  response.status >= 200 && response.status <= 299;

function removeUndefined(obj: Record<string, string>) {
  const objCopy = { ...obj };
  for (const [key, value] of Object.entries(objCopy)) {
    if (value == null) delete objCopy[key];
  }
  return objCopy;
}

export const parseData = async (response: Response) => {
  // HTTP 204 - no content
  if (response.status === 204) return null;

  // headers indicates that the content is JSON, so parse it.
  const contentType = response.headers.get('content-type');
  if (contentType && contentType == 'application/json') {
    return await response.json();
  }

  // try JSON parsing on the text
  // If it fails, return the string. The headers does not have a content type.
  const text = await response.text();
  try {
    return JSON.parse(text);
  } catch {
    return text;
  }
};

// Header instance serialize undefined value as `undefined`, we do not want that
const cleanHeadersInit = (headersInit: HeadersInit | undefined) => {
  if (Array.isArray(headersInit)) return headersInit;
  if (headersInit instanceof Headers) return headersInit;
  if (headersInit == null) return headersInit;

  return removeUndefined(headersInit);
};

const createHeaders = (
  headersInitA: HeadersInit | undefined,
  headersInitB: HeadersInit | undefined,
) => {
  const headersA = new Headers(cleanHeadersInit(headersInitA));
  const headersB = new Headers(cleanHeadersInit(headersInitB));

  headersB.forEach((value, key) => {
    headersA.set(key, value);
  });

  return headersA;
};

export const createPath = (path: string, pathParam: PathParam = {}) =>
  path.replace(/\{([^}]+)}/g, (_, key) => {
    if (!(key in pathParam))
      throw new Error(`Path parameter "${key}" is missing in the path "${path}"`);

    return encodeURIComponent(pathParam[key]);
  });

export const createSearch = (query: Record<string, unknown>) => {
  return qs.stringify(query, { encodeValuesOnly: true, skipNulls: true });
};

export const createBody = (body: BodyParam, headers: Headers) => {
  if (body == null) return null;
  if (body instanceof FormData) return body;

  const stringifyBody = JSON.stringify(body);
  const stringBody = stringifyBody === '{}' ? null : stringifyBody;

  if (stringBody) headers.set(HttpHeader.CONTENT_TYPE, HttpContentType.JSON);

  return stringBody;
};

export const parseContentDispositionHeader = (contentDisposition?: string | null) => {
  // extract the type ('inline' or 'attachment'), default the type to 'unknown'
  const type = (() => {
    const typeMatch = contentDisposition?.match(/^\s*(inline|attachment)\s*;/i);
    return (typeMatch?.[1].toLowerCase() ?? 'unknown') as 'inline' | 'attachment' | 'unknown';
  })();

  // extract the filename, default the type to null
  const filename = (() => {
    const filenameMatch = contentDisposition?.match(/filename="([^"]+)"/i);
    if (filenameMatch) return filenameMatch[1];

    // If no filename="...", check for encoded filename*=UTF-8''
    const encodedFilenameMatch = contentDisposition?.match(/filename\*=(?:UTF-8'')?([^;]+)/i);
    if (encodedFilenameMatch) return decodeURIComponent(encodedFilenameMatch[1]);

    // Check for unquoted filenames (rare but possible)
    const unquotedFilenameMatch = contentDisposition?.match(/filename=([^;]+)/i);
    return unquotedFilenameMatch?.[1] ?? null;
  })();

  // Return the parsed result
  return { type, filename };
};

export const createFetchRequest: CreateFetchRequestFn = ({
  url,
  method,
  params,
  options = {},
  defaultOptions,
}) => {
  const {
    baseUrl,
    validateStatus: defValidateStatus = defaultValidateStatus,
    headers: defaultHeaders,
    ...restDefaultOptions
  } = defaultOptions;
  const { validateStatus, headers: endpointHeaders, ...restOptions } = options;

  const headers = createHeaders(defaultHeaders, endpointHeaders);
  const path = createPath(url, params.path);
  const search = createSearch(params.query ?? {});
  const body = createBody(params.body, headers);

  const urlInstance = new URL(baseUrl + path);
  urlInstance.search = search;

  const request = new Request(urlInstance, {
    ...restDefaultOptions,
    ...restOptions,
    headers,
    method,
    body,
  });

  return { request, validateStatus: validateStatus ?? defValidateStatus };
};
