import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import type Promise from 'bluebird';
import type { Map } from 'immutable';
import { List } from 'immutable';

import { cn, IconButton } from '@keboola/design';

import { KEBOOLA_DATA_APPS, KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import keyCodes from '@/constants/keyCodes';
import {
  ensureConfigurationsDetails,
  getComponentTypeColorClassName,
} from '@/modules/components/helpers';
import TaskIcon from '@/modules/flows/components/TaskIcon';
import { resolveComponentId, selectConfigWithRedirect } from '@/modules/flows/helpers';
import { Truncated } from '@/react/common';
import ComponentBadges from '@/react/common/ComponentBadges';
import FullScreenModal, { FullScreenModalHeader } from '@/react/common/FullScreenModal';
import ComponentDetail from './ComponentDetail';
import ConfigurationDetail from './ConfigurationDetail';

type Props = {
  configId: string;
  automationId?: string;
  show?: boolean;
  readOnly: boolean;
  hasPayAsYouGo: boolean;
  task: Map<string, any>;
  lastJob?: Map<string, any>;
  onEditTask: (id: string, field: string | string[], value: any) => void;
  onSelectConfig: (
    taskId: string,
    componentId: string,
    configId: string,
    options?: { autosave: boolean },
  ) => Promise<any>;
  patternComponents: Map<string, any>;
  allConfigurations: Map<string, any>;
  allComponents: Map<string, any>;
  folders: Map<string, any>;
  showBackendSize: boolean;
  isDevModeActive: boolean;
  hasSnowflakeDynamicBackendSize: boolean;
  hasJobsDynamicBackendSize: boolean;
  onHide: () => void;
  onSetTaskParams: (taskId: string) => void;
  onDeleteTask: () => void;
};

const TaskDetailModal = ({
  configId,
  automationId,
  show,
  task,
  lastJob,
  readOnly,
  hasPayAsYouGo,
  onEditTask,
  onSelectConfig,
  allConfigurations,
  allComponents,
  folders,
  showBackendSize,
  hasSnowflakeDynamicBackendSize,
  patternComponents,
  hasJobsDynamicBackendSize,
  isDevModeActive,
  onHide,
  onSetTaskParams,
  onDeleteTask,
}: Props) => {
  const componentId = task.get('componentId');
  const [previousConfigId, setPreviousConfigId] = useState<string | null>(null);

  useEffect(() => {
    if (show) {
      ensureConfigurationsDetails(componentId);
    }
  }, [componentId, show]);

  useEffect(() => {
    const hide = (e: KeyboardEvent) => e.key === keyCodes.ESCAPE && show && onHide();
    window.addEventListener('keyup', hide, { passive: true });
    return () => window.removeEventListener('keyup', hide);
  }, [show, onHide]);

  if (!show) {
    return null;
  }

  const flags = allComponents.getIn([task.get('componentId'), 'flags'], List());

  const handleSelectConfigWithRedirect = (
    event?: React.MouseEvent | null,
    componentId?: string,
    config?: string,
  ) => {
    const configurationId = config || task.get('configId');
    const realComponentId =
      componentId || resolveComponentId(allConfigurations, task, configurationId);

    return selectConfigWithRedirect(
      event ?? null,
      realComponentId,
      configurationId,
      task.get('id'),
      onSelectConfig,
      configId,
      automationId,
    );
  };

  const handleChangeConfiguration = () => {
    setPreviousConfigId(task.get('configId'));
    onEditTask(task.get('id'), ['task', 'configId'], '');
  };

  const handleSelectConfiguration = (configId: string) => {
    setPreviousConfigId(null);
    return onSelectConfig(
      task.get('id'),
      resolveComponentId(allConfigurations, task, configId),
      configId,
    );
  };

  const handleBack = () => {
    if (!previousConfigId) {
      return;
    }

    return handleSelectConfiguration(previousConfigId);
  };

  const configurationName = allConfigurations.getIn(
    [componentId, 'configurations', task.get('configId'), 'name'],
    '',
  );

  const isConfigDetail = !!task.get('configId');

  const customType =
    task.get('componentId') === KEBOOLA_ORCHESTRATOR
      ? 'Flow'
      : task.get('componentId') === KEBOOLA_DATA_APPS
        ? 'Data App'
        : '';

  const type = task.get('type') || (isConfigDetail ? customType : '');

  const typeColor = !!task.get('type')
    ? getComponentTypeColorClassName(task.get('type'))
    : 'tw-text-neutral-400';

  return (
    <FullScreenModal
      className="tw-h-screen tw-w-[480px] tw-justify-self-end tw-shadow-[0px_3px_4px_0px_rgba(34,37,41,0.12)] [&_.modal-content]:tw-h-full [&_.modal-content]:tw-bg-white [&_.modal-content]:tw-px-3 [&_.modal-content]:tw-pb-0 [&_.modal-content]:tw-pt-6"
      wrapperClassName="tw-flex tw-flex-col tw-gap-6 tw-h-full tw-overflow-hidden"
      hideOverflow={false}
    >
      <FullScreenModalHeader
        showPreviousTitle={false}
        title={
          <div className="tw-flex tw-w-full tw-items-center">
            {!!previousConfigId && (
              <IconButton
                className="tw-mr-4"
                variant="outline"
                onClick={handleBack}
                icon="angle-left"
              />
            )}
            <TaskIcon src={task.get('iconUrl')} size={32} />
            <div className="font-medium">
              <div className="tw-flex">
                <Truncated
                  className="tw-text-sm"
                  text={task.get('configId') ? configurationName : task.get('component')}
                />
                {task.get('componentId') !== KEBOOLA_DATA_APPS &&
                  task.get('componentId') !== KEBOOLA_ORCHESTRATOR && (
                    <ComponentBadges flags={flags} className="tw-ml-2" />
                  )}
              </div>

              {!!type && <div className={cn('tw-text-xs', typeColor)}>{type}</div>}
            </div>
          </div>
        }
        onClose={onHide}
        className="tw-border-0 tw-bg-white tw-px-3 tw-py-0"
      >
        <IconButton
          icon="xmark"
          onClick={onHide}
          variant="outline"
          className="tw-ml-10 tw-shrink-0"
        />
      </FullScreenModalHeader>
      <Modal.Body className="tw-flex tw-grow tw-flex-col tw-gap-4 tw-overflow-hidden tw-p-1">
        {isConfigDetail ? (
          <ConfigurationDetail
            task={task}
            readOnly={readOnly}
            lastJob={lastJob}
            onEditTask={onEditTask}
            onSelectConfigWithRedirect={handleSelectConfigWithRedirect}
            allConfigurations={allConfigurations}
            showBackendSize={showBackendSize}
            hasSnowflakeDynamicBackendSize={hasSnowflakeDynamicBackendSize}
            hasJobsDynamicBackendSize={hasJobsDynamicBackendSize}
            isDevModeActive={isDevModeActive}
            onSetTaskParams={onSetTaskParams}
            onDeleteTask={onDeleteTask}
            onBack={handleChangeConfiguration}
          />
        ) : (
          <ComponentDetail
            readOnly={readOnly}
            hasPayAsYouGo={hasPayAsYouGo}
            task={task}
            onSelectConfig={handleSelectConfiguration}
            onSelectConfigWithRedirect={handleSelectConfigWithRedirect}
            patternComponents={patternComponents}
            allConfigurations={allConfigurations}
            allComponents={allComponents}
            folders={folders}
            isDevModeActive={isDevModeActive}
          />
        )}
      </Modal.Body>
    </FullScreenModal>
  );
};

const TaskDetailModalMemoized = React.memo(TaskDetailModal);

export default TaskDetailModalMemoized;
