import { cn, Icon, Link } from '@keboola/design';

const MarketplacePortalLink = (props: { href: string; className?: string }) => {
  return (
    <Link href={props.href} className={cn('btn btn-success btn-block', props.className)}>
      Manage in Azure Portal
      <Icon icon="arrow-up-right-from-square" className="f-14 icon-addon-left" />
    </Link>
  );
};

export default MarketplacePortalLink;
