import type { ComponentProps, ReactNode } from 'react';

import { Icon, type TooltipProps } from '@keboola/design';

import { ButtonInline } from '../Button/ButtonInline';

import { Clipboard } from './Clipboard';

export const ClipboardInline = ({
  text,
  label = 'Copy to Clipboard',
  className,
  icon = 'copy',
  buttonClassName,
  tooltipType,
  tooltipText = 'Click to copy',
  tooltipPlacement,
}: {
  text?: string;
  label?: ReactNode;
  className?: string;
  icon?: ComponentProps<typeof Icon>['icon'] | null;
  buttonClassName?: ComponentProps<typeof ButtonInline>['className'];
  tooltipType?: TooltipProps['type'];
  tooltipText?: TooltipProps['tooltip'];
  tooltipPlacement?: TooltipProps['placement'];
}) => {
  return (
    <Clipboard
      text={text}
      className={className}
      tooltipType={tooltipType}
      tooltipText={tooltipText}
      tooltipPlacement={tooltipPlacement}
    >
      <ButtonInline
        disabled={!text}
        className={buttonClassName}
        variant={buttonClassName ? 'custom' : 'link'}
      >
        {icon && <Icon icon={icon} />}
        {label}
      </ButtonInline>
    </Clipboard>
  );
};
