import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { Map } from 'immutable';

import { KEBOOLA_EX_GOOGLE_ANALYTICS_V_4 } from '@/constants/componentIds';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { prepareProfilesData } from '@/modules/ex-google-analytics-v4/helpers';
import { parseSavedProfiles, usingTwoVersionOfApi } from '@/modules/google-utils/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import nextTick from '@/utils/nextTick';
import Help from './Help';
import SyncAccountsSelect from './SyncAccountsSelect';

class ProfilesManager extends Component {
  static propTypes = {
    config: PropTypes.instanceOf(Map).isRequired,
    oauthCredentials: PropTypes.instanceOf(Map).isRequired,
    readOnly: PropTypes.bool.isRequired,
  };

  syncAccountSelectRef = createRef();
  state = { profiles: Map(), isSaving: false };

  render() {
    return (
      <div className="flex-container align-top">
        <div className="fill-space pr-2">
          {this.renderProfileSelector()}
          {this.renderConfirmButton()}
        </div>
        {this.renderHelp()}
      </div>
    );
  }

  renderProfileSelector() {
    return (
      <SyncAccountsSelect
        ref={this.syncAccountSelectRef}
        componentId={KEBOOLA_EX_GOOGLE_ANALYTICS_V_4}
        oauthCredentials={this.props.oauthCredentials}
        saved={this.props.config.get('configuration', Map())}
        onChange={(profiles) =>
          this.setState({
            profiles: parseSavedProfiles(profiles),
          })
        }
        readOnly={this.props.readOnly}
      />
    );
  }

  renderHelp() {
    if (!this.state.isSaving && this.isSaveDisabled()) {
      return (
        <Help
          title="Just select some of the available profiles"
          text={
            <>
              <p>Select the profiles you&apos;d like to connect to your configuration.</p>
              {this.syncAccountSelectRef.current && (
                <p>
                  Not seeing your newest profiles?{' '}
                  <Button
                    bsStyle="link"
                    className="btn-link-inline"
                    onClick={() => this.syncAccountSelectRef.current.loadOptions()}
                    disabled={
                      this.state.isLoadingProfiles || this.state.isSaving || this.props.readOnly
                    }
                  >
                    Reload
                  </Button>{' '}
                  the list of profiles.
                </p>
              )}
            </>
          }
        />
      );
    }

    return (
      <Help
        down
        title="What will happen now?"
        text="You will be able to select your desired data case, then we start loading your data, it may take a few minutes for large ones. In the meantime you can load data from other sources."
      />
    );
  }

  renderConfirmButton() {
    return (
      <ConfirmButtons
        block
        saveLabel="Save Profiles"
        onSave={this.handleSave}
        isSaving={this.state.isSaving}
        isDisabled={this.isSaveDisabled()}
      />
    );
  }

  isSaveDisabled() {
    return (
      this.props.readOnly ||
      this.state.isSaving ||
      this.state.profiles.isEmpty() ||
      this.state.profiles.equals(this.getSavedProfiles()) ||
      usingTwoVersionOfApi(this.state.profiles)
    );
  }

  handleSave = () => {
    this.setState({ isSaving: true });

    return InstalledComponentsActionCreators.saveComponentConfigData(
      KEBOOLA_EX_GOOGLE_ANALYTICS_V_4,
      this.props.config.get('id'),
      prepareProfilesData(this.props.config.get('configuration'), this.state.profiles.toList()),
      'Update profiles',
    ).finally(() => {
      nextTick(() => this.setState({ profiles: this.getSavedProfiles(), isSaving: false }));
    });
  };

  getSavedProfiles() {
    return parseSavedProfiles(this.props.config.getIn(['configuration'], Map()));
  }
}
export default ProfilesManager;
