import { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import type Promise from 'bluebird';
import { fromJS, List, Map } from 'immutable';

import { HelpBlock } from '@keboola/design';

import { unhandledRequest } from '@/modules/components/DockerActionsApi';
import InstalledComponentsActions from '@/modules/components/InstalledComponentsActionCreators';
import Select from '@/react/common/Select';

type Props = {
  componentId: string;
  configId: string;
  tableName: string;
  onChange: (table: string, schema?: string) => Promise<void>;
  rootConfigData: Map<string, any>;
  localState: Map<string, any>;
  readOnly: boolean;
};

const DestinationTableSelector = ({
  componentId,
  configId,
  tableName,
  onChange,
  rootConfigData,
  localState,
  readOnly,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const loadTablesInfo = useCallback(() => {
    setIsLoading(true);
    unhandledRequest(componentId, 'getTablesInfo', {
      configData: { parameters: { db: rootConfigData.getIn(['parameters', 'db'], Map()).toJS() } },
    })
      .then(({ tables }) => {
        return InstalledComponentsActions.updateLocalState(
          componentId,
          configId,
          localState.set(
            'destinationTables',
            fromJS(tables || [])
              .map((table: Map<string, any>) => table.get('name'))
              .sort()
              .map((table: string) => ({ label: table, value: table })),
          ),
        );
      })
      .finally(() => setIsLoading(false));
  }, [componentId, configId, localState, rootConfigData]);

  useEffect(() => {
    if (!readOnly && !isLoading && localState.get('destinationTables', List()).isEmpty()) {
      loadTablesInfo();
    }
  }, [readOnly, isLoading, localState, loadTablesInfo]);

  return (
    <>
      <Select
        allowCreate
        clearable={false}
        promptTextCreator={(label) => `Set "${label}" as destination table`}
        options={localState.get('destinationTables', List()).toArray()}
        placeholder="Select table"
        value={tableName}
        onChange={(table: string) => {
          setIsSaving(true);
          onChange(table).finally(() => setIsSaving(false));
        }}
        disabled={readOnly}
        isLoading={isLoading || isSaving}
      />
      <HelpBlock className="tw-mt-1">
        Not seeing your newest tables?{' '}
        <Button bsStyle="link" className="btn-link-inline" onClick={loadTablesInfo}>
          Reload
        </Button>{' '}
        the list of tables.
      </HelpBlock>
    </>
  );
};

export default DestinationTableSelector;
