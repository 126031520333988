import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import { Badge, Icon, Tooltip } from '@keboola/design';

import Loader from '@/react/common/Loader';
import MultiActionsSelectCheckbox from '@/react/common/MultiActionsSelectCheckbox';
import FileInputMappingModal from './FileInputMappingModal';
import { getFileLocationHint } from './helpers';

const FileInputMappingRow = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    isSelected: PropTypes.bool.isRequired,
    toggleSelection: PropTypes.func.isRequired,
    componentId: PropTypes.string.isRequired,
  },

  getInitialState() {
    return {
      isDeleting: false,
    };
  },

  render() {
    return (
      <span className="tr hoverable-actions">
        {this.renderColumns()}
        {this.renderActionButtons()}
      </span>
    );
  },

  renderColumns() {
    return (
      <>
        {!this.props.readOnly && (
          <span className="td pr-0">
            <div className="tw-flex tw-h-full tw-items-center">
              <MultiActionsSelectCheckbox
                isChecked={this.props.isSelected}
                isDisabled={this.state.isDeleting}
                onToggle={this.props.toggleSelection}
                entity="mapping"
              />
            </div>
          </span>
        )}
        <span className="td col-xs-5">
          {this.renderTags()}
          {this.props.value.get('query', '') !== '' && (
            <>
              {' '}
              <code>{this.props.value.get('query')}</code>
            </>
          )}
        </span>
        <span className="td col-xs-1 text-center">{this.renderRightArrow()}</span>
        <span className="td col-xs-5">{`${getFileLocationHint(
          this.props.componentId,
        )}/files/*`}</span>
      </>
    );
  },

  renderActionButtons() {
    if (this.props.readOnly) {
      return <span className="td pl-0 pr-1 no-wrap" />;
    }

    return (
      <span className="td pl-0 pr-1 no-wrap">
        <FileInputMappingModal mapping={this.props.value} onSave={this.props.onSave} />
        <Tooltip placement="top" tooltip="Delete Input">
          <Button
            bsStyle="link"
            className="text-muted"
            onClick={() => {
              this.setState({ isDeleting: true });
              this.props.onDelete().finally(() => this.setState({ isDeleting: false }));
            }}
            disabled={this.state.isDeleting}
          >
            {this.state.isDeleting ? <Loader /> : <Icon icon="trash" fixedWidth />}
          </Button>
        </Tooltip>
      </span>
    );
  },

  renderTags() {
    const tags = !this.props.value.get('tags', List()).isEmpty()
      ? this.props.value.get('tags', List())
      : this.props.value.getIn(['source', 'tags'], List()).map((tag) => tag.get('name'));

    if (tags.isEmpty()) {
      return null;
    }

    return tags.map((tag) => <Badge key={tag} text={tag} asTag placement="right" />).toArray();
  },

  renderRightArrow() {
    const withProcessedTags = this.props.value.get('processed_tags', List()).count() > 0;

    if (!withProcessedTags) {
      return <Icon icon={['far', 'chevron-right']} className="text-muted" />;
    }

    return (
      <Tooltip placement="top" type="explanatory" tooltip="Processed Tags">
        <Icon icon={['far', 'chevron-right']} className="text-muted" />
      </Tooltip>
    );
  },
});

export default FileInputMappingRow;
