import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqualImmutable } from 'react-immutable-render-mixin';
import {
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import _ from 'underscore';

import { CONFIGURATIONS_SORT_BY } from '@/constants/localStorageKeys';
import dayjs from '@/date';
import ConfigurationRow from '@/react/common/ConfigurationsTable/ConfigurationRow';
import useMatchMedia from '@/react/hooks/useMatchMedia';
import { getItem } from '@/utils/localStorage';
import AddNewConfigButton from './AddNewConfigButton';
import { DEFAULT_FOLDER_NAME } from './constants';
import EmptyRow from './EmptyRow';
import FolderRow from './FolderRow';
import PaddingRow from './PaddingRow';
import TableHead from './TableHead';

const VIRTUALIZATION_LIMIT = 100;
const HIDDEN_COLUMNS_WIDTH_LIMIT = 1281;

const getTableInitialState = (
  data,
  supportFolders,
  expandedFolders,
  sortByKey,
  columnsHiddenForSmallerScreens,
) => {
  let expanded = {};

  if (supportFolders) {
    expanded = data.reduce(
      (all, row) => {
        return { [row.data]: expandedFolders.get(row.data, false), ...all };
      },
      { [DEFAULT_FOLDER_NAME]: true },
    );
  }

  const [id, order] = getItem(`${CONFIGURATIONS_SORT_BY}-${sortByKey}`, 'last_change:asc').split(
    ':',
  );

  return {
    sorting: [{ id, desc: order === 'desc' }],
    expanded,
    ...(window.innerWidth < HIDDEN_COLUMNS_WIDTH_LIMIT && {
      columnVisibility: columnsHiddenForSmallerScreens.reduce((visibility, column) => {
        return { ...visibility, [column]: false };
      }, {}),
    }),
  };
};

const Table = ({
  columns,
  data,
  component,
  allComponents,
  readOnly,
  hasNewQueue,
  hasFlows,
  showMigrations,
  allowCreateConfig,
  customClasses,
  entity,
  forceShowAll,
  configurations,
  expandedFolders,
  supportFolders,
  columnsHiddenForSmallerScreens,
  isLoading,
}) => {
  const sortByKey = component?.get('id') || entity;

  const initialState = useMemo(() => {
    return getTableInitialState(
      data,
      supportFolders,
      expandedFolders,
      sortByKey,
      columnsHiddenForSmallerScreens,
    );
  }, [data, supportFolders, expandedFolders, sortByKey, columnsHiddenForSmallerScreens]);

  const [sorting, setSorting] = useState(initialState.sorting);

  const isInFolder = (row) => row.depth === 1 && row.parentId !== DEFAULT_FOLDER_NAME;

  const sortByName = useCallback(
    (rowA, rowB, columnId) => {
      if (supportFolders && rowA.depth === 0 && rowB.depth === 0) {
        const isDataDesc = sorting.some((sort) => sort.id === columnId && sort.desc);

        if (
          rowA.original.data === DEFAULT_FOLDER_NAME &&
          rowB.original.data !== DEFAULT_FOLDER_NAME
        ) {
          return isDataDesc ? -1 : 1;
        }

        if (
          rowA.original.data !== DEFAULT_FOLDER_NAME &&
          rowB.original.data === DEFAULT_FOLDER_NAME
        ) {
          return isDataDesc ? 1 : -1;
        }

        if (columnId !== 'data') {
          return 0;
        }

        return rowA.original.data.trim().localeCompare(rowB.original.data.trim());
      }

      if (
        rowA.original.data.config?.get('isCreating') ||
        rowB.original.data.config?.get('isCreating')
      ) {
        return (
          rowB.original.data.config?.get('isCreating') &&
          dayjs(rowA.original.data.config?.getIn(['currentVersion', 'created'])).isBefore(
            dayjs(rowB.original.data.config?.getIn(['currentVersion', 'created'])),
          )
        );
      }

      return rowA.original.data.config
        .get('name')
        .trim()
        .localeCompare(rowB.original.data.config.get('name').trim());
    },
    [sorting, supportFolders],
  );

  const sortByLastChange = useCallback(
    (rowA, rowB) => {
      if (supportFolders && rowA.depth === 0 && rowB.depth === 0) {
        return sortByName(rowA, rowB, 'last_change');
      }

      const valueA = new Date(
        rowA.original.data.config.getIn(['currentVersion', 'created']),
      ).valueOf();
      const valueB = new Date(
        rowB.original.data.config.getIn(['currentVersion', 'created']),
      ).valueOf();

      if (valueA < valueB) return 1;
      if (valueA > valueB) return -1;
      return 0;
    },
    [supportFolders, sortByName],
  );

  const sortByLastUse = useCallback(
    (rowA, rowB) => {
      if (supportFolders && rowA.depth === 0 && rowB.depth === 0) {
        return sortByName(rowA, rowB, 'job');
      }

      const valueA = new Date(rowA.original.job.get('createdTime')).valueOf();
      const valueB = new Date(rowB.original.job.get('createdTime')).valueOf();
      if (valueA < valueB || (_.isNaN(valueA) && !_.isNaN(valueB))) return 1;
      if (valueA > valueB || (_.isNaN(valueB) && !_.isNaN(valueA))) return -1;
      return 0;
    },
    [supportFolders, sortByName],
  );

  const tableInstance = useReactTable({
    columns,
    data,
    state: { sorting },
    initialState,
    getRowId: (row) => row.data?.id ?? row.data,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    enableSortingRemoval: false,
    sortingFns: { sortByName, sortByLastChange, sortByLastUse },
  });

  useEffect(() => {
    const allExpanded = tableInstance.getIsAllRowsExpanded();

    if (forceShowAll && !allExpanded) {
      tableInstance.toggleAllRowsExpanded(true);
    }

    if (!forceShowAll && allExpanded) {
      tableInstance.resetExpanded();
    }
  }, [tableInstance, forceShowAll]);

  const rows = tableInstance.getRowModel().rows;

  const matchMediaHandler = useCallback(
    ({ matches }) => {
      return tableInstance
        .getAllColumns()
        .filter((column) => columnsHiddenForSmallerScreens.includes(column.id))
        .forEach((column) => column.toggleVisibility(!!matches));
    },
    [columnsHiddenForSmallerScreens, tableInstance],
  );

  useMatchMedia(`(min-width: ${HIDDEN_COLUMNS_WIDTH_LIMIT}px)`, matchMediaHandler);

  const renderHeader = () => {
    return (
      <>
        <TableHead
          tableInstance={tableInstance}
          sortByKey={sortByKey}
          allowCreateConfig={allowCreateConfig}
          customClasses={customClasses}
          readOnly={readOnly}
        />
        {allowCreateConfig && (
          <AddNewConfigButton
            allComponents={allComponents}
            component={component}
            readOnly={readOnly}
            showMigrations={showMigrations}
            hasConfigurations={!configurations.isEmpty()}
            hasNewQueue={hasNewQueue}
            hasFlows={hasFlows}
          />
        )}
      </>
    );
  };

  const renderRow = (row, index) => {
    if (supportFolders && row.depth === 0) {
      return (
        <FolderRow
          key={row.id}
          row={row}
          isFirst={index === 0}
          entity={entity}
          customClasses={customClasses}
        />
      );
    }

    return (
      <ConfigurationRow
        key={row.id}
        row={row}
        inFolder={isInFolder(row)}
        customClasses={customClasses}
        readOnly={readOnly}
        hasFlows={hasFlows}
      />
    );
  };

  if (rows.length > VIRTUALIZATION_LIMIT) {
    return (
      <VirtualizedTable
        renderHeader={renderHeader}
        renderRow={renderRow}
        tableInstance={tableInstance}
      />
    );
  }

  return (
    <>
      {renderHeader()}
      <div className="table table-hover react-table">
        <div className="tbody">
          {rows.length === 0 ? (
            <EmptyRow isLoading={isLoading} entity={entity} />
          ) : (
            rows.map(renderRow)
          )}
        </div>
      </div>
    </>
  );
};

const VirtualizedTable = ({ renderHeader, renderRow, tableInstance }) => {
  const tableContainerRef = useRef();
  const rowModel = tableInstance.getRowModel();
  const rowVirtualizer = useVirtualizer({
    count: rowModel.rows.length,
    getScrollElement: () => tableContainerRef.current,
    estimateSize: () => 58,
    overscan: 5,
  });
  const virtualizedRows = rowVirtualizer.getVirtualItems();
  const [paddingBefore, paddingAfter] = [
    virtualizedRows[0]?.start || 0,
    rowVirtualizer.getTotalSize() - virtualizedRows[virtualizedRows.length - 1]?.end || 0,
  ];

  return (
    <div
      ref={tableContainerRef}
      className="tw-h-[72vh] tw-min-h-[48rem] tw-overflow-y-auto [overflow-anchor:none]"
    >
      {renderHeader()}
      <div className="table table-hover react-table">
        <div className="tbody">
          <PaddingRow height={paddingBefore} />
          {virtualizedRows.map((virtualRow) => {
            return renderRow(rowModel.rows[virtualRow.index], virtualRow.index);
          })}
          <PaddingRow height={paddingAfter} />
        </div>
      </div>
    </div>
  );
};

/** @type {any} */
const MemoizedTable = memo(Table, shallowEqualImmutable);

export default MemoizedTable;
