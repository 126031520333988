import { ButtonLink, Icon } from '@keboola/design';

type Props = {
  className?: string;
  url: string;
  disabled?: boolean;
  isLoading?: boolean;
};

export const LinkLoginButton = ({ className, url, disabled, isLoading }: Props) => {
  return (
    <ButtonLink className={className} disabled={disabled || isLoading}>
      <a href={url} target="_blank" rel="noreferrer">
        {isLoading ? (
          <Icon
            spin={isLoading}
            icon="spinner"
            className="tw-inline-block tw-h-4 tw-w-4 tw-align-middle"
          />
        ) : (
          <Icon icon="circle-play" />
        )}
        Connect
      </a>
    </ButtonLink>
  );
};
