import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { IconButton, Search, Tooltip } from '@keboola/design';

import { FILTERS, FILTERS_GROUP } from '@/constants';
import { canManageBuckets, canWriteBucket } from '@/modules/admin/privileges';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import {
  filterDevBranchBuckets,
  filterProductionAndCurrentDevBranchBuckets,
  filterProductionBuckets,
} from '@/modules/dev-branches/helpers';
import BetaTitle from '@/modules/lineage/BetaTitle';
import { constructFQID, FQID_TYPES } from '@/modules/lineage/fqid';
import TablesLineage from '@/modules/lineage/TablesLineage/TablesLineage';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import { factory as eventsFactory } from '@/modules/sapi-events/TableEventsService';
import StackFeaturesStore from '@/modules/stack-features/Store';
import { TabLink, TabNav } from '@/react/common';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import ColumnsPreview from './components/ColumnsPreview';
import DataSample from './components/DataSample';
import DataSampleColumnOrderInfo from './components/DataSampleColumnOrderInfo';
import DataSampleHelp from './components/DataSampleHelp';
import TableEvents from './components/Events';
import SearchContextDropdown from './components/SearchContextDropdown';
import Snapshots from './components/Snapshots';
import TableActions from './components/TableActions';
import TableGraph from './components/TableGraph';
import TableOverview from './components/TableOverview';
import TableSchema from './components/TableSchema';
import TableUsage from './components/TableUsage';
import { toggleContextFilter } from './actions';
import { routeNames, tableTabs } from './constants';
import { getTableAliases, getTableLinks } from './helpers';
import StorageStore from './store';

const Table = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      RoutesStore,
      StorageStore,
      ComponentsStore,
      StackFeaturesStore,
      InstalledComponentsStore,
      BucketsStore,
      TablesStore,
      SandboxesStore,
      DevBranchesStore,
    ),
  ],

  getStateFromStores() {
    const sapiToken = ApplicationStore.getSapiToken();
    const bucketId = RoutesStore.getCurrentRouteParam('bucketId');
    const tableName = RoutesStore.getCurrentRouteParam('tableName');
    const table = TablesStore.getTableByName(tableName, bucketId);
    const bucket = table.get('bucket', Map());
    const allBuckets = BucketsStore.getAll();
    const searchFilters = StorageStore.getSearchFilters();
    const contextFilter = StorageStore.getContextFilter();
    const isDevModeActive = DevBranchesStore.isDevModeActive();
    const availableBuckets = isDevModeActive
      ? filterProductionAndCurrentDevBranchBuckets(allBuckets)
      : contextFilter && searchFilters.get(FILTERS_GROUP.ENTITY) === FILTERS.DEV
        ? filterDevBranchBuckets(allBuckets)
        : filterProductionBuckets(allBuckets);
    const hasNewTransformationsOnly = ApplicationStore.hasNewTransformationsOnly();
    const hasShowTransformationMigration = ApplicationStore.hasShowTransformationMigration();

    return {
      sapiToken,
      bucket,
      table,
      bucketId,
      allBuckets,
      contextFilter,
      availableBuckets,
      isDevModeActive,
      activeTab: RoutesStore.getCurrentRouteParam('tableTab') || tableTabs.OVERVIEW,
      indexSearchFilters: searchFilters,
      allTables: TablesStore.getAll(),
      indexSearchQuery: StorageStore.getSearchQuery(),
      canWriteBucket: canWriteBucket(sapiToken, bucket),
      canManageBuckets: canManageBuckets(sapiToken),
      components: ComponentsStore.getAll(),
      configurations: InstalledComponentsStore.getAll(),
      urlTemplates: ApplicationStore.getUrlTemplates(),
      admins: ApplicationStore.getAdmins(),
      snapshots: TablesStore.getTableSnapshots(table.get('id')),
      updatingTable: TablesStore.getIsUpdatingTable(table.get('id')),
      settingAliasFilter: TablesStore.getIsSettingAliasFilter(table.get('id')),
      removingAliasFilter: TablesStore.getIsRemovingAliasFilter(table.get('id')),
      exportingTable: TablesStore.getIsExportingTable(table.get('id')),
      creatingFromSnapshot: TablesStore.getIsCreatingFromSnapshot(),
      deletingSnapshot: TablesStore.getIsDeletingSnapshot(),
      addingColumn: TablesStore.getAddingColumn(),
      deletingColumn: TablesStore.getDeletingColumn().get(table.get('id'), Map()),
      hasGraph: !hasNewTransformationsOnly,
      hasUsage: hasNewTransformationsOnly || hasShowTransformationMigration,
      hasShowTransformationMigration,
      hasFlows: ApplicationStore.hasFlows(),
      currentProjectId: ApplicationStore.getCurrentProjectId(),
      hasAiLineage: ApplicationStore.hasAiLineage(),
      currentOrganization: ApplicationStore.getCurrentOrganization(),
      currentProject: ApplicationStore.getCurrentProject(),
      hasAllowedAi: StackFeaturesStore.hasAllowedAi(),
    };
  },

  getInitialState() {
    return {
      showFullScreenDataSample: false,
      isLineageModalOpened: false,
      searchQuery: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
    };
  },

  render() {
    if (this.state.bucket.isEmpty()) {
      return <p>Bucket not found</p>;
    }

    if (this.state.table.isEmpty()) {
      return <p>Table not found</p>;
    }

    const tableAliases = getTableAliases(
      this.state.table,
      this.state.allTables,
      this.state.sapiToken,
    );
    const tableLinks = getTableLinks(
      this.state.table,
      this.state.allBuckets.get(this.state.bucketId, Map()),
    );

    return (
      <>
        <div className="tabs-with-border-wrapper flex-container flex-start align-top">
          <SearchContextDropdown
            activeBucket={this.state.bucket.get('id')}
            activeTable={this.state.table.get('id')}
            searchQuery={this.state.indexSearchQuery}
            searchFilters={this.state.indexSearchFilters}
            contextFilter={this.state.contextFilter}
            toggleContextFilter={toggleContextFilter}
            tables={this.state.allTables}
            buckets={this.state.availableBuckets}
            activeTab={this.state.activeTab}
          />
          <TabNav>
            {this.renderTabLink(tableTabs.OVERVIEW, 'Overview', 'ml-1')}
            {this.renderTabLink(tableTabs.SCHEMA, 'Schema')}
            {this.renderTabLink(tableTabs.EVENTS, 'Events')}
            {this.renderTabLink(tableTabs.DATA_SAMPLE, 'Data Sample')}
            {!this.state.table.get('isAlias') &&
              this.renderTabLink(tableTabs.SNAPSHOT_AND_RESTORE, 'Snapshots')}
            {this.state.hasGraph && this.renderTabLink(tableTabs.GRAPH, 'Graph')}
            {this.state.hasUsage && this.renderTabLink(tableTabs.USAGE, 'Usage')}
            {this.state.hasAiLineage &&
              this.state.hasAllowedAi &&
              this.state.hasFlows &&
              !this.state.isDevModeActive &&
              this.renderTabLink(tableTabs.LINEAGE, <BetaTitle>Data Lineage</BetaTitle>)}
          </TabNav>
          <div className="ml-auto flex-container flex-end">
            {!this.state.exportingTable && this.state.activeTab === tableTabs.DATA_SAMPLE && (
              <>
                <DataSampleColumnOrderInfo
                  tableId={this.state.table.get('id')}
                  onResetColumnsOrder={() => this.forceUpdate()}
                />
                <DataSampleHelp backend={this.state.bucket.get('backend')} />
                <Tooltip placement="left" tooltip="Expand data sample to full screen">
                  <IconButton
                    variant="inline"
                    size="small"
                    icon="up-right-and-down-left-from-center"
                    onClick={() => this.setState({ showFullScreenDataSample: true })}
                  />
                </Tooltip>
              </>
            )}
            <TableActions
              table={this.state.table}
              onDeleteTable={() => {
                RoutesStore.getRouter().transitionTo(routeNames.BUCKET, {
                  bucketId: this.state.bucket.get('id'),
                });
              }}
            />
          </div>
        </div>
        <div key={this.state.table.get('id')}>
          {this.renderTabContent(tableAliases, tableLinks)}
        </div>
      </>
    );
  },

  renderTabLink(tab, label, className) {
    return (
      <TabLink
        active={this.state.activeTab === tab}
        to={routeNames.TABLE}
        params={{
          bucketId: this.state.bucketId,
          tableName: this.state.table.get('name'),
          tableTab: tab,
        }}
        className={className}
      >
        {label}
      </TabLink>
    );
  },

  renderTabContent(tableAliases, tableLinks) {
    if (this.state.activeTab === tableTabs.SCHEMA) {
      return (
        <div className="tw-flex tw-flex-col tw-gap-6">
          <Search
            placeholder={this.getPlaceholder()}
            defaultValue={this.state.searchQuery}
            onChange={(searchQuery) => {
              this.setState({ searchQuery });
              RoutesStore.getRouter().updateQuery({ q: searchQuery });
            }}
          />
          <TableSchema
            sapiToken={this.state.sapiToken}
            tables={this.state.allTables}
            table={this.state.table}
            canWriteBucket={this.state.canWriteBucket}
            canManageBuckets={this.state.canManageBuckets}
            addingColumn={this.state.addingColumn}
            deletingColumn={this.state.deletingColumn}
            tableAliases={tableAliases}
            tableLinks={tableLinks}
            urlTemplates={this.state.urlTemplates}
            components={this.state.components}
            searchQuery={this.state.searchQuery}
          />
        </div>
      );
    }

    if (this.state.activeTab === tableTabs.EVENTS) {
      return (
        <TableEvents
          eventsFactory={eventsFactory(this.state.table.get('id'))}
          admins={this.state.admins}
        />
      );
    }

    if (this.state.activeTab === tableTabs.DATA_SAMPLE) {
      return (
        <DataSample
          backend={this.state.bucket.get('backend')}
          table={this.state.table}
          bucket={this.state.bucket}
          fullScreen={this.state.showFullScreenDataSample}
          closeFullScreen={() => this.setState({ showFullScreenDataSample: false })}
          onChangeColumnOrder={() => this.forceUpdate()}
        />
      );
    }

    if (this.state.activeTab === tableTabs.SNAPSHOT_AND_RESTORE) {
      return (
        <Snapshots
          snapshots={this.state.snapshots}
          sapiToken={this.state.sapiToken}
          admins={this.state.admins}
          buckets={this.state.allBuckets}
          tables={this.state.allTables}
          table={this.state.table}
          creatingFromSnapshot={this.state.creatingFromSnapshot}
          deletingSnapshot={this.state.deletingSnapshot}
        />
      );
    }

    if (this.state.hasGraph && this.state.activeTab === tableTabs.GRAPH) {
      return (
        <TableGraph
          key={this.state.table.get('lastImportDate') || tableTabs.GRAPH}
          table={this.state.table}
        />
      );
    }

    if (this.state.hasUsage && this.state.activeTab === tableTabs.USAGE) {
      return (
        <TableUsage
          admins={this.state.admins}
          tableId={this.state.table.get('id')}
          configurations={this.state.configurations}
          hasShowTransformationMigration={this.state.hasShowTransformationMigration}
          hasFlows={this.state.hasFlows}
        />
      );
    }

    if (this.state.activeTab === tableTabs.LINEAGE) {
      const startingPointFqid = constructFQID({
        type: FQID_TYPES.table,
        data: { projectId: this.state.currentProjectId, tableId: this.state.table.get('id') },
      });

      return (
        <TablesLineage
          isLocked={!this.state.hasAiLineage}
          allComponents={this.state.components}
          startingPointFqid={startingPointFqid}
          organizationName={this.state.currentOrganization.get('name', '')}
          nodeName={this.state.table.get('name', '')}
          projectName={this.state.currentProject.get('name', '')}
          projectId={`${this.state.currentProjectId}`}
          onOpenModal={() => this.setState({ isLineageModalOpened: true })}
          onCloseModal={
            this.state.isLineageModalOpened
              ? () => this.setState({ isLineageModalOpened: false })
              : null
          }
        />
      );
    }

    return (
      <>
        <TableOverview
          canManageBuckets={this.state.canManageBuckets}
          table={this.state.table}
          tables={this.state.allTables}
          components={this.state.components}
          configurations={this.state.configurations}
          tableAliases={tableAliases}
          tableLinks={tableLinks}
          sapiToken={this.state.sapiToken}
          urlTemplates={this.state.urlTemplates}
          settingAliasFilter={this.state.settingAliasFilter}
          removingAliasFilter={this.state.removingAliasFilter}
          updatingTable={this.state.updatingTable}
          canWriteBucket={this.state.canWriteBucket}
          hasFlows={this.state.hasFlows}
        />
        <ColumnsPreview
          key={this.state.table.get('lastChangeDate')}
          tableId={this.state.table.get('id')}
        />
      </>
    );
  },

  getPlaceholder() {
    return `Search columns (${this.state.table.get('columns').count()})`;
  },
});

export default Table;
