import { useState } from 'react';

import { Icon, Tooltip } from '@keboola/design';

const Protected = (props: { children: JSX.Element }) => {
  const [isProtected, setIsProtected] = useState(true);

  if (!isProtected) {
    return props.children;
  }

  return (
    <Tooltip placement="top" tooltip="Protected content, click to reveal." type="explanatory">
      <Icon icon="lock" fixedWidth className="clickable" onClick={() => setIsProtected(false)} />
    </Tooltip>
  );
};

export default Protected;
