import type Promise from 'bluebird';
import type { Map } from 'immutable';
import Switch from 'rc-switch';

import {
  Button,
  ButtonGroup,
  ButtonInline,
  ButtonLink,
  FormGroup,
  Label,
  Tooltip,
} from '@keboola/design';

import { disabledInBranch } from '@/modules/flows/constants';
import { routeNames as trashRouteNames } from '@/modules/trash/constants';
import { RouterLink } from '@/react/common/RouterLink';
import { simulateClickIfMiddleMouseIsUsed } from '@/utils/windowOpen';
import BackendSize from './BackendSize';
import LastRun from './LastRun';
import SelectSpecificRows from './SelectSpecificRows';

type Props = {
  task: Map<string, any>;
  lastJob?: Map<string, any>;
  readOnly: boolean;
  onEditTask: (id: string, field: string | string[], value: any) => void;
  onSelectConfigWithRedirect: (
    event?: React.MouseEvent | null,
    componentId?: string,
    config?: string,
  ) => Promise<any>;
  allConfigurations: Map<string, any>;
  showBackendSize: boolean;
  hasSnowflakeDynamicBackendSize: boolean;
  hasJobsDynamicBackendSize: boolean;
  isDevModeActive: boolean;
  onSetTaskParams: (taskId: string) => void;
  onDeleteTask: () => void;
  onBack: () => void;
};

const ConfigurationDetail = ({
  task,
  lastJob,
  readOnly,
  onEditTask,
  onSelectConfigWithRedirect,
  allConfigurations,
  showBackendSize,
  hasSnowflakeDynamicBackendSize,
  hasJobsDynamicBackendSize,
  isDevModeActive,
  onSetTaskParams,
  onDeleteTask,
  onBack,
}: Props) => {
  const componentId = task.get('componentId');
  const isDisabledInBranch = isDevModeActive && disabledInBranch.includes(componentId);

  return (
    <div className="tw-flex tw-grow tw-flex-col tw-gap-6 tw-overflow-hidden tw-px-2">
      <div className="tw-flex tw-flex-col tw-gap-4">
        <ButtonGroup variant="block" className="tw-whitespace-nowrap">
          {task.get('hasConfigurationInTrash') ? (
            <ButtonLink variant="secondary">
              <RouterLink to={trashRouteNames.SETTINGS_TRASH} query={{ q: task.get('configId') }}>
                Deleted Configuration
              </RouterLink>
            </ButtonLink>
          ) : (
            <Tooltip
              placement="top"
              type="explanatory"
              forceHide={!isDisabledInBranch}
              tooltip="This component is disabled in branches."
              triggerClassName="tw-w-full"
            >
              <Button
                disabled={isDisabledInBranch}
                onClick={(event: React.MouseEvent) => {
                  if (!isDisabledInBranch) {
                    onSelectConfigWithRedirect(event);
                  }
                }}
                onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
                onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
              >
                {readOnly ? 'Show' : 'Edit'} Configuration
              </Button>
            </Tooltip>
          )}
          <Button variant="outline" onClick={onBack}>
            Replace configuration
          </Button>
        </ButtonGroup>
        <SelectSpecificRows task={task} readOnly={readOnly} onEditTask={onEditTask} />
      </div>
      <hr className="tw-m-0" />
      <div className="tw-flex tw-flex-col tw-gap-4">
        <FormGroup className="tw-flex-row tw-items-center tw-justify-between">
          <Label
            htmlFor="component-enabled"
            className="tw-font-medium tw-text-neutral-400"
            onClick={() =>
              !readOnly && onEditTask(task.get('id'), 'enabled', !task.get('enabled', true))
            }
          >
            Component Enabled
          </Label>
          <Switch
            id="component-enabled"
            prefixCls="switch"
            className="wider no-margin"
            disabled={readOnly}
            checked={task.get('enabled', true)}
            onChange={(checked) => onEditTask(task.get('id'), 'enabled', checked)}
          />
        </FormGroup>
        <FormGroup className="tw-flex-row tw-items-center tw-justify-between">
          <Label
            htmlFor="continue-on-failure"
            className="tw-font-medium tw-text-neutral-400"
            onClick={() =>
              !readOnly &&
              onEditTask(task.get('id'), 'continueOnFailure', !task.get('continueOnFailure', false))
            }
          >
            Continue on Failure
          </Label>
          <Switch
            id="continue-on-failure"
            prefixCls="switch"
            className="wider no-margin"
            disabled={readOnly}
            checked={task.get('continueOnFailure', false)}
            onChange={(checked) => onEditTask(task.get('id'), 'continueOnFailure', checked)}
          />
        </FormGroup>
        <BackendSize
          task={task}
          readOnly={readOnly}
          onEditTask={onEditTask}
          allConfigurations={allConfigurations}
          showBackendSize={showBackendSize}
          hasSnowflakeDynamicBackendSize={hasSnowflakeDynamicBackendSize}
          hasJobsDynamicBackendSize={hasJobsDynamicBackendSize}
        />
        <LastRun task={task} lastJob={lastJob} />
      </div>
      {(!!task.get('configId') || task.get('invalid')) && (
        <FormGroup className="flex-container justify-center mb-0">
          <ButtonInline onClick={() => onSetTaskParams(task.get('id'))}>
            {readOnly ? 'Show' : 'Set'} advanced parameters
          </ButtonInline>
        </FormGroup>
      )}
      <hr className="tw-m-0" />
      <ButtonInline variant="dark" onClick={onDeleteTask} className="tw-self-center">
        Remove task
      </ButtonInline>
    </div>
  );
};

export default ConfigurationDetail;
