import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { FormGroup, Label } from '@keboola/design';

import InputValidation from '@/react/common/InputValidation';

const TargetDatasetSection = createReactClass({
  propTypes: {
    value: PropTypes.shape({
      dataset: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    const { value, onChange, disabled } = this.props;

    return (
      <div className="form-horizontal">
        <InputValidation
          value={value.dataset}
          customValidation={(value) => {
            if (/^[a-zA-Z0-9_]*$/.test(value)) {
              return null;
            }

            return 'Dataset name can contain only letters, numbers, and underscores.';
          }}
        >
          {(inputState, errMsg) => {
            const state = inputState === 'error' ? 'error' : 'default';

            const messages = {
              default: 'Google BigQuery dataset name.',
              error: errMsg,
            };

            return (
              <FormGroup state={state} className="tw-grid tw-grid-cols-3">
                <Label htmlFor="dataset">Dataset</Label>

                <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
                  <FormGroup.TextInput
                    id="dataset"
                    variant="secondary"
                    placeholder="my_dataset"
                    disabled={disabled}
                    onChange={(value) => onChange({ dataset: value })}
                    value={value.dataset}
                  />

                  <FormGroup.Help>{messages[state]}</FormGroup.Help>
                </div>
              </FormGroup>
            );
          }}
        </InputValidation>
      </div>
    );
  },
});

export default TargetDatasetSection;
