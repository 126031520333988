import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import { prepareFoldersFromMetadata } from '@/modules/components/helpers';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { mergeSampleDataToConfigurations } from '@/modules/components-directory/helpers';
import ComponentsReloaderButton from '@/modules/orchestrations/react/components/ComponentsReloaderButton';
import OrchestrationStore from '@/modules/orchestrations/stores/OrchestrationsStore';
import ModalIcon from '@/react/common/ModalIcon';
import SearchBar from '@/react/common/SearchBar';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { matchByWords } from '@/utils';
import ComponentSelect from './ComponentSelect';
import ConfigurationSelect from './ConfigurationSelect';
import OrchestrationSelect from './OrchestrationSelect';

const STEP_COMPONENT_SELECT = 'componentSelect';
const STEP_CONFIGURATION_SELECT = 'configurationSelect';
const STEP_ORCHESTRATOR_CONFIGURATION_SELECT = 'orchestratorConfigurationSelect';

const AddTaskModal = createReactClass({
  propTypes: {
    onConfigurationSelect: PropTypes.func.isRequired,
    onChangeSearchQuery: PropTypes.func.isRequired,
    onHide: PropTypes.func,
    show: PropTypes.bool,
    phaseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    phaseName: PropTypes.string,
    searchQuery: PropTypes.string,
  },

  mixins: [
    createStoreMixin(
      InstalledComponentsStore,
      ComponentsStore,
      OrchestrationStore,
      ApplicationStore,
    ),
    immutableMixin,
  ],

  getInitialState() {
    return {
      selectedComponent: null,
      currentStep: STEP_COMPONENT_SELECT,
    };
  },

  getStateFromStores() {
    const legacyOrchestrationId = RoutesStore.getCurrentRouteIntParam('orchestrationId');
    const orchestrationId = RoutesStore.getCurrentRouteParam('config');

    return {
      orchestrationId,
      allComponents: ComponentsStore.getAll(),
      folders: prepareFoldersFromMetadata(InstalledComponentsStore.getAllMetadata()),
      installedComponents: InstalledComponentsStore.getAll().filter(
        (component) => !component.get('flags', List()).includes('excludeRun'),
      ),
      legacyOrchestrations: OrchestrationStore.getAll().filter((orchestration) => {
        return (
          !orchestration.get('crontabRecord') && legacyOrchestrationId !== orchestration.get('id')
        );
      }),
      hasFlows: ApplicationStore.hasFlows(),
    };
  },

  _getFilteredComponents() {
    return mergeSampleDataToConfigurations(
      this.state.installedComponents,
      this.state.allComponents,
    ).filter((component) => {
      return matchByWords([component.get('name'), component.get('id')], this.props.searchQuery);
    });
  },

  _getFilteredLegacyOrchestrations() {
    return this.state.legacyOrchestrations.filter(() =>
      matchByWords('orchestrator', this.props.searchQuery),
    );
  },

  _handleOnHide() {
    this._handleComponentReset();
    return this.props.onHide();
  },

  render() {
    return (
      <Modal onHide={this._handleOnHide} show={this.props.show}>
        <Modal.Header closeButton>
          <Modal.Title>
            Add New Task to {this.props.phaseName || this.props.phaseId}{' '}
            <ComponentsReloaderButton />
          </Modal.Title>
          <ModalIcon.Plus />
        </Modal.Header>
        <Modal.Body>
          <SearchBar
            bordered
            query={this.props.searchQuery}
            onChange={this.props.onChangeSearchQuery}
            placeholder={this.searchBarPlaceholder()}
            inputRef={(input) => (this.searchInput = input)}
          />
          {this._renderSelect()}
        </Modal.Body>
      </Modal>
    );
  },

  _renderSelect() {
    switch (this.state.currentStep) {
      case STEP_COMPONENT_SELECT:
        return (
          <ComponentSelect
            legacyOrchestrations={this._getFilteredLegacyOrchestrations()}
            components={this._getFilteredComponents()}
            onComponentSelect={this._handleComponentSelect}
            hasFlows={this.state.hasFlows}
          />
        );

      case STEP_CONFIGURATION_SELECT:
        return (
          <ConfigurationSelect
            folders={this.state.folders}
            component={this.state.selectedComponent}
            orchestrationId={this.state.orchestrationId}
            onReset={this._handleComponentReset}
            onConfigurationSelect={this._handleConfigurationSelect}
            query={this.props.searchQuery}
          />
        );

      case STEP_ORCHESTRATOR_CONFIGURATION_SELECT:
        return (
          <OrchestrationSelect
            component={this.state.selectedComponent}
            orchestrations={this.state.legacyOrchestrations}
            onReset={this._handleComponentReset}
            onConfigurationSelect={this._handleConfigurationSelect}
            orchestratorConfigurations={this.state.installedComponents.getIn([
              'orchestrator',
              'configurations',
            ])}
            query={this.props.searchQuery}
            hasFlows={this.state.hasFlows}
          />
        );

      default:
        return null;
    }
  },

  searchBarPlaceholder() {
    switch (this.state.currentStep) {
      case STEP_COMPONENT_SELECT:
        return 'Search component';

      case STEP_CONFIGURATION_SELECT:
        return 'Search configuration';

      case STEP_ORCHESTRATOR_CONFIGURATION_SELECT:
        return `Search ${this.state.hasFlows ? 'flow' : 'orchestration'}`;
    }
  },

  _handleComponentSelect(component) {
    return this.setState(
      {
        selectedComponent: component,
        currentStep:
          component.get('id') === 'orchestrator'
            ? STEP_ORCHESTRATOR_CONFIGURATION_SELECT
            : STEP_CONFIGURATION_SELECT,
      },
      this._clearSearchAndFocus,
    );
  },

  _handleComponentReset() {
    return this.setState(
      { selectedComponent: null, currentStep: STEP_COMPONENT_SELECT },
      this._clearSearchAndFocus,
    );
  },

  _clearSearchAndFocus() {
    this.props.onChangeSearchQuery('');
    this.searchInput.focus();
  },

  _handleConfigurationSelect(configuration) {
    this.props.onConfigurationSelect(
      this.state.selectedComponent,
      configuration,
      this.props.phaseId,
    );
    return this._handleOnHide();
  },
});

export default AddTaskModal;
