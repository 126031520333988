import { createElement } from 'react';
import type { ComponentProps } from 'react';

import type { Icon } from '@keboola/design';

import { CircleIcon, type IconColor } from './CircleIcon';

type Props = {
  icon?: ComponentProps<typeof Icon>['icon'];
  iconUrl?: string;
  color?: IconColor;
  bold?: boolean;
  backgroundColor?: string;
};

const ModalIcon = ({ icon, iconUrl, color, bold = false, backgroundColor }: Props) => {
  return (
    <CircleIcon
      className="modal-icon"
      icon={icon}
      iconUrl={iconUrl}
      color={color}
      bold={bold}
      backgroundColor={backgroundColor}
      bigger
    />
  );
};

ModalIcon.Clock = () => createElement(ModalIcon, { color: 'green', icon: 'clock', bold: true });
ModalIcon.Code = () => createElement(ModalIcon, { color: 'blue', icon: 'code', bold: true });
ModalIcon.Edit = () => createElement(ModalIcon, { color: 'green', icon: 'pen', bold: true });
ModalIcon.Play = () => createElement(ModalIcon, { color: 'green', icon: 'play', bold: true });
ModalIcon.Plus = () => createElement(ModalIcon, { color: 'green', icon: 'plus', bold: true });
ModalIcon.Clone = () => createElement(ModalIcon, { color: 'green', icon: 'clone', bold: true });
ModalIcon.Reply = () => createElement(ModalIcon, { color: 'green', icon: 'reply', bold: true });
ModalIcon.Trash = () => createElement(ModalIcon, { color: 'red', icon: 'trash', bold: true });
ModalIcon.Upload = () => createElement(ModalIcon, { color: 'green', icon: 'upload', bold: true });
ModalIcon.Database = () =>
  createElement(ModalIcon, { color: 'green', icon: 'database', bold: true });

export default ModalIcon;
