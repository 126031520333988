import PropTypes from 'prop-types';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import { getCurrentBranchTableWithProductionFallback } from '@/modules/storage/helpers';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import Select from '@/react/common/Select';
import whereOperatorConstants from '@/react/common/whereOperatorConstants';
import { getColumnsOptions } from './helpers';

const DataFilterRow = createReactClass({
  propTypes: {
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    allTables: PropTypes.object.isRequired,
    componentId: PropTypes.string,
    groupClassName: PropTypes.string,
    labelClassName: PropTypes.string,
    whereColumnClassName: PropTypes.string,
    formType: PropTypes.oneOf(['normal', 'horizontal']),
  },

  getDefaultProps() {
    return {
      groupClassName: 'form-group',
      labelClassName: 'col-xs-2 control-label',
      whereColumnClassName: 'col-xs-4',
      formType: 'horizontal',
    };
  },

  render() {
    const isDisabled =
      this.props.disabled ||
      !this.props.value.get('source') ||
      !this.props.value.get('where_column');

    if (this.props.formType === 'horizontal') {
      return (
        <div className={this.props.groupClassName}>
          <div className={this.props.labelClassName}>Data Filter</div>
          <div className={this.props.whereColumnClassName}>{this.renderWhereColumn()}</div>
          <div className="col-xs-2">{this.renderWhereOperator(isDisabled)}</div>
          <div className="col-xs-4">{this.renderWhereValues(isDisabled)}</div>
        </div>
      );
    }

    return (
      <FormGroup>
        <ControlLabel>
          Data Filter <OptionalFormLabel />
        </ControlLabel>
        <div className="select-group">
          {this.renderWhereColumn()}
          {this.renderWhereOperator(isDisabled)}
        </div>
        {this.renderWhereValues(isDisabled)}
      </FormGroup>
    );
  },

  renderWhereColumn() {
    return (
      <Select
        ariaLabel="Select a column"
        name="where_column"
        placeholder="Select a column"
        value={this.props.value.get('where_column')}
        disabled={this.props.disabled || !this.props.value.get('source')}
        onChange={this._handleChangeWhereColumn}
        options={this._getColumnsOptions()}
      />
    );
  },

  renderWhereOperator(isDisabled) {
    return (
      <Select
        ariaLabel="Select a operator"
        clearable={false}
        searchable={false}
        name="where_operator"
        value={this.props.value.get('where_operator') || 'eq'}
        disabled={isDisabled}
        options={[
          { label: whereOperatorConstants.EQ_LABEL, value: whereOperatorConstants.EQ_VALUE },
          {
            label: whereOperatorConstants.NOT_EQ_LABEL,
            value: whereOperatorConstants.NOT_EQ_VALUE,
          },
        ]}
        onChange={this._handleChangeWhereOperator}
      />
    );
  },

  renderWhereValues(isDisabled) {
    return (
      <Select
        ariaLabel="Add a value"
        multi
        allowCreate
        emptyStrings
        name="whereValues"
        placeholder="Add a value"
        value={this.props.value.get('where_values')}
        onChange={this._handleChangeWhereValues}
        disabled={isDisabled}
      />
    );
  },

  _getColumnsOptions() {
    const columns = getCurrentBranchTableWithProductionFallback(
      this.props.allTables,
      this.props.value.get('source'),
    ).get('columns', List());

    return getColumnsOptions(columns, this.props.componentId);
  },

  _handleChangeWhereValues(newValue) {
    const value = this.props.value.set('where_values', newValue);
    this.props.onChange(value);
  },

  _handleChangeWhereOperator(selected) {
    const value = this.props.value.set('where_operator', selected);
    this.props.onChange(value);
  },

  _handleChangeWhereColumn(string) {
    let value = this.props.value.set('where_column', string);

    if (!string) {
      value = value.set('where_values', List());
    }

    this.props.onChange(value);
  },
});

export default DataFilterRow;
