import type { ReactNode } from 'react';
import type { List } from 'immutable';

import { Search } from '@keboola/design';

import type { Sliceable } from '@/react/common/LazyList';
import LazyList from '@/react/common/LazyList';
import NoResultsFound from '@/react/common/NoResultsFound';

type Props = {
  query: string;
  onSearchChange: (query: string) => void;
  renderRows: () => ReactNode;
  filteredVersions: List<any>;
  versions: List<any>;
};

const VersionsTable = ({
  query,
  onSearchChange,
  filteredVersions,
  renderRows,
  versions,
}: Props) => {
  const noRows = filteredVersions.count() === 0 && versions.count() > 0;

  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <Search defaultValue={query} onChange={onSearchChange} />
      {noRows ? (
        <NoResultsFound entityName="versions" />
      ) : (
        <div className="box">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="w-50" />
                <th>#</th>
                <th className="w-280 text-right">Created</th>
                <th className="w-200"></th>
              </tr>
            </thead>

            <LazyList
              rootElement="tbody"
              items={filteredVersions as Sliceable<any>}
              render={renderRows}
            />
          </table>
        </div>
      )}
    </div>
  );
};

export default VersionsTable;
