import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import CredentialsActionCreators from '@/modules/provisioning/ActionCreators';
import RedshiftCredentials from '@/modules/provisioning/react/components/RedshiftCredentials';
import RedshiftSandboxCredentialsStore from '@/modules/provisioning/stores/RedshiftSandboxCredentialsStore';
import createStoreMixin from '@/react/mixins/createStoreMixin';

const RedshiftCredentialsContainer = createReactClass({
  mixins: [createStoreMixin(RedshiftSandboxCredentialsStore)],

  propTypes: {
    isAutoLoad: PropTypes.bool.isRequired,
  },

  componentDidMount() {
    if (!this.state.credentials.get('id') && this.props.isAutoLoad) {
      CredentialsActionCreators.createRedshiftSandboxCredentials();
    }
  },

  getStateFromStores() {
    return {
      credentials: RedshiftSandboxCredentialsStore.getCredentials(),
      pendingActions: RedshiftSandboxCredentialsStore.getPendingActions(),
    };
  },

  render() {
    return (
      <RedshiftCredentials
        credentials={this.state.credentials}
        isCreating={this.state.pendingActions.get('create')}
      />
    );
  },
});

export default RedshiftCredentialsContainer;
