import { memo } from 'react';
import type { ReactNode } from 'react';
import type { Map } from 'immutable';

import type { ConfigRow } from './constants';
import LastUseCell from './LastUseCell';

const LastUseActionCell = (props: {
  admins: Map<string, any>;
  row: ConfigRow;
  renderAction: (row: ConfigRow, body: ReactNode) => ReactNode;
}) => {
  return props.renderAction(
    props.row,
    <LastUseCell admins={props.admins} job={props.row.original.job} />,
  );
};

const MemoizedLastUseActionCell = memo(LastUseActionCell);

export default MemoizedLastUseActionCell;
