import type { getTotalStatistics } from '@/modules/stream/helpers';
import { FileSize } from '@/react/common';

export const TotalDataStatistics = ({
  totalStatistics,
  className,
}: {
  totalStatistics: ReturnType<typeof getTotalStatistics>;
  className?: string;
}) => {
  if (!totalStatistics?.data) return null;

  return (
    <div className={className}>
      <FileSize size={totalStatistics.data.waiting} />
      <span className="tw-text-neutral-200"> / </span>
      <span className="tw-text-primary-600">
        <FileSize size={totalStatistics.data.imported} />
      </span>
    </div>
  );
};

export const TotalRowsStatistics = ({
  totalStatistics,
  className,
}: {
  totalStatistics: ReturnType<typeof getTotalStatistics>;
  className?: string;
}) => {
  if (!totalStatistics?.rows) return null;

  return (
    <div className={className}>
      {totalStatistics.rows.waiting}
      <span className="tw-text-neutral-200"> / </span>
      <span className="tw-text-primary-600">{totalStatistics.rows.imported}</span>
    </div>
  );
};
