import { Fragment, useState } from 'react';
import type { ChangeEvent } from 'react';
import { Button, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import Textarea from 'react-textarea-autosize';
import { List, Map } from 'immutable';
import _ from 'underscore';

import { Badge, cn, Icon, Tooltip } from '@keboola/design';

import {
  GENERIC_DOCKER_UI_TABLE_INPUT,
  GENERIC_DOCKER_UI_TABLE_OUTPUT,
} from '@/constants/componentFlags';
import { KEBOOLA_DBT_TRANSFORMATION } from '@/constants/componentIds';
import { FEATURE_IS_SINGLE_TENANT } from '@/constants/features';
import { canRunConfigDataJob } from '@/modules/admin/privileges';
import { ioType } from '@/modules/components/Constants';
import callDockerAction from '@/modules/components/DockerActionsApi';
import { getSourceTypeFromStagingStorage } from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import BackendSizeSelect from '@/modules/components/react/components/BackendSizeSelect';
import { getMappingBasePath } from '@/modules/components/react/components/generic/helpers';
import TableInputMapping from '@/modules/components/react/components/generic/TableInputMapping';
import TableOutputMapping from '@/modules/components/react/components/generic/TableOutputMapping';
import MappingsWrapper from '@/modules/components/react/components/MappingsWrapper';
import { GenericConfigBody } from '@/modules/components/react/pages/GenericConfigBody';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import Checkbox from '@/react/common/Checkbox';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import GitSetting from '@/react/common/GitSetting';
import Loader from '@/react/common/Loader';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import PasswordControl from '@/react/common/PasswordControl';
import ReadOnlyTooltip from '@/react/common/ReadOnlyTooltip';
import SaveButtons from '@/react/common/SaveButtons';
import Select from '@/react/common/Select';
import Sortable from '@/react/common/Sortable';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import SimpleError from '@/utils/errors/SimpleError';
import fromJSOrdered from '@/utils/fromJSOrdered';
import { windowOpen } from '@/utils/windowOpen';
import ExecutionStepModal from './components/ExecutionStepModal';
import ThreadsModal from './components/ThreadsModal';
import {
  DBT_CONNECTION_INPUTS,
  DBT_DEFAULT_THREADS,
  DBT_REMOTE_TRANSFORMATIONS,
} from './constants';
import { prepareDbtSyncActionConfigData } from './helpers';

type CONNECTION_ENTRIES = [string, Record<string, any>];

const DEBUG_STEP = 'dbt debug';

const FRESHNESS_PERIOD_OPTIONS = [
  { value: 'minute', label: 'Minute' },
  { value: 'hour', label: 'Hour' },
  { value: 'day', label: 'Day' },
];

type PendingEntities = {
  executionSteps: boolean;
  reorderSteps: boolean;
  runDebug: boolean;
  connectionDatabase: boolean;
  dbtFreshness: boolean;
  executionParameters: boolean;
  dbtDocs: boolean;
} & { [key: string]: boolean };

const DbtDetail = () => {
  const state = useStores(
    () => {
      const componentId = RoutesStore.getCurrentRouteComponentId();
      const configId = RoutesStore.getCurrentRouteParam('config');

      return {
        configId,
        componentId,
        sapiToken: ApplicationStore.getSapiToken(),
        hasNewQueue: ApplicationStore.hasNewQueue(),
        hasJobsDynamicBackendSize: ApplicationStore.hasJobsDynamicBackendSize(),
        hasSnowflakeDynamicBackendSize: ApplicationStore.hasSnowflakeDynamicBackendSize(),
        isSingleTenant: StackFeaturesStore.hasStackFeature(FEATURE_IS_SINGLE_TENANT),
        component: ComponentsStore.getComponent(componentId),
        config: InstalledComponentsStore.getConfig(componentId, configId),
        configData: InstalledComponentsStore.getConfigData(componentId, configId),
        readOnly: ApplicationStore.isReadOnly(),
        tables: StorageTablesStore.getAll(),
        buckets: StorageBucketsStore.getAll(),
      };
    },
    [],
    [
      ApplicationStore,
      DevBranchesStore,
      RoutesStore,
      InstalledComponentsStore,
      ComponentsStore,
      StackFeaturesStore,
      StorageTablesStore,
      StorageBucketsStore,
    ],
  );
  const [formData, setFormData] = useState(state.configData.get('parameters', Map()));
  const [stepEditing, setStepEditing] = useState<Map<'new' | number, any>>(Map());
  const [pendingEntities, setPendingEntities] = useState<PendingEntities>({
    executionSteps: false,
    reorderSteps: false,
    runDebug: false,
    connectionDatabase: false,
    dbtFreshness: false,
    executionParameters: false,
    dbtDocs: false,
  });
  const isRemoteDbt = DBT_REMOTE_TRANSFORMATIONS.includes(state.componentId);
  const executeSteps = formData
    .getIn(['dbt', 'executeSteps'], List())
    .map((step: string | Map<string, any>) => {
      return _.isString(step) ? Map({ step, active: true }) : step;
    });

  const saveData = (entity: string, changeDescription: string, configData: Map<string, any>) => {
    setPendingEntities((prevState) => ({
      ...prevState,
      [entity]: true,
    }));
    return InstalledComponentsActionCreators.saveComponentConfigData(
      state.componentId,
      state.configId,
      configData,
      changeDescription,
    ).finally(() =>
      setPendingEntities((prevState) => ({
        ...prevState,
        [entity]: false,
      })),
    );
  };

  const getConnectionEntries = () => {
    return Object.entries(
      DBT_CONNECTION_INPUTS[state.componentId as keyof typeof DBT_CONNECTION_INPUTS],
    );
  };

  const getInputMappingPath = () => {
    return getMappingBasePath(KEBOOLA_DBT_TRANSFORMATION, 'input', 'tables');
  };

  const hasSourceFreshness = () => {
    return executeSteps.some((step: Map<string, any>) => {
      return step.get('active') && step.get('step').includes('source freshness');
    });
  };

  const renderFormInput = (
    label: string,
    path: string[],
    type?: 'text' | 'number' | 'password',
    placeholder?: string,
    optional?: boolean,
  ) => {
    const value = formData.getIn(path, '');
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setFormData(formData.setIn(path, type === 'number' ? parseInt(newValue, 10) : newValue));
    };

    return (
      <FormGroup>
        <ControlLabel>
          {label} {optional && <OptionalFormLabel />}
        </ControlLabel>
        {type === 'password' ? (
          <PasswordControl
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            disabled={state.readOnly}
          />
        ) : (
          <FormControl
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            disabled={state.readOnly}
            {...(type === 'number' && { min: 1 })}
          />
        )}
      </FormGroup>
    );
  };

  const renderSelectInput = (
    label: string,
    path: string[],
    options: { label: string; value: string }[],
    placeholder?: string,
  ) => {
    return (
      <FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <Select
          placeholder={placeholder}
          value={formData.getIn(path, '')}
          options={options}
          onChange={(branch: string) => setFormData(formData.setIn(path, branch))}
          disabled={state.readOnly}
        />
      </FormGroup>
    );
  };

  const renderCheckbox = (label: string, path: string[], className?: string) => {
    return (
      <Checkbox
        className={className}
        checked={formData.getIn(path, false)}
        onChange={(checked) => setFormData(formData.setIn(path, checked))}
        disabled={state.readOnly}
      >
        {label}
      </Checkbox>
    );
  };

  const renderExecutionStep = (step: any, index: any) => {
    const name = step.get('step');
    const active = step.get('active');
    const canDrag = !state.readOnly && executeSteps.count() > 1;

    return (
      <div
        key={`${index}-${name}`}
        data-id={name}
        className={cn('flex-container flex-start dragable-option', { active })}
      >
        <Tooltip placement="top" tooltip="Move step" forceHide={!canDrag}>
          <Icon icon="grip-dots-vertical" className={cn('f-16', { dragable: canDrag })} />
        </Tooltip>
        <Checkbox
          checked={active}
          onChange={(checked) => {
            const updatedSteps = executeSteps.setIn([index, 'active'], checked);

            return saveData(
              'executionSteps',
              `${checked ? 'Add' : 'Remove'} execution steps`,
              state.configData.setIn(['parameters', 'dbt', 'executeSteps'], updatedSteps),
            ).then(() => setFormData(formData.setIn(['dbt', 'executeSteps'], updatedSteps)));
          }}
          disabled={
            !!(state.readOnly || pendingEntities.executionSteps || pendingEntities.reorderSteps)
          }
        >
          {name}
        </Checkbox>
        {!state.readOnly && (
          <div className="ml-auto no-wrap">
            <Tooltip tooltip="Edit Step" placement="top">
              <Button
                bsStyle="link"
                className="btn-link-inline btn-link-muted mrp-4"
                onClick={() => setStepEditing(stepEditing.set(index, name))}
              >
                <Icon fixedWidth icon="pen" className="f-14" />
              </Button>
            </Tooltip>
            <Tooltip placement="top" tooltip="Remove Step">
              <Button
                bsStyle="link"
                className={cn('btn-link-inline btn-link-muted mr-0', {
                  disabled: pendingEntities[`removeExecutionStep-${index}`],
                })}
                onClick={() => {
                  return saveData(
                    `removeExecutionStep-${index}`,
                    'Remove execution step',
                    state.configData.deleteIn(['parameters', 'dbt', 'executeSteps', index]),
                  ).then(() => setFormData(formData.deleteIn(['dbt', 'executeSteps', index])));
                }}
              >
                {pendingEntities[`removeExecutionStep-${index}`] ? (
                  <Loader className="f-14" />
                ) : (
                  <Icon fixedWidth icon="trash" className="f-14" />
                )}
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
    );
  };

  const renderDebugButton = () => {
    const readOnly = state.readOnly || !canRunConfigDataJob(state.sapiToken);

    return (
      <ReadOnlyTooltip readOnly={readOnly}>
        <Button
          block
          bsStyle="link"
          className={cn('btn-link-inline', {
            disabled: readOnly || pendingEntities.runDebug,
          })}
          onClick={() => {
            if (readOnly || pendingEntities.runDebug) return;

            setPendingEntities((prevState) => ({
              ...prevState,
              runDebug: true,
            }));
            InstalledComponentsActionCreators.runComponent({
              component: state.componentId,
              data: {
                config: state.configId,
                configData: state.configData
                  .setIn(['parameters', 'dbt', 'executeSteps'], List([DEBUG_STEP]))
                  .deleteIn(['parameters', 'dbt', 'modelNames'])
                  .deleteIn(['parameters', 'showExecutedSqls'])
                  .delete('storage'),
              },
            }).finally(() =>
              setPendingEntities((prevState) => ({
                ...prevState,
                runDebug: false,
              })),
            );
          }}
        >
          {pendingEntities.runDebug ? <Loader /> : <Icon icon="code" fixedWidth />}
          Run debug
        </Button>
      </ReadOnlyTooltip>
    );
  };

  return (
    <GenericConfigBody
      key={`${state.componentId}-${state.configId}`}
      componentId={state.componentId}
      configId={state.configId}
      sidebarProps={{
        run: {
          params: () => ({ config: state.configId }),
          disabled: executeSteps.isEmpty() ? 'No execution steps activated' : '',
          title: 'Run transformation',
          text: 'You are about to run the transformation.',
        },
        additionalButtons: (
          <>
            {renderDebugButton()}
            {state.componentId === KEBOOLA_DBT_TRANSFORMATION && (
              <BackendSizeSelect
                readOnly={state.readOnly}
                componentId={state.componentId}
                isSingleTenant={state.isSingleTenant}
                hasJobsDynamicBackendSize={state.hasJobsDynamicBackendSize}
                hasSnowflakeDynamicBackendSize={state.hasSnowflakeDynamicBackendSize}
                currentSize={state.configData.getIn(['runtime', 'backend', 'type'])}
                changeBackendSize={(type: string) => {
                  return InstalledComponentsActionCreators.saveComponentConfigData(
                    state.componentId,
                    state.configId,
                    state.configData.setIn(['runtime', 'backend', 'type'], type),
                    'Change transformation backend size',
                  );
                }}
              />
            )}
            <ThreadsModal
              onChange={(threads) => {
                return InstalledComponentsActionCreators.saveComponentConfigData(
                  state.componentId,
                  state.configId,
                  state.configData.setIn(
                    ['parameters', isRemoteDbt ? 'remoteDwh' : 'dbt', 'threads'],
                    threads,
                  ),
                  'Change threads',
                );
              }}
              savedValue={state.configData.getIn(
                ['parameters', isRemoteDbt ? 'remoteDwh' : 'dbt', 'threads'],
                DBT_DEFAULT_THREADS,
              )}
              readOnly={state.readOnly}
            />
            <Button
              block
              bsStyle="link"
              className="btn-link-inline"
              disabled={pendingEntities.dbtDocs}
              onClick={() => {
                setPendingEntities((prevState) => ({
                  ...prevState,
                  dbtDocs: true,
                }));
                return callDockerAction(state.componentId, 'dbtDocs', {
                  configData: prepareDbtSyncActionConfigData(state.configId, state.configData),
                })
                  .then((response) => {
                    if (response?.status === 'error') {
                      if (response?.message.startsWith('No artifact from previous run found.')) {
                        throw new SimpleError(
                          'No data found',
                          'You have to select dbt docs generate execution step and run component first.',
                        );
                      }

                      throw response;
                    }

                    const popup = windowOpen(
                      '',
                      'popup,toolbar=no,menubar=no',
                      'DbtDocs',
                    ) as Window;

                    if (!popup) {
                      throw new SimpleError(
                        'Popup was blocked',
                        'Project documentation popup was blocked by browser.',
                      );
                    }

                    popup.document.write(response.html);
                    popup.document.close();
                    popup.focus();
                  })
                  .finally(() =>
                    setPendingEntities((prevState) => ({
                      ...prevState,
                      dbtDocs: false,
                    })),
                  );
              }}
            >
              {pendingEntities.dbtDocs ? <Loader /> : <Icon icon="file-lines" fixedWidth />}
              Dbt project documentation
            </Button>
          </>
        ),
      }}
    >
      {state.componentId in DBT_CONNECTION_INPUTS && (
        <CollapsibleBox
          title="Database Connection"
          hint="Connection strings for external DWH. dbt code will be executed there."
          defaultOpen={
            !getConnectionEntries().some(
              ([name]: CONNECTION_ENTRIES) => !!formData.getIn(['remoteDwh', name]),
            )
          }
          additionalActions={() => {
            if (state.readOnly) {
              return null;
            }

            return (
              <SaveButtons
                isChanged={
                  !state.configData
                    .getIn(['parameters', 'remoteDwh'], Map())
                    .equals(formData.get('remoteDwh', Map()))
                }
                onReset={() => {
                  setFormData(
                    formData.set(
                      'remoteDwh',
                      state.configData.getIn(['parameters', 'remoteDwh'], Map()),
                    ),
                  );
                }}
                onSave={() => {
                  return saveData(
                    'connectionDatabase',
                    'Update project repository',
                    state.configData.mergeIn(
                      ['parameters', 'remoteDwh'],
                      formData.get('remoteDwh', Map()),
                    ),
                  )
                    .then(({ parameters }) => {
                      setFormData(
                        formData.set('remoteDwh', fromJSOrdered(parameters?.remoteDwh || {})),
                      );
                    })
                    .finally(() =>
                      setPendingEntities((prevState) => ({
                        ...prevState,
                        connectionDatabase: false,
                      })),
                    );
                }}
                isSaving={pendingEntities.connectionDatabase}
              />
            );
          }}
        >
          {getConnectionEntries().map(([name, data]: CONNECTION_ENTRIES) => {
            return (
              <Fragment key={name}>
                {renderFormInput(
                  data.label,
                  ['remoteDwh', name],
                  data.type,
                  data.placeholder,
                  data.optional,
                )}
              </Fragment>
            );
          })}
        </CollapsibleBox>
      )}
      <GitSetting
        hideEntrypoint
        readOnly={state.readOnly}
        componentId={state.componentId}
        configId={state.configId}
        configData={state.configData}
      >
        {(renderContent, renderSaveButton) => {
          return (
            <CollapsibleBox
              title="dbt Project Repository"
              hint="Git URL of your dbt code repository."
              headerClassName="plp-6"
              defaultOpen={state.configData.getIn(['parameters', 'git'], Map()).isEmpty()}
              additionalActions={renderSaveButton}
            >
              {renderContent()}
            </CollapsibleBox>
          );
        }}
      </GitSetting>
      {state.component.get('flags').includes(GENERIC_DOCKER_UI_TABLE_INPUT) && (
        <MappingsWrapper>
          <TableInputMapping
            readOnly={state.readOnly}
            destinationType={ioType.TABLE}
            componentId={state.componentId}
            configId={state.configId}
            onDeleteMappings={(...args: any) =>
              InstalledComponentsActionCreators.deleteMappings(state.configData, ...args)
            }
            value={state.configData.getIn(getInputMappingPath(), List())}
            tables={state.tables}
            buckets={state.buckets}
            generateSources={formData.get('generateSources', true)}
            onGenerateSourceChange={(checked: boolean) => {
              setFormData(formData.set('generateSources', checked));
              return saveData(
                'generateSources',
                `${checked ? 'Enable' : 'Disable'} sources generation`,
                state.configData.setIn(['parameters', 'generateSources'], checked),
              );
            }}
          />
        </MappingsWrapper>
      )}
      <CollapsibleBox
        title="Execution Steps"
        hint="Specify the run steps and reorder them by dragging. Set the execution parameters; for instance, a list of the models, definition of the tag, etc."
        defaultOpen={state.config.get('version') === 1 || executeSteps.isEmpty()}
        additionalActions={(isOpen, toggleOpen) => {
          return (
            <Button
              bsStyle="link"
              className="header-inline-button color-success"
              onClick={() => {
                if (!isOpen && toggleOpen) {
                  toggleOpen();
                }

                setStepEditing(stepEditing.set('new', 'dbt '));
              }}
              disabled={!stepEditing.isEmpty()}
            >
              <Icon icon="plus" className="icon-addon-right" />
              Add New Step
            </Button>
          );
        }}
      >
        {executeSteps.isEmpty() ? (
          <p className="m-0 text-muted">No execution steps defined.</p>
        ) : (
          <Sortable
            options={{
              handle: '.dragable',
              disabled:
                state.readOnly || pendingEntities.executionSteps || pendingEntities.reorderSteps,
            }}
            onChange={(order, event) => {
              const reorderedSteps = executeSteps
                .splice(event.oldIndex, 1)
                .splice(event.newIndex, 0, executeSteps.get(event.oldIndex));

              setFormData(formData.setIn(['dbt', 'executeSteps'], reorderedSteps));
              return saveData(
                'reorderSteps',
                'Reorder execution steps',
                state.configData.setIn(['parameters', 'dbt', 'executeSteps'], reorderedSteps),
              );
            }}
          >
            {executeSteps.map(renderExecutionStep).toArray()}
          </Sortable>
        )}
      </CollapsibleBox>
      <CollapsibleBox
        title="Freshness"
        hint={
          hasSourceFreshness()
            ? "Define the acceptable time between the most recent record and now for a source table to be considered 'fresh'. The script uses the system '_timestamp' column."
            : 'You must first activate "dbt source freshness" in the executive steps.'
        }
        defaultOpen={formData.getIn(['dbt', 'freshness'], Map()).isEmpty()}
        isDisabled={!hasSourceFreshness()}
        additionalActions={() => {
          if (state.readOnly) {
            return null;
          }

          return (
            <SaveButtons
              isChanged={
                !state.configData
                  .getIn(['parameters', 'dbt', 'freshness'], Map())
                  .equals(formData.getIn(['dbt', 'freshness'], Map()))
              }
              onReset={() => {
                setFormData(
                  formData.setIn(
                    ['dbt', 'freshness'],
                    state.configData.getIn(['parameters', 'dbt', 'freshness'], Map()),
                  ),
                );
              }}
              onSave={() => {
                return saveData(
                  'dbtFreshness',
                  'Update freshness',
                  state.configData.setIn(
                    ['parameters', 'dbt', 'freshness'],
                    formData.getIn(['dbt', 'freshness'], Map()),
                  ),
                ).finally(() =>
                  setPendingEntities((prevState) => ({
                    ...prevState,
                    dbtFreshness: false,
                  })),
                );
              }}
              isSaving={pendingEntities.dbtFreshness}
            />
          );
        }}
      >
        {renderCheckbox('Warn After', ['dbt', 'freshness', 'warn_after', 'active'], 'mt-0')}
        <div className="form-groups">
          {renderSelectInput(
            'Select Period',
            ['dbt', 'freshness', 'warn_after', 'period'],
            FRESHNESS_PERIOD_OPTIONS,
          )}
          {renderFormInput('Value', ['dbt', 'freshness', 'warn_after', 'count'], 'number')}
        </div>
        {renderCheckbox('Error After', ['dbt', 'freshness', 'error_after', 'active'], 'mt-0')}
        <div className="form-groups">
          {renderSelectInput(
            'Select Period',
            ['dbt', 'freshness', 'error_after', 'period'],
            FRESHNESS_PERIOD_OPTIONS,
          )}
          {renderFormInput('Value', ['dbt', 'freshness', 'error_after', 'count'], 'number')}
        </div>
      </CollapsibleBox>
      {state.configData.getIn(['parameters', 'dbt', 'modelNames'], List()).join().length > 0 && (
        <div className="box">
          <div className="box-header big-padding">
            <h2 className="box-title flex-container flex-start">
              Execution Parameters
              <Tooltip
                placement="top"
                type="explanatory"
                tooltip="Execution steps are now editable, and parameters should be defined there."
              >
                <Badge text="Deprecated" variant="orange" placement="right" />
              </Tooltip>
            </h2>
            {!state.readOnly && (
              <SaveButtons
                isChanged={
                  !state.configData
                    .getIn(['parameters', 'dbt', 'modelNames'], List())
                    .equals(formData.getIn(['dbt', 'modelNames'], List()))
                }
                onReset={() => {
                  setFormData(
                    formData.setIn(
                      ['dbt', 'modelNames'],
                      state.configData.getIn(['parameters', 'dbt', 'modelNames'], List()),
                    ),
                  );
                }}
                onSave={() => {
                  return saveData(
                    'executionParameters',
                    'Update execution parameters',
                    state.configData.setIn(
                      ['parameters', 'dbt', 'modelNames'],
                      formData.getIn(['dbt', 'modelNames'], List()),
                    ),
                  );
                }}
                isSaving={pendingEntities.executionParameters}
              />
            )}
          </div>
          <div className="box-content pt-0">
            <FormGroup>
              <Textarea
                minRows={3}
                className="form-control"
                placeholder="path:marts/finance,tag:nightly,config.materialized:table"
                value={formData.getIn(['dbt', 'modelNames', 0], '')}
                onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                  setFormData(
                    formData.setIn(
                      ['dbt', 'modelNames'],
                      List([event.target.value]).filter(Boolean),
                    ),
                  );
                }}
                disabled={state.readOnly}
              />
            </FormGroup>
          </div>
        </div>
      )}
      {state.component.get('flags').includes(GENERIC_DOCKER_UI_TABLE_OUTPUT) && (
        <MappingsWrapper>
          <TableOutputMapping
            readOnly={state.readOnly}
            configId={state.configId}
            componentId={state.componentId}
            onDeleteMappings={(...args: any) =>
              InstalledComponentsActionCreators.deleteMappings(state.configData, ...args)
            }
            configName={state.config.get('name')}
            value={state.configData.getIn(['storage', 'output', 'tables'], List())}
            tables={state.tables}
            buckets={state.buckets}
            sourceType={getSourceTypeFromStagingStorage(
              state.component.getIn(['data', 'staging_storage', 'output']),
            )}
          />
        </MappingsWrapper>
      )}
      <ExecutionStepModal
        step={stepEditing as any}
        show={!stepEditing.isEmpty()}
        onHide={() => setStepEditing(Map())}
        onSubmit={(command: string) => {
          const index = stepEditing.has('new')
            ? executeSteps.count()
            : stepEditing.keySeq().first();
          let updatedSteps = executeSteps.setIn([index, 'step'], command);

          if (stepEditing.has('new')) {
            updatedSteps = updatedSteps.setIn([index, 'active'], true);
          }

          return saveData(
            'modifyExecutionStep',
            `${stepEditing.has('new') ? 'Add' : 'Update'} execution step`,
            state.configData.setIn(['parameters', 'dbt', 'executeSteps'], updatedSteps),
          ).then(() => {
            setFormData(formData.setIn(['dbt', 'executeSteps'], updatedSteps));
            setStepEditing(Map());
          });
        }}
      />
    </GenericConfigBody>
  );
};

export default DbtDetail;
