import type { ReactNode } from 'react';

import { Truncated } from '@/react/common';
import RowWrapper from './RowWrapper';

const Row = <T extends ReactNode>({ title, value }: { title: string; value: T }) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return null;
  }

  return (
    <RowWrapper title={title}>
      <Truncated text={value} className="tw-text-neutral-400" />
    </RowWrapper>
  );
};

export default Row;
