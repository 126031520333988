import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { Button } from '@keboola/design';

import Modal from './ServiceAccountModal';

const ServiceAccountSection = createReactClass({
  propTypes: {
    imageParameters: PropTypes.object.isRequired,
    documentationLink: PropTypes.string.isRequired,
    value: PropTypes.shape({
      type: PropTypes.string.isRequired,
      projectId: PropTypes.string.isRequired,
      privateKeyId: PropTypes.string.isRequired,
      privateKey: PropTypes.string.isRequired,
      clientEmail: PropTypes.string.isRequired,
      clientId: PropTypes.string.isRequired,
      authUri: PropTypes.string.isRequired,
      tokenUri: PropTypes.string.isRequired,
      authProviderX509CertUrl: PropTypes.string.isRequired,
      clientX509CertUrl: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return {
      showModal: false,
    };
  },

  render() {
    const value = this.getValue();

    if (!value.privateKeyId) {
      return this.renderButton();
    }

    return this.renderForm(value);
  },

  renderButton() {
    if (this.props.readOnly) {
      return <p className="text-center">No Service Account Key set</p>;
    }

    return (
      <p className="tw-flex tw-justify-center">
        <Button disabled={this.props.disabled} onClick={() => this.setState({ showModal: true })}>
          Set Service Account Key
        </Button>
        <Modal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          onSubmit={this.handleModalSubmit}
          documentationLink={this.props.documentationLink}
        />
      </p>
    );
  },

  renderForm(value) {
    return (
      <div className="form-horizontal">
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Project</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl.Static>{value.projectId}</FormControl.Static>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Service Account Email</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl.Static>{value.clientEmail}</FormControl.Static>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Service Account Key ID</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl.Static>{value.privateKeyId}</FormControl.Static>
          </div>
        </FormGroup>
        {!this.props.readOnly && !this.hasStaticValues() && (
          <p className="tw-flex tw-justify-center">
            <Button variant="danger" onClick={this.deleteServiceAccountKey}>
              Delete Service Account Key
            </Button>
          </p>
        )}
      </div>
    );
  },

  deleteServiceAccountKey() {
    this.props.onChange(this.parseValue(Map()));
  },

  handleModalSubmit(value) {
    this.setState({ showModal: false });
    this.props.onChange(this.parseValue(value));
  },

  hasStaticValues() {
    return this.props.imageParameters.has('service_account');
  },

  getValue() {
    if (this.hasStaticValues()) {
      return this.parseValue(this.props.imageParameters.get('service_account'));
    }

    return this.props.value;
  },

  parseValue(value) {
    return {
      type: value.get('type', ''),
      projectId: value.get('project_id', ''),
      privateKeyId: value.get('private_key_id', ''),
      privateKey: value.get('private_key', ''),
      clientEmail: value.get('client_email', ''),
      clientId: value.get('client_id', ''),
      authUri: value.get('auth_uri', ''),
      tokenUri: value.get('token_uri', ''),
      authProviderX509CertUrl: value.get('auth_provider_x509_cert_url', ''),
      clientX509CertUrl: value.get('client_x509_cert_url', ''),
    };
  },
});

export default ServiceAccountSection;
