import { Icon } from '@keboola/design';

import { RowActionMenuItem } from '@/react/common';
import Loader from '@/react/common/Loader';
import RoutesStore from '@/stores/RoutesStore';

const QueryDeleteButton = ({
  componentId,
  configurationId,
  actionsProvisioning,
  query,
  isPending,
  onKeyDown,
}) => {
  const deleteQuery = (e) => {
    e.preventDefault();
    e.stopPropagation();
    RoutesStore.getRouter().transitionTo(componentId, {
      config: configurationId,
    });
    const ExDbActionCreators = actionsProvisioning.createActions(componentId);
    setTimeout(ExDbActionCreators.deleteQuery, null, configurationId, query.get('id'));
  };

  return (
    <RowActionMenuItem onSelect={deleteQuery} disabled={isPending} onKeyDown={onKeyDown}>
      {isPending ? <Loader /> : <Icon icon="trash" fixedWidth />}Delete query
    </RowActionMenuItem>
  );
};

export default QueryDeleteButton;
