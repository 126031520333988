import type { Map } from 'immutable';

import { Icon } from '@keboola/design';

import { SHARES_TYPES } from '@/constants';
import { ObjectTypes } from '@/modules/components/MetadataConstants';
import DescriptionButton from '@/modules/storage/components/DescriptionButton';
import {
  getLoadingActionName,
  getTableShareTooltipText,
  isTableShareDisabled,
} from '@/modules/storage/helpers';
import { RowActionDropdown, RowActionMenuItem } from '@/react/common';
import ActionLoader from '@/react/common/ActionLoader';
import { ShareTablesIcon } from '@/react/common/icons';
import type { OpenShareModal } from '@/types/types';

type Props = {
  table: Map<string, any>;
  isDeleting: boolean;
  isExporting: boolean;
  isCreatingSnapshot: boolean;
  openDeleteTableModal: (table: Map<string, any>) => void;
  openExportTablesModal: (tables: Map<string, any>[]) => void;
  openSnapshotModal: (tables: Map<string, any>[]) => void;
  openShareModal: OpenShareModal;
  canExportTable: boolean;
  canShareTable: boolean;
  canWriteBucket: boolean;
};

export const TableActions = ({
  table,
  isDeleting,
  isExporting,
  isCreatingSnapshot,
  openDeleteTableModal,
  openExportTablesModal,
  openSnapshotModal,
  openShareModal,
  canExportTable,
  canShareTable,
  canWriteBucket,
}: Props) => {
  const isLoading = isCreatingSnapshot || isExporting || isDeleting;
  const selectedTables = [table];

  return isLoading ? (
    <ActionLoader
      loadingActionName={getLoadingActionName({ isCreatingSnapshot, isExporting, isDeleting })}
    />
  ) : (
    <RowActionDropdown>
      {canExportTable && (
        <RowActionMenuItem onSelect={() => openExportTablesModal([table])}>
          <Icon fixedWidth icon="down-to-line" />
          Export table
        </RowActionMenuItem>
      )}
      {canWriteBucket && (
        <RowActionMenuItem
          onSelect={() => openSnapshotModal([table])}
          disabled={table.get('isAlias')}
          disabledReason="Snapshot is not available for alias tables."
        >
          <Icon fixedWidth icon="camera-viewfinder" />
          Create snapshot
        </RowActionMenuItem>
      )}
      {canShareTable && (
        <RowActionMenuItem
          disabled={isTableShareDisabled(table)}
          disabledReason={getTableShareTooltipText(table.get('bucket'))}
          onSelect={() =>
            openShareModal({
              selectedTables,
              shareModalStep: SHARES_TYPES.SELECTED_TABLES,
            })
          }
        >
          <ShareTablesIcon />
          Share Table
        </RowActionMenuItem>
      )}
      <DescriptionButton data={table} entity={ObjectTypes.TABLE} readOnly={!canWriteBucket} />
      {canWriteBucket && (
        <>
          <RowActionMenuItem divider />
          <RowActionMenuItem onSelect={() => openDeleteTableModal(table)}>
            <Icon icon="trash" fixedWidth />
            Delete table
          </RowActionMenuItem>
        </>
      )}
    </RowActionDropdown>
  );
};
