import { Component } from 'react';
import type { FormEvent } from 'react';
import { Modal } from 'react-bootstrap';
import type { Map } from 'immutable';
import { List } from 'immutable';

import {
  Alert,
  Button,
  FormGroup,
  HelpBlock,
  Icon,
  IconButton,
  Label,
  ProgressBar,
  Tabs,
  TabsContent,
  TextInput,
  Tooltip,
} from '@keboola/design';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { validateTableName } from '@/modules/storage/helpers';
import { bigquery, snowflake } from '@/modules/wr-db/templates/dataTypes';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import FilesDropZone from '@/react/common/FilesDropZone';
import { isValidName } from '@/react/common/helpers';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import PredefinedInput from './PredefinedInput';

type Props = {
  bucket: Map<string, any>;
  tables: Map<string, any>;
  openModal: boolean;
  onCreateFromCsv: (file: File, params: any) => Promise<void>;
  onCreateDefinition: (params: any) => Promise<void>;
  onHide: () => void;
  isCreatingTable: boolean;
  progress: number;
  hasNewNativeTypes: boolean;
  defaultBackend: string;
};

type State = {
  name: string;
  file: File | null;
  delimiter: string;
  enclosure: string;
  primaryKey: List<string>;
  columns: [string, string][];
  error: string | null;
  warning: string | null;
  activeTab: 'upload' | 'direct';
};

const INITIAL_STATE: State = {
  name: '',
  file: null,
  delimiter: ',',
  enclosure: '"',
  primaryKey: List(),
  columns: [['', '']],
  error: null,
  warning: null,
  activeTab: 'upload',
};

const SUPPORTED_TYPES: Record<string, (string | Record<string, any>)[]> = {
  snowflake,
  bigquery,
};

class CreateTableModal extends Component<Props, State> {
  state = INITIAL_STATE;

  render() {
    const progress = this.props.isCreatingTable ? 100 : this.props.progress;
    const isSaving = this.props.isCreatingTable || progress > 0;
    const allowCreateTableDefinition =
      this.props.hasNewNativeTypes && this.props.defaultBackend in SUPPORTED_TYPES;

    return (
      <Modal show={this.props.openModal} onHide={this.props.onHide} onEnter={this.resetState}>
        <form onSubmit={this.onSubmit}>
          <Modal.Header closeButton className="tw-border-0 !tw-pb-0">
            <Modal.Title>
              Create New Table in Bucket {this.props.bucket.get('displayName')}
            </Modal.Title>
            <ModalIcon icon="table" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            {allowCreateTableDefinition ? (
              <Tabs
                inModal
                value={this.state.activeTab}
                onValueChange={(activeTab) => {
                  this.setState({ ...INITIAL_STATE, activeTab });
                }}
                triggers={[
                  { title: 'Upload Table', value: 'upload' },
                  { title: 'Create Table', value: 'direct' },
                ]}
              >
                <TabsContent value="upload">
                  {this.renderUploadForm(isSaving, progress)}
                </TabsContent>
                <TabsContent value="direct">{this.renderTableDefinitionForm()}</TabsContent>
              </Tabs>
            ) : (
              this.renderUploadForm(isSaving, progress)
            )}
          </Modal.Body>
          <Modal.Footer>
            {this.state.error && this.state.activeTab === 'upload' ? (
              <ConfirmButtons
                block
                saveStyle="primary"
                saveLabel="Try again"
                onSave={this.resetState}
              />
            ) : (
              <ConfirmButtons
                block
                saveButtonType="submit"
                saveLabel={
                  isSaving
                    ? progress < 100
                      ? 'Uploading data...'
                      : 'Creating table...'
                    : 'Create table'
                }
                isDisabled={this.isDisabled()}
                isSaving={isSaving}
              />
            )}
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  renderUploadForm(isSaving: boolean, progress: number) {
    if (this.state.error) {
      return (
        <Alert variant="error" className="tw-mb-5">
          {this.state.error}
        </Alert>
      );
    }

    if (isSaving) {
      return <ProgressBar type="success" progress={progress} />;
    }

    return (
      <div className="tw-mb-5 tw-flex tw-flex-col tw-gap-4">
        {this.renderTableNameInput()}
        <FormGroup>
          <FilesDropZone
            label="CSV file"
            files={this.state.file}
            onDrop={(files) => this.setState({ file: files[0] })}
          />
          <HelpBlock>Table structure will be set up from the CSV file.</HelpBlock>
        </FormGroup>

        <FormGroup>
          <Label htmlFor="delimiter">Delimiter</Label>
          <TextInput
            id="delimiter"
            value={this.state.delimiter}
            onChange={(value) => this.setState({ delimiter: value })}
            variant="secondary"
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="enclosure">Enclosure</Label>
          <TextInput
            id="enclosure"
            value={this.state.enclosure}
            onChange={(value) => this.setState({ enclosure: value })}
            variant="secondary"
          />
        </FormGroup>

        {this.renderPrimaryKeyInput()}
      </div>
    );
  }

  renderTableDefinitionForm() {
    return (
      <>
        {this.state.error && (
          <Alert variant="error" className="tw-mb-5">
            {this.state.error}
          </Alert>
        )}
        {this.renderTableNameInput()}
        {this.renderColumnsEditor()}
        {this.renderPrimaryKeyInput(this.state.columns.map(([name]) => name).filter(Boolean))}
      </>
    );
  }

  renderTableNameInput() {
    return (
      <PredefinedInput
        autoFocus
        className="tw-mb-0"
        entity="tableName"
        value={this.state.name}
        warning={this.state.warning}
        onChange={(name: string) => {
          this.setState({ name }, () => {
            this.setState({ warning: validateTableName(this.state.name, this.props.tables) });
          });
        }}
      />
    );
  }

  renderPrimaryKeyInput(columns?: string[]) {
    return (
      <FormGroup>
        <Label htmlFor="primary-key">
          Primary Key <Label.Optional />
        </Label>
        <Select
          id="primary-key"
          multi
          value={this.state.primaryKey}
          placeholder="Primary key column name"
          onChange={(primaryKey) => this.setState({ primaryKey })}
          {...(columns
            ? {
                disabled: columns.length === 0,
                options: columns.map((value) => ({ label: value, value })),
              }
            : {
                allowCreate: true,
                trimMultiCreatedValues: true,
                promptTextCreator: (name) => `Add the "${name}" column as the primary key`,
              })}
        />
        <HelpBlock>
          Primary key is useful for incremental imports - rows that already exist in the table are
          updated.
        </HelpBlock>
      </FormGroup>
    );
  }

  renderColumnsEditor() {
    const typeOptions = SUPPORTED_TYPES[this.props.defaultBackend]
      .map((type) => {
        const realType = typeof type === 'string' ? type : Object.keys(type)[0];

        return { label: realType, value: realType };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    return (
      <>
        <div className="tw-mb-4 tw-mt-6 tw-flex tw-text-sm tw-font-medium tw-text-neutral-800">
          <div className="tw-grid tw-flex-1 tw-grid-cols-2 tw-items-start tw-gap-6">
            <div>Column Name</div>
            <div>Type</div>
          </div>
          <div className="tw-w-8" />
        </div>
        {this.state.columns.map(([name, type], index) => {
          return (
            <div className="tw-group/column tw-mb-4 tw-flex tw-justify-between" key={index}>
              <div className="tw-grid tw-flex-1 tw-grid-cols-2 tw-items-start tw-gap-6">
                <TextInput
                  variant="secondary"
                  placeholder="Column"
                  value={name}
                  onChange={(value) => {
                    const columnName = value.replace(/[^a-zA-Z0-9_-]/g, '_');

                    this.setState({
                      columns: this.state.columns.map((column, i) => {
                        return i === index ? [columnName, type] : column;
                      }),
                    });
                  }}
                />
                <Select
                  placeholder="Type"
                  value={type}
                  options={typeOptions}
                  onChange={(type: string) => {
                    this.setState({
                      columns: this.state.columns.map((column, i) => {
                        return i === index ? [name, type] : column;
                      }),
                    });
                  }}
                />
              </div>
              <div className="tw-inline-flex tw-h-10 tw-w-8 tw-justify-end">
                {this.state.columns.length > 1 && (
                  <Tooltip tooltip="Delete row" placement="top">
                    <IconButton
                      variant="inline"
                      className="tw-inline-flex tw-w-max tw-opacity-0 tw-transition-opacity group-hover/column:tw-opacity-100"
                      onClick={() => {
                        this.setState({
                          columns: this.state.columns.filter((_, i) => i !== index),
                        });
                      }}
                      icon="trash"
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          );
        })}
        <Button
          variant="outline"
          onClick={() => {
            this.setState({ columns: [...this.state.columns, ['', '']] });
          }}
          className="tw-mb-6"
        >
          <Icon icon="plus" />
          Add Column
        </Button>
      </>
    );
  }

  onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    return Promise.resolve()
      .then(() => {
        if (this.state.activeTab === 'direct') {
          return this.props.onCreateDefinition({
            name: this.state.name,
            columns: this.state.columns.map(([name, type]) => {
              return { name, definition: { type } };
            }),
            ...(!this.state.primaryKey.isEmpty() && {
              primaryKeysNames: this.state.primaryKey.toArray(),
            }),
          });
        }

        return this.props.onCreateFromCsv(this.state.file!, {
          name: this.state.name,
          delimiter: this.state.delimiter,
          enclosure: this.state.enclosure,
          ...(!this.state.primaryKey.isEmpty() && { primaryKey: this.state.primaryKey.join(',') }),
        });
      })
      .then(() => {
        ApplicationActionCreators.sendNotification({
          type: 'success',
          message: `Table "${this.state.name}" has been successfully created.`,
        });
      })
      .then(this.props.onHide, (error: string) => this.setState({ error }));
  };

  resetState = () => {
    this.setState(INITIAL_STATE);
  };

  isDisabled = () => {
    if (!this.state.name || !isValidName(this.state.name)) {
      return true;
    }

    if (this.state.activeTab === 'direct') {
      return this.state.columns.some(([name, type]) => !name || !type);
    }

    return !!(
      this.props.isCreatingTable ||
      this.props.progress ||
      this.state.error ||
      this.state.warning ||
      !this.state.file ||
      !this.state.delimiter
    );
  };
}

export default CreateTableModal;
