import type { KeyboardEvent } from 'react';
import { type Map } from 'immutable';

import { Icon } from '@keboola/design';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import type { ExistingApp } from '@/api/routes/dataScienceService';
import { updateApp } from '@/modules/data-apps/actions';
import Confirm from '@/react/common/Confirm';
import ConfirmMenuItem from '@/react/common/ConfirmMenuItem';
import Loader from '@/react/common/Loader';

const TerminateDataApp = (props: {
  config: Map<string, any>;
  mode: 'sidebar' | 'menuitem';
  readOnly: boolean;
  app?: ExistingApp;
  onKeyDown?: (event: KeyboardEvent) => void;
}) => {
  const isProcessing = props.app?.state === 'stopping';

  if (!props.app || (props.app?.state !== 'running' && !isProcessing)) {
    return null;
  }

  const handleConfirm = () => {
    return updateApp(props.app, props.config, 'stopped').then(() => {
      ApplicationActionCreators.sendNotification({
        message: () => {
          return (
            <>
              <strong>{props.config.get('name')}</strong> data app is stopping.
            </>
          );
        },
      });
    });
  };

  const renderBody = () => {
    return (
      <p>
        Are you sure you want to terminate the data app <strong>{props.config.get('name')}</strong>?
      </p>
    );
  };

  if (props.mode === 'sidebar') {
    return (
      <Confirm
        icon="circle-pause"
        buttonType="danger"
        title="Terminate Data App"
        buttonLabel="Terminate Data App"
        childrenRootElement="a"
        text={renderBody()}
        isLoading={isProcessing}
        onConfirm={handleConfirm}
        isDisabled={isProcessing || props.readOnly}
      >
        {isProcessing ? <Loader /> : <Icon icon="circle-pause" fixedWidth />}
        Terminate data app
      </Confirm>
    );
  }

  return (
    <ConfirmMenuItem
      icon="circle-pause"
      buttonType="danger"
      title="Terminate Data App"
      buttonLabel="Terminate Data App"
      text={renderBody()}
      onConfirm={handleConfirm}
      isLoading={isProcessing}
      isDisabled={isProcessing || props.readOnly}
      onKeyDown={props.onKeyDown}
    >
      {isProcessing ? <Loader /> : <Icon icon="circle-pause" fixedWidth />}
      Terminate data app
    </ConfirmMenuItem>
  );
};

export default TerminateDataApp;
