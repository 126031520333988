import { useState } from 'react';
import PropTypes from 'prop-types';

import { URLS } from '@keboola/constants';
import { Alert, HelpBlock, Link } from '@keboola/design';

import actions from '@/modules/transformations/ActionCreators';
import ConfirmModal from '@/react/common/ConfirmModal';
import SyncActionError from '@/utils/errors/SyncActionError';

const MigrationModal = ({ show, onHide, bucketName, bucketId, phasesCount }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  return (
    <ConfirmModal
      closeAfterResolve
      handleErrorInModal
      show={show}
      isDisabled={!!error?.message}
      isLoading={loading}
      icon="circle-arrow-right"
      buttonType="success"
      buttonLabel="Check and migrate"
      title="Migrate to new transformations"
      text={
        <>
          {error?.message && (
            <Alert variant="error" className="tw-mb-5">
              <p>{error.message}</p>
              {error.exceptionId && <small>Exception ID: {error.exceptionId}</small>}
            </Alert>
          )}
          The migration process will automatically convert <strong>{bucketName}</strong> (the whole
          bucket) to the new version, unlocking enhanced features.
          {phasesCount > 1 && (
            <>
              <Alert variant="warning" className="tw-mb-5 tw-mt-3.5">
                This transformation bucket contains {phasesCount} phases and will be split into{' '}
                {phasesCount} new transformations. Are you sure you want to continue?
              </Alert>
              <HelpBlock className="tw-mt-1">
                Note: If you don&apos;t want to split the transformation bucket into multiple
                individual transformations, please move all transformations into one phase using{' '}
                <Link href={`${URLS.USER_DOCUMENTATION}/transformations/migration`}>our guide</Link>
                .
              </HelpBlock>
            </>
          )}
        </>
      }
      onConfirm={() => {
        setLoading(true);

        return actions
          .migrateToNewTransformation(bucketId)
          .catch(SyncActionError, (error) => {
            setError(error);
            throw error;
          })
          .finally(() => setLoading(false));
      }}
      onHide={() => {
        onHide();
        setError(null);
      }}
    />
  );
};

MigrationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  bucketName: PropTypes.string.isRequired,
  bucketId: PropTypes.string.isRequired,
  phasesCount: PropTypes.number.isRequired,
};

export default MigrationModal;
