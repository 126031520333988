import { useEffect, useState } from 'react';

import dayjs from '@/date';
import { calculateDuration, timeInWords } from '@/utils/duration';
import Timer from '@/utils/Timer';

const POLL_INTERVAL = 1 * 1000; // 1 second

const DurationUntillNow = (props: { startTime: string }) => {
  const [endTime, setEndTime] = useState(dayjs().format());

  useEffect(() => {
    const increaseEndTime = () => setEndTime(dayjs().format());

    Timer.poll(increaseEndTime, { interval: POLL_INTERVAL });
    return () => {
      Timer.stop(increaseEndTime);
    };
  }, []);

  return <>{timeInWords(calculateDuration(props.startTime, endTime))}</>;
};

export default DurationUntillNow;
