import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import type { AutomationDetail, AutomationMessage, UserMessage } from '@/api/routes/aiService';
import * as AiApi from '@/modules/ai/api';
import { constructMessage } from '@/modules/automations/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { routeNames as flowsRouteNames } from '@/modules/flows/constants';
import RoutesStore from '@/stores/RoutesStore';

const QUERY_KEYS = {
  automation: (id?: string) => ['automation', id] as const,
  automationMessages: (id?: string) => ['automation', id, 'messages'] as const,
} as const;

export const useAutomation = (id?: string) => {
  return useQuery({
    queryKey: QUERY_KEYS.automation(id),
    queryFn: () => AiApi.getAutomation(id!),
    enabled: !!id,
  });
};

export const useAutomationMessages = (id?: string) => {
  return useQuery({
    queryKey: QUERY_KEYS.automationMessages(id),
    queryFn: () => AiApi.getAutomationMessages(id!).then((response) => response.messages),
    enabled: !!id,
  });
};

const resolveWhenReady = async (automation: AutomationDetail): Promise<AutomationDetail> => {
  if (!automation.id) throw new Error('Automation creation failed');

  const response = await AiApi.getAutomation(automation.id);
  if (response.status === 'provisioningSandbox' || response.status === 'ready') return response;
  if (response.status === 'failed') throw new Error('Automation creation failed');

  await new Promise((resolve) => setTimeout(resolve, 500));
  return resolveWhenReady(response);
};

export const useCreateAutomation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (initialMessage?: string) => {
      const automation = await AiApi.createAutomation();
      const readyAutomation = await resolveWhenReady(automation);

      if (!readyAutomation.id) throw new Error('Automation creation failed');

      if (initialMessage) {
        await AiApi.sendMessage(readyAutomation.id, constructMessage({ text: initialMessage }));
      }

      return readyAutomation;
    },
    onSuccess: (automation) => {
      if (automation.id) {
        queryClient.setQueryData(QUERY_KEYS.automation(automation.id), automation);
        queryClient.invalidateQueries({ queryKey: QUERY_KEYS.automation(automation.id) });
      }
    },
  });
};

export const useDeleteAutomation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => AiApi.deleteAutomation(id),
    onSuccess: (_, id) => {
      queryClient.setQueryData(QUERY_KEYS.automation(id), null);
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.automation(id) });
    },
  });
};

export const useSendMessage = (id?: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (messageObject: UserMessage) => {
      if (!id) return Promise.reject('No ID provided');

      queryClient.setQueryData(
        QUERY_KEYS.automationMessages(id),
        (oldMessages: AutomationMessage[] = []) => [...oldMessages, messageObject],
      );

      return AiApi.sendMessage(id, messageObject);
    },
    onSuccess: async (agentMessage: AutomationMessage, userMessage: UserMessage) => {
      queryClient.setQueryData(
        QUERY_KEYS.automationMessages(id),
        (oldMessages: AutomationMessage[] = []) => [...oldMessages, agentMessage],
      );

      if (agentMessage.type === 'flowGenerated') {
        await InstalledComponentsActionCreators.loadInstalledComponentsForce({
          include: 'configuration',
        });

        queryClient.invalidateQueries({ queryKey: QUERY_KEYS.automation(id) });
      }

      if (
        userMessage.type === 'sourceTableSelectionResponse' ||
        ['configurationEditFinished', 'transformationEditFinished'].includes(agentMessage.type)
      ) {
        queryClient.invalidateQueries({ queryKey: QUERY_KEYS.automation(id) });
      }
    },
  });
};

export const redirectToAutomation = (automation: AutomationDetail) => {
  RoutesStore.getRouter().transitionTo(flowsRouteNames.DETAIL, {
    config: automation.configurationId,
  });
};
