import { useMutation, useQueryClient } from '@tanstack/react-query';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { storageApiKey } from '@/constants/queryKeys';
import installedComponentsApi from '@/modules/components/InstalledComponentsApi';
import configurationRestoredNotification from '@/modules/components/react/components/notifications/configurationRestored';
import ApplicationStore from '@/stores/ApplicationStore';

const restoreConfiguration = (
  componentId: string,
  configurationId: string,
  configurationName: string,
) => {
  return installedComponentsApi.restoreConfiguration(componentId, configurationId).then(() => {
    ApplicationActionCreators.sendNotification({
      type: 'success',
      message: configurationRestoredNotification(
        componentId,
        configurationId,
        configurationName,
        ApplicationStore.hasFlows(),
      ),
    });

    return null;
  });
};

export const useRestoreConfiguration = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      componentId,
      configurationId,
      configurationName,
    }: {
      componentId: string;
      configurationId: string;
      configurationName: string;
    }) => restoreConfiguration(componentId, configurationId, configurationName),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: storageApiKey.trash() });
    },
  });
};
