import { ButtonToolbar } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import Actions from '@/modules/configurations/ConfigurationRowsActionCreators';
import Store from '@/modules/configurations/ConfigurationRowsStore';
import { IncompatibleConfigModalProps } from '@/modules/configurations/utils/helpers';
import isParsableConfiguration from '@/modules/configurations/utils/isParsableConfiguration';
import sectionsUtils from '@/modules/configurations/utils/sections';
import CatchUnsavedChanges from '@/react/common/CatchUnsavedChanges';
import Confirm from '@/react/common/Confirm';
import SaveButtons from '@/react/common/SaveButtons';
import SwitchButton from '@/react/common/SwitchButton';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import fromJSOrdered from '@/utils/fromJSOrdered';

const RowHeaderButton = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, InstalledComponentsStore, Store, TablesStore)],

  getStateFromStores() {
    const settings = RoutesStore.getRouteSettings();
    const componentId = settings.get('componentId');
    const configId = RoutesStore.getCurrentRouteParam('config');
    const rowId = RoutesStore.getCurrentRouteParam('row');
    const conformFn = settings.getIn(['row', 'onConform'], (config) => config);
    let context = InstalledComponentsStore.getConfigurationContext(componentId, configId);
    const parseTableIdFn = settings.getIn(['row', 'parseTableId']);
    const configuration = Store.getConfiguration(componentId, configId, rowId);
    if (parseTableIdFn) {
      const tableId = parseTableIdFn(configuration);
      const table = TablesStore.getTable(tableId);
      context = context.set('table', table).set('tableId', tableId);
    }
    const sections = settings.getIn(['row', 'sections'], List());
    const saveFn = sectionsUtils.makeCreateFn(sections, settings.getIn(['row', 'onSave']));
    const createFn = sectionsUtils.makeCreateFn(sections);
    const parseFn = sectionsUtils.makeParseFn(sections, conformFn, context);
    const editingConfiguration = Store.getEditingJsonConfiguration(componentId, configId, rowId);
    const row = Store.get(componentId, configId, rowId);
    const isJsonValid = Store.isEditingJsonConfigurationValid(componentId, configId, rowId);

    return {
      row,
      settings,
      componentId,
      rowId,
      configId,
      saveFn,
      createFn,
      parseFn,
      isJsonValid,
      isChanged: Store.isEditingConfiguration(componentId, configId, rowId),
      isJsonChanged: Store.isEditingJsonConfiguration(componentId, configId, rowId),
      isParsable: isParsableConfiguration(conformFn(configuration), parseFn, createFn),
      isJsonParsable:
        isJsonValid &&
        isParsableConfiguration(conformFn(fromJSOrdered(editingConfiguration)), parseFn, createFn),
      isSaving: Store.getPendingActions(componentId, configId, rowId).has('save-configuration'),
      isJsonSaving: Store.getPendingActions(componentId, configId, rowId).has('save-json'),
      hasJsonEditor: Store.hasJsonEditor(
        componentId,
        configId,
        rowId,
        parseFn,
        createFn,
        conformFn,
      ),
      readOnly: ApplicationStore.isReadOnly(),
    };
  },

  render() {
    return (
      <CatchUnsavedChanges
        isDirty={this.state.isJsonChanged || this.state.isChanged}
        onSave={() => {
          if (this.state.hasJsonEditor) {
            return this.saveJsonConfiguration();
          } else {
            return this.saveConfiguration();
          }
        }}
        isSaveDisabled={this.state.hasJsonEditor && !this.state.isJsonValid}
        onDirtyLeave={() => {
          if (this.state.hasJsonEditor || !this.state.isJsonParsable) {
            this.resetJsonConfiguration();
          } else {
            this.resetConfiguration();
          }
        }}
      >
        <ButtonToolbar className="no-wrap">
          {this.state.hasJsonEditor ? (
            <>
              {this.renderCloseJsonButton()}
              {this.renderSaveJsonButton()}
            </>
          ) : (
            <>
              {this.renderOpenJsonButton()}
              {this.renderSaveFormButton()}
            </>
          )}
        </ButtonToolbar>
      </CatchUnsavedChanges>
    );
  },

  renderCloseJsonButton() {
    if (!this.state.isParsable) {
      return (
        <SwitchButton
          isDisabled
          tooltip="Can't close the code editor, the configuration is not compatible. Revert your changes to allow switching back to the visual editor."
          label="Visual editor"
        />
      );
    }

    if (this.state.isJsonChanged) {
      return (
        <Confirm
          title="Close code editor"
          text="All unsaved changes will be discarded."
          buttonLabel="Confirm"
          onConfirm={this.closeJsonEditor}
        >
          <SwitchButton label="Visual editor" />
        </Confirm>
      );
    }

    return (
      <SwitchButton
        onClick={this.closeJsonEditor}
        label="Visual editor"
        tooltip="Close code editor"
      />
    );
  },

  renderOpenJsonButton() {
    if (this.state.isChanged) {
      return (
        <Confirm
          title="Open code editor"
          text="All unsaved changes will be discarded."
          buttonLabel="Confirm"
          onConfirm={this.openJsonEditor}
        >
          <SwitchButton label="Code editor" />
        </Confirm>
      );
    }

    return (
      <SwitchButton tooltip="Open code editor" label="Code editor" onClick={this.openJsonEditor} />
    );
  },

  renderSaveFormButton() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <SaveButtons
        isSaving={this.state.isSaving}
        isChanged={this.state.isChanged}
        onReset={this.resetConfiguration}
        onSave={this.saveConfiguration}
      />
    );
  },

  renderSaveJsonButton() {
    if (this.state.readOnly) {
      return null;
    }

    const showModal = this.state.isParsable && !this.state.isJsonParsable;

    return (
      <SaveButtons
        isSaving={this.state.isJsonSaving}
        isChanged={this.state.isJsonChanged}
        onReset={this.resetJsonConfiguration}
        onSave={this.saveJsonConfiguration}
        disabled={!this.state.isJsonValid}
        showModal={showModal}
        {...(showModal && IncompatibleConfigModalProps)}
      />
    );
  },

  saveConfiguration() {
    return Actions.saveConfiguration(
      this.state.componentId,
      this.state.configId,
      this.state.rowId,
      this.state.saveFn,
      this.state.parseFn,
    );
  },

  saveJsonConfiguration() {
    return Actions.saveJsonConfiguration(
      this.state.componentId,
      this.state.configId,
      this.state.rowId,
    );
  },

  resetConfiguration() {
    Actions.resetConfiguration(this.state.componentId, this.state.configId, this.state.rowId);
  },

  resetJsonConfiguration() {
    Actions.resetJsonConfiguration(this.state.componentId, this.state.configId, this.state.rowId);
  },

  closeJsonEditor() {
    Actions.closeJsonEditor(this.state.componentId, this.state.configId, this.state.rowId);
  },

  openJsonEditor() {
    Actions.openJsonEditor(this.state.componentId, this.state.configId, this.state.rowId);
  },
});

export default RowHeaderButton;
