import { Fade } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { cn, Icon, Tooltip } from '@keboola/design';

import automateImage from '../../../images/illustrations/automate.png';
import extractorsImage from '../../../images/illustrations/extractors.png';

import {
  KEBOOLA_EX_AWS_S_3,
  KEBOOLA_EX_GOOGLE_ANALYTICS_V_4,
  KEBOOLA_EX_GOOGLE_DRIVE,
  KEBOOLA_EX_HTTP,
  KEBOOLA_ORCHESTRATOR,
} from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import { routeNames as componentsRouteNames } from '@/modules/components-directory/constants';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import * as exDbGenericHelpers from '@/modules/ex-db-generic/helpers';
import { getSelectedPredefinedQuery } from '@/modules/ex-google-analytics-v4/helpers';
import { routeNames as jobsRouteNames } from '@/modules/jobs/Constants';
import LatestJobsStore from '@/modules/jobs/stores/LatestJobsStore';
import OauthStore from '@/modules/oauth-v2/Store';
import { JOBS_STATUS, routeNames as queueRouteNames } from '@/modules/queue/constants';
import { extractErrorDetails, hasInternalError } from '@/modules/queue/helpers';
import JobsStore from '@/modules/queue/store';
import ScheduleConfigurationButton from '@/modules/scheduler/components/ScheduleConfigurationButton';
import { prepareTablesMetadataMap } from '@/modules/storage/helpers';
import { RouterLink } from '@/react/common';
import ConfigurationInfoPanel from '@/react/common/ConfigurationInfoPanel';
import JobStatusLabelWithErrorAndIcon from '@/react/common/JobStatusLabelWithErrorAndIcon';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { fileUrl } from '@/utils/fileHelpers';
import getDefaultBucket from '@/utils/getDefaultBucket';
import string from '@/utils/string';
import Authorization from './components/Authorization';
import AwsLogin from './components/AwsLogin';
import AwsSourceSetting from './components/AwsSourceSetting';
import Credentials from './components/Credentials';
import DatabaseSourcesSelector from './components/DatabaseSourcesSelector';
import GoogleDriverSheetsPicker from './components/GoogleDriverSheetsPicker';
import HttpExSetup from './components/HttpExSetup';
import LoadingData from './components/LoadingData';
import PredefinedQuerySelector from './components/PredefinedQuerySelector';
import ProfilesManager from './components/ProfilesManager';
import Step from './components/Step';

const Index = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      RoutesStore,
      ComponentsStore,
      StorageBucketsStore,
      StorageTablesStore,
      InstalledComponentsStore,
      ConfigurationRowsStore,
      LatestJobsStore,
      JobsStore,
      OauthStore,
    ),
  ],

  getStateFromStores() {
    const hasNewQueue = ApplicationStore.hasNewQueue();
    const componentId = RoutesStore.getCurrentRouteComponentId();
    const configId = RoutesStore.getCurrentRouteParam('configId');
    const config = InstalledComponentsStore.getConfig(componentId, configId);
    const configData = InstalledComponentsStore.getConfigData(componentId, configId);
    const configRows = ConfigurationRowsStore.getRows(componentId, configId);

    return {
      configId,
      componentId,
      config,
      configData,
      configRows,
      hasNewQueue,
      component: ComponentsStore.getComponent(componentId),
      allComponents: ComponentsStore.getAll(),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      sapiToken: ApplicationStore.getSapiToken(),
      allBuckets: StorageBucketsStore.getAll(),
      allTables: StorageTablesStore.getAll(),
      exportingTables: StorageTablesStore.getExportingTables(),
      oauthCredentials: OauthStore.getCredentials(componentId, configData) || Map(),
      flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      lastJobs: hasNewQueue
        ? JobsStore.getLatestJobs(componentId, configId)
        : LatestJobsStore.getJobs(componentId, configId),
      readOnly: ApplicationStore.isReadOnly(),
      hasFlows: ApplicationStore.hasFlows(),
    };
  },

  getInitialState() {
    return {
      allFilesFinished: false,
    };
  },

  render() {
    return (
      <>
        <ConfigurationInfoPanel
          component={this.state.component}
          allComponents={this.state.allComponents}
          config={this.state.config}
          flows={this.state.flows}
          tablesMetadataMap={prepareTablesMetadataMap(this.state.allTables)}
          metadata={this.state.componentsMetadata}
        />
        <div className="simplified-form">{this.renderForm()}</div>
      </>
    );
  },

  renderForm() {
    if (this.state.componentId === KEBOOLA_EX_GOOGLE_DRIVE) {
      return (
        <>
          {this.renderAuthorizationStep()}
          {this.renderGooglePickerStep()}
          {this.renderLoadingStep(
            this.getGoogleDriveExpectedTables,
            !this.isGoogleDriveConfigured(),
          )}
          {this.renderAdditionalLinks()}
        </>
      );
    }

    if (this.state.componentId === KEBOOLA_EX_GOOGLE_ANALYTICS_V_4) {
      return (
        <>
          {this.renderAuthorizationStep()}
          {this.renderGoogleProfilesPickerStep()}
          {this.renderDataUsecasePickerStep()}
          {this.renderLoadingStep(
            this.getGoogleAnalyticsExpectedTables,
            !getSelectedPredefinedQuery(this.state.config),
          )}
          {this.renderAdditionalLinks()}
        </>
      );
    }

    if (this.state.componentId === KEBOOLA_EX_HTTP) {
      return (
        <>
          {this.renderHttpSetupStep()}
          {this.renderLoadingStep(this.getHttpExExpectedTables, !this.isHttpExConfigured())}
          {this.renderAdditionalLinks()}
        </>
      );
    }

    if (
      exDbGenericHelpers.supportConfigRows(this.state.componentId) &&
      exDbGenericHelpers.supportSimpleSetup(this.state.componentId)
    ) {
      return (
        <>
          {this.renderCredentialsStep()}
          {this.renderSelectSourcesStep()}
          {this.renderLoadingStep(this.getDatabaseExpectedTables, !this.isDatabaseRowsConfigured())}
          {this.renderAdditionalLinks()}
        </>
      );
    }

    if (this.state.componentId === KEBOOLA_EX_AWS_S_3) {
      return (
        <>
          {this.renderAwsLoginStep()}
          {this.renderAwsFileStep()}
          {this.renderLoadingStep(this.getAwsS3ExpectedTables, !this.isAwsS3Configured())}
          {this.renderAdditionalLinks()}
        </>
      );
    }

    return null;
  },

  renderAuthorizationStep() {
    return (
      <Step title="Authorization" isConfigured={this.isAuthorized()}>
        <Authorization
          sapiToken={this.state.sapiToken}
          componentId={this.state.componentId}
          configId={this.state.configId}
          readOnly={this.state.readOnly}
          oauthCredentials={this.state.oauthCredentials}
        />
      </Step>
    );
  },

  renderCredentialsStep() {
    return (
      <Step title="Credentials" isConfigured={this.hasCredentials()}>
        <Credentials
          componentId={this.state.componentId}
          configId={this.state.configId}
          configData={this.state.configData}
          readOnly={this.state.readOnly}
        />
      </Step>
    );
  },

  renderAwsLoginStep() {
    return (
      <Step title="AWS login" isConfigured={this.hasAwsLogin()}>
        <AwsLogin
          componentId={this.state.componentId}
          configId={this.state.configId}
          configData={this.state.configData}
          readOnly={this.state.readOnly}
        />
      </Step>
    );
  },

  renderAwsFileStep() {
    return (
      <Step
        title="Source setting"
        isConfigured={this.hasAwsSource()}
        isDisabled={!this.hasAwsLogin()}
      >
        <AwsSourceSetting
          componentId={this.state.componentId}
          configId={this.state.configId}
          configData={this.state.configData}
          configRows={this.state.configRows}
          readOnly={this.state.readOnly}
          hasNewQueue={this.state.hasNewQueue}
        />
      </Step>
    );
  },

  renderSelectSourcesStep() {
    return (
      <Step
        title="Select sources"
        isConfigured={this.isDatabaseRowsConfigured()}
        isDisabled={!this.hasCredentials()}
      >
        <DatabaseSourcesSelector
          componentId={this.state.componentId}
          configId={this.state.configId}
          configData={this.state.configData}
          configRows={this.state.configRows}
          readOnly={this.state.readOnly}
          hasNewQueue={this.state.hasNewQueue}
        />
      </Step>
    );
  },

  renderGooglePickerStep() {
    return (
      <Step
        title="Select sheets from Google Drive"
        isConfigured={this.isGoogleDriveConfigured()}
        isDisabled={!this.isAuthorized()}
      >
        <GoogleDriverSheetsPicker
          oauthCredentials={this.state.oauthCredentials}
          componentId={this.state.componentId}
          configId={this.state.configId}
          configData={this.state.configData}
          readOnly={this.state.readOnly}
          hasNewQueue={this.state.hasNewQueue}
        />
      </Step>
    );
  },

  renderGoogleProfilesPickerStep() {
    return (
      <Step
        title="Select Google Analytics profiles"
        isConfigured={this.isGoogleProfilesConfigured()}
        isDisabled={!this.isAuthorized()}
      >
        <ProfilesManager
          config={this.state.config}
          oauthCredentials={this.state.oauthCredentials}
          readOnly={this.state.readOnly}
        />
      </Step>
    );
  },

  renderDataUsecasePickerStep() {
    return (
      <Step
        title="Select desired data case"
        isConfigured={!!getSelectedPredefinedQuery(this.state.config)}
        isDisabled={!this.isAuthorized() || !this.isGoogleProfilesConfigured()}
      >
        <PredefinedQuerySelector
          componentId={this.state.componentId}
          configId={this.state.configId}
          config={this.state.config}
          readOnly={this.state.readOnly}
          hasNewQueue={this.state.hasNewQueue}
        />
      </Step>
    );
  },

  renderHttpSetupStep() {
    return (
      <Step title="Set up file source" isConfigured={this.isHttpExConfigured()}>
        <HttpExSetup
          config={this.state.config}
          readOnly={this.state.readOnly}
          hasNewQueue={this.state.hasNewQueue}
        />
      </Step>
    );
  },

  renderLoadingStep(getExpectedTables, isDisabled) {
    const job = this.state.lastJobs.getIn(['jobs', 0], Map());
    const isProcessing = [
      JOBS_STATUS.CREATED,
      JOBS_STATUS.WAITING,
      JOBS_STATUS.PROCESSING,
    ].includes(job.get('status'));
    const isExtractor = this.state.component.get('type') === componentTypes.EXTRACTOR;

    return (
      <Step
        paddingless
        title={
          isProcessing
            ? this.state.allFilesFinished
              ? 'Finishing job...'
              : `${isExtractor ? 'Loading' : 'Processing'} data...`
            : isExtractor
              ? 'Load data'
              : 'Result'
        }
        status={
          !job.isEmpty() && (
            <div className={cn('ml-1', { 'text-muted': isDisabled })}>
              Last job status:{' '}
              <Tooltip tooltip="Check job detail" placement="top">
                <RouterLink
                  className="no-underline"
                  to={this.state.hasNewQueue ? queueRouteNames.JOB_DETAIL : jobsRouteNames.DETAIL}
                  params={{ jobId: job.get('id') }}
                >
                  <JobStatusLabelWithErrorAndIcon job={job} />
                </RouterLink>
              </Tooltip>
            </div>
          )
        }
        isProcessing={isProcessing}
        isConfigured={job.get('status') === JOBS_STATUS.SUCCESS}
        isDisabled={isDisabled}
        onStartProcessing={() => this.setState({ allFilesFinished: false })}
      >
        <LoadingData
          key={job.get('runId', 'first-job')}
          job={job}
          isProcessing={isProcessing}
          allBuckets={this.state.allBuckets}
          allTables={this.state.allTables}
          getExpectedTables={getExpectedTables}
          hasNewQueue={this.state.hasNewQueue}
          allProcessingFinished={() => this.setState({ allFilesFinished: true })}
          exportingTables={this.state.exportingTables}
        />
      </Step>
    );
  },

  renderAdditionalLinks() {
    return (
      <div className="flex-container align-top justify-center mt-2 pt-2">
        <Fade in={!this.state.lastJobs.get('jobs', List()).isEmpty()}>
          <div className="max-w-400 text-center p-2">
            <img
              height={122}
              src={fileUrl(automateImage)}
              alt="Automate configuration"
              loading="lazy"
            />
            <h3 className="f-18 mt-0">Run this configuration periodically</h3>
            <p className="text-muted mb-2">
              Create a single task {this.state.hasFlows ? 'flow' : 'orchestration'} from this
              configuration.
            </p>
            <ScheduleConfigurationButton
              linkToIndex
              mode="button"
              flows={this.state.flows}
              component={this.state.component}
              config={this.state.config}
              hasNewQueue={this.state.hasNewQueue}
              hasFlows={this.state.hasFlows}
            />
          </div>
        </Fade>
        <Fade in={!this.state.lastJobs.get('jobs', List()).isEmpty()}>
          <div className="max-w-400 text-center p-2">
            <img
              height={122}
              src={fileUrl(extractorsImage)}
              alt="More data sources"
              loading="lazy"
            />
            <h3 className="f-18 mt-0">Not enough? Add other data sources</h3>
            <p className="text-muted mb-2">
              Add more data to your project by selecting another data source.
            </p>
            <RouterLink
              className="btn btn-success"
              to={componentsRouteNames.ROOT}
              onlyActiveOnIndex
            >
              <Icon icon="plus" className="icon-addon-right" />
              Add more data
            </RouterLink>
          </div>
        </Fade>
      </div>
    );
  },

  renderError(job) {
    if (
      job.get('status') !== 'error' ||
      (!hasInternalError(job) && !job.getIn(['result', 'message']))
    ) {
      return null;
    }

    return (
      <span className="job-status-label text-danger">
        {' - '}
        {hasInternalError(job)
          ? 'Internal Error'
          : string.truncate(extractErrorDetails(job.getIn(['result', 'message'])), 40)}
      </span>
    );
  },

  isAuthorized() {
    return !!this.state.oauthCredentials?.has('id');
  },

  hasCredentials() {
    return !this.state.configData.getIn(['parameters', 'db'], Map()).isEmpty();
  },

  hasAwsLogin() {
    return !!this.state.configData.getIn(['parameters', 'loginType']);
  },

  hasAwsSource() {
    return !this.state.configRows.isEmpty();
  },

  isGoogleDriveConfigured() {
    return (
      this.state.configData.hasIn(['parameters', 'outputBucket']) &&
      this.state.configData.hasIn(['parameters', 'sheets'])
    );
  },

  getGoogleDriveExpectedTables() {
    const bucketId = this.state.configData.getIn(['parameters', 'outputBucket']);

    return this.state.configData.getIn(['parameters', 'sheets'], List()).map((sheet) => {
      return `${bucketId}.${sheet.get('outputTable')}`;
    });
  },

  isDatabaseRowsConfigured() {
    return !this.state.configRows.isEmpty();
  },

  getDatabaseExpectedTables() {
    return this.state.configRows.map((row) => {
      return row.getIn(['configuration', 'parameters', 'outputTable']);
    });
  },

  isGoogleProfilesConfigured() {
    return !this.state.config
      .getIn(
        ['configuration', 'parameters', 'profiles'],
        this.state.config.getIn(['configuration', 'parameters', 'properties'], List()),
      )
      .isEmpty();
  },

  getAwsS3ExpectedTables() {
    return List([`${getDefaultBucket('in', KEBOOLA_EX_AWS_S_3, this.state.configId)}.data`]);
  },

  isAwsS3Configured() {
    return !this.state.configRows.isEmpty();
  },

  getGoogleAnalyticsExpectedTables() {
    const bucketId = this.state.configData.getIn(['parameters', 'outputBucket'], '');

    return this.state.configRows
      .toList()
      .map((row) => `${bucketId}.${row.getIn(['configuration', 'parameters', 'outputTable'])}`)
      .update((rows) => {
        if (this.state.config.hasIn(['configuration', 'parameters', 'profiles'])) {
          return rows.push(`${bucketId}.profiles`);
        }

        if (this.state.config.hasIn(['configuration', 'parameters', 'properties'])) {
          return rows.push(`${bucketId}.properties`);
        }

        return rows;
      });
  },

  isHttpExConfigured() {
    if (!this.state.config.getIn(['configuration', 'parameters', 'baseUrl'])) {
      return false;
    }

    return !!this.state.config.getIn(['rows', 0, 'configuration', 'parameters', 'path']);
  },

  getHttpExExpectedTables() {
    return List([`${getDefaultBucket('in', KEBOOLA_EX_HTTP, this.state.configId)}.data`]);
  },
});

export default Index;
