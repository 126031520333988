import _ from 'underscore';

import string from '@/utils/string';

const RowsCount = (props: { count?: number }) => {
  if (!_.isNumber(props.count)) {
    return <span>N/A</span>;
  }

  return <span>{string.numberFormat(props.count)}</span>;
};

export default RowsCount;
