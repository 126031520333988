import createReactClass from 'create-react-class';

import { canManageTokens } from '@/modules/admin/privileges';
import NewOrchestrationButton from '@/modules/orchestrations/react/modals/NewOrchestration';
import OrchestrationsStore from '@/modules/orchestrations/stores/OrchestrationsStore';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';

const NewOrchestrationHeaderButton = createReactClass({
  mixins: [createStoreMixin(OrchestrationsStore, ApplicationStore)],

  getStateFromStores() {
    return {
      hasOrchestrations: OrchestrationsStore.getAll().count(),
      sapiTokenCanManageTokens: canManageTokens(ApplicationStore.getSapiToken()),
    };
  },

  render() {
    if (!this.state.hasOrchestrations || !this.state.sapiTokenCanManageTokens) {
      return null;
    }

    return <NewOrchestrationButton />;
  },
});

export default NewOrchestrationHeaderButton;
