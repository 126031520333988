import type { ComponentProps, ReactNode } from 'react';
import { Button } from 'react-bootstrap';

import { Icon, Tooltip } from '@keboola/design';

import Confirm from './Confirm';
import Loader from './Loader';

type Props = {
  confirm: {
    title: string;
    onConfirm: () => Promise<void>;
    text: ReactNode;
    buttonLabel?: string;
    closeAfterResolve?: boolean;
  };
  isPending?: boolean;
  tooltip?: string;
  isEnabled?: boolean;
  label?: string;
  pendingLabel?: string;
  icon?: ComponentProps<typeof Icon>['icon'];
  hideIcon?: boolean;
  buttonClass?: string;
  buttonStyle?: string;
};

const DeleteButton = ({
  confirm,
  isPending = false,
  tooltip,
  isEnabled = true,
  label = '',
  pendingLabel = '',
  icon = 'trash',
  hideIcon = false,
  buttonClass = '',
  buttonStyle = 'link',
}: Props) => {
  if (isPending && !confirm.closeAfterResolve) {
    return (
      <Button bsStyle={buttonStyle} className={buttonClass} disabled>
        {pendingLabel ? (
          <>
            <Loader className="icon-addon-right" />
            {pendingLabel}
          </>
        ) : (
          <Loader />
        )}
      </Button>
    );
  }

  const buttonLabel = label ? (
    <>
      {!hideIcon && <Icon icon={icon} className="icon-addon-right" />}
      {label}
    </>
  ) : (
    <Icon icon={icon} fixedWidth />
  );

  if (!isEnabled) {
    return (
      <Button bsStyle={buttonStyle} className={buttonClass} disabled>
        {buttonLabel}
      </Button>
    );
  }

  const finalTooltip = tooltip || (!label ? 'Delete' : '');

  return (
    <Confirm
      icon="trash"
      buttonLabel={isPending ? 'Deleting...' : 'Delete'}
      {...confirm}
      {...(confirm.closeAfterResolve && { isLoading: isPending })}
    >
      <Tooltip tooltip={finalTooltip} placement="top" forceHide={!finalTooltip}>
        <Button bsStyle={buttonStyle} className={buttonClass} disabled={isPending}>
          {buttonLabel}
        </Button>
      </Tooltip>
    </Confirm>
  );
};

export default DeleteButton;
