import { Button } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';

import { Icon } from '@keboola/design';

import { TDE_EXPORTER as componentId } from '@/constants/componentIds';
import InstalledComponentsActions from '@/modules/components/InstalledComponentsActionCreators';
import ComponentEmptyState from '@/modules/components/react/components/ComponentEmptyState';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import OAuthStore from '@/modules/oauth-v2/Store';
import { OAUTH_V2_WRITERS } from '@/modules/tde-exporter/tdeCommon';
import uploadUtils from '@/modules/tde-exporter/uploadUtils';
import ActivateDeactivateButton from '@/react/common/ActivateDeactivateButton';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import OauthV2WriterRow from './OauthV2WriterRow';
import TableauServerRow from './TableauServerRow';
import SelectWriterModal from './WritersModal';

const Destination = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, InstalledComponentsStore, OAuthStore)],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');

    return {
      configId,
      configData: InstalledComponentsStore.getConfigData(componentId, configId),
      localState: InstalledComponentsStore.getLocalState(componentId, configId),
      isSaving: InstalledComponentsStore.isSavingConfigData(componentId, configId),
      readOnly: ApplicationStore.isReadOnly(),
      admins: ApplicationStore.getAdmins(),
    };
  },

  render() {
    let destinationRow = (
      <ComponentEmptyState>
        <p>Upload destination is not chosen</p>
        <Button bsStyle="success" onClick={this._showWritersModal}>
          Choose Destination
        </Button>
      </ComponentEmptyState>
    );

    const task = this.state.configData.getIn(['parameters', 'stageUploadTask']);
    switch (task) {
      case 'tableauServer':
        destinationRow = this._renderTableauServer();
        break;
      default:
        break;
    }

    if (OAUTH_V2_WRITERS.includes(task)) {
      destinationRow = this._renderOAuthV2Writer(task);
    }

    return (
      <>
        <div className="box">
          <div className="box-content">{destinationRow}</div>
        </div>
        <SelectWriterModal
          isSaving={this.state.isSaving}
          localState={this.state.localState.get('writersModal', Map())}
          setLocalState={(key, value) =>
            this._updateLocalState(['writersModal'].concat(key), value)
          }
          onChangeWriterFn={(newTask) => {
            let params = this.state.configData.get('parameters') || Map();
            params = params.set('stageUploadTask', newTask);
            params = params.set('uploadTasks', List());
            return this._saveConfigData(['parameters'], params).then(() => {
              return this._updateLocalState(['writersModal', 'show'], false);
            });
          }}
        />
      </>
    );
  },

  _renderOAuthV2Writer(component) {
    const parameters = this.state.configData.get('parameters');
    const componentData = parameters.get(component, Map());
    const credentialsId = componentData.get('id');
    const oauthCredentials =
      credentialsId && OAuthStore.getCredentialsById(component, credentialsId);
    const isAuthorized = uploadUtils.isOauthV2Authorized(parameters, component) && oauthCredentials;

    return (
      <OauthV2WriterRow
        componentData={componentData}
        configId={this.state.configId}
        localState={this.state.localState}
        updateLocalState={this._updateLocalState}
        componentId={component}
        isAuthorized={!!isAuthorized}
        oauthCredentials={oauthCredentials}
        setConfigDataFn={this._saveConfigData}
        renderComponent={() => this._renderComponentCol(component)}
        renderEnableUpload={() => this._renderEnableUploadCol(component, isAuthorized)}
        resetUploadTask={() => this._resetUploadTask(component)}
        readOnly={this.state.readOnly}
        admins={this.state.admins}
      />
    );
  },

  _renderTableauServer() {
    const parameters = this.state.configData.get('parameters');
    const account = this.state.configData.getIn(['parameters', 'tableauServer']);
    const isAuthorized = uploadUtils.isTableauServerAuthorized(parameters);

    return (
      <TableauServerRow
        configId={this.state.configId}
        localState={this.state.localState}
        updateLocalStateFn={this._updateLocalState}
        account={account}
        setConfigDataFn={this._saveConfigData}
        renderComponent={() => this._renderComponentCol('wr-tableau-server')}
        renderEnableUpload={() => this._renderEnableUploadCol('tableauServer', isAuthorized)}
        resetUploadTask={() => this._resetUploadTask('tableauServer')}
      />
    );
  },

  _saveConfigData(path, data) {
    const newData = this.state.configData.setIn(path, data);
    return InstalledComponentsActions.saveComponentConfigData(
      componentId,
      this.state.configId,
      fromJS(newData),
    );
  },

  _updateLocalState(path, data) {
    const newLocalState = this.state.localState.setIn(path, data);
    return InstalledComponentsActions.updateLocalState(
      componentId,
      this.state.configId,
      newLocalState,
      path,
    );
  },

  _renderComponentCol(id) {
    const component = ComponentsStore.getComponent(id);

    return (
      <span>
        <ComponentIcon component={component} className="icon-addon-right" size="32" />
        <ComponentName component={component} />{' '}
        <Button bsStyle="success" onClick={this._showWritersModal} className="ml-2">
          <Icon icon="pen" className="icon-addon-right" />
          Change
        </Button>
      </span>
    );
  },

  _showWritersModal() {
    return this._updateLocalState(['writersModal', 'show'], true);
  },

  _hasUploadTask(taskName) {
    const tasks = this.state.configData.getIn(['parameters', 'uploadTasks'], List());
    return !!tasks.find((t) => t === taskName);
  },

  _toggleImmediateUpload(taskName, isActive) {
    const newTasks = isActive ? List() : List([taskName]);
    return this._saveConfigData(['parameters', 'uploadTasks'], newTasks);
  },

  _renderEnableUploadCol(componentKey, isAuthorized) {
    if (!isAuthorized) {
      return null;
    }

    const isActive = this._hasUploadTask(componentKey); // gdrive, dropbox, # tableauServer

    return (
      <>
        <ActivateDeactivateButton
          key="active"
          isActive={isActive}
          isPending={this.state.isSaving}
          onChange={() => this._toggleImmediateUpload(componentKey, isActive)}
        />
        <p className="help-block mt-1">
          {isActive
            ? 'All TDE files will be uploaded to selected destination immediately after the export.'
            : 'No TDE files will be uploaded after the export.'}
        </p>
      </>
    );
  },

  _resetUploadTask(taskName) {
    let params = this.state.configData.getIn(['parameters'], Map());
    params = params.delete(taskName);
    params = params.set('uploadTasks', List());
    params = params.delete('stageUploadTask');
    return this._saveConfigData(['parameters'], params);
  },
});

export default Destination;
