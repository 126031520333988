import { fromJS, List, type Map } from 'immutable';
import _ from 'underscore';

import type { AutomationDetail, AutomationMessage, UserMessage } from '@/api/routes/aiService';
import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { findAutomationIdFromMetadata } from '@/modules/components/helpers';
import { roles } from './constants';

const getCurrentAutomationId = (
  routerState: Map<string, any>,
  componentsMetadata: Map<string, any>,
) => {
  return routerState.getIn(
    ['location', 'state', 'automationId'],
    findAutomationIdFromMetadata(
      KEBOOLA_ORCHESTRATOR,
      routerState.getIn(['params', 'config']),
      componentsMetadata,
    ),
  ) as string | undefined;
};

const constructMessage = (
  { text = null, type = 'plainText', role = roles.USER, data = {} }: Partial<UserMessage>,
  context: {
    flowConfiguration?: Record<string, any>;
    taskId?: string;
    componentId?: string;
    configurationId?: string;
    rowId?: string;
    configuration?: Record<string, any>;
  } = {},
  allComponents: Record<string, any> = {},
): UserMessage => {
  const messageObject = { text, type, role, data };
  const { flowConfiguration, taskId, componentId, configurationId, rowId, configuration } = context;

  if (taskId || messageObject.data.taskId) {
    return {
      ...messageObject,
      ...(type === 'plainText' && {
        type:
          componentId && allComponents[componentId]?.type === componentTypes.TRANSFORMATION
            ? 'transformationEditInProgress'
            : 'configurationEditInProgress',
      }),
      data: {
        taskId,
        componentId,
        configurationId,
        rowId,
        configuration,
        ...messageObject.data,
      },
    };
  }

  if (!_.isEmpty(flowConfiguration)) {
    return {
      ...messageObject,
      type: 'flowConfigurationInProgress' as const,
      data: {
        configuration: flowConfiguration,
        ...messageObject.data,
      },
    };
  }

  return messageObject;
};

const isInteractiveMessage = (messageObject?: AutomationMessage) => {
  if (!messageObject) return false;

  return [
    'componentSelectionRequest',
    'sourceTableSelectionRequest',
    'intentConfirmationRequest',
    'flowGenerated',
  ].includes(messageObject.type);
};

const injectSourceTablesToVisualizationPhases = (
  visualizationPhases: List<Map<string, any>>,
  allTables: Map<string, any>,
  sourceTables?: AutomationDetail['sourceTables'],
) => {
  const phases = visualizationPhases;
  const sourceTablesTasks = fromJS(
    sourceTables?.map((tableId) => ({
      id: tableId,
      name: allTables.getIn([tableId, 'displayName']),
      component: allTables.getIn([tableId, 'bucket', 'displayName']),
      icon: 'table',
      type: 'Table',
      specificRows: [],
    })),
  )?.toList() as List<any>;

  if (!sourceTablesTasks || !phases) return phases;

  // If there is no 'Data source' phase, add it. It should always have id `1`.
  if (!phases.some((phase) => phase?.get('id') === 1)) {
    return phases.unshift(
      fromJS({
        id: 1,
        name: 'Data source',
        key: '1-Data source',
        isFake: true,
        tasks: sourceTablesTasks,
      }),
    );
  }

  return phases.map((phase) => {
    if (phase?.get('id') === 1) {
      return phase.update('tasks', List(), (tasks) => tasks.concat(sourceTablesTasks));
    }

    return phase;
  });
};

export {
  getCurrentAutomationId,
  constructMessage,
  isInteractiveMessage,
  injectSourceTablesToVisualizationPhases,
};
