import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { FormGroup, HelpBlock, Label, TextInput } from '@keboola/design';

import { bucketDisplayNameWithStage } from '@/modules/storage/helpers';
import Checkbox from '@/react/common/Checkbox';
import PasswordControl from '@/react/common/PasswordControl';
import Select from '@/react/common/Select';
import { bucketLabel } from '@/react/common/selectLabels';

const NewProjectForm = createReactClass({
  propTypes: {
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    buckets: PropTypes.instanceOf(Map).isRequired,
  },

  handleChange(newDiff) {
    this.props.onChange({ ...this.props.value, ...newDiff });
  },

  allBucketsOptions() {
    return this.props.buckets
      .sortBy((bucket) => bucketDisplayNameWithStage(bucket))
      .map((bucket) => ({
        value: bucket.get('id'),
        label: bucketLabel(bucket),
        name: bucket.get('displayName'),
      }))
      .toArray();
  },

  render() {
    return (
      <div className="tw-mb-4 tw-flex tw-flex-col tw-gap-4">
        {this.renderInputControlGroup(
          'Project ID',
          'pid',
          'Type project ID',
          'ID of GoodData project/workspace',
        )}
        {this.renderInputControlGroup(
          'Username',
          'login',
          'Type username',
          'Username of GoodData user',
        )}
        {this.renderInputControlGroup(
          'Password',
          'password',
          'Type password',
          'Password of GoodData user',
        )}
        {this.renderInputControlGroup(
          'Backend URL (optional)',
          'backendUrl',
          'Type URL',
          'If empty, the default (https://secure.gooddata.com) will be used.',
        )}
        <FormGroup className="tw-grid tw-grid-cols-3 tw-gap-4">
          <div className="tw-col-span-2 tw-col-start-2">
            <Checkbox
              checked={this.props.value.readLDM}
              onChange={(checked) => this.handleChange({ readLDM: checked })}
              disabled={this.props.disabled}
            >
              Read LDM model
            </Checkbox>
          </div>
        </FormGroup>
        {this.props.value.readLDM && (
          <FormGroup className="tw-grid tw-grid-cols-3 tw-gap-4">
            <Label htmlFor="target-bucket">Target bucket</Label>
            <Select
              id="target-bucket"
              className="tw-col-span-2"
              options={this.allBucketsOptions()}
              value={this.props.value.targetBucket}
              onChange={(selected) => this.handleChange({ targetBucket: selected })}
            />
          </FormGroup>
        )}
      </div>
    );
  },

  renderInputControlGroup(label, fieldName, placeholder, help) {
    const { disabled, value } = this.props;

    return (
      <FormGroup className="tw-grid tw-grid-cols-3 tw-items-center tw-gap-4" key={fieldName}>
        <Label htmlFor={fieldName}>{label}</Label>
        <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
          {fieldName === 'password' ? (
            <PasswordControl
              placeholder={placeholder}
              disabled={disabled}
              onChange={(e) => this.handleChange({ [fieldName]: e.target.value })}
              value={value[fieldName] || ''}
            />
          ) : (
            <TextInput
              variant="secondary"
              name={fieldName}
              placeholder={placeholder}
              disabled={disabled}
              onChange={(value) => this.handleChange({ [fieldName]: value })}
              value={value[fieldName] || ''}
            />
          )}
          <HelpBlock>{help}</HelpBlock>
        </div>
      </FormGroup>
    );
  },
});

export default NewProjectForm;
