import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import InputMapping from './InputMapping';

const InputTab = createReactClass({
  propTypes: {
    onSelect: PropTypes.func.isRequired,
    buckets: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    mapping: PropTypes.object.isRequired,
    exclude: PropTypes.object,
  },

  getInitialState() {
    return {
      isSaving: false,
    };
  },

  render() {
    return (
      <InputMapping
        mapping={this.props.mapping}
        buckets={this.props.buckets}
        tables={this.props.tables}
        onChange={this.props.onSelect}
        disabled={this.state.isSaving}
        exclude={this.props.exclude}
      />
    );
  },
});

export default InputTab;
