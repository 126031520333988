import { cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import { components } from 'react-select';
import { CSSTransition } from 'react-transition-group';
import { List, Map } from 'immutable';
import _ from 'underscore';

import { Badge, ButtonInline, cn, Icon, Tooltip } from '@keboola/design';

import { ORCHESTRATOR } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import keyCodes from '@/constants/keyCodes';
import dayjs, { DATE_FORMAT, DATE_FORMAT_WITHOUT_YEAR, TIME_FORMAT } from '@/date';
import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import ComponentTypeIcon from '@/modules/components-directory/components/ComponentTypeIcon';
import { JOBS_STATUS } from '@/modules/queue/constants';
import { Truncated } from '@/react/common';
import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';
import ComponentIcon from '@/react/common/ComponentIcon';
import DatePicker from '@/react/common/DatePicker';
import Gravatar from '@/react/common/Gravatar';
import MarkedText from '@/react/common/MarkedText';
import Select from '@/react/common/Select';
import { matchByWords } from '@/utils';

class JobsSearch extends Component {
  state = {
    openDatePicker: false,
    moreFilters:
      this.props.query.has('timeRange') ||
      this.props.query.has('duration') ||
      this.props.query.has('runId') ||
      this.props.query.has('row'),
    runIdFilter: this.props.query.get('runId', ''),
  };

  componentDidUpdate(prevProps, prevState) {
    const activeRunId = this.props.query.get('runId', '');

    if (
      activeRunId !== this.state.runIdFilter &&
      prevState.runIdFilter === this.state.runIdFilter
    ) {
      this.setState({ runIdFilter: activeRunId });
    }
  }

  render() {
    return (
      <div id="jobs-advanced-search" className="mbp-6">
        <div className="filters-container">
          {this.renderStatusSelect()}
          {this.renderUserSelect()}
          {this.renderComponentSelect()}
          {this.renderConfigurationSelect()}
        </div>
        <CSSTransition timeout={300} in={this.state.moreFilters} classNames="slide-down">
          <div className={cn('slide-down', { closed: !this.state.moreFilters })}>
            <div className="filters-container ptp-3">
              {this.renderTimeRangeSelect()}
              {this.renderDurationSelect()}
              {this.renderRunIdInput()}
              {this.renderRowSelect()}
            </div>
          </div>
        </CSSTransition>
        <div className="flex-container flex-end inline-flex controls">
          {/*{this.renderChildJobsSwitch()}*/}
          {/*<span className="btn-separator" />*/}
          {this.renderFiltersControl()}
        </div>
      </div>
    );
  }

  renderChildJobsSwitch() {
    const isActive = !this.props.query.has('excludeChildJobs');

    return (
      <div className="flex-container inline-flex flex-start">
        <span className="text-muted">Show Child Jobs</span>
        <ActivateDeactivateSwitch
          withLabel
          isActive={isActive}
          onChange={() => this.changeQuery('excludeChildJobs', isActive)}
          className="pr-0"
          tooltipPlacement="bottom"
          activateTooltip="Disabled (click to enable)"
          deactivateTooltip="Enabled (click to disable)"
        />
      </div>
    );
  }

  renderFiltersControl() {
    return (
      <ButtonInline onClick={() => this.setState({ moreFilters: !this.state.moreFilters })}>
        <Icon icon="sliders" />
        {this.state.moreFilters ? 'Hide' : 'Show'} Advanced Filters
      </ButtonInline>
    );
  }

  renderStatusSelect() {
    return (
      <Select
        multi
        clearable={false}
        hideSelectAllOptions
        placeholder="Status"
        multiValueRenderer={this.renderMultiValue}
        value={this.props.query.get('status')}
        onChange={(selected) => this.changeQuery('status', selected)}
        options={[
          { value: JOBS_STATUS.SUCCESS, label: 'Success' },
          { value: JOBS_STATUS.ERROR, label: 'Error' },
          { value: JOBS_STATUS.WARNING, label: 'Warning' },
          { value: JOBS_STATUS.CREATED, label: 'Created' },
          { value: JOBS_STATUS.WAITING, label: 'Waiting' },
          { value: JOBS_STATUS.PROCESSING, label: 'Processing' },
          { value: JOBS_STATUS.TERMINATED, label: 'Terminated' },
        ]}
      />
    );
  }

  renderUserSelect() {
    return (
      <Select
        multi
        allowCreate
        clearable={false}
        hideSelectAllOptions
        placeholder="User"
        multiValueRenderer={this.renderMultiValue}
        value={this.props.query.get('user')}
        promptTextCreator={(value) => `Search for "${value}"`}
        onChange={(selected) => this.changeQuery('user', selected)}
        options={this.props.admins
          .map((admin) => {
            const label = admin.get('name') || admin.get('email');

            return {
              value: admin.get('email'),
              label: (inputString) => (
                <div className="flex-container flex-start">
                  <Gravatar size={16} user={admin} className="mrp-2" />
                  <Truncated
                    tooltip={label}
                    text={<MarkedText source={label} mark={inputString} />}
                  />
                </div>
              ),
              selectedLabel: label,
              name: admin.get('name'),
            };
          })
          .sortBy(({ value }) => value !== this.props.currentUserEmail)
          .toArray()}
      />
    );
  }

  renderComponentSelect() {
    return (
      <Select
        allowCreate
        placeholder="Component"
        value={this.props.query.get('component', this.props.query.get('componentType'))}
        promptTextCreator={(value) => `Search for "${value}"`}
        onChange={(selected) => this.changeQuery('component', selected)}
        options={this.props.installedComponents
          .filter((component) => !component.get('configurations', Map()).isEmpty())
          .groupBy((component) => component.get('type'))
          .sortBy((components, typeCategory) => typeCategory === 'other')
          .reduce((options, components, typeCategory) => {
            const typeLabel = getNewComponentTypeLabel(typeCategory, { showOtherType: true });
            let newOptions = options;

            if (typeCategory === 'other') {
              components = components.delete(ORCHESTRATOR);
            }

            newOptions = options.push({
              value: typeCategory,
              label: (inputString) => (
                <Badge>
                  <ComponentTypeIcon type={typeCategory} size="16" className="mrp-2" />
                  <Truncated
                    twoLines
                    tooltip={`${typeLabel}s`}
                    text={<MarkedText source={`${typeLabel}s`} mark={inputString} />}
                  />
                </Badge>
              ),
              selectedLabel: `${typeLabel}s`,
              name: typeLabel,
              className: 'font-semibold options-group-label',
              isOptionsGroup: true,
            });

            return newOptions.concat(
              components.map((component, componentId) => {
                const componentLabel = this.getComponentLabel(component);

                return {
                  value: componentId,
                  label: (inputString) => (
                    <div className="flex-container flex-start">
                      <ComponentIcon component={component} size="16" className="mrp-2" />
                      <Truncated
                        twoLines
                        tooltip={componentLabel}
                        text={<MarkedText source={componentLabel} mark={inputString} />}
                      />
                    </div>
                  ),
                  selectedLabel: componentLabel,
                  name: component.get('name'),
                };
              }),
            );
          }, List())}
      />
    );
  }

  renderConfigurationSelect() {
    return (
      <Select
        allowCreate
        placeholder="Configuration"
        value={
          this.props.query.has('component') && this.props.query.has('config')
            ? `${this.props.query.get('component')}:${this.props.query.get('config')}`
            : this.props.query.get('config')
        }
        promptTextCreator={(value) => `Search for "${value}"`}
        onChange={(selected) => this.changeQuery('config', selected)}
        options={this.props.installedComponents
          .filter(
            (component, componentId) =>
              (!this.props.query.get('component') ||
                this.props.query.get('component') === componentId) &&
              (!this.props.query.get('componentType') ||
                this.props.query.get('componentType') === component.get('type')) &&
              !component.get('configurations', Map()).isEmpty(),
          )
          .reduce((options, component, componentId) => {
            let newOptions = options;

            if (!this.props.query.get('component')) {
              const componentLabel = this.getComponentLabel(component);

              newOptions = options.push({
                value: componentId,
                label: (inputString) => (
                  <Badge>
                    <ComponentIcon component={component} size="16" className="mrp-2" />
                    <Truncated
                      tooltip={componentLabel}
                      text={<MarkedText source={componentLabel} mark={inputString} />}
                    />
                  </Badge>
                ),
                selectedLabel: componentLabel,
                className: 'font-semibold options-group-label',
                isOptionsGroup: true,
                isDisabled: true,
              });
            }

            return newOptions.concat(
              component
                .get('configurations', Map())
                .map((config, configId) => ({
                  value: `${component.get('id')}:${configId}`,
                  label: (inputString) => (
                    <div className="flex-container flex-start">
                      <ComponentIcon component={component} size="16" className="mrp-2" />
                      <Truncated
                        twoLines
                        tooltip={config.get('name')}
                        text={<MarkedText source={config.get('name')} mark={inputString} />}
                      />
                    </div>
                  ),
                  selectedLabel: config.get('name'),
                  name: `${component.get('id')} ${component.get('name')} ${config.get('name')}`,
                }))
                .toArray(),
            );
          }, List())}
        filterOption={(option, filter) => {
          return !option.isOptionsGroup && matchByWords([option.value, option.name], filter);
        }}
      />
    );
  }

  renderTimeRangeSelect() {
    const options = [
      { value: '-1 hour', label: '1 hour' },
      { value: '-7 days', label: '7 days' },
      { value: '-30 days', label: '30 days' },
      { value: '-90 days', label: '90 days' },
      { value: 'custom', label: 'Custom' },
    ];

    let selected = options.find(({ value }) => {
      return value === this.props.query.getIn(['timeRange', 'start']);
    })?.value;

    if (!selected && this.props.query.hasIn(['timeRange', 'start'])) {
      const start = dayjs(this.props.query.getIn(['timeRange', 'start']));
      const end = dayjs(this.props.query.getIn(['timeRange', 'end']));
      const currentYear = start.year() === dayjs().year() && end.year() === dayjs().year();
      const timeSet = !!start.hour() || !!start.minute() || !!end.hour() || !!end.minute();
      const targetFormat = `${currentYear ? DATE_FORMAT_WITHOUT_YEAR : DATE_FORMAT}${
        timeSet ? ` ${TIME_FORMAT}` : ''
      }`;

      if (
        this.props.query.hasIn(['timeRange', 'start']) &&
        this.props.query.hasIn(['timeRange', 'end'])
      ) {
        selected = `${start.format(targetFormat)} - ${end.format(targetFormat)}`;
      } else {
        selected = start.format(targetFormat);
      }
    }

    return (
      <span className="time-range-container">
        <Select
          placeholder="Time Range"
          value={selected}
          onChange={(selected) => {
            if (selected === 'custom') {
              this.setState({ openDatePicker: true });
            } else {
              this.changeQuery('timeRange', { start: selected });
            }
          }}
          options={options}
        />
        {this.state.openDatePicker && (
          <DatePicker
            initialValue={
              this.props.query.has('timeRange')
                ? JSON.stringify(this.props.query.get('timeRange').toJS())
                : void 0
            }
            onSelect={(selected) => {
              this.changeQuery('timeRange', selected);
              this.setState({ openDatePicker: false });
            }}
          />
        )}
      </span>
    );
  }

  renderDurationSelect() {
    const options = [
      { value: { start: '0', end: '120' }, label: '0 - 2 min' },
      { value: { start: '120', end: '600' }, label: '2 - 10 min' },
      { value: { start: '600', end: '1800' }, label: '10 - 30 min' },
      { value: { start: '1800', end: '3600' }, label: '30 - 60 min' },
      { value: { start: '3600' }, label: '> 1 hour' },
    ];
    const selected = options.find(({ value: { start, end } }) => {
      return (
        start === this.props.query.getIn(['duration', 'start']) &&
        end === this.props.query.getIn(['duration', 'end'])
      );
    })?.value;

    return (
      <Select
        placeholder="Duration"
        value={selected}
        onChange={(selected) => this.changeQuery('duration', selected)}
        options={options}
      />
    );
  }

  renderRunIdInput() {
    return (
      <FormControl
        type="text"
        placeholder="Run ID"
        value={
          this.state.runIdFilter === this.props.query.get('runId', '')
            ? this.props.query.get('runId', '')
            : this.state.runIdFilter
        }
        onChange={({ target }) => {
          this.saveRunIdDebounced(target.value);
          this.setState({ runIdFilter: target.value.trim() });
        }}
        onBlur={({ target }) => this.saveRunId(target.value)}
        onKeyDown={(event) => event.key === keyCodes.ENTER && this.saveRunId(event.target.value)}
      />
    );
  }

  renderRowSelect() {
    const options = this.props.installedComponents
      .getIn(
        [
          this.props.query.get('component'),
          'configurations',
          this.props.query.get('config'),
          'rows',
        ],
        List(),
      )
      .map((row) => {
        return {
          value: row.get('id'),
          label: (inputString) => (
            <div className="flex-container flex-start">
              <ComponentIcon
                size="16"
                className="mrp-2"
                component={this.props.installedComponents.get(this.props.query.get('component'))}
              />
              <Truncated
                tooltip={row.get('name')}
                text={<MarkedText source={row.get('name')} mark={inputString} />}
              />
            </div>
          ),
          selectedLabel: row.get('name'),
          name: row.get('name'),
        };
      });

    if (
      (!this.props.query.has('config') ||
        !this.props.query.has('component') ||
        options.isEmpty()) &&
      !this.props.query.has('row')
    ) {
      return (
        <Tooltip
          placement="top"
          type="explanatory"
          tooltip="First you have to select a configuration with config row/s."
          triggerClassName="tw-block"
        >
          <Select placeholder="Row" disabled />
        </Tooltip>
      );
    }

    return (
      <Select
        allowCreate
        placeholder="Configuration Row"
        options={options.toArray()}
        value={this.props.query.get('row')}
        onChange={(selected) => this.changeQuery('row', selected)}
      />
    );
  }

  renderMultiValue(props) {
    const values = props.selectProps?.value.length;
    const isFirst = _.isEqual(props.data, props.selectProps?.value[0]);

    if (values > 1 && !isFirst) {
      return null;
    }

    if (values > 1) {
      return (
        <components.MultiValueContainer {...props}>
          <components.MultiValueLabel {...props.children[0].props}>
            {values} {props.selectProps.placeholder}
            {props.selectProps.placeholder === 'Status' ? 'es' : 's'}
          </components.MultiValueLabel>
          {cloneElement(props.children[1], {
            ...props.children[1].props,
            innerProps: {
              ...props.children[1].props.innerProps,
              onClick: () => props.selectProps.onChange(null, 'clear'),
            },
          })}
        </components.MultiValueContainer>
      );
    }

    return <components.MultiValueContainer {...props} />;
  }

  getComponentLabel(component) {
    const componentType = component.get('type');
    const type = [componentTypes.EXTRACTOR, componentTypes.WRITER].includes(componentType)
      ? ` ${getNewComponentTypeLabel(componentType)}`
      : '';

    return component.get('name') + type;
  }

  changeQuery = (key, value) => {
    if (key !== 'runId' || !value) {
      this.setState({ runIdFilter: '' });
    }

    this.props.changeQuery(key, value);
  };

  saveRunId = (value) => {
    const trimmedValue = value.trim();

    if (trimmedValue !== this.props.query.get('runId', '').trim()) {
      this.changeQuery('runId', trimmedValue);
      this.setState({ runIdFilter: trimmedValue });
    }
  };

  saveRunIdDebounced = _.debounce(this.saveRunId, 500);
}

JobsSearch.propTypes = {
  query: PropTypes.instanceOf(Map).isRequired,
  changeQuery: PropTypes.func.isRequired,
  admins: PropTypes.instanceOf(Map).isRequired,
  installedComponents: PropTypes.instanceOf(Map).isRequired,
  currentUserEmail: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default JobsSearch;
