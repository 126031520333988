import { List, Map } from 'immutable';

import { KEBOOLA_SHARED_CODE } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import SidebarVersions from '@/modules/components/react/components/SidebarVersions';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import VersionsStore from '@/modules/components/stores/VersionsStore';
import { routeNames as componentsRoutes } from '@/modules/components-directory/constants';
import RowVersionsStore from '@/modules/configurations/RowVersionsStore';
import { routeNames as transformationRoutes } from '@/modules/transformations-v2/constants';
import useStores from '@/react/hooks/useStores';

type Props = {
  componentId: string;
  configId: string;
  rowId?: string;
  versionsLinkTo?: string;
  component: Map<string, any>;
  configuration: Map<string, any>;
  admins: Map<string, any>;
  row?: Map<string, any>;
};

const Versions = ({
  componentId,
  configId,
  rowId,
  versionsLinkTo,
  component,
  configuration,
  admins,
  row,
}: Props) => {
  const {
    sharedCodes,
    versionsConfigs,
    rowVersions,
    rowVersionsConfigs,
    isLoadingVersions,
    versions,
    isPendingVersions,
    pendingMultiLoadVersions,
    isLoadingRowVersions,
    isPendingRowVersions,
    pendingMultiLoadRowVersions,
  } = useStores(
    () => {
      return {
        sharedCodes: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SHARED_CODE)
          .find(
            (config: Map<string, any>) =>
              config.getIn(['configuration', 'componentId']) === componentId,
            null,
            Map(),
          )
          .get('rows', List()),
        versionsConfigs: VersionsStore.getVersionsConfigs(componentId, configId),
        rowVersions: RowVersionsStore.getVersions(componentId, configId, rowId),
        rowVersionsConfigs: RowVersionsStore.getVersionsConfigs(componentId, configId, rowId),
        isLoadingVersions: VersionsStore.isLoadingVersions(componentId, configId),
        versions: VersionsStore.getVersions(componentId, configId),
        isPendingVersions: VersionsStore.isPendingConfig(componentId, configId),
        pendingMultiLoadVersions: VersionsStore.getPendingMultiLoad(componentId, configId),
        isLoadingRowVersions: RowVersionsStore.isLoadingVersions(componentId, configId, rowId),
        isPendingRowVersions: RowVersionsStore.isPendingConfig(componentId, configId, rowId),
        pendingMultiLoadRowVersions: RowVersionsStore.getPendingMultiLoad(
          componentId,
          configId,
          rowId,
        ),
      };
    },
    [componentId, configId, rowId],
    [VersionsStore, RowVersionsStore, InstalledComponentsStore],
  );

  if (rowId) {
    return (
      <SidebarVersions
        configId={configId}
        component={component}
        config={configuration}
        row={row}
        versionsLinkTo={versionsLinkTo || componentsRoutes.GENERIC_CONFIG_ROW_VERSIONS}
        admins={admins}
        versions={rowVersions}
        versionsConfigs={rowVersionsConfigs}
        isLoading={isLoadingRowVersions}
        isPending={isPendingRowVersions}
        pendingMultiLoad={pendingMultiLoadRowVersions}
        rowId={rowId}
        limit={5}
      />
    );
  }

  return (
    <SidebarVersions
      configId={configId}
      component={component}
      config={configuration}
      versionsLinkTo={
        versionsLinkTo ||
        (component.get('type') === componentTypes.TRANSFORMATION
          ? transformationRoutes.GENERIC_TRANSFORMATION_VERSIONS
          : componentsRoutes.GENERIC_CONFIG_VERSIONS)
      }
      admins={admins}
      versions={versions}
      versionsConfigs={versionsConfigs}
      isLoading={isLoadingVersions}
      isPending={isPendingVersions}
      pendingMultiLoad={pendingMultiLoadVersions}
      sharedCodes={sharedCodes}
    />
  );
};

export default Versions;
