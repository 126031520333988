import type { ReactNode } from 'react';

const OnClickEventPropagationStopper = (props: { children: ReactNode }) => {
  return (
    <span
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      {props.children}
    </span>
  );
};

export default OnClickEventPropagationStopper;
