import PropTypes from 'prop-types';
import { Button, FormControl, ListGroup, ListGroupItem } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import { Icon, Tooltip } from '@keboola/design';

import DatatypeLabel from './DatatypeLabel';

const RedshiftDataTypesList = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    datatypes: PropTypes.object.isRequired,
    handleRemoveDataType: PropTypes.func.isRequired,
  },

  render() {
    if (!this.props.datatypes.count()) {
      return <FormControl.Static>No data types set yet.</FormControl.Static>;
    }

    return (
      <ListGroup>
        {this.props.datatypes
          .sort()
          .map((datatype, key) => (
            <ListGroupItem key={key} className="flex-container pl-0 pr-0">
              <DatatypeLabel column={key.toString()} datatype={datatype} />
              <Tooltip placement="top" tooltip="Delete data type">
                <Button
                  bsStyle="link"
                  className="text-muted pt-0 pb-0"
                  onClick={() => this.props.handleRemoveDataType(key)}
                >
                  <Icon icon="trash" fixedWidth />
                </Button>
              </Tooltip>
            </ListGroupItem>
          ))
          .toArray()}
      </ListGroup>
    );
  },
});

export default RedshiftDataTypesList;
