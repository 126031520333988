import { useState } from 'react';
import type { Map } from 'immutable';

import { RadioGroup, Search } from '@keboola/design';

import { FILTERS } from '@/constants';
import { canPurgeTrash } from '@/modules/admin/privileges';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import DeletedComponentRow from '@/modules/trash/react/components/DeletedComponentRow';
import EmptyBox from '@/modules/trash/react/components/EmptyBox';
import { useDeletedComponents } from '@/modules/trash/rqHooks/useDeletedComponents';
import type { DeletedComponent, FilterValues } from '@/modules/trash/types';
import { getFilteredComponents } from '@/modules/trash/utils';
import LazyList from '@/react/common/LazyList';
import Loader from '@/react/common/Loader';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const TrashIndex = () => {
  const state = useStores(
    () => {
      const allComponents = ComponentsStore.getAll() as Map<string, any>;
      return {
        allComponents,
        isDevModeActive: DevBranchesStore.isDevModeActive(),
        sapiToken: ApplicationStore.getSapiToken(),
        readOnly: ApplicationStore.isReadOnly(),
        hasFlows: ApplicationStore.hasFlows(),
        adminEmail: ApplicationStore.getCurrentAdmin().get('email'),
        admins: ApplicationStore.getAdmins(),
      };
    },
    [],
    [InstalledComponentsStore, DevBranchesStore, ApplicationStore, ComponentsStore],
  );

  const [searchQuery, setSearchQuery] = useState(
    RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
  );
  const [filter, setFilter] = useState<FilterValues>(FILTERS.ALL);

  const deletedComponents = useDeletedComponents();

  if (deletedComponents.isError) {
    return <EmptyBox>Error loading trash: {deletedComponents.error.message}</EmptyBox>;
  }

  if (deletedComponents.isLoading) {
    return (
      <EmptyBox>
        <Loader className="icon-addon-right" />
        Loading...
      </EmptyBox>
    );
  }

  if (!deletedComponents.data || deletedComponents.data.length === 0) {
    return <EmptyBox>Trash is empty</EmptyBox>;
  }

  const renderRows = (components: DeletedComponent[]) => {
    if (components.length === 0) {
      return (
        <EmptyBox>
          {searchQuery || filter === FILTERS.COMPONENTS
            ? 'No removed configurations found'
            : 'Trash is empty'}
        </EmptyBox>
      );
    }

    return components.map((component) => {
      return (
        <DeletedComponentRow
          key={component.id}
          readOnly={state.readOnly}
          component={component}
          allComponents={state.allComponents}
          searchQuery={searchQuery}
          isDeleteEnabled={canPurgeTrash(state.sapiToken) && !state.isDevModeActive}
          hasFlows={state.hasFlows}
          admins={state.admins}
        />
      );
    });
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <Search
        placeholder="Search by name, description or id"
        defaultValue={searchQuery}
        onChange={(query) => setSearchQuery(query)}
        suffix={
          <RadioGroup variant="button" value={filter} onChange={setFilter}>
            <RadioGroup.Item value={FILTERS.ALL}>All</RadioGroup.Item>
            <RadioGroup.Item value={FILTERS.COMPONENTS}>My Components</RadioGroup.Item>
          </RadioGroup>
        }
      />
      <LazyList
        limit={5}
        items={getFilteredComponents(filter, searchQuery, deletedComponents.data, state.adminEmail)}
        render={renderRows}
      />
    </div>
  );
};

export default TrashIndex;
