import { Component } from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

import { Alert, HelpBlock, Icon, Link } from '@keboola/design';

import { nameWarning } from '@/modules/storage/constants';
import ComponentIcon from '@/react/common/ComponentIcon';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import { isValidName } from '@/react/common/helpers';
import ModalIcon from '@/react/common/ModalIcon';
import PasswordControl from '@/react/common/PasswordControl';
import SyncActionError from '@/utils/errors/SyncActionError';

const INITIAL_STATE = {
  connectionName: '',
  credentialsId: '',
  token: '',
  host: '',
  isLoading: false,
  warning: null,
  error: null,
};

const LOOKER_HELP_LINK =
  'https://docs.looker.com/reference/api-and-integration/api-auth#authentication_with_a_sdk';

class LookerCredentialsModal extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.onHide = this.onHide.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.onHide}>
        <form onSubmit={this.handleSubmit} className="form-horizontal">
          <Modal.Header closeButton>
            <Modal.Title>Connect Your Looker Account</Modal.Title>
            <ModalIcon icon="database" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <Link href={LOOKER_HELP_LINK} className="color-main flex-container no-underline">
              <ComponentIcon
                size="24"
                component={this.props.lookerComponent}
                className="icon-addon-right"
              />
              See how to get your Looker API key
              <Icon icon="chevron-right" fixedWidth className="text-muted-light ml-auto" />
            </Link>
            <hr className="inside-modal-body" />
            {this.state.error && (
              <Alert variant="error" className="tw-mb-5">
                {this.state.error}
              </Alert>
            )}
            <FormGroup validationState={this.state.warning ? 'error' : null}>
              <div className="col-xs-3">
                <ControlLabel>Connection Name</ControlLabel>
              </div>
              <div className="col-xs-9">
                <FormControl
                  autoFocus
                  type="text"
                  value={this.state.connectionName}
                  onChange={({ target }) =>
                    this.setState({
                      connectionName: target.value,
                      warning: isValidName(target.value) ? null : nameWarning,
                    })
                  }
                />
                {this.state.warning && (
                  <HelpBlock className="tw-mt-1" state="error">
                    {this.state.warning}
                  </HelpBlock>
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-3">
                <ControlLabel>Client ID</ControlLabel>
              </div>
              <div className="col-xs-9">
                <FormControl
                  type="text"
                  value={this.state.credentialsId}
                  onChange={(e) => this.setState({ credentialsId: e.target.value })}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-3">
                <ControlLabel>Client Secret</ControlLabel>
              </div>
              <div className="col-xs-9">
                <PasswordControl
                  value={this.state.token}
                  onChange={(e) => this.setState({ token: e.target.value })}
                />
              </div>
            </FormGroup>
            <FormGroup>
              <div className="col-xs-3">
                <ControlLabel>Host</ControlLabel>
              </div>
              <div className="col-xs-9">
                <FormControl
                  type="text"
                  value={this.state.host}
                  onChange={(e) => this.setState({ host: e.target.value })}
                />
                <HelpBlock className="tw-mt-1">
                  E.g., <code>https://MY_COMPANY.api.looker.com/api/3.1</code>
                </HelpBlock>
              </div>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel={
                this.state.isLoading ? 'Testing connection...' : 'Test connection and save'
              }
              saveButtonType="submit"
              isSaving={this.state.isLoading}
              isDisabled={this.isSubmitDisabled()}
            />
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  isSubmitDisabled() {
    return (
      !this.state.credentialsId ||
      !this.state.token ||
      !this.state.host ||
      this.state.isLoading ||
      this.state.warning
    );
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.props
      .registerToLookerFn(this.state.credentialsId, this.state.token, this.state.host)
      .then(() => {
        return this.props.saveCredentialsFn(
          this.props.configId,
          this.state.credentialsId,
          this.state.token,
          this.state.host,
          this.state.connectionName,
        );
      })
      .then(this.onHide)
      .catch(SyncActionError, (error) => {
        this.setState({
          error: error.message,
        });
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  onHide() {
    this.setState(INITIAL_STATE);
    this.props.onHide();
  }
}

LookerCredentialsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  registerToLookerFn: PropTypes.func.isRequired,
  saveCredentialsFn: PropTypes.func.isRequired,
  lookerComponent: PropTypes.object.isRequired,
  configId: PropTypes.string.isRequired,
};

export default LookerCredentialsModal;
