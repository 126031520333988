import { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

import { URLS } from '@keboola/constants';
import { Button, Icon, Link } from '@keboola/design';

import { TRANSFORMATION } from '@/constants/componentIds';
import { canRunConfigDataJob } from '@/modules/admin/privileges';
import {
  getLargerBackendSize,
  hasDynamicBackendSizeEnabled,
  supportsDynamicBackendSize,
} from '@/modules/components/helpers';
import actions from '@/modules/queue/actions';
import {
  extractErrorDetails,
  getCurrentBackendSize,
  hasInternalError,
} from '@/modules/queue/helpers';
import { CircleIcon } from '@/react/common';
import Confirm from '@/react/common/Confirm';
import JobDuration from '@/react/common/JobDuration';
import UrlsToLinks from '@/react/common/UrlsToLinks';
import contactSupport from '@/utils/contactSupport';
import string from '@/utils/string';
import JobErrorModal from './JobErrorModal';

class JobErrorPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showErrorModal: false,
      isRetryingJob: false,
    };
  }

  render() {
    const errorMessage = this.props.job.getIn(['result', 'message']);
    const [title, body] = this.renderDetails(errorMessage);

    return (
      <div className="job-error-panel box box-panel">
        <div className="box-header">
          <h3 className="box-title text-danger">{title}</h3>
          <CircleIcon icon="circle-exclamation" color="red" />
        </div>
        <div className="box-panel-content mt-auto">
          <p className="summary-title">
            <JobDuration
              status={this.props.job.get('status')}
              startTime={this.props.job.get('startTime')}
              endTime={this.props.job.get('endTime')}
            />
          </p>
          {body}
        </div>
      </div>
    );
  }

  /** @returns {[string, JSX.Element]} */
  renderDetails(message) {
    if (message.indexOf('Component out of memory') !== -1) {
      return ['Component out of memory', this.renderInsufficientResourcesPanel('memory')];
    } else if (message.indexOf('container exceeded the timeout') !== -1) {
      return ['Job Timeout', this.renderInsufficientResourcesPanel('time')];
    } else if (
      this.props.job.get('component') === TRANSFORMATION &&
      message.indexOf('TTL exceeded') !== -1
    ) {
      return [
        'Circular dependencies',
        <p key="Circular Dependencies">
          Please check your transformations to prevent any circular{' '}
          <Link href={`${URLS.USER_DOCUMENTATION}/transformations/#dependencies`}>
            dependencies
          </Link>
          .
        </p>,
      ];
    }

    const useAi =
      this.props.hasAllowedAi &&
      !hasInternalError(this.props.job) &&
      this.props.job.hasIn(['result', 'message']) &&
      this.props.job.getIn(['result', 'context'], List()).isEmpty();
    const errorMessage = extractErrorDetails(this.props.job.getIn(['result', 'message'], ''));

    return [
      'Job Error',
      <>
        <div className="overflow-break-anywhere">
          <p>
            {hasInternalError(this.props.job) ? (
              'Internal Error'
            ) : (
              <UrlsToLinks text={string.truncate(errorMessage, 300)} />
            )}
          </p>
        </div>
        <Button onClick={() => this.setState({ showErrorModal: true })}>
          {useAi ? (
            <>
              <Icon icon="brain-circuit" />
              {errorMessage.length > 300 ? 'Show More & Explain' : 'Explain'}
            </>
          ) : (
            'Show details'
          )}
        </Button>
        <JobErrorModal
          useAi={useAi}
          job={this.props.job}
          show={this.state.showErrorModal}
          onHide={() => this.setState({ showErrorModal: false })}
        />
      </>,
    ];
  }

  renderInsufficientResourcesPanel(entity) {
    const supportsDynamicBackendSizes =
      this.props.allComponents &&
      supportsDynamicBackendSize(this.props.allComponents.get(this.props.job.get('component'))) &&
      hasDynamicBackendSizeEnabled(
        this.props.allComponents.get(this.props.job.get('component'), Map()),
        this.props.hasSnowflakeDynamicBackendSize,
        this.props.hasJobsDynamicBackendSize,
      );
    const hasLargerBackendSize = !!getLargerBackendSize(
      this.props.job.get('component'),
      getCurrentBackendSize(this.props.job),
    );

    return (
      <>
        <p>
          It looks like{' '}
          {entity === 'time' ? (
            <>
              <strong>this job timed out</strong>
            </>
          ) : (
            <>
              you are <strong>running out of {entity}</strong> for this job
            </>
          )}
          . Would you like to run it with increased {entity === 'time' ? 'timeout' : entity}?
        </p>
        {supportsDynamicBackendSizes &&
        hasLargerBackendSize &&
        canRunConfigDataJob(this.props.sapiTokens) ? (
          <Confirm
            closeAfterResolve
            title="Run with More Resources"
            text={
              <>
                <p className="text-muted">
                  You can try to run a job using additional resources to see if it helps. Running it
                  on a stronger backend is more expensive. Please check the pricing{' '}
                  <Link
                    href={`${URLS.USER_DOCUMENTATION}/management/project/limits/#project-power--time-credits`}
                  >
                    here
                  </Link>
                  .
                </p>
                <p className="text-muted">Do you want to continue?</p>
              </>
            }
            buttonLabel="Retry job with More Resources"
            buttonType="success"
            childrenRootElement="button"
            childrenRootElementClass="btn btn-primary"
            onConfirm={() => {
              this.setState({ isRetryingJob: true });

              return actions
                .retryJobWithLargerBackend(this.props.job, this.props.configData)
                .catch(() => this.setState({ isRetryingJob: false }));
            }}
            isLoading={this.state.isRetryingJob}
          >
            <Icon icon="rocket" className="icon-addon-right" />
            Retry job with more resources
          </Confirm>
        ) : (
          <Button variant="danger" onClick={() => contactSupport()}>
            Contact Support
          </Button>
        )}
      </>
    );
  }
}

JobErrorPanel.propTypes = {
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  job: PropTypes.instanceOf(Map).isRequired,
  configData: PropTypes.instanceOf(Map),
  allComponents: PropTypes.instanceOf(Map),
  hasAllowedAi: PropTypes.bool,
  hasSnowflakeDynamicBackendSize: PropTypes.bool,
  hasJobsDynamicBackendSize: PropTypes.bool,
};

export default JobErrorPanel;
