import Promise from 'bluebird';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request, { Request } from 'superagent';

import { REQUEST_TIMEOUT_MS } from '@/constants/superagent';
import {
  HTTP_STATUS_CODE_BAD_GATEWAY,
  HTTP_STATUS_CODE_GATEWAY_TIMEOUT,
  HTTP_STATUS_CODE_INTERNAL_SERVER_ERROR,
  HTTP_STATUS_CODE_SERVICE_UNAVAILABLE,
} from './errors/helpers';
import HttpError from './errors/HttpError';

declare module 'superagent' {
  // `interface` required for merging declarations
  // eslint-disable-next-line
  interface Request {
    promise(): Promise<request.Response>;
  }
}

(Request.prototype as any as request.Request).promise = function () {
  return new Promise((resolve, reject) => {
    return this.then(
      (responseOk) => {
        return resolve(responseOk);
      },
      (responseNotOk) => {
        if (responseNotOk.response) {
          return reject(new HttpError(responseNotOk.response));
        } else {
          return reject(responseNotOk);
        }
      },
    ).catch((error) => {
      return reject(error);
    });
  });
};

export type HttpMethod =
  | 'GET'
  | 'HEAD'
  | 'POST'
  | 'PUT'
  | 'DELETE'
  | 'CONNECT'
  | 'OPTIONS'
  | 'TRACE'
  | 'PATCH';

// Overload provided for method autocompletions
function _request(method: HttpMethod, url: string): Request;
// This overload enables passing just a plain `string`
function _request(method: string, url: string): Request;
function _request(method: string, url: string): Request {
  return request(method, encodeURI(url))
    .retry(3, (error) => {
      // allow retries for network errors
      if (error?.crossDomain) {
        return true;
      }

      // allow retries for 5xx errors on GET requests
      if (
        method?.toLowerCase() === 'get' &&
        [
          HTTP_STATUS_CODE_INTERNAL_SERVER_ERROR,
          HTTP_STATUS_CODE_BAD_GATEWAY,
          HTTP_STATUS_CODE_SERVICE_UNAVAILABLE,
          HTTP_STATUS_CODE_GATEWAY_TIMEOUT,
        ].includes(error?.code)
      ) {
        return true;
      }

      return false;
    })
    .timeout(REQUEST_TIMEOUT_MS);
}

export default _request;
