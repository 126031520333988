import type { KeyboardEvent } from 'react';
import type { Map } from 'immutable';

import { isComponentDeprecated } from '@/modules/components/helpers';
import VersionsActionCreators from '@/modules/components/VersionsActionCreators';
import CopyVersionButton from '@/react/common/CopyVersionButton';
import { getRealComponentId } from './helpers';

type Props = {
  configuration: Map<string, any>;
  component: Map<string, any>;
  hasFlows: boolean;
  onKeyDown?: (e: KeyboardEvent) => void;
  mode?: 'sidebar' | 'menuitem';
};

const CopyButton = (props: Props) => {
  if (isComponentDeprecated(props.component)) {
    return null;
  }

  return (
    <CopyVersionButton
      isLast
      mode={props.mode || 'menuitem'}
      onKeyDown={props.onKeyDown}
      hasFlows={props.hasFlows}
      configId={props.configuration.get('id')}
      componentId={getRealComponentId(props.configuration, props.component)}
      componentType={props.component.get('type')}
      version={props.configuration}
      onCopy={(name: string, exludeRows: boolean) => {
        return VersionsActionCreators.copyVersion(
          getRealComponentId(props.configuration, props.component),
          props.configuration.get('id'),
          props.configuration.get('version'),
          name,
          exludeRows,
        );
      }}
    />
  );
};

export default CopyButton;
