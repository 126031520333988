import { useMemo } from 'react';
import dayjs from 'dayjs';

import { FormGroup, Label } from '@keboola/design';

import StorageApiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';

type Props = {
  value: {
    source: string;
    destination: string;
    prefix: string;
  };
  onChange: (value: { source?: string; destination?: string; prefix?: string }) => void;
  disabled: boolean;
};

const Configuration = (props: Props) => {
  const validationStatePrefix = useMemo(() => {
    if (!!props.value.prefix && !props.value.prefix.endsWith('/')) {
      return {
        state: 'warning',
        message: 'The path must end with a slash.',
      } as const;
    }
    return {
      state: 'default',
      message: (
        <>
          <p>Specify the folder path. You can use the following dynamic placeholders:</p>
          <ul>
            <li>
              <code>{`{timestamp}`}</code> - Full datetime (e.g., &quot;
              {dayjs().format('YYYYMMDDHHmmss')}&quot;)
            </li>
            <li>
              <code>{`{date}`}</code> - Date only (e.g., &quot;{dayjs().format('YYYYMMDD')}
              &quot;)
            </li>
            <li>
              <code>{`{time}`}</code> - Time only (e.g., &quot;{dayjs().format('HHmmss')}
              &quot;)
            </li>
          </ul>
          <p>
            Example: &quot;{`{timestamp}_backup`}&quot; will create a folder like &quot;
            {dayjs().format('YYYYMMDDHHmmss')}_backup&quot;.
          </p>
        </>
      ),
    } as const;
  }, [props.value.prefix]);

  const validationStateDestination = useMemo(() => {
    if (!!props.value.destination && props.value.destination.includes('/')) {
      return {
        state: 'warning',
        message: 'The filename cannot include a path.',
      } as const;
    }
    return {
      state: 'default',
      message: 'Enter the destination filename without the path.',
    } as const;
  }, [props.value.destination]);

  return (
    <>
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Source</h2>
        </div>
        <div className="box-content">
          <FormGroup className="tw-flex-row tw-items-baseline">
            <div className="col-xs-4 tw-p-0">
              <p className="tw-m-0 tw-font-normal tw-text-neutral-800">Table</p>
            </div>
            <div className="col-xs-8">
              <StorageApiTableLinkEx tableId={props.value.source} />
            </div>
          </FormGroup>
        </div>
      </div>

      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Destination</h2>
        </div>
        <div className="box-content tw-flex tw-flex-col tw-gap-4">
          <FormGroup className="tw-flex-row tw-items-baseline" state={validationStatePrefix.state}>
            <div className="col-xs-4 tw-p-0">
              <Label htmlFor="prefix">Path</Label>
            </div>
            <div className="col-xs-8 tw-flex tw-flex-col tw-gap-1">
              <FormGroup.TextInput
                variant="secondary"
                id="prefix"
                value={props.value.prefix}
                onChange={(newPrefix) => {
                  props.onChange({ prefix: newPrefix.trim() });
                }}
                disabled={props.disabled}
              />
              <FormGroup.Help>{validationStatePrefix.message}</FormGroup.Help>
            </div>
          </FormGroup>
          <FormGroup
            className="tw-flex-row tw-items-baseline"
            state={validationStateDestination.state}
          >
            <div className="col-xs-4 tw-p-0">
              <Label htmlFor="destination">Filename</Label>
            </div>
            <div className="col-xs-8 tw-flex tw-flex-col tw-gap-1">
              <FormGroup.TextInput
                variant="secondary"
                id="destination"
                value={props.value.destination}
                onChange={(newDestination) => {
                  props.onChange({ destination: newDestination.trim() });
                }}
                placeholder="file.csv"
                disabled={props.disabled}
              />
              <FormGroup.Help>{validationStateDestination.message}</FormGroup.Help>
            </div>
          </FormGroup>
        </div>
      </div>
    </>
  );
};

export default Configuration;
