import { Map } from 'immutable';

import { EmptySearchContent } from '@keboola/design';

import Box from '@/modules/flows/components/Box';
import ConfigurationRow from '@/modules/flows/components/ConfigurationRow';
import AddTaskComponentRow from './AddTaskComponentRow';
import type { AggregatedConfigurations } from './helpers';

type Props = {
  allConfigurations: Map<string, any>;
  components?: Map<string, any>;
  transformations?: Map<string, any>;
  configurations: AggregatedConfigurations;
  flows?: Map<string, any>;
  dataApps?: Map<string, any>;
  query: string;
  onClick: (
    component: Map<string, any>,
    configuration?: Map<string, any>,
    options?: { autosave: boolean },
  ) => void;
};

const SearchedList = ({
  allConfigurations,
  components = Map(),
  transformations = Map(),
  configurations,
  flows = Map(),
  dataApps = Map(),
  query,
  onClick,
}: Props) => {
  if (
    components.isEmpty() &&
    transformations.isEmpty() &&
    configurations.size === 0 &&
    flows.get('data', Map()).isEmpty() &&
    dataApps.get('data', Map()).isEmpty()
  ) {
    return <EmptySearchContent />;
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      {!components.isEmpty() && (
        <Box title="Components">
          {components
            .map((component) => (
              <AddTaskComponentRow
                key={component.get('id')}
                component={component}
                configurations={allConfigurations
                  .getIn([component.get('id'), 'configurations'], Map())
                  .count()}
                onSelect={onClick}
                query={query}
              />
            ))
            .toArray()}
        </Box>
      )}
      {!transformations.isEmpty() && (
        <Box title="Transformations">
          {transformations
            .map((transformation) => (
              <AddTaskComponentRow
                key={transformation.get('id')}
                component={transformation}
                configurations={allConfigurations
                  .getIn([transformation.get('id'), 'configurations'], Map())
                  .count()}
                onSelect={onClick}
                query={query}
              />
            ))
            .toArray()}
        </Box>
      )}
      {configurations.size !== 0 && (
        <Box title="Configurations">
          {configurations.data.map((value) =>
            value.data.map((config: Map<string, any>) => (
              <ConfigurationRow
                key={config.get('id')}
                component={value.component}
                configuration={config}
                onSelect={onClick}
                query={query}
                customFooterContent={
                  <span className="tw-text-neutral-400">{value.component.get('name')}</span>
                }
              />
            )),
          )}
        </Box>
      )}
      {!flows.get('data', Map()).isEmpty() && (
        <Box title="Flows">
          {flows
            .get('data')
            .map((configuration: Map<string, any>) => (
              <ConfigurationRow
                key={configuration.get('id')}
                component={flows.get('component')}
                configuration={configuration}
                onSelect={onClick}
                query={query}
                customFooterContent={<span className="tw-text-neutral-400">Flow</span>}
              />
            ))
            .toArray()}
        </Box>
      )}
      {!dataApps.get('data', Map()).isEmpty() && (
        <Box title="Data Apps">
          {dataApps
            .get('data')
            .map((configuration: Map<string, any>) => (
              <ConfigurationRow
                key={configuration.get('id')}
                component={dataApps.get('component')}
                configuration={configuration}
                onSelect={onClick}
                query={query}
                customFooterContent={<span className="tw-text-neutral-400">Data App</span>}
              />
            ))
            .toArray()}
        </Box>
      )}
    </div>
  );
};

export default SearchedList;
