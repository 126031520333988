import { useEffect, useRef } from 'react';
import type { Dayjs } from 'dayjs';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import dayjs from '@/date';
import { request } from '@/helpers';
import ApplicationStore from '@/stores/ApplicationStore';
import Timer from '@/utils/Timer';

type App = {
  name: string;
  version: string;
  isCritical?: boolean;
};

const CHECK_INTERVAL = 1000 * 60 * 6; // how often to check for updates
const NOTIFICATION_TIMEOUT = 1000 * 60 * 2; // how long to show the notification
const DAYS_LIMIT = 5; // limit before showing the notification
const HOURS_MUTE_LIMIT = 2; // how long mute next notifications

const ReleaseManager = (props: { location: Record<string, any> }) => {
  const lastUpdate = useRef(dayjs());
  const lastNotification = useRef<Dayjs | null>(null);
  const currentlyUsedVersion = useRef<App['version'] | null>(
    props.location.query?.kbcVersion || null,
  );

  useEffect(() => {
    const sendNotification = (message: string) => {
      ApplicationActionCreators.sendNotification({
        type: 'info',
        timeout: NOTIFICATION_TIMEOUT,
        message,
        button: {
          label: 'Refresh now',
          action: () => window.location.reload(),
        },
      });

      lastNotification.current = dayjs();
    };

    const checkVersions = () => {
      if (
        lastNotification.current &&
        dayjs().diff(lastNotification.current, 'hours') < HOURS_MUTE_LIMIT
      ) {
        return;
      }

      request('GET', `${ApplicationStore.getSapiUrl()}/manage/ui-apps`)
        .promise()
        .then((response) => response.body)
        .then((apps: App[]) => apps.find((app) => app.name === 'kbc'))
        .then((app) => {
          if (!app?.version || currentlyUsedVersion.current === app.version) {
            return;
          }

          if (!currentlyUsedVersion.current) {
            currentlyUsedVersion.current = app.version;
            return;
          }

          if (app.isCritical) {
            return sendNotification(
              'An important update is available. Please refresh the page as soon as possible to get the latest version.',
            );
          }

          if (dayjs().diff(lastUpdate.current, 'days') > DAYS_LIMIT) {
            return sendNotification(
              'New updates are available. Please refresh the page when possible to get the latest version.',
            );
          }
        });
    };

    Timer.poll(checkVersions, { interval: CHECK_INTERVAL });

    return () => {
      Timer.stop(checkVersions);
    };
  }, []);

  return null;
};

export default ReleaseManager;
