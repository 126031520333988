import type { KeyboardEventHandler, ReactNode, SyntheticEvent } from 'react';

import { cn } from '@keboola/design';

import { RouterLink } from '@/react/common';

type Props = {
  to: string;
  active?: boolean;
  children: ReactNode;
  params?: Record<string, any>;
  forceProduction?: boolean;
  // these two props are passed by dropdown menu
  onSelect?: (to: string, event: SyntheticEvent) => void;
  onKeyDown?: KeyboardEventHandler;
};

const LinkMenuItem = (props: Props) => {
  return (
    <li role="presentation" className={cn({ active: props.active })}>
      <RouterLink
        role="menuitem"
        tabIndex={-1}
        to={props.to}
        params={props.params}
        onKeyDown={props.onKeyDown}
        onClick={(event) => props.onSelect?.(props.to, event)}
        forceProduction={props.forceProduction}
      >
        {props.children}
      </RouterLink>
    </li>
  );
};

export default LinkMenuItem;
