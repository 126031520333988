import type { Map } from 'immutable';

import { ClipboardInline } from '@keboola/design';

const RunIdTooltip = ({ job }: { job: Map<string, any> }) => {
  return (
    <ClipboardInline
      text={job.get('runId')}
      label="Run ID"
      tooltipText={
        <>
          <p className="tooltip-title">Run ID</p>
          <p className="overflow-break-anywhere">{job.get('runId')}</p>
          <small>Click to copy</small>
        </>
      }
      tooltipPlacement="top"
      tooltipType="explanatory"
      className="tw-ml-2"
      buttonClassName="tw-text-xs tw-font-normal tw-text-neutral-400"
    />
  );
};

export default RunIdTooltip;
