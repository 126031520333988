import { useEffect, useState } from 'react';

import CollapsibleAlert from '@/react/common/CollapsibleAlert';
import detectAdblock from '@/utils/detectAdblock';

const AdblockDetector = () => {
  const [detected, setDetected] = useState(false);

  useEffect(() => {
    detectAdblock().then(setDetected);
  }, []);

  if (!detected) {
    return null;
  }

  return (
    <CollapsibleAlert
      id="adblock-detector"
      title="Adblock detected"
      variant="warning"
      className="tw-mb-6"
    >
      <p>
        We have detected that your browser is blocking advertisements. This can interfere with our
        application and lead to seemingly missing things.
      </p>
      <p>
        We strongly recommend to whitelist the current site ({window.location.hostname}). We have no
        ads here anyway.
      </p>
    </CollapsibleAlert>
  );
};

export default AdblockDetector;
