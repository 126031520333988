import MarketplacePortalLink from './MarketplacePortalLink';

const AzureLogo = () => {
  return (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1755_1298)">
        <path
          d="M8.01576 0.215088H15.0913L7.7462 22.1855C7.6707 22.4113 7.52698 22.6075 7.33526 22.7464C7.14354 22.8854 6.91347 22.9602 6.67744 22.9602H1.17092C0.992094 22.9603 0.815829 22.9173 0.656712 22.8349C0.497595 22.7525 0.360199 22.633 0.25589 22.4864C0.151581 22.3397 0.0833572 22.1701 0.0568634 21.9916C0.0303696 21.813 0.0463671 21.6307 0.103532 21.4596L6.94674 0.989801C7.0222 0.763945 7.16594 0.567678 7.35772 0.428647C7.5495 0.289616 7.77966 0.215101 8.01576 0.215088Z"
          fill="url(#paint0_linear_1755_1298)"
        />
        <path
          d="M18.2975 14.9514H7.07742C6.97311 14.9513 6.87118 14.9829 6.78491 15.0421C6.69865 15.1013 6.63204 15.1853 6.59377 15.2833C6.5555 15.3813 6.54734 15.4886 6.57035 15.5913C6.59337 15.694 6.64649 15.7874 6.7228 15.8592L13.9326 22.6527C14.1425 22.8504 14.4189 22.9603 14.706 22.9602H21.0592L18.2975 14.9514Z"
          fill="#0078D4"
        />
        <path
          d="M8.01579 0.215066C7.7771 0.214141 7.54438 0.290287 7.35161 0.43238C7.15885 0.574474 7.01613 0.77507 6.94432 1.00487L0.111982 21.441C0.050971 21.6126 0.0318167 21.7966 0.0561396 21.9774C0.0804624 22.1581 0.147547 22.3303 0.251718 22.4793C0.355889 22.6283 0.494082 22.7498 0.654606 22.8336C0.815131 22.9173 0.993265 22.9607 1.17394 22.9602H6.82258C7.03296 22.9223 7.22959 22.8286 7.39239 22.6889C7.55519 22.5491 7.67832 22.3682 7.74921 22.1646L9.11171 18.1108L13.9786 22.6936C14.1825 22.8639 14.4383 22.958 14.703 22.9602H21.0326L18.2565 14.9514L10.1639 14.9533L15.1169 0.215066H8.01579Z"
          fill="url(#paint1_linear_1755_1298)"
        />
        <path
          d="M17.053 0.988704C16.9776 0.763213 16.8341 0.567271 16.6426 0.428484C16.4511 0.289697 16.2213 0.21506 15.9856 0.215088H8.09998C8.33569 0.215101 8.56547 0.289755 8.75695 0.428536C8.94844 0.567317 9.09198 0.763235 9.16737 0.988704L16.0108 21.4594C16.0681 21.6305 16.0841 21.8128 16.0576 21.9914C16.0311 22.17 15.9629 22.3397 15.8586 22.4864C15.7543 22.6331 15.6169 22.7526 15.4578 22.835C15.2986 22.9175 15.1223 22.9605 14.9435 22.9605H22.8293C23.0082 22.9605 23.1844 22.9174 23.3435 22.835C23.5027 22.7525 23.64 22.633 23.7443 22.4863C23.8486 22.3396 23.9168 22.1699 23.9432 21.9914C23.9697 21.8128 23.9536 21.6304 23.8964 21.4594L17.053 0.988704Z"
          fill="url(#paint2_linear_1755_1298)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1755_1298"
          x1="10.5946"
          y1="1.90058"
          x2="3.1203"
          y2="23.7728"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#114A8B" />
          <stop offset="1" stopColor="#0669BC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1755_1298"
          x1="12.89"
          y1="12.1137"
          x2="11.187"
          y2="12.6841"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.3" />
          <stop offset="0.071" stopOpacity="0.2" />
          <stop offset="0.321" stopOpacity="0.1" />
          <stop offset="0.623" stopOpacity="0.05" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1755_1298"
          x1="11.9551"
          y1="1.26139"
          x2="20.1564"
          y2="22.9046"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3CCBF4" />
          <stop offset="1" stopColor="#2892DF" />
        </linearGradient>
        <clipPath id="clip0_1755_1298">
          <rect width="24" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

const ManageSubscription = (props: { marketplaceSubscriptionLink: string }) => {
  return (
    <div className="box box-panel box-panel-small">
      <div className="box-header">
        <h2 className="box-title">Manage Subscription</h2>
        <span className="circle-icon blue">
          <AzureLogo />
        </span>
      </div>
      <div className="box-panel-content mt-auto">
        <p className="summary-text w-300 pr-2">
          This Keboola project is paid for through the Azure Marketplace.
        </p>
        <MarketplacePortalLink href={props.marketplaceSubscriptionLink} />
      </div>
    </div>
  );
};

export default ManageSubscription;
