import { useState } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { List, Map } from 'immutable';
import _ from 'underscore';

import {
  EX_GENERIC_V_2 as componentId,
  KEBOOLA_ORCHESTRATOR,
  KEBOOLA_VARIABLES,
} from '@/constants/componentIds';
import { hasVariables } from '@/modules/components/helpers';
import { prepareVariables } from '@/modules/components/react/components/generic/variables/helpers';
import Variables from '@/modules/components/react/components/generic/variables/Variables';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import { prepareTablesMetadataMap } from '@/modules/storage/helpers';
import ConfigurationInfoPanel from '@/react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import { ErrorBoundaryFallback } from '@/react/common/ErrorBoundaryFallback';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import AdditionalConfigurationWarning from './components/AdditionalConfigurationWarning';
import DetailContent from './components/DetailContent';
import JsonConfiguration from './components/JsonConfiguration';
import SidebarMenu from './components/SidebarMenu';
import VisualSwitch from './components/VisualSwitch';
import { saveConfigData } from './actions';
import { type ACTIVE_MENU } from './constants';
import { parseMenuParam, setMenuParam } from './helpers';

const Detail = () => {
  const store = useStores(
    () => {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const menu = parseMenuParam(RoutesStore.getCurrentRouteParam('menu'));
      const config = InstalledComponentsStore.getConfig(componentId, configId);
      const configData = config.get('configuration', Map());
      const allConfigurations = InstalledComponentsStore.getAll();
      const variablesConfig = InstalledComponentsStore.getConfig(
        KEBOOLA_VARIABLES,
        configData.get('variables_id'),
      );

      return {
        menu,
        config,
        configId,
        configData,
        readOnly: ApplicationStore.isReadOnly(),
        component: ComponentsStore.getComponent(componentId),
        componentsMetadata: InstalledComponentsStore.getAllMetadata() as Map<string, any>,
        flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
        variablesConfiguration: variablesConfig.get('configuration', Map()),
        variablesConfigurationRow: variablesConfig.get('rows', List()).find(
          (row: Map<string, any>) => {
            return row.get('id') === configData.get('variables_values_id', null);
          },
          null,
          Map(),
        ),
        preparedVariables: prepareVariables(allConfigurations, componentId, configId),
        allBuckets: StorageBucketsStore.getAll() as Map<string, any>,
        tables: StorageTablesStore.getAll() as Map<string, any>,
        allComponents: ComponentsStore.getAll() as Map<string, any>,
      };
    },
    [],
    [
      ApplicationStore,
      RoutesStore,
      ComponentsStore,
      StorageBucketsStore,
      StorageTablesStore,
      InstalledComponentsStore,
    ],
  );
  const [jsonMode, setJsonMode] = useState(false);
  const [isUIIncompatible, setIsUIIncompatible] = useState(false);
  const parameters = store.configData.get('parameters', Map());

  const setMenu = (menu: ACTIVE_MENU, mode: 'transitionTo' | 'replaceTo' = 'transitionTo') => {
    RoutesStore.getRouter()[mode](componentId, {
      config: store.configId,
      menu: setMenuParam(menu),
    });
  };

  const handleSaveConfigData = (configData: Map<string, any>, changeDescription: string) => {
    return saveConfigData(store.configId, configData, changeDescription);
  };

  const handleSaveParameters = (
    parameters: Map<string, any>,
    changeDescription: string,
    newMenu?: ACTIVE_MENU,
  ) => {
    return handleSaveConfigData(
      store.configData.set('parameters', parameters),
      changeDescription,
    ).tap(() => {
      if (newMenu && !_.isEqual(store.menu, newMenu)) {
        setMenu(newMenu, 'replaceTo');
      }
    });
  };

  return (
    <>
      <ConfigurationTabs
        componentId={componentId}
        configId={store.configId}
        config={store.config}
        additionalButtons={
          <VisualSwitch jsonMode={jsonMode} setJsonMode={setJsonMode} disabled={isUIIncompatible} />
        }
      />
      <ConfigurationInfoPanel
        component={store.component}
        config={store.config}
        flows={store.flows}
        tablesMetadataMap={prepareTablesMetadataMap(store.tables)}
        metadata={store.componentsMetadata}
        allComponents={store.allComponents}
      />
      <AdditionalConfigurationWarning configData={store.configData} />
      {jsonMode ? (
        <>
          {hasVariables() && (
            <Variables
              readOnly={store.readOnly}
              mainConfiguration={store.configData}
              mainComponentId={componentId}
              mainConfigurationId={store.configId}
              configuration={store.variablesConfiguration}
              row={store.variablesConfigurationRow}
              variables={store.preparedVariables}
              configVariablesId={store.configData.get('variables_id', null)}
              configVariablesValuesId={store.configData.get('variables_values_id', null)}
            />
          )}
          <JsonConfiguration configData={store.configData} onSave={handleSaveConfigData} />
        </>
      ) : (
        <ErrorBoundary
          fallback={() => (
            <ErrorBoundaryFallback
              message="Visual editor is incompatible with this configuration. Please use the JSON editor."
              type="info"
              callback={() => {
                setJsonMode(true);
                setIsUIIncompatible(true);
              }}
            />
          )}
        >
          <div className="tw-grid tw-grid-cols-4 tw-items-start tw-gap-6">
            <SidebarMenu
              menu={store.menu}
              setMenu={setMenu}
              parameters={parameters}
              readOnly={store.readOnly}
              onSave={handleSaveParameters}
            />
            <div className="tw-col-span-3">
              <DetailContent
                menu={store.menu}
                parameters={parameters}
                allBuckets={store.allBuckets}
                allTables={store.tables}
                readOnly={store.readOnly}
                setMenu={setMenu}
                onSave={handleSaveParameters}
              />
            </div>
          </div>
        </ErrorBoundary>
      )}
    </>
  );
};

export default Detail;
