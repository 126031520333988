import type { ChangeEvent } from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

import { HelpBlock } from '@keboola/design';

type Props = {
  value: {
    baseUrl: string;
    maxRedirects: string;
  };
  onChange: (data: Record<string, any>) => void;
  disabled: boolean;
};

const Credentials = (props: Props) => {
  return (
    <div className="form-horizontal">
      <FormGroup>
        <div className="col-xs-4">
          <ControlLabel>Base URL</ControlLabel>
        </div>
        <div className="col-xs-8">
          <FormControl
            type="text"
            value={props.value.baseUrl}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              props.onChange({ baseUrl: e.target.value.trim() });
            }}
            placeholder="https://example.com"
            disabled={props.disabled}
          />
          <HelpBlock className="tw-mt-1">
            Base URL is common for all files downloaded from a certain site/domain.
          </HelpBlock>
        </div>
      </FormGroup>
      <FormGroup>
        <div className="col-xs-4">
          <ControlLabel>Maximum Redirects</ControlLabel>
        </div>
        <div className="col-xs-8">
          <FormControl
            type="number"
            min="0"
            value={props.value.maxRedirects}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              props.onChange({ maxRedirects: e.target.value });
            }}
            disabled={props.disabled}
          />
          <HelpBlock className="tw-mt-1">
            The maximum number of redirects to follow when downloading files. Leave empty to use
            default value (5).
          </HelpBlock>
        </div>
      </FormGroup>
    </div>
  );
};

export default Credentials;
