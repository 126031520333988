import InlineEditTextInput from '@/react/common/InlineEditTextInput';
import ComponentEditField from './ComponentEditField';

type Props = {
  componentId: string;
  configId: string;
  readOnly?: boolean;
};

export const ComponentNameEdit = ({ componentId, configId, readOnly = false }: Props) => (
  <ComponentEditField
    key={`${componentId}-${configId}`}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    componentId={componentId}
    configId={configId}
    readOnly={readOnly}
    fieldName="name"
    editElement={InlineEditTextInput}
    placeholder="My Configuration"
    tooltipPlacement="bottom"
  />
);
