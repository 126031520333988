import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { FormGroup, HelpBlock, Icon, Label, TextInput, Tooltip } from '@keboola/design';

import { KEBOOLA_EX_MONGODB } from '@/constants/componentIds';
import { defaultPorts, protocols } from '@/modules/ex-mongodb/constants';
import PasswordControl from '@/react/common/PasswordControl';
import Select from '@/react/common/Select';
import SshForm from '@/react/common/SshForm';
import SSLForm from '@/react/common/SSLForm';
import TestCredentialsButtonGroup from '@/react/common/TestCredentialsButtonGroup';
import string from '@/utils/string';

const FORM_GROUP_CLASS_NAME = 'tw-grid tw-grid-cols-3 tw-items-baseline tw-gap-4';

const CredentialsForm = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    savedCredentials: PropTypes.object.isRequired,
    credentials: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isValidEditingCredentials: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    configId: PropTypes.string.isRequired,
    actionCreators: PropTypes.object.isRequired,
  },

  render() {
    return (
      <form className="tw-flex tw-flex-col tw-gap-4">
        {this.renderProtocolSelect()}
        {this.renderForm()}
        {this.renderTestButton()}
      </form>
    );
  },

  renderProtocolSelect() {
    return (
      <FormGroup className={FORM_GROUP_CLASS_NAME}>
        <Label htmlFor="protocol">Protocol</Label>
        <Select
          id="protocol"
          className="tw-col-span-2"
          clearable={false}
          value={this.props.credentials.get('protocol', '')}
          onChange={this.handleChangeProtocol}
          disabled={this.props.isDisabled || this.props.readOnly}
          options={[
            { value: '', label: `Default (${protocols.MONGO_DB})` },
            { value: protocols.MONGO_DB_SRV, label: `SRV (${protocols.MONGO_DB_SRV})` },
            { value: protocols.CUSTOM_URI, label: 'Custom URI' },
          ]}
        />
      </FormGroup>
    );
  },

  renderForm() {
    if (this.props.credentials.get('protocol') === protocols.CUSTOM_URI) {
      return (
        <>
          {this.renderField('URI', 'uri', 'Make sure the password is not part of the URI.')}
          {this.renderPasswordField()}
        </>
      );
    }

    return (
      <>
        {this.renderField('Host', 'host')}
        {this.props.credentials.get('protocol') !== protocols.MONGO_DB_SRV &&
          this.renderNumberField('Port', 'port')}
        {this.renderField('Username', 'user')}
        {this.renderPasswordField()}
        {this.renderField('Authentication Database (Optional)', 'authenticationDatabase')}
        {this.renderField('Database', 'database')}
        {this.renderSsh()}
        {this.renderSSLForm()}
      </>
    );
  },

  renderSsh() {
    return (
      <SshForm
        readOnly={this.props.readOnly}
        isEnabled={!this.props.isDisabled}
        data={this.props.credentials.get('ssh') || Map()}
        onChange={(sshObject) => this.props.onChange(this.props.credentials.set('ssh', sshObject))}
      />
    );
  },

  renderSSLForm() {
    return (
      <SSLForm
        readOnly={this.props.readOnly}
        componentId={KEBOOLA_EX_MONGODB}
        isEditing={!this.props.isDisabled}
        data={this.props.credentials.get('ssl', Map())}
        onChange={(sslObject) => this.props.onChange(this.props.credentials.set('ssl', sslObject))}
      />
    );
  },

  renderTestButton() {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <TestCredentialsButtonGroup
        componentId={KEBOOLA_EX_MONGODB}
        configId={this.props.configId}
        isEditing={this.props.isEditing}
        disabled={this.props.isEditing && !this.props.isValidEditingCredentials}
        testCredentialsFn={() => {
          return this.props.actionCreators.testCredentials(
            this.props.configId,
            this.props.credentials,
          );
        }}
      />
    );
  },

  renderField(label, key, help) {
    return (
      <FormGroup className={FORM_GROUP_CLASS_NAME}>
        <Label htmlFor={string.webalize(label)}>{label}</Label>
        <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
          <TextInput
            id={string.webalize(label)}
            variant="secondary"
            disabled={this.props.isDisabled || this.props.readOnly}
            value={this.props.credentials.get(key, '')}
            onChange={(value) => this.props.onChange(this.props.credentials.set(key, value))}
          />
          {help && <HelpBlock>{help}</HelpBlock>}
        </div>
      </FormGroup>
    );
  },

  renderNumberField(label, key) {
    return (
      <FormGroup className={FORM_GROUP_CLASS_NAME}>
        <Label htmlFor={string.webalize(label)}>{label}</Label>
        <FormControl
          id={string.webalize(label)}
          className="tw-col-span-2"
          type="number"
          disabled={this.props.isDisabled || this.props.readOnly}
          value={this.props.credentials.get(key, '')}
          onChange={(e) =>
            this.props.onChange(this.props.credentials.set(key, parseInt(e.target.value, 10)))
          }
        />
      </FormGroup>
    );
  },

  renderPasswordField() {
    return (
      <FormGroup className={FORM_GROUP_CLASS_NAME}>
        <Label htmlFor="password">
          Password{' '}
          <Tooltip tooltip="Password will be stored securely encrypted." type="explanatory">
            <Icon icon="circle-question" fixedWidth />
          </Tooltip>
        </Label>
        <PasswordControl
          id="password"
          className="tw-col-span-2"
          placeholder={
            this.props.savedCredentials.get('#password') ? 'type a new password to change it' : ''
          }
          disabled={this.props.isDisabled || this.props.readOnly}
          value={this.props.credentials.get('#password')}
          onChange={(e) =>
            this.props.onChange(this.props.credentials.set('#password', e.target.value))
          }
        />
      </FormGroup>
    );
  },

  handleChangeProtocol(selected) {
    let credentials = this.props.credentials;

    switch (selected) {
      case protocols.MONGO_DB_SRV:
        credentials = Map({
          protocol: protocols.MONGO_DB_SRV,
          host: this.props.credentials.get('host', ''),
          database: this.props.credentials.get('database', ''),
          user: this.props.credentials.get('user', ''),
          '#password': this.props.credentials.get('#password', ''),
        });
        break;

      case protocols.CUSTOM_URI:
        credentials = Map({
          protocol: protocols.CUSTOM_URI,
          uri: this.props.credentials.get('uri', ''),
          '#password': this.props.credentials.get('#password', ''),
        });
        break;

      default:
        credentials = Map({
          host: this.props.credentials.get('host', ''),
          port: this.props.credentials.get('host', defaultPorts.DATABASE_PORT),
          database: this.props.credentials.get('database', ''),
          user: this.props.credentials.get('user', ''),
          '#password': this.props.credentials.get('#password', ''),
        });
        break;
    }

    if (this.props.credentials.has('ssh') && selected !== protocols.CUSTOM_URI) {
      credentials = credentials.set('ssh', this.props.credentials.get('ssh'));
    }

    this.props.onChange(credentials);
  },
});

export default CredentialsForm;
