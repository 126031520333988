import BluebirdPromise from 'bluebird';

import { isManagementClientAuthError } from '@keboola/api-client';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { IS_DEVELOPMENT } from '@/constants/environment';
import { redirectToProductionIfBranchNotFound } from '@/modules/dev-branches/helpers';
import ApplicationStore from '@/stores/ApplicationStore';
import Error from '@/utils/errors/Error';
import { createPresentationalError, shouldBeMuted } from './errors/helpers';

if (IS_DEVELOPMENT) {
  /*
    Cancellation can be enabled only before first promise is created.
    React-refresh creates promises before this file is loaded, so we need to reset async hooks.
  */
  (BluebirdPromise as any)._async._reset();

  BluebirdPromise.config({ cancellation: true, longStackTraces: true, warnings: true });
} else {
  BluebirdPromise.config({ cancellation: true });
}

export const handleError = (e: any) => {
  if (redirectToProductionIfBranchNotFound(e)) {
    return;
  }

  let error = createPresentationalError(e);
  if (isManagementClientAuthError(e)) {
    if (ApplicationStore.isPreview()) return;

    if (IS_DEVELOPMENT) {
      ApplicationActionCreators.sendNotification({
        timeout: 6000,
        type: 'warning',
        message: 'Failed to create local session token - missing Manage Api Token.',
        id: 'sessionTokenCreationFailed',
      });
      return;
    } else {
      window.location.reload();
      error = new Error(e.title, e.message);
      error.isUserError = true;
    }
  }

  if (!shouldBeMuted(error)) {
    ApplicationActionCreators.sendNotification({
      id: error.id,
      type: !!error.isMaintenanceError ? 'warning' : 'error',
      message: () => (
        <>
          <div className="tw-mb-2 tw-font-medium">{error.getTitle() || 'Error'}</div>
          <div>{error.getText()}</div>
          {error.getExceptionId() && (
            <div className="tw-mt-2">Exception ID: {error.getExceptionId()}</div>
          )}
        </>
      ),
    });
  }

  if (!error.isUserError && !error.isMaintenanceError) {
    throw e;
  }
};

// Error thrown during application live not on route change
BluebirdPromise.onPossiblyUnhandledRejection(handleError);
