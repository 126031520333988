import type { ComponentProps, ReactNode } from 'react';

import { Icon } from '@keboola/design';

import { routeNames } from '@/modules/storage/constants';
import { TabLink, TabNav } from '@/react/common';

type TAB = {
  icon: ComponentProps<typeof Icon>['icon'];
  title: ReactNode;
  routeName: string;
};

const NAV_TABS: TAB[] = [
  { icon: 'table', title: 'Tables & Buckets', routeName: routeNames.ROOT },
  { icon: ['fas', 'files'], title: 'Files', routeName: routeNames.FILES },
  {
    icon: ['fas', 'webhook'],
    title: 'Data Streams',
    routeName: routeNames.STREAMS,
  },
  { icon: 'circle-play', title: 'Storage Jobs', routeName: routeNames.JOBS },
  { icon: ['fas', 'list-timeline'], title: 'Events', routeName: routeNames.EVENTS },
];

const StorageTabs = (props: {
  activeTab: string;
  hasProtectedDefaultBranch: boolean;
  hasPayAsYouGo: boolean;
  canEnableDataStreams: boolean;
  children: ReactNode;
}) => {
  return (
    <>
      <div className="tabs-with-border-wrapper">
        <TabNav>
          {NAV_TABS.filter((tab) => {
            if (tab.routeName === routeNames.STREAMS) return props.canEnableDataStreams;
            return true;
          }).map((tab) => (
            <TabLink
              key={tab.routeName}
              active={props.activeTab === tab.routeName}
              to={tab.routeName}
            >
              <Icon icon={tab.icon} className="icon-addon-right" />
              {tab.title}
            </TabLink>
          ))}
        </TabNav>
      </div>
      {props.children}
    </>
  );
};

export default StorageTabs;
