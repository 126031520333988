import Markdown from '@/react/common/Markdown';

const Description = ({ description }: { description: string | null }) => {
  if (!description) {
    return null;
  }

  return (
    <div>
      <hr className="tw-my-2 tw-bg-neutral-200" />
      <div className="tw-text-xs tw-font-medium tw-uppercase tw-leading-10">Description</div>
      <Markdown source={description} collapsible={false} className="tw-font-normal" />
    </div>
  );
};

export default Description;
