import type { ChangeEvent, FormEvent, ReactNode } from 'react';
import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import type { Map } from 'immutable';

import { Alert, Button, ButtonGroup, IconButton } from '@keboola/design';

import { canManageReadOnlyStorageForProvisionedCredentials } from '@/modules/admin/privileges';
import { prepareRuntimesForSandboxes } from '@/modules/runtimes/helpers';
import RuntimesStore from '@/modules/runtimes/store';
import ReadOnlyAccessBigQuery from '@/modules/sandboxes/components/ReadOnlyAccessBigQuery';
import ReadOnlyStorageCheckbox from '@/modules/sandboxes/components/ReadOnlyStorageCheckbox';
import WorkspaceExpirationControl from '@/modules/sandboxes/components/WorkspaceExpirationControl';
import WorkspaceSizeControl from '@/modules/sandboxes/components/WorkspaceSizeControl';
import {
  AUTO_SLEEP_DEFAULT,
  NEED_CLIENT_TO_CONNECT,
  SANDBOX_SIZES,
  SANDBOX_TYPE,
} from '@/modules/sandboxes/Constants';
import { resolveComponentIdFromSandboxType } from '@/modules/sandboxes/helpers';
import Checkbox from '@/react/common/Checkbox';
import { ConnectToSandboxButton } from '@/react/common/ConnectToSandboxButton';
import ModalIcon from '@/react/common/ModalIcon';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import Select from '@/react/common/Select';
import type { SandboxModalForceStep } from './types';
import WorkspaceCredentials from './WorkspaceCredentials';

type Props = {
  sandbox: Map<string, any>;
  tempData: Map<string, any>;
  sourceTransformation: Map<string, any>;
  forceStep?: SandboxModalForceStep;
  simple: boolean;
  hasPayAsYouGo: boolean;
  onSetTempData: (key: string, value: unknown) => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;

  // state
  isLoading: boolean;
  createdWorkspace: Map<string, any> | null;
  error: ReactNode | null;
  isLoadingWorkspace: boolean;
  onResetState: () => void;
};

const CreateModal = ({
  sandbox,
  tempData,
  sourceTransformation,
  forceStep,
  simple,
  hasPayAsYouGo,
  onSetTempData,
  isLoading,
  createdWorkspace,
  error,
  isLoadingWorkspace,
  onResetState,
  onSubmit,
}: Props) => {
  const runtimes = RuntimesStore.getRuntimes(
    resolveComponentIdFromSandboxType(sandbox.get('type')) ?? '',
  );
  const isDisabled = isLoading || !!createdWorkspace;
  const isSubmitDisabled = !tempData.get('name', '').trim() || !!createdWorkspace || isLoading;

  const sandboxType = sandbox.get('type');

  const isConnectButtonVisible = !simple && !NEED_CLIENT_TO_CONNECT.includes(sandboxType);

  return (
    <form onSubmit={onSubmit}>
      <Modal.Header closeButton>
        <Modal.Title>New {sandbox.get('name')} Workspace</Modal.Title>
        <ModalIcon icon="box" color="green" bold />
      </Modal.Header>
      <Modal.Body>
        {sourceTransformation.get('name') && (
          <p>
            You are about to create a new workspace from the transformation{' '}
            <strong>{sourceTransformation.get('name')}</strong>.
          </p>
        )}
        <FormGroup>
          <ControlLabel>Name</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            placeholder="Name your workspace"
            value={tempData.get('name', '')}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onSetTempData('name', event.target.value)
            }
            disabled={isDisabled}
          />
        </FormGroup>
        <FormGroup>
          <ControlLabel>
            Description <OptionalFormLabel />
          </ControlLabel>
          <FormControl
            type="text"
            placeholder="Describe your workspace"
            value={tempData.get('description', '')}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onSetTempData('description', event.target.value)
            }
            disabled={isDisabled}
          />
        </FormGroup>
        {runtimes.length !== 0 && (
          <FormGroup>
            <ControlLabel>Backend Version</ControlLabel>
            <Select
              clearable={false}
              options={prepareRuntimesForSandboxes(runtimes)}
              value={tempData.get('imageVersion', '')}
              onChange={(value: string) => onSetTempData('imageVersion', value)}
              disabled={isDisabled}
            />
          </FormGroup>
        )}
        <WorkspaceSizeControl
          type={sandboxType}
          value={tempData.get('size', SANDBOX_SIZES.SMALL)}
          onChange={(value) => onSetTempData('size', value)}
          isDisabled={isDisabled}
          hasPayAsYouGo={hasPayAsYouGo}
        />
        <WorkspaceExpirationControl
          type={sandboxType}
          value={tempData.get('expirationAfterHours', AUTO_SLEEP_DEFAULT)}
          onChange={(value) => onSetTempData('expirationAfterHours', value)}
          isDisabled={isDisabled}
        />
        <FormGroup>
          <Checkbox
            checked={tempData.get('shared', false)}
            onChange={(checked) => onSetTempData('shared', checked)}
            disabled={isDisabled}
          >
            Share with all project users
          </Checkbox>
        </FormGroup>
        {canManageReadOnlyStorageForProvisionedCredentials(sandboxType) && (
          <ReadOnlyStorageCheckbox
            value={tempData.get('readOnlyStorageAccess', false)}
            onChange={(checked) => onSetTempData('readOnlyStorageAccess', checked)}
            isDisabled={isDisabled}
            alertClassName="tw-mb-5"
          />
        )}
        {sandboxType === SANDBOX_TYPE.BIGQUERY && <ReadOnlyAccessBigQuery />}
        <WorkspaceCredentials
          workspace={createdWorkspace}
          simple={simple}
          isLoadingWorkspace={isLoadingWorkspace}
        />
        {error && <Alert variant="error">{error}</Alert>}
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup variant="block">
          {!forceStep && <IconButton variant="outline" icon="arrow-left" onClick={onResetState} />}

          <Button
            type="submit"
            icon="circle-check"
            isLoading={isLoading}
            disabled={isSubmitDisabled}
            className="tw-flex-1"
          >
            {!!createdWorkspace
              ? 'Created'
              : isLoading
                ? 'Creating Workspace...'
                : 'Create Workspace'}
          </Button>

          {isConnectButtonVisible && (
            <ConnectToSandboxButton className="tw-flex-1" sandbox={createdWorkspace?.toJS()} />
          )}
        </ButtonGroup>
      </Modal.Footer>
    </form>
  );
};

export default CreateModal;
