import type {
  GetAllFeaturesQuery,
  GetDataAppLogsTailQuery,
  GetWorkspaceSaml2LoginPath,
} from '@keboola/api-client';

export const managementApiKey = {
  getAllFeatures: (query: GetAllFeaturesQuery) => ['all-features', { query }] as const,
  getProject: (projectId: number) => ['project', { projectId }] as const,
};

export const dataScienceApiKey = {
  appLogsTail: (appId: string, query?: GetDataAppLogsTailQuery) =>
    ['app-logs-tail', { appId, query }] as const,
  appLogsDownload: (appId: string) => ['app-download-logs', { appId }] as const,
};

export const storageApiKey = {
  trash: () => ['trash'] as const,
  workspaceSaml2Login: (path: GetWorkspaceSaml2LoginPath) =>
    ['workspace-saml-2-login', { path }] as const,
  workspaceDetail: (workspaceId: string) =>
    ['workspace-detail', { path: { workspaceId } }] as const,
};
