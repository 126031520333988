import { Image, Modal } from 'react-bootstrap';
import type { Map } from 'immutable';

import { FORM_STEPS, ONLY_READONLY_STORAGE } from '@/modules/sandboxes/Constants';
import { prepareSandboxTypeLabel } from '@/modules/sandboxes/helpers';
import { BadgeBeta } from '@/react/common/Badges';
import ModalActionButton from '@/react/common/ModalActionButton';
import ModalIcon from '@/react/common/ModalIcon';
import type { SandboxModalForceStep } from './types';

type Props = {
  forceStep?: SandboxModalForceStep;
  hasTableInputMapping?: boolean;
  onPrepareWorkspaces: () => Map<string, any>;
  onSelectSandbox: (sandbox: Map<string, any>, step: string) => void;
};

const ListModal = ({
  forceStep,
  hasTableInputMapping = false,
  onPrepareWorkspaces,
  onSelectSandbox,
}: Props) => {
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          {forceStep === FORM_STEPS.SANDBOX_UPDATE ? 'Select Workspace Backend' : 'New Workspace'}
        </Modal.Title>
        <ModalIcon icon="box" color="green" bold />
      </Modal.Header>
      <Modal.Body>
        {onPrepareWorkspaces()
          .sortBy((sandbox) => sandbox.get('name'))
          .sortBy((item) => item.get('beta'))
          .sortBy((sandbox) => {
            return hasTableInputMapping && ONLY_READONLY_STORAGE.includes(sandbox.get('type'));
          })
          .map((sandbox, index) => {
            return (
              <ModalActionButton
                key={index}
                icon={
                  <Image
                    width={48}
                    src={sandbox.get('imageSrc')}
                    alt={prepareSandboxTypeLabel(sandbox.get('type'))}
                  />
                }
                title={
                  <div className="tw-flex tw-items-center tw-gap-2">
                    {sandbox.get('name')} Workspace
                    {sandbox.get('beta') && <BadgeBeta />}
                  </div>
                }
                description={sandbox.get('description')}
                onClick={() => onSelectSandbox(sandbox, forceStep || FORM_STEPS.SANDBOX_CREATE)}
                {...(hasTableInputMapping &&
                  ONLY_READONLY_STORAGE.includes(sandbox.get('type')) && {
                    isDisabled: true,
                    disabledReason:
                      'This workspace does not support input mapping. All tables are available automatically.',
                  })}
              />
            );
          })}
      </Modal.Body>
    </>
  );
};

export default ListModal;
