import { Card, RadioGroup, Search } from '@keboola/design';

import routerAction from '@/actions/RouterActionCreators';
import SettingsTabs from '@/modules/settings/components/SettingsTabs';
import { routeNames } from '@/modules/settings/constants';
import {
  FEATURE_FILTER_TYPE,
  type FeatureFilterType,
  FeatureList,
  useProjectFeaturesQuery,
} from '@/react/common/FeatureList';
import { useToggleProjectFeatureMutation } from '@/react/common/FeatureList/hooks';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

export const FeaturesTab = () => {
  const projectId = ApplicationStore.getCurrentProjectId();
  const { data, isPending, isError } = useProjectFeaturesQuery(projectId);
  const toggleProjectFeatureMutation = useToggleProjectFeatureMutation(projectId);

  const filter = useStores(
    () => {
      const queryState = RoutesStore.getRouterState().getIn(['location', 'query']).toJS();
      return {
        type: queryState.type ?? FEATURE_FILTER_TYPE.ALL,
        query: queryState.query ?? '',
      };
    },
    [],
    [RoutesStore],
  );

  const setFilter = (newFilter: { query?: string; type?: FeatureFilterType }) => {
    routerAction.updateQuery({ ...filter, ...newFilter });
  };

  return (
    <>
      <SettingsTabs activeTab={routeNames.FEATURES} />

      <div className="tw-flex tw-flex-col tw-gap-6">
        <Search
          defaultValue={filter.query}
          onChange={(query) => setFilter({ query })}
          placeholder="Search features..."
          disableDebounce
          suffix={
            <RadioGroup
              value={filter.type}
              onChange={(type) => setFilter({ type })}
              variant="button"
            >
              <RadioGroup.Item value={FEATURE_FILTER_TYPE.ALL}>All</RadioGroup.Item>
              <RadioGroup.Separator />
              <RadioGroup.Item value={FEATURE_FILTER_TYPE.ENABLED}>ENABLED</RadioGroup.Item>
              <RadioGroup.Item value={FEATURE_FILTER_TYPE.DISABLED}>DISABLED</RadioGroup.Item>
              <RadioGroup.Separator />
              <RadioGroup.Item value={FEATURE_FILTER_TYPE.BETA}>BETA</RadioGroup.Item>
            </RadioGroup>
          }
        />

        <Card padding="large">
          <Card.Content>
            <FeatureList
              features={data}
              isLoading={isPending}
              isError={isError}
              variant="project"
              filter={filter}
              onFeatureToggle={toggleProjectFeatureMutation.toggle}
              isFeatureLoading={({ name }) =>
                toggleProjectFeatureMutation.isPending &&
                name === toggleProjectFeatureMutation.featureName
              }
            />
          </Card.Content>
        </Card>
      </div>
    </>
  );
};
