import type { Map } from 'immutable';

import { cn } from '@keboola/design';

import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentTypeIcon from './ComponentTypeIcon';

type Props = {
  component?: Map<string, any>;
  componentIconSize?: string;
  typeIconSize?: string;
  className?: string;
  isPhase?: boolean;
};

const ComponentIconWithType = ({
  component,
  componentIconSize,
  typeIconSize,
  className,
  isPhase,
}: Props) => {
  if (!component) {
    return null;
  }

  return (
    <div className={cn('component-icon-with-type', className)}>
      <ComponentTypeIcon type={component.get('type')} size={typeIconSize} />
      <ComponentIcon component={component} size={componentIconSize} isPhase={isPhase} />
    </div>
  );
};

export default ComponentIconWithType;
