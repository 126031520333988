import type { Map } from 'immutable';
import _ from 'underscore';

import { Link } from '@keboola/design';

import { routeNames } from '@/modules/storage/constants';

type Props = {
  bucket: Map<string, any>;
  urlTemplates: Map<string, string>;
};

const ExternalProjectBucketLink = ({ bucket, urlTemplates }: Props) => {
  const projectId = bucket.getIn(['project', 'id']);
  const projectName = bucket.getIn(['project', 'name']);

  return (
    <span>
      <Link href={_.template(urlTemplates.get('project'))({ projectId })}>{projectName}</Link>
      {' / '}
      <Link
        href={`${_.template(urlTemplates.get('project'))({
          projectId,
        })}/${routeNames.ROOT}/${bucket.get('id')}`}
      >
        {bucket.get('id')}
      </Link>
    </span>
  );
};

export default ExternalProjectBucketLink;
