import { useState } from 'react';
import type { Map } from 'immutable';

import { Button } from '@keboola/design';

import { updateBillingInformation } from '@/modules/billing/actions';
import { CircleIcon } from '@/react/common';
import { BillingAddress } from './BillingAddress';
import { BillingEditModal } from './BillingEditModal';

export const BillingInformation = (props: { billingInformation: Map<string, any> }) => {
  const [showEditModal, setShowEditModal] = useState(false);

  const hasBillingInformation = !props.billingInformation.isEmpty();

  return (
    <>
      <div className="box box-panel box-panel-small">
        <div className="box-header">
          <h2 className="box-title">Billing Details</h2>
          <CircleIcon icon="file-invoice" color="blue" bold />
        </div>
        {hasBillingInformation ? (
          <>
            <div className="box-panel-content mb-2">
              <BillingAddress billingInformation={props.billingInformation} />
            </div>
            <div className="box-panel-content padding-bottom mt-auto">
              <Button variant="outline" block onClick={() => setShowEditModal(true)}>
                Edit billing details
              </Button>
            </div>
          </>
        ) : (
          <div className="box-panel-content text-center mt-1 mb-2">
            <h3 className="f-14 line-height-20 mt-0">No Billing Details</h3>
            <p className="text-muted line-height-20 mb-1">
              Billing details will be accessible once completed.
            </p>
          </div>
        )}
      </div>
      <BillingEditModal
        show={showEditModal}
        billing={props.billingInformation}
        onHide={() => setShowEditModal(false)}
        onSubmit={updateBillingInformation}
      />
    </>
  );
};
