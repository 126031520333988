import type { Map } from 'immutable';

import { Link, Tooltip } from '@keboola/design';

import { canDownloadSlicedFile } from '@/modules/admin/privileges';
import ModalHandler from '@/modules/sapi-events/sliced-files-downloader/ModalHandler';
import { FileSize } from '@/react/common';
import ApplicationStore from '@/stores/ApplicationStore';

type Props = {
  file: Map<string, any>;
  showFilesize?: boolean;
  linkClass?: string;
};

const FileLink = ({ file, showFilesize = true, linkClass }: Props) => {
  const body = file.get('name');

  const filesize = showFilesize ? <FileSize size={file.get('sizeBytes')} /> : null;

  const renderSlicedFileDownloadModalTrigger = () => {
    const modalBody = (
      <>
        {body} {filesize}
      </>
    );

    if (canDownloadSlicedFile(ApplicationStore.getSapiToken())) {
      return (
        <ModalHandler file={file} linkClass={linkClass}>
          {modalBody}
        </ModalHandler>
      );
    }

    return modalBody;
  };

  if (file.get('isExpired')) {
    return (
      <Tooltip placement="top" tooltip="File expired" triggerClassName="text-muted">
        {body}
      </Tooltip>
    );
  }

  if (file.get('isSliced')) {
    return renderSlicedFileDownloadModalTrigger();
  }

  return (
    <Link href={file.get('url')} className={linkClass} download>
      {body} {filesize}
    </Link>
  );
};

export default FileLink;
