import { useState } from 'react';
import type { Map } from 'immutable';

import { IconButton, Tooltip } from '@keboola/design';

import RollbackVersionModal from './RollbackVersionModal';

type Props = {
  version: Map<string, any>;
  onRollback: () => void;
  componentId: string;
  isPending: boolean;
  isDisabled: boolean;
};

const RollbackVersionButton = ({
  version,
  onRollback,
  componentId,
  isPending,
  isDisabled,
}: Props) => {
  const [showModal, setShowModal] = useState(false);

  const handleRollback = () => {
    onRollback();
    setShowModal(false);
  };

  return (
    <>
      <Tooltip tooltip="Restore this version" placement="top">
        <IconButton
          variant="inline"
          onClick={() => setShowModal(true)}
          disabled={isDisabled || isPending}
          isLoading={isPending}
          icon="arrow-rotate-left"
        />
      </Tooltip>
      <RollbackVersionModal
        version={version}
        show={showModal}
        onClose={() => setShowModal(false)}
        onRollback={handleRollback}
        componentId={componentId}
      />
    </>
  );
};

export default RollbackVersionButton;
