import PropTypes from 'prop-types';
import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import Select from '@/react/common/Select';

const WritersModal = createReactClass({
  propTypes: {
    localState: PropTypes.object.isRequired,
    setLocalState: PropTypes.func,
    onChangeWriterFn: PropTypes.func,
    isSaving: PropTypes.bool,
  },

  getInitialState() {
    return { task: '' };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.isSaving) {
      this.setState({ task: '' });
    }
  },

  render() {
    return (
      <Modal show={this.props.localState.get('show', false)} onHide={this.close}>
        <Modal.Header>
          <Modal.Title>Choose Destination Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.renderBody()}</Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveLabel="Change"
            saveStyle="primary"
            isSaving={this.props.isSaving}
            isDisabled={this.state.task === ''}
            onSave={() => this.props.onChangeWriterFn(this.state.task)}
          />
        </Modal.Footer>
      </Modal>
    );
  },

  renderBody() {
    return (
      <div className="form form-horizontal">
        <FormGroup>
          <ControlLabel className="col-sm-4">Destination Type</ControlLabel>
          <div className="col-sm-8">
            <Select
              clearable={false}
              placholder="Please select..."
              value={this.state.task}
              onChange={(value) => this.setState({ task: value })}
              options={[
                { label: 'Tableau Server', value: 'tableauServer' },
                { label: 'Dropbox', value: 'keboola.wr-dropbox-v2' },
                { label: 'Google Drive', value: 'keboola.wr-google-drive' },
              ]}
            />
          </div>
        </FormGroup>
      </div>
    );
  },

  close() {
    this.props.setLocalState('show', false);
  },
});

export default WritersModal;
