import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import createReactClass from 'create-react-class';

import { Badge } from '@keboola/design';

const ActiveCountBadge = createReactClass({
  mixins: [PureRenderMixin],

  propTypes: {
    totalCount: PropTypes.number.isRequired,
    activeCount: PropTypes.number.isRequired,
  },

  render() {
    const { activeCount, totalCount } = this.props;

    return (
      <Badge variant={activeCount === 0 ? 'gray' : 'blue'}>
        {`${activeCount} / ${totalCount}`}
      </Badge>
    );
  },
});

export default ActiveCountBadge;
