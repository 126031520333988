import { useCallback } from 'react';

import { cn } from '@keboola/design';

import { sortEntities } from '@/constants';
import type { SetSortType, SortType } from '@/modules/data-catalog/types';
import { SortIcon } from '@/react/common';

type SortableHeaderProps = {
  label: string;
  entityKey: keyof typeof sortEntities;
  sort: SortType;
  setSort: SetSortType;
  arrowSide?: 'left' | 'right';
  justifyToEnd?: boolean;
};

export const SortableHeader = ({
  label,
  entityKey,
  sort,
  setSort,
  arrowSide = 'left',
  justifyToEnd = !arrowSide || arrowSide === 'left',
}: SortableHeaderProps) => {
  const handleSort = useCallback(() => {
    setSort({
      entity: sortEntities[entityKey],
      order: sort.get('entity') === sortEntities[entityKey] ? sort.get('order') * -1 : 1,
    });
  }, [setSort, sort, entityKey]);

  const SortIconComponent = () => (
    <SortIcon
      className={cn('icon-addon-left', { 'tw-ml-0': arrowSide === 'left' })}
      isSorted={sort.get('entity') === sortEntities[entityKey]}
      isSortedDesc={sort.get('order') === -1}
    />
  );

  return (
    <div
      className={cn('clickable tw-flex tw-items-center', { 'tw-justify-end': justifyToEnd })}
      onClick={handleSort}
      title={`Sort by ${label}`}
    >
      {arrowSide === 'left' && <SortIconComponent />}
      <span className="tw-whitespace-nowrap">{label}</span>
      {arrowSide === 'right' && <SortIconComponent />}
    </div>
  );
};
