import { Button } from '@keboola/design';

const CredentialsSetupBox = (props: { show: boolean; onClick: () => void; readOnly: boolean }) => {
  if (!props.show) {
    return null;
  }

  return (
    <div className="box">
      <div className="box-header with-border big-padding">
        <h3 className="box-title">Connect Database</h3>
      </div>
      <div className="box-content text-center">
        {props.readOnly ? (
          <p>No credentials provided.</p>
        ) : (
          <>
            <p>Please set up the database credentials.</p>
            <Button icon="user" onClick={props.onClick} className="tw-justify-self-center">
              Set Up Credentials
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default CredentialsSetupBox;
