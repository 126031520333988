import type { HTMLAttributes } from 'react';
import type { VariantProps } from 'class-variance-authority';

import { CardContent } from './CardContent';
import { CardHeader } from './CardHeader';
import { CardTitle } from './CardTitle';
import { cardClassnames } from './styles';

type Props = HTMLAttributes<HTMLDivElement> & VariantProps<typeof cardClassnames>;

export const Card = ({ children, className, padding = 'small', ...rest }: Props) => (
  <div className={cardClassnames({ className, padding })} {...rest}>
    {children}
  </div>
);

Card.Header = CardHeader;
Card.Content = CardContent;
Card.Title = CardTitle;
