import { useState } from 'react';
import type { ChangeEvent } from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import type { Map } from 'immutable';
import { parse, stringify } from 'smol-toml';
import _ from 'underscore';

import { cn, Icon } from '@keboola/design';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import type { ThemeName, ThemeValues } from '@/modules/data-apps/constants';
import { THEME_DEFAULT_FONT, themes } from '@/modules/data-apps/constants';
import { prepareTheme, resolveThemeName } from '@/modules/data-apps/helpers';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import SaveButtons from '@/react/common/SaveButtons';
import Select from '@/react/common/Select';

const HEX_REGEX = /^#([0-9A-F]{6}|[0-9A-F]{3})$/i;

const themeOptions: { value: ThemeName; label: string }[] = [
  { value: 'keboola', label: 'Keboola' },
  { value: 'streamlit', label: 'Streamlit Default' },
  { value: 'light-red', label: 'Light Red' },
  { value: 'light-purple', label: 'Light Purple' },
  { value: 'light-blue', label: 'Light Blue' },
  { value: 'dark-green', label: 'Dark Green' },
  { value: 'dark-amber', label: 'Dark Amber' },
  { value: 'dark-orange', label: 'Dark Orange' },
  { value: 'custom', label: 'Custom' },
] as const;

const fontOptions = [
  { value: THEME_DEFAULT_FONT, label: 'Sans Serif' },
  { value: 'serif', label: 'Serif' },
  { value: 'monospace', label: 'Monospace' },
];

const CONFIG_PATH = ['parameters', 'dataApp', 'streamlit', 'config.toml'];

const ThemeSettings = (props: {
  componentId: string;
  configId: string;
  configData: Map<string, any>;
  readOnly: boolean;
}) => {
  const parsedConfig = parse(props.configData.getIn(CONFIG_PATH, ''));
  const savedThemeValues = prepareTheme(parsedConfig);

  const [saving, setSaving] = useState(false);
  const [themeName, setThemeName] = useState<ThemeName>(resolveThemeName(savedThemeValues));
  const [themeValues, setThemeValues] = useState<ThemeValues>(savedThemeValues);

  const handleSetThemeValues = (updatedThemeValues: ThemeValues) => {
    setThemeValues({ ...themeValues, ...updatedThemeValues });
  };

  const isThemeValid = () => {
    if (themeName === 'streamlit') {
      return Object.values(themeValues).length === 0;
    }

    return (
      themeValues.font &&
      themeValues.textColor &&
      themeValues.backgroundColor &&
      themeValues.secondaryBackgroundColor &&
      themeValues.primaryColor
    );
  };

  const renderColorInput = (themeKey: string, label: string) => {
    const hasHexValue = !!HEX_REGEX.test(themeValues[themeKey]);

    return (
      <FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <div className="tw-relative">
          <FormControl
            className={cn(hasHexValue && 'tw-pl-11')}
            type="text"
            value={themeValues[themeKey]}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value;
              handleSetThemeValues({
                [themeKey]: !value.includes('#') && value.length > 0 ? `#${value}` : value,
              });
            }}
          />

          {hasHexValue && (
            <div
              className="tw-absolute tw-left-4 tw-top-2.5 tw-h-5 tw-w-5 tw-rounded-sm tw-border tw-border-solid tw-border-neutral-300"
              style={{ background: themeValues[themeKey] || 'transparent' }}
            />
          )}

          <input
            type="color"
            className="tw-absolute tw-right-1 tw-top-0.5 tw-h-9 tw-w-9 tw-cursor-pointer tw-opacity-0"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              handleSetThemeValues({
                [themeKey]: event.target.value,
              })
            }
          />

          <Icon
            icon="eye-dropper"
            className="tw-pointer-events-none tw-absolute tw-right-3.5 tw-top-3 tw-text-base tw-text-neutral-400"
            fixedWidth
          />
        </div>
      </FormGroup>
    );
  };

  return (
    <CollapsibleBox
      title="Theme"
      additionalActions={() => {
        return (
          <SaveButtons
            disabled={props.readOnly || !isThemeValid()}
            isSaving={saving}
            isChanged={!_.isEqual(savedThemeValues, themeValues)}
            onSave={() => {
              setSaving(true);

              const newConfig = stringify({
                ..._.omit(parsedConfig, 'theme'),
                ...(themeName !== 'streamlit' && { theme: themeValues }),
              });

              return InstalledComponentsActionCreators.saveComponentConfigData(
                props.componentId,
                props.configId,
                newConfig
                  ? props.configData.setIn(CONFIG_PATH, newConfig)
                  : props.configData.deleteIn(CONFIG_PATH),
                'Theme changed',
              ).finally(() => setSaving(false));
            }}
            onReset={() => setThemeValues(savedThemeValues)}
          />
        );
      }}
    >
      <FormGroup>
        <ControlLabel>Color Palette</ControlLabel>
        <Select
          clearable={false}
          searchable={false}
          value={themeName}
          placeholder="Select a palette"
          onChange={(name: ThemeName) => {
            setThemeName(name);
            setThemeValues(themes[name]);
          }}
          options={themeOptions}
        />
      </FormGroup>

      {themeName === 'custom' && (
        <>
          <FormGroup className="tw-mt-4">
            <ControlLabel>Typeface</ControlLabel>
            <Select
              clearable={false}
              searchable={false}
              value={themeValues.font}
              onChange={(font: string) => handleSetThemeValues({ font })}
              options={fontOptions}
            />
          </FormGroup>

          {renderColorInput('primaryColor', 'Primary Color')}
          {renderColorInput('textColor', 'Text Color')}
          {renderColorInput('backgroundColor', 'Background Color')}
          {renderColorInput('secondaryBackgroundColor', 'Secondary Background Color')}
        </>
      )}
    </CollapsibleBox>
  );
};

export default ThemeSettings;
