import type { ReactNode } from 'react';
import type { Map } from 'immutable';

import { cn } from '@keboola/design';

import { CreatedDate, Truncated } from '@/react/common';
import Gravatar from './Gravatar';

const TimeAndUser = ({
  admin,
  time,
  timeSuffix,
  fallbackName = '',
  showAvatar = true,
  absolute = false,
  withSeconds = false,
  avatarPosition = 'right',
}: {
  time?: string;
  fallbackName?: string;
  admin?: Map<string, any>;
  timeSuffix?: ReactNode;
  showAvatar?: boolean;
  absolute?: boolean;
  withSeconds?: boolean;
  avatarPosition?: 'left' | 'right';
}) => {
  return (
    <div
      className={cn('flex-container inline-flex flex-start', {
        'flex-reverse': avatarPosition === 'left',
      })}
    >
      <div>
        <div className="flex-container inline-flex flex-start">
          <CreatedDate createdTime={time} absolute={absolute} withSeconds={withSeconds} />
          {timeSuffix}
        </div>
        <br />
        <Truncated
          className="text-muted"
          text={admin?.get('name') || admin?.get('email') || fallbackName}
          tooltip={admin?.get('email') || fallbackName}
        />
      </div>
      {showAvatar && (
        <span
          className={cn(
            'line-height-1',
            avatarPosition === 'left' ? 'icon-addon-right' : 'icon-addon-left',
          )}
        >
          <Gravatar user={admin} fallback={fallbackName} />
        </span>
      )}
    </div>
  );
};

export default TimeAndUser;
