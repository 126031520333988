import { createElement, useCallback } from 'react';
import type { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import { List, Map } from 'immutable';

import { ButtonGroup, cn, Icon } from '@keboola/design';

import type { UserMessage } from '@/api/routes/aiService';
import { EXPERIMENTAL } from '@/constants/componentFlags';
import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { HEADER_BUTTONS } from '@/constants/external';
import { APP_ROUTE } from '@/constants/routeNames';
import { isComponentDeprecated } from '@/modules/components/helpers';
import { ComponentNameEdit } from '@/modules/components/react/components/ComponentNameEdit';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ComponentIconWithType from '@/modules/components-directory/components/ComponentIconWithType';
import { routeNames as componentsRoutes } from '@/modules/components-directory/constants';
import ConfigurationRowName from '@/modules/configurations/react/components/ConfigurationRowName';
import BackToFlowButton from '@/modules/flows/components/BackToFlowButton';
import { routeNames as flowsRouteNames } from '@/modules/flows/constants';
import { routeNames as orchestrationsRouteNames } from '@/modules/orchestrations-v2/constants';
import { isPhaseJob } from '@/modules/queue/helpers';
import JobsStore from '@/modules/queue/store';
import { routeNames as snowflakePartnerConnectRouteNames } from '@/modules/snowflake-partner-connect/constants';
import TransformationType from '@/modules/transformations/react/components/TransformationType';
import { routeNames as transformationsRouteNames } from '@/modules/transformations-v2/constants';
import {
  getComponentTypeIcon,
  getComponentTypeLabel,
  resolveComponent,
} from '@/react/admin/project/helpers';
import Labels from '@/react/admin/project/Labels';
import { RouterLink } from '@/react/common';
import CollapsibleAlert from '@/react/common/CollapsibleAlert';
import ExperimentalComponentInfoAlert from '@/react/common/ExperimentalComponentInfoAlert';
import useStores from '@/react/hooks/useStores';
import RoutePendingIndicator from '@/react/layout/RoutePendingIndicator';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import contactSupport from '@/utils/contactSupport';

const PageTitle = (props: {
  hasInvalidCustomBackend: boolean;
  isDemoPreview: boolean;
  submitAutomationMessage?: (message: Partial<UserMessage>) => void;
}) => {
  const store = useStores(
    () => {
      const realComponentId = RoutesStore.getCurrentRouteComponentId();
      const configId = RoutesStore.getConfigId();
      const currentRouteConfig = RoutesStore.getCurrentRouteConfig();
      const routerState = RoutesStore.getRouterState();

      return {
        routerState,
        realComponentId,
        currentRouteConfig,
        component: resolveComponent(),
        configuration: InstalledComponentsStore.getConfig(realComponentId, configId),
        router: RoutesStore.getRouter(),
        routerError: RoutesStore.getError(),
        breadcrumbs: RoutesStore.getBreadcrumbs(),
        currentRouteParams: routerState.get('params', Map()),
        currentLocationQuery: routerState.getIn(['location', 'query'], Map()),
        hasNewQueue: ApplicationStore.hasNewQueue(),
        allFlows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      };
    },
    [],
    [JobsStore, RoutesStore, ApplicationStore, InstalledComponentsStore],
  );

  const pageName = store.breadcrumbs.last()?.get('name');
  const isBackFlowEmpty = !store.routerState.getIn(['location', 'state', 'flowId']);

  const getBackFlowBreadcrumbs = useCallback(() => {
    const configIndex = store.breadcrumbs.findIndex((part: Map<string, any>) => {
      return part.hasIn(['link', 'params', 'config']);
    });

    return store.breadcrumbs.slice(configIndex);
  }, [store.breadcrumbs]);

  const getCurrentRouteQueryParams = useCallback(() => {
    return store.currentRouteConfig
      .get('persistQueryParams', List())
      .reduce((result: Map<string, any>, item: string) => {
        return result.set(item, store.currentLocationQuery.get(item));
      }, Map())
      .filter(Boolean);
  }, [store.currentLocationQuery, store.currentRouteConfig]);

  const renderName = () => {
    if (!store.breadcrumbs.count()) {
      return null;
    }

    const page = store.breadcrumbs.last();
    const linkTo = page.getIn(['link', 'to']);

    if (
      [
        store.component.get('id'),
        flowsRouteNames.DETAIL,
        orchestrationsRouteNames.DETAIL,
        transformationsRouteNames.GENERIC_TRANSFORMATION_CONFIG,
        componentsRoutes.GENERIC_CONFIG,
      ].includes(linkTo)
    ) {
      return (
        <h1 className={cn(!isBackFlowEmpty && 'reduce-size')}>
          <ComponentNameEdit
            componentId={store.realComponentId || store.component.get('id')}
            configId={store.currentRouteParams.get('config')}
          />
        </h1>
      );
    }

    if (
      [`${store.component.get('id')}-row`, componentsRoutes.GENERIC_CONFIG_ROW].includes(linkTo)
    ) {
      return (
        <h1>
          <ConfigurationRowName
            componentId={store.realComponentId || store.component.get('id')}
            configId={store.currentRouteParams.get('config')}
            rowId={store.currentRouteParams.get('row')}
          />
        </h1>
      );
    }

    if (store.currentRouteConfig.get('nameEdit')) {
      return <h1>{store.currentRouteConfig.get('nameEdit')(store.currentRouteParams.toJS())}</h1>;
    }

    return <h1>{page.get('title')}</h1>;
  };

  const renderFlowsButtons = () => {
    const backButton = getBackFlowBreadcrumbs().get(-2); // get route before last route
    const exploreButtonLinkParams = {
      to: store.routerState
        .get('routes')
        .reverse()
        .find((route: Map<string, any>) => route.has('name'))
        ?.get('name'),
      params: store.currentRouteParams.toJS(),
    };

    return (
      <ButtonGroup>
        {!!backButton && (
          <RouterLink
            onlyActiveOnIndex
            className="btn btn-default"
            to={backButton.getIn(['link', 'to'])}
            params={backButton.getIn(['link', 'params'], Map()).toJS()}
          >
            <Icon icon="chevron-left" className="icon-addon-right" />
            Back
          </RouterLink>
        )}
        {!!exploreButtonLinkParams.to &&
          !store.routerState.getIn(['location', 'state', 'automationId']) && (
            <RouterLink
              className="btn btn-default"
              activeClassName=""
              to={exploreButtonLinkParams.to}
              params={exploreButtonLinkParams.params}
              state={{ resetBackFlow: true }}
            >
              <Icon icon={getComponentTypeIcon(store.component)} className="icon-addon-right" />
              Explore in {getComponentTypeLabel(store.component)}s
            </RouterLink>
          )}
        <BackToFlowButton
          flow={store.routerState.getIn(['location', 'state']).toJS()}
          component={store.component.toJS()}
          configuration={store.configuration.toJS()}
          submitAutomationMessage={props.submitAutomationMessage}
        />
      </ButtonGroup>
    );
  };

  const renderIcon = () => {
    if (store.component.has('backend')) {
      return (
        <span className="breadcrumb-component-icon">
          <TransformationType showLabel={false} transformation={store.component} imageSize="64" />
        </span>
      );
    }

    if (!store.component.isEmpty()) {
      return (
        <ComponentIconWithType
          component={store.component}
          typeIconSize="18"
          className="breadcrumb-component-icon"
          isPhase={
            store.hasNewQueue &&
            store.currentRouteParams.has('jobId') &&
            isPhaseJob(JobsStore.get(store.currentRouteParams.get('jobId')))
          }
        />
      );
    }

    return null;
  };

  const renderBreadcrumbs = () => {
    const breadcrumbs: ReactNode[] = [];
    const filteredBreadcrumbs = isBackFlowEmpty ? store.breadcrumbs : getBackFlowBreadcrumbs();

    filteredBreadcrumbs.forEach((part: Map<string, any>, i: number) => {
      if (i !== filteredBreadcrumbs.count() - 1) {
        breadcrumbs.push(
          <RouterLink
            className="dark muted"
            key={`${i}-${part.get('name')}`}
            to={part.getIn(['link', 'to'])}
            params={part.getIn(['link', 'params'], Map()).toJS()}
            query={part.getIn(['link', 'query'], getCurrentRouteQueryParams()).toJS()}
          >
            {part.get('title')}
          </RouterLink>,
        );

        if (i !== filteredBreadcrumbs.count() - 2) {
          breadcrumbs.push(
            <Icon icon={['far', 'angle-right']} key={`arrow-${i}-${part.get('name')}`} />,
          );
        }
      }
    });

    if (store.currentRouteConfig.get('breadcrumbHandler')) {
      return (
        <div className="breadcrumb">
          {createElement(store.currentRouteConfig.get('breadcrumbHandler'), {
            breadcrumbs,
            params: store.currentRouteParams.toJS(),
          })}
        </div>
      );
    }

    const subtitle = filteredBreadcrumbs.last()?.get('subtitle');

    if (store.routerError || (!breadcrumbs.length && !subtitle)) {
      return null;
    }

    return (
      <div className="breadcrumb">
        {breadcrumbs.length < 2 && subtitle ? (
          <span className="active">{subtitle}</span>
        ) : (
          breadcrumbs
        )}
        <RoutePendingIndicator />
      </div>
    );
  };

  const renderInfoAlert = () => {
    const infoAlertHandler = store.currentRouteConfig.get('infoAlertHandler');
    const isExperimental = store.component.get('flags', List()).includes(EXPERIMENTAL);

    if (!infoAlertHandler && !isExperimental) {
      return null;
    }

    return (
      <>
        {isExperimental && (
          <div className="container tw-mt-5">
            <ExperimentalComponentInfoAlert />
          </div>
        )}
        {infoAlertHandler && createElement(infoAlertHandler)}
      </>
    );
  };

  const renderButtons = () => {
    const showCustomHeaderButtons =
      !store.routerError && store.currentRouteConfig.get('headerButtonsHandler');
    const showBackToFlowButton = !isBackFlowEmpty;

    if (!showCustomHeaderButtons && !showBackToFlowButton) {
      return null;
    }

    return (
      <div className={`top-buttons ${HEADER_BUTTONS}`}>
        {showCustomHeaderButtons &&
          createElement(store.currentRouteConfig.get('headerButtonsHandler'), {
            descriptionIconOnly: showBackToFlowButton,
          })}
        {showCustomHeaderButtons && showBackToFlowButton && <span className="btn-separator" />}
        {showBackToFlowButton && renderFlowsButtons()}
      </div>
    );
  };

  if (
    (store.allFlows.isEmpty() && pageName === flowsRouteNames.ROOT) ||
    (props.isDemoPreview && pageName === APP_ROUTE) ||
    pageName === snowflakePartnerConnectRouteNames.UPGRADE_PAGE
  ) {
    return null;
  }

  return (
    <div className="container-topbar navbar navbar-default">
      {!store.routerError && renderInfoAlert()}
      {!store.routerError &&
        isComponentDeprecated(store.component) &&
        store.currentRouteParams.get('config') && (
          <div className="container tw-mt-5">
            <CollapsibleAlert
              id="deprecated-component"
              title="This component has been deprecated"
              variant="warning"
            >
              New configuration cannot be created. Consider switching to another component to ensure
              a functionality in the future.{' '}
              <Button bsStyle="link" className="btn-link-inline" onClick={() => contactSupport()}>
                Contact our support
              </Button>{' '}
              if you have any questions.
            </CollapsibleAlert>
          </div>
        )}
      {!props.hasInvalidCustomBackend && (
        <div className="container flex-container breadcrumb-container">
          {!store.routerError && (
            <div className="title">
              {renderIcon()}
              <div className="flex-container flex-column align-top justify-center">
                {renderBreadcrumbs()}
                <div className="flex-container flex-start pr-2">
                  {renderName()}
                  <Labels />
                </div>
              </div>
            </div>
          )}
          {renderButtons()}
        </div>
      )}
    </div>
  );
};

export default PageTitle;
