import Checkbox from '@/react/common/Checkbox';
import type { TableData } from '@/types/types';

type Props = {
  table: TableData;
};

export const HeaderSelection = ({ table }: Props) => {
  const allRows = table.getRowModel().flatRows;

  if (allRows.length === 0) {
    return null;
  }

  const isAllSelected = allRows.every((row) => row.getIsSelected());
  const isSomeSelected = allRows.some((row) => row.getIsSelected());

  return (
    <Checkbox
      checked={isAllSelected}
      indeterminate={!isAllSelected && isSomeSelected}
      onChange={() => {
        const toggleState = !(isAllSelected || isSomeSelected);
        allRows.forEach((row) => row.toggleSelected(toggleState));
      }}
      tooltip={isAllSelected || isSomeSelected ? 'Deselect all' : 'Select all'}
    />
  );
};
