import { lazy, Suspense } from 'react';

import LoadingBlock from '@/react/common/LoadingBlock';
import type { WorkspaceData } from './api';

const GoodDataDashboard = lazy(() => {
  return import(/* webpackChunkName: "gooddata-dashboard" */ './GoodDataDashboard');
});

const LazyLoadedDashboard = (props: { credentials: WorkspaceData }) => {
  return (
    <Suspense fallback={<LoadingBlock label="Loading dashboard..." className="tw-h-12" />}>
      <GoodDataDashboard
        userToken={props.credentials.userToken}
        workspaceId={props.credentials.workspaceId}
        dashboardId={props.credentials.dashboardId}
      />
    </Suspense>
  );
};

export default LazyLoadedDashboard;
