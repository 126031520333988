import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import type { PrevieData, WhereFilters } from '@keboola/api-client';
import { Button, ButtonGroup, Icon } from '@keboola/design';

import { deleteTableRows } from '@/modules/storage/actions';
import Checkbox from '@/react/common/Checkbox';
import Loader from '@/react/common/Loader';
import { TableRowsPreview } from './TableRowsPreview';

export const PreviewStep = (props: {
  previewData: PrevieData | null;
  onBack: () => void;
  onHide: () => void;
  tableId: string;
  params: {
    changedSince?: string;
    changedUntil?: string;
    validFilters?: WhereFilters[];
  };
}) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  if (!props.previewData) {
    return null;
  }

  const handleDelete = () => {
    setIsDeleting(true);
    deleteTableRows(props.tableId, props.params)
      .then(props.onHide)
      .finally(() => setIsDeleting(false));
  };

  return (
    <>
      <Modal.Body>
        <TableRowsPreview previewData={props.previewData} />
        <Checkbox checked={confirmDelete} onChange={setConfirmDelete}>
          I confirm that I want to delete matched rows
        </Checkbox>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup className="tw-w-full">
          <Button
            variant="outline"
            className="tw-whitespace-nowrap"
            onClick={props.onBack}
            disabled={isDeleting}
          >
            <Icon icon="arrow-left" />
            Back to filters
          </Button>
          <Button
            variant="danger"
            className="tw-w-full"
            onClick={handleDelete}
            disabled={!confirmDelete || isDeleting}
          >
            {isDeleting ? (
              <>
                <Loader />
                Deleting matched rows...
              </>
            ) : (
              <>
                <Icon icon="trash" />
                Delete matched rows
              </>
            )}
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </>
  );
};
