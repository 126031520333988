import MetadataActionCreators from '@/modules/components/MetadataActionCreators';
import type { ObjectTypes } from '@/modules/components/MetadataConstants';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import { getDescriptionValue } from '@/modules/storage/helpers';
import InlineDescriptionEditInput from '@/react/common/InlineDescriptionEditInput';
import type { RowData } from '@/types/types';

type EntityType = (typeof ObjectTypes)[keyof typeof ObjectTypes];

type Props = {
  row: RowData;
  entity: EntityType;
  readOnly: boolean;
};

export const Description = ({ row, entity, readOnly }: Props) => (
  <div className="f-13 text-muted">
    <InlineDescriptionEditInput
      entity={entity}
      description={getDescriptionValue(row.original.item.get('metadata'))}
      readOnly={readOnly}
      onSave={(newDescription) =>
        MetadataActionCreators.saveMetadata(
          entity,
          row.original.item.get('id'),
          MetadataKeys.DESCRIPTION,
          newDescription.trim(),
        )
      }
    />
  </div>
);
