import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import CredentialsActionCreators from '@/modules/provisioning/ActionCreators';
import SnowflakeCredentials from '@/modules/provisioning/react/components/SnowflakeCredentials';
import SnowflakeSandboxCredentialsStore from '@/modules/provisioning/stores/SnowflakeSandboxCredentialsStore';
import createStoreMixin from '@/react/mixins/createStoreMixin';

const SnowflakeCredentialsContainer = createReactClass({
  mixins: [createStoreMixin(SnowflakeSandboxCredentialsStore)],

  propTypes: {
    isAutoLoad: PropTypes.bool.isRequired,
  },

  componentDidMount() {
    if (!this.state.credentials.get('id') && this.props.isAutoLoad) {
      CredentialsActionCreators.createSnowflakeSandboxCredentials();
    }
  },

  getStateFromStores() {
    return {
      credentials: SnowflakeSandboxCredentialsStore.getCredentials(),
      pendingActions: SnowflakeSandboxCredentialsStore.getPendingActions(),
    };
  },

  render() {
    return (
      <SnowflakeCredentials
        credentials={this.state.credentials}
        isCreating={this.state.pendingActions.get('create')}
      />
    );
  },
});

export default SnowflakeCredentialsContainer;
