import { memo } from 'react';
import Promise from 'bluebird';

import { Icon } from '@keboola/design';

import { saveFolderToMetadata } from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import TableCollapseButton from '@/react/common/TableCollapseButton';
import type { ConfigRow } from './constants';
import FolderName from './FolderName';
import { getRealComponentId } from './helpers';

type Props = {
  name: string;
  isExpanded: boolean;
  readOnly: boolean;
  rows: ConfigRow[];
};

const ConfigurationsFolderName = (props: Props) => {
  return (
    <span className="with-expand overflow-break-anywhere tw-flex tw-items-center">
      <TableCollapseButton entity="folder" isCollapsed={!props.isExpanded} />
      <Icon fixedWidth icon={props.isExpanded ? 'folder-open' : 'folder'} className="text-muted" />
      <FolderName
        name={props.name}
        readOnly={props.readOnly}
        onSave={(folderName) => {
          return Promise.map(
            props.rows,
            (row) => {
              return saveFolderToMetadata(
                getRealComponentId(row.original.data.config, row.original.data.component),
                row.original.data.config.get('id'),
                folderName,
                { skipStoreUpdate: true },
              );
            },
            { concurrency: 3 },
          ).then(() => InstalledComponentsActionCreators.loadComponentsMetadataForce());
        }}
      />
    </span>
  );
};

const MemoizedConfigurationsFolderName = memo(ConfigurationsFolderName);

export default MemoizedConfigurationsFolderName;
