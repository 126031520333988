import { useState } from 'react';
import type { KeyboardEvent, MouseEvent } from 'react';
import { Button } from 'react-bootstrap';
import { Promise } from 'bluebird';
import type { List } from 'immutable';
import { Map } from 'immutable';

import { cn, Icon, Tooltip } from '@keboola/design';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { canManageNotifications } from '@/modules/admin/privileges';
import {
  addNotification,
  loadNotificationsForce,
  removeNotification,
} from '@/modules/notifications/actions';
import { prepareOneTimeNotifications } from '@/modules/notifications/helpers';
import { JOB_RUNNING_STATUSES } from '@/modules/queue/constants';
import { RowActionMenuItem } from '@/react/common';
import Loader from '@/react/common/Loader';
import ApplicationStore from '@/stores/ApplicationStore';

const OneTimeNotificationButton = (props: {
  admin: Map<string, any>;
  notifications: List<any>;
  job?: Map<string, any> | null;
  inline?: boolean;
  menuitem?: boolean;
  className?: string;
  onKeyDown?: (event: KeyboardEvent) => void;
}) => {
  const [isLoading, setLoading] = useState(false);

  if (!props.job || !JOB_RUNNING_STATUSES.includes(props.job.get('status'))) {
    return null;
  }

  const adminNotifications = props.notifications.filter((notification) => {
    return (
      notification.getIn(['recipient', 'address']) === props.admin.get('email') &&
      notification.get('filters').some((filter: Map<string, any>) => {
        return filter.get('value') === props.job!.get('id');
      })
    );
  });
  const hasNotification = !adminNotifications.isEmpty();

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    setLoading(true);
    Promise.resolve()
      .then(() => {
        if (hasNotification) {
          return Promise.each(adminNotifications.toArray(), (notification) => {
            return removeNotification(notification.get('id'));
          });
        }

        return Promise.each(
          prepareOneTimeNotifications(props.job ?? Map(), props.admin.get('email')),
          addNotification,
        ).tap(() => {
          ApplicationActionCreators.sendNotification({
            type: 'info',
            message: "You'll receive an email notification when the job finishes.",
          });
        });
      })
      .then(loadNotificationsForce)
      .finally(() => setLoading(false));
  };

  if (!canManageNotifications(ApplicationStore.getSapiToken())) {
    return null;
  }

  if (props.menuitem) {
    return (
      <RowActionMenuItem onSelect={handleClick} disabled={isLoading} onKeyDown={props.onKeyDown}>
        {isLoading ? (
          <Loader />
        ) : (
          <Icon fixedWidth icon="bell" className={cn({ 'color-primary': hasNotification })} />
        )}
        {hasNotification ? 'Cancel notification' : 'Notify me'}
      </RowActionMenuItem>
    );
  }

  return (
    <Tooltip tooltip={hasNotification ? 'Cancel Notification' : 'Notify me'} placement="top">
      <Button
        bsStyle={props.inline ? 'link' : 'default'}
        onClick={handleClick}
        disabled={isLoading}
        className={cn(
          props.inline ? 'btn-link-inline text-muted f-14' : 'no-label',
          props.className,
        )}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <Icon fixedWidth icon="bell" className={cn({ 'color-primary': hasNotification })} />
        )}
      </Button>
    </Tooltip>
  );
};

export default OneTimeNotificationButton;
