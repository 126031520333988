import { type ChangeEvent, Component, type FormEvent } from 'react';
import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import type { Map } from 'immutable';

import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import DevBranchStorageWarning from './DevBranchStorageWarning';

type State = {
  isLoading: boolean;
  description: string;
};

type Props = {
  show: boolean;
  table: Map<string, any>;
  columnName: string;
  onSubmit: (description: string) => Promise<any>;
  onHide: () => void;
  description?: string;
};

class ColumnDescriptionModal extends Component<Props, State> {
  state = {
    isLoading: false,
    description: '',
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => this.setState({ description: this.props.description || '' })}
      >
        <form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Column {this.props.columnName} Description</Modal.Title>
            <ModalIcon.Edit />
          </Modal.Header>
          <Modal.Body>
            <DevBranchStorageWarning
              message="The column description will also be modified in the production table."
              hasProductionEntity={!isCreatedInDevBranch(this.props.table.get('bucket'))}
            />
            <FormGroup>
              <ControlLabel>Description</ControlLabel>
              <FormControl
                autoFocus
                componentClass="textarea"
                placeholder="Describe your column"
                value={this.state.description}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  this.setState({ description: e.target.value })
                }
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel={this.state.isLoading ? 'Updating description...' : 'Update description'}
              saveButtonType="submit"
              isSaving={this.state.isLoading}
            />
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.setState({ isLoading: true });
    return this.props
      .onSubmit(this.state.description)
      .then(() => this.setState({ isLoading: false }))
      .then(this.props.onHide);
  };
}

export default ColumnDescriptionModal;
