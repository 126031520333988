import Avatar from 'boring-avatars';
import type { Map } from 'immutable';

import { cn } from '@keboola/design';

import { useSHA256 } from '@/utils/crypto';
import randomNumber from '@/utils/randomNumber';

const styles = {
  wrapper: {
    position: 'relative',
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  gravatar: {
    position: 'absolute',
    left: 0,
  },
} as const;

const Gravatar = ({
  user,
  fallback,
  size = 36,
  className,
}: {
  user?: Map<string, any>;
  fallback?: string;
  className?: string;
  size?: number;
}) => {
  const userName = user?.get('name') || user?.get('email');
  const email = user?.get('email');
  const hash = useSHA256(email ? email.trim().toLowerCase() : fallback || '');

  return (
    <span style={styles.wrapper} className={cn('circle overflow-hidden', className)}>
      <Avatar
        name={userName || fallback || randomNumber()}
        variant="beam"
        colors={['#1F8FFF', '#004787', '#28D8FF', '#925cff', '#A8C2E6']}
        size={size}
      />
      {hash && (
        <img
          src={`https://secure.gravatar.com/avatar/${hash}?s=${size * 3}&d=blank`}
          width={size}
          height={size}
          alt={userName || fallback || 'User avatar'}
          referrerPolicy="no-referrer"
          loading="lazy"
          style={styles.gravatar}
        />
      )}
    </span>
  );
};

export default Gravatar;
