import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { type Map } from 'immutable';

import { type PrevieData, useApiClient } from '@keboola/api-client';

import { previewLimit } from '@/modules/storage/constants';
import ModalIcon from '@/react/common/ModalIcon';
import { FormStep } from './FormStep';
import type { Params } from './helpers';
import { DEFAULT_WHERE_FILTER, prepareParams } from './helpers';
import { PreviewStep } from './PreviewStep';

const STEPS = {
  FORM: 'form',
  PREVIEW: 'preview',
};

const DEFAULT_PARAMS: Params = {
  changedSince: '',
  changedUntil: '',
  whereFilters: [DEFAULT_WHERE_FILTER],
};

export const DeleteRowsModal = (props: {
  show: boolean;
  onHide: () => void;
  table: Map<string, any>;
}) => {
  const api = useApiClient();
  const [step, setStep] = useState(STEPS.FORM);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState(DEFAULT_PARAMS);
  const [previewData, setPreviewData] = useState<PrevieData | null>(null);

  const validParams = prepareParams(props.table, params);

  const loadPreview = async () => {
    setError(null);
    setPreviewData(null);
    setIsLoading(true);
    try {
      const previewData = await api.storage.tables.getDataPreview(props.table.get('id'), {
        limit: previewLimit,
        ...validParams,
      });

      if (!previewData?.rows.length) {
        setError('No rows found matching the filters.');
        return;
      }

      setPreviewData(previewData);
      setStep(STEPS.PREVIEW);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      bsSize="lg"
      onHide={props.onHide}
      show={props.show}
      onEnter={() => {
        setError(null);
        setStep(STEPS.FORM);
        setParams(DEFAULT_PARAMS);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete table rows</Modal.Title>
        <ModalIcon icon="table-rows" color="green" bold />
      </Modal.Header>
      {step === STEPS.FORM ? (
        <FormStep
          error={error}
          isLoading={isLoading}
          table={props.table}
          params={params}
          setParams={(params) => {
            setError(null);
            setParams(params);
          }}
          onLoadPreview={loadPreview}
        />
      ) : (
        <PreviewStep
          previewData={previewData}
          tableId={props.table.get('id')}
          params={validParams}
          onBack={() => setStep(STEPS.FORM)}
          onHide={props.onHide}
        />
      )}
    </Modal>
  );
};
