import PropTypes from 'prop-types';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import Checkbox from '@/react/common/Checkbox';
import TaskSelectTableRow from './TaskSelectTableRow';

const TaskSelectTable = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    tasks: PropTypes.object.isRequired,
    onTaskUpdate: PropTypes.func.isRequired,
    onTasksUpdate: PropTypes.func.isRequired,
  },

  render() {
    const allActive = this.isAllActive();

    return (
      <div className="table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Component</th>
              <th>Action</th>
              <th>Parameters</th>
              <th>
                <Checkbox checked={allActive} onChange={() => this.handleActiveAll(allActive)}>
                  Select All
                </Checkbox>
              </th>
            </tr>
          </thead>
          <tbody>{this.renderTasks()}</tbody>
        </table>
      </div>
    );
  },

  handleActiveAll(active) {
    const updatedTasks = this.props.tasks.map((phase) => {
      return phase.set(
        'tasks',
        phase.get('tasks').map((task) => task.set('active', !active)),
      );
    });

    this.props.onTasksUpdate(updatedTasks);
  },

  renderTasks() {
    let tasks = List();
    this.props.tasks.forEach((phase) => {
      const phaseId = phase.get('id');
      const tasksRows = phase.get('tasks').map((task, index) => {
        return (
          <TaskSelectTableRow
            key={`phase-${phaseId}-task-${index}`}
            task={task}
            component={ComponentsStore.getComponent(task.get('component'))}
            onTaskUpdate={this.props.onTaskUpdate}
          />
        );
      });
      tasks = tasks.push(this.renderPhaseRow(phaseId)).concat(tasksRows);
    });

    if (!tasks.count()) {
      return (
        <tr>
          <td colSpan={4} className="text-muted">
            There are no tasks assigned yet.
          </td>
        </tr>
      );
    }

    return tasks.toArray();
  },

  renderPhaseRow(phaseId) {
    return (
      <tr className="text-center" key={`phase-${phaseId}`}>
        <td colSpan={4}>
          <strong>{phaseId}</strong>
        </td>
      </tr>
    );
  },

  isAllActive() {
    let allActive = true;

    this.props.tasks.forEach((phase) => {
      phase.get('tasks').forEach((task) => {
        if (allActive && !task.get('active')) {
          allActive = false;
        }
      });
    });

    return allActive;
  },
});

export default TaskSelectTable;
