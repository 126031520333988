import { Component } from 'react';
import PropTypes from 'prop-types';
import Promise from 'bluebird';
import { List, Map } from 'immutable';

import { cn, IconButton, Tooltip } from '@keboola/design';

import keyCodes from '@/constants/keyCodes';
import { resolveRouterLinkParams } from '@/modules/components/helpers';
import InstalledActionsCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import { toggleAllConfigurations, toggleConfigurationChange } from '@/modules/dev-branches/actions';
import {
  deletedInProduction,
  filterSelectedUpdatedComponents,
  updatedInProduction,
} from '@/modules/dev-branches/helpers';
import { routeNames as trashRouteNames } from '@/modules/trash/constants';
import { Truncated } from '@/react/common';
import Checkbox from '@/react/common/Checkbox';
import CollapseButton from '@/react/common/CollapseButton';
import ComponentIcon from '@/react/common/ComponentIcon';
import { getRealComponentId } from '@/react/common/ConfigurationsTable/helpers';
import ConfirmModal from '@/react/common/ConfirmModal';
import SortByName from '@/react/common/SortByName';
import { ResetChanges } from '@/react/common/TableIconActions';
import TimeAndUser from '@/react/common/TimeAndUser';
import RoutesStore from '@/stores/RoutesStore';
import descriptionExcerpt from '@/utils/descriptionExcerpt';
import hasSelections from '@/utils/hasSelections';
import string from '@/utils/string';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen,
} from '@/utils/windowOpen';
import DevBranchDiffModal from './DevBranchDiffModal';

const getProductionModalTexts = (isPlural) => {
  const title = `Reset configuration${isPlural ? 's' : ''} to production version`;
  const text = `
      This action will replace your ${isPlural ? "branch's selected configurations" : 'branch configuration'} with the latest production version.
      Any changes made to ${isPlural ? 'these configurations' : 'this configuration'} will be permanently lost and cannot be undone.
     `;

  return { text, title };
};

class ConfigurationTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sort: 'asc',
      detail: Map(),
      showDiffModal: false,
      showResetModal: false,
      isDiffLoading: false,
      isResetting: false,
      isCollapsed: false,
    };
  }

  render() {
    if (this.props.components.isEmpty()) {
      return null;
    }

    const numberOfConfigurations = this.props.components.reduce(
      (counter, component) => counter + component.get('configurations').count(),
      0,
    );

    const numberOfSelectedConfigurations = filterSelectedUpdatedComponents(
      this.props.components,
      this.props.selectedConfigurationChanges,
    ).reduce((counter, component) => counter + component.get('configurations').count(), 0);

    const isDetailEmpty = this.state.detail.isEmpty();
    const productionModalTexts = getProductionModalTexts(
      isDetailEmpty && numberOfSelectedConfigurations !== 1,
    );

    return (
      <div className="box">
        <div
          onClick={this.toggleCollapse}
          className="flex-container collapse-above-table btn-collapse-area pb-0"
        >
          <span className="font-medium f-16 line-height-24">Changed Configurations</span>
          <CollapseButton
            entity="configurations"
            isCollapsed={this.state.isCollapsed}
            onToggle={this.toggleCollapse}
          />
        </div>
        {this.state.isCollapsed ? (
          <div className="collapsed-configurations clickable" onClick={this.toggleCollapse}>
            <span className="font-semibold">{numberOfConfigurations} </span>
            <span className="text-muted">
              {string.pluralize(numberOfConfigurations, 'Configuration')}
            </span>
          </div>
        ) : (
          this.renderTable(numberOfConfigurations, numberOfSelectedConfigurations)
        )}
        {this.renderDiffModal()}
        <ConfirmModal
          closeAfterResolve
          show={this.state.showResetModal}
          onHide={() => this.setState({ showResetModal: false, detail: Map() })}
          title={productionModalTexts.title}
          text={productionModalTexts.text}
          buttonLabel={this.state.isResetting ? 'Resetting...' : 'Reset changes'}
          buttonType="danger"
          icon="arrow-rotate-left"
          isLoading={this.state.isResetting}
          onConfirm={() => {
            this.setState({ isResetting: true });
            return InstalledActionsCreators.resetConfigurationsToProductionVersion(
              isDetailEmpty
                ? this.props.selectedConfigurationChanges
                    .entrySeq()
                    .flatMap(([componentId, configs]) =>
                      configs.map((configId) => [componentId, configId]),
                    )
                    .toArray()
                : [[this.state.detail.get('componentId'), this.state.detail.get('configId')]],
            )
              .then(() =>
                Promise.all([
                  InstalledActionsCreators.loadInstalledComponentsForce(),
                  InstalledActionsCreators.loadComponentsMetadataForce(),
                  InstalledActionsCreators.loadDeletedComponentsForce(),
                ]),
              )
              .then(() => {
                if (this.state.showDiffModal) {
                  this.setState({ showDiffModal: false, showResetModal: false });
                  return;
                }

                this.handleToggleAllConfigurations(false);
              })
              .finally(() => this.setState({ isResetting: false }));
          }}
        />
      </div>
    );
  }

  renderDiffModal() {
    const componentId = this.state.detail.get('componentId');
    const sharedCodes = this.props.sharedCodes
      .find((config) => config.getIn(['configuration', 'componentId']) === componentId, null, Map())
      .get('rows', List());

    return (
      <DevBranchDiffModal
        show={this.state.showDiffModal}
        onClose={() => this.setState({ showDiffModal: false })}
        onDiffLoaded={() => this.setState({ isDiffLoading: false })}
        updatedMetadata={this.props.updatedMetadata}
        detail={this.state.detail}
        admins={this.props.admins}
        component={this.props.allComponents.get(componentId, Map())}
        sharedCodes={sharedCodes}
        {...(!this.props.readOnly && {
          onResetChanges: () => this.setState({ showResetModal: true }),
        })}
      />
    );
  }

  renderTable(numberOfConfigurations, numberOfSelectedConfigurations) {
    const isSomeSelected = numberOfSelectedConfigurations > 0;
    const isAllSelected = numberOfConfigurations === numberOfSelectedConfigurations;

    return (
      <table className="table table-hover">
        <thead>
          <tr>
            <th>
              <div className="tw-flex-start tw-inline-flex">
                {!this.props.readOnly && (
                  <Checkbox
                    className="tw-pr-3"
                    tooltip="Toggle all changes"
                    checked={isAllSelected}
                    onChange={this.handleToggleAllConfigurations}
                    indeterminate={isSomeSelected && !isAllSelected}
                  />
                )}
                {!numberOfSelectedConfigurations ? (
                  <SortByName
                    sortBy={this.state.sort}
                    onClick={(sort) => this.setState({ sort })}
                  />
                ) : (
                  <>
                    <strong>
                      {`${numberOfSelectedConfigurations} ${string.pluralize(
                        numberOfSelectedConfigurations,
                        'configuration',
                      )} `}
                      selected
                    </strong>
                    {!this.props.readOnly && (
                      <ResetChanges onReset={() => this.setState({ showResetModal: true })} />
                    )}
                  </>
                )}
              </div>
            </th>
            <th className="w-250">Last Change</th>
            <th className="w-250 text-left">Status</th>
            <th className="w-100" />
          </tr>
        </thead>
        <tbody>
          {this.props.components
            .sort(this.handleSort)
            .map((component) => {
              return component
                .get('configurations')
                .sort(this.handleSort)
                .map((config) => {
                  const detail = Map({
                    name: config.get('name'),
                    componentId: component.get('id'),
                    configId: config.get('id'),
                  });
                  const linkParams = resolveRouterLinkParams(
                    component.get('id'),
                    config.get('id'),
                    null,
                    this.props.hasFlows,
                  );

                  const onClick = (e) => {
                    if (!linkParams || hasSelections()) {
                      return;
                    }

                    const to = config.get('isDeleted')
                      ? trashRouteNames.SETTINGS_TRASH
                      : linkParams.to;
                    const params = config.get('isDeleted') ? null : linkParams.params;

                    if (shouldUseNewWindow(e)) {
                      return windowOpen(RoutesStore.getRouter().createHref(to, params));
                    }

                    return RoutesStore.getRouter().transitionTo(to, params);
                  };

                  return (
                    <tr
                      key={config.get('id')}
                      tabIndex="0"
                      role="button"
                      onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
                      onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
                      onClick={onClick}
                      onKeyDown={(e) => {
                        if (e.key === keyCodes.ENTER) {
                          onClick();
                        }
                      }}
                      className="clickable hoverable-actions"
                    >
                      <td className={cn({ '!tw-pl-3': !this.props.readOnly })}>
                        <div className="flex-container flex-start">
                          {!this.props.readOnly && (
                            <Checkbox
                              className="tw-px-3 tw-py-2"
                              tooltip="Add/remove change from merge."
                              checked={this.props.selectedConfigurationChanges
                                .get(component.get('id'), List())
                                .includes(config.get('id'))}
                              onChange={(checked) => {
                                toggleConfigurationChange(
                                  component.get('id'),
                                  config.get('id'),
                                  checked,
                                );
                              }}
                            />
                          )}
                          <ComponentIcon
                            size="36"
                            className="icon-addon-right align-start"
                            component={component}
                          />
                          <div className="tw-flex tw-flex-col tw-items-start">
                            <ComponentConfigurationLink
                              className="link-inherit"
                              componentId={getRealComponentId(config, component)}
                              configId={config.get('id')}
                              hasFlows={this.props.hasFlows}
                            >
                              <Truncated className="component-name" text={config.get('name')} />
                            </ComponentConfigurationLink>
                            <span className="f-13 text-muted">
                              {descriptionExcerpt(config.get('description') || 'No description')}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <TimeAndUser
                          avatarPosition="left"
                          admin={this.props.admins.get(
                            config.getIn(['currentVersion', 'creatorToken', 'description']),
                          )}
                          time={config.getIn(['currentVersion', 'created'])}
                          fallbackName={config.getIn([
                            'currentVersion',
                            'creatorToken',
                            'description',
                          ])}
                        />
                      </td>
                      <td className="text-left">
                        {this.renderStatus(component.get('id'), config)}
                      </td>
                      <td className="td pl-0 pr-1 no-wrap">
                        {!this.props.readOnly && (
                          <Tooltip tooltip="Reset changes" placement="top">
                            <IconButton
                              className="tw-inline-flex"
                              variant="inline"
                              onClick={(e) => {
                                e.stopPropagation();
                                this.setState({ showResetModal: true, detail });
                              }}
                              icon="arrow-rotate-left"
                            />
                          </Tooltip>
                        )}
                        <Tooltip tooltip="Compare with production" placement="top">
                          <IconButton
                            className="tw-inline-flex"
                            variant="inline"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.setState({ showDiffModal: true, isDiffLoading: true, detail });
                            }}
                            icon="right-left"
                            isLoading={this.state.isDiffLoading}
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })
                .toArray();
            })
            .toArray()}
        </tbody>
      </table>
    );
  }

  renderStatus(componentId, config) {
    const productionConfig = this.props.productionComponents.getIn([
      componentId,
      'configurations',
      config.get('id'),
    ]);

    if (config.get('isDeleted')) {
      return <span className="text-warning">Deleted configuration</span>;
    }

    if (
      deletedInProduction(componentId, config.get('id'), this.props.productionDeletedComponents)
    ) {
      return <span className="text-danger">Deleted in production</span>;
    }

    if (!productionConfig) {
      return <span className="text-success">New configuration</span>;
    }

    if (updatedInProduction(config, productionConfig)) {
      return <span className="text-danger">Conflicting configuration</span>;
    }

    return <span className="text-muted">No changes in production</span>;
  }

  handleSort = (entityA, entityB) => {
    const sort = this.state.sort === 'asc' ? 1 : -1;

    return entityA.get('name').localeCompare(entityB.get('name')) * sort;
  };

  toggleCollapse = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  };

  handleToggleAllConfigurations = (checked) => {
    toggleAllConfigurations(
      checked
        ? this.props.components
            .map((component) => component.get('configurations').keySeq().toList())
            .filter((component) => !component.isEmpty())
        : Map(),
    );
  };
}

ConfigurationTable.propTypes = {
  admins: PropTypes.instanceOf(Map).isRequired,
  components: PropTypes.instanceOf(Map).isRequired,
  updatedMetadata: PropTypes.instanceOf(Map).isRequired,
  productionComponents: PropTypes.instanceOf(Map).isRequired,
  productionDeletedComponents: PropTypes.instanceOf(Map).isRequired,
  allComponents: PropTypes.instanceOf(Map).isRequired,
  selectedConfigurationChanges: PropTypes.instanceOf(Map).isRequired,
  sharedCodes: PropTypes.instanceOf(Map).isRequired,
  readOnly: PropTypes.bool.isRequired,
  hasFlows: PropTypes.bool.isRequired,
};

export default ConfigurationTable;
