import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import {
  Alert,
  Button,
  ButtonGroup,
  ClipboardInline,
  Link,
  Tabs,
  TabsContent,
} from '@keboola/design';

import {
  APAC_EX_GMAIL_ATTACHMENTS,
  KDS_TEAM_EX_ZOHO,
  KEBOOLA_EX_GMAIL,
  KEBOOLA_EX_GOOGLE_ANALYTICS_V_4,
  SMETRIC_EX_SIMPRO_API,
  SMETRIC_EX_VEND_API,
} from '@/constants/componentIds';
import { canCreateExternalAuthorization } from '@/modules/admin/privileges';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { isFacebookService, isGoogleService } from '@/modules/oauth-v2/helpers';
import * as oauthUtils from '@/modules/oauth-v2/OauthUtils';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';
import ApplicationStore from '@/stores/ApplicationStore';
import AuthorizationForm from './AuthorizationForm';
import CustomAuthorizationFields from './CustomAuthorizationFields';
import DirectTokenInsertFields from './DirectTokenInsertFields';
import FacebookAuthorizeButton from './FacebookAuthorizeButton';
import GoogleAuthorizeButton from './GoogleAuthorizeButton';
import InstantAuthorizationFields from './InstantAuthorizationFields';

const COMPONENT_LIMITS_INFO = [KEBOOLA_EX_GOOGLE_ANALYTICS_V_4];
const GOOGLE_LIMITED_USE_REQUIREMENT_COMPONENTS = [KEBOOLA_EX_GMAIL, APAC_EX_GMAIL_ATTACHMENTS];
const FORCE_HIDE_EXTERNAL_AUTHORIZATION = [
  SMETRIC_EX_SIMPRO_API,
  SMETRIC_EX_VEND_API,
  KDS_TEAM_EX_ZOHO,
];

/** @type {any} */
const Authorization = createReactClass({
  propTypes: {
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string,
    wrapperComponentId: PropTypes.string,
    allowExternalAuthorization: PropTypes.bool,
    idPrefix: PropTypes.string,
    branchId: PropTypes.string,
    initialAuthorizedFor: PropTypes.string,
    fallbackAuthorizedFor: PropTypes.string,
    showModal: PropTypes.bool,
    onModalHideFn: PropTypes.func,
    onCompleteFn: PropTypes.func,
    skipSave: PropTypes.bool,
  },

  getInitialState() {
    return {
      direct: { authorizedFor: this.props.initialAuthorizedFor },
      custom: { authorizedFor: this.props.initialAuthorizedFor },
      instant: { authorizedFor: this.props.initialAuthorizedFor },
      externalLink: '',
      generatingLink: false,
      activeTab: 'instant',
    };
  },

  render() {
    if (!this.props.onModalHideFn) {
      return (
        <AuthorizationForm
          componentId={this.props.componentId}
          wrapperComponentId={this.props.wrapperComponentId}
          configId={this.props.configId}
          idPrefix={this.props.idPrefix}
          branchId={this.getBranchId()}
          authorizedFor={this.getFormAuthorizedFor()}
          onComplete={this.props.onCompleteFn}
          skipSave={this.props.skipSave}
        >
          {this.renderInstantAuthorizeButton()}
        </AuthorizationForm>
      );
    }

    return (
      <Modal
        show={this.props.showModal}
        onEnter={() => this.setState({ ...this.getInitialState() })}
        onHide={this.props.onModalHideFn}
      >
        <Modal.Header closeButton className="tw-border-0 !tw-pb-0">
          <Modal.Title>Authorize</Modal.Title>
          <ModalIcon icon="user" color="green" bold />
        </Modal.Header>

        <AuthorizationForm
          componentId={this.props.componentId}
          wrapperComponentId={this.props.wrapperComponentId}
          configId={this.props.configId}
          idPrefix={this.props.idPrefix}
          branchId={this.getBranchId()}
          authorizedFor={this.getFormAuthorizedFor()}
          onComplete={(credentials) => {
            this.props.onCompleteFn?.(credentials);
            this.props.onModalHideFn();
          }}
          skipSave={this.props.skipSave}
        >
          <Modal.Body>
            {GOOGLE_LIMITED_USE_REQUIREMENT_COMPONENTS.includes(this.props.componentId) && (
              <p>
                App&apos;s use of information received, and App&apos;s transfer of information to
                any other app, from Google APIs will adhere to Google&apos;s Limited Use
                Requirements.
              </p>
            )}
            <Tabs
              inModal
              value={this.state.activeTab}
              onValueChange={this.goToTab}
              triggers={[
                { value: 'instant', title: 'Instant authorization' },
                ...(canCreateExternalAuthorization(ApplicationStore.getSapiToken()) &&
                this.props.allowExternalAuthorization &&
                this.props.configId &&
                !FORCE_HIDE_EXTERNAL_AUTHORIZATION.includes(this.props.componentId)
                  ? [
                      {
                        value: 'external',
                        title: 'External authorization',
                      },
                    ]
                  : []),
                ...(isFacebookService(this.props.componentId) && this.props.configId
                  ? [
                      {
                        value: 'direct',
                        title: 'Direct token insert',
                      },
                    ]
                  : []),
                ...(isGoogleService(this.props.componentId)
                  ? [
                      {
                        value: 'custom',
                        title: 'Custom authorization',
                      },
                    ]
                  : []),
              ]}
            >
              <TabsContent value="instant">{this.renderInstant()}</TabsContent>
              <TabsContent value="external">{this.renderExternal()}</TabsContent>
              <TabsContent value="direct">{this.renderDirectFacebookTokenInsert()}</TabsContent>
              <TabsContent value="custom">{this.renderCustomAuth()}</TabsContent>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>{this.renderFooterButtons()}</Modal.Footer>
        </AuthorizationForm>
      </Modal>
    );
  },

  getFallbackAuthorizedFor() {
    return this.props.fallbackAuthorizedFor || ApplicationStore.getCurrentAdmin()?.get('email', '');
  },

  getLimitsInfo() {
    if (COMPONENT_LIMITS_INFO.includes(this.props.componentId)) {
      return 'The number of requests is limited to thousands API calls per day. Use Custom Authorization with your own credentials to obtain full access to the API.';
    }

    return null;
  },

  renderFooterButtons() {
    if (this.state.activeTab === 'instant') return this.renderInstantButtons();
    if (this.state.activeTab === 'external') return this.renderExternalButtons();
    if (this.state.activeTab === 'direct') return this.renderDirectFacebookButtons();
    if (this.state.activeTab === 'custom') return this.renderCustomButtons();
  },

  renderExternal() {
    return (
      <>
        {!!this.getLimitsInfo() && (
          <Alert variant="warning" className="tw-mb-5">
            {this.getLimitsInfo()}
          </Alert>
        )}
        <p>
          To authorize an account from a non-Keboola user, generate a link to the external
          authorization app and send it to the user for whom you want to have the authorized
          account. The generated link is valid for <strong>48</strong> hours and will not be stored
          anywhere.
        </p>
        {this.state.externalLink && (
          <>
            <pre>
              <Link href={this.state.externalLink}>{this.state.externalLink}</Link>
            </pre>
            <ClipboardInline
              text={this.state.externalLink}
              label="Copy external link to clipboard"
              className="tw-ml-2.5"
            />
          </>
        )}
      </>
    );
  },

  onGetExternalLink() {
    this.setState({ generatingLink: true });
    oauthUtils.generateLink(this.props.componentId, this.props.configId).then((link) => {
      this.setState({ generatingLink: false, externalLink: link });
    });
  },

  renderCancelButton(disabled = false) {
    return (
      <Button variant="outline" disabled={disabled} onClick={this.props.onModalHideFn}>
        Cancel
      </Button>
    );
  },

  renderExternalButtons() {
    if (!this.props.configId) return null;

    return (
      <ButtonGroup space="medium">
        {this.state.generatingLink && <Loader />}
        {this.renderCancelButton()}
        <Button disabled={this.state.generatingLink} onClick={this.onGetExternalLink}>
          {this.state.externalLink ? 'Regenerate Link' : 'Generate Link'}
        </Button>
      </ButtonGroup>
    );
  },

  onSaveDirectToken() {
    const { direct } = this.state;
    const data = {
      token: direct.token,
    };
    this.setDirectState('saving', true);
    oauthUtils
      .saveDirectData(
        this.props.componentId,
        this.props.configId,
        direct.authorizedFor || this.getFallbackAuthorizedFor(),
        this.getBranchId(),
        data,
      )
      .then(this.props.onModalHideFn);
  },

  renderDirectFacebookButtons() {
    if (!this.props.configId) return null;

    const { direct } = this.state;
    return (
      <ButtonGroup space="medium">
        {direct.saving && <Loader />}
        {this.renderCancelButton(direct.saving)}
        <Button
          onClick={this.onSaveDirectToken}
          disabled={!this.state.direct.token?.trim() || direct.saving}
        >
          Save
        </Button>
      </ButtonGroup>
    );
  },

  setDirectState(prop, value) {
    const { direct } = this.state;
    direct[prop] = value;
    this.setState({ direct: direct });
  },

  renderDirectFacebookTokenInsert() {
    const { direct } = this.state;
    return (
      <DirectTokenInsertFields
        token={direct.token}
        authorizedFor={direct.authorizedFor}
        onChangeProperty={this.setDirectState}
        componentId={this.props.componentId}
      />
    );
  },

  renderInstant() {
    return (
      <InstantAuthorizationFields
        disabled={this.state.activeTab !== 'instant'}
        values={this.state.instant}
        componentId={this.props.componentId}
        onChangeFn={this.setInstantState}
        infoText={this.getLimitsInfo()}
      />
    );
  },

  renderInstantButtons() {
    return (
      <ButtonGroup space="medium">
        {this.renderCancelButton()}
        {this.renderInstantAuthorizeButton()}
      </ButtonGroup>
    );
  },

  renderInstantAuthorizeButton() {
    if (isGoogleService(this.props.componentId)) {
      return <GoogleAuthorizeButton isDisabled={this.isInstantAuthorizeButtonDisabled()} />;
    }

    if (isFacebookService(this.props.componentId)) {
      return <FacebookAuthorizeButton isDisabled={this.isInstantAuthorizeButtonDisabled()} />;
    }

    return (
      <Button type="submit" disabled={this.isInstantAuthorizeButtonDisabled()}>
        Authorize
      </Button>
    );
  },

  isInstantAuthorizeButtonDisabled() {
    if (this.props.componentId === SMETRIC_EX_SIMPRO_API) {
      return (
        !this.state.instant.authUrl ||
        !this.state.instant.tokenUrl ||
        !this.state.instant.appKey ||
        !this.state.instant.appSecret
      );
    }

    if (this.props.componentId === SMETRIC_EX_VEND_API) {
      return !this.state.instant.tokenUrl;
    }

    if (this.props.componentId === KDS_TEAM_EX_ZOHO) {
      return !this.state.instant.authUrl;
    }

    return false;
  },

  setInstantState(prop, value) {
    const { instant } = this.state;
    instant[prop] = value;
    this.setState({ instant: instant });
  },

  renderCustomAuth() {
    const { custom } = this.state;

    return (
      <CustomAuthorizationFields
        values={custom}
        componentId={this.props.componentId}
        onChangeFn={this.setCustomState}
        disabled={this.state.activeTab !== 'custom'}
      />
    );
  },

  renderCustomButtons() {
    return (
      <ButtonGroup space="medium">
        {this.renderCancelButton()}
        <Button type="submit" disabled={!this.state.custom.appKey || !this.state.custom.appSecret}>
          Authorize
        </Button>
      </ButtonGroup>
    );
  },

  setCustomState(prop, value) {
    const { custom } = this.state;
    custom[prop] = value;
    this.setState({ custom: custom });
  },

  goToTab(tab) {
    this.setState({
      activeTab: tab,
    });
  },

  getBranchId() {
    if (typeof this.props.branchId !== 'undefined') return `${this.props.branchId}`;
    if (!ApplicationStore.hasProtectedDefaultBranch()) return null;
    return `${DevBranchesStore.getCurrentId()}`;
  },

  getFormAuthorizedFor() {
    return this.state[this.state.activeTab]?.authorizedFor || this.getFallbackAuthorizedFor();
  },
});

export default Authorization;
