import { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { List, Map } from 'immutable';

import { Alert } from '@keboola/design';

import keyCodes from '@/constants/keyCodes';
import { canManageSharedBucket } from '@/modules/admin/privileges';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import UsedByProjectsList from '@/modules/data-catalog/react/UsedByProjectsList';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';

const INITIAL_STATE = {
  isLoading: false,
};

export class DisableSharingModal extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    if (this.cancellablePromise) {
      this.cancellablePromise.cancel();
    }
  }

  render() {
    if (!canManageSharedBucket(this.props.sapiToken)) {
      return null;
    }

    return (
      <span
        className="tw-hidden"
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.key === keyCodes.ENTER && e.stopPropagation()}
      >
        <Modal show={this.props.show} onHide={this.closeModal}>
          <form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <ModalIcon icon="ban" color="red" bold />
              <Modal.Title>Bucket Sharing</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.error && (
                <Alert variant="error" className="tw-mb-5">
                  {this.state.error}
                </Alert>
              )}
              {this.getLinkedProjects().count() !== 0 ? (
                <>
                  <p>
                    You are about to unlink the bucket from the projects bellow and disable sharing
                    of the bucket <strong>{this.props.bucket.get('displayName')}</strong>. Reading
                    from the bucket will no longer work in these projects.
                  </p>
                  <div className="well">
                    <p className="text-muted small">
                      The bucket will be unlinked from these projects:
                    </p>
                    <UsedByProjectsList
                      urlTemplates={this.props.urlTemplates}
                      links={this.getLinkedProjects()}
                    />
                  </div>
                </>
              ) : (
                <p>
                  You are about to disable sharing of the bucket{' '}
                  <strong>{this.props.bucket.get('displayName')}</strong>
                </p>
              )}
            </Modal.Body>
            <Modal.Footer>
              <ConfirmButtons
                block
                saveButtonType="submit"
                saveStyle="danger"
                saveLabel={this.state.isLoading ? 'Disabling...' : 'Disable'}
                isSaving={this.state.isLoading}
              />
            </Modal.Footer>
          </form>
        </Modal>
      </span>
    );
  }

  closeModal() {
    this.setState(INITIAL_STATE, () => {
      this.props.closeModalFn();
    });
  }

  getLinkedProjects() {
    return this.props.bucket.get('linkedBy', List());
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ isLoading: true });

    this.cancellablePromise = StorageActionCreators.unshareBucket(this.props.bucket)
      .then(this.closeModal)
      .then(this.props.onUnshare)
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        });
      });
  }
}

DisableSharingModal.propTypes = {
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  bucket: PropTypes.instanceOf(Map).isRequired,
  urlTemplates: PropTypes.object.isRequired,
  closeModalFn: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onUnshare: PropTypes.func,
};

DisableSharingModal.defaultProps = {
  onUnshare: () => null,
};
