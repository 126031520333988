import { Icon } from '@keboola/design';

import { getFolderFromMetadata } from '@/modules/components/helpers';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { RouterLink } from '@/react/common';

const folderBreadcrumbHandler = (
  breadcrumbs: any[],
  componentId: string,
  configId: string,
  destinationRoute: string,
) => {
  const folderName = getFolderFromMetadata(
    InstalledComponentsStore.getAllMetadata().getIn([componentId, configId]),
  );

  if (!folderName) {
    return breadcrumbs;
  }

  return (
    <>
      {breadcrumbs.length > 0 && (
        <>
          {breadcrumbs}
          <Icon icon={['far', 'angle-right']} />
        </>
      )}
      <RouterLink
        className="dark muted"
        to={destinationRoute}
        query={{ q: folderName }}
        disabled={!breadcrumbs.length}
      >
        <Icon icon="folder" style={{ fontSize: '0.9em' }} className="text-muted icon-addon-right" />
        {folderName}
      </RouterLink>
    </>
  );
};

export default folderBreadcrumbHandler;
