import type { MouseEvent } from 'react';
import type Promise from 'bluebird';
import { fromJS, List, Map, Set } from 'immutable';
import _ from 'underscore';

import { KEBOOLA_EX_SAMPLE_DATA } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { DISABLE_AUTOSAVING_IN_FLOWS } from '@/constants/features';
import dayjs from '@/date';
import { defaultTransformationBackendSize as defaultBackendSize } from '@/modules/components/Constants';
import {
  getNewComponentTypeLabel,
  hasDynamicBackendSizeEnabled,
} from '@/modules/components/helpers';
import { prepareVariables } from '@/modules/components/react/components/generic/variables/helpers';
import { BEHAVIOR_TYPES, JOB_RUNNING_STATUSES } from '@/modules/queue/constants';
import { getChildJobs } from '@/modules/queue/helpers';
import { BRANCH_TOOLTIP_MESSAGE, SOX_BRANCH_TOOLTIP_MESSAGE } from '@/modules/scheduler/constants';
import { READ_ONLY_TOOLTIP_MESSAGE } from '@/react/common/constants';
import ApplicationStore from '@/stores/ApplicationStore';
import { getComponentIconUrl } from '@/utils/componentIconFinder';
import generateId from '@/utils/generateId';
import { shouldUseNewWindow } from '@/utils/windowOpen';
import { redirectToTask } from './actions';

class DragZone {
  y: number;
  top: number;
  bottom: number;

  constructor(cy: number, hh: number) {
    /** center Y */
    this.y = cy;
    /** top edge */
    this.top = cy - hh;
    /** bottom edge */
    this.bottom = cy + hh;
  }

  /** Test if this drag zone match `py` */
  match(py: number, isFirst: boolean, isLast: boolean) {
    if (isFirst) return py <= this.bottom; // any point above bottom edge of first drag zone match
    if (isLast) return py >= this.top; // any point under top edge of first drag zone match
    return this.top <= py && py <= this.bottom;
  }

  /** Construct a drag zone around `el` */
  static simple(el: Element) {
    const bb = el.getBoundingClientRect();
    return new DragZone(bb.y + bb.height / 2, bb.height / 2);
  }

  /** Construct a drag zone positioned before `el`, with size matching `el` */
  static before(el: Element) {
    const bb = el.getBoundingClientRect();
    return new DragZone(bb.y - bb.height * 0.5, bb.height / 2);
  }

  /**
   * Construct a drag zone taking up the space between `a` and `b`,
   * with width being the minimum of the two elements' widths.
   */
  static between(a: Element, b: Element) {
    const a_b = a.getBoundingClientRect();
    const b_b = b.getBoundingClientRect();
    return new DragZone(
      (a_b.bottom + b_b.top) / 2, // point on Y axis between bottom edge of `A` and top edge of `B`
      (b_b.top - a_b.bottom) / 2, // half of distance between bottom edge of `A` and top edge of `B`
    );
  }
}

const phaseKey = (phase: Map<string, any>) => `${phase.get('id')}-${phase.get('name')}`;

/**
 * Use more user friendly phases names if user do not provide own name
 */
const updatePhasesNames = (phases: List<any>) => {
  return phases.map((phase, index) => {
    if (/^(New Step|Step \d+)$/i.test(phase.get('name', ''))) {
      return phase.set('name', `Step ${index! + 1}`);
    }

    return phase;
  });
};

const sortPhases = (phases: List<any>) => {
  const dependsOnMapping = phases
    .toMap()
    .mapKeys((_, phase) => phase.get('id'))
    .map((phase) => phase.getIn(['dependsOn', 0]));

  return phases.sortBy((phase: Map<string, any>) => {
    let dependencies = 0;
    let dependsOn = dependsOnMapping.get(phase.get('id'));

    while (typeof dependsOn !== 'undefined') {
      dependencies += 1;
      dependsOn = dependsOnMapping.get(dependsOn);
    }

    return dependencies;
  }) as List<any>;
};

/**
 * Merges all tasks into their phases + calls `prepareTask` on each task
 */
const prepareVisualizationPhases = (
  phases: List<any>,
  tasks: Map<string, any>,
  allComponents: Map<string, any>,
  allInstalledComponents: Map<string, any>,
  deletedComponents: Map<string, any>,
) => {
  return phases.map((phase: Map<string, any>) => {
    return Map({
      id: phase.get('id'),
      name: phase.get('name'),
      description: phase.get('description', ''),
      behaviorOnError: phase.getIn(['behavior', 'onError'], BEHAVIOR_TYPES.STOP),
      key: phaseKey(phase),
      isFake: phase.get('isFake', false),
      tasks: tasks
        .filter((task: Map<string, any>) => task.get('phase') === phase.get('id'))
        .map((task) => {
          return prepareTask(task, allComponents, allInstalledComponents, deletedComponents);
        }),
    });
  }) as List<any>;
};

/**
 * Merges the config name, component type, and iconUrl of the task into itself
 */
const prepareTask = (
  task: Map<string, any>,
  allComponents: Map<string, any>,
  allInstalledComponents: Map<string, any>,
  deletedComponents: Map<string, any>,
) => {
  const componentId = task.getIn(['task', 'componentId']);
  const configId = task.getIn(['task', 'configId']);
  const config = allInstalledComponents.getIn([componentId, 'configurations', configId], Map());
  const component = allComponents.get(
    componentId === KEBOOLA_EX_SAMPLE_DATA && !config.isEmpty()
      ? config.getIn(['configuration', 'parameters', 'componentId'])
      : componentId,
    Map(),
  );

  const componentName = component.get('name');
  const isDeleted = !!configId && config.isEmpty();
  const inTrash = deletedComponents.hasIn([componentId, 'configurations', configId, 'name']);
  const specificRows = task.getIn(['task', 'configRowIds'], List());
  const availableRows = config
    .get('rows', List())
    .map((row: Map<string, any>) => ({ value: row.get('id'), label: row.get('name') }));

  let out = Map({
    id: task.get('id'),
    name: isDeleted
      ? inTrash
        ? `Deleted (${deletedComponents.getIn([componentId, 'configurations', configId, 'name'])})`
        : 'Deleted Configuration'
      : config.get('name', configId),
    specificRows,
    availableRows,
    componentId: component.get('id'),
    type: Object.values(componentTypes).includes(component.get('type'))
      ? getNewComponentTypeLabel(component.get('type'))
      : '',
    configId,
    component: componentName ?? 'Invalid component',
    iconUrl: getComponentIconUrl(component),
    enabled: task.get('enabled', true),
    continueOnFailure: task.get('continueOnFailure', false),
    invalid: !componentName,
    hasDeletedConfiguration: isDeleted,
    hasConfigurationInTrash: inTrash,
  });

  if (
    task.hasIn(['task', 'backend', 'type']) ||
    config.hasIn(['configuration', 'runtime', 'backend', 'type'])
  ) {
    out = out.set(
      'backend',
      task.getIn(
        ['task', 'backend', 'type'],
        config.getIn(['configuration', 'runtime', 'backend', 'type']),
      ),
    );
  }

  if (task.hasIn(['task', 'variableValuesId'])) {
    out = out.set('variableValuesId', task.getIn(['task', 'variableValuesId']));
  }

  if (task.hasIn(['task', 'variableValuesData'])) {
    out = out.set('variableValuesData', task.getIn(['task', 'variableValuesData']));
  }

  return out;
};

const insertEmptyPhase = (phases: List<any>, insertAfter?: number) => {
  const atLast = _.isUndefined(insertAfter) || insertAfter === phases.count();
  const index = atLast ? phases.count() : insertAfter;
  const id = generateId(phases.map((phase) => phase.get('id')).toArray());

  const data = {
    id,
    name: 'New Step',
    dependsOn: [phases.getIn([atLast ? -1 : index, 'id'])],
  };

  return phases.splice(index, 0, fromJS(data)) as List<any>;
};

const withLastFakePhase = (phases: List<any>) => {
  return insertEmptyPhase(phases, phases.count()).setIn([-1, 'isFake'], true);
};

const resolveComponentId = (
  allConfigurations: Map<string, any>,
  task: Map<string, any>,
  configId?: string,
) => {
  return allConfigurations.getIn(
    [task.get('componentId'), 'configurations', configId ?? task.get('configId'), 'isSample'],
    false,
  )
    ? KEBOOLA_EX_SAMPLE_DATA
    : task.get('componentId');
};

const getBackendSize = (
  component: Map<string, any>,
  task: Map<string, any>,
  hasSnowflakeDynamicBackendSize: boolean,
  hasJobsDynamicBackendSize: boolean,
): 'small' | 'medium' | 'large' | null => {
  return hasDynamicBackendSizeEnabled(
    component,
    hasSnowflakeDynamicBackendSize,
    hasJobsDynamicBackendSize,
  )
    ? task.get('backend', defaultBackendSize)
    : null;
};

/** Ensures that `tasks` contains at least some tasks are configured and enabled */
const shouldAllowRunFlow = (tasks: Map<string, any>) => {
  return tasks.some(
    (task: Map<string, any>) => !!task.getIn(['task', 'configId']) && task.get('enabled', true),
  );
};

/**
 * Gets all currently running phases + their running tasks.
 */
const getRunningFlowStatus = (allJobs: Map<string, any>, flowJob: Map<string, any>) => {
  // phaseInfos format:
  // {
  //   jobId: string
  //   isRunning: boolean
  //   [phase: number]: {
  //     jobId: string
  //     status: Status
  //     [task: number]: {
  //       jobId: string
  //       status: Status
  //     }
  //   }
  // }
  let phaseInfos = Map({
    jobId: flowJob.get('id'),
    isRunning: JOB_RUNNING_STATUSES.includes(flowJob.get('status')),
  });
  getChildJobs(allJobs, flowJob)
    .filter((phaseJob: Map<string, any>) => phaseJob.hasIn(['configData', 'phaseId']))
    .forEach((phaseJob: Map<string, any>) => {
      // task jobs are created in the same order as the tasks themselves are in the phase,
      // so sorting by `runId` is the same as sorting by task id
      // this is to make up for the fact that we don't have access to the task id in the child jobs.
      const taskJobs = getChildJobs(allJobs, phaseJob)
        .toIndexedSeq()
        .sortBy((v: Map<string, any>) => v.get('runId'));
      // because two or more tasks in a phase may have the same configId, we use a set to
      // keep track of which specific task _jobs_ we have already visited, to avoid having
      // two tasks with the same configId sharing the same status
      let usedTaskJobs = Set();

      let phaseInfo = Map({ jobId: phaseJob.get('id'), status: phaseJob.get('status') });
      phaseJob.getIn(['configData', 'tasks'], List()).forEach((task: Map<string, any>) => {
        const matchedJob = taskJobs.find(
          (taskJob: Map<string, any>) =>
            !usedTaskJobs.has(taskJob.get('runId')) &&
            taskJob.get('config') === task.getIn(['task', 'configId']),
        );
        if (matchedJob) {
          usedTaskJobs = usedTaskJobs.add(matchedJob.get('runId'));
          phaseInfo = phaseInfo.set(
            task.get('id'),
            Map({ jobId: matchedJob.get('id'), status: matchedJob.get('status') }),
          );
        }
      });
      phaseInfos = phaseInfos.set(phaseJob.getIn(['configData', 'phaseId']), phaseInfo);
    });

  return phaseInfos;
};

/** Returns true if `job` was run with the current version of `config` */
const jobVersionMatch = (config: Map<string, any>, job: Map<string, any>) => {
  const jobStartTime = dayjs(job.getIn(['startTime']));
  const currentVersionCreatedTime = dayjs(config.getIn(['currentVersion', 'created']));
  return currentVersionCreatedTime.isBefore(jobStartTime);
};

const filterDisabledTasks = (phases: List<any>) => {
  return phases.map((phase: Map<string, any>) => {
    return phase.update('tasks', List(), (tasks) => {
      return tasks.filter((task: Map<string, any>) => task.get('enabled'));
    });
  }) as List<any>;
};

const prepareSelectedTasks = (
  configData: Map<string, any>,
  selected: Record<number, boolean>,
  allConfigs = Map(),
  variables = Map(),
) => {
  const setVariablesOverride = (task: Map<string, any>) => {
    if (!variables.hasIn([task.get('phase'), task.get('id')])) {
      return task;
    }

    return task.setIn(
      ['task', 'variableValuesData', 'values'],
      prepareVariables(
        allConfigs,
        task.getIn(['task', 'componentId']),
        task.getIn(['task', 'configId']),
      ).map((value: Map<string, any>) => ({
        name: value.get('name'),
        value: variables.getIn(
          [task.get('phase'), task.get('id'), value.get('name')],
          value.get('value'),
        ),
      })),
    );
  };

  const allPhases = configData.get('phases');
  const firstPhase = allPhases.find((phase: Map<string, any>) =>
    phase.get('dependsOn', List()).isEmpty(),
  );
  const tasks = configData
    .get('tasks')
    .filter((task: Map<string, any>) => selected[task.get('id')])
    .map((task: Map<string, any>) => setVariablesOverride(task.set('enabled', true)));

  let dependencyMap = List([firstPhase.get('id')]);

  const buildDependencyMap = (phaseId: number) => {
    const nextPhase = allPhases.find(
      (phase: Map<string, any>) => phase.getIn(['dependsOn', 0]) === phaseId,
    );
    if (nextPhase) {
      dependencyMap = dependencyMap.push(nextPhase.get('id'));
      buildDependencyMap(nextPhase.get('id'));
    }
  };
  buildDependencyMap(firstPhase.get('id'));

  dependencyMap = dependencyMap.filter((phaseId) => {
    return tasks.some((task: Map<string, any>) => task.get('phase') === phaseId);
  }) as List<number>;

  const phases = allPhases
    .filter((phase: Map<string, any>) => dependencyMap.includes(phase.get('id')))
    .map((phase: Map<string, any>) => {
      const dependsOnIndex = dependencyMap.findIndex((phaseId) => phaseId === phase.get('id'));

      if (dependsOnIndex === 0) {
        return phase.set('dependsOn', List());
      }

      return phase.setIn(['dependsOn', 0], dependencyMap.get(dependsOnIndex - 1));
    });

  return { tasks: tasks.toJS(), phases: phases.toJS() };
};

const getScheduleTooltipMessage = (
  hasProtectedDefaultBranch: boolean,
  isDevModeActive: boolean,
  isButtonDisabled: boolean,
) => {
  if (hasProtectedDefaultBranch) {
    return isButtonDisabled ? READ_ONLY_TOOLTIP_MESSAGE : SOX_BRANCH_TOOLTIP_MESSAGE;
  }

  if (isDevModeActive) {
    return BRANCH_TOOLTIP_MESSAGE;
  }

  return isButtonDisabled ? READ_ONLY_TOOLTIP_MESSAGE : '';
};

const selectConfigWithRedirect = (
  event: MouseEvent | null,
  componentId: string,
  configId: string,
  taskId: string,
  onSelectConfig: (
    taskId: string,
    componentId: string,
    configId: string,
    options: { autosave: boolean },
  ) => Promise<void>,
  flowConfigId: string,
  automationId?: string,
) => {
  const autosave =
    !shouldUseNewWindow(event) &&
    !ApplicationStore.hasCurrentAdminFeature(DISABLE_AUTOSAVING_IN_FLOWS);

  return onSelectConfig(taskId, componentId, configId, { autosave }).then(() =>
    redirectToTask({
      componentId,
      configurationId: configId,
      flowId: flowConfigId,
      taskId,
      automationId,
      event,
    }),
  );
};

export {
  DragZone,
  updatePhasesNames,
  sortPhases,
  prepareVisualizationPhases,
  prepareTask,
  insertEmptyPhase,
  withLastFakePhase,
  resolveComponentId,
  getBackendSize,
  shouldAllowRunFlow,
  getRunningFlowStatus,
  jobVersionMatch,
  filterDisabledTasks,
  prepareSelectedTasks,
  getScheduleTooltipMessage,
  selectConfigWithRedirect,
};
