import { SHARING_TYPE_LABELS } from '@/modules/data-catalog/constants';
import string from '@/utils/string';

type Project = { id: number; name: string };
type User = { id: number; name: string };
type Props = { projects?: Project[]; users?: User[]; sharing: string };

export const SharedLabel = ({ projects, users, sharing }: Props) => {
  const hasProjects = projects && projects.length > 0;
  const hasUsers = users && users.length > 0;

  if (hasProjects) {
    if (projects.length === 1) return projects[0]?.name || 'Project';
    return <>{`${projects.length} ${string.pluralize(projects.length, 'Project')}`}</>;
  }
  if (hasUsers) {
    if (users.length === 1) return users[0]?.name || 'User';
    return <>{`${users.length} ${string.pluralize(users.length, 'User')}`}</>;
  }

  if (!SHARING_TYPE_LABELS[sharing]) return null;
  return <div>{SHARING_TYPE_LABELS[sharing]}</div>;
};
