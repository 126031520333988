import { Icon } from '@keboola/design';

import { routeNames as billingRouteNames } from '@/modules/billing/constants';
import MarketplacePortalLink from '@/modules/billing/react/MarketplacePortalLink';
import BillingStore from '@/modules/billing/store';
import { RouterLink as Link } from '@/react/common';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';

const TopUpBar = () => {
  const state = useStores(
    () => {
      const hasPayAsYouGo = ApplicationStore.hasPayAsYouGo();
      const remainingCredits = BillingStore.getRemainingCredits();

      return {
        isVisible: hasPayAsYouGo && typeof remainingCredits === 'number' && remainingCredits <= 0,
        isMarketplaceProject: BillingStore.isMarketplaceProject(),
        marketplaceSubscriptionLink: BillingStore.getMarketplaceSubscriptionLink(),
      };
    },
    [],
    [ApplicationStore, BillingStore],
  );

  if (state.isVisible) {
    return (
      <div className="tw-flex tw-items-center tw-justify-center tw-gap-8 tw-bg-error-500 tw-p-3 tw-text-center tw-text-white">
        You just used all your minutes. To continue working on your project, you should top up.
        {state.isMarketplaceProject ? (
          <MarketplacePortalLink
            href={state.marketplaceSubscriptionLink}
            className="btn-default btn-sm tw-w-auto !tw-border-none"
          />
        ) : (
          <Link
            to={billingRouteNames.BILLING_MINUTES}
            className="btn btn-default btn-sm !tw-border-none"
          >
            <Icon icon="plus" className="icon-addon-right" />
            Top up
          </Link>
        )}
      </div>
    );
  }

  return null;
};

export default TopUpBar;
