import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import * as componentFlags from '@/constants/componentFlags';
import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import {
  getAllowedTransformations,
  getDestinationTypeFromStagingStorage,
  getSourceTypeFromStagingStorage,
  hasWriterSimpleTableInput,
} from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ConfigurationSchemaNotValidBox from '@/modules/components/react/components/ConfigurationSchemaNotValidBox';
import FileInputMapping from '@/modules/components/react/components/generic/FileInputMapping';
import FileOutputMapping from '@/modules/components/react/components/generic/FileOutputMapping';
import FileTagsSelector from '@/modules/components/react/components/generic/FileTagsSelector';
import { supportsFileTags } from '@/modules/components/react/components/generic/helpers';
import TableInputMapping from '@/modules/components/react/components/generic/TableInputMapping';
import TableOutputMapping from '@/modules/components/react/components/generic/TableOutputMapping';
import JSONSchemaEditor from '@/modules/components/react/components/JSONSchemaEditor';
import MappingsWrapper from '@/modules/components/react/components/MappingsWrapper';
import Processors from '@/modules/components/react/components/Processors';
import ComponentStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import { routeNames as componentsRoutes } from '@/modules/components-directory/constants';
import RowsActions from '@/modules/configurations/ConfigurationRowsActionCreators';
import RowsStore from '@/modules/configurations/ConfigurationRowsStore';
import DeleteRowButton from '@/modules/configurations/react/components/DeleteConfigurationRowButton';
import { prepareSandboxes } from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import ActivateDeactivateButton from '@/react/common/ActivateDeactivateButton';
import CatchUnsavedChanges from '@/react/common/CatchUnsavedChanges';
import CatchUnsavedRunWarning from '@/react/common/CatchUnsavedRunWarning';
import CodeEditor from '@/react/common/CodeEditor';
import EncryptedPropertiesHelpBlock from '@/react/common/EncryptedPropertiesHelpBlock';
import SaveButtons from '@/react/common/SaveButtons';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { isValidJsonConfig } from '@/utils/validation';
import { GenericConfigBody } from './GenericConfigBody';

const METADATA_KEY = '_metadata_';

const GenericDetailRow = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      RoutesStore,
      ComponentStore,
      StorageTablesStore,
      StorageBucketsStore,
      InstalledComponentsStore,
      RowsStore,
      StackFeaturesStore,
      SandboxesStore,
    ),
  ],

  getStateFromStores() {
    const componentId = RoutesStore.getCurrentRouteParam('component');
    const configId = RoutesStore.getCurrentRouteParam('config');
    const rowId = RoutesStore.getCurrentRouteParam('row');
    const row = RowsStore.get(componentId, configId, rowId);
    const pendingActions = RowsStore.getPendingActions(componentId, configId, rowId);
    const editingJson = RowsStore.getEditingJsonParametersString(componentId, configId, rowId);

    return {
      componentId,
      configId,
      rowId,
      row,
      pendingActions,
      editingJson,
      tables: StorageTablesStore.getAll(),
      buckets: StorageBucketsStore.getAll(),
      configData: InstalledComponentsStore.getConfigData(componentId, configId),
      component: ComponentStore.getComponent(componentId),
      configuration: InstalledComponentsStore.getConfig(componentId, configId),
      editing: RowsStore.getEditingConfiguration(componentId, configId, rowId),
      isValidEditingJson: isValidJsonConfig(editingJson),
      readOnly: ApplicationStore.isReadOnly(),
      allowedTransformationComponents: getAllowedTransformations(
        ComponentStore.getAllForType(componentTypes.TRANSFORMATION),
        ApplicationStore.getSapiToken(),
        ApplicationStore.getCurrentProjectFeatures(),
        StackFeaturesStore.getAll(),
      ),
      sandboxes: prepareSandboxes(
        SandboxesStore.getSandboxes(),
        InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SANDBOXES),
      ),
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
    };
  },

  getInitialState() {
    return {
      jsonSchemEditorResetKey: _.uniqueId('json_schema_editor_'),
    };
  },

  render() {
    if (!this.state.row.count()) {
      return null;
    }

    if (!Map.isMap(this.state.component.get('configurationRowSchema'))) {
      return (
        <ConfigurationSchemaNotValidBox
          component={this.state.component}
          entity="configurationRow"
        />
      );
    }

    return (
      <GenericConfigBody
        key={`${this.state.componentId}-${this.state.configId}-${this.state.rowId}`}
        componentId={this.state.componentId}
        configId={this.state.configId}
        rowId={this.state.rowId}
        sidebarProps={{
          run: {
            forceModal: this.isChanged(),
            text: (
              <>
                {this.isChanged() && <CatchUnsavedRunWarning />}
                <p>You are about to run {this.state.row.get('name')}.</p>
              </>
            ),
          },
          delete: (
            <DeleteRowButton
              mode="link"
              isPending={this.state.pendingActions.has('delete')}
              onClick={this.handleDelete}
            />
          ),
          additionalButtons: !this.state.readOnly && this.renderAdditionalButtons(),
        }}
      >
        <MappingsWrapper>
          {this.tableInputMapping()}
          {this.fileInputMapping()}
          {this.tableOutputMapping()}
          {this.fileOutputMapping()}
        </MappingsWrapper>
        <CatchUnsavedChanges
          isDirty={this.isChanged()}
          onSave={this.handleSaveConfiguration}
          isSaveDisabled={
            this.state.component.get('configurationRowSchema').isEmpty() &&
            !this.state.isValidEditingJson
          }
          onDirtyLeave={this.handleResetConfiguration}
        >
          {this.configuration()}
          {this.processorsConfiguration()}
        </CatchUnsavedChanges>
      </GenericConfigBody>
    );
  },

  configuration() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Configuration Parameters</h2>
          {this.renderSaveButtons()}
        </div>
        <div className="box-content">
          {this.state.component.get('configurationRowSchema').isEmpty()
            ? this.renderCodeEditor()
            : this.renderJsonSchemaEditor()}
          {supportsFileTags(this.state.component, this.state.configData) && (
            <FileTagsSelector
              tags={this.state.editing.getIn(['storage', 'output', 'table_files', 'tags'], List())}
              onChange={this.handleChangeFileTags}
              disabled={this.state.readOnly}
            />
          )}
        </div>
      </div>
    );
  },

  renderSaveButtons() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <SaveButtons
        isSaving={this.state.pendingActions.has('save-configuration')}
        isChanged={this.isChanged()}
        disabled={
          this.state.component.get('configurationRowSchema').isEmpty() &&
          !this.state.isValidEditingJson
        }
        onSave={this.handleSaveConfiguration}
        onReset={this.handleResetConfiguration}
      />
    );
  },

  renderCodeEditor() {
    return (
      <div className="edit">
        <CodeEditor
          value={this.state.editingJson}
          onChange={this.handleChangeJson}
          options={{
            readOnly: this.state.pendingActions.has('save-configuration') || this.state.readOnly,
          }}
        />
        <EncryptedPropertiesHelpBlock />
      </div>
    );
  },

  renderJsonSchemaEditor() {
    let schema = this.state.component.get('configurationRowSchema');
    let value = this.state.editing.get('parameters', Map());

    if (this.hasPrefilledTable()) {
      const enhancedData = this.prepareEnvData(schema, value);

      schema = enhancedData.schema;
      value = enhancedData.value;
    }

    return (
      <JSONSchemaEditor
        key={this.state.jsonSchemEditorResetKey}
        component={this.state.component}
        schema={schema}
        value={value}
        onChange={this.handleChange}
        readOnly={this.state.pendingActions.has('save-configuration') || this.state.readOnly}
      />
    );
  },

  tableInputMapping() {
    if (
      !this.hasPrefilledTable() &&
      !this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_TABLE_INPUT)
    ) {
      return null;
    }

    return (
      <TableInputMapping
        readOnly={this.state.readOnly}
        rowId={this.state.rowId}
        configId={this.state.configId}
        componentId={this.state.componentId}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
        value={this.state.row.getIn(['configuration', 'storage', 'input', 'tables'], List())}
        tables={this.state.tables}
        buckets={this.state.buckets}
        destinationType={getDestinationTypeFromStagingStorage(
          this.state.component.getIn(['data', 'staging_storage', 'input']),
        )}
        allowedComponents={this.state.allowedTransformationComponents}
        sandboxes={this.state.sandboxes}
        hasPayAsYouGo={this.state.hasPayAsYouGo}
        singleMapping={this.hasPrefilledTable()}
      />
    );
  },

  fileInputMapping() {
    if (!this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_FILE_INPUT)) {
      return null;
    }

    return (
      <FileInputMapping
        readOnly={this.state.readOnly}
        rowId={this.state.rowId}
        configId={this.state.configId}
        componentId={this.state.componentId}
        value={this.state.row.getIn(['configuration', 'storage', 'input', 'files'], List())}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
        allowedComponents={this.state.allowedTransformationComponents}
        sandboxes={this.state.sandboxes}
        hasPayAsYouGo={this.state.hasPayAsYouGo}
      />
    );
  },

  tableOutputMapping() {
    if (
      !this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_TABLE_OUTPUT)
    ) {
      return null;
    }

    return (
      <TableOutputMapping
        readOnly={this.state.readOnly}
        rowId={this.state.rowId}
        configId={this.state.configId}
        componentId={this.state.componentId}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
        configName={this.state.row.get('name')}
        value={this.state.row.getIn(['configuration', 'storage', 'output', 'tables'], List())}
        tables={this.state.tables}
        buckets={this.state.buckets}
        sourceType={getSourceTypeFromStagingStorage(
          this.state.component.getIn(['data', 'staging_storage', 'output']),
        )}
      />
    );
  },

  fileOutputMapping() {
    if (!this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_FILE_OUTPUT)) {
      return null;
    }

    return (
      <FileOutputMapping
        readOnly={this.state.readOnly}
        rowId={this.state.rowId}
        configId={this.state.configId}
        componentId={this.state.componentId}
        value={this.state.row.getIn(['configuration', 'storage', 'output', 'files'], List())}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
      />
    );
  },

  renderAdditionalButtons() {
    let actions = [];

    actions.push(
      <ActivateDeactivateButton
        isActive={!this.state.row.get('isDisabled', false)}
        isPending={
          this.state.pendingActions.has('enable') || this.state.pendingActions.has('disable')
        }
        onChange={this.handleActivateDeactivate}
      />,
    );

    return actions;
  },

  processorsConfiguration() {
    if (
      ![componentTypes.EXTRACTOR, componentTypes.WRITER].includes(
        this.state.component.get('type'),
      ) &&
      !this.state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_PROCESSORS) &&
      !this.state.row.hasIn(['configuration', 'processors'])
    ) {
      return null;
    }

    return (
      <Processors
        value={this.state.row.getIn(['configuration', 'processors'], Map())}
        readOnly={this.state.readOnly}
        onSubmit={this.handleSaveProcessors}
      />
    );
  },

  hasPrefilledTable() {
    return (
      hasWriterSimpleTableInput(this.state.component) &&
      this.state.row.getIn(['configuration', 'storage', 'input', 'tables'], List()).count() === 1
    );
  },

  handleChange(parameters) {
    return RowsActions.updateConfiguration(
      this.state.componentId,
      this.state.configId,
      this.state.rowId,
      this.state.editing.set('parameters', parameters.delete(METADATA_KEY)),
    );
  },

  isChanged() {
    if (this.state.component.get('configurationRowSchema').isEmpty()) {
      return this.isRowJsonChanged() || this.isFileTagsChanged();
    }

    return this.isParametersChanged() || this.isFileTagsChanged();
  },

  isParametersChanged() {
    return !this.state.row
      .getIn(['configuration', 'parameters'], Map())
      .equals(this.state.editing.get('parameters', Map()));
  },

  isRowJsonChanged() {
    const rowJson = JSON.stringify(
      this.state.row.getIn(['configuration', 'parameters'], Map()).toJS(),
      null,
      '  ',
    );
    return rowJson !== this.state.editingJson;
  },

  isFileTagsChanged() {
    return !this.state.row
      .getIn(['configuration', 'storage', 'output', 'table_files', 'tags'], List())
      .equals(this.state.editing.getIn(['storage', 'output', 'table_files', 'tags'], List()));
  },

  handleChangeJson(value) {
    return RowsActions.updateJsonConfiguration(
      this.state.componentId,
      this.state.configId,
      this.state.rowId,
      value,
    );
  },

  handleRun() {
    return { config: this.state.configId, row: this.state.rowId };
  },

  handleActivateDeactivate() {
    if (this.state.row.get('isDisabled', false)) {
      return RowsActions.enable(this.state.componentId, this.state.configId, this.state.rowId);
    }

    return RowsActions.disable(this.state.componentId, this.state.configId, this.state.rowId);
  },

  handleDelete() {
    RoutesStore.getRouter().transitionToForce(componentsRoutes.GENERIC_CONFIG, {
      component: this.state.componentId,
      config: this.state.configId,
    });

    return RowsActions.delete(this.state.componentId, this.state.configId, this.state.rowId);
  },

  handleSaveConfiguration() {
    if (this.state.component.get('configurationRowSchema').isEmpty()) {
      return this.handleSaveParameters(fromJS(JSON.parse(this.state.editingJson)));
    }

    return this.handleSaveParameters(this.state.editing.get('parameters', Map()));
  },

  handleResetConfiguration() {
    if (this.state.component.get('configurationRowSchema').isEmpty()) {
      return RowsActions.resetJsonConfiguration(
        this.state.componentId,
        this.state.configId,
        this.state.rowId,
      );
    }

    RowsActions.resetConfiguration(this.state.componentId, this.state.configId, this.state.rowId);
    this.resetJsonSchemaEditor();
  },

  handleSaveParameters(parameters) {
    return RowsActions.saveConfigurationSimple(
      this.state.componentId,
      this.state.configId,
      this.state.rowId,
      this.state.row
        .get('configuration')
        .set('parameters', parameters)
        .update((configuration) => {
          if (this.state.editing.hasIn(['storage', 'output', 'table_files', 'tags'])) {
            return configuration.setIn(
              ['storage', 'output', 'table_files', 'tags'],
              this.state.editing.getIn(['storage', 'output', 'table_files', 'tags']),
            );
          }

          return configuration;
        }),
      `Row ${this.state.row.get('name')} updated`,
    ).then(this.resetJsonSchemaEditor);
  },

  handleSaveProcessors(processors) {
    const configData = processors
      ? this.state.row.get('configuration').set('processors', processors)
      : this.state.row.get('configuration').delete('processors');

    return RowsActions.saveConfigurationSimple(
      this.state.componentId,
      this.state.configId,
      this.state.rowId,
      configData,
      'Update processors configuration',
      { skipResetEditing: true },
    );
  },

  handleChangeFileTags(fileTags) {
    return RowsActions.updateConfiguration(
      this.state.componentId,
      this.state.configId,
      this.state.rowId,
      this.state.editing.setIn(['storage', 'output', 'table_files', 'tags'], fileTags),
    );
  },

  resetJsonSchemaEditor() {
    if (!this.state.component.get('configurationRowSchema').isEmpty()) {
      this.setState({ jsonSchemEditorResetKey: _.uniqueId('json_schema_editor_') });
    }
  },

  prepareEnvData(schema, value) {
    const mapping = this.state.row.getIn(['configuration', 'storage', 'input', 'tables', 0], Map());
    const table = this.state.tables.get(mapping.get('source', ''), Map());

    return {
      schema: schema.setIn(
        ['properties', METADATA_KEY],
        fromJS({
          type: 'object',
          options: { hidden: true },
          properties: {
            table: {
              type: 'object',
              properties: {
                id: { type: 'string' },
                name: { type: 'string' },
                columns: { type: 'array' },
                primaryKey: { type: 'array' },
              },
            },
          },
        }),
      ),
      value: value.setIn(
        [METADATA_KEY, 'table'],
        fromJS({
          id: table.get('id', ''),
          name: table.get('displayName', ''),
          columns: mapping.get('columns', List()).isEmpty()
            ? table.get('columns', [])
            : mapping.get('columns'),
          primaryKey: table.get('primaryKey', []),
        }),
      ),
    };
  },
});

export default GenericDetailRow;
