import type { SortState } from '@/react/common/SortByName';

export const sortRowsByName = (sort: SortState) => {
  return (rowA: Map<string, any>, rowB: Map<string, any>) => {
    if (!sort) {
      return 0;
    }

    return rowA.get('name').localeCompare(rowB.get('name')) * (sort === 'asc' ? 1 : -1);
  };
};

export const IncompatibleConfigModalProps = {
  buttonLabel: 'Save',
  icon: 'floppy-disk',
  modalTitle: 'Save Parameters',
  modalBody: (
    <p>
      The changes in the configuration are not compatible with the original visual editor. Saving
      this configuration will disable the visual representation of the whole configuration and you
      will be able to edit the configuration in code editor only.
    </p>
  ),
};
