import { useState } from 'react';
import type { Map } from 'immutable';

import { Button, Icon } from '@keboola/design';

import { NEW_ENTITY_BUTTON } from '@/constants/external';
import FilesStore from '@/modules/components/stores/StorageFilesStore';
import { loadFiles } from '@/modules/storage/actions';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import UploadModal from './UploadModal';

const UploadFileButton = () => {
  const store = useStores(
    () => {
      return {
        readOnly: ApplicationStore.isReadOnly(),
        uploadingProgress: FilesStore.getAllUploadingProgress() as Map<string, any>,
        searchQuery: RoutesStore.getRouterState().getIn(['location', 'query', 'q']) ?? '',
      };
    },
    [],
    [ApplicationStore, FilesStore, RoutesStore],
  );

  const [openUploadModal, setOpenUploadModal] = useState(false);

  if (store.readOnly) {
    return null;
  }

  const reloadFiles = () => {
    return loadFiles({ offset: 0, ...(store.searchQuery && { q: store.searchQuery }) });
  };

  return (
    <>
      <Button onClick={() => setOpenUploadModal(true)} className={NEW_ENTITY_BUTTON}>
        <Icon icon="circle-arrow-up" />
        Upload Files
      </Button>
      <UploadModal
        show={openUploadModal}
        uploadingProgress={store.uploadingProgress}
        onUpload={reloadFiles}
        onHide={() => setOpenUploadModal(false)}
      />
    </>
  );
};

export default UploadFileButton;
