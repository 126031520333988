import type { ComponentProps } from 'react';

import { cn, Icon } from '@keboola/design';

export type IconColor =
  | 'blue'
  | 'green'
  | 'light-green'
  | 'red'
  | 'violet'
  | 'yellow'
  | 'gray'
  | 'orange';

type Props = {
  icon?: ComponentProps<typeof Icon>['icon'];
  iconUrl?: string;
  color?: IconColor;
  backgroundColor?: string;
  className?: string;
  bold?: boolean;
  bigger?: boolean;
  smaller?: boolean;
};

export const CircleIcon = ({
  icon,
  iconUrl,
  className,
  color = 'blue',
  backgroundColor,
  bold = false,
  bigger = false,
  smaller = false,
}: Props) => {
  let styles = {};

  if (backgroundColor) {
    styles = {
      color: '#fff',
      transition: 'background 0.3s ease',
      background: backgroundColor,
    };
  }

  return (
    <span
      className={cn(
        'circle-icon',
        className,
        { [color]: !iconUrl },
        { bold },
        { 'bigger-icon': bigger },
        { 'smaller-icon': smaller },
      )}
      style={styles}
    >
      {iconUrl ? (
        <img src={iconUrl} width="100%" height="100%" loading="lazy" alt="Circle icon" />
      ) : (
        icon && <Icon icon={icon} />
      )}
    </span>
  );
};
