import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import _ from 'underscore';

import { Link } from '@keboola/design';

import { routeNames } from '@/modules/storage/constants';
import { parse as parseTable } from '@/utils/tableIdParser';

const ExternalProjectTableLink = createReactClass({
  propTypes: {
    table: PropTypes.object.isRequired,
    urlTemplates: PropTypes.object.isRequired,
  },

  render() {
    const { table, urlTemplates } = this.props;
    const tableId = table.get('id');
    const projectId = table.getIn(['project', 'id']);
    const projectName = table.getIn(['project', 'name']);
    const parsedTable = parseTable(tableId);

    return (
      <span>
        <Link href={_.template(urlTemplates.get('project'))({ projectId })}>{projectName}</Link>
        {' / '}
        <Link
          href={
            _.template(urlTemplates.get('project'))({ projectId }) +
            `/${routeNames.ROOT}/${parsedTable.parts.stage}.${parsedTable.parts.bucket}/${parsedTable.parts.table}`
          }
        >
          {tableId}
        </Link>
      </span>
    );
  },
});

export default ExternalProjectTableLink;
