import { useState } from 'react';
import type { ChangeEvent, FormEvent } from 'react';
import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import type { Map } from 'immutable';

import { Alert, HelpBlock } from '@keboola/design';

import dayjs from '@/date';
import { canWriteBucket } from '@/modules/admin/privileges';
import { filterProductionBuckets } from '@/modules/dev-branches/helpers';
import { backends } from '@/modules/storage/constants';
import { bucketDisplayNameWithStage, validateTableName } from '@/modules/storage/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import { bucketLabel } from '@/react/common/selectLabels';
import PredefinedInput from './PredefinedInput';

const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
const DATETIME_COMPACT_FORMAT = 'YYYYMMDDHHmmss';

type Props = {
  show: boolean;
  buckets: Map<string, any>;
  tables: Map<string, any>;
  table: Map<string, any>;
  sapiToken: Map<string, any>;
  onConfirm: (destinationBucket: string, tableName: string, timestamp: string) => Promise<void>;
  onHide: () => void;
};

const TimeTravelModal = (props: Props) => {
  const minRestoreDate = () => {
    const dataRetentionTimeInDays = props.sapiToken.getIn(['owner', 'dataRetentionTimeInDays']);
    const projectRetentionMinDate = dayjs().subtract(dataRetentionTimeInDays, 'days');
    const tableCreatedDate = dayjs(props.table.get('created'));

    return dayjs.max(projectRetentionMinDate, tableCreatedDate);
  };

  const restoreDate = dayjs(minRestoreDate()).add(5, 'minute');
  const now = dayjs();
  const finalDate = restoreDate.isAfter(now, 'minutes') ? now : restoreDate;

  const initValues = {
    timestamp: finalDate.format(DATETIME_FORMAT),
    tableName: props.table.get('name') + '_' + finalDate.format(DATETIME_COMPACT_FORMAT),
    destinationBucket: props.table.getIn(['bucket', 'id']),
    warning: null,
    isSaving: false,
  };

  const [timestamp, setTimestamp] = useState(finalDate.format(DATETIME_FORMAT));
  const [tableName, setTableName] = useState(
    props.table.get('name') + '_' + finalDate.format(DATETIME_COMPACT_FORMAT),
  );
  const [destinationBucket, setDestinationBucket] = useState(props.table.getIn(['bucket', 'id']));
  const [warning, setWarning] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const retentionLimit = props.sapiToken.getIn(['owner', 'dataRetentionTimeInDays']);
  const bucketsOptions = filterProductionBuckets(props.buckets)
    .filter((bucket: Map<string, any>) => canWriteBucket(props.sapiToken, bucket))
    .sortBy((bucket: Map<string, any>) => bucketDisplayNameWithStage(bucket))
    .map((bucket: Map<string, any>) => {
      return {
        value: bucket.get('id'),
        label: bucketLabel(bucket),
        name: bucket.get('displayName'),
      };
    })
    .toArray();

  const handleTimestamp = (e: ChangeEvent<HTMLInputElement>) => {
    if (tableName.match(/_\d{14}$/) && dayjs(e.target.value).isValid()) {
      setTableName(
        props.table.get('name') + '_' + dayjs(e.target.value).format(DATETIME_COMPACT_FORMAT),
      );
    }

    setTimestamp(e.target.value);
    setTableName(tableName);
  };

  const handleDestinationBucket = (selected: string) => {
    setDestinationBucket(selected);
    validateName();
  };

  const handleTableName = (tableName: string) => {
    setTableName(tableName);
    validateName();
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsSaving(true);
    props
      .onConfirm(destinationBucket, tableName, timestamp)
      .then(props.onHide)
      .catch((error) => {
        setIsSaving(false);
        throw error;
      });
  };

  const validateName = () => {
    setWarning(
      validateTableName(
        tableName,
        props.tables.filter(
          (table: Map<string, any>) => table.getIn(['bucket', 'id']) === destinationBucket,
        ) as Map<string, any>,
      ),
    );
  };

  const isValidDate = () => {
    const current = dayjs(timestamp);
    const min = minRestoreDate();
    const max = dayjs();

    return (
      current.isValid() &&
      current.isSameOrAfter(min, 'minutes') &&
      current.isSameOrBefore(max, 'minutes')
    );
  };

  const isDisabled = !isValidDate() || !destinationBucket || !tableName || !!warning;

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      onEnter={() => {
        setTimestamp(initValues.timestamp);
        setTableName(initValues.tableName);
        setDestinationBucket(initValues.destinationBucket);
        setWarning(initValues.warning);
        setIsSaving(initValues.isSaving);
      }}
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Restore Table Using Time Travel</Modal.Title>
          <ModalIcon color="green" icon="arrow-rotate-left" bold />
        </Modal.Header>
        <Modal.Body>
          <p>
            This will create a new table which will be a replica of the data as it existed at the
            time of your choice. If the table has defined data types, they must be re-created after
            the table has been created. This method cannot replicate data older than your project
            limit of <strong>{retentionLimit} days</strong>.
          </p>
          <FormGroup>
            <ControlLabel>Replication Date</ControlLabel>
            <FormControl
              type="datetime-local"
              placeholder={DATETIME_FORMAT}
              min={minRestoreDate()?.format(DATETIME_FORMAT)}
              max={dayjs().format(DATETIME_FORMAT)}
              value={timestamp}
              onChange={handleTimestamp}
              disabled={isSaving}
            />
            <HelpBlock className="tw-mt-1">
              Date in <code>{DATETIME_FORMAT}</code> format.
            </HelpBlock>
          </FormGroup>
          <PredefinedInput
            entity="tableName"
            value={tableName}
            warning={warning}
            disabled={isSaving}
            onChange={handleTableName}
          />
          <FormGroup>
            <ControlLabel>Destination Bucket</ControlLabel>
            <Select
              clearable={false}
              disabled={isSaving}
              value={destinationBucket}
              onChange={handleDestinationBucket}
              options={bucketsOptions}
            />
          </FormGroup>
          {props.table.getIn(['bucket', 'backend']) === backends.BIGQUERY && (
            <Alert variant="warning" className="tw-mb-5">
              Note that primary keys are not preserved / restored. They must be set up manually
              after table creation.
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isSaving={isSaving}
            isDisabled={isDisabled}
            saveLabel={isSaving ? 'Creating table...' : 'Create table'}
            saveButtonType="submit"
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default TimeTravelModal;
