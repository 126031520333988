import { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Image, Modal } from 'react-bootstrap';

import { Icon } from '@keboola/design';

import { backendImages } from '@/constants/backendImages';
import { KDS_TEAM_WR_EXASOL, KEBOOLA_WR_LOOKER_V2 } from '@/constants/componentIds';
import { canManageReadOnlyStorageForProvisionedCredentials } from '@/modules/admin/privileges';
import ReadOnlyStorageCheckbox from '@/modules/sandboxes/components/ReadOnlyStorageCheckbox';
import { prepareBackendLabel } from '@/modules/storage/helpers';
import WrDbActions from '@/modules/wr-db/actionCreators';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import Loader from '@/react/common/Loader';
import ModalActionButton from '@/react/common/ModalActionButton';
import ModalIcon from '@/react/common/ModalIcon';
import KeboolaLogo from '@/react/layout/KeboolaLogo';
import RoutesStore from '@/stores/RoutesStore';
import string from '@/utils/string';

const FORM_STEPS = {
  LIST: 'list',
  DETAILS: 'details',
};

class ProvisioningCredentialsModal extends Component {
  state = {
    step: FORM_STEPS.LIST,
    readOnlyStorageAccess: false,
    isPreparing: false,
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        className="provisioning-credentials-modal"
      >
        {this.renderBody()}
      </Modal>
    );
  }

  renderBody() {
    if (this.state.step === FORM_STEPS.DETAILS) {
      return this.renderDetails();
    }

    return this.renderOptionsList();
  }

  renderOptionsList() {
    const driverName = prepareBackendLabel(this.props.driver);

    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>Choose Which Database to Use</Modal.Title>
          <ModalIcon.Database />
        </Modal.Header>
        <Modal.Body>
          {!this.state.isPreparing ? (
            this.props.componentId === KDS_TEAM_WR_EXASOL ? (
              this.renderExasolContent()
            ) : (
              <>
                <ModalActionButton
                  onClick={() => {
                    RoutesStore.getRouter().transitionTo(`${this.props.componentId}-credentials`, {
                      config: this.props.configId,
                    });
                  }}
                  title={`Own ${driverName} database`}
                  description={`User has their own ${driverName} database and will provide its credentials.`}
                  icon={
                    <Image
                      width={48}
                      height={48}
                      className="icon-addon-right"
                      src={backendImages[this.props.driver]}
                    />
                  }
                />
                {this.props.componentId === KEBOOLA_WR_LOOKER_V2 && (
                  <ModalActionButton
                    onClick={() => {
                      RoutesStore.getRouter().transitionTo(
                        `${KEBOOLA_WR_LOOKER_V2}-credentials`,
                        { config: this.props.configId },
                        { type: 'bigquery' },
                      );
                    }}
                    title="Own BigQuery database"
                    description="User has their own BigQuery database and will provide its credentials."
                    icon={
                      <Image
                        width={48}
                        height={48}
                        className="icon-addon-right"
                        src={backendImages.bigquery}
                      />
                    }
                  />
                )}
                <ModalActionButton
                  isDisabled={
                    !this.props.sapiToken.getIn([
                      'owner',
                      `has${string.capitalize(this.props.driver)}`,
                    ])
                  }
                  disabledReason="Provisioning is not available for this backend."
                  onClick={() => {
                    if (canManageReadOnlyStorageForProvisionedCredentials(this.props.driver)) {
                      return this.setState({ step: FORM_STEPS.DETAILS });
                    }

                    return this.createProvisioningCredentials();
                  }}
                  title={`Keboola ${driverName} database`}
                  description={`Keboola will provide and set up a dedicated ${driverName} database.`}
                  icon={
                    <KeboolaLogo className="icon-addon-right" width="48" height="48" withoutText />
                  }
                />
              </>
            )
          ) : (
            <div className="big-padding">
              <Loader className="icon-addon-right" />
              Preparing database...
            </div>
          )}
        </Modal.Body>
      </>
    );
  }

  renderDetails() {
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>Keboola {prepareBackendLabel(this.props.driver)} Database</Modal.Title>
          <ModalIcon.Database />
        </Modal.Header>
        <Modal.Body>
          <ReadOnlyStorageCheckbox
            value={this.state.readOnlyStorageAccess}
            onChange={(checked) => this.setState({ readOnlyStorageAccess: checked })}
            isDisabled={this.state.isPreparing}
          />
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="block">
            <Button onClick={() => this.setState({ step: FORM_STEPS.LIST })}>
              <Icon icon="arrow-left" fixedWidth />
            </Button>
            <ConfirmButtons
              block
              saveLabel="Create Workspace"
              isSaving={this.state.isPreparing}
              onSave={this.createProvisioningCredentials}
            />
          </ButtonToolbar>
        </Modal.Footer>
      </>
    );
  }

  renderExasolContent() {
    return (
      <>
        <ModalActionButton
          onClick={() => {
            RoutesStore.getRouter().transitionTo(
              `${KDS_TEAM_WR_EXASOL}-credentials`,
              { config: this.props.configId },
              { type: 'saas' },
            );
          }}
          title="SaaS Exasol"
          description="Connect to the SaaS version of Exasol using a personal access token."
          icon={
            <Image width={48} height={48} className="icon-addon-right" src={backendImages.exasol} />
          }
        />
        <ModalActionButton
          onClick={() => {
            RoutesStore.getRouter().transitionTo(`${this.props.componentId}-credentials`, {
              config: this.props.configId,
            });
          }}
          title="On Premise/Self Hosted Exasol"
          description="Connect to an on-premise or self-hosted version of Exasol using a username and password."
          icon={
            <Image
              width={48}
              height={48}
              className="icon-addon-right"
              src={backendImages[this.props.driver]}
            />
          }
        />
      </>
    );
  }

  createProvisioningCredentials = () => {
    this.setState({ isPreparing: true });
    WrDbActions.createWorkspaceCredentials(
      this.props.componentId,
      this.props.configId,
      this.props.driver,
      this.state.readOnlyStorageAccess,
    ).then(() => {
      RoutesStore.getRouter().transitionTo(`${this.props.componentId}-credentials`, {
        config: this.props.configId,
      });
    });
  };
}

ProvisioningCredentialsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  driver: PropTypes.oneOf(['snowflake', 'redshift', 'exasol']),
  configId: PropTypes.string.isRequired,
  componentId: PropTypes.string.isRequired,
  sapiToken: PropTypes.object.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default ProvisioningCredentialsModal;
