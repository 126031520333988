import PropTypes from 'prop-types';

import { ClipboardIcon } from '@keboola/design';

const TokenString = ({ token, sendTokenComponent }) => {
  const tokenString = token.get('token');

  return (
    <pre>
      <div>
        {tokenString}
        <ClipboardIcon text={tokenString} />
      </div>
      {sendTokenComponent}
    </pre>
  );
};

TokenString.propTypes = {
  token: PropTypes.object.isRequired,
  sendTokenComponent: PropTypes.any,
};

export default TokenString;
