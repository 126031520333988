import type { ReactNode } from 'react';

import { Badge, type BadgeProps, Tooltip } from '@keboola/design';

const BadgeWithTooltip = (props: {
  label: string;
  variant?: BadgeProps['variant'];
  tooltip?: ReactNode;
  className?: string;
}) => {
  const body = <Badge text={props.label} variant={props.variant} className={props.className} />;

  if (props.tooltip) {
    return (
      <Tooltip tooltip={props.tooltip} placement="top" type="explanatory">
        {body}
      </Tooltip>
    );
  }

  return body;
};

const BadgeBeta = (props: { tooltip?: ReactNode; className?: string }) => (
  <BadgeWithTooltip
    variant="cyan"
    label="Beta"
    tooltip={props.tooltip}
    className={props.className}
  />
);

const BadgeExperimental = (props: { tooltip?: ReactNode; className?: string }) => (
  <BadgeWithTooltip
    variant="purple"
    label="Experimental"
    tooltip={props.tooltip}
    className={props.className}
  />
);

const BadgePrivate = (props: { tooltip?: ReactNode; className?: string }) => (
  <BadgeWithTooltip label="Private" tooltip={props.tooltip} className={props.className} />
);

export { BadgeBeta, BadgeExperimental, BadgePrivate };
