import { useState } from 'react';
import type { ReactNode } from 'react';
import { Promise } from 'bluebird';
import type { Map } from 'immutable';

import { Button, ButtonGroup, cn, IconButton } from '@keboola/design';

import rowRollback from '@/modules/configurations/utils/createRowVersionOnRollback';
import { CreatedDate } from '@/react/common';
import ComponentIcon from '@/react/common/ComponentIcon';
import Loader from '@/react/common/Loader';
import rollback from '@/utils/createVersionOnRollback';
import { SIDE } from './constants';
import { prepareConfigData } from './helpers';
import SplitDiffRenderer from './SplitDiffRenderer';

export type SIDE_OPTIONS = (typeof SIDE)[keyof typeof SIDE];

const SplitDiff = ({
  admins,
  componentId,
  config,
  row,
  leftVersion,
  rightVersion,
  handleChangeVersion,
  branchDiff,
  hideRollback,
  noChangesPlaceholder,
  prepareData = prepareConfigData,
}: {
  admins: Map<string, any>;
  leftVersion: Map<string, any>;
  rightVersion: Map<string, any>;
  handleChangeVersion: (version: number, side: SIDE_OPTIONS) => Promise<any>;
  componentId: string;
  config?: Map<string, any>;
  row?: Map<string, any>;
  branchDiff?: boolean;
  hideRollback?: boolean;
  noChangesPlaceholder?: ReactNode;
  prepareData?: (config?: Map<string, any>) => string;
}) => {
  const actualVersion = !!row ? row.get('version') : (config?.get('version') ?? 1);

  const prepareTitle = (side: SIDE_OPTIONS) => {
    const version = side === SIDE.LEFT ? leftVersion : rightVersion;

    if (!version || version.isEmpty()) {
      return 'No data';
    }

    const versionId = version.get('version');
    const creator = version.getIn(
      ['currentVersion', 'creatorToken', 'description'],
      version.getIn(['creatorToken', 'description'], 'unknown'),
    );
    const adminName = admins.getIn([creator, 'name'], creator);
    const created = version.getIn(['currentVersion', 'created'], version.get('created'));
    const entity = branchDiff
      ? `${side === SIDE.RIGHT ? 'Branch' : 'Production'} version`
      : 'Version';

    const renderLeftSide = () => {
      if (!config) {
        return null;
      }

      return (
        <ButtonGroup className="no-wrap tw-mr-4 !tw-gap-2">
          <LoadVersionButton
            side={SIDE.LEFT}
            diffSide={side}
            leftVersion={leftVersion?.get('version') ?? 1}
            rightVersion={rightVersion?.get('version') ?? 1}
            actualVersion={actualVersion}
            handleChange={handleChangeVersion}
          />
          <LoadVersionButton
            side={SIDE.RIGHT}
            diffSide={side}
            leftVersion={leftVersion?.get('version') ?? 1}
            rightVersion={rightVersion?.get('version') ?? 1}
            actualVersion={actualVersion}
            handleChange={handleChangeVersion}
          />
        </ButtonGroup>
      );
    };

    const renderRightSide = () => {
      if (actualVersion === versionId) {
        return (
          <span className="tw-ml-auto tw-text-right tw-text-secondary-600">Current Version</span>
        );
      }

      if (hideRollback || !config) {
        return null;
      }

      return (
        <VersionRestoreButton
          componentId={componentId}
          configId={config.get('id')}
          versionId={versionId}
          rowId={row?.get('id')}
        />
      );
    };

    return (
      <div className="tw-flex tw-items-center tw-justify-start">
        {renderLeftSide()}
        <header>
          <h3 className="tw-m-0 tw-text-sm tw-font-normal">
            {entity} <b className="tw-font-medium">#{version.get('version')}</b>
          </h3>
          <div className="tw-text-xs tw-text-neutral-400">
            Created <CreatedDate createdTime={created} /> by {adminName}
          </div>
        </header>
        {renderRightSide()}
      </div>
    );
  };

  const oldData = prepareData(leftVersion);
  const newData = prepareData(rightVersion);

  if (noChangesPlaceholder && oldData === newData) {
    return <div>{noChangesPlaceholder}</div>;
  }

  return (
    <div className="split-diff">
      <SplitDiffRenderer
        oldValue={oldData}
        newValue={newData}
        leftTitle={prepareTitle(SIDE.LEFT)}
        rightTitle={prepareTitle(SIDE.RIGHT)}
      />
    </div>
  );
};

export const SplitDiffTitle = ({
  name,
  component,
  helpText,
}: {
  name: string;
  component: Map<string, any>;
  helpText?: ReactNode;
}) => {
  return (
    <>
      <ComponentIcon component={component} size="40" className="tw-mr-4" />
      <div className="flex-container flex-column align-top">
        {name} - configuration changes
        {helpText && <div className="f-12 line-height-16 text-muted">{helpText}</div>}
      </div>
    </>
  );
};

const LoadVersionButton = ({
  side,
  diffSide,
  leftVersion,
  rightVersion,
  actualVersion,
  handleChange,
}: {
  side: SIDE_OPTIONS;
  diffSide: SIDE_OPTIONS;
  leftVersion: number;
  rightVersion: number;
  actualVersion: number;
  handleChange: (version: number, side: SIDE_OPTIONS) => Promise<any>;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const isDisabled =
    isLoading ||
    (diffSide === SIDE.LEFT && side === SIDE.LEFT && leftVersion === 1) ||
    (diffSide === SIDE.LEFT && side === SIDE.RIGHT && leftVersion + 1 === actualVersion) ||
    (diffSide === SIDE.RIGHT && side === SIDE.RIGHT && rightVersion === actualVersion) ||
    (diffSide === SIDE.RIGHT && side === SIDE.LEFT && rightVersion <= 2);

  const version =
    (diffSide === SIDE.LEFT ? leftVersion : rightVersion) + (side === SIDE.LEFT ? -1 : 1);

  return (
    <IconButton
      size="small"
      variant="outline"
      className={cn('only-icon')}
      disabled={isDisabled}
      icon={side === SIDE.RIGHT ? 'angle-right' : 'angle-left'}
      onClick={() => {
        setIsLoading(true);
        Promise.all([
          handleChange(version, diffSide),
          Promise.resolve().then(() => {
            if (diffSide === SIDE.LEFT && side === SIDE.RIGHT && leftVersion + 1 === rightVersion) {
              return handleChange(version + 1, SIDE.RIGHT);
            }

            if (diffSide === SIDE.RIGHT && side === SIDE.LEFT && leftVersion + 1 === rightVersion) {
              return handleChange(version - 1, SIDE.LEFT);
            }

            if (diffSide === SIDE.RIGHT && side === SIDE.RIGHT && rightVersion === 1) {
              return handleChange(1, SIDE.LEFT);
            }
          }),
        ]).finally(() => setIsLoading(false));
      }}
    />
  );
};

const VersionRestoreButton = ({
  componentId,
  configId,
  versionId,
  rowId,
}: {
  componentId: string;
  configId: string;
  versionId: number;
  rowId?: string;
}) => {
  const [restoring, setRestoring] = useState(false);

  return (
    <Button
      size="small"
      variant="outline"
      className="tw-ml-auto"
      disabled={restoring}
      onClick={() => {
        const rollbackAction = !!rowId
          ? rowRollback(componentId, configId, rowId, versionId)
          : rollback(componentId, configId, versionId);

        setRestoring(true);
        rollbackAction().finally(() => setRestoring(false));
      }}
    >
      {restoring ? (
        <>
          <Loader className="text-muted icon-addon-right" />
          Restoring...
        </>
      ) : (
        'Restore version'
      )}
    </Button>
  );
};

export default SplitDiff;
