import Checkbox from './Checkbox';

type Props = {
  isChecked: boolean;
  isDisabled: boolean;
  onToggle: (checked: boolean) => void;
  entity: string;
  isIndeterminate?: boolean;
};

const MultiActionsSelectCheckbox = ({
  isChecked,
  isIndeterminate,
  isDisabled,
  onToggle,
  entity,
}: Props) => {
  return (
    <Checkbox
      tooltip={`${isChecked || isIndeterminate ? 'Deselect' : 'Select'} ${entity}`}
      disabled={isDisabled}
      checked={isChecked}
      indeterminate={isIndeterminate}
      onChange={onToggle}
    />
  );
};

export default MultiActionsSelectCheckbox;
