import { Component } from 'react';
import type { ReactNode, SyntheticEvent } from 'react';
import { Button } from 'react-bootstrap';
import { Map } from 'immutable';

import { cn, Tooltip } from '@keboola/design';

import { routeNames as bucketBrowserRouteNames } from '@/modules/bucket-browser/constants';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import { routeNames as storageRouteNames } from '@/modules/storage/constants';
import { CreatedDate, FileSize, Truncated } from '@/react/common';
import BucketStageLabel from '@/react/common/BucketStageLabel';
import DevBranchLabel from '@/react/common/DevBranchLabel';
import Loader from '@/react/common/Loader';
import RowsCount from '@/react/common/RowsCount';
import RoutesStore from '@/stores/RoutesStore';
import { HTTP_STATUS_CODE_NOT_FOUND } from '@/utils/errors/helpers';
import { prepareLocationContext } from '@/utils/modalRoutes';
import string from '@/utils/string';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen,
} from '@/utils/windowOpen';

type Props = {
  bucketId: string;
  truncateBucketName?: boolean;
  truncateLimit?: number;
  openInNewTab?: boolean;
  className?: string;
  hideLabels?: boolean;
  children?: ReactNode;
};

type State = {
  stage: string;
  bucket: Map<string, any>;
  isLoading: boolean;
};

class StorageApiBucketLinkEx extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = this.getBucketDetails();
  }

  componentDidUpdate(prevProps: Props) {
    const bucketDetails = this.getBucketDetails();

    if (
      prevProps.bucketId !== this.props.bucketId ||
      !this.state.bucket.equals(bucketDetails.bucket)
    ) {
      this.setState(bucketDetails);
    }
  }

  render() {
    return (
      <Tooltip
        tooltip={this.renderTooltip()}
        placement="top"
        type="explanatory"
        className={cn({ 'tw-max-w-md': !!this.props.truncateBucketName })}
      >
        {this.renderBody()}
      </Tooltip>
    );
  }

  renderBody() {
    if (this.state.bucket.isEmpty()) {
      return (
        <Button
          bsStyle="link"
          onClick={this.reloadData}
          className={cn(
            'btn-link-inline dark flex-container inline-flex flex-start',
            this.props.className,
          )}
        >
          {this.renderBucket()}
        </Button>
      );
    }

    const location = RoutesStore.getRouterState().get('location', Map());

    return (
      <Button
        bsStyle="link"
        className={cn(
          'btn-link-inline flex-container inline-flex flex-start',
          this.props.className,
        )}
        onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
        onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
        onClick={(e: SyntheticEvent) => {
          e.preventDefault();
          e.stopPropagation();

          if (this.props.openInNewTab || shouldUseNewWindow(e)) {
            return windowOpen(
              RoutesStore.getRouter().createHref(storageRouteNames.BUCKET, {
                bucketId: this.state.bucket.get('id'),
              }),
            );
          }

          return RoutesStore.getRouter().transitionTo(
            bucketBrowserRouteNames.BUCKET_PREVIEW,
            { bucketId: this.props.bucketId },
            { context: prepareLocationContext(location) },
            null,
            { ...(location.get('state') ?? Map()).toJS(), scrollY: window.scrollY },
          );
        }}
      >
        {this.renderBucket()}
      </Button>
    );
  }

  renderTooltip() {
    if (this.state.bucket.isEmpty()) {
      return (
        <div>
          Bucket does not exist.
          <br />
          {this.state.isLoading ? (
            <>
              <Loader /> Loading...{' '}
            </>
          ) : (
            'Click to reload.'
          )}
        </div>
      );
    }

    if (this.state.bucket.get('hasExternalSchema')) {
      return 'External bucket - click to explore details.';
    }

    return (
      <div className={cn({ 'text-left': !!this.props.truncateBucketName })}>
        {this.props.truncateBucketName && (
          <div className="mbp-4">{this.state.bucket.get('displayName', this.props.bucketId)}</div>
        )}
        <div>
          Last change: <CreatedDate createdTime={this.state.bucket.get('lastChangeDate')} />
        </div>
        <div>
          Data size: <FileSize size={this.state.bucket.get('dataSizeBytes')} />
        </div>
        <div>
          Rows count: <RowsCount count={this.state.bucket.get('rowsCount')} />
        </div>
      </div>
    );
  }

  renderBucket() {
    if (this.props.children) {
      return this.props.children;
    }

    const bucket = this.state.bucket;
    let bucketName = bucket.get('displayName', this.props.bucketId);
    if (this.props.truncateLimit) {
      bucketName = string.truncate(bucketName, this.props.truncateLimit);
    }

    return (
      <>
        {!this.props.hideLabels && !!this.state.stage && (
          <BucketStageLabel placement="left" stage={this.state.stage} className="mtp-1 mbp-1" />
        )}
        {!bucket.isEmpty() && <DevBranchLabel bucket={bucket} />}

        {this.props.truncateBucketName ? <Truncated text={bucketName} noTooltip /> : bucketName}
      </>
    );
  }

  getBucketDetails = () => {
    return {
      stage: string.strLeft(this.props.bucketId, '.'),
      bucket: BucketsStore.getBucket(this.props.bucketId, Map()),
      isLoading: false,
    };
  };

  reloadData = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isLoading: true });
    StorageActionCreators.loadBucketDetailForce(this.props.bucketId)
      .catch((error: any) => {
        if (error?.response?.status === HTTP_STATUS_CODE_NOT_FOUND) {
          return;
        }

        throw error;
      })
      .finally(() => {
        this.setState(this.getBucketDetails());
      });
  };
}

export default StorageApiBucketLinkEx;
