import { cn } from '@keboola/design';

import dataLineageImageLocked from '../../../images/illustrations/data-lineage-locked.png';

import { REQUESTABLE_FEATURES } from '@/constants/features';
import FeatureRequestButton from '@/react/common/FeatureRequestButton';
import { fileUrl } from '@/utils/fileHelpers';

const TeasingContent = ({ className }: { className?: string }) => {
  const feature = REQUESTABLE_FEATURES.FEATURE_AI_LINEAGE;

  return (
    <div className={cn('tw-flex tw-flex-col tw-items-center', className)}>
      <h2 className="tw-text-2xl">Unlock the Power of Data Lineage</h2>
      <h3 className="tw-m-0 tw-text-center tw-text-base tw-text-neutral-400">
        Get access to our comprehensive data lineage capabilities and AI-powered <br /> insights to
        optimize your data operations.
      </h3>
      <FeatureRequestButton feature={feature}>{feature.buttonLabel}</FeatureRequestButton>
      <img src={fileUrl(dataLineageImageLocked)} loading="lazy" alt="Data Stream" />
    </div>
  );
};

export default TeasingContent;
