import PropTypes from 'prop-types';
import PureRenderMixin from 'react-addons-pure-render-mixin';
import createReactClass from 'create-react-class';
import { fromJS, Map } from 'immutable';

import StorageActionCreators from '@/modules/components/StorageActionCreators';
import StorageApi from '@/modules/components/StorageApi';
import { shouldReloadTables } from '@/modules/queue/helpers';
import JobMappingPanel from './JobMappingPanel';

const JobStatsContainer = createReactClass({
  mixins: [PureRenderMixin],

  propTypes: {
    runId: PropTypes.string.isRequired,
    autoRefresh: PropTypes.bool.isRequired,
  },

  componentDidMount() {
    this.collectStats(this.props.runId);

    if (this.props.autoRefresh) {
      this.startPolling();
    }
  },

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.autoRefresh) {
      setTimeout(this.stopPolling, 10000); // events can be delayed
    }

    if (shouldReloadTables(this.state.stats, prevState.stats)) {
      StorageActionCreators.loadBucketsAndTablesForce();
    }
  },

  componentWillUnmount() {
    this.stopPolling();
  },

  getInitialState() {
    return {
      stats: Map(),
    };
  },

  render() {
    return <JobMappingPanel stats={this.state.stats} />;
  },

  startPolling() {
    this.timeout = setInterval(() => this.collectStats(this.props.runId), 5000);
  },

  stopPolling() {
    if (this.timeout) {
      clearInterval(this.timeout);
    }
    if (this.cancellablePromise) {
      this.cancellablePromise.cancel();
    }
  },

  collectStats(runId) {
    if (!runId) {
      return;
    }

    this.cancellablePromise = StorageApi.getRunIdStats(runId).then((stats) => {
      this.setState({ stats: fromJS(stats) });
    });
  },
});

export default JobStatsContainer;
