import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { routeNames } from '@/modules/storage/constants';
import { RouterLink as Link } from '@/react/common';

const tableHasBeenRestoredNotification = (table) => {
  return createReactClass({
    propTypes: {
      onClick: PropTypes.func.isRequired,
    },

    render() {
      return (
        <span>
          Table{' '}
          <Link
            to={routeNames.TABLE}
            params={{ bucketId: table.getIn(['bucket', 'id']), tableName: table.get('name') }}
            onClick={this.props.onClick}
          >
            {table.get('displayName')}
          </Link>{' '}
          has been created.
        </span>
      );
    },
  });
};

export default tableHasBeenRestoredNotification;
