import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { URLS } from '@keboola/constants';
import { Icon, Link } from '@keboola/design';

import ModalIcon from '@/react/common/ModalIcon';
import SearchPanel from '@/react/common/SearchPanel';

const getPredefinedSearches = (userEmail: string) => {
  return [
    {
      name: 'Long running',
      query: 'durationSeconds:>7200',
    },
    {
      name: 'My jobs',
      query: 'token.description:' + userEmail,
    },
    {
      name: 'My failed jobs',
      query: 'status:error AND token.description:' + userEmail,
    },
    {
      name: 'Failed in last 7 days',
      query: 'status:error AND startTime:>now-7d',
    },
  ];
};

const QueryRow = (props: {
  onSearch: (query: string) => void;
  query: string;
  userEmail: string;
}) => {
  const [showModal, setShowModal] = useState(false);

  const additionalActions = () => {
    return (
      <>
        <Button
          bsStyle="link"
          title="Show Quick help"
          className="btn-link-inline btn-link-muted tw-ml-2"
          onClick={() => setShowModal(true)}
        >
          <Icon icon="circle-question" />
        </Button>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Quick Help</Modal.Title>
            <ModalIcon icon="circle-question" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <>
              <h3>Search Attributes</h3>
              <dl>
                <dt>Job status</dt>
                <dd>
                  <code>status:success</code>
                </dd>
                <dt>User who created the job</dt>
                <dd>
                  <code>token.description:john.doe@company.com</code>
                </dd>
                <dt>Component name</dt>
                <dd>
                  <code>params.component:keboola.ex-http</code>
                </dd>
                <dt>Config ID</dt>
                <dd>
                  <code>params.config:351711187</code>
                </dd>
                <dt>Duration</dt>
                <dd>
                  <code>durationSeconds:&gt;120</code>
                </dd>
                <dt>Time started</dt>
                <dd>
                  <code>startTime:[2018-06-21 TO 2018-07-01]</code>
                </dd>
                <dt>Time finished</dt>
                <dd>
                  <code>endTime:[2018-06-21 TO 2018-07-01]</code>
                </dd>
                <h3>Modifiers and Combining Queries</h3>

                <dt>Exclude some results</dt>
                <dd>
                  <code>-status:success</code>
                  <br /> Note the minus sign before the query
                </dd>

                <dt>Combine queries</dt>
                <dd>
                  <code>+params.component:keboola.ex-http +status:error</code>
                </dd>
                <dt>Combine queries with more possible values</dt>
                <dd>
                  <code>+params.component:(keboola.ex-http OR keboola.wr-google-sheets)</code>
                  <br />
                  Jobs from either HTTP or Google Sheets component
                </dd>
                <dt>Complex query</dt>
                <dd>
                  <code>
                    +params.component:(keboola.ex-http OR keboola.wr-google-sheets) AND
                    -status:success
                  </code>
                </dd>
                <dt>Open ended time query</dt>
                <dd>
                  <code>endTime:[2018-06-21 TO *]</code>
                </dd>
              </dl>
              <p>
                You can{' '}
                <Link href={`${URLS.USER_DOCUMENTATION}/management/jobs/#searching-the-jobs-log`}>
                  find more complex examples in our documentation
                </Link>
              </p>
            </>
          </Modal.Body>
        </Modal>
      </>
    );
  };

  return (
    <SearchPanel
      autoFocus
      onSearch={props.onSearch}
      query={props.query}
      predefinedSearches={getPredefinedSearches(props.userEmail)}
      additionalActions={additionalActions()}
      searchFieldPlaceholder="Search jobs"
    />
  );
};

export default QueryRow;
