import dayjs from '@/date';

const ValidUntil = ({ validUntil }: { validUntil: string | null }) => {
  const validUntilDate = dayjs(validUntil);

  if (!validUntilDate.isValid()) {
    return null;
  }

  if (validUntilDate.isBefore(dayjs())) {
    return <span>any time now</span>;
  }

  return <span>{validUntilDate.fromNow()}</span>;
};

export default ValidUntil;
