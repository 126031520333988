import { ControlLabel, FormGroup } from 'react-bootstrap';
import type { Map } from 'immutable';
import _ from 'underscore';

import { JOB_NAME } from '@/modules/ex-generic/constants';
import { flattenJobs } from '@/modules/ex-generic/helpers';
import Select from '@/react/common/Select';
import SwitchControl from './SwitchControl';

const ParentForm = (props: {
  readOnly: boolean;
  parameters: Map<string, any>;
  editing: Map<string, any>;
  savedJob?: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
}) => {
  const childJob = props.editing.get('childJob', false);
  const flattenedJobs = flattenJobs(props.parameters);
  const savedJob = flattenedJobs.find(
    (job: Map<string, any>) => job.get('endpoint') === props.savedJob?.get('endpoint'),
  );
  const options = flattenJobs(props.parameters)
    .filter((job: Map<string, any>) => {
      if (!savedJob) {
        return true;
      }

      return !_.isEqual(
        savedJob.get('endpointPath'),
        job.get('endpointPath').slice(0, savedJob.get('endpointPath').length),
      );
    })
    .map((job: Map<string, any>) => {
      const value = job.get('endpoint');

      return { label: job.get(JOB_NAME, value), value };
    })
    .sortBy((option: { label: string; value: string }) => option.label.toLowerCase())
    .toArray();

  return (
    <>
      <SwitchControl
        label="Child Endpoint"
        checked={childJob}
        readOnly={props.readOnly || !options.length}
        disabledReason={!options.length ? 'No parent endpoints available' : ''}
        onChange={(checked: boolean) => props.setEditing(props.editing.set('childJob', checked))}
      />
      {childJob && (
        <FormGroup>
          <ControlLabel>Parent Endpoint</ControlLabel>
          <Select
            clearable={false}
            disabled={props.readOnly}
            value={props.editing.get('parent')}
            onChange={(value: string) => props.setEditing(props.editing.set('parent', value))}
            options={options}
          />
        </FormGroup>
      )}
    </>
  );
};

export default ParentForm;
