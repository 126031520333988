import { useState } from 'react';
import type { KeyboardEvent, MouseEvent, PropsWithChildren, ReactNode } from 'react';

import { Icon } from '../components/Icon';
import { keyCodes } from '../constants/keyCodes';
import { cn } from '../utils';

import { Collapse } from './Collapse';

type Props = PropsWithChildren<{
  header: ReactNode;
  isDisabled?: boolean;
  defaultExpanded?: boolean;
  className?: string;
  bodyClassName?: string;
}>;

export const CollapsiblePanel = ({
  header,
  className,
  bodyClassName,
  defaultExpanded = false,
  isDisabled = false,
  children,
}: Props) => {
  const [isOpen, setIsOpen] = useState(defaultExpanded);

  const handleKeyUp = (e: KeyboardEvent<HTMLDivElement>) => {
    if ([keyCodes.ENTER, keyCodes.SPACE].includes(e.key)) {
      e.stopPropagation();
      if (!isDisabled) {
        setIsOpen(!isOpen);
      }
    }
  };

  return (
    <Collapse
      open={isOpen}
      className={cn(
        'tw-overflow-hidden tw-rounded !tw-border !tw-border-solid !tw-border-neutral-200',
        className,
      )}
      header={
        <div
          role="button"
          tabIndex={0}
          className={cn(
            'tw-flex tw-items-center tw-justify-between tw-bg-neutral-100 tw-px-4 tw-py-2 tw-text-sm tw-text-neutral-400',
            isDisabled && 'tw-cursor-not-allowed tw-opacity-50',
            !isDisabled && 'tw-cursor-pointer hover:tw-text-secondary-500',
          )}
          onClick={(event: MouseEvent) => {
            event.stopPropagation();
            if (!isDisabled) {
              setIsOpen(!isOpen);
            }
          }}
          onKeyUp={handleKeyUp}
        >
          <div className="tw-py-px tw-text-sm tw-font-medium tw-text-neutral-800">{header}</div>
          <div className="tw-shrink-0">
            <Icon
              icon="chevron-down"
              className={cn('tw-text-xs group-data-[state=open]:tw-rotate-180')}
            />
          </div>
        </div>
      }
    >
      <div className={cn('tw-px-4 tw-py-3', bodyClassName)}>{children}</div>
    </Collapse>
  );
};
