import { Dropdown } from 'react-bootstrap';
import type { Map } from 'immutable';

import { cn, Icon } from '@keboola/design';

import { APP_ROUTE } from '@/constants/routeNames';
import { canLoadSharedBuckets, isSharingAvailable } from '@/modules/admin/privileges';
import { useAutomationId } from '@/modules/automations/useAutomationId';
import { routeNames as componentRoutes } from '@/modules/components-directory/constants';
import { routeNames as dataAppsRoutes } from '@/modules/data-apps/constants';
import { routeNames as dataCatalogRouteNames } from '@/modules/data-catalog/constants';
import { routeNames as flowRoutes } from '@/modules/flows/constants';
import { routeNames as jobRoutes } from '@/modules/jobs/Constants';
import { routeNames as legacyOrchestrationRoutes } from '@/modules/orchestrations/Constants';
import { routeNames as orchestrationRoutes } from '@/modules/orchestrations-v2/constants';
import { routeNames as queueRoutes } from '@/modules/queue/constants';
import { routeNames as workspacesRouteNames } from '@/modules/sandboxes/Constants';
import { routeNames as snowflakePartnerConnectRouteNames } from '@/modules/snowflake-partner-connect/constants';
import { routeNames as storageRoutes } from '@/modules/storage/constants';
import { routeNames as templateRoutes } from '@/modules/templates/constants';
import { routeNames as legacyTransformationsRouteNames } from '@/modules/transformations/Constants';
import { routeNames as transformationsRouteNames } from '@/modules/transformations-v2/constants';
import { RouterLink as Link } from '@/react/common';
import RoutesStore from '@/stores/RoutesStore';
import LinkMenuItem from './LinkMenuItem';

type Props = {
  sapiToken: Map<string, any>;
  showNewTransformations: boolean;
  hideOldTransformations: boolean;
  hasNewQueue: boolean;
  readOnly: boolean;
  hasPayAsYouGo: boolean;
  isDevModeActive: boolean;
  hasConfigurations: boolean;
  hasFlowsOnly: boolean;
  hasFlows: boolean;
  hasTemplates: boolean;
  hasInvalidCustomBackend: boolean;
  isDemoPreview: boolean;
  hasDataApps: boolean;
};

const Navigation = (props: Props) => {
  const router = RoutesStore.getRouter();
  const isAutomationView = !!useAutomationId();
  const isActiveRoute = (routeName: string) => {
    if (isAutomationView && routeName !== flowRoutes.ROOT) return false;

    return router.isActive(routeName);
  };

  const renderComponents = () => {
    if (props.hasDataApps && !props.isDevModeActive) {
      return (
        <Dropdown
          id="navbar-components"
          componentClass="li"
          rootCloseEvent="mousedown"
          disabled={props.hasInvalidCustomBackend}
        >
          <Dropdown.Toggle
            noCaret
            bsStyle="link"
            className={cn('navbar-link', {
              active: isActiveRoute(componentRoutes.ROOT) || isActiveRoute(dataAppsRoutes.ROOT),
              'btn-disabled': props.hasInvalidCustomBackend,
            })}
          >
            <Icon className="icon-addon-right" fixedWidth icon="layer-group" />
            Components
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <LinkMenuItem
              to={props.hasConfigurations ? componentRoutes.CONFIGURATIONS : componentRoutes.ROOT}
              active={isActiveRoute(componentRoutes.ROOT)}
            >
              <Icon className="icon-addon-right" fixedWidth icon="layer-group" />
              Components
            </LinkMenuItem>
            <LinkMenuItem to={dataAppsRoutes.ROOT} active={isActiveRoute(dataAppsRoutes.ROOT)}>
              <Icon className="icon-addon-right" fixedWidth icon="browser" />
              Data Apps
            </LinkMenuItem>
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    return (
      <li>
        <Link
          to={props.hasConfigurations ? componentRoutes.CONFIGURATIONS : componentRoutes.ROOT}
          disabled={props.hasInvalidCustomBackend}
          className={cn('navbar-link', {
            active: isActiveRoute(componentRoutes.ROOT),
            'tw-opacity-60': props.hasInvalidCustomBackend,
          })}
        >
          <Icon className="icon-addon-right" fixedWidth icon="layer-group" />
          <span>Components</span>
        </Link>
      </li>
    );
  };

  const renderFlows = () => {
    if (!props.hasFlows && !props.hasFlowsOnly) {
      return null;
    }

    if (props.hasFlowsOnly || props.isDemoPreview) {
      return (
        <li>
          <Link
            to={flowRoutes.ROOT}
            disabled={props.hasInvalidCustomBackend}
            className={cn('navbar-link', {
              'tw-opacity-60': props.hasInvalidCustomBackend,
            })}
          >
            <Icon className="icon-addon-right" fixedWidth icon="bars-staggered" />
            <span>Flows</span>
          </Link>
        </li>
      );
    }

    return (
      <Dropdown
        id="navbar-flows"
        componentClass="li"
        rootCloseEvent="mousedown"
        disabled={props.hasInvalidCustomBackend}
      >
        <Dropdown.Toggle
          noCaret
          bsStyle="link"
          className={cn('navbar-link', {
            active: isActiveRoute(flowRoutes.ROOT) || isActiveRoute(orchestrationRoutes.ROOT),
            'btn-disabled': props.hasInvalidCustomBackend,
          })}
        >
          <Icon className="icon-addon-right" fixedWidth icon="bars-staggered" />
          Flows
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <LinkMenuItem to={flowRoutes.ROOT} active={isActiveRoute(flowRoutes.ROOT)}>
            <Icon className="icon-addon-right" icon="bars-staggered" fixedWidth />
            Flows
          </LinkMenuItem>
          <LinkMenuItem
            to={orchestrationRoutes.ROOT}
            active={isActiveRoute(orchestrationRoutes.ROOT)}
          >
            <Icon className="icon-addon-right" icon="window-restore" fixedWidth />
            Orchestrations
          </LinkMenuItem>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderUseCases = () => {
    if (!props.hasTemplates || props.isDevModeActive) {
      return null;
    }

    return (
      <li>
        <Link
          to={templateRoutes.ROOT}
          disabled={props.hasInvalidCustomBackend}
          className={cn('navbar-link', {
            active:
              isActiveRoute(templateRoutes.ROOT) ||
              (isActiveRoute(snowflakePartnerConnectRouteNames.UPGRADE_PAGE) &&
                [templateRoutes.ROOT, templateRoutes.TEMPLATES].includes(
                  RoutesStore.getRouterState().getIn(['location', 'query', 'd']),
                )),
            'tw-opacity-60': props.hasInvalidCustomBackend,
          })}
        >
          <Icon className="icon-addon-right" fixedWidth icon="book-open" />
          Templates
        </Link>
      </li>
    );
  };

  const renderDataCatalog = () => {
    if (!isSharingAvailable(props.sapiToken)) {
      return null;
    }

    return (
      <li>
        <Link
          to={
            !canLoadSharedBuckets(props.sapiToken)
              ? dataCatalogRouteNames.SHARED_FROM_THIS_PROJECT
              : dataCatalogRouteNames.SHARED_WITH_YOU
          }
          className={cn('navbar-link', {
            active:
              !isAutomationView &&
              (isActiveRoute(dataCatalogRouteNames.SHARED_WITH_YOU) ||
                isActiveRoute(dataCatalogRouteNames.SHARED_FROM_THIS_PROJECT)),
            'tw-opacity-60': props.hasInvalidCustomBackend,
          })}
          disabled={props.hasInvalidCustomBackend}
        >
          <Icon className="icon-addon-right" fixedWidth icon="book-blank" />
          <span>Data Catalog</span>
        </Link>
      </li>
    );
  };

  const renderOrchestrations = () => {
    if (props.hasFlows || props.hasFlowsOnly || (props.isDevModeActive && !props.hasNewQueue)) {
      return null;
    }

    return (
      <li>
        <Link
          to={props.hasNewQueue ? orchestrationRoutes.ROOT : legacyOrchestrationRoutes.ROOT}
          className={cn('navbar-link', {
            'tw-opacity-60': props.hasInvalidCustomBackend,
          })}
          disabled={props.hasInvalidCustomBackend}
        >
          <Icon className="icon-addon-right" fixedWidth icon="bars-staggered" />
          <span>Orchestrations</span>
        </Link>
      </li>
    );
  };

  const renderTransformationsMenu = () => {
    if (props.showNewTransformations && !props.hideOldTransformations) {
      return (
        <Dropdown
          id="navbar-transformations"
          componentClass="li"
          rootCloseEvent="mousedown"
          disabled={props.hasInvalidCustomBackend}
        >
          <Dropdown.Toggle
            noCaret
            bsStyle="link"
            className={cn('navbar-link', {
              active:
                isActiveRoute('transformations') || isActiveRoute(transformationsRouteNames.ROOT),
            })}
          >
            <Icon className="icon-addon-right" fixedWidth icon="window-restore" />
            Transformations
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <LinkMenuItem
              to={legacyTransformationsRouteNames.ROOT}
              active={isActiveRoute('transformations')}
            >
              <Icon className="icon-addon-right" icon="window-restore" fixedWidth />
              Legacy Transformations
            </LinkMenuItem>
            <LinkMenuItem
              to={transformationsRouteNames.ROOT}
              active={isActiveRoute(transformationsRouteNames.ROOT)}
            >
              <Icon className="icon-addon-right" fixedWidth icon="window-restore" />
              Transformations
            </LinkMenuItem>
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    if (props.showNewTransformations) {
      return (
        <li>
          <Link
            to={transformationsRouteNames.ROOT}
            disabled={props.hasInvalidCustomBackend}
            className={cn('navbar-link', {
              active:
                isActiveRoute(snowflakePartnerConnectRouteNames.UPGRADE_PAGE) &&
                RoutesStore.getRouterState().getIn(['location', 'query', 'd']) ===
                  transformationsRouteNames.ROOT,
              'tw-opacity-60': props.hasInvalidCustomBackend,
            })}
          >
            <Icon className="icon-addon-right" fixedWidth icon="window-restore" />
            <span>Transformations</span>
          </Link>
        </li>
      );
    }
  };

  const renderWorkspaces = () => {
    if (!props.showNewTransformations) {
      return null;
    }

    return (
      <li>
        <Link
          to={workspacesRouteNames.WORKSPACES}
          disabled={props.hasInvalidCustomBackend}
          className={cn('navbar-link', {
            active:
              isActiveRoute(snowflakePartnerConnectRouteNames.UPGRADE_PAGE) &&
              RoutesStore.getRouterState().getIn(['location', 'query', 'd']) ===
                workspacesRouteNames.WORKSPACES,
            'tw-opacity-60': props.hasInvalidCustomBackend,
          })}
        >
          <Icon className="icon-addon-right" fixedWidth icon="box" />
          <span>Workspaces</span>
        </Link>
      </li>
    );
  };

  return (
    <div className="navbar-collapse collapse">
      <div className="navbar">
        <ul className="nav navbar-nav">
          <li>
            <Link to={APP_ROUTE} className="navbar-link" onlyActiveOnIndex>
              <Icon className="icon-addon-right" fixedWidth icon="gauge" />
              <span>Dashboard</span>
            </Link>
          </li>
          {renderFlows()}
          {renderComponents()}
          {renderUseCases()}
          {renderDataCatalog()}
          <li>
            <Link
              to={storageRoutes.ROOT}
              className={cn('navbar-link', {
                'tw-opacity-60': props.hasInvalidCustomBackend,
              })}
              disabled={props.hasInvalidCustomBackend}
            >
              <Icon className="icon-addon-right" fixedWidth icon="warehouse" />
              <span>Storage</span>
            </Link>
          </li>
          {renderOrchestrations()}
          {renderTransformationsMenu()}
          {renderWorkspaces()}
          <li>
            <Link
              to={props.hasNewQueue ? queueRoutes.JOBS : jobRoutes.ROOT}
              className={cn('navbar-link', {
                'tw-opacity-60': props.hasInvalidCustomBackend,
              })}
              disabled={props.hasInvalidCustomBackend}
            >
              <Icon className="icon-addon-right" fixedWidth icon="circle-play" />
              <span>Jobs</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navigation;
