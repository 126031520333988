import { Button } from 'react-bootstrap';
import createReactClass from 'create-react-class';

const AboutPhases = createReactClass({
  getInitialState() {
    return { isExpanded: false };
  },

  render() {
    if (this.state.isExpanded) {
      return (
        <div>
          {this.renderInfo()}{' '}
          <Button
            bsStyle="link"
            onClick={() => this.setState({ isExpanded: false })}
            className="btn-link-inline"
          >
            Hide
          </Button>
        </div>
      );
    }

    return (
      <Button
        bsStyle="link"
        onClick={() => this.setState({ isExpanded: true })}
        className="btn-link-inline"
      >
        More about phases
      </Button>
    );
  },

  renderInfo() {
    return (
      <>
        Phases are executed sequentially from top to bottom. All tasks belonging to a phase are run
        in parallel. The next phase will begin right after all tasks from the previous phase are
        finished. For example, a common setup could have 3 phases: Extract, Transform, and Load.
      </>
    );
  },
});

export default AboutPhases;
