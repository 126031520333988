import { Map } from 'immutable';

import { flattenJobs } from '@/modules/ex-generic/helpers';
import CollapsibleAlert from '@/react/common/CollapsibleAlert';

const PATH_TO_CHECK = {
  general: {
    'Base URL (function)': ['api', 'baseUrl', 'function'],
    Aws: ['aws'],
    Iterations: ['iterations'],
    'SSH Proxy Configuration': ['sshProxy'],
    'Compatibility Level': ['config', 'compatLevel'],
    'Required Headers': ['http', 'requiredHeaders'],
    'Ignore Errors': ['http', 'ignoreErrors'],
    'Curl Codes': ['curl', 'codes'],
  },
  job: {
    'Job Recursion Filter': ['recursionFilter'],
    'Job Response Filter': ['responseFilter'],
    'Job Response Filter Delimiter': ['responseFilterDelimiter'],
  },
};

const advancedGeneralSettings = (parameters: Map<string, any>) => {
  return Object.entries(PATH_TO_CHECK.general)
    .map(([name, path]) => (parameters.hasIn(path) ? name : null))
    .filter(Boolean);
};

const advancedJobSettings = (parameters: Map<string, any>) => {
  const allJobs = flattenJobs(parameters);

  return Object.entries(PATH_TO_CHECK.job)
    .map(([name, path]) => (allJobs.some((job: Map<string, any>) => job.hasIn(path)) ? name : null))
    .filter(Boolean);
};

const AdditionalConfigurationWarning = (props: { configData: Map<string, any> }) => {
  const parameters = props.configData.get('parameters', Map());

  const advancedSettings = [
    ...advancedGeneralSettings(parameters),
    ...advancedJobSettings(parameters),
    ...(props.configData.has('variables_id') ? ['Variables'] : []),
    ...(props.configData.has('processors') ? ['Processors'] : []),
  ];

  if (advancedSettings.length === 0) {
    return null;
  }

  return (
    <CollapsibleAlert
      id="advanced-configuration-warning"
      variant="warning"
      title="Advanced configuration detected"
      className="tw-mb-5"
    >
      <p>
        This configuration contains advanced settings that are not supported by the visual editor.
        It is recommended that you use the JSON editor to modify this configuration.
      </p>
      <p>
        Detected settings: <strong>{advancedSettings.join(', ')}</strong>
      </p>
    </CollapsibleAlert>
  );
};

export default AdditionalConfigurationWarning;
