import { TableIconAction } from './TableIconAction';

type Props = {
  onClick: () => void;
  disabled?: boolean;
};

export const ExportSelected = ({ onClick, disabled = false }: Props) => (
  <TableIconAction
    tooltip="Export selected"
    onClick={onClick}
    disabled={disabled}
    libraryIcon="down-to-line"
  />
);
