import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { List } from 'immutable';

import { HelpBlock } from '@keboola/design';

import { FEATURE_IS_SINGLE_TENANT } from '@/constants/features';
import ExternalBucketLink from '@/modules/components/react/components/ExternalBucketLink';
import ExternalProjectLink from '@/modules/components/react/components/ExternalProjectLink';
import SyncActionSimpleValue from '@/modules/configurations/react/components/SyncActionSimpleValue';
import PasswordControl from '@/react/common/PasswordControl';
import ProjectStackSelector from './ProjectStackSelector';

const SourceProject = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    stackFeatures: PropTypes.instanceOf(List).isRequired,
    value: PropTypes.shape({
      url: PropTypes.string.isRequired,
      token: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    actions: PropTypes.object,
  },

  render() {
    let sourceInfo = this.props.actions.get('sourceInfo');

    return (
      <form className="form-horizontal">
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Project stack</ControlLabel>
          </div>
          <div className="col-xs-8">
            <ProjectStackSelector
              value={this.props.value.url}
              onChange={(url) => this.props.onChange({ url })}
              isDisabled={this.props.disabled}
              isSingleTenantStack={this.props.stackFeatures.includes(FEATURE_IS_SINGLE_TENANT)}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Storage API Token</ControlLabel>
          </div>
          <div className="col-xs-8">
            <PasswordControl
              value={this.props.value.token}
              onChange={(e) => this.props.onChange({ token: e.target.value })}
              disabled={this.props.disabled}
            />
            <HelpBlock className="tw-mt-1">
              Use a token with permissions limited only to read from a single bucket in the source
              project.
            </HelpBlock>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Project</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl.Static>
              <ExternalProjectLink
                stackUrl={sourceInfo.getIn(['request', 'configData', 'parameters', 'url'])}
                projectId={sourceInfo.getIn(['data', 'project', 'projectId'])}
              >
                <SyncActionSimpleValue action={sourceInfo} valueKey={['project', 'projectName']} />
              </ExternalProjectLink>
            </FormControl.Static>
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-4">
            <ControlLabel>Bucket</ControlLabel>
          </div>
          <div className="col-xs-8">
            <FormControl.Static>
              <ExternalBucketLink
                stackUrl={sourceInfo.getIn(['request', 'configData', 'parameters', 'url'])}
                projectId={sourceInfo.getIn(['data', 'project', 'projectId'])}
                bucketId={sourceInfo.getIn(['data', 'project', 'bucket'])}
              >
                <SyncActionSimpleValue action={sourceInfo} valueKey={['project', 'bucket']} />
              </ExternalBucketLink>
            </FormControl.Static>
          </div>
        </FormGroup>
      </form>
    );
  },
});

export default SourceProject;
