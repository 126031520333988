import { Modal } from 'react-bootstrap';
import type BluebirdPromise from 'bluebird';
import type { Map } from 'immutable';

import { Alert } from '@keboola/design';

import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import DevBranchStorageWarning from './DevBranchStorageWarning';
import TableAliasesAndLinks from './TableAliasesAndLinks';

type Props = {
  show: boolean;
  table: Map<string, any>;
  sapiToken: Map<string, any>;
  urlTemplates: Map<string, any>;
  tableAliases: Map<string, any>[];
  tableLinks: Map<string, any>[];
  deleting: boolean;
  onConfirm: () => BluebirdPromise<unknown>;
  onHide: () => void;
};

const DeleteTableModal = (props: Props) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Table</Modal.Title>
        <ModalIcon.Trash />
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete the table <b>{props.table.get('displayName')}</b>?
        </p>
        <DevBranchStorageWarning
          message="The table will also be deleted in production."
          hasProductionEntity={
            !props.table.isEmpty() && !isCreatedInDevBranch(props.table.get('bucket'))
          }
        />
        {(props.tableAliases.length > 0 || props.tableLinks.length > 0) && (
          <>
            <Alert variant="warning" className="tw-mb-5">
              All alias tables and links will also be deleted.
            </Alert>
            <TableAliasesAndLinks
              sapiToken={props.sapiToken}
              urlTemplates={props.urlTemplates}
              tableAliases={props.tableAliases}
              tableLinks={props.tableLinks}
              onLinkClick={props.onHide}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ConfirmButtons
          block
          isSaving={props.deleting}
          isDisabled={props.deleting}
          saveLabel={props.deleting ? 'Deleting...' : 'Delete'}
          saveStyle="danger"
          onSave={() => {
            props.onConfirm().then(props.onHide);
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteTableModal;
