import { cn } from '@keboola/design';

import { Truncated } from '@/react/common';

const HEADER_STYLES =
  'tw-font-semibold tw-px-2 tw-py-0.5 tw-text-[11px] tw-uppercase tw-leading-5 tw-tracking-[1px] tw-text-neutral-400';

const Header = ({ text, href = null }: { text: string; href?: string | null }) => {
  return href ? (
    <a href={href} className="!tw-p-0 tw-no-underline">
      <Truncated text={text} className={cn(HEADER_STYLES, 'hover:tw-text-secondary-500')} />
    </a>
  ) : (
    <Truncated text={text} className={HEADER_STYLES} />
  );
};

export default Header;
