import { useState } from 'react';
import type Promise from 'bluebird';
import type { Map } from 'immutable';

import CodeEditor from '@/react/common/CodeEditor';
import EncryptedPropertiesHelpBlock from '@/react/common/EncryptedPropertiesHelpBlock';
import SaveButtons from '@/react/common/SaveButtons';
import fromJSOrdered from '@/utils/fromJSOrdered';
import { isValidJsonConfig } from '@/utils/validation';

const prepareValue = (configData: Map<string, any>) => {
  return JSON.stringify(configData, null, '  ');
};

const JsonConfiguration = (props: {
  configData: Map<string, any>;
  onSave: (configData: Map<string, any>, changeDescription: string) => Promise<any>;
}) => {
  const preparedValue = prepareValue(props.configData);

  const [value, setValue] = useState(preparedValue);
  const [saving, setSaving] = useState(false);

  const isChanged = value !== preparedValue;

  return (
    <div className="box">
      <div className="box-header big-padding">
        <h2 className="box-title">Configuration</h2>
        <SaveButtons
          isSaving={saving}
          isChanged={isChanged}
          onSave={() => {
            setSaving(true);

            return props
              .onSave(fromJSOrdered(JSON.parse(value)), 'Update configuration')
              .then((configData: Record<string, any>) => {
                setValue(prepareValue(fromJSOrdered(configData)));
              })
              .finally(() => setSaving(false));
          }}
          onReset={() => setValue(preparedValue)}
          disabled={!isValidJsonConfig(value)}
          className="tw-inline-flex"
        />
      </div>
      <div className="box-content !tw-pt-0">
        <CodeEditor value={value} onChange={setValue} />
        <EncryptedPropertiesHelpBlock />
      </div>
    </div>
  );
};

export default JsonConfiguration;
