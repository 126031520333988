import type { ReactNode } from 'react';

import { Link } from '@keboola/design';

import type { ErrorCode } from '@/modules/lineage/constants';
import { ERRORS } from '@/modules/lineage/constants';
import contactSupport from '@/utils/contactSupport';
import LoadingPlaceholder from './LoadingPlaceholder';

const NoGraphWrapper = ({
  isLoading,
  errorCode,
  children,
}: {
  isLoading: boolean;
  errorCode: ErrorCode | null;
  children: ReactNode;
}) => {
  if (isLoading) {
    return <LoadingPlaceholder />;
  }

  if (!errorCode) {
    return children;
  }

  if (errorCode === ERRORS.EMPTY) {
    return (
      <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center">
        <h3>No lineage was calculated. This could be due to the following reasons:</h3>
        <ul className="tw-text-sm tw-text-neutral-400">
          <li>No lineage detected between input and output tables.</li>
          <li>No job run associated with the tables.</li>
        </ul>
        <p className="tw-mb-6 tw-text-sm tw-text-neutral-400">
          If the issue persists, please{' '}
          <Link onClick={() => contactSupport()}>contact our support team</Link> for assistance.
        </p>
      </div>
    );
  }

  if (errorCode === ERRORS.NOT_FOUND) {
    return (
      <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center">
        <h3>
          Lineage for this configuration hasn&apos;t been calculated yet. It updates every 30
          minutes—please check back later.
        </h3>
      </div>
    );
  }

  return (
    <div className="tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center">
      <h3>Failed to Load Data Lineage</h3>
      <p className="tw-mb-6 tw-text-sm tw-text-neutral-400">
        Unable to load data lineage information on this screen. Please check back later. If the
        issue persists, please <Link onClick={() => contactSupport()}>contact our support</Link>.
      </p>
    </div>
  );
};

export default NoGraphWrapper;
