import { Badge, cn } from '@keboola/design';

import { getComponentTypeColorClassName } from '@/modules/components/helpers';
import { Truncated } from '@/react/common';

const TaskName = ({
  name,
  componentName,
  componentType,
  isBlank,
  isDragged,
  isDeleted,
  isDisabled,
  className,
}: {
  name: string;
  componentName: string;
  componentType: string;
  isBlank: boolean;
  isDragged: boolean;
  isDeleted: boolean;
  isDisabled?: boolean;
  className?: string;
}) => {
  return (
    <h4 className={cn('user-select-none line-height-20 m-0', className)}>
      <span className="tw-flex tw-items-center tw-gap-2">
        {isBlank ? (
          <div className={cn('color-orange', { 'tw-opacity-40': isDisabled })}>
            No configuration
          </div>
        ) : (
          <Truncated
            twoLines
            text={name}
            noTooltip={isDragged}
            className={cn('break-all', { 'color-danger': isDeleted, 'tw-opacity-40': isDisabled })}
          />
        )}
        {isDisabled && <Badge text="Disabled" variant="purple" className="tw-min-w-fit" />}
      </span>
      <div
        className={cn('flex-container flex-start f-12 font-normal text-muted', {
          'tw-opacity-40': isDisabled,
        })}
      >
        {componentType && (
          <span
            className={cn(
              'font-medium no-wrap tw-mr-1',
              getComponentTypeColorClassName(componentType),
            )}
          >
            {componentType}
          </span>
        )}
        <Truncated text={componentName} />
      </div>
    </h4>
  );
};

export default TaskName;
