import { SERVICE_BILLING } from '@/constants/serviceIds';
import ServicesStore from '@/modules/services/Store';
import ApplicationStore from '@/stores/ApplicationStore';
import type { HttpMethod } from '@/utils/request';
import request from '@/utils/request';

const createRequest = (method: HttpMethod, path: string) => {
  return request(method, `${ServicesStore.getServiceUrl(SERVICE_BILLING)}/${path}`).set(
    'X-StorageApi-Token',
    ApplicationStore.getSapiTokenString(),
  );
};

const BillingApi = {
  getCredits() {
    return createRequest('GET', 'credits')
      .promise()
      .then((response) => response.body);
  },
};

export default BillingApi;
