import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { configPoll } from '@/utils/genericPolls';
import { configRequiredData } from '@/utils/genericRequiredData';
import HeaderButtons from './react/Index/HeaderButtons';
import Index from './react/Index/Index';
import QueryDetail from './react/Index/Query';
import QueryNameEdit from './react/Index/QueryNameEdit';
import store from './storeProvisioning';

export default function routes(componentsIds) {
  return componentsIds.map((componentId) => {
    return {
      name: componentId,
      path: `${componentId}/:config`,
      defaultRouteHandler: Index(componentId),
      requireData: [(params) => configRequiredData(componentId, params.config)],
      title: (routerState) => {
        const configId = routerState.getIn(['params', 'config']);
        return InstalledComponentsStore.getConfig(componentId, configId).get('name');
      },
      poll: configPoll(componentId),
      childRoutes: [
        {
          name: `${componentId}-query-detail`,
          path: 'query/:queryId',
          defaultRouteHandler: QueryDetail,
          headerButtonsHandler: HeaderButtons,
          title: (routerState) => {
            const configId = routerState.getIn(['params', 'config']);
            const queryId = routerState.getIn(['params', 'queryId']);

            return store(componentId, configId).findQuery(queryId).get('name');
          },
          nameEdit: (params) => {
            return (
              <QueryNameEdit
                componentId={componentId}
                configId={params.config}
                queryId={params.queryId}
              />
            );
          },
        },
      ],
    };
  });
}
