import { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { List, Map } from 'immutable';

import * as componentFlags from '@/constants/componentFlags';
import contactSupport from '@/utils/contactSupport';

class ConfigurationSchemaNotValidBox extends Component {
  render() {
    return (
      <div className="box">
        <div className="box-content">
          <p>
            The component configuration {this.props.entity === 'configurationRow' && 'row '}schema
            is not valid.
          </p>
          {!this.props.component
            .get('flags', List())
            .includes(componentFlags.EXCLUDE_FROM_NEW_LIST) && (
            <Button onClick={() => contactSupport()} bsStyle="success">
              Contact Support
            </Button>
          )}
        </div>
      </div>
    );
  }
}

ConfigurationSchemaNotValidBox.propTypes = {
  component: PropTypes.instanceOf(Map).isRequired,
  entity: PropTypes.oneOf(['configuration', 'configurationRow']),
};

export default ConfigurationSchemaNotValidBox;
