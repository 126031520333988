import type { Map } from 'immutable';

import ApplicationStore from '@/stores/ApplicationStore';

export const getFormattedAdminsForSelect = () => {
  return getAdmins().map((admin: Map<string, any>) => ({
    value: admin.get('id'),
    label: admin.get('name'),
    email: admin.get('email'),
  }));
};

export const findAdminByUser = (user: Map<string, any>) => {
  if (!user) return void 0;

  return getAdmins().find(
    (admin: Map<string, any>) =>
      admin.get('id') === user.get('id') || admin.get('email') === user.get('email'),
  );
};

const getAdmins = () => ApplicationStore.getAdmins().valueSeq();
