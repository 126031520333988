import { cn } from '../utils/classNames';

type DotsLoaderProps = {
  className?: string;
};

const DOT_CLASS_NAME = cn('tw-h-1.5', 'tw-w-1.5', 'tw-rounded-full', 'tw-bg-neutral-700');

export const DotsLoader = ({ className }: DotsLoaderProps) => {
  return (
    <div
      className={cn(
        'tw-relative -tw-m-0.5 tw-flex tw-items-center tw-justify-center tw-gap-1',
        className,
      )}
    >
      <div className={cn(DOT_CLASS_NAME, 'tw-animate-dot-pulse-1')} />
      <div className={cn(DOT_CLASS_NAME, 'tw-animate-dot-pulse-2')} />
      <div className={cn(DOT_CLASS_NAME, 'tw-animate-dot-pulse-3')} />
    </div>
  );
};
