import { Button } from 'react-bootstrap';

import { Icon } from '@keboola/design';

import { DataSampleItem } from '@/modules/storage/components/DataSample';
import Loader from '@/react/common/Loader';
import DynamicLoadingText from './DynamicLoadingText';

export type DataSample = {
  dataSample: { columns?: string[]; rows?: { value: string; isTruncated: boolean }[][] };
  isLoading?: boolean;
};
type Props = {
  tableData: DataSample | null;
  isSaving: boolean;
  handleReset: () => void;
};

const SimplifiedDataSample = ({ tableData, isSaving, handleReset }: Props) => {
  const isLoading = !isSaving && tableData?.isLoading;

  return (
    <>
      <div id="data-sample-table" className="box no-bg flex-container justify-center">
        {isLoading && (
          <div className="color-base font-medium text-center">
            <Loader className="icon-addon-right f-20 mb-1" />
            <br />
            <DynamicLoadingText />
          </div>
        )}
        {!tableData?.dataSample?.rows?.length && !isLoading && (
          <div className="color-base font-medium text-center">
            <span className="color-base font-medium">No Data Found</span>
            <br />
            <Button className="mt-1" onClick={handleReset}>
              <Icon icon="arrow-rotate-left" className="icon-addon-right" />
              Reset
            </Button>
          </div>
        )}
        {!!tableData?.dataSample?.rows?.length && !isLoading && (
          <div className="table-responsive align-start">
            <table className="table table-hover">
              <thead>
                <tr className="is-sticky">
                  {tableData?.dataSample?.columns?.map((columnName) => (
                    <th key={columnName} className="text-left">
                      {columnName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableData.dataSample.rows.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((column, columnIndex) => (
                      <td key={`${rowIndex}-${columnIndex}`} className="text-left bg-color-white">
                        <DataSampleItem source={column.value} isNotDownloadable />
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default SimplifiedDataSample;
