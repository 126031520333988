import { ControlLabel, FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { Alert, ClipboardButton, HelpBlock } from '@keboola/design';

import { KEBOOLA_EX_EMAIL_ATTACHMENTS as COMPONENT_ID } from '@/constants/componentIds';
import Processors from '@/modules/components/react/components/Processors';
import SapiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import { GenericConfigBody } from '@/modules/components/react/pages/GenericConfigBody';
import actionsProvisioning from '@/modules/ex-email-attachments/actionsProvisioning';
import storeProvisioning, { storeMixins } from '@/modules/ex-email-attachments/storeProvisioning';
import Loader from '@/react/common/Loader';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import getDefaultBucket from '@/utils/getDefaultBucket';
import ConfigurationForm from './ConfigurationForm';

const Index = createReactClass({
  mixins: [createStoreMixin(...storeMixins, ApplicationStore)],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const store = storeProvisioning(configId);
    const actions = actionsProvisioning(configId);

    return {
      configId,
      store,
      actions,
      localState: store.getLocalState(),
      settings: store.settings,
      processors: store.processors,
      readOnly: ApplicationStore.isReadOnly(),
    };
  },

  componentDidMount() {
    this.state.actions.requestEmailAndInitConfig();
  },

  getDefaultBucketName() {
    return getDefaultBucket('in', COMPONENT_ID, this.state.configId) + '.data';
  },

  renderImportedResult() {
    return (
      <FormGroup>
        <div className="col-xs-4">
          <ControlLabel>Output Table</ControlLabel>
        </div>
        <div className="col-xs-8">
          <InputGroup>
            <SapiTableLinkEx tableId={this.getDefaultBucketName()} />
          </InputGroup>
          <HelpBlock className="tw-mt-1">
            Table in Storage, where the .csv attachments will be imported. If the table or bucket
            does not exist, it will be created.
          </HelpBlock>
        </div>
      </FormGroup>
    );
  },

  render() {
    return (
      <GenericConfigBody
        key={`${COMPONENT_ID}-${this.state.configId}`}
        componentId={COMPONENT_ID}
        configId={this.state.configId}
        sidebarProps={{
          run: { disabled: this.invalidToRun(), text: 'You are about to run an extraction.' },
        }}
      >
        {this.state.store.requestedEmail ? this.renderConfig() : this.renderInitConfig()}
      </GenericConfigBody>
    );
  },

  renderInitConfig() {
    if (this.state.store.error) {
      return this.renderError();
    }

    return (
      <div className="box">
        <div className="box-content">
          <p>
            <Loader /> Generating an email address, please wait.
          </p>
        </div>
      </div>
    );
  },

  renderConfig() {
    return (
      <>
        <div className="box">
          <div className="box-content">
            <div className="form-horizontal">
              <FormGroup>
                <div className="col-xs-4">
                  <ControlLabel>Email address</ControlLabel>
                </div>
                <div className="col-xs-8">
                  <div className="tw-flex tw-items-center tw-gap-4">
                    <FormControl
                      type="email"
                      placeholder="Creating an email address, please wait"
                      readOnly
                      value={this.state.store.requestedEmail}
                      title="Email address generated by Email Attachments"
                    />
                    <ClipboardButton
                      text={this.state.store.requestedEmail}
                      label="Copy email"
                      className="tw-whitespace-nowrap"
                    />
                  </div>
                  <HelpBlock className="tw-mt-1">
                    Please send emails with .csv attachments to this email address. Received
                    attachments will be imported after configuration execution. Only new emails are
                    imported on subsequent executions.
                  </HelpBlock>
                </div>
              </FormGroup>
              {this.renderImportedResult()}
            </div>
          </div>
        </div>
        <ConfigurationForm
          readOnly={this.state.readOnly}
          incremental={this.state.settings.get('incremental')}
          delimiter={this.state.settings.get('delimiter')}
          enclosure={this.state.settings.get('enclosure')}
          primaryKey={this.state.settings.get('primaryKey')}
          onChange={this.state.actions.editChange}
          requestedEmail={this.state.store.requestedEmail}
          actions={this.state.actions}
          localState={this.state.localState}
        />
        {this.renderProcessors()}
      </>
    );
  },

  renderError() {
    const error = this.state.store.error;
    const code = error.code;
    let message = 'Unexpected error';
    try {
      const jsError = JSON.parse(error).error;
      message = jsError.message || jsError;
    } catch {
      message = error.message || error;
    }

    return (
      <Alert variant="error" className="tw-mb-5">
        <p>Error: {message}</p>
        {code >= 500 ? <div>{error.get('exceptionId')}</div> : null}
      </Alert>
    );
  },

  invalidToRun() {
    if (
      !this.state.settings.get('enclosure') ||
      !this.state.settings.get('delimiter') ||
      !this.state.settings.get('email')
    ) {
      return 'Configuration has missing values';
    }
    if (this.state.localState.get('isChanged', false)) {
      return 'Configuration is not saved';
    }

    return '';
  },

  renderProcessors() {
    if (this.state.processors === '{}') {
      return null;
    }

    return (
      <Processors
        readOnly={this.state.readOnly}
        value={this.state.processors}
        onSubmit={this.state.actions.editProcessorsSave}
      />
    );
  },
});

export default Index;
