import { memo, useCallback, useMemo } from 'react';
import type { ReactNode } from 'react';
import { shallowEqualImmutable } from 'react-immutable-render-mixin';
import { fromJS, List, Map } from 'immutable';

import { Badge } from '@keboola/design';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { FLOW, routeNames, tabs } from '@/modules/flows/constants';
import ScheduleInfo from '@/modules/scheduler/components/ScheduleInfo';
import { RouterLink } from '@/react/common';
import ConfigurationsTable from '@/react/common/ConfigurationsTable/ConfigurationsTable';
import type { ConfigRow } from '@/react/common/ConfigurationsTable/constants';
import ActivateFlowSwitch from './ActivateFlowSwitch';
import RetryButton from './RetryButton';
import RunResults from './RunResults';

const CUSTOM_CLASSES = fromJS({
  schedule: { th: 'text-right w-200', td: 'text-right text-muted w-200' },
  run_results: { th: 'text-right w-175', td: 'text-right w-175' },
});
const HIDE_COLUMN_FOR_SMALLER_SCREEN = ['schedule', 'run_results'];

type Props = {
  admins: Map<string, any>;
  currentAdmin: Map<string, any>;
  notifications: List<any>;
  triggers: Map<string, any>;
  latestJobs: Map<string, any>;
  configurations: Map<string, any>;
  allConfigurations: Map<string, any>;
  allComponents: Map<string, any>;
  sapiToken: Map<string, any>;
  hasProtectedDefaultBranch: boolean;
  hasFlows: boolean;
  readOnly: boolean;
  isSearching: boolean;
  component: Map<string, any>;
  componentsMetadata?: Map<string, any>;
  expandedFolders?: Record<string, boolean>;
};

const FlowConfigs = (props: Props) => {
  const additionalColumns = useMemo(() => {
    return [
      {
        enableSorting: false,
        accessorKey: 'schedule',
        header: 'Schedule',
        cell: ({ row }: { row: ConfigRow }) => {
          const { config } = row.original.data;

          if (!config) {
            return null;
          }

          return (
            <ScheduleInfo config={config} triggers={props.triggers.get(config.get('id'), Map())}>
              {(label: ReactNode) => (
                <RouterLink
                  to={routeNames.DETAIL}
                  params={{ config: config.get('id'), tab: tabs.SCHEDULES }}
                >
                  {label}
                </RouterLink>
              )}
            </ScheduleInfo>
          );
        },
      },
      {
        enableSorting: false,
        accessorKey: 'run_results',
        header: 'Run Results',
        cell: ({ row }: { row: ConfigRow }) => {
          const { config } = row.original.data;

          if (!config) {
            return null;
          }

          return (
            <RunResults jobs={props.latestJobs.getIn([KEBOOLA_ORCHESTRATOR, config.get('id')])} />
          );
        },
      },
    ];
  }, [props.latestJobs, props.triggers]);

  const renderAdditionalActions = useCallback(
    (config: Map<string, any>) => {
      return (
        <>
          <ActivateFlowSwitch flow={config} isDisabled={props.readOnly} />
          {props.hasFlows && (
            <RetryButton
              mode="menuitem"
              latestJob={props.latestJobs.getIn([KEBOOLA_ORCHESTRATOR, config.get('id'), 0])}
              config={config}
              sapiToken={props.sapiToken}
              allComponents={props.allComponents}
              allInstalledComponents={props.allConfigurations}
              hasProtectedDefaultBranch={props.hasProtectedDefaultBranch}
            />
          )}
        </>
      );
    },
    [
      props.latestJobs,
      props.allComponents,
      props.allConfigurations,
      props.readOnly,
      props.hasFlows,
      props.sapiToken,
      props.hasProtectedDefaultBranch,
    ],
  );

  const renderCustomLabel = useCallback(
    (data: { config: Map<string, any> }) => {
      const hasConfiguration = data.config
        .getIn(['configuration', 'tasks'], List())
        .some((task: Map<string, any>) => !!task.getIn(['task', 'configId']));

      if (!props.hasFlows || hasConfiguration) {
        return null;
      }

      return (
        <Badge variant="orange-inverse" placement="right">
          No configurations
        </Badge>
      );
    },
    [props.hasFlows],
  );

  return (
    <ConfigurationsTable
      hasNewQueue
      supportFolders={!!props.componentsMetadata}
      configurations={props.configurations}
      allConfigurations={props.allConfigurations}
      admins={props.admins}
      currentAdmin={props.currentAdmin}
      notifications={props.notifications}
      readOnly={props.readOnly}
      component={props.component}
      latestJobs={props.latestJobs}
      forceShowAll={props.isSearching}
      hasFlows={props.hasFlows}
      customClasses={CUSTOM_CLASSES}
      additionalColumns={additionalColumns}
      renderAdditionalActions={renderAdditionalActions}
      renderCustomLabel={renderCustomLabel}
      columnsHiddenForSmallerScreens={HIDE_COLUMN_FOR_SMALLER_SCREEN}
      componentsMetadata={props.componentsMetadata}
      availableConfigurations={props.allConfigurations.get(KEBOOLA_ORCHESTRATOR)}
      expandedFolders={props.expandedFolders}
      entity={FLOW}
    />
  );
};

const MemoizedFlowConfigs = memo(FlowConfigs, shallowEqualImmutable);

export default MemoizedFlowConfigs;
