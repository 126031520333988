import createReactClass from 'create-react-class';

import JulipyterSandbox from '@/modules/transformations/react/components/JulipyterSandbox';
import JupyterSandbox from '@/modules/transformations/react/components/JupyterSandbox';
import RedshiftSandbox from '@/modules/transformations/react/components/RedshiftSandbox';
import RStudioSandbox from '@/modules/transformations/react/components/RStudioSandbox';
import SnowflakeSandbox from '@/modules/transformations/react/components/SnowflakeSandbox';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';

const Sandbox = createReactClass({
  mixins: [createStoreMixin(ApplicationStore)],

  getStateFromStores() {
    const token = ApplicationStore.getSapiToken();

    return {
      hasRedshift: token.getIn(['owner', 'hasRedshift'], false),
      hasSnowflake: token.getIn(['owner', 'hasSnowflake'], false),
      readOnly: ApplicationStore.isReadOnly(),
    };
  },

  render() {
    return (
      <>
        {this.state.hasRedshift && <RedshiftSandbox readOnly={this.state.readOnly} />}
        {this.state.hasSnowflake && <SnowflakeSandbox readOnly={this.state.readOnly} />}
        <RStudioSandbox readOnly={this.state.readOnly} />
        <JupyterSandbox readOnly={this.state.readOnly} />
        <JulipyterSandbox readOnly={this.state.readOnly} />
      </>
    );
  },
});

export default Sandbox;
