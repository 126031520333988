import { Icon } from '@keboola/design';

import { ObjectTypes } from '@/modules/components/MetadataConstants';
import { routeNames } from '@/modules/storage/constants';
import { BucketLabels, RouterLink as Link, Truncated } from '@/react/common';
import TableCollapseButton from '@/react/common/TableCollapseButton';
import type { RowData } from '@/types/types';
import { Description } from './Description';

type Props = {
  row: RowData;
  canWrite: boolean;
};

export const BucketCell = ({ row, canWrite }: Props) => (
  <span className="with-expand flex-container flex-start overflow-break-anywhere">
    {row.getCanExpand() && (
      <TableCollapseButton entity="bucket" isCollapsed={!row.getIsExpanded()} />
    )}
    <Icon
      fixedWidth
      icon={
        row.getCanExpand() ? (row.getIsExpanded() ? 'folder-open' : 'folder') : ['far', 'folder']
      }
      className="text-muted"
    />
    <div>
      <div className="flex-container flex-start">
        <Link
          to={routeNames.BUCKET}
          params={{ bucketId: row.original.item.get('id') }}
          className="link-inherit tw-mr-2.5"
        >
          <Truncated text={row.original.item.get('displayName')} />
        </Link>
        <BucketLabels bucket={row.original.item} />
      </div>
      <Description row={row} entity={ObjectTypes.BUCKET} readOnly={!canWrite} />
    </div>
  </span>
);
