import { useState } from 'react';
import type { Map } from 'immutable';
import { List } from 'immutable';

import { canManageSharedBucket } from '@/modules/admin/privileges';
import type { BucketType } from '@/modules/data-catalog/types';
import { RowActionMenuItem } from '@/react/common';
import { EditSharingModal, ForceUnlinkModal } from './modals';

type AvailableOptionType = { id: number; name: string };
type Props = {
  bucket: BucketType;
  sapiToken: Map<string, any>;
  urlTemplates: Map<string, any>;
  availableUsersOptions: AvailableOptionType[];
  availableProjectsOptions: AvailableOptionType[];
};

export const EditSharing = ({
  bucket,
  sapiToken,
  urlTemplates,
  availableUsersOptions,
  availableProjectsOptions,
}: Props) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [disableAnimation, setDisableAnimation] = useState(false);

  return (
    <>
      <RowActionMenuItem
        onSelect={() => setShowEditModal(true)}
        disabled={!canManageSharedBucket(sapiToken)}
        disabledReason="You don't have access rights to edit sharing. Ask your organization administrator for the permission."
      >
        Edit sharing
      </RowActionMenuItem>
      <EditSharingModal
        disableAnimation={disableAnimation}
        bucket={bucket}
        sapiToken={sapiToken}
        availableUsersOptions={availableUsersOptions}
        availableProjectsOptions={availableProjectsOptions}
        show={showEditModal}
        closeModalFn={() => setShowEditModal(false)}
        onSaveFn={(showLinkingWarning: boolean) => {
          if (showLinkingWarning && bucket.get('linkedBy', List()).count() > 0) {
            setDisableAnimation(true);
            setShowUnlinkModal(true);
            setTimeout(() => setDisableAnimation(false), 400);
          }
        }}
      />
      <ForceUnlinkModal
        disableAnimation={disableAnimation}
        show={showUnlinkModal}
        closeModalFn={() => setShowUnlinkModal(false)}
        allowCancel
        showUnsharedInfo
        bucket={bucket}
        urlTemplates={urlTemplates}
        sapiToken={sapiToken}
      />
    </>
  );
};
