import Loader from './Loader';

const BoxLoader = ({ entity }: { entity: string }) => {
  return (
    <div className="box">
      <div className="box-content">
        <Loader /> Loading {entity}, please wait...
      </div>
    </div>
  );
};

export default BoxLoader;
