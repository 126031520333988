import { type MouseEvent, useCallback, useEffect, useState } from 'react';

import { IconButton, Tooltip } from '@keboola/design';

import type { UserMessage } from '@/api/routes/aiService';
import { componentTypes } from '@/constants/componentTypes';
import keyCodes from '@/constants/keyCodes';
import { routeNames } from '@/modules/flows/constants';
import RoutesStore from '@/stores/RoutesStore';

type Props = {
  flow: {
    flowId: string;
    automationId: string;
    taskId: string;
    scroll: string;
  };
  component: {
    id: string;
    type: string;
  };
  configuration: {
    id: string;
    configuration: Record<string, any>;
  };
  submitAutomationMessage?: (message: Partial<UserMessage>) => void;
};

const BackToFlowButton = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const submitAutomationMessage = props.submitAutomationMessage;

  const redirectBackToFlow = useCallback(
    async (event: MouseEvent | KeyboardEvent) => {
      event.stopPropagation();

      const currentRouteName = RoutesStore.getCurrentRouteConfig()?.get('name');

      if (isLoading) return;

      if (props.flow.automationId) {
        setIsLoading(true);

        await submitAutomationMessage?.({
          type:
            props.component.type === componentTypes.TRANSFORMATION
              ? 'transformationEditFinished'
              : 'configurationEditFinished',
          data: {
            taskId: props.flow.taskId,
            componentId: props.component.id,
            configurationId: props.configuration.id,
            configuration: props.configuration.configuration,
          },
        });

        setIsLoading(false);
      }

      if (currentRouteName !== RoutesStore.getCurrentRouteConfig()?.get('name')) return;

      RoutesStore.getRouter().transitionTo(
        routeNames.DETAIL,
        { config: props.flow.flowId },
        null,
        null,
        {
          scroll: props.flow.scroll,
        },
      );
    },
    [isLoading, props.flow, props.component, props.configuration, submitAutomationMessage],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        !document.body.classList.contains('modal-open') &&
        !document.body.classList.contains('full-screen-modal-open') &&
        event.key === keyCodes.ESCAPE
      ) {
        redirectBackToFlow(event);
      }
    };

    document.addEventListener('keydown', handleKeyDown, { passive: true });

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [redirectBackToFlow]);

  return (
    <Tooltip tooltip="Back to flow">
      <IconButton
        variant="outline"
        onClick={redirectBackToFlow}
        icon="xmark"
        isLoading={isLoading}
        disabled={isLoading}
      />
    </Tooltip>
  );
};

export default BackToFlowButton;
