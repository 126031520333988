import type { List } from 'immutable';

import Form from '@/react/common/KeyValueBox/Form';
import { isVariableDuplicate } from './helpers';

type Props = {
  saveVariableFn: (name: string, value: string) => Promise<void>;
  resetVariableFn: () => void;
  isSaving: boolean;
  variables: List<any>;
  variableName?: string;
  variableValue?: string;
  nameOnly?: boolean;
};

const VariableForm = ({
  saveVariableFn,
  resetVariableFn,
  isSaving,
  variables,
  variableName = '',
  variableValue = '',
  nameOnly,
}: Props) => {
  return (
    <Form
      onSubmit={(name, value) => {
        saveVariableFn(name, value).then(resetVariableFn);
      }}
      isSaving={isSaving}
      checkDuplicate={(newName) => isVariableDuplicate(variables, variableName, newName)}
      defaultKey={variableName}
      defaultValue={variableValue}
      nameOnly={nameOnly}
      keyPlaceholder="Variable name (e.g. “multiplier”)"
      valuePlaceholder="Default value (e.g. “1000”)"
      onReset={resetVariableFn}
    />
  );
};

export default VariableForm;
