import type { ReactNode } from 'react';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import _ from 'underscore';

import Select from '@/react/common/Select';
import { EXPIRATION_OPTIONS } from './constants';
import { prepareExpirationOption } from './helpers';

const HOURS_OPTIONS = _.range(0, 24).map((hour) => prepareExpirationOption(hour));
const MINUTES_OPTIONS = _.range(0, 60).map((minute) => prepareExpirationOption(minute));

const TimeoutControl = (props: {
  value: number;
  onChange: (value: number, isCustom: boolean) => void;
  showCustom: boolean;
  isDisabled: boolean;
  label?: ReactNode;
  help?: ReactNode;
}) => {
  const hours = Math.floor(props.value / 3600);
  const minutes = Math.floor((props.value % 3600) / 60);

  const showCustom =
    props.showCustom || !EXPIRATION_OPTIONS.some((option) => option.value === props.value);

  const handleChange = (field: 'timeout' | 'hours' | 'minutes') => (value: number) => {
    const isCustom = field === 'timeout' ? value === 0 : true;

    switch (field) {
      case 'timeout':
        props.onChange(isCustom ? 3600 : value, isCustom);
        break;
      case 'hours':
        props.onChange(value * 3600 + minutes * 60, isCustom);
        break;
      case 'minutes':
        props.onChange(hours * 3600 + value * 60, isCustom);
        break;
    }
  };

  return (
    <>
      <FormGroup>
        <ControlLabel>{props.label || 'Timeout'}</ControlLabel>
        <Select
          clearable={false}
          disabled={props.isDisabled}
          options={EXPIRATION_OPTIONS}
          value={showCustom ? 0 : props.value}
          onChange={handleChange('timeout')}
        />
      </FormGroup>
      {showCustom && (
        <FormGroup className="tw-flex-1">
          <div className="tw-flex tw-items-center tw-justify-between tw-gap-2">
            <span className="tw-flex-1">
              <ControlLabel>Hours</ControlLabel>
              <Select
                clearable={false}
                value={hours}
                onChange={handleChange('hours')}
                options={HOURS_OPTIONS}
              />
            </span>
            <span className="tw-inline-flex tw-self-end tw-leading-10">:</span>
            <span className="tw-flex-1">
              <ControlLabel>Minutes</ControlLabel>
              <Select
                clearable={false}
                value={minutes}
                onChange={handleChange('minutes')}
                options={MINUTES_OPTIONS}
              />
            </span>
          </div>
          {props.help}
        </FormGroup>
      )}
    </>
  );
};

export default TimeoutControl;
