import type { Map } from 'immutable';

import { routeNames } from '@/modules/storage/constants';
import { RouterLink as Link } from '@/react/common';
import ExternalProjectBucketLink from './ExternalProjectBucketLink';

type Props = {
  sapiToken: Map<string, any>;
  urlTemplates: Map<string, any>;
  alias: Map<string, any>;
};

const ProjectAliasLink = (props: Props) => {
  const projectId = parseInt(props.alias.getIn(['project', 'id']), 10);

  if (props.sapiToken.getIn(['owner', 'id']) === projectId) {
    return (
      <Link
        to={routeNames.TABLE}
        params={{
          bucketId: props.alias.get('id'),
          tableName: props.alias.getIn(['table', 'name']),
        }}
      >
        {props.alias.get('id')} / {props.alias.getIn(['table', 'displayName'])}
      </Link>
    );
  }

  if (props.sapiToken.getIn(['admin', 'isOrganizationMember'])) {
    return <ExternalProjectBucketLink bucket={props.alias} urlTemplates={props.urlTemplates} />;
  }

  return (
    <span>
      {props.alias.getIn(['project', 'name'])} / {props.alias.get('id')} /{' '}
      {props.alias.getIn(['table', 'displayName'])}
    </span>
  );
};

export default ProjectAliasLink;
