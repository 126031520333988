export const ShareTablesIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="svg-custom biggest"
  >
    <path
      d="M17 23C18.5913 23 20.1174 22.3679 21.2426 21.2426C22.3679 20.1174 23 18.5913 23 17C23 15.4087 22.3679 13.8826 21.2426 12.7574C20.1174 11.6321 18.5913 11 17 11C15.4087 11 13.8826 11.6321 12.7574 12.7574C11.6321 13.8826 11 15.4087 11 17C11 18.5913 11.6321 20.1174 12.7574 21.2426C13.8826 22.3679 15.4087 23 17 23ZM20.4219 16.4375L19.625 17.232L17.5625 15.1695V20.75H16.4375V15.1695L14.375 17.232L13.5805 16.4375L17 13.0156L20.4219 16.4375Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.101 12C13.3636 10.7628 15.0927 10 17 10C17.3395 10 17.6734 10.0242 18 10.0709V5C18 3.89688 17.1031 3 16 3H4C2.89688 3 2 3.89688 2 5V15C2 16.1031 2.89688 17 4 17H10C10 15.6803 10.3652 14.446 11 13.3924V12H12.101ZM4 7V10H9V7H4ZM9 12H4V15H9V12ZM11 10H16V7H11V10Z"
      fill="currentColor"
    />
  </svg>
);
