import type { ReactNode } from 'react';

import { FormGroup, Label, type ValidityState } from '@keboola/design';

import { isValidUrl } from '@/utils/validation';

const prepareUrlPreview = (baseUrl: string, path: string) => {
  if (path.startsWith('http://') || path.startsWith('https://')) {
    return path;
  }

  if (path.startsWith('/') && isValidUrl(baseUrl)) {
    return `${new URL(baseUrl).origin}${path}`;
  }

  return `${baseUrl}${path}`;
};

const UrlPreview = (props: { baseUrl: string; path: string }) => {
  const state: ValidityState = isValidUrl(props.baseUrl) ? 'default' : 'error';
  const messages: Partial<Record<ValidityState, ReactNode>> = {
    error: 'Base url is not valid URL.',
  };

  return (
    <FormGroup state={state} className="tw-mb-4">
      <Label htmlFor="url-preview">URL Preview</Label>
      <FormGroup.TextInput
        id="url-preview"
        disabled
        value={prepareUrlPreview(props.baseUrl, props.path)}
        variant="secondary"
      />
      <FormGroup.Help>{messages[state]}</FormGroup.Help>
    </FormGroup>
  );
};

export default UrlPreview;
