import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { TRANSFORMATION } from '@/constants/componentIds';
import actionCreators from '@/modules/components/InstalledComponentsActionCreators';
import jobsApi from '@/modules/jobs/JobsApi';
import provisioningActions from '@/modules/provisioning/ActionCreators';
import JulipyterSandboxCredentialsStore from '@/modules/provisioning/stores/JulipyterSandboxCredentialsStore';
import JupyterSandboxCredentialsStore from '@/modules/provisioning/stores/JupyterSandboxCredentialsStore';
import RedshiftSandboxCredentialsStore from '@/modules/provisioning/stores/RedshiftSandboxCredentialsStore';
import RStudioSandboxCredentialsStore from '@/modules/provisioning/stores/RStudioSandboxCredentialsStore';
import SnowflakeSandboxCredentialsStore from '@/modules/provisioning/stores/SnowflakeSandboxCredentialsStore';
import { backends, transformationType } from '@/modules/transformations/Constants';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ConfigureSandboxModal from './ConfigureSandboxModal';

const ConfigureSandbox = createReactClass({
  mixins: [
    createStoreMixin(
      RedshiftSandboxCredentialsStore,
      SnowflakeSandboxCredentialsStore,
      JupyterSandboxCredentialsStore,
      JulipyterSandboxCredentialsStore,
      RStudioSandboxCredentialsStore,
    ),
  ],

  propTypes: {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    defaultMode: PropTypes.string.isRequired,
    backend: PropTypes.string.isRequired,
    transformationType: PropTypes.string.isRequired,
    runParams: PropTypes.object.isRequired,
  },

  getStateFromStores() {
    let dockerStore;

    if (this.isPythonTransformation()) {
      dockerStore = JupyterSandboxCredentialsStore;
    } else if (this.isJuliaTransformation()) {
      dockerStore = JulipyterSandboxCredentialsStore;
    } else {
      dockerStore = RStudioSandboxCredentialsStore;
    }

    return {
      redshiftCredentials: RedshiftSandboxCredentialsStore.getCredentials(),
      snowflakeCredentials: SnowflakeSandboxCredentialsStore.getCredentials(),
      dockerCredentials: dockerStore.getCredentials(),
      dockerPendingActions: dockerStore.getPendingActions(),
      isLoadingDockerCredentials: dockerStore.getIsLoading(),
      canDockerExtend: dockerStore.getCanExtend(),
    };
  },

  getInitialState() {
    return {
      mode: this.props.defaultMode,
      isRunning: false,
      jobId: null,
      progress: null,
      progressStatus: null,
      isCreated: false,
    };
  },

  componentWillUnmount() {
    if (this.cancellablePromiseRunComponent) {
      this.cancellablePromiseRunComponent.cancel();
    }
    if (this.cancellablePromiseJobDetail) {
      this.cancellablePromiseJobDetail.cancel();
    }
  },

  render() {
    return (
      <ConfigureSandboxModal
        redshiftCredentials={this.state.redshiftCredentials}
        dockerCredentials={this.state.dockerCredentials}
        dockerPendingActions={this.state.dockerPendingActions}
        isLoadingDockerCredentials={this.state.isLoadingDockerCredentials}
        isPythonTransformation={this.isPythonTransformation()}
        isJuliaTransformation={this.isJuliaTransformation()}
        snowflakeCredentials={this.state.snowflakeCredentials}
        onHide={this.handleModalClose}
        show={this.props.show}
        backend={this.props.backend}
        transformationType={this.props.transformationType}
        mode={this.state.mode}
        jobId={this.state.jobId}
        progress={this.state.progress}
        progressStatus={this.state.progressStatus}
        isRunning={this.state.isRunning}
        isCreated={this.state.isCreated}
        canDockerExtend={this.state.canDockerExtend}
        onModeChange={this.handleModeChange}
        onCreateStart={
          this.props.backend === 'docker'
            ? this.handleDockerSandboxCreate
            : this.handleSandboxCreate
        }
        onDropStart={this.handleDockerDropCredentials}
      />
    );
  },

  handleModeChange(mode) {
    this.setState({ mode });
  },

  handleDockerSandboxCreate() {
    this.setState({
      isRunning: true,
      jobId: null,
      progress: 'Creating sandbox and loading data.',
      progressStatus: null,
    });

    let createCredentialsAction;

    if (this.isPythonTransformation()) {
      createCredentialsAction = provisioningActions.createJupyterSandboxCredentials;
    } else if (this.isJuliaTransformation()) {
      createCredentialsAction = provisioningActions.createJulipyterSandboxCredentials;
    } else {
      createCredentialsAction = provisioningActions.createRStudioSandboxCredentials;
    }

    const createSandboxPromise = createCredentialsAction(this.props.runParams.toJS(), {
      source: 'transformation',
    });

    return createSandboxPromise
      .then(() =>
        this.setState({
          isRunning: false,
          progress:
            'Sandbox has been successfully created and all data loaded. You can start using it now!',
          progressStatus: 'success',
          jobId: null,
          isCreated: true,
        }),
      )
      .catch((error) => {
        const isJobError = !!error.runId;
        this.setState({
          isRunning: false,
          progress: 'Load finished with an error.',
          jobId: isJobError ? error.id.toString() : null,
          progressStatus: 'danger',
          isCreated: true,
        });
        if (!isJobError) {
          throw error;
        }
      });
  },

  handleSandboxCreate() {
    this.setState({
      isRunning: true,
      jobId: null,
      progress: 'Waiting for load to start.',
      progressStatus: null,
    });

    this.cancellablePromiseRunComponent = actionCreators
      .runComponent({
        component: TRANSFORMATION,
        notify: false,
        data: this.props.runParams.set('mode', this.state.mode).toJS(),
      })
      .then(this.handleJobReceive)
      .catch((e) => {
        this.setState({
          isRunning: false,
        });
        throw e;
      });
  },

  handleDockerDropCredentials() {
    if (this.isPythonTransformation()) {
      return provisioningActions.dropJupyterSandboxCredentials();
    }

    if (this.isJuliaTransformation()) {
      return provisioningActions.dropJulipyterSandboxCredentials();
    }

    return provisioningActions.dropRStudioSandboxCredentials();
  },

  handleJobReceive(job) {
    if (job.isFinished) {
      if (job.status === 'success') {
        this.setState({
          isRunning: false,
          progress: 'Sandbox has been successfully loaded. You can start using it now.',
          progressStatus: 'success',
          jobId: null,
          isCreated: true,
        });
      } else {
        this.setState({
          isRunning: false,
          progress: 'Load finished with an error.',
          progressStatus: 'danger',
          jobId: job.id.toString(),
          isCreated: true,
        });
      }
    } else {
      this.setState({
        jobId: job.id.toString(),
        progress:
          job.state === 'waiting' ? 'Waiting for load to start.' : 'Loading data into sandbox.',
      });
      setTimeout(this.checkJobStatus, 5000);
    }
  },

  checkJobStatus() {
    if (!this.state.jobId) {
      return;
    }

    this.cancellablePromiseJobDetail = jobsApi
      .getJobDetail(this.state.jobId)
      .then(this.handleJobReceive)
      .catch((e) => {
        this.setState({
          isRunning: false,
        });
        throw e;
      });
  },

  handleModalClose() {
    // reset state
    this.setState(this.getInitialState());
    this.props.onHide();
  },

  isPythonTransformation() {
    return (
      this.props.transformationType === transformationType.PYTHON &&
      this.props.backend === backends.DOCKER
    );
  },

  isJuliaTransformation() {
    return (
      this.props.transformationType === transformationType.JULIA &&
      this.props.backend === backends.DOCKER
    );
  },
});

export default ConfigureSandbox;
