import type { ChangeEvent } from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import type { Map } from 'immutable';
import { List } from 'immutable';

import { HelpBlock } from '@keboola/design';

import Checkbox from '@/react/common/Checkbox';
import CopyPrimaryKeyButton from '@/react/common/CopyPrimaryKeyButton';
import Select from '@/react/common/Select';
import TableSelectorForm from '@/react/common/TableSelectorForm';

type Props = {
  settings: Map<string, any>;
  defaultTable: string;
  onChange: (settings: Map<string, any>) => void;
  tables: Map<string, any>;
  buckets: Map<string, any>;
  disabled?: boolean;
  destinationEditing: boolean;
  onDestinationEdit: () => void;
};

const Settings = (props: Props) => {
  const onChangeDestination = (value: string) => {
    let settings = props.settings.set('destination', value);

    // set primary key if table exists
    if (props.tables.has(value)) {
      settings = settings.set('primaryKey', props.tables.getIn([value, 'primaryKey']));
    } else {
      settings = settings.set('primaryKey', List());
    }
    props.onChange(settings);
  };

  const onDestinationEdit = () => {
    const settings = props.settings;
    props.onChange(settings);
    props.onDestinationEdit();
  };

  const isExistingTable = () => {
    const destinationTable = props.settings.get('destination');
    if (!destinationTable || destinationTable === '') {
      return false;
    }
    return props.tables.has(destinationTable);
  };

  const onChangeIncremental = () => {
    const settings = props.settings.set('incremental', !props.settings.get('incremental', false));
    props.onChange(settings);
  };

  const onChangePrimaryKey = (value: List<any>) => {
    const settings = props.settings.set('primaryKey', value);
    props.onChange(settings);
  };

  const onChangeDelimiter = (e: ChangeEvent<HTMLInputElement>) => {
    const settings = props.settings.set('delimiter', e.target.value);
    props.onChange(settings);
  };

  const onChangeEnclosure = (e: ChangeEvent<HTMLInputElement>) => {
    const settings = props.settings.set('enclosure', e.target.value);
    props.onChange(settings);
  };

  const primaryKeyPlaceholder = () => {
    if (props.disabled || isExistingTable()) {
      return 'Cannot add a column';
    }

    return 'Add a column';
  };

  const renderPrimaryKeyHelpText = () => {
    const sourcePrimaryKey = props.tables.getIn(
      [props.settings.get('destination'), 'primaryKey'],
      List(),
    );

    return (
      <>
        If a primary key is set, updates can be done on the table by selecting{' '}
        <strong>incremental loads</strong>. The primary key can consist of multiple columns.{' '}
        {!sourcePrimaryKey.equals(props.settings.get('primaryKey', List())) && (
          <>
            The primary key must match the primary key in the existing table.{' '}
            {!isExistingTable() && (
              <CopyPrimaryKeyButton primaryKey={sourcePrimaryKey} onChange={onChangePrimaryKey} />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div className="form-horizontal">
      <FormGroup>
        <div className="col-xs-3">
          <ControlLabel>Destination</ControlLabel>
        </div>
        <div className="col-xs-9">
          <TableSelectorForm
            buckets={props.buckets}
            tables={props.tables}
            value={props.settings.get('destination', '')}
            onChange={onChangeDestination}
            disabled={props.disabled ?? false}
            onEdit={onDestinationEdit}
            editing={props.destinationEditing}
          />
        </div>
      </FormGroup>
      <FormGroup>
        <div className="col-xs-9 col-xs-offset-3">
          <Checkbox
            checked={props.settings.get('incremental')}
            onChange={onChangeIncremental}
            disabled={props.disabled}
          >
            Incremental load
          </Checkbox>
          <HelpBlock className="tw-mt-1">
            If incremental load is turned on, the table will be updated instead of rewritten. Tables
            with a primary key will have rows updated, tables without a primary key will have rows
            appended.
          </HelpBlock>
        </div>
      </FormGroup>
      <FormGroup>
        <div className="col-xs-3">
          <ControlLabel>Primary Key</ControlLabel>
        </div>
        <div className="col-xs-9">
          <Select
            name="primaryKey"
            value={props.settings.get('primaryKey')}
            multi
            allowCreate
            placeholder={primaryKeyPlaceholder()}
            onChange={onChangePrimaryKey}
            disabled={props.disabled || isExistingTable()}
          />
          <HelpBlock className="tw-mt-1">{renderPrimaryKeyHelpText()}</HelpBlock>
        </div>
      </FormGroup>
      <FormGroup>
        <div className="col-xs-3">
          <ControlLabel>Delimiter</ControlLabel>
        </div>
        <div className="col-xs-9">
          <FormControl
            type="text"
            value={props.settings.get('delimiter')}
            onChange={onChangeDelimiter}
            disabled={props.disabled}
          />
          <HelpBlock className="tw-mt-1">
            Field delimiter used in the CSV file. The default value is <code>,</code>. Use{' '}
            <code>\t</code> for tabulator.
          </HelpBlock>
        </div>
      </FormGroup>
      <FormGroup>
        <div className="col-xs-3">
          <ControlLabel>Enclosure</ControlLabel>
        </div>
        <div className="col-xs-9">
          <FormControl
            type="text"
            value={props.settings.get('enclosure')}
            onChange={onChangeEnclosure}
            disabled={props.disabled}
          />
          <HelpBlock className="tw-mt-1">Field enclosure used in the CSV file.</HelpBlock>
        </div>
      </FormGroup>
    </div>
  );
};

export default Settings;
