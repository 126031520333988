import type { Service, ServiceId } from './types';

export const getServiceMap = (services: Service[]) =>
  services.reduce(
    (acc, service) => {
      acc[service.id] = service.url;
      return acc;
    },
    {} as Record<ServiceId, string>,
  );
