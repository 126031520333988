import { BackLink } from '@/modules/billing/routes';
import ActivityCenter from './ActivityCenter';
import BillingConsumptionInfo from './BillingConsumptionInfo';
import OrganizationUsageInfo from './OrganizationUsageInfo';
import { RealTimeInfoAlert } from './RealTimeInfoAlert';

export const routeNames = {
  BILLING_CONSUMPTION: 'billing-consumption',
  ORGANIZATION_USAGE: 'organization-usage',
  ACTIVITY_CENTER: 'activity-center',
};

const routes = [
  {
    name: routeNames.BILLING_CONSUMPTION,
    title: 'Project Consumption',
    breadcrumbHandler: BackLink,
    infoAlertHandler: RealTimeInfoAlert,
    defaultRouteHandler: BillingConsumptionInfo,
  },
  {
    name: routeNames.ORGANIZATION_USAGE,
    title: 'Organization Usage',
    infoAlertHandler: RealTimeInfoAlert,
    defaultRouteHandler: OrganizationUsageInfo,
  },
  {
    name: routeNames.ACTIVITY_CENTER,
    title: 'Activity Center',
    infoAlertHandler: RealTimeInfoAlert,
    defaultRouteHandler: ActivityCenter,
  },
];

export default routes;
