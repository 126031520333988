import type { KeyboardEvent, ReactNode } from 'react';

import { keyCodes } from '../constants/keyCodes';
import { cn } from '../utils';

type BadgeVariant =
  | 'gray'
  | 'gray-darker'
  | 'green'
  | 'green-darker'
  | 'green-inverse'
  | 'blue'
  | 'blue-inverse'
  | 'red'
  | 'orange'
  | 'orange-inverse'
  | 'teal'
  | 'purple'
  | 'purple-inverse'
  | 'cyan';

type Placement = 'right' | 'left';

type OptionalProps = {
  placement?: Placement;
  variant?: BadgeVariant;
  asTag?: boolean;
  className?: string;
  onClick?: () => void;
};

type ContentExclusiveProps =
  | { text: string; children?: never }
  | { children: ReactNode; text?: never };

export type Props = ContentExclusiveProps & OptionalProps;

const BASIC_STYLES = {
  display: 'tw-inline-flex tw-justify-center tw-items-center tw-h-fit tw-shrink-0',
  text: 'tw-text-xs tw-font-medium tw-tracking-[1px] tw-text-white tw-whitespace-pre-wrap tw-text-center tw-underline',
  content: '[&>svg]:tw-self-center',
};

const TYPE_STYLE = {
  tag: 'tw-rounded tw-px-1 tw-py-0.5 tw-normal-case',
  pill: 'tw-rounded tw-px-2 tw-py-0.5 tw-uppercase',
};

export const Badge = ({
  variant = 'gray',
  asTag,
  placement,
  className,
  children,
  text,
  onClick,
}: Props) => {
  const tailwindColorClasses = getTailwindColorClassesFromType(variant);

  return (
    <span
      {...(onClick
        ? {
            role: 'button',
            tabIndex: 0,
            onClick: onClick,
            onKeyUp: (e: KeyboardEvent) => {
              if ([keyCodes.ENTER, keyCodes.SPACE].includes(e.key)) {
                e.preventDefault();
                onClick();
              }
            },
          }
        : {})}
      className={cn(
        BASIC_STYLES.display,
        BASIC_STYLES.text,
        BASIC_STYLES.content,
        asTag ? TYPE_STYLE.tag : TYPE_STYLE.pill,
        tailwindColorClasses,
        {
          'tw-ml-2': placement === 'right',
          'tw-mr-2': placement === 'left',
          'tw-cursor-pointer': onClick,
        },
        className,
      )}
    >
      {children ?? text}
    </span>
  );
};

const getTailwindColorClassesFromType = (type: OptionalProps['variant'] = 'gray') => {
  switch (type) {
    case 'gray':
      return 'tw-bg-neutral-400 tw-decoration-neutral-400';
    case 'gray-darker':
      return 'tw-bg-neutral-500 tw-decoration-neutral-500';
    case 'green':
      return 'tw-bg-primary-500 tw-decoration-primary-500';
    case 'green-darker':
      return 'tw-bg-primary-600 tw-decoration-primary-600';
    case 'green-inverse':
      return 'tw-bg-primary-100 tw-text-primary-900 tw-decoration-primary-100';
    case 'blue':
      return 'tw-bg-secondary-500 tw-decoration-secondary-500';
    case 'blue-inverse':
      return 'tw-bg-secondary-100 tw-text-secondary-600 tw-decoration-secondary-100';
    case 'red':
      return 'tw-bg-error-500 tw-decoration-error-500';
    case 'orange':
      return 'tw-bg-warning-500 tw-decoration-warning-500';
    case 'orange-inverse':
      return 'tw-bg-warning-100 tw-text-warning-500 tw-decoration-warning-100';
    case 'teal':
      return 'tw-bg-teal-500 tw-decoration-teal-500';
    case 'purple':
      return 'tw-bg-purple-500 tw-decoration-purple-500';
    case 'purple-inverse':
      return 'tw-bg-purple-100 tw-text-purple-800 tw-decoration-purple-100';
    case 'cyan':
      return 'tw-bg-cyan-500 tw-decoration-cyan-500';
    default:
      return '';
  }
};
