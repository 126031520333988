import { lazy, Suspense, useCallback, useState } from 'react';
import type { SyntheticEvent } from 'react';
import { ErrorBoundary } from '@sentry/react';
import type { List } from 'immutable';
import { Map } from 'immutable';

import { Button, cn, Icon, Tooltip } from '@keboola/design';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { canDeleteDevBranch, canManageDevBranch } from '@/modules/admin/privileges';
import { useAutomationId } from '@/modules/automations/useAutomationId';
import { hasActivityCenter, hasOrganizationUsage } from '@/modules/billing/helpers';
import BillingStore from '@/modules/billing/store';
import PlatformNews from '@/modules/components/react/components/PlatformNews';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { allowedTypes } from '@/modules/components-directory/constants';
import CreateDevBranchModal from '@/modules/dev-branches/components/CreateDevBranchModal';
import DeleteDevBranchModal from '@/modules/dev-branches/components/DeleteDevBranchModal';
import UpdateDevBranchModal from '@/modules/dev-branches/components/UpdateDevBranchModal';
import { routeNames as devBranchesRouteNames } from '@/modules/dev-branches/constants';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { prepareBranchHref } from '@/modules/dev-branches/helpers';
import JobsStore from '@/modules/jobs/stores/JobsStore';
import LineageButton from '@/modules/lineage/CombinedLineage/LineageButton';
import SettingsStore from '@/modules/settings/SettingsStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import { routeNames as trashRouteNames } from '@/modules/trash/constants';
import Vault from '@/modules/vault/Vault';
import { RouterLink as Link } from '@/react/common';
import { ErrorBoundaryFallback } from '@/react/common/ErrorBoundaryFallback';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { CurrentUser } from './CurrentUser';
import { resolveComponent } from './helpers';
import Logo from './Logo';
import Navigation from './Navigation';
import ProjectSelect from './ProjectSelect';
import PullFromProductionModal from './PullFromProductionModal';

const GlobalSearch = lazy(
  () =>
    import(/* webpackChunkName: "global-search" */ '../../layout/search/newSearch/GlobalSearch'),
);

const GlobalSearchLegacy = lazy(
  () => import(/* webpackChunkName: "global-search-legacy" */ '../../layout/search/GlobalSearch'),
);

type Props = {
  user: Map<string, any>;
  urlTemplates: Map<string, any>;
  currentProject: Map<string, any>;
  currentOrganization: Map<string, any>;
  projectTemplates: List<any>;
  organizations: List<any>;
  canManageApps: boolean;
};

type ModalType = 'createDevBranch' | 'editDevBranch' | 'deleteDevBranch' | 'pullProduction' | null;

const TopBarNav = (props: Props) => {
  const [showModal, setShowModal] = useState<ModalType>(null);
  const [devBranchId, setDevBranchId] = useState('');

  const store = useStores(
    () => {
      const realComponentId = RoutesStore.getCurrentRouteComponentId();
      const currentRouteConfig = RoutesStore.getCurrentRouteConfig();
      const routerState = RoutesStore.getRouterState();
      const sapiToken = ApplicationStore.getSapiToken();
      const project = SettingsStore.getProject() as Map<any, any>;

      return {
        project,
        sapiToken,
        routerState,
        realComponentId,
        currentRouteConfig,
        component: resolveComponent(),
        router: RoutesStore.getRouter(),
        routerError: RoutesStore.getError(),
        breadcrumbs: RoutesStore.getBreadcrumbs(),
        currentRouteParams: routerState.get('params', Map()),
        currentLocationQuery: routerState.getIn(['location', 'query'], Map()),
        hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
        hasDataApps: ApplicationStore.hasDataApps(),
        stackId: ApplicationStore.getCurrentStackId() as string,
        isMarketplaceProject: BillingStore.isMarketplaceProject(),
        hasNewTransformationsOnly: ApplicationStore.hasNewTransformationsOnly(),
        hasShowTransformationMigration: ApplicationStore.hasShowTransformationMigration(),
        hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
        hasInvalidCustomBackend: ApplicationStore.hasInvalidCustomBackend(),
        hasNewGlobalSearch: ApplicationStore.hasGlobalSearch(),
        hasVault: ApplicationStore.hasVault(),
        hasNewQueue: ApplicationStore.hasNewQueue(),
        hasFlows: ApplicationStore.hasFlows(),
        hasFlowsOnly: ApplicationStore.hasFlowsOnly(),
        hasTemplates: ApplicationStore.hasTemplates(),
        devBranches: DevBranchesStore.getAll() as Map<string, any>,
        mergeRequests: DevBranchesStore.getMergeRequests() as Map<string, any>,
        currentDevBranchId: DevBranchesStore.getCurrentId() as string | null,
        isDevModeActive: DevBranchesStore.isDevModeActive(),
        readOnly: ApplicationStore.isReadOnly(),
        isDemoPreview: ApplicationStore.isDemoPreview() as boolean,
        projectBaseUrl: ApplicationStore.getProjectBaseUrl(),
        canManageDevBranch: canManageDevBranch(sapiToken),
        canDeleteDevBranch: canDeleteDevBranch(sapiToken),
        hasAllowedAi: StackFeaturesStore.hasAllowedAi(),
        allFlows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
        hasConfigurations: !InstalledComponentsStore.getAll()
          .filter((component: Map<string, any>) => allowedTypes.includes(component.get('type')))
          .isEmpty(),
        hasAiLineage: ApplicationStore.hasAiLineage(),
      };
    },
    [],
    [
      JobsStore,
      RoutesStore,
      ApplicationStore,
      StackFeaturesStore,
      DevBranchesStore,
      BillingStore,
      SettingsStore,
      InstalledComponentsStore,
    ],
  );
  const isAutomationView = !!useAutomationId();

  const handleToggleNavigation = useCallback(() => {
    return document.querySelector('.navbar-collapse')?.classList.toggle('in');
  }, []);

  const handleCreateDevBranch = useCallback(
    (name: string, desciption: string) => {
      return StorageActionCreators.createDevBranch(name, desciption).then((branchId) => {
        window.location.href = prepareBranchHref(
          store.projectBaseUrl,
          location.pathname,
          branchId,
          store.currentDevBranchId,
        );
      });
    },
    [store.projectBaseUrl, store.currentDevBranchId],
  );

  const openModal = useCallback((event: SyntheticEvent, modal: ModalType) => {
    event.preventDefault();

    setShowModal(modal);
  }, []);

  const closeModal = useCallback(() => setShowModal(null), []);

  const openCreateDevBranchModal = useCallback(
    (event: SyntheticEvent) => openModal(event, 'createDevBranch'),
    [openModal],
  );

  const openEditDevBranchModal = useCallback(
    (event: SyntheticEvent, devBranchId: string) => {
      openModal(event, 'editDevBranch');
      setDevBranchId(devBranchId);
    },
    [openModal],
  );

  const openDeleteDevBranchModal = useCallback(
    (event: SyntheticEvent, devBranchId: string) => {
      openModal(event, 'deleteDevBranch');
      setDevBranchId(devBranchId);
    },
    [openModal],
  );

  const closeDeleteDevBranchModal = useCallback(() => {
    closeModal();
    setDevBranchId('');
  }, [closeModal]);

  const renderDevelopmentBranchUI = () => {
    if (!store.isDevModeActive) {
      return null;
    }

    const routeName = store.routerState.getIn(['routes', -1, 'name'], '');

    return (
      <>
        {routeName !== devBranchesRouteNames.DEVELOPMENT_OVERVIEW && (
          <Link
            to={devBranchesRouteNames.DEVELOPMENT_OVERVIEW}
            className="btn btn-success btn-sm color-white ml-1"
          >
            Branch Diff
          </Link>
        )}
        <Button
          size="small"
          variant="outline"
          onClick={(e: SyntheticEvent) => openModal(e, 'pullProduction')}
          className="ml-1"
        >
          Pull from prod
        </Button>
        {showModal === 'pullProduction' && <PullFromProductionModal onClose={() => closeModal()} />}
      </>
    );
  };

  const renderBin = () => {
    if (store.hasProtectedDefaultBranch && !store.isDevModeActive) {
      return null;
    }

    return (
      <Tooltip tooltip="Recently Deleted" placement="bottom">
        <Link to={trashRouteNames.SETTINGS_TRASH} title="Recently Deleted">
          <Icon icon="trash" />
        </Link>
      </Tooltip>
    );
  };

  return (
    <ErrorBoundary fallback={() => <ErrorBoundaryFallback />}>
      <div
        className={cn('container-topbar navbar navbar-default', {
          'tw-pointer-events-none tw-brightness-[0.6]': isAutomationView,
        })}
      >
        <div className="topbar">
          <div className="container">
            <div className="topbar-left">
              <Logo
                isDevModeActive={store.isDevModeActive}
                mergeRequests={store.mergeRequests}
                currentDevBranchId={store.currentDevBranchId}
              />
              <ProjectSelect
                organizations={props.organizations}
                currentProject={props.currentProject}
                currentOrganization={props.currentOrganization}
                urlTemplates={props.urlTemplates}
                projectTemplates={props.projectTemplates}
                devBranches={store.devBranches}
                mergeRequests={store.mergeRequests}
                hasProtectedDefaultBranch={store.hasProtectedDefaultBranch}
                currentDevBranchId={store.currentDevBranchId}
                openCreateDevBranchModalHandler={openCreateDevBranchModal}
                openEditDevBranchModalHandler={openEditDevBranchModal}
                openDeleteDevBranchModalHandler={openDeleteDevBranchModal}
                isDevModeActive={store.isDevModeActive}
                isDemoPreview={store.isDemoPreview}
                hasInvalidCustomBackend={store.hasInvalidCustomBackend}
                readOnly={!store.canManageDevBranch}
                canDeleteDevBranch={store.canDeleteDevBranch}
                projectBaseUrl={store.projectBaseUrl}
                currentUser={props.user}
              />
              {renderDevelopmentBranchUI()}
            </div>
            <div className="topbar-right">
              {store.hasNewQueue && (
                <Suspense fallback={null}>
                  {store.hasNewGlobalSearch ? <GlobalSearch /> : <GlobalSearchLegacy />}
                </Suspense>
              )}
              {!store.isDemoPreview && (
                <>
                  {!store.hasInvalidCustomBackend && (
                    <div className="topbar-navbar">
                      {store.hasAiLineage &&
                        store.hasAllowedAi &&
                        store.hasFlows &&
                        !store.isDevModeActive && (
                          <LineageButton
                            currentProjectId={props.currentProject.get('id', null)}
                            currentProjectName={props.currentProject.get('name', null)}
                          />
                        )}
                      {store.hasVault && <Vault />}
                      {renderBin()}
                      {!store.isDevModeActive && <PlatformNews />}
                    </div>
                  )}
                  <CurrentUser
                    user={props.user}
                    urlTemplates={props.urlTemplates}
                    canManageApps={props.canManageApps}
                    hasPayAsYouGo={store.hasPayAsYouGo}
                    isMarketplaceProject={store.isMarketplaceProject}
                    hasInvalidCustomBackend={store.hasInvalidCustomBackend}
                    hasActivityCenter={hasActivityCenter(store.project)}
                    hasOrganizationUsage={hasOrganizationUsage(store.project, store.stackId)}
                  />
                </>
              )}
              <button onClick={handleToggleNavigation} type="button" className="navbar-toggle">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>
          </div>
        </div>
        <div className="main-navbar">
          <div className="container">
            <Navigation
              showNewTransformations={
                store.hasNewTransformationsOnly || store.hasShowTransformationMigration
              }
              hideOldTransformations={store.hasNewTransformationsOnly}
              hasNewQueue={store.hasNewQueue}
              sapiToken={store.sapiToken}
              readOnly={store.readOnly}
              hasPayAsYouGo={store.hasPayAsYouGo}
              isDevModeActive={store.isDevModeActive}
              hasInvalidCustomBackend={store.hasInvalidCustomBackend}
              hasConfigurations={store.hasConfigurations}
              hasFlowsOnly={store.hasFlowsOnly}
              hasFlows={store.hasFlows}
              hasTemplates={store.hasTemplates}
              isDemoPreview={store.isDemoPreview}
              hasDataApps={store.hasDataApps}
            />
          </div>
        </div>
      </div>
      <CreateDevBranchModal
        show={showModal === 'createDevBranch'}
        onSubmit={handleCreateDevBranch}
        onHide={() => closeModal()}
        devBranches={store.devBranches}
      />
      <UpdateDevBranchModal
        show={showModal === 'editDevBranch'}
        onSubmit={StorageActionCreators.updateDevBranch}
        onHide={() => closeModal()}
        devBranch={store.devBranches.get(devBranchId, Map()) as Map<string, any>}
        devBranches={store.devBranches}
      />
      <DeleteDevBranchModal
        show={showModal === 'deleteDevBranch'}
        name={store.devBranches.getIn([devBranchId, 'name'], '')}
        onSubmit={() => StorageActionCreators.deleteDevBranch(devBranchId)}
        onHide={closeDeleteDevBranchModal}
      />
    </ErrorBoundary>
  );
};

export default TopBarNav;
