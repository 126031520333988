import type { ReactNode } from 'react';

import escapeStringRegexp from '@/utils/escapeStringRegexp';

type Props = {
  source: string;
  className?: string;
  mark?: string;
  isCaseSensitive?: boolean;
};

const MarkedText = ({ source, mark, className, isCaseSensitive }: Props) => {
  if (!mark || !mark.trim()) {
    return <span className={className}>{source}</span>;
  }

  const parts: ReactNode[] = source.split(
    new RegExp(
      '(' + escapeStringRegexp(mark).split(' ').filter(Boolean).join('|') + ')',
      `g${isCaseSensitive ? '' : 'i'}`,
    ),
  );

  for (let i = 1; i < parts.length; i += 2) {
    parts[i] = <mark key={i}>{parts[i]}</mark>;
  }

  return <span className={className}>{parts}</span>;
};

export default MarkedText;
