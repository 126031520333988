import { cn, Label, Switch, Tooltip } from '@keboola/design';

import { canManageProjectFeatures } from '@/modules/admin/privileges';
import { BadgeBeta } from '@/react/common/Badges';
import MarkedText from '@/react/common/MarkedText';
import ApplicationStore from '@/stores/ApplicationStore';
import type { FeatureVariant, FeFeature } from './types';

export type OnFeatureToggleHandler = (checked: boolean, feature: FeFeature) => void;

type Props = {
  data: FeFeature;
  searchQuery?: string;
  variant: FeatureVariant;
  onToggle?: OnFeatureToggleHandler;
  isLoading?: boolean | ((feature: FeFeature) => boolean);
};

export const FeatureSwitch = ({
  data,
  searchQuery,
  variant,
  onToggle,
  isLoading = false,
}: Props) => {
  const isAdminFeatureVariant = variant === 'admin';
  const isProjectFeatureVariant = variant === 'project';
  const isFeatureManagementAllowed = canManageProjectFeatures(ApplicationStore.getSapiToken());

  const onChange = async (value: boolean) => {
    onToggle?.(value, data);
  };

  const resolvedIsLoading = typeof isLoading === 'function' ? isLoading(data) : isLoading;

  return (
    <div
      className={cn('tw-grid tw-items-start tw-gap-2 tw-font-sans tw-grid-cols-1 ', {
        'xl:tw-grid-cols-2 2xl:tw-grid-cols-3': isProjectFeatureVariant,
      })}
    >
      <div className="tw-flex tw-items-center tw-gap-4">
        <Tooltip tooltip={`${data.isActive ? 'Deactivate' : 'Activate'} Feature`} placement="top">
          <Switch
            checked={data.isActive}
            disabled={resolvedIsLoading || !isFeatureManagementAllowed}
            onChange={onChange}
            id={data.id}
          />
        </Tooltip>

        <Label className="tw-font-medium" htmlFor={data.id}>
          <MarkedText source={data.title} mark={searchQuery} />
        </Label>

        {data.isBeta && <BadgeBeta />}
      </div>

      <p
        className={cn('tw-m-0 tw-text-sm tw-text-neutral-500', {
          'tw-ml-12 tw-text-xs': isAdminFeatureVariant,
        })}
      >
        <MarkedText source={data.description} mark={searchQuery} />
      </p>
    </div>
  );
};
