import type { ComponentProps, ReactNode } from 'react';

import type { TooltipProps } from '@keboola/design';

import { Button } from '../Button/Button';
import { Icon } from '../Icon';

import { Clipboard } from './Clipboard';

export const ClipboardButton = ({
  text,
  label = 'Copy To Clipboard',
  className,
  tooltipType,
  tooltipText,
  tooltipPlacement,
}: {
  text?: string;
  label?: ReactNode;
  className?: string;
  disabled?: ComponentProps<typeof Button>['disabled'];
  tooltipType?: TooltipProps['type'];
  tooltipText?: TooltipProps['tooltip'];
  tooltipPlacement?: TooltipProps['placement'];
}) => {
  return (
    <Clipboard
      text={text}
      tooltipType={tooltipType}
      tooltipText={tooltipText}
      tooltipPlacement={tooltipPlacement}
      className={className}
    >
      <Button variant="outline" disabled={!text}>
        <Icon icon="copy" />
        {label}
      </Button>
    </Clipboard>
  );
};
