import { useState } from 'react';
import type { ChangeEvent } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import { type Map } from 'immutable';
import _ from 'underscore';

import { Badge, Icon } from '@keboola/design';

import ComponentsActionCreators from '@/modules/components/ComponentsActionCreators';
import { resolveRouterLinkParams } from '@/modules/components/helpers';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import { routeNames } from '@/modules/components-directory/constants';
import CodeEditor from '@/react/common/CodeEditor';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import FullScreenEditor from '@/react/common/FullScreenEditor';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';
import { isValidJsonConfig } from '@/utils/validation';

const EDITABLE_PROPERTIES = [
  'flags',
  'configurationSchema',
  'configurationRowSchema',
  'emptyConfiguration',
  'emptyConfigurationRow',
  'createConfigurationRowSchema',
  'description',
  'configurationDescription',
  'dataTypesConfiguration',
  'processorConfiguration',
  'data',
];

const prepareComponent = (component: Map<string, any>) => {
  return JSON.stringify(_.pick(component.toJS(), ...EDITABLE_PROPERTIES), null, 2);
};

const GenericComponentRaw = () => {
  const { component, hasOverrides } = useStores(
    () => {
      const componentId = RoutesStore.getCurrentRouteComponentId();

      return {
        component: ComponentsStore.getComponent(componentId),
        hasOverrides: ComponentsStore.hasOverrides(componentId),
      };
    },
    [],
    [RoutesStore, ComponentsStore],
  );
  const [value, setValue] = useState(prepareComponent(component));
  const [tag, setTag] = useState(component.get('tag', ''));

  const isInvalidOverride = () => {
    if (!isValidJsonConfig(value)) {
      return true;
    }

    return (
      EDITABLE_PROPERTIES.length !==
      Object.keys(_.pick(JSON.parse(value), ...EDITABLE_PROPERTIES)).length
    );
  };

  return (
    <FullScreenEditor
      disableEscClose
      renderTitle={() => {
        return (
          <>
            <ComponentIcon component={component} size="40" className="icon-addon-right" />
            <ComponentName component={component} />
            {hasOverrides && <HasOverridesLabel componentId={component.get('id')} />}
          </>
        );
      }}
      renderEditor={() => {
        return (
          <>
            <FormControl
              type="text"
              className="tw-mb-4"
              placeholder="Component tag, keep empty to use production version"
              value={tag}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                setTag(event.target.value);
              }}
            />
            <CodeEditor autoFocus value={value} onChange={setValue} />
          </>
        );
      }}
      renderButtons={() => {
        const isChanged = value !== prepareComponent(component) || tag !== component.get('tag', '');

        if (!isChanged) {
          return null;
        }

        return (
          <>
            <Button
              onClick={() => {
                setValue(prepareComponent(component));
                setTag(component.get('tag', ''));
              }}
            >
              <Icon icon="arrow-rotate-left" className="icon-addon-right" />
              Reset
            </Button>
            <Button
              bsStyle="success"
              onClick={() => {
                ComponentsActionCreators.saveComponentOverrides(component.get('id'), {
                  ...JSON.parse(value),
                  tag,
                });
              }}
              disabled={isInvalidOverride()}
            >
              <Icon icon="check-circle" className="icon-addon-right" />
              Apply
            </Button>
            <span className="btn-separator" />
          </>
        );
      }}
      onClose={() => {
        const linkParams = resolveRouterLinkParams(component.get('id'));

        if (linkParams) {
          RoutesStore.getRouter().transitionTo(linkParams.to, linkParams.params);
        }
      }}
    />
  );
};

export const HasOverridesLabel = (props: { componentId: string }) => {
  return (
    <Badge
      text="Component Overrides"
      variant="purple"
      placement="right"
      onClick={() => {
        RoutesStore.getRouter().transitionTo(routeNames.COMPONENT_RAW, {
          component: props.componentId,
        });
      }}
    />
  );
};

export default GenericComponentRaw;
