import BackLink from './react/BackLink';
import { Credits } from './react/Credits';
import { Overview } from './react/Overview';
import {
  getBillingConfiguration,
  getBillingInformation,
  getCredits,
  getPurchases,
  getTopUpSetting,
} from './actions';
import { routeNames } from './constants';

const loadCommonData = () => {
  return Promise.all([
    getPurchases(),
    getBillingConfiguration(),
    getBillingInformation(),
    getTopUpSetting(),
  ]);
};

const routes = [
  {
    name: routeNames.BILLING_OVERVIEW,
    title: 'Overview',
    subtitle: 'Billing',
    defaultRouteHandler: Overview,
    requireData: [() => loadCommonData(), () => getCredits()],
  },
  {
    name: routeNames.BILLING_MINUTES,
    title: 'Add more minutes',
    breadcrumbHandler: BackLink,
    defaultRouteHandler: Credits,
    requireData: [loadCommonData],
  },
];

export { BackLink };

export default routes;
