import { List } from 'immutable';

import { KEBOOLA_NO_CODE_DBT_TRANSFORMATION } from '@/constants/componentIds';
import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const NoCodeHeaderButtons = () => {
  const { componentId, configId, configData } = useStores(
    () => {
      const componentId = RoutesStore.getCurrentRouteComponentId();
      const configId = RoutesStore.getCurrentRouteParam('configId');

      return {
        componentId,
        configId,
        configData: InstalledComponentsStore.getConfigData(componentId, configId),
      };
    },
    [],
    [ApplicationStore, InstalledComponentsStore, RoutesStore],
  );

  if (componentId !== KEBOOLA_NO_CODE_DBT_TRANSFORMATION) return null;

  const noDataManimulations = configData.getIn(['parameters', 'models'], List()).isEmpty();

  return (
    <RunComponentButton
      componentId={KEBOOLA_NO_CODE_DBT_TRANSFORMATION}
      runParams={() => ({ config: configId })}
      label="Run Transformation"
      runLabel="Run Transformation"
      buttonIcon="circle-play"
      buttonBsStyle="success"
      disabled={noDataManimulations}
      disabledReason={noDataManimulations ? 'No data manipulations configured' : ''}
    >
      You are about to run the component.
    </RunComponentButton>
  );
};

export default NoCodeHeaderButtons;
