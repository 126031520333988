import { cn } from '@keboola/design';

const LineageIcon = ({ className }: { className?: string }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn('tw-text-neutral-400', className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 5.75C2.75 4.50736 3.75736 3.5 5 3.5H14C15.2426 3.5 16.25 4.50736 16.25 5.75V5.77921C19.3609 5.93187 21.379 6.68774 22.5436 8.16294C23.1916 8.98378 23.4819 9.91762 23.6187 10.8206C23.7019 11.3696 23.7324 11.9413 23.7436 12.5H27C28.2426 12.5 29.25 13.5074 29.25 14.75V17.25C29.25 18.4926 28.2426 19.5 27 19.5H23.7436C23.7324 20.0587 23.7019 20.6304 23.6187 21.1794C23.4819 22.0824 23.1916 23.0162 22.5436 23.8371C21.379 25.3123 19.3609 26.0681 16.25 26.2208V26.25C16.25 27.4926 15.2426 28.5 14 28.5H5C3.75736 28.5 2.75 27.4926 2.75 26.25V23.75C2.75 22.5074 3.75736 21.5 5 21.5H14C15.2318 21.5 16.2324 22.4898 16.2498 23.7175C18.9898 23.5661 20.0891 22.9115 20.5814 22.2879C20.8709 21.9213 21.0494 21.4489 21.1469 20.8049C21.2075 20.405 21.2325 19.9751 21.2428 19.5H18C16.7574 19.5 15.75 18.4926 15.75 17.25V14.75C15.75 13.5074 16.7574 12.5 18 12.5H21.2428C21.2325 12.0249 21.2075 11.595 21.1469 11.1951C21.0494 10.5511 20.8709 10.0787 20.5814 9.71206C20.0891 9.08853 18.9898 8.43387 16.2498 8.28251C16.2324 9.51016 15.2318 10.5 14 10.5H5C3.75736 10.5 2.75 9.49264 2.75 8.25V5.75ZM5.25 6V8H13.75V6H5.25ZM18.25 15V17H26.75V15H18.25ZM5.25 26V24H13.75V26H5.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default LineageIcon;
