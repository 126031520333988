import { Children, cloneElement, Component } from 'react';
import PropTypes from 'prop-types';

class MappingsWrapper extends Component {
  render() {
    return Children.toArray(this.props.children).map((child, index, children) => {
      // if current mapping is not empty or next mapping is empty, do not add bottom radius
      if (!child.props.value?.isEmpty() || children[index + 1]?.props.value?.isEmpty()) {
        return child;
      }

      return cloneElement(child, child.props);
    });
  }
}

MappingsWrapper.propTypes = {
  children: PropTypes.node,
};

export default MappingsWrapper;
