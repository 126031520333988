import createReactClass from 'create-react-class';
import { List } from 'immutable';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import SaveButtons from '@/react/common/SaveButtons';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { updateOrchestrationConfiguration } from './actions';
import { prepareConfigurationForSave } from './helpers';
import { clearLocalState, getLocalStateValue } from './localState';

const TasksHeader = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, InstalledComponentsStore)],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const config = InstalledComponentsStore.getConfig(KEBOOLA_ORCHESTRATOR, configId);
    const tasksFromConfig = config.getIn(['configuration', 'tasks'], List());
    const phasesFromConfig = config.getIn(['configuration', 'phases'], List());
    const tasks = getLocalStateValue(configId, ['tasks'], tasksFromConfig);
    const phases = getLocalStateValue(configId, ['phases'], phasesFromConfig);

    return {
      configId,
      config,
      phases,
      tasks,
      isChanged: !tasks.equals(tasksFromConfig) || !phases.equals(phasesFromConfig),
      readOnly: ApplicationStore.isReadOnly(),
    };
  },

  getInitialState() {
    return {
      isSaving: false,
    };
  },

  _handleSave() {
    this.setState({
      isSaving: true,
    });
    return updateOrchestrationConfiguration(
      this.state.configId,
      prepareConfigurationForSave(
        this.state.config.get('configuration'),
        this.state.phases,
        this.state.tasks,
      ),
      'Update orchestration tasks',
    )
      .then(() => {
        this.resetEditingTasks();
      })
      .finally(() => {
        this.setState({
          isSaving: false,
        });
      });
  },

  resetEditingTasks() {
    clearLocalState(this.state.configId);
  },

  render() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <SaveButtons
        onReset={this.resetEditingTasks}
        isSaving={this.state.isSaving}
        onSave={this._handleSave}
        isChanged={this.state.isChanged}
      />
    );
  },
});

export default TasksHeader;
