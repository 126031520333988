import { useMemo, useState } from 'react';
import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import { cn, Icon, Search, Tooltip } from '@keboola/design';

import { sortEntities } from '@/constants';
import { useAllowedProjectIds, useSorter } from '@/hooks';
import { canUnlinkBucket } from '@/modules/admin/privileges';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import StorageApiBucketLinkEx from '@/modules/components/react/components/StorageApiBucketLinkEx';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import { routeNames } from '@/modules/data-catalog/constants';
import {
  getDescriptionWithDefaultValue,
  prepareSharedBuckets,
} from '@/modules/data-catalog/helpers';
import type { BucketColumn, FlatTableRow } from '@/modules/data-catalog/types';
import { getFilteredSortedBuckets } from '@/modules/data-catalog/utils';
import { getInitialSort, saveSort, sortKeys } from '@/modules/data-catalog/utils/sortStorage';
import { getDescriptionValue } from '@/modules/storage/helpers';
import { CircleIcon, DisableTooltip, RouterLink, Truncated } from '@/react/common';
import MarkedText from '@/react/common/MarkedText';
import { DEFAULT_BUCKET_COLOR } from '@/react/constants';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import string from '@/utils/string';
import { lastModified, ownerColumn, sizeColumn } from './tableComponents/tableColumns';
import { DataCatalogTable } from './DataCatalogTable';
import { FilterButton } from './FilterButton';
import { FilterButtons } from './FilterButtons';
import LinkButton from './LinkButton';
import { SharedLabel, SortableHeader } from './tableComponents';
import { TabNavigation } from './TabNavigation';
import UnlinkButton from './UnlinkButton';

const SharedWithYou = () => {
  const [query, setQuery] = useState(
    RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
  );
  const [searchFilters, setSearchFilters] = useState<Map<string, any>>(Map());

  const { sort, sorter, setSort } = useSorter(
    fromJS(getInitialSort(sortKeys.SHARED_WITH_YOU)),
    (newSort) => saveSort(sortKeys.SHARED_WITH_YOU, newSort),
  );

  const {
    admins,
    currentAdmin,
    currentProjectId,
    sapiToken,
    urlTemplates,
    allBuckets,
    sharedBuckets,
    linkedBuckets,
  } = useStores(
    () => ({
      admins: ApplicationStore.getAdmins(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      currentProjectId: ApplicationStore.getCurrentProjectId(),
      sapiToken: ApplicationStore.getSapiToken(),
      urlTemplates: ApplicationStore.getUrlTemplates(),
      allBuckets: StorageBucketsStore.getAll(),
      sharedBuckets: StorageBucketsStore.getSharedBuckets(),
      linkedBuckets: StorageBucketsStore.getLinkedBuckets(),
    }),
    [],
    [ApplicationStore, StorageBucketsStore],
  );

  const allowedProjectIds = useAllowedProjectIds();
  const sharedAndLinkedBuckets: Map<string, any> = useMemo(() => {
    return prepareSharedBuckets(sharedBuckets, linkedBuckets, currentProjectId, query);
  }, [sharedBuckets, linkedBuckets, currentProjectId, query]);

  const columns = useMemo<BucketColumn[]>(
    () => [
      {
        id: 'displayName',
        header: () => (
          <SortableHeader
            label="Name"
            entityKey={sortEntities.NAME}
            arrowSide="right"
            sort={sort}
            setSort={setSort}
          />
        ),
        cell: ({ row }: { row: FlatTableRow }) => {
          const bucket = row.original;
          const bucketColor = bucket.get('color');
          const metadata = bucket.get('metadata', List());
          const displayName = bucket.get('displayName');
          const linkedBucket = bucket.get('linkedBucket');

          const description =
            getDescriptionValue(metadata, MetadataKeys.SHARED_DESCRIPTION) ||
            getDescriptionValue(metadata, MetadataKeys.DESCRIPTION);

          const unsharedLinkedBucket =
            !!linkedBucket &&
            canUnlinkBucket(sapiToken, linkedBucket) &&
            !sharedBuckets.some((sharedBucket) => sharedBucket.get('id') === bucket.get('id'));

          return (
            <div className={cn('tw-inline-flex tw-items-center')}>
              <CircleIcon icon="folder" backgroundColor={bucketColor ?? DEFAULT_BUCKET_COLOR} />

              <div className={cn('tw-items-center tw-flex-col tw-px-3')}>
                <div className={cn('tw-inline-flex tw-items-center')}>
                  {unsharedLinkedBucket && (
                    <Tooltip
                      placement="top"
                      type="explanatory"
                      tooltip={
                        <>
                          This bucket is no longer shared
                          <br /> with you. If you unlink it, you
                          <br /> won&apos;t be able to link it back.
                        </>
                      }
                      triggerClassName="tw-inline"
                    >
                      <Icon icon="triangle-exclamation" className="f-15 text-warning tw-mr-1" />
                    </Tooltip>
                  )}
                  <Truncated
                    tooltip={displayName}
                    text={<MarkedText source={displayName} mark={query} />}
                  />
                </div>
                <div className="text-muted tw-w-[280px]">
                  {linkedBucket ? (
                    <>
                      Linked as{' '}
                      <StorageApiBucketLinkEx
                        bucketId={linkedBucket.get('id')}
                        hideLabels
                        truncateBucketName
                        truncateLimit={25}
                      />
                    </>
                  ) : (
                    <div className="tw-truncate">{string.truncate(description, 60)}</div>
                  )}
                </div>
              </div>
            </div>
          );
        },
      },
      ownerColumn({ admins, sort, setSort }),
      sizeColumn({ sort, setSort }),
      {
        id: 'shared',
        header: () => (
          <SortableHeader
            label="Shared from"
            entityKey={sortEntities.SHARED}
            sort={sort}
            setSort={setSort}
          />
        ),
        cell: ({ row }: { row: FlatTableRow }) => {
          const bucket = row.original;
          const sharing = bucket.get('sharing');
          const projectId = bucket.getIn(['project', 'id']);
          const sharingParameters = bucket.get('sharingParameters');
          const { projects, users } = sharingParameters.toJS();
          const isDisabled = !allowedProjectIds.has(Number(projectId));

          return (
            <div className="tw-text-right">
              <RouterLink
                to={_.template(urlTemplates.get('project'))({ projectId })}
                disabled={isDisabled}
              >
                <Tooltip
                  key={projectId}
                  placement="top"
                  type="explanatory"
                  forceHide={!isDisabled}
                  tooltip={<DisableTooltip />}
                  triggerClassName="tw-flex tw-items-center tw-justify-end"
                >
                  <div className={isDisabled ? 'text-muted' : ''}>
                    <Truncated text={bucket.getIn(['project', 'name'])} />
                  </div>
                  {isDisabled && (
                    <Icon icon="lock" className="tw-mb-0.5 tw-ml-2 tw-text-neutral-400" />
                  )}
                </Tooltip>
              </RouterLink>
              <div className="text-muted">
                <SharedLabel projects={projects} users={users} sharing={sharing} />
              </div>
            </div>
          );
        },
      },
      lastModified({ sort, setSort }),
      {
        id: 'actions',
        header: '',
        cell: ({ row }: { row: FlatTableRow }) => {
          const linkedBucket = row.original.get('linkedBucket');
          return (
            <div className="tw-flex tw-justify-end">
              {linkedBucket ? (
                <UnlinkButton size="small" token={sapiToken} bucket={linkedBucket} hideIcon />
              ) : (
                <LinkButton
                  size="small"
                  buckets={allBuckets}
                  token={sapiToken}
                  bucket={row.original}
                  hideIcon
                />
              )}
            </div>
          );
        },
      },
    ],
    [
      admins,
      allBuckets,
      query,
      sapiToken,
      sharedBuckets,
      urlTemplates,
      allowedProjectIds,
      sort,
      setSort,
    ],
  );

  const memoizedFilteredSortedRows = useMemo(
    () =>
      getFilteredSortedBuckets(
        sharedAndLinkedBuckets.toArray(),
        searchFilters,
        currentAdmin,
        query,
        sorter,
        {
          getDescription: getDescriptionWithDefaultValue,
          checkLinked: (bucket) => bucket.get('linkedBucket'),
        },
      ),
    [sharedAndLinkedBuckets, searchFilters, currentAdmin, query, sorter],
  );

  return (
    <>
      <TabNavigation sourceTab={routeNames.SHARED_WITH_YOU} />
      <Search
        className="tw-mb-6"
        defaultValue={query}
        placeholder={!query ? `Search data catalog (${sharedAndLinkedBuckets.count()})` : ''}
        suffix={
          <FilterButtons
            bucketsCount={sharedAndLinkedBuckets.count()}
            renderFilterButton={(type, group, label) => (
              <FilterButton
                type={type}
                group={group}
                label={label}
                searchFilters={searchFilters}
                setSearchFilters={setSearchFilters}
              />
            )}
          />
        }
        onChange={(query) => {
          setQuery(query);
          RoutesStore.getRouter().updateQuery({ q: query });
        }}
      />
      <DataCatalogTable
        columns={columns}
        data={memoizedFilteredSortedRows}
        sourceTab={routeNames.SHARED_WITH_YOU}
      />
    </>
  );
};

export default SharedWithYou;
