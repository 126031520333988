import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';

import type { ApiClient, ApiClientOptions } from '../client';
import type { CreateCallbackMiddlewareOptions } from '../fetchClient';

import { Provider } from './context';

type Props = {
  client: ApiClient;
  children: ReactNode;
  fallback?: ReactNode;
} & Omit<ApiClientOptions, 'callbacks'> &
  CreateCallbackMiddlewareOptions;

export const ApiClientProvider = ({
  client,
  children,
  fallback = null,

  onError,
  onSuccess,
  onSettled,
  ...rest
}: Props) => {
  const [isInitializing, setIsInitializing] = useState<boolean>(true);

  useEffect(() => {
    client
      .init({
        ...rest,
        callbacks: { onSuccess, onError, onSettled },
      })
      .finally(() => {
        setIsInitializing(false);
      });
    // we want to init only on initial mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isInitializing ? fallback : <Provider value={client}>{children}</Provider>;
};
