import { cn, Icon, Tooltip } from '@keboola/design';

type Props = {
  show: boolean;
  isDisabled: boolean;
  onToggle: () => void;
  columnsLength: number;
};

const ToggleNodeButton = ({ show, isDisabled, onToggle, columnsLength }: Props) => {
  const button = (
    <button
      className={cn(
        'tw-border-0',
        'tw-group/columnsToggle tw-flex tw-w-full tw-justify-between tw-rounded-none !tw-bg-white tw-px-2 tw-py-3 tw-text-xs tw-font-normal tw-normal-case tw-leading-4 tw-tracking-normal hover:tw-no-underline focus:tw-no-underline',
        { 'tw-rounded-b-md': !show },
        isDisabled ? 'tw-opacity-40 [&_svg]:!tw-text-neutral-400' : 'hover:!tw-bg-neutral-100',
      )}
      onClick={onToggle}
      disabled={isDisabled}
    >
      {columnsLength} columns
      <Icon
        icon={show ? 'chevron-up' : 'chevron-down'}
        fixedWidth
        className="tw-text-neutral-400 group-hover/columnsToggle:tw-text-secondary-600"
      />
    </button>
  );

  return isDisabled ? (
    <Tooltip
      placement="top"
      type="explanatory"
      tooltip="You cannot manipulate with node as you have column selected"
    >
      {button}
    </Tooltip>
  ) : (
    button
  );
};

export default ToggleNodeButton;
