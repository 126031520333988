import { Button } from 'react-bootstrap';

import { URLS } from '@keboola/constants';
import { ButtonLink, Icon, Link } from '@keboola/design';

import TextDivider from '@/react/common/TextDivider';
import { VideoWidget } from '@/react/common/VideoWidget';

type Props = {
  onDismiss: () => void;
};

const WelcomeWidget = ({ onDismiss }: Props) => {
  return (
    <div className="box box-panel dashboard-welcome-widget text-center">
      <Button bsStyle="link" className="ml-auto close" onClick={onDismiss}>
        <Icon icon="xmark" />
      </Button>
      <div className="flex-container flex-column stretch gap-24 plp-3 prp-3">
        <h2 className="mtp-2 mb-0 f-24 line-height-32">New to Keboola?</h2>
        <div>
          <h3>Try out our interactive walk-through in the demo project.</h3>
          <ButtonLink variant="outline">
            <Link href={URLS.DEMO_PROJECT}>Demo Project</Link>
          </ButtonLink>
        </div>
        <TextDivider className="tw-my-1 tw-text-white/30" textClassName="tw-text-secondary-100" />
        <div>
          <h3>Watch the walk-through video.</h3>
          <VideoWidget type="keboola_walkthrough" />
        </div>
      </div>
    </div>
  );
};

export default WelcomeWidget;
