import { Modal } from 'react-bootstrap';
import type { Map } from 'immutable';

import { ClipboardIcon } from '@keboola/design';

import ModalIcon from '@/react/common/ModalIcon';

const MLFlowArtifactsModal = ({
  models,
  show,
  onHide,
}: {
  models: Map<string, any>;
  show: boolean;
  onHide: () => void;
}) => {
  return (
    <Modal bsSize="large" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>MLFlow Models</Modal.Title>
        <ModalIcon icon="rotate" />
      </Modal.Header>
      <Modal.Body>
        <div className="well with-table">
          <table className="table">
            <thead>
              <tr>
                <th className="w-200">Name</th>
                <th className="w-200">Stage</th>
                <th className="w-100">Deployment URL</th>
                <th className="w-100">Artifact URL</th>
              </tr>
            </thead>
            <tbody>
              {models
                .sortBy((model) => model.get('name'))
                .map((model, index) => {
                  const deployment = model.getIn(['deployment', 'url']);
                  const artifactUrl = model.get('artifactUrl');

                  return (
                    <tr key={index}>
                      <td>{model.get('name')}</td>
                      <td>{model.get('stage')}</td>
                      <td className="text-center">
                        {deployment ? <ClipboardIcon text={deployment} /> : 'None'}
                      </td>
                      <td>
                        <ClipboardIcon text={artifactUrl} />
                      </td>
                    </tr>
                  );
                })
                .toArray()}
            </tbody>
          </table>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MLFlowArtifactsModal;
