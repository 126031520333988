import createReactClass from 'create-react-class';

import { URLS } from '@keboola/constants';
import { cn, Icon } from '@keboola/design';

import { KEBOOLA_LEGACY_TRANSFORMATION, KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { SIDEBAR } from '@/constants/external';
import CreateWorkspaceButton from '@/modules/components/react/components/CreateWorkspaceButton';
import DocumentationLink from '@/modules/components/react/components/DocumentationLink';
import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import SidebarJobs from '@/modules/components/react/components/SidebarJobs';
import SidebarVersions from '@/modules/components/react/components/SidebarVersions';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import RowVersionsStore from '@/modules/configurations/RowVersionsStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import ActionCreators from '@/modules/legacy-transformation/ActionCreators';
import { backends, routeNames } from '@/modules/legacy-transformation/Constants';
import CopyTransformationButton from '@/modules/legacy-transformation/react/components/CopyTransformationButton';
import TransformationVersion from '@/modules/legacy-transformation/react/components/TransformationVersion';
import Phase from '@/modules/legacy-transformation/react/modals/Phase';
import TransformationBucketsStore from '@/modules/legacy-transformation/stores/TransformationBucketsStore';
import TransformationsStore from '@/modules/legacy-transformation/stores/TransformationsStore';
import { isKnownTransformationType } from '@/modules/legacy-transformation/utils/transformationTypes';
import NotificationsStore from '@/modules/notifications/store';
import JobsStore from '@/modules/queue/store';
import { prepareSandboxes } from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import ActivateDeactivateButton from '@/react/common/ActivateDeactivateButton';
import Confirm from '@/react/common/Confirm';
import RunUnsavedWarning from '@/react/common/RunUnsavedWarning';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import TransformationDetailStatic from './TransformationDetailStatic';

const TransformationDetail = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      DevBranchesStore,
      TransformationsStore,
      TransformationBucketsStore,
      InstalledComponentsStore,
      ComponentsStore,
      StorageTablesStore,
      StorageBucketsStore,
      NotificationsStore,
      RowVersionsStore,
      SandboxesStore,
      JobsStore,
    ),
  ],

  UNSAFE_componentWillReceiveProps() {
    return this.setState(this.getStateFromStores());
  },

  getStateFromStores() {
    let highlightQueryNumber;
    const componentId = KEBOOLA_LEGACY_TRANSFORMATION;
    const routerState = RoutesStore.getRouterState();
    const bucketId = RoutesStore.getCurrentRouteParam('config');
    const transformationId = RoutesStore.getCurrentRouteParam('row');

    if (routerState.hasIn(['location', 'query', 'highlightQueryNumber'])) {
      highlightQueryNumber = parseInt(
        routerState.getIn(['location', 'query', 'highlightQueryNumber']),
        10,
      );
    }

    return {
      readOnly: ApplicationStore.isReadOnly(),
      notifications: NotificationsStore.getAll(),
      component: ComponentsStore.getComponent(componentId),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      bucket: TransformationBucketsStore.get(bucketId),
      transformationBuckets: TransformationBucketsStore.getAll(),
      allConfigurations: InstalledComponentsStore.getAll(),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      allowedCreateWorkspace: ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
      sandboxes: prepareSandboxes(
        SandboxesStore.getSandboxes(),
        InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SANDBOXES),
      ),
      transformation: TransformationsStore.getTransformation(bucketId, transformationId),
      editingFields: TransformationsStore.getTransformationEditingFields(
        bucketId,
        transformationId,
      ),
      pendingActions: TransformationsStore.getTransformationPendingActions(
        bucketId,
        transformationId,
      ),
      tables: StorageTablesStore.getAll(),
      buckets: StorageBucketsStore.getAll(),
      bucketId,
      transformationId,
      transformations: TransformationsStore.getTransformations(bucketId),
      isTransformationEditingValid: TransformationsStore.getTransformationEditingIsValid(
        bucketId,
        transformationId,
      ),
      highlightQueryNumber,
      latestJobs: JobsStore.getLatestJobs(
        KEBOOLA_LEGACY_TRANSFORMATION,
        bucketId,
        transformationId,
      ),
      rowVersions: RowVersionsStore.getVersions(componentId, bucketId, transformationId),
      rowVersionsConfigs: RowVersionsStore.getVersionsConfigs(
        componentId,
        bucketId,
        transformationId,
      ),
      isLoadingRowVersions: RowVersionsStore.isLoadingVersions(
        componentId,
        bucketId,
        transformationId,
      ),
      isPendingRowVersions: RowVersionsStore.isPendingConfig(
        componentId,
        bucketId,
        transformationId,
      ),
      pendingMultiLoadRowVersions: RowVersionsStore.getPendingMultiLoad(
        componentId,
        bucketId,
        transformationId,
      ),
      admins: ApplicationStore.getAdmins(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
    };
  },

  resolveLinkDocumentationLink() {
    let subpageName;
    const documentationLink = `${URLS.USER_DOCUMENTATION}/transformations/`;
    const transformationType = this.state.transformation.get('type');

    if (transformationType === 'simple') {
      subpageName = this.state.transformation.get('backend');
    } else {
      subpageName = transformationType;
    }

    return documentationLink + subpageName;
  },

  _deleteTransformation() {
    const bucketId = this.state.bucket.get('id');
    RoutesStore.getRouter().transitionTo(routeNames.BUCKET, { config: bucketId });
    const transformationId = this.state.transformation.get('id');
    return ActionCreators.deleteTransformation(bucketId, transformationId);
  },

  _handleActiveChange(newValue) {
    let changeDescription;
    if (newValue) {
      changeDescription = `Transformation ${this.state.transformation.get(
        'name',
        this.state.transformation.get('id'),
      )} enabled`;
    } else {
      changeDescription = `Transformation ${this.state.transformation.get(
        'name',
        this.state.transformation.get('id'),
      )} disabled`;
    }
    return ActionCreators.changeTransformationProperty(
      this.state.bucketId,
      this.state.transformationId,
      'disabled',
      !newValue,
      changeDescription,
    );
  },

  _showDetails() {
    return (
      (this.state.transformation.get('backend') === 'snowflake' &&
        this.state.transformation.get('type') === 'simple') ||
      this.state.transformation.get('backend') === 'docker'
    );
  },

  render() {
    return (
      <div className="row">
        <div className="col-sm-9">
          <TransformationDetailStatic
            readOnly={this.state.readOnly}
            bucket={this.state.bucket}
            transformation={this.state.transformation}
            editingFields={this.state.editingFields}
            transformations={this.state.transformations}
            pendingActions={this.state.pendingActions}
            tables={this.state.tables}
            buckets={this.state.buckets}
            bucketId={this.state.bucketId}
            transformationId={this.state.transformationId}
            showDetails={this._showDetails()}
            isEditingValid={this.state.isTransformationEditingValid}
            isQueriesProcessing={this.state.pendingActions.has('queries-processing')}
            highlightQueryNumber={this.state.highlightQueryNumber}
          />
        </div>
        <div className="col-sm-3">
          <div className={`sidebar-content ${SIDEBAR}`}>
            <ul className="nav nav-stacked">
              {!this.state.readOnly && isKnownTransformationType(this.state.transformation) && (
                <>
                  <li className={cn({ disabled: this.state.transformation.get('disabled') })}>
                    <RunComponentButton
                      mode="sidebar"
                      title="Run transformation"
                      componentId={KEBOOLA_LEGACY_TRANSFORMATION}
                      disabled={this.state.transformation.get('disabled')}
                      disabledReason={
                        this.state.transformation.get('disabled')
                          ? 'Transformation is disabled'
                          : ''
                      }
                      runParams={() => ({
                        config: this.state.bucketId,
                        row: this.state.transformation.get('id'),
                      })}
                      forceModal={this.state.editingFields.get('queriesChanged', false)}
                    >
                      {this.state.editingFields.get('queriesChanged', false) && (
                        <RunUnsavedWarning
                          section={
                            this.state.transformation.get('backend') === backends.DOCKER
                              ? 'scripts'
                              : 'queries'
                          }
                          entity="transformation"
                        />
                      )}
                      You are about to run the transformation{' '}
                      {this.state.transformation.get('name', this.state.transformation.get('id'))}.
                    </RunComponentButton>
                  </li>
                  <li>
                    <ActivateDeactivateButton
                      isActive={!this.state.transformation.get('disabled')}
                      isPending={this.state.pendingActions.has('save-disabled')}
                      onChange={this._handleActiveChange}
                    />
                  </li>
                </>
              )}
              {!this.state.readOnly && (
                <>
                  <li>
                    <Phase
                      transformation={this.state.transformation}
                      bucketId={this.state.bucketId}
                    />
                  </li>
                  <li>
                    <TransformationVersion
                      transformation={this.state.transformation}
                      bucketId={this.state.bucketId}
                    />
                  </li>
                  <li>
                    <CreateWorkspaceButton
                      hasPayAsYouGo={false}
                      componentId={KEBOOLA_LEGACY_TRANSFORMATION}
                      metadata={this.state.componentsMetadata}
                      config={this.state.transformation}
                      workspaces={this.state.sandboxes}
                      allowedComponents={this.state.allowedCreateWorkspace}
                      forceType={
                        this.state.transformation.get('backend') === 'docker'
                          ? this.state.transformation.get('type')
                          : this.state.transformation.get('backend')
                      }
                    />
                  </li>
                  <li>
                    <CopyTransformationButton
                      transformation={this.state.transformation}
                      transformationBuckets={this.state.transformationBuckets}
                      bucketId={this.state.bucketId}
                    />
                  </li>
                </>
              )}
              {isKnownTransformationType(this.state.transformation) && (
                <li>
                  <DocumentationLink href={this.resolveLinkDocumentationLink()} />
                </li>
              )}
              {!this.state.readOnly && (
                <li>
                  <hr />
                  <Confirm
                    icon="trash"
                    title="Delete Transformation"
                    text={`Are you sure you want to delete the transformation ${this.state.transformation.get(
                      'name',
                      this.state.transformation.get('id'),
                    )}?`}
                    buttonLabel="Delete"
                    buttonType="danger"
                    onConfirm={this._deleteTransformation}
                    childrenRootElement="a"
                  >
                    <Icon icon="trash" />
                    <span className="text-muted">Delete transformation</span>
                  </Confirm>
                </li>
              )}
            </ul>
            {isKnownTransformationType(this.state.transformation) && (
              <SidebarJobs
                hasNewQueue
                jobs={this.state.latestJobs}
                admins={this.state.admins}
                currentAdmin={this.state.currentAdmin}
                notifications={this.state.notifications}
                allConfigurations={this.state.allConfigurations}
                componentId={KEBOOLA_LEGACY_TRANSFORMATION}
                configId={this.state.bucketId}
                rowId={this.state.transformationId}
              />
            )}
            {isKnownTransformationType(this.state.transformation) && (
              <>
                <hr />
                <SidebarVersions
                  component={this.state.component}
                  configId={this.state.bucketId}
                  rowId={this.state.transformationId}
                  versions={this.state.rowVersions}
                  versionsConfigs={this.state.rowVersionsConfigs}
                  versionsLinkTo={routeNames.BUCKET_ROW_VERSIONS}
                  versionsLinkParams={{
                    component: KEBOOLA_LEGACY_TRANSFORMATION,
                    config: this.state.bucketId,
                    row: this.state.transformationId,
                  }}
                  admins={this.state.admins}
                  isLoading={this.state.isLoadingRowVersions}
                  isPending={this.state.isPendingRowVersions}
                  pendingMultiLoad={this.state.pendingMultiLoadRowVersions}
                  limit={5}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  },
});

export default TransformationDetail;
