import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import { Icon, Tooltip } from '@keboola/design';

import { canPullTable } from '@/modules/admin/privileges';
import { ioType } from '@/modules/components/Constants';
import StorageApiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { pullTable } from '@/modules/storage/actions';
import {
  getBranchTableId,
  getCurrentBranchTableWithProductionFallback,
} from '@/modules/storage/helpers';
import InputOutputTypeIcon from '@/react/common/InputOutputTypeIcon';
import Loader from '@/react/common/Loader';
import MultiActionsSelectCheckbox from '@/react/common/MultiActionsSelectCheckbox';
import ApplicationStore from '@/stores/ApplicationStore';
import { isDeleteWhereConfigurationValid } from './helpers';
import TableOutputMappingModal from './TableOutputMappingModal';

const TableOutputMappingRow = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    componentId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
    buckets: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    sourceType: PropTypes.oneOf(Object.values(ioType)).isRequired,
    defaultBucketName: PropTypes.string.isRequired,
    defaultTableName: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    toggleSelection: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      isDeleting: false,
      isPullingTable: false,
    };
  },

  render() {
    return (
      <span className="tr hoverable-actions">
        {this.renderColumns()}
        {this.renderActionButtons()}
      </span>
    );
  },

  renderColumns() {
    return (
      <>
        {!this.props.readOnly && (
          <span className="td pr-0">
            <div className="tw-flex tw-h-full tw-items-center">
              <MultiActionsSelectCheckbox
                isChecked={this.props.isSelected}
                isDisabled={this.state.isDeleting}
                onToggle={this.props.toggleSelection}
                entity="mapping"
              />
            </div>
          </span>
        )}
        <span className="td col-xs-5">{this.renderSource()}</span>
        <span className="td col-xs-1 text-center">{this.renderRightArrow()}</span>
        <span className="td col-xs-5">{this.renderDestination()}</span>
      </>
    );
  },

  renderSource() {
    return (
      <>
        <div className="tw-flex tw-items-center">
          <InputOutputTypeIcon type={this.props.sourceType} />
          {this.props.sourceType === ioType.FILE && 'out/tables/'}
          {this.props.value.get('source')}
        </div>
      </>
    );
  },

  renderActionButtons() {
    if (this.props.readOnly) {
      return <span className="td pl-0 pr-1 no-wrap" />;
    }

    return (
      <span className="td pl-0 pr-1 no-wrap">
        {this.shouldShowCloneButton() && (
          <Tooltip placement="top" tooltip="Clone to Current Branch">
            <Button
              bsStyle="link"
              className="text-muted"
              onClick={() => {
                this.setState({ isPullingTable: true });

                pullTable(this.props.value.get('destination')).finally(() =>
                  this.setState({ isPullingTable: false }),
                );
              }}
              disabled={this.state.isPullingTable}
            >
              {this.state.isPullingTable ? <Loader /> : <Icon icon="clone" fixedWidth />}
            </Button>
          </Tooltip>
        )}
        <TableOutputMappingModal
          tables={this.props.tables}
          buckets={this.props.buckets}
          mapping={this.props.value}
          onSave={this.props.onSave}
          sourceType={this.props.sourceType}
          componentId={this.props.componentId}
          defaultBucketName={this.props.defaultBucketName}
          defaultTableName={this.props.defaultTableName}
        />
        {this.props.value.get('destination') !== '' && (
          <Tooltip placement="top" tooltip="Delete Output">
            <Button
              bsStyle="link"
              className="text-muted"
              onClick={() => {
                this.setState({ isDeleting: true });
                this.props.onDelete().finally(() => this.setState({ isDeleting: false }));
              }}
              disabled={this.state.isDeleting}
            >
              {this.state.isDeleting ? <Loader /> : <Icon icon="trash" fixedWidth />}
            </Button>
          </Tooltip>
        )}
      </span>
    );
  },

  renderDestination() {
    if (!this.props.value.get('destination')) {
      return 'Not set';
    }

    return (
      <div className="flex-container flex-start">
        <StorageApiTableLinkEx
          tableId={getBranchTableId(this.props.value.get('destination'))}
          forceDev={
            DevBranchesStore.isDevModeActive() && !ApplicationStore.hasProtectedDefaultBranch()
          }
        />
        {this.shouldShowCloneButton() && (
          <Tooltip
            placement="top"
            type="explanatory"
            tooltip="Consider cloning this table from production to current branch to sustain data consistency."
          >
            <Icon icon="triangle-exclamation" className="icon-addon-left f-16 text-warning" />
          </Tooltip>
        )}
      </div>
    );
  },

  renderRightArrow() {
    const isIncremental = this.props.value.get('incremental');

    if (!isIncremental) {
      return <Icon icon={['far', 'chevron-right']} className="text-muted" />;
    }

    return (
      <Tooltip
        placement="top"
        type="explanatory"
        tooltip={
          <>
            <div>Incremental</div>
            {isDeleteWhereConfigurationValid(this.props.value) && <div>Delete rows</div>}
          </>
        }
      >
        <Icon icon={['far', 'chevrons-right']} className="text-muted" />
      </Tooltip>
    );
  },

  shouldShowCloneButton() {
    return (
      canPullTable() &&
      this.props.value.get('incremental') &&
      getCurrentBranchTableWithProductionFallback(
        this.props.tables,
        this.props.value.get('destination'),
      ).getIn(['bucket', 'idBranch']) === DevBranchesStore.getDefaultBranchId()
    );
  },
});

export default TableOutputMappingRow;
