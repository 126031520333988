import { Alert } from '@keboola/design';

import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ReadOnlyDocumentationLink from '@/modules/sandboxes/components/ReadOnlyDocumentationLink';
import { FORCE_READONLY_STORAGE_ACCESS } from '@/modules/sandboxes/Constants';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';

const WorkspacesReadOnlyStorageAlert = () => {
  const state = useStores(
    () => {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const configData = InstalledComponentsStore.getConfigData(KEBOOLA_SANDBOXES, configId);
      const sandbox = SandboxesStore.getSandbox(configData.getIn(['parameters', 'id']));

      return {
        sandboxType: sandbox.get('type'),
        readOnlyStorageAccess: sandbox.getIn(
          ['workspaceDetails', 'connection', 'readOnlyStorageAccess'],
          false,
        ),
      };
    },
    [],
    [RoutesStore, InstalledComponentsStore],
  );

  if (!state.readOnlyStorageAccess && !FORCE_READONLY_STORAGE_ACCESS.includes(state.sandboxType)) {
    return null;
  }

  return (
    <div className="container tw-mt-5">
      <Alert>
        <span className="font-medium">Read-Only Access</span> to all project data is granted for
        this workspace. Be cautious when sharing it. <ReadOnlyDocumentationLink />
      </Alert>
    </div>
  );
};

export default WorkspacesReadOnlyStorageAlert;
