import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import _ from 'underscore';

import { Link } from '@keboola/design';

import ApplicationStore from '@/stores/ApplicationStore';
import string from '@/utils/string';

const ExternalProjectLink = createReactClass({
  propTypes: {
    stackUrl: PropTypes.string,
    projectId: PropTypes.number,
    children: PropTypes.any,
  },

  getProjectUrl() {
    const projectPath = _.template(ApplicationStore.getProjectUrlTemplate())({
      projectId: this.props.projectId,
    });

    return `${string.rtrim(this.props.stackUrl, '/')}/${string.trim(projectPath, '/')}`;
  },

  render() {
    if (this.props.stackUrl && this.props.projectId) {
      return <Link href={this.getProjectUrl()}>{this.props.children}</Link>;
    }

    return this.props.children;
  },
});

export default ExternalProjectLink;
