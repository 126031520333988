import type { LabelProps as RLabelProps } from '@radix-ui/react-label';
import * as RLabel from '@radix-ui/react-label';

import { cn } from '../../utils';

export type LabelProps = { id?: string | number; htmlFor?: string | number } & Omit<
  RLabelProps,
  'id' | 'htmlFor'
>;

const Optional = ({ className }: { className?: string }) => (
  <span className={cn('tw-text-neutral-400', className)}>(optional)</span>
);

export const Label = ({ className, children, id, htmlFor, ...rest }: LabelProps) => {
  return (
    <RLabel.Root
      {...rest}
      id={id?.toString()}
      htmlFor={htmlFor?.toString()}
      className={cn('tw-m-0 tw-text-neutral-800 tw-font-normal tw-max-w-max', className)}
    >
      {children}
    </RLabel.Root>
  );
};

Label.Optional = Optional;
