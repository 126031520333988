import { useState } from 'react';
import { Map } from 'immutable';
import {
  Bar,
  Cell,
  ComposedChart,
  LabelList,
  Line,
  ResponsiveContainer,
  Tooltip as ChartTooltip,
  XAxis,
} from 'recharts';
import _ from 'underscore';

import { routeNames as jobsRouteNames } from '@/modules/jobs/Constants';
import { JOBS_STATUS } from '@/modules/queue/constants';
import { JOBS_GRAPH_COLORS, JOBS_GRAPH_COLORS_HOVER } from '@/react/common/Graph/constants';
import JobsGraphTooltip from '@/react/common/Graph/JobsGraphTooltip';
import StatusIndicator from '@/react/common/Graph/StatusIndicator';
import type { GraphJob } from '@/react/common/Graph/types';
import RoutesStore from '@/stores/RoutesStore';

const getTrendLine = (jobs: GraphJob[], trend: GraphJob[]) => {
  const differential = trend[0].duration - trend[trend.length - 1].duration;
  const trendStep = differential / (jobs.length - 1);

  const trendData = jobs.map((j, i) => {
    if (i === 0) {
      return trend[0];
    }

    if (i === jobs.length - 1) {
      return trend[1];
    }

    return {
      duration: trend[0].duration - trendStep * i,
    };
  });

  return {
    decline: Math.sign(differential) === 1,
    trendData,
  };
};

const JobsGraph = ({ data, minEntries }: { data: Map<string, any>; minEntries: number }) => {
  const [activeIndex, setActiveId] = useState<number | null>(null);

  if (!data) return null;

  const getJobs = () => {
    let jobs = data.get('jobs');
    const jobsCount = jobs.count();

    if (minEntries && jobsCount < minEntries) {
      _.times(minEntries - jobsCount, (index) => {
        jobs = jobs.push(Map({ duration: 0, index: index + jobsCount }));
      });
    }

    return jobs.toJS() as GraphJob[];
  };

  const goToJob = (jobId: string) => {
    return RoutesStore.getRouter().transitionTo(jobsRouteNames.DETAIL, { jobId });
  };

  const jobs = getJobs();
  const jobsData = jobs
    ?.filter((j) => j.status)
    .map((j) => ({
      jobId: j.jobId,
      duration: j.duration,
    }));

  const { trendData, decline } = getTrendLine(jobs, data.get('trendData').toJS());
  const activeJob = activeIndex !== null && jobs[activeIndex];

  return (
    <div className="tw-mx-9 tw-mb-9">
      <div className="tw-h-48">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={jobsData} dataKey="duration" margin={{ top: 20 }}>
            <XAxis xAxisId={0} hide={true} />
            <XAxis xAxisId={1} hide={true} />
            <Bar
              data={jobsData as (GraphJob & { x: number })[]}
              xAxisId={0}
              dataKey="duration"
              isAnimationActive={false}
              barSize={12}
            >
              <LabelList
                dataKey="duration"
                position="top"
                content={(props) => {
                  const index = Number(props?.index);
                  const job = jobs[index];
                  const jobStatus = job?.status;

                  if (jobStatus) {
                    return (
                      <>
                        <StatusIndicator
                          x={Number(props?.x)}
                          y={Number(props?.y) - 4}
                          radius={4}
                          width={Number(props?.width)}
                          color={JOBS_GRAPH_COLORS[jobStatus]}
                          onClick={() => goToJob(job?.jobId)}
                          onMouseEnter={() => {
                            setActiveId(index);
                          }}
                          onMouseLeave={() => {
                            setActiveId(null);
                          }}
                        />
                      </>
                    );
                  }

                  return null;
                }}
              />
              {jobs.map((entry, i) => (
                <Cell
                  key={entry.jobId ?? i}
                  fill={i === activeIndex ? JOBS_GRAPH_COLORS_HOVER[entry.status] : '#c5cbd6'}
                  style={{ cursor: 'pointer' }}
                  stroke="transparent"
                  strokeWidth={8}
                  onMouseEnter={() => {
                    setActiveId(i);
                  }}
                  onMouseLeave={() => {
                    setActiveId(null);
                  }}
                  onClick={() => goToJob(entry.jobId)}
                />
              ))}
            </Bar>

            <ChartTooltip
              cursor={false}
              isAnimationActive={false}
              content={() => {
                if (!activeJob) {
                  return null;
                }
                return <JobsGraphTooltip job={activeJob} />;
              }}
            />

            <Line
              data={trendData}
              xAxisId={1}
              type="linear"
              dataKey="duration"
              dot={false}
              activeDot={false}
              strokeDasharray="2 2"
              stroke={
                decline
                  ? JOBS_GRAPH_COLORS[JOBS_STATUS.SUCCESS]
                  : JOBS_GRAPH_COLORS[JOBS_STATUS.ERROR]
              }
              strokeWidth={2}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className="tw-text-md tw-mt-5 tw-text-center tw-text-neutral-400">Previous runs</div>
    </div>
  );
};

export default JobsGraph;
