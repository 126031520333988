import { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import Promise from 'bluebird';
import { fromJS, Map } from 'immutable';

import { Alert, Icon, Tooltip } from '@keboola/design';

import InstalledComponentsApi from '@/modules/components/InstalledComponentsApi';
import { createSimpleRow } from '@/modules/configurations/ConfigurationRowsActionCreators';
import { reloadSourceTables } from '@/modules/ex-db-generic/actionsProvisioning';
import { runComponent } from '@/modules/simplified-ui/actions';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import Loader from '@/react/common/Loader';
import Select from '@/react/common/Select';
import getDefaultBucket from '@/utils/getDefaultBucket';
import nextTick from '@/utils/nextTick';
import string from '@/utils/string';
import Help from './Help';

class DatabaseSourcesSelector extends Component {
  static propTypes = {
    configRows: PropTypes.instanceOf(Map),
    configData: PropTypes.instanceOf(Map),
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    readOnly: PropTypes.bool.isRequired,
    hasNewQueue: PropTypes.bool.isRequired,
  };

  state = {
    availableTables: Map(),
    tables: this.prepareTables(),
    loadingDataError: null,
    isLoadingTables: false,
    isSaving: false,
  };

  componentDidMount() {
    nextTick(this.loadAvailAbleTables);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.configData.hasIn(['parameters', 'db']) &&
      !this.props.configData
        .getIn(['parameters', 'db'])
        .equals(prevProps.configData.getIn(['parameters', 'db']))
    ) {
      nextTick(this.loadAvailAbleTables);
    }
  }

  render() {
    return (
      <div className="flex-container align-top">
        <div className="fill-space pr-2">
          {this.renderSourcesSelector()}
          {this.renderSelectedTables()}
          {this.renderConfirmButton()}
        </div>
        {this.renderHelp()}
      </div>
    );
  }

  renderSourcesSelector() {
    const options = this.getOptions();

    return (
      <>
        <Select
          className="select-with-border"
          disabled={this.state.isLoadingTables}
          placeholder={this.renderPlaceholder()}
          options={options}
          onChange={this.handleTableSelect}
          noResultsText={
            options.length === 0 ? 'All tables are already selected' : 'No table found'
          }
        />
        {this.state.loadingDataError && (
          <Alert variant="error" className="text-left tw-mb-5 tw-mt-3.5">
            {this.state.loadingDataError}
          </Alert>
        )}
      </>
    );
  }

  renderSelectedTables() {
    if (this.state.tables.isEmpty()) {
      return (
        <div className="well empty-muted">
          <Icon icon="table" size="3x" />
          <p>Select source above to see tables</p>
        </div>
      );
    }

    return (
      <div className="well with-table">
        <table className="table overflow-break-anywhere">
          <thead>
            <tr>
              <th>Schema / Table name</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.state.tables
              .sortBy((table, tableId) => tableId.toLowerCase())
              .map(this.renderTable)
              .toArray()}
          </tbody>
        </table>
      </div>
    );
  }

  renderTable = (table, tableId) => {
    return (
      <tr key={tableId}>
        <td>
          <Icon icon="table" className="icon-addon-right text-muted" />
          {table.get('schema')} / {table.get('name')}
        </td>
        <td className="pl-0 pr-0">
          <Tooltip placement="top" tooltip="Remove table">
            <Button
              bsStyle="link"
              className="text-muted"
              onClick={() => this.handleRemoveTable(tableId)}
            >
              <Icon icon="trash" />
            </Button>
          </Tooltip>
        </td>
      </tr>
    );
  };

  renderHelp() {
    if (!this.state.isSaving && this.isSaveDisabled()) {
      return (
        <Help
          title="Time to pick your tables"
          text={
            <>
              <p>
                Select the tables you&apos;d like to import. You can also pick a schema with all the
                tables already in it.
              </p>
              <p>
                Not seeing your newest tables?{' '}
                <Button
                  bsStyle="link"
                  className="btn-link-inline"
                  onClick={this.loadAvailAbleTables}
                  disabled={
                    this.state.isLoadingTables || this.state.isSaving || this.props.readOnly
                  }
                >
                  Refresh
                </Button>{' '}
                the list.
              </p>
            </>
          }
        />
      );
    }

    return (
      <Help
        down
        title="What will happen now?"
        text="We will start loading your data, it may take a few minutes for large ones. In the meantime you can load data from other sources."
      />
    );
  }

  renderConfirmButton() {
    return (
      <ConfirmButtons
        block
        saveLabel="Save and Run configuration"
        onSave={this.handleSaveAndRun}
        isSaving={this.state.isSaving}
        isDisabled={this.isSaveDisabled()}
      />
    );
  }

  renderPlaceholder() {
    if (!this.state.isLoadingTables) {
      return 'Select database sources';
    }

    return (
      <>
        <Loader className="icon-addon-right" />
        Loading list of tables in your database...
      </>
    );
  }

  isSaveDisabled() {
    return (
      this.props.readOnly ||
      this.state.isSaving ||
      this.state.tables.isEmpty() ||
      this.state.tables.equals(this.prepareTables())
    );
  }

  handleTableSelect = (selected) => {
    const tables = this.state.availableTables.has(selected)
      ? this.state.tables.set(selected, this.state.availableTables.get(selected))
      : this.state.tables.concat(
          this.state.availableTables.filter((table) => table.get('schema') === selected),
        );

    this.setState({ tables });
  };

  handleRemoveTable = (tableId) => {
    this.setState({
      tables: this.state.tables.delete(tableId),
    });
  };

  handleSaveAndRun = () => {
    const bucketId = getDefaultBucket('in', this.props.componentId, this.props.configId);

    const savedTables = this.prepareTables();
    const deletedTables = savedTables.filterNot((table, tableId) => this.state.tables.has(tableId));
    const newTables = this.state.tables.filterNot((table, tableId) => savedTables.has(tableId));

    this.setState({ isSaving: true });
    return Promise.each(deletedTables.toList(), (table) => {
      return InstalledComponentsApi.deleteConfigurationRow(
        this.props.componentId,
        this.props.configId,
        this.getTableRowId(table),
        'Delete table',
      );
    })
      .then(() => {
        return Promise.each(newTables.toList(), (table) => {
          const tableName = string.sanitizeKbcTableIdString(table.get('name'));

          return createSimpleRow(
            this.props.componentId,
            this.props.configId,
            {
              name: table.get('name'),
              configuration: JSON.stringify({
                parameters: {
                  columns: [],
                  primaryKey: [],
                  incremental: false,
                  outputTable: `${bucketId}.${tableName}`,
                  table: {
                    schema: table.get('schema'),
                    tableName: table.get('name'),
                  },
                },
              }),
            },
            `Add ${this.state.tables.count()} ${string.pluralize(
              this.state.tables.count(),
              'table',
            )}`,
          );
        });
      })
      .then(() => runComponent(this.props.componentId, this.props.configId, this.props.hasNewQueue))
      .finally(() => {
        nextTick(() => this.setState({ tables: this.prepareTables(), isSaving: false }));
      });
  };

  getOptions() {
    return this.state.availableTables
      .filter((table, tableId) => !this.state.tables.has(tableId))
      .groupBy((table) => table.get('schema'))
      .map((tables, schema) => {
        return [
          { value: schema, label: `Schema: ${schema}`, className: 'font-semibold' },
          ...tables
            .sortBy((table, tableId) => tableId.toLowerCase())
            .map((table, tableId) => ({ value: tableId, label: tableId }))
            .toArray(),
        ];
      })
      .reduce((all, options) => all.concat(options), []);
  }

  loadAvailAbleTables = () => {
    this.setState({ isLoadingTables: true, loadingDataError: null });
    reloadSourceTables(this.props.componentId, this.props.configId)
      .then((data) => {
        if (data?.status === 'error') {
          return this.setState({ loadingDataError: data.message });
        }

        return this.setState({
          availableTables: fromJS(data?.tables || [])
            .toMap()
            .mapKeys((key, table) => `${table.get('schema')}.${table.get('name')}`),
        });
      })
      .finally(() => this.setState({ isLoadingTables: false }));
  };

  prepareTables() {
    return Map().withMutations((rows) => {
      this.props.configRows.forEach((row) => {
        const table = row.getIn(['configuration', 'parameters', 'table'], Map());

        rows.set(
          `${table.get('schema')}.${table.get('tableName')}`,
          Map({
            name: table.get('tableName'),
            schema: table.get('schema'),
          }),
        );
      });
    });
  }

  getTableRowId(table) {
    return this.props.configRows
      .find((row) => {
        return (
          row.getIn(['configuration', 'parameters', 'table', 'schema']) === table.get('schema') &&
          row.getIn(['configuration', 'parameters', 'table', 'tableName']) === table.get('name')
        );
      })
      .get('id');
  }
}

export default DatabaseSourcesSelector;
