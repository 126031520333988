import { lazy, Suspense } from 'react';
import type { Map } from 'immutable';

import Checkbox from '@/react/common/Checkbox';
import graphUtils from '@/utils/graphUtils';

const GraphCanvas = lazy(() => {
  return import(/* webpackChunkName: "graph-canvas" */ '@/react/common/GraphCanvas');
});

type Props = {
  model: Map<string, any>;
  centerNodeId: string;
  showDisabled: boolean;
  disabledTransformation: boolean;
  showDisabledHandler: () => void;
};

const Graph = ({ model, disabledTransformation, showDisabledHandler, showDisabled }: Props) => {
  const rawData = model.toJS();
  const data = {
    ...rawData,
    nodes: graphUtils.addLinksToNodes(rawData.nodes),
  };

  return (
    <div>
      <div>
        <div className="tw-flex tw-justify-end">
          <span>
            {disabledTransformation ? (
              <small>Showing all disabled transformations</small>
            ) : (
              <Checkbox onChange={showDisabledHandler} checked={showDisabled}>
                Show disabled transformations
              </Checkbox>
            )}
          </span>
        </div>
      </div>
      <div className="tw-h-[400px] tw-w-full">
        <Suspense fallback={null}>
          <GraphCanvas data={data} />
        </Suspense>
      </div>
    </div>
  );
};

export default Graph;
