import { Component } from 'react';
import type { Map } from 'immutable';
import { List } from 'immutable';

import { KEBOOLA_EX_SAMPLE_DATA } from '@/constants/componentIds';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import ConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import { routeNames as storageRouteNames } from '@/modules/storage/constants';
import { RouterLink, Truncated } from '@/react/common';
import { CircleIcon } from './CircleIcon';
import ComponentDetailLink from './ComponentDetailLink';
import ComponentIcon from './ComponentIcon';
import ComponentName from './ComponentName';

type Props = {
  components: Map<string, any>;
  configurations: Map<string, any>;
  table: Map<string, any>;
  hasFlows: boolean;
};

class TableUpdatedBy extends Component<Props> {
  render() {
    const { componentId, configId, streamSourceId } = this.getLastUpdatedInfo();

    if (streamSourceId) {
      return (
        <div className="flex-container flex-start flex-reverse">
          <CircleIcon className="icon-addon-left" icon="webhook" smaller bold />
          <div>
            <RouterLink
              to={storageRouteNames.STREAM_DETAIL}
              params={{ sourceId: streamSourceId }}
              className="config-name dark"
            >
              {streamSourceId}
            </RouterLink>
            <div className="f-11 line-height-1 text-muted">Data Stream</div>
          </div>
        </div>
      );
    }

    const component = this.props.components.get(componentId);
    const config = this.props.configurations.getIn([componentId, 'configurations', configId]);

    if (!component) {
      return <span className="tw-text-neutral-400">N/A</span>;
    }

    if (!config) {
      return (
        <div className="flex-container flex-start flex-reverse">
          {this.renderComponentIcon(component, config)}
          <div>
            <ComponentDetailLink componentId={component.get('id')} className="config-name dark">
              {configId || 'Unknown configuration'}
            </ComponentDetailLink>
            <div className="f-11 line-height-1 text-muted">
              <ComponentName component={component} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="flex-container flex-start flex-reverse">
        {this.renderComponentIcon(component, config)}
        <div>
          <ConfigurationLink
            componentId={componentId}
            configId={configId}
            hasFlows={this.props.hasFlows}
            className="config-name"
          >
            <Truncated text={config.get('name', configId)} />
          </ConfigurationLink>
          <div className="f-11 line-height-1 text-muted">
            <ComponentName component={component} />
          </div>
        </div>
      </div>
    );
  }

  renderComponentIcon(componentForIcon: Map<string, any>, config: Map<string, any>) {
    let component = componentForIcon;
    if (config && component.get('id') === KEBOOLA_EX_SAMPLE_DATA) {
      component = this.props.components.get(
        config.getIn(['configuration', 'parameters', 'componentId'], component),
      );
    }

    return <ComponentIcon size="24" component={component} className="icon-addon-left" />;
  }

  getLastUpdatedInfo() {
    const streamSourceFound = this.metadataLookup(MetadataKeys.STREAM_SOURCE_ID);

    if (streamSourceFound) {
      return {
        streamSourceId: streamSourceFound.get('value'),
      };
    }

    let componentFound = this.metadataLookup(MetadataKeys.LAST_UPDATED_BY_COMPONENT_ID);
    let configFound = this.metadataLookup(MetadataKeys.LAST_UPDATED_BY_CONFIGURATION_ID);

    if (!componentFound || !configFound) {
      componentFound = this.metadataLookup(MetadataKeys.CREATED_BY_COMPONENT_ID);
      configFound = this.metadataLookup(MetadataKeys.CREATED_BY_CONFIGURATION_ID);
    }

    return {
      componentId: componentFound && componentFound.get('value'),
      configId: configFound && configFound.get('value'),
    };
  }

  metadataLookup(key: (typeof MetadataKeys)[keyof typeof MetadataKeys]) {
    return this.props.table
      .get('metadata', List())
      .find((m: Map<string, any>) => m.get('key') === key);
  }
}

export default TableUpdatedBy;
