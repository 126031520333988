import { ControlLabel, FormGroup } from 'react-bootstrap';

import { HelpBlock } from '@keboola/design';

import Select from '@/react/common/Select';

const LoadOptions = ({
  preserve,
  onChange,
}: {
  preserve: boolean;
  onChange: (value: boolean) => void;
}) => {
  return (
    <FormGroup>
      <ControlLabel>Operation</ControlLabel>
      <Select
        clearable={false}
        value={preserve}
        onChange={onChange}
        options={[
          { value: false, label: 'Clean workspace before loading' },
          { value: true, label: 'Preserve non-conflicting tables when loading' },
        ]}
      />
      <HelpBlock className="tw-mt-1">
        {preserve
          ? 'New tables will be loaded, existing tables with the same name will be replaced, other tables will be preserved.'
          : 'All existing data will be deleted before loading the new tables.'}
      </HelpBlock>
    </FormGroup>
  );
};

export default LoadOptions;
