import { forwardRef, useCallback, useEffect, useId, useState } from 'react';
import type { ReactNode } from 'react';
import { Dropdown } from 'react-bootstrap';
import { autoUpdate, offset, useFloating } from '@floating-ui/react';

import { cn, Icon } from '@keboola/design';

import { HIDE_OTHER_ACTION_DROPDOWNS } from '@/constants/customEvents';
import ActionLoader from './ActionLoader';
import FastFade from './FastFade';

const MENU_TRANSITION_STYLES = { transition: 'transform 120ms ease-out' };

/** @description Warning: All onClicks are prevented by default unless there is 'href' in event.target or `inModal` prop is sent to unblock clicks in modal or `stopPropagation` if you want to also avoid event bubbling.*/
export const RowActionDropdown = (props: {
  children: ReactNode;
  toggleClassName?: string;
  showLoading?: boolean;
  loadingActionName?: string | null;
  inHeader?: boolean;
  inModal?: boolean;
  isCompact?: boolean;
}) => {
  const dropdownId = useId();
  const [wantsToOpen, setWantsToOpen] = useState(false);
  const [dropup, setDropup] = useState(false);

  const { refs, elements, update, floatingStyles } = useFloating({
    strategy: 'fixed',
    placement: dropup ? 'top-end' : 'bottom-end',
    middleware: [offset({ crossAxis: 8 })],
  });

  useEffect(() => {
    if (!wantsToOpen) {
      return;
    }

    const hide = () => setWantsToOpen(false);

    window.addEventListener(HIDE_OTHER_ACTION_DROPDOWNS, hide, { passive: true });
    return () => {
      window.removeEventListener(HIDE_OTHER_ACTION_DROPDOWNS, hide);
    };
  }, [wantsToOpen]);

  useEffect(() => {
    if (!wantsToOpen || !elements.reference || !elements.floating) {
      return;
    }

    const cleanup = autoUpdate(elements.reference, elements.floating, update, {
      animationFrame: true,
    });

    return () => {
      cleanup();
    };
  }, [wantsToOpen, elements, update]);

  const handleToggle = (isOpen: boolean, event: any, data: { source: string }) => {
    if (
      data.source === 'rootClose' &&
      event.composedPath?.().some((el: any) => el.id === dropdownId)
    ) {
      return;
    }

    // first hide all other dropdowns
    if (isOpen) {
      window.dispatchEvent(new Event(HIDE_OTHER_ACTION_DROPDOWNS));
    }

    // then decide about this one
    setWantsToOpen(isOpen);

    if (!isOpen) {
      return dropup && setDropup(false);
    }

    const rect = event?.target?.getBoundingClientRect();

    if (rect && rect.bottom > 400 && window.innerHeight * 0.65 < rect.bottom) {
      return setDropup(true);
    }
  };

  if (!props.children) {
    return null;
  }

  return (
    <span
      ref={refs.setReference}
      onClick={(event) => {
        // We have to catch this event and decide what to do with it, because
        // sometimes RowActionDropdown component is used within Anchor (e.g. for
        // configurations in generic Docker components).
        //
        // Note that all events will be caught, also clicks on menu items in
        // Dropdown.Menu.
        //
        // To make Anchors work, we have to check if event.target has href attr and
        // decide whether to "prevent default" or not, so Anchors will work.
        if (props.inModal || 'href' in event.target) {
          return;
        }

        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <Dropdown
        pullRight
        id={dropdownId}
        className="row-dropdown"
        dropup={dropup}
        open={wantsToOpen}
        onToggle={handleToggle}
        rootCloseEvent="mousedown"
      >
        <Dropdown.Toggle
          noCaret
          bsStyle={props.inHeader ? 'default' : 'link'}
          className={cn(props.toggleClassName, { '!tw-ml-3 tw-max-h-10': props.inHeader })}
        >
          {props.showLoading ? (
            <ActionLoader loadingActionName={props.loadingActionName} />
          ) : (
            <Icon
              className="f-20"
              icon={props.inHeader ? ['fas', 'ellipsis-vertical'] : ['far', 'ellipsis']}
            />
          )}
        </Dropdown.Toggle>
        <LazyDropdownMenu
          bsRole="menu"
          inHeader={props.inHeader}
          isCompact={props.isCompact}
          floating={refs.floating}
          setFloating={refs.setFloating}
          floatingStyles={floatingStyles}
        >
          {props.children}
        </LazyDropdownMenu>
      </Dropdown>
    </span>
  );
};

const LazyDropdownMenu = forwardRef(
  (
    { children, inHeader, isCompact, floating, setFloating, floatingStyles, ...restProps }: any,
    ref,
  ) => {
    const [entered, setEntered] = useState(false);
    const [hasOverflow, setHasOverflow] = useState(false);

    const checkOverflow = useCallback(() => {
      let parent = floating.current?.parentElement;

      while (parent) {
        const overflowY = window.getComputedStyle(parent).getPropertyValue('overflow-y');

        if (['auto', 'scroll'].includes(overflowY)) {
          setHasOverflow(true);
          return;
        }

        parent = parent?.parentElement;

        if (parent?.tagName.toLowerCase() === 'body') {
          break;
        }
      }

      setHasOverflow(false);
    }, [floating]);

    return (
      <FastFade
        appear
        mountOnEnter
        in={!!restProps.open}
        onEnter={checkOverflow}
        onEntered={() => setEntered(true)}
        onExit={() => setEntered(false)}
      >
        <span
          ref={setFloating}
          {...(hasOverflow && {
            style: {
              zIndex: 11,
              ...floatingStyles,
              ...(entered && MENU_TRANSITION_STYLES),
            },
          })}
        >
          <Dropdown.Menu
            {...restProps}
            className={cn({ compact: isCompact, 'tw-mt-2': inHeader })}
            ref={ref}
          >
            {children}
          </Dropdown.Menu>
        </span>
      </FastFade>
    );
  },
);
