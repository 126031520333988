import { Item } from '@radix-ui/react-radio-group';

import { cn } from '../../utils';

import type { RadioGroupItemProps } from './types';

export const RadioGroupItemButton = ({ className, children, ...props }: RadioGroupItemProps) => (
  <Item
    {...props}
    className={cn(
      // reset
      'tw-bg-transparent',
      'tw-p-0',
      'tw-peer',
      'tw-cursor-pointer',
      'tw-whitespace-nowrap',

      // position
      'tw-flex',
      'tw-items-center',
      'tw-justify-center',

      // disabled
      'disabled:tw-opacity-40',
      'disabled:tw-cursor-not-allowed',

      // layouts
      'tw-w-full',
      'tw-px-2',
      'tw-py-1.5',

      // fonts
      'tw-text-secondary-600',
      'tw-uppercase',
      'tw-font-semibold',
      'tw-font-sans',
      'tw-text-[11px]',
      'tw-whitespace-nowrap',
      'tw-tracking-widest',
      'tw-leading-[18px]',

      // border
      'tw-border',
      'tw-border-solid',
      'tw-border-secondary-500',
      'tw-rounded',

      // hover
      'data-[state=unchecked]:hover:tw-bg-secondary-500',
      'data-[state=unchecked]:hover:tw-text-white',

      // disabled
      'data-[state=unchecked]:disabled:hover:tw-bg-white',
      'data-[state=unchecked]:disabled:hover:tw-text-secondary-500',
      'data-[state=checked]:disabled:tw-cursor-not-allowed',

      // states
      'data-[state=unchecked]:tw-bg-white',
      'data-[state=checked]:tw-bg-secondary-500',
      'data-[state=checked]:tw-text-white',
      'data-[state=checked]:tw-cursor-default',

      'focus-visible:tw-outline',
      'focus-visible:tw-outline-secondary-500',
      'focus-visible:tw-ring-4',
      'focus-visible:tw-ring-secondary-200',
      className,
    )}
  >
    {children}
  </Item>
);
