import { useMemo, useState } from 'react';
import { fromJS, List, Map } from 'immutable';

import { Badge, cn, Search } from '@keboola/design';

import { sortEntities } from '@/constants';
import { useSorter } from '@/hooks';
import { canLoadSharedBuckets } from '@/modules/admin/privileges';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import { routeNames } from '@/modules/data-catalog/constants';
import { getDescriptionWithDefaultValue } from '@/modules/data-catalog/helpers';
import type { BucketColumn, BucketType, FlatTableRow } from '@/modules/data-catalog/types';
import type { SortConfig } from '@/modules/data-catalog/utils';
import {
  getFilteredSortedBuckets,
  getInitialSort,
  saveSort,
  sortKeys,
} from '@/modules/data-catalog/utils';
import { getDescriptionValue, isBucketLinked } from '@/modules/storage/helpers';
import { CircleIcon, Truncated } from '@/react/common';
import MarkedText from '@/react/common/MarkedText';
import { DEFAULT_BUCKET_COLOR } from '@/react/constants';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import string from '@/utils/string';
import { DataCatalogTable } from './DataCatalogTable';
import { FilterButton } from './FilterButton';
import { FilterButtons } from './FilterButtons';
import {
  ActionsDropdown,
  lastModified,
  ownerColumn,
  SharedLabel,
  sizeColumn,
  SortableHeader,
} from './tableComponents';
import { TabNavigation } from './TabNavigation';

const SharedFromThisProject = () => {
  const [query, setQuery] = useState(
    RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
  );
  const [searchFilters, setSearchFilters] = useState<Map<string, any>>(Map());

  const { sort, sorter, setSort } = useSorter(
    fromJS(getInitialSort(sortKeys.SHARED_FROM_PROJECT)),
    (newSort: SortConfig) => saveSort(sortKeys.SHARED_FROM_PROJECT, newSort),
  );

  const {
    admins,
    currentAdmin,
    sapiToken,
    urlTemplates,
    availableUsersOptions,
    availableProjectsOptions,
    mySharedBuckets,
  } = useStores(
    () => ({
      admins: ApplicationStore.getAdmins(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      sapiToken: ApplicationStore.getSapiToken(),
      urlTemplates: ApplicationStore.getUrlTemplates(),
      availableUsersOptions: ApplicationStore.getSharingToAdminsData(),
      availableProjectsOptions: ApplicationStore.getSharingToProjectsData(),
      mySharedBuckets: StorageBucketsStore.getAll().filter((bucket: BucketType) =>
        bucket.get('sharing'),
      ),
    }),
    [],
    [ApplicationStore, StorageBucketsStore],
  );

  const columns = useMemo<BucketColumn[]>(
    () => [
      {
        id: 'displayName',
        header: () => (
          <SortableHeader
            label="Name"
            entityKey={sortEntities.NAME}
            arrowSide="right"
            sort={sort}
            setSort={setSort}
          />
        ),
        cell: ({ row }: { row: FlatTableRow }) => {
          const bucket = row.original;
          const bucketColor = bucket.get('color');
          const metadata = bucket.get('metadata', List());
          const displayName = bucket.get('displayName');

          const description =
            getDescriptionValue(metadata, MetadataKeys.SHARED_DESCRIPTION) ||
            getDescriptionValue(metadata, MetadataKeys.DESCRIPTION);

          return (
            <div className={cn('tw-inline-flex tw-items-center')}>
              <CircleIcon icon="folder" backgroundColor={bucketColor ?? DEFAULT_BUCKET_COLOR} />
              <div className={cn('tw-items-center tw-flex-col tw-px-3')}>
                <Truncated
                  tooltip={displayName}
                  text={<MarkedText source={displayName} mark={query} />}
                />
                <div className="text-muted tw-w-[280px] tw-truncate">
                  {string.truncate(description, 60)}
                </div>
              </div>
            </div>
          );
        },
      },
      ownerColumn({ admins, sort, setSort }),
      sizeColumn({ sort, setSort }),
      {
        id: 'shared',
        header: () => (
          <SortableHeader
            label="Shared to"
            entityKey={sortEntities.SHARED}
            sort={sort}
            setSort={setSort}
            justifyToEnd
          />
        ),
        cell: ({ row }: { row: FlatTableRow }) => {
          const bucket = row.original;
          const sharing = bucket.get('sharing');
          const sharingParameters = bucket.get('sharingParameters');
          const { projects, users } = sharingParameters.toJS();
          const linkedByCount = bucket.get('linkedBy', List()).count();

          return (
            <div className="tw-text-right">
              <div>
                <SharedLabel projects={projects} users={users} sharing={sharing} />
              </div>
              {linkedByCount > 0 && (
                <Badge
                  className="tw-mt-[2px]"
                  text={`${linkedByCount} Linked`}
                  variant="green-inverse"
                />
              )}
            </div>
          );
        },
      },
      lastModified({ sort, setSort }),
      {
        id: 'actions',
        header: '',
        cell: ({ row }: { row: FlatTableRow }) => (
          <ActionsDropdown
            bucket={row.original}
            sapiToken={sapiToken}
            urlTemplates={urlTemplates}
            availableUsersOptions={availableUsersOptions}
            availableProjectsOptions={availableProjectsOptions}
          />
        ),
      },
    ],
    [
      admins,
      query,
      sort,
      sapiToken,
      urlTemplates,
      availableUsersOptions,
      availableProjectsOptions,
      setSort,
    ],
  );

  const memoizedFilteredSortedRows = useMemo(
    () =>
      getFilteredSortedBuckets(
        mySharedBuckets.toArray(),
        searchFilters,
        currentAdmin,
        query,
        sorter,
        {
          getDescription: getDescriptionWithDefaultValue,
          checkLinked: isBucketLinked,
        },
      ),
    [mySharedBuckets, searchFilters, currentAdmin, query, sorter],
  );

  return (
    <>
      {canLoadSharedBuckets(sapiToken) && (
        <TabNavigation sourceTab={routeNames.SHARED_FROM_THIS_PROJECT} />
      )}
      <div className="tw-flex tw-flex-col tw-gap-6">
        <Search
          placeholder={`Search data catalog (${mySharedBuckets.count()})`}
          defaultValue={query}
          onChange={(newQuery) => {
            setQuery(newQuery);
            RoutesStore.getRouter().updateQuery({ q: newQuery });
          }}
          suffix={
            <FilterButtons
              bucketsCount={mySharedBuckets.count()}
              renderFilterButton={(type, group, label) => (
                <FilterButton
                  type={type}
                  group={group}
                  label={label}
                  searchFilters={searchFilters}
                  setSearchFilters={setSearchFilters}
                />
              )}
            />
          }
        />
        <DataCatalogTable
          columns={columns}
          data={memoizedFilteredSortedRows}
          sourceTab={routeNames.SHARED_FROM_THIS_PROJECT}
        />
      </div>
    </>
  );
};

export default SharedFromThisProject;
