import { Modal } from 'react-bootstrap';

import { Alert } from '@keboola/design';

import { FeatureList, useUserAdminFeaturesQuery } from '@/react/common/FeatureList';
import { useToggleAdminFeatureMutation } from '@/react/common/FeatureList/hooks';
import ModalIcon from '@/react/common/ModalIcon';
import ApplicationStore from '@/stores/ApplicationStore';

type Props = {
  show: boolean;
  onHide: () => void;
};

export const UserFeaturesModal = (props: Props) => {
  const { data, isError, isPending, refetch } = useUserAdminFeaturesQuery({ enabled: false });
  const toggleAdminFeatureMutation = useToggleAdminFeatureMutation(
    ApplicationStore.getCurrentAdmin().get('email'),
  );

  return (
    <Modal show={props.show} onHide={props.onHide} onEnter={refetch}>
      <Modal.Header closeButton>
        <Modal.Title>User Features</Modal.Title>
        <ModalIcon icon="star" color="blue" bold />
      </Modal.Header>
      <Modal.Body className="pbp-2">
        <Alert className="tw-mb-6">
          These features are connected to your account, so they may change the behavior of every
          project you are a member of in the stack.
        </Alert>

        <FeatureList
          features={data}
          isLoading={isPending}
          isError={isError}
          variant="admin"
          onFeatureToggle={toggleAdminFeatureMutation.toggle}
          isFeatureLoading={({ name }) =>
            toggleAdminFeatureMutation.isPending && name === toggleAdminFeatureMutation.featureName
          }
        />
      </Modal.Body>
    </Modal>
  );
};
