import { Tooltip } from '@keboola/design';

import Loader from '@/react/common/Loader';

export const CreditsLoadingTooltip = (props: { show: boolean }) => {
  if (!props.show) {
    return null;
  }

  return (
    <Tooltip
      placement="top"
      tooltip="Loading credits balance..."
      triggerClassName="tw-align-middle tw-text-[0.5em]"
    >
      <Loader className="icon-addon-left text-muted" />
    </Tooltip>
  );
};
