import type { List } from 'immutable';

import { KEBOOLA_DATA_APPS } from '@/constants/componentIds';
import { canManageNotifications } from '@/modules/admin/privileges';
import { routeNames } from '@/modules/data-apps/constants';
import Notifications from '@/modules/notifications/components/Notifications';
import NotificationsStore from '@/modules/notifications/store';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const componentId = KEBOOLA_DATA_APPS;

export const NotificationsTab = () => {
  const { configId, admins, canSetupNotifications, allNotifications, notifications } = useStores(
    () => {
      const configId = RoutesStore.getCurrentRouteParam('config') as string;
      const notifications = NotificationsStore.getNotifications(componentId, configId) as List<any>;

      return {
        configId,
        admins: ApplicationStore.getAdmins(),
        canSetupNotifications: canManageNotifications(ApplicationStore.getSapiToken()),
        allNotifications: NotificationsStore.getAll(),
        notifications,
      };
    },
    [],
    [RoutesStore, ApplicationStore, NotificationsStore],
  );

  return (
    <>
      <ConfigurationTabs
        componentId={componentId}
        configId={configId}
        versionsLinkTo={routeNames.DATA_APP_VERSIONS}
        notificationsLinkTo={routeNames.DATA_APP_NOTIFICATIONS}
      />

      <Notifications
        configId={configId}
        componentId={componentId}
        notifications={notifications}
        allNotifications={allNotifications}
        admins={admins}
        readOnly={!canSetupNotifications}
      />
    </>
  );
};
