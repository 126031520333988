import type { ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';
import { Bar, BarChart, Cell, LabelList, Tooltip } from 'recharts';

import skeletonSVGGradient from '@/react/common/Skeleton/skeletonSVGGradient';
import { MINI_BAR_GRAPH_LIMIT } from './constants';
import StatusIndicator from './StatusIndicator';

const TOOLTIP_WRAPPER_STYLES = {
  width: 'fit-content',
  top: 'auto',
  right: 0,
  bottom: 'calc(100% + 12px)',
  left: 'auto',
  zIndex: 10,
};

const MiniBarGraph = <T extends Record<string, unknown>, C extends string>(props: {
  records: T[];
  dataKey: keyof T & string;
  getColor: (record: T) => C | undefined;
  renderTooltip: (record: T) => ReactNode;
  onRecordClick?: (record: T) => void;
}) => {
  const { ref, inView } = useInView({ triggerOnce: true });

  if (!props.records?.length) return null;

  const slicedAndReversedRecords = props.records.slice(-MINI_BAR_GRAPH_LIMIT).reverse();
  const maxValue = Math.max(
    ...(slicedAndReversedRecords
      .map((record) => record[props.dataKey])
      .filter((value) => typeof value === 'number' && !isNaN(value)) as number[]),
  );
  const records = [...new Array(MINI_BAR_GRAPH_LIMIT)]
    .map((_, index) => {
      const record = slicedAndReversedRecords[index];

      return {
        ...record,
        _dataKey: record?.inProgress ? maxValue || 100 : record?.[props.dataKey],
      };
    })
    .reverse() as (T | undefined)[];

  return (
    <div
      ref={ref}
      className="tw-relative tw-flex tw-justify-end"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="tw-h-10 tw-w-32">
        {inView ? (
          <BarChart width={130} height={40} data={records} margin={{ top: 10 }}>
            {skeletonSVGGradient}
            <Bar dataKey="_dataKey" isAnimationActive={false} barSize={8}>
              <LabelList
                dataKey="_dataKey"
                position="top"
                content={(labelProps) => {
                  const index = Number(labelProps?.index);
                  const color = records[index] && props.getColor(records[index]!);

                  if (color) {
                    return (
                      <StatusIndicator
                        x={Number(labelProps?.x)}
                        y={Number(labelProps?.y) - 2}
                        radius={3}
                        width={Number(labelProps?.width)}
                        color={color}
                        {...(records[index] &&
                          props.onRecordClick && {
                            onClick: () => props.onRecordClick!(records[index]!),
                          })}
                      />
                    );
                  }

                  return null;
                }}
              />
              {records.map((record, index) => (
                <Cell
                  key={index}
                  fill={record?.inProgress ? 'url(#animatedGradient)' : '#c5cbd6'}
                  style={record && props.onRecordClick && { cursor: 'pointer' }}
                  stroke="transparent"
                  strokeWidth={8}
                  className="animation-bar-core"
                  {...(record &&
                    props.onRecordClick && {
                      onClick: () => props.onRecordClick!(record!),
                    })}
                />
              ))}
            </Bar>
            <Tooltip
              cursor={false}
              content={({ active, payload }) => {
                if (!active || !props.renderTooltip || !payload?.[0]?.payload) return null;
                return props.renderTooltip(payload[0].payload);
              }}
              wrapperStyle={TOOLTIP_WRAPPER_STYLES}
            />
          </BarChart>
        ) : null}
      </div>
    </div>
  );
};

export default MiniBarGraph;
