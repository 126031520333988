import { useState } from 'react';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ConfigurationRowsActionCreators from '@/modules/configurations/ConfigurationRowsActionCreators';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import DescriptionBox from '@/react/common/DescriptionBox/DescriptionBox';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';

const ComponentDescription = (props: {
  componentId: string;
  configId: string;
  rowId?: string;
  readOnly?: boolean;
  placeholderEntity?: string;
}) => {
  const state = useStores(
    () => {
      const config = InstalledComponentsStore.getConfig(props.componentId, props.configId);
      const row = ConfigurationRowsStore.get(props.componentId, props.configId, props.rowId);

      return {
        hasAllowedDescriptionAi: StackFeaturesStore.hasAllowedAi(),
        readOnly: props.readOnly || ApplicationStore.isReadOnly(),
        description: props.rowId ? row.get('description', '') : config.get('description', ''),
        configuration: props.rowId ? row : config,
      };
    },
    [props.componentId, props.configId, props.rowId, props.readOnly],
    [ApplicationStore, InstalledComponentsStore, ConfigurationRowsStore],
  );

  const [editorValue, setEditorValue] = useState<string | null>(null);

  const handleSave = async () => {
    const newDescription = editorValue || '';

    if (props.rowId) {
      return ConfigurationRowsActionCreators.updateSimple(
        props.componentId,
        props.configId,
        props.rowId,
        { description: newDescription.trim() },
        'Update row description',
      );
    }

    return InstalledComponentsActionCreators.updateComponentConfiguration(
      props.componentId,
      props.configId,
      { description: newDescription.trim() },
      'Update description',
    );
  };

  return (
    <DescriptionBox
      editorValue={editorValue}
      onSetEditorValue={setEditorValue}
      description={state.description}
      onSave={handleSave}
      readOnly={state.readOnly}
      hasAllowedAi={state.hasAllowedDescriptionAi}
      placeholder={
        props.placeholderEntity ? `${props.placeholderEntity} Description` : 'Description'
      }
      componentId={props.componentId}
      configId={props.configId}
      rowId={props.rowId}
    />
  );
};

export default ComponentDescription;
