import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';
import _ from 'underscore';

import { HelpBlock } from '@keboola/design';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import PasswordControl from '@/react/common/PasswordControl';

const TableauServerCredentialsModal = createReactClass({
  propTypes: {
    saveCredentialsFn: PropTypes.func.isRequired,
    credentials: PropTypes.object,
    localState: PropTypes.object,
    updateLocalState: PropTypes.func,
  },

  getInitialState() {
    return {
      credentials: this.props.credentials || Map(),
      isSaving: false,
    };
  },

  render() {
    const show = !!(this.props.localState && this.props.localState.get('show'));

    return (
      <span onClick={(e) => e.stopPropagation()}>
        <Modal show={show} onHide={() => this.props.updateLocalState(Map())}>
          <Modal.Header closeButton>
            <Modal.Title>Set Up Credentials to Tableau Server</Modal.Title>
          </Modal.Header>
          <div className="form form-horizontal">
            <Modal.Body>
              {this._createInput(
                'Server URL',
                'server_url',
                'text',
                'use the URL of your concrete instance, e.g., https://10az.online.tableau.com',
              )}
              {this._createInput('Username', 'username')}
              {this._createInput('Password', 'password', 'password')}
              {this._createInput('Project Name', 'project_name')}
              {this._createInput('Site', 'site')}
            </Modal.Body>
            <Modal.Footer>
              <ConfirmButtons
                block
                saveLabel="Save"
                isSaving={this.state.isSaving}
                isDisabled={!this._isValid()}
                onSave={() => {
                  this.setState({
                    isSaving: true,
                  });
                  const creds = this.state.credentials;
                  let safeCreds = creds.set('#password', creds.get('password'));
                  safeCreds = safeCreds.remove('password');
                  this.props.saveCredentialsFn(safeCreds).then(() => {
                    this.setState({
                      isSaving: false,
                      credentials: safeCreds,
                    });
                    this.props.updateLocalState(Map());
                  });
                }}
              />
            </Modal.Footer>
          </div>
        </Modal>
      </span>
    );
  },

  _isValid() {
    return (
      this.state.credentials &&
      !_.isEmpty(this.state.credentials.get('server_url')) &&
      !_.isEmpty(this.state.credentials.get('username')) &&
      !_.isEmpty(this.state.credentials.get('project_name')) &&
      !_.isEmpty(this.state.credentials.get('password'))
    );
  },

  _createInput(labelValue, propName, type = 'text', desc = '') {
    return (
      <FormGroup>
        <div className="col-xs-4">
          <ControlLabel>{labelValue}</ControlLabel>
        </div>
        <div className="col-xs-8">
          {type === 'password' ? (
            <PasswordControl
              value={this.state.credentials.get(propName)}
              onChange={(event) => {
                this.setState({
                  credentials: this.state.credentials.set(propName, event.target.value),
                });
              }}
            />
          ) : (
            <FormControl
              type={type}
              placeholder={propName === 'site' ? 'default if empty' : null}
              value={this.state.credentials.get(propName, '')}
              onChange={(event) => {
                this.setState({
                  credentials: this.state.credentials.set(propName, event.target.value),
                });
              }}
            />
          )}
          {desc && <HelpBlock className="tw-mt-1">{desc}</HelpBlock>}
        </div>
      </FormGroup>
    );
  },
});

export default TableauServerCredentialsModal;
