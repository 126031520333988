import type { ReactNode } from 'react';
import type { Map } from 'immutable';

import { Truncated } from '@/react/common';
import MarkedText from '@/react/common/MarkedText';
import ComponentType from './ComponentType';
import Row from './Row';

type Props = {
  component: Map<string, any>;
  configuration: Map<string, any>;
  customFooterContent?: ReactNode;
  query: string;
  onSelect: (component: Map<string, any>, configuration: Map<string, any>) => void;
  withComponentType?: boolean;
};

const ConfigurationRow = ({
  configuration,
  component,
  customFooterContent,
  query,
  onSelect,
  withComponentType = true,
}: Props) => {
  return (
    <Row component={component} onClick={() => onSelect(component, configuration)}>
      <div className="tw-flex tw-flex-col tw-gap-0.5">
        <Truncated
          tooltip={configuration.get('name')}
          text={
            <MarkedText className="tw-text-sm" source={configuration.get('name')} mark={query} />
          }
        />
        <div className="tw-flex tw-gap-2 tw-text-xs">
          {withComponentType && <ComponentType component={component} />}
          {customFooterContent}
        </div>
      </div>
    </Row>
  );
};

export default ConfigurationRow;
