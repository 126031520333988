import type { Map } from 'immutable';

import { Clipboard } from '@keboola/design';

import { sortEntities } from '@/constants';
import { SortableHeader } from '@/modules/data-catalog/react/tableComponents/SortableHeader';
import type { FlatTableRow, SetSortType, SortType } from '@/modules/data-catalog/types';
import { getUserDetails } from '@/modules/data-catalog/utils';
import { Truncated } from '@/react/common';

type Props = {
  admins: Map<string, any>;
  sort: SortType;
  setSort: SetSortType;
};

export const ownerColumn = ({ admins, sort, setSort }: Props) => ({
  id: 'datashare_owner',
  header: () => (
    <SortableHeader
      label="Datashare Owner"
      entityKey={sortEntities.OWNER}
      sort={sort}
      setSort={setSort}
    />
  ),
  cell: ({ row }: { row: FlatTableRow }) => {
    const owner = row.original.get('owner');
    if (!owner) return <div className="font-medium">-</div>;

    const { name, email } = getUserDetails(owner, admins);
    if (!name && !email) return <div className="font-medium">-</div>;

    const renderOwnerText = () => {
      return (
        <Truncated className="color-primary font-medium underline" text={name || email} noTooltip />
      );
    };

    if (!owner) {
      return <div className=" font-medium">-</div>;
    }

    // TODO: check: Maybe stopEventPropagation span warp to Clipboard
    return (
      <Clipboard text={email} tooltipText="Click to Copy Email Address" tooltipPlacement="top">
        {renderOwnerText()}
      </Clipboard>
    );
  },
});
