import type { List } from 'immutable';

import { Icon, Tooltip } from '@keboola/design';

import string from '@/utils/string';

const SpecificRowsIcon = (props: { rows: List<string> }) => {
  const rows = props.rows.count();

  if (!rows) {
    return null;
  }

  return (
    <Tooltip
      placement="bottom"
      type="explanatory"
      tooltip={`Selected only ${rows} ${string.pluralize(rows, 'row')} to run.`}
      triggerClassName="simple-icon"
    >
      <Icon icon="table-rows" className="color-orange f-16" />
    </Tooltip>
  );
};

export default SpecificRowsIcon;
