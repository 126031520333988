import { useState } from 'react';
import type Promise from 'bluebird';
import { Map } from 'immutable';

import type { ACTIVE_MENU } from '@/modules/ex-generic/constants';
import { JOB_NAME } from '@/modules/ex-generic/constants';
import { flattenJobs, prepareRealJobPath } from '@/modules/ex-generic/helpers';
import * as endpointMapping from '@/modules/ex-generic/mapping/endpoint';
import * as headersMapping from '@/modules/ex-generic/mapping/jobHeaders';
import * as paramsMapping from '@/modules/ex-generic/mapping/jobParams';
import * as mappingMapping from '@/modules/ex-generic/mapping/mapping';
import * as paginationMapping from '@/modules/ex-generic/mapping/pagination';
import * as placeholdersMapping from '@/modules/ex-generic/mapping/placeholders';
import CatchUnsavedChanges from '@/react/common/CatchUnsavedChanges';
import SaveButtons from '@/react/common/SaveButtons';
import fromJSOrdered from '@/utils/fromJSOrdered';
import { isValidJsonConfig } from '@/utils/validation';
import EndpointName from './EndpointName';
import Headers from './Headers';
import JobParameters from './JobParameters';
import Mapping from './Mapping';
import Pagination from './Pagination';
import PathSettings from './PathSettings';
import Placeholders from './Placeholders';
import TestModal from './TestModal';

const JOB_MAPPINGS = [
  endpointMapping,
  paramsMapping,
  paginationMapping,
  headersMapping,
  placeholdersMapping,
  mappingMapping,
];

const PARAMETERS_MAPPING = [endpointMapping, mappingMapping];

const mapToState = (parameters: Map<string, any>, jobPath: (string | number)[]) => {
  return JOB_MAPPINGS.reduce((state, mapping) => {
    return state.mergeDeep(mapping.mapToState(parameters, jobPath));
  }, Map()) as Map<string, any>;
};

const mapToApi = (
  parameters: Map<string, any>,
  jobPath: (string | number)[],
  editing: Map<string, any>,
) => {
  return parameters
    .updateIn(['config', 'jobs', ...jobPath], (job: Map<string, any>) => {
      return JOB_MAPPINGS.reduce((job, mapping) => mapping.mapToJob(job, editing), job);
    })
    .update((parameters) => {
      return PARAMETERS_MAPPING.reduce((parameters, mapping) => {
        return mapping.mapToParameters(parameters, editing, jobPath);
      }, parameters);
    });
};

const Endpoint = (props: {
  readOnly: boolean;
  endpointPath: number[];
  parameters: Map<string, any>;
  allTables: Map<string, any>;
  setMenu: (menu: ACTIVE_MENU) => void;
  onSave: (
    parameters: Map<string, any>,
    changeDescription: string,
    newMenu?: ACTIVE_MENU,
  ) => Promise<any>;
}) => {
  const jobPath = prepareRealJobPath(props.endpointPath);
  const job = props.parameters.getIn(['config', 'jobs', ...jobPath], Map());
  const preparedJob = mapToState(props.parameters, jobPath);

  const [editing, setEditing] = useState(preparedJob);
  const [saving, setSaving] = useState(false);

  const isChanged = !editing.equals(preparedJob);

  const handleNameSave = (newName: string) => {
    return props.onSave(
      props.parameters.setIn(['config', 'jobs', ...jobPath, JOB_NAME], newName),
      'Update endpoint name',
    );
  };

  const handleSave = () => {
    setSaving(true);
    const newParameters = mapToApi(props.parameters, jobPath, editing);
    const endpoint = editing.getIn([endpointMapping.KEY, 'endpoint']);
    const endpointPath = flattenJobs(newParameters)
      .find((job: Map<string, any>) => job.get('endpoint') === endpoint)
      .get('endpointPath');

    return props
      .onSave(newParameters, 'Update endpoint', endpointPath)
      .then((configData: Record<string, any>) => {
        const parameters = fromJSOrdered(configData.parameters);

        setEditing(mapToState(parameters, prepareRealJobPath(endpointPath)));
      })
      .finally(() => setSaving(false));
  };

  const handleReset = () => setEditing(preparedJob);

  const prepareSectionProps = (key: string) => {
    return {
      onSave: props.onSave,
      readOnly: props.readOnly,
      editing: editing.get(key),
      setEditing: (values: Map<string, any>) => setEditing(editing.set(key, values)),
      rawEditingData: editing,
      parameters: props.parameters,
    };
  };

  const isDisabled = () => {
    const isChild = editing.getIn([endpointMapping.KEY, 'childJob']);
    const hasParent = editing.getIn([endpointMapping.KEY, 'parent']);

    const isPost = editing.getIn([endpointMapping.KEY, 'method']) === 'POST';
    const params = editing.getIn([paramsMapping.KEY, 'params']);

    return (
      (isChild && !hasParent) ||
      (isPost && typeof params === 'string' && !isValidJsonConfig(params))
    );
  };

  return (
    <>
      <div className="tw-mb-4 tw-mt-1 tw-flex tw-items-center tw-justify-between tw-gap-6">
        <EndpointName job={job} onSave={handleNameSave} />
        <div className="tw-inline-flex tw-flex-nowrap tw-items-center tw-justify-end tw-gap-3">
          <CatchUnsavedChanges
            isDirty={isChanged && !saving}
            onSave={handleSave}
            onDirtyLeave={handleReset}
            isSaveDisabled={isDisabled()}
          >
            <SaveButtons
              isSaving={saving}
              isChanged={isChanged}
              onSave={handleSave}
              onReset={handleReset}
              disabled={isDisabled()}
              className="tw-inline-flex"
            />
          </CatchUnsavedChanges>
          <TestModal
            jobName={job.get(JOB_NAME)}
            endpointPath={props.endpointPath}
            prepareParameters={() => mapToApi(props.parameters, jobPath, editing)}
          />
        </div>
      </div>
      <PathSettings {...prepareSectionProps(endpointMapping.KEY)} savedJob={job} />
      <Placeholders {...prepareSectionProps(placeholdersMapping.KEY)} />
      <JobParameters {...prepareSectionProps(paramsMapping.KEY)} />
      <Pagination {...prepareSectionProps(paginationMapping.KEY)} />
      <Headers {...prepareSectionProps(headersMapping.KEY)} />
      <Mapping
        {...prepareSectionProps(mappingMapping.KEY)}
        endpointPath={props.endpointPath}
        allTables={props.allTables}
        setMenu={props.setMenu}
      />
    </>
  );
};

export default Endpoint;
