import { useState } from 'react';
import type { ReactNode } from 'react';
import { Modal } from 'react-bootstrap';

import { Button, ButtonGroup } from '@keboola/design';

import ModalIcon from './ModalIcon';

type Props = {
  show: boolean;
  onSave: () => Promise<unknown>;
  onLeave: () => void;
  onHide: () => void;
  text?: ReactNode;
  leaveLabel?: string;
  isSaveDisabled?: boolean;
};

const CatchUnsavedChangesModal = ({
  show,
  onLeave,
  onSave,
  onHide,
  text,
  isSaveDisabled,
  leaveLabel,
}: Props) => {
  const [isSaving, setIsSaving] = useState(false);

  return (
    <Modal bsSize="sm" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-md">Unsaved changes</Modal.Title>
        <ModalIcon icon="square-exclamation" color="orange" bold />
      </Modal.Header>
      <Modal.Body>
        <div className="max-w-350">
          {text || (
            <p>
              You have unsaved changes! If you leave, your unsaved changes will be discarded and
              your work will be lost.
            </p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup space="medium" variant="block">
          <Button variant="outline" onClick={onLeave}>
            {leaveLabel || 'Leave without saving'}
          </Button>
          {isSaveDisabled ? (
            <Button variant="primary" icon="circle-check" disabled>
              Save changes
            </Button>
          ) : (
            <Button
              disabled={isSaving}
              variant="primary"
              icon="circle-check"
              isLoading={isSaving}
              onClick={() => {
                setIsSaving(true);
                onSave().finally(() => setIsSaving(false));
              }}
            >
              Save changes
            </Button>
          )}
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default CatchUnsavedChangesModal;
