import type { Map } from 'immutable';

import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';
import { getCurrentAutomationId } from './helpers';

export const useAutomationId = () => {
  const automationId = useStores(
    () =>
      getCurrentAutomationId(
        RoutesStore.getRouterState(),
        InstalledComponentsStore.getAllMetadata() as Map<string, any>,
      ),
    [],
    [RoutesStore, InstalledComponentsStore],
  );

  return automationId;
};
