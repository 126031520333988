import { useState } from 'react';
import { Map } from 'immutable';

import { Search } from '@keboola/design';

import { componentTypes } from '@/constants/componentTypes';
import { getFolderFromMetadata } from '@/modules/components/helpers';
import BlankComponentsPage from '@/modules/components/react/pages/BlankComponentsPage';
import TransformationTabs from '@/modules/components/react/pages/TransformationTabs';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { routeNames } from './constants';
import TransformationsConfigs from './TransformationsConfigs';

const Index = () => {
  const state = useStores(
    () => {
      return {
        hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
        installedComponents: InstalledComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
        componentsMetadata: InstalledComponentsStore.getAllMetadata() as Map<string, any>,
      };
    },
    [],
    [ApplicationStore, InstalledComponentsStore],
  );

  const [filterQuery, setFilterQuery] = useState(
    RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
  );

  const getPlaceholder = () => {
    const transformations = state.installedComponents
      .flatMap((transformation: Map<string, any>) => transformation.get('configurations'))
      .count();

    const folders = state.installedComponents
      .map((transformation: Map<string, any>) => {
        return transformation
          .get('configurations', Map())
          .map((configuration: Map<string, any>) => {
            return getFolderFromMetadata(
              state.componentsMetadata.getIn([transformation.get('id'), configuration.get('id')]),
            );
          });
      })
      .flatten(1)
      .filter(Boolean)
      .toSet();

    if (!folders.isEmpty()) {
      return `Search folders (${folders.count()}) and transformations (${transformations})`;
    }

    return `Search transformations (${transformations})`;
  };

  return (
    <TransformationTabs activeTab={routeNames.ROOT}>
      {state.installedComponents.isEmpty() && state.hasPayAsYouGo ? (
        <BlankComponentsPage type="transformation" />
      ) : (
        <>
          <Search
            defaultValue={filterQuery}
            onChange={(query) => {
              setFilterQuery(query);
              RoutesStore.getRouter().updateQuery({ q: query });
            }}
            placeholder={getPlaceholder()}
            className="tw-mb-6"
          />
          <TransformationsConfigs filterQuery={filterQuery} />
        </>
      )}
    </TransformationTabs>
  );
};

export default Index;
