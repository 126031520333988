import ImmutableRendererMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { Search } from '@keboola/design';

import { canManageTokens } from '@/modules/admin/privileges';
import EventTriggersStore from '@/modules/event-trigger/EventTriggersStore';
import OrchestrationsActionCreators from '@/modules/orchestrations/ActionCreators';
import { ORCHESTRATIONS_LIST_SORT_BY_NAME_OPTIONS } from '@/modules/orchestrations/Constants';
import OrchestrationStore from '@/modules/orchestrations/stores/OrchestrationsStore';
import { SortIcon } from '@/react/common';
import NoResultsFound from '@/react/common/NoResultsFound';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { matchByWords } from '@/utils';
import BlankOrchestrationPage from './BlankOrchestrationPage';
import OrchestrationRow from './OrchestrationRow';

const OrchestrationsIndex = createReactClass({
  mixins: [
    createStoreMixin(OrchestrationStore, EventTriggersStore, ApplicationStore),
    ImmutableRendererMixin,
  ],

  getStateFromStores() {
    return {
      orchestrations: OrchestrationStore.getAll(),
      allTasks: OrchestrationStore.getAllOrchestrationsTasks(),
      allTasksToRun: OrchestrationStore.getAllOrchestrationsTasksToRun(),
      pendingActions: OrchestrationStore.getPendingActions(),
      isLoading: OrchestrationStore.getIsLoading(),
      isLoaded: OrchestrationStore.getIsLoaded(),
      sortByNameOption: OrchestrationStore.getSortByNameOption(),
      triggers: EventTriggersStore.getForComponent('orchestrator'),
      sapiTokenCanManageTokens: canManageTokens(ApplicationStore.getSapiToken()),
      readOnly: ApplicationStore.isReadOnly(),
    };
  },

  getInitialState() {
    return {
      filter: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
    };
  },

  render() {
    if (!this.state.orchestrations.count()) {
      return <BlankOrchestrationPage canManageTokens={this.state.sapiTokenCanManageTokens} />;
    }

    return (
      <>
        <Search
          defaultValue={this.state.filter}
          onChange={(query) => {
            this.setState({ filter: query });
            RoutesStore.getRouter().updateQuery({ q: query });
          }}
          className="tw-mb-6"
        />
        {this.renderTable()}
      </>
    );
  },

  renderTable() {
    const orchestrationsFiltered = this.getOrchestrationsFiltered();

    if (!orchestrationsFiltered.count()) {
      return <NoResultsFound entityName="orchestrations" />;
    }

    return (
      <div className="box">
        <div className="table table-hover">
          <div className="thead">
            <div className="tr">
              <span className="th w-50" />
              <span className="th clickable" title="Sort by name" onClick={this.sortByNameOption}>
                Name
                <SortIcon
                  className="icon-addon-left"
                  isSorted={this.state.sortByNameOption !== null}
                  isSortedDesc={
                    this.state.sortByNameOption === ORCHESTRATIONS_LIST_SORT_BY_NAME_OPTIONS.DESC
                  }
                />
              </span>
              <span className="th w-250">Schedule</span>
              <span className="th w-150 text-right">Duration</span>
              <span className="th w-150 text-right">
                {this.state.sortByNameOption === null && <SortIcon isSorted />}
                Last Run
              </span>
            </div>
          </div>
          <div className="tbody">{this.renderTableBody(orchestrationsFiltered)}</div>
        </div>
      </div>
    );
  },

  renderTableBody(orchestrations) {
    if (this.state.sortByNameOption) {
      orchestrations = orchestrations.sortBy((orchestration) =>
        orchestration.get('name').toLowerCase(),
      );

      if (this.state.sortByNameOption === ORCHESTRATIONS_LIST_SORT_BY_NAME_OPTIONS.DESC) {
        orchestrations = orchestrations.reverse();
      }
    }

    return orchestrations
      .map((orchestration) => {
        const tasks = this.state.allTasksToRun.get(orchestration.get('id'))
          ? this.state.allTasksToRun.get(orchestration.get('id'))
          : this.state.allTasks.get(orchestration.get('id'));

        return (
          <OrchestrationRow
            orchestration={orchestration}
            readOnly={this.state.readOnly}
            pendingActions={this.state.pendingActions.get(orchestration.get('id'), Map())}
            key={orchestration.get('id')}
            hasTrigger={this.state.triggers.has(orchestration.get('id').toString())}
            tasks={tasks}
            isOrchestrationActiveButtonDisabled={!this.state.sapiTokenCanManageTokens}
          />
        );
      })
      .toArray();
  },

  getOrchestrationsFiltered() {
    if (!this.state.filter) {
      return this.state.orchestrations;
    }

    return this.state.orchestrations.filter((orchestration) => {
      return matchByWords(orchestration.get('name'), this.state.filter);
    });
  },

  sortByNameOption() {
    let sortByName = null;

    if (!this.state.sortByNameOption) {
      sortByName = ORCHESTRATIONS_LIST_SORT_BY_NAME_OPTIONS.ASC;
    } else if (this.state.sortByNameOption === ORCHESTRATIONS_LIST_SORT_BY_NAME_OPTIONS.ASC) {
      sortByName = ORCHESTRATIONS_LIST_SORT_BY_NAME_OPTIONS.DESC;
    }

    OrchestrationsActionCreators.setOrchestrationsListSortByNameOption(sortByName);
  },
});

export default OrchestrationsIndex;
