import { cn } from '../../utils';

import type { Height } from './Skeleton';
import { Skeleton } from './Skeleton';

const duplicateArray = (arr: string[], times: number) => {
  return Array(times).fill(arr).flat();
};

const Item = ({
  className,
  iconHeight = 'md',
  rowHeight = 'sm',
  iconClassName = 'tw-w-6 ',
}: {
  className?: string;
  iconHeight?: Height;
  rowHeight?: Height;
  iconClassName?: string;
}) => (
  <div className="tw-flex tw-items-center tw-gap-3">
    <Skeleton height={iconHeight} className={cn('tw-rounded-full', iconClassName)} />
    <Skeleton height={rowHeight} className={cn('tw-w-40', className)} />
  </div>
);

export const SkeletonList = ({
  hasHeading = false,
  className,
  iconHeight = 'md',
  rowHeight = 'sm',
  iconClassName,
}: {
  hasHeading?: boolean;
  className?: string;
  iconHeight?: Height;
  rowHeight?: Height;
  iconClassName?: string;
}) => (
  <div className={cn('tw-flex tw-flex-col tw-gap-4', className)}>
    {hasHeading ? <Skeleton height="xxs" className="tw-w-28" /> : null}

    <div className="tw-flex tw-flex-col tw-gap-4">
      {duplicateArray(['tw-w-96', 'tw-w-60', 'tw-w-72', 'tw-w-52'], 3).map((className, index) => (
        <Item
          key={index}
          iconHeight={iconHeight}
          rowHeight={rowHeight}
          iconClassName={iconClassName}
          className={className}
        />
      ))}
    </div>
  </div>
);
