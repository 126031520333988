import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { fromJS, List, Map } from 'immutable';

import { cn, Icon, Tooltip } from '@keboola/design';

import keyCodes from '@/constants/keyCodes';
import StorageTableLink from '@/modules/components/react/components/StorageApiTableLinkEx';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import StorageApi from '@/modules/components/StorageApi';
import { exportTables } from '@/modules/storage/actions';
import ExportModal from '@/modules/storage/components/ExportModal';
import { getBucketDisplayNameFromName } from '@/modules/storage/helpers';
import { routeNames as tableBrowserRouteNames } from '@/modules/table-browser/constants';
import { FileSize } from '@/react/common';
import BucketStageLabel from '@/react/common/BucketStageLabel';
import DevBranchLabel from '@/react/common/DevBranchLabel';
import Duration from '@/react/common/Duration';
import Loader from '@/react/common/Loader';
import RowsCount from '@/react/common/RowsCount';
import RoutesStore from '@/stores/RoutesStore';
import string from '@/utils/string';
import { parse } from '@/utils/tableIdParser';

class LoadingData extends Component {
  static propTypes = {
    allTables: PropTypes.instanceOf(Map).isRequired,
    getExpectedTables: PropTypes.func.isRequired,
    job: PropTypes.instanceOf(Map).isRequired,
    isProcessing: PropTypes.bool.isRequired,
    exportingTables: PropTypes.instanceOf(Map).isRequired,
    allBuckets: PropTypes.instanceOf(Map),
    allProcessingFinished: PropTypes.func,
    simplifiedList: PropTypes.bool,
    canExportTable: PropTypes.bool,
    hasNewQueue: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    canExportTable: true,
  };

  state = {
    stats: Map(),
    tableToExport: '',
  };

  componentDidMount() {
    this.collectStats(this.props.job);

    if (!this.props.job.get('endTime')) {
      setTimeout(this.startPolling, 5000);
    }
  }

  componentDidUpdate() {
    if (this.props.job.get('endTime')) {
      setTimeout(this.stopPolling, 10000);
    }
  }

  componentWillUnmount() {
    this.stopPolling();
  }

  render() {
    if (this.props.job.isEmpty() && this.props.isProcessing) {
      return (
        <div className={cn('box-content', { 'simplified-list': this.props.simplifiedList })}>
          <Loader /> Loading job detail...
        </div>
      );
    }

    return (
      <>
        <table className={cn({ 'table-hover simplified-list': this.props.simplifiedList })}>
          {!this.props.simplifiedList && (
            <thead>
              <tr>
                <th className="pt-0">Table</th>
                <th className="w-100 text-right pt-0">Size</th>
                <th className="w-150 text-right pt-0">Rows</th>
                <th className="w-150 text-right pt-0">Duration</th>
                <th className="w-150 text-right pt-0">Status</th>
              </tr>
            </thead>
          )}
          <tbody>{this.renderTables()}</tbody>
        </table>
        {this.props.canExportTable && (
          <ExportModal
            show={!!this.state.tableToExport}
            tables={List([this.props.allTables.get(this.state.tableToExport, Map())])}
            onSubmit={(type) => {
              exportTables(List([this.props.allTables.get(this.state.tableToExport, Map())]), type);
            }}
            onHide={() => this.setState({ tableToExport: '' })}
          />
        )}
      </>
    );
  }

  renderTables() {
    return this.props
      .getExpectedTables()
      .sortBy((tableId) => tableId.toLowerCase())
      .map((tableId) => {
        return {
          tableId,
          storageTable: this.props.allTables.get(tableId, Map()),
          statsTable: this.state.stats
            .getIn(['tables', 'import', 'tables'], List())
            .find((table) => table.get('id') === tableId, null, Map()),
        };
      })
      .groupBy(({ tableId }) => {
        const { stage, bucket } = parse(tableId).parts;

        return `${stage}.${bucket}`;
      })
      .map((tables, bucketId) => {
        const [stage, displayName] = bucketId.split('.');
        const bucket =
          this.props.allBuckets?.get(bucketId) ||
          Map({ stage, displayName: getBucketDisplayNameFromName(displayName) });

        return (
          <Fragment key={bucketId}>
            {this.props.simplifiedList && (
              <tr className="no-hover bucket-row">
                <td className="pl-2 flex-container flex-start font-medium">
                  <Icon icon="folder" className="color-base icon-addon-right" />
                  <BucketStageLabel placement="left" stage={bucket.get('stage')} />
                  <DevBranchLabel bucket={bucket} />
                  {bucket.get('displayName')}
                </td>
              </tr>
            )}
            {tables
              .map(({ tableId, storageTable, statsTable }) => {
                const isExporting = this.props.exportingTables.get(tableId, false);

                return (
                  <tr
                    key={tableId}
                    className={cn({
                      'table-row': this.props.simplifiedList,
                      'hoverable-actions': this.props.canExportTable,
                      'no-hover': this.props.isProcessing,
                    })}
                    {...(!this.props.isProcessing && {
                      onClick: () => this.redirectToTable(tableId),
                      onKeyDown: (event) => {
                        if (event.key === keyCodes.ENTER) {
                          this.redirectToTable(tableId);
                        }
                      },
                      role: 'button',
                      tabIndex: '0',
                    })}
                  >
                    <td
                      className={cn('no-wrap', {
                        'pl-3': this.props.simplifiedList,
                        'text-muted': this.props.isProcessing,
                      })}
                    >
                      <Icon icon="table" fixedWidth className="text-muted icon-addon-right" />
                      {this.props.isProcessing ? (
                        string.strRightBack(tableId, '.')
                      ) : (
                        <StorageTableLink
                          tableId={tableId}
                          showLabels={false}
                          {...(this.props.simplifiedList && {
                            paddingless: true,
                            showOnlyDisplayName: true,
                            className: 'link-inherit',
                          })}
                        />
                      )}
                    </td>
                    {this.props.canExportTable ? (
                      !this.props.isProcessing && (
                        <td className="pt-0 pb-0 pl-0 pr-2 no-wrap">
                          {isExporting ? (
                            <Tooltip placement="top" tooltip="Preparing Export">
                              <Loader />
                            </Tooltip>
                          ) : (
                            <Tooltip placement="top" tooltip="Export Table">
                              <Button
                                bsStyle="link"
                                className="text-muted"
                                onClick={(e) => {
                                  e.stopPropagation();

                                  this.setState({ tableToExport: tableId });
                                }}
                                disabled={storageTable.isEmpty()}
                              >
                                <Icon icon="down-to-line" />
                              </Button>
                            </Tooltip>
                          )}
                        </td>
                      )
                    ) : (
                      <>
                        <td className="text-right">
                          <FileSize size={storageTable.get('dataSizeBytes')} />
                        </td>
                        <td className="text-right">
                          <RowsCount count={storageTable.get('rowsCount')} />
                        </td>
                        <td className="text-right">
                          <Duration duration={statsTable.get('durationTotalSeconds')} />
                        </td>
                        <td className="text-right font-medium">
                          {this.renderStatus(storageTable, statsTable)}
                        </td>
                      </>
                    )}
                  </tr>
                );
              })
              .toArray()}
          </Fragment>
        );
      })
      .toArray();
  }

  renderStatus(storageTable, statsTable) {
    if (!this.props.isProcessing && statsTable.isEmpty()) {
      return <span className="text-muted">Skipped</span>;
    }

    if (storageTable.isEmpty() && statsTable.isEmpty()) {
      return <span className="text-muted">Waiting</span>;
    }

    if (!storageTable.isEmpty() && statsTable.isEmpty()) {
      return <span className="text-success">Loading</span>;
    }

    if (!statsTable.isEmpty()) {
      return <span className="text-success">Finished</span>;
    }

    return <span className="text-muted">N/A</span>;
  }

  collectStats = (job) => {
    const runId = this.props.hasNewQueue ? job.get('id') : job.get('runId');

    if (!runId) {
      return;
    }

    this.cancellablePromise = StorageApi.getRunIdStats(runId)
      .then((data) => {
        const stats = fromJS(data);
        const statsImportTables = stats.getIn(['tables', 'import', 'tables'], List());
        const allProcessingFinished = this.props
          .getExpectedTables()
          .map((tableId) => statsImportTables.find((table) => table.get('id') === tableId))
          .every(Boolean);

        if (allProcessingFinished) {
          this.props.allProcessingFinished?.();
        }

        this.setState({ stats });
      })
      .then(() => StorageActionCreators.loadBucketsAndTablesForce());
  };

  startPolling = () => {
    this.timeout = setInterval(() => this.collectStats(this.props.job), 5000);
  };

  stopPolling = () => {
    if (this.timeout) {
      clearInterval(this.timeout);
    }
    if (this.cancellablePromise) {
      this.cancellablePromise.cancel();
    }
  };

  redirectToTable = (tableId) => {
    RoutesStore.getRouter().transitionTo(
      tableBrowserRouteNames.ROOT,
      { tableId },
      { context: window.location.pathname },
    );
  };
}

export default LoadingData;
