import { useState } from 'react';
import type Promise from 'bluebird';
import type { Map } from 'immutable';

import * as certificateMapping from '@/modules/ex-generic/mapping/certificate';
import * as debugMapping from '@/modules/ex-generic/mapping/debug';
import { prepareMapToApiFn, prepareMapToStateFn } from '@/modules/ex-generic/mapping/helpers';
import * as retryMapping from '@/modules/ex-generic/mapping/retry';
import * as headersMapping from '@/modules/ex-generic/mapping/rootHeaders';
import * as paramsMapping from '@/modules/ex-generic/mapping/rootParams';
import * as timeoutMapping from '@/modules/ex-generic/mapping/timeout';
import CatchUnsavedChanges from '@/react/common/CatchUnsavedChanges';
import SaveButtons from '@/react/common/SaveButtons';
import fromJSOrdered from '@/utils/fromJSOrdered';
import Certificate from './Certificate';
import Debug from './Debug';
import Headers from './Headers';
import Params from './Params';
import Retry from './Retry';
import Timeout from './Timeout';

const MAPPINGS = [
  headersMapping,
  paramsMapping,
  retryMapping,
  timeoutMapping,
  certificateMapping,
  debugMapping,
];

const mapToState = prepareMapToStateFn(MAPPINGS);
const mapToApi = prepareMapToApiFn(MAPPINGS);

const AdvancedSettings = (props: {
  readOnly: boolean;
  parameters: Map<string, any>;
  onSave: (parameters: Map<string, any>, changeDescription: string) => Promise<any>;
}) => {
  const preparedParameters = mapToState(props.parameters);

  const [editing, setEditing] = useState(preparedParameters);
  const [saving, setSaving] = useState(false);

  const isChanged = !editing.equals(preparedParameters);

  const prepareSectionProps = (key: string) => {
    return {
      onSave: props.onSave,
      readOnly: props.readOnly,
      parameters: props.parameters,
      editing: editing.get(key),
      setEditing: (values: Map<string, any>) => setEditing(editing.set(key, values)),
    };
  };

  const handleSave = () => {
    setSaving(true);
    return props
      .onSave(mapToApi(props.parameters, editing), 'Update advanced settings')
      .then((configData: Record<string, any>) => {
        setEditing(mapToState(fromJSOrdered(configData.parameters)));
      })
      .finally(() => setSaving(false));
  };

  const handleReset = () => setEditing(preparedParameters);

  return (
    <>
      <div className="tw-flex tw-items-start tw-justify-between">
        <h2 className="tw-mb-5 tw-mt-1 tw-text-2xl tw-font-normal">Advanced HTTP Settings</h2>
        <CatchUnsavedChanges
          isDirty={isChanged && !saving}
          onSave={handleSave}
          onDirtyLeave={handleReset}
        >
          <SaveButtons
            isSaving={saving}
            isChanged={isChanged}
            onSave={handleSave}
            onReset={handleReset}
            className="tw-inline-flex"
          />
        </CatchUnsavedChanges>
      </div>
      <Params {...prepareSectionProps(paramsMapping.KEY)} />
      <Headers {...prepareSectionProps(headersMapping.KEY)} title="Default Headers" />
      <Retry {...prepareSectionProps(retryMapping.KEY)} />
      <Timeout {...prepareSectionProps(timeoutMapping.KEY)} />
      <Certificate {...prepareSectionProps(certificateMapping.KEY)} />
      <Debug {...prepareSectionProps(debugMapping.KEY)} />
    </>
  );
};

export default AdvancedSettings;
