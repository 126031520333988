import { useState } from 'react';
import { fromJS, List, Map } from 'immutable';
import qs from 'qs';

import { Card, cn, Search, Separator } from '@keboola/design';

import flowBuilderTeaserImage from '../../../images/illustrations/flow-builder-teaser.png';
import templatesTeaserImage from '../../../images/illustrations/templates-teaser.png';

import { KEBOOLA_ORCHESTRATOR, KEBOOLA_SCHEDULER } from '@/constants/componentIds';
import NewAutomation from '@/modules/automations/NewAutomation';
import { getFolderFromMetadata } from '@/modules/components/helpers';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import EventTriggersStore from '@/modules/event-trigger/EventTriggersStore';
import NotificationsStore from '@/modules/notifications/store';
import { prepareOrchestrations } from '@/modules/orchestrations-v2/helpers';
import { JOB_FAILED_STATUSES } from '@/modules/queue/constants';
import JobsStore from '@/modules/queue/store';
import { activeSchedules } from '@/modules/scheduler/helpers';
import { routeNames as templateRouteNames } from '@/modules/templates/constants';
import { RouterLink } from '@/react/common';
import NoResultsFound from '@/react/common/NoResultsFound';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { matchByWords } from '@/utils';
import { fileUrl } from '@/utils/fileHelpers';
import FlowConfigs from './components/FlowConfigs';
import NewFlowButton from './components/NewFlowButton';
import { FLOW } from './constants';

const FILTERS_GROUP = {
  SCHEDULE: 'schedule',
  STATUS: 'status',
  CONFIGURATION: 'configuration',
} as const;

const FILTERS = {
  ALL: '',
  SCHEDULED: 'scheduled',
  NO_SCHEDULE: 'no-schedule',
  FAILED: 'failed',
  NO_CONFIGURATION: 'no-configuration',
} as const;

const Index = () => {
  const initialFilter = RoutesStore.getRouterState().getIn(['location', 'query', 'filter']);

  const [filters, setFilters] = useState<Map<string, any>>(
    initialFilter ? fromJS(qs.parse(initialFilter)) : Map(),
  );
  const [filterQuery, setFilterQuery] = useState<string>(
    RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
  );

  const store = useStores(
    () => {
      const flows = prepareOrchestrations(
        InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
        InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SCHEDULER),
      );

      return {
        flows,
        allComponents: ComponentsStore.getAll() as Map<string, any>,
        allConfigurations: InstalledComponentsStore.getAll() as Map<string, any>,
        componentsMetadata: InstalledComponentsStore.getAllMetadata() as Map<string, any>,
        latestJobs: JobsStore.getLatestConfigJobs(),
        component: ComponentsStore.getComponent(KEBOOLA_ORCHESTRATOR),
        triggers: EventTriggersStore.getForComponent(KEBOOLA_ORCHESTRATOR),
        hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
        currentAdmin: ApplicationStore.getCurrentAdmin(),
        sapiToken: ApplicationStore.getSapiToken(),
        notifications: NotificationsStore.getAll(),
        readOnly: ApplicationStore.isReadOnly(),
        admins: ApplicationStore.getAdmins(),
        hasTemplates: ApplicationStore.hasTemplates(),
        hasAiAutomations: ApplicationStore.hasAiAutomations(),
        isDevModeActive: DevBranchesStore.isDevModeActive(),
        expandedFolders: InstalledComponentsStore.getExpandedFolders(FLOW),
      };
    },
    [],
    [
      ApplicationStore,
      ComponentsStore,
      InstalledComponentsStore,
      EventTriggersStore,
      NotificationsStore,
      JobsStore,
      DevBranchesStore,
    ],
  );

  const renderAdditionalActionsButton = (
    type: (typeof FILTERS)[keyof typeof FILTERS],
    group: (typeof FILTERS_GROUP)[keyof typeof FILTERS_GROUP] | null,
    label: string,
  ) => {
    const active = group ? filters.get(group) === type : filters.isEmpty();

    return (
      <button
        type="button"
        className={cn('btn predefined-search-link !tw-m-0', { active })}
        onClick={() => {
          const newFilters = group
            ? active
              ? filters.remove(group)
              : filters.set(group, type)
            : (Map() as Map<string, any>);

          setFilters(newFilters);

          RoutesStore.getRouter().updateQuery({ filter: qs.stringify(newFilters.toJS()) });
        }}
      >
        {label}
      </button>
    );
  };

  const getActiveSchedules = (flow: Map<string, any>) => {
    return activeSchedules(store.triggers.get(flow.get('id'), Map()), flow.get('schedulers'));
  };

  const getFilteredFlows = () => {
    let filteredFlows = store.flows;

    if (filterQuery) {
      filteredFlows = filteredFlows.filter((flow: Map<string, any>) => {
        const folder = getFolderFromMetadata(
          store.componentsMetadata.getIn([KEBOOLA_ORCHESTRATOR, flow.get('id')], List()),
        );

        return (
          flow.get('id') === filterQuery ||
          matchByWords([flow.get('name'), flow.get('description'), folder], filterQuery)
        );
      });
    }

    filters.forEach((filter: string) => {
      switch (filter) {
        case FILTERS.SCHEDULED:
          filteredFlows = filteredFlows.filter((flow: Map<string, any>) => {
            return getActiveSchedules(flow) > 0;
          });
          break;

        case FILTERS.NO_SCHEDULE:
          filteredFlows = filteredFlows.filter((flow: Map<string, any>) => {
            return getActiveSchedules(flow) === 0;
          });
          break;

        case FILTERS.FAILED:
          filteredFlows = filteredFlows.filter((flow: Map<string, any>) => {
            return JOB_FAILED_STATUSES.includes(
              store.latestJobs.getIn([KEBOOLA_ORCHESTRATOR, flow.get('id'), 0, 'status']),
            );
          });
          break;

        case FILTERS.NO_CONFIGURATION:
          filteredFlows = filteredFlows.filter((flow: Map<string, any>) => {
            return flow
              .getIn(['configuration', 'tasks'], List())
              .every((task: Map<string, any>) => !task.getIn(['task', 'configId']));
          });
          break;
      }
    });

    return filteredFlows;
  };

  const getPlaceholder = (filteredFlows: Map<string, any>) => {
    const folders = filteredFlows
      .map((flow: Map<string, any>) => {
        return getFolderFromMetadata(
          store.componentsMetadata.getIn([KEBOOLA_ORCHESTRATOR, flow.get('id')]),
        );
      })
      .filter(Boolean)
      .toSet();

    if (!folders.isEmpty()) {
      return `Search${
        filters.isEmpty() ? ' all' : ''
      } folders (${folders.count()}) and flows (${filteredFlows.count()})`;
    }

    return `Search${filters.isEmpty() ? ' all' : ''} flows (${filteredFlows.count()})`;
  };

  if (store.flows.isEmpty()) {
    return (
      <div className="blank-page tw-pt-12">
        <h2>Create your first flow!</h2>
        <h3>
          {store.hasAiAutomations && !store.readOnly
            ? 'Choose the method that works best for you: use AI assistance, build it yourself, or start with a template.'
            : 'Choose the method that works best for you: build it yourself or start with a template.'}
        </h3>
        <div className="tw-my-12 tw-flex tw-flex-wrap tw-justify-center tw-gap-6 tw-text-left">
          {store.hasAiAutomations && !store.readOnly && (
            <NewAutomation hideExamples className="tw-basis-full" />
          )}
          <Card className="tw-max-w-[50%] tw-flex-1">
            <Card.Header>
              <Card.Title>Build It Yourself</Card.Title>
            </Card.Header>
            <Card.Content className="tw-grow">
              <div className="tw-flex tw-h-full tw-gap-11">
                <div className="tw-flex tw-flex-col tw-items-start tw-justify-between tw-gap-6">
                  If you know exactly what you need, build your pipeline effortlessly with our
                  intuitive drag-and-drop builder.
                  <NewFlowButton
                    size="large"
                    component={store.component}
                    readOnly={store.readOnly}
                    componentMetadata={store.componentsMetadata.get(KEBOOLA_ORCHESTRATOR, Map())}
                  />
                </div>
                <img
                  src={fileUrl(flowBuilderTeaserImage)}
                  loading="lazy"
                  alt="Flow Diagram"
                  height="108"
                  className="-tw-mt-4"
                />
              </div>
            </Card.Content>
          </Card>
          {!store.isDevModeActive && store.hasTemplates && (
            <Card className="tw-flex-1">
              <Card.Header>
                <Card.Title>Use a Template</Card.Title>
              </Card.Header>
              <Card.Content className="tw-grow">
                <div className="tw-flex tw-h-full tw-gap-11">
                  <div className="tw-flex tw-flex-col tw-items-start tw-justify-between tw-gap-6">
                    Choose from a variety of pre-built pipeline templates to get started quickly.
                    <RouterLink
                      className="btn btn-default btn-big"
                      to={templateRouteNames.TEMPLATES}
                      query={{ flowId: 'new' }}
                      disabled={store.readOnly}
                    >
                      Use Template
                    </RouterLink>
                  </div>
                  <img
                    src={fileUrl(templatesTeaserImage)}
                    loading="lazy"
                    alt="Templates"
                    height="108"
                    className="-tw-mt-4"
                  />
                </div>
              </Card.Content>
            </Card>
          )}
        </div>
      </div>
    );
  }

  const filteredFlows = getFilteredFlows();

  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <Search
        defaultValue={filterQuery}
        onChange={(query) => {
          setFilterQuery(query);
          RoutesStore.getRouter().updateQuery({ q: query });
        }}
        placeholder={getPlaceholder(filteredFlows)}
        suffix={
          <div className="tw-flex tw-gap-2 tw-whitespace-nowrap">
            {renderAdditionalActionsButton(FILTERS.ALL, null, 'ALL')}
            <Separator orientation="vertical" className="tw-mx-2" size="1/2" />
            {renderAdditionalActionsButton(FILTERS.SCHEDULED, FILTERS_GROUP.SCHEDULE, 'Scheduled')}
            {renderAdditionalActionsButton(
              FILTERS.NO_SCHEDULE,
              FILTERS_GROUP.SCHEDULE,
              'Not scheduled',
            )}
            <Separator orientation="vertical" className="tw-mx-2" size="1/2" />
            {renderAdditionalActionsButton(FILTERS.FAILED, FILTERS_GROUP.STATUS, 'Failed')}
            <Separator orientation="vertical" className="tw-mx-2" size="1/2" />
            {renderAdditionalActionsButton(
              FILTERS.NO_CONFIGURATION,
              FILTERS_GROUP.CONFIGURATION,
              'No configuration',
            )}
          </div>
        }
      />
      {filteredFlows.isEmpty() ? (
        <NoResultsFound entityName="flows" />
      ) : (
        <FlowConfigs
          hasFlows
          configurations={filteredFlows}
          allConfigurations={store.allConfigurations}
          admins={store.admins}
          currentAdmin={store.currentAdmin}
          triggers={store.triggers}
          readOnly={store.readOnly}
          component={store.component}
          latestJobs={store.latestJobs}
          isSearching={!!filterQuery}
          allComponents={store.allComponents}
          componentsMetadata={store.componentsMetadata}
          notifications={store.notifications}
          sapiToken={store.sapiToken}
          hasProtectedDefaultBranch={store.hasProtectedDefaultBranch}
          expandedFolders={store.expandedFolders}
        />
      )}
    </div>
  );
};

export default Index;
