import { ButtonLink, Icon } from '@keboola/design';

import { routeNames } from '@/modules/components-directory/constants';
import { RouterLink } from '@/react/common';

const ComponentsListLink = () => {
  return (
    <ButtonLink>
      <RouterLink to={routeNames.ROOT} onlyActiveOnIndex>
        <Icon icon="plus" />
        Add Component
      </RouterLink>
    </ButtonLink>
  );
};

export default ComponentsListLink;
