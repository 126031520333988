import { Alert } from '@keboola/design';

const IncrementalResetWarning = () => {
  return (
    <Alert
      variant="error"
      title="Warning: The date of last import will be cleared for all tables in the entire configuration."
      className="tw-mb-5"
    >
      <p>
        Running this part of the configuration will cause incremental processing to be reset. The
        next job of the configuration will process all source data without filter and may run
        considerably longer. To avoid this, run only the entire configuration.
      </p>
    </Alert>
  );
};

export default IncrementalResetWarning;
