import type { ComponentProps } from 'react';
import * as RRadioGroup from '@radix-ui/react-radio-group';
import { cva } from 'class-variance-authority';

import { cn } from '../../utils';

import type { Orientation, Variant } from './context';
import { RadioGroupProvider } from './context';
import { RadioGroupItem } from './RadioGroupItem';
import { RadioGroupSeparator } from './RadioGroupSeparator';

const radioGroupVariants = cva(['tw-flex', 'tw-gap-2'], {
  variants: {
    orientation: {
      horizontal: [],
      vertical: ['tw-flex-col'],
    },
    variant: {
      button: [],
      radio: [],
      'radio-block': ['tw-w-full'],
    },
  },
});

type Props<Value> = {
  variant?: Variant;
  orientation?: Orientation;
  onChange?: (value: Value) => void;
  value?: Value | null;
  defaultValue?: Value | null;
} & Omit<
  ComponentProps<typeof RRadioGroup.Root>,
  'asChild' | 'orientation' | 'onValueChange' | 'onChange' | 'value' | 'defaultValue'
>;

export const RadioGroup = <Value extends string = string>({
  variant = 'radio',
  className,
  disabled = false,
  orientation = 'horizontal',
  onChange,
  children,
  value,
  defaultValue,
  ...props
}: Props<Value>) => {
  return (
    <RRadioGroup.Root
      {...props}
      onValueChange={onChange}
      orientation={orientation}
      className={cn(radioGroupVariants({ orientation, variant, className }))}
      disabled={disabled}
      value={value ?? void 0}
      defaultValue={defaultValue ?? void 0}
    >
      <RadioGroupProvider value={{ variant, orientation }}>{children}</RadioGroupProvider>
    </RRadioGroup.Root>
  );
};

RadioGroup.Item = RadioGroupItem;
RadioGroup.Separator = RadioGroupSeparator;
