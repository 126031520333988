import type { ReactNode } from 'react';
import type { Map } from 'immutable';

import { Link } from '@keboola/design';

import { AVAILABLE_COUNTRIES } from '@/modules/billing/constants';

export const BillingAddress = (props: { billingInformation: Map<string, any> }) => {
  const hasBillingDetails = !!props.billingInformation.get('name');
  const country = props.billingInformation.getIn([
    'address',
    'country',
  ]) as keyof typeof AVAILABLE_COUNTRIES;

  const renderLine = (path: string[], prefix?: ReactNode, suffix?: ReactNode) => {
    if (!props.billingInformation.getIn(path)) {
      return null;
    }

    return (
      <>
        {prefix}
        {props.billingInformation.getIn(path)}
        {suffix ?? <br />}
      </>
    );
  };

  return (
    <div className="flex-container align-top">
      {hasBillingDetails && (
        <address className="fill-space line-height-2 mb-0">
          {renderLine(['name'])}
          {renderLine(['address', 'line1'])}
          {renderLine(['address', 'line2'])}
          {renderLine(['address', 'postal_code'], null, ' ')}
          {renderLine(['address', 'city'])}
          {renderLine(['address', 'state'])}
          {country && (
            <>
              {AVAILABLE_COUNTRIES[country] || country}
              <br />
            </>
          )}
        </address>
      )}
      <div className="fill-space line-height-2">
        <span className="text-muted m-0">{hasBillingDetails ? 'Other Info' : 'Billing Email'}</span>
        <br />
        {renderLine(['vat_id'], 'Vat ID: ')}
        <Link className="font-medium" href={`mailto:${props.billingInformation.get('email')}`}>
          {props.billingInformation.get('email')}
        </Link>
      </div>
    </div>
  );
};
