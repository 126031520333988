import { useEffect, useState } from 'react';
import type { ReactNode, SyntheticEvent } from 'react';
import { Button } from 'react-bootstrap';
import type Promise from 'bluebird';

import { Icon, Tooltip } from '@keboola/design';

import { Truncated } from '@/react/common';
import InlineEditTextInput from '@/react/common/InlineEditTextInput';

type Props = {
  name: string;
  readOnly: boolean;
  onSave: (name: string) => Promise<any>;
  label?: ReactNode;
};

const FolderName = (props: Props) => {
  const [folderName, setFolderName] = useState(props.name);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!isEditing && props.name !== folderName) {
      setFolderName(props.name);
    }
  }, [isEditing, props.name, folderName]);

  if (props.readOnly) {
    return (
      <span className="folder-name tw-flex tw-items-center">
        <Truncated text={props.label || folderName} twoLines />
      </span>
    );
  }

  if (isEditing) {
    return (
      <InlineEditTextInput
        isEditing
        className="tw-inline-flex tw-items-center"
        placeholder="Folder name"
        isValid={!!folderName.trim()}
        isChanged={props.name !== folderName}
        text={folderName}
        isSaving={isSaving}
        onEditChange={setFolderName}
        onEditCancel={() => setIsEditing(false)}
        onEditSubmit={() => {
          setIsSaving(true);

          return props.onSave(folderName.trim()).finally(() => setIsSaving(false));
        }}
      />
    );
  }

  return (
    <span className="folder-name tw-flex tw-items-center">
      <Truncated text={props.label || folderName} twoLines />
      <Tooltip tooltip="Rename folder" placement="top">
        <Button
          bsStyle="link"
          className="btn-link-inline btn-link-muted icon-addon-left"
          onClick={(e: SyntheticEvent) => {
            e.stopPropagation();

            setIsEditing(true);
            setFolderName(props.name);
          }}
        >
          <Icon fixedWidth icon="pen" className="f-12" />
        </Button>
      </Tooltip>
    </span>
  );
};

export default FolderName;
