import PropTypes from 'prop-types';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import { FormGroup, HelpBlock, Label, TextInput } from '@keboola/design';

import { nameWarning } from '@/modules/storage/constants';
import Select from '@/react/common/Select';
import string from '@/utils/string';

const FORM_GROUP_CLASS_NAME = 'tw-grid tw-grid-cols-3 tw-items-start tw-gap-4';

const SaveSettings = createReactClass({
  mixins: [immutableMixin],

  propTypes: {
    value: PropTypes.shape({
      tableName: PropTypes.string.isRequired,
      primaryKey: PropTypes.array.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Save Settings</h2>
        </div>
        <div className="box-content tw-flex tw-flex-col tw-gap-4">
          <FormGroup className={FORM_GROUP_CLASS_NAME}>
            <Label htmlFor="table-name">Table Name</Label>
            <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
              <TextInput
                id="table-name"
                variant="secondary"
                value={this.props.value.tableName}
                onChange={(value) => {
                  this.props.onChange({ tableName: string.sanitizeKbcTableIdString(value) });
                }}
                disabled={this.props.disabled}
              />

              <HelpBlock>
                Data will be saved to this table in the output bucket (customers and campaigns are
                reserved names and cannot be used as table names). {nameWarning}
              </HelpBlock>
            </div>
          </FormGroup>

          <FormGroup className={FORM_GROUP_CLASS_NAME}>
            <Label htmlFor="primary-key-columns">Primary Key Columns</Label>
            <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
              <Select
                id="primary-key-columns"
                name="primaryKey"
                value={this.props.value.primaryKey}
                multi
                allowCreate
                placeholder="Add a column to the primary key"
                onChange={(value) => {
                  this.props.onChange({ primaryKey: value });
                }}
                disabled={this.props.disabled}
              />
              <HelpBlock>List of columns to be used as primary key</HelpBlock>
            </div>
          </FormGroup>
        </div>
      </div>
    );
  },
});

export default SaveSettings;
