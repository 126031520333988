import type { PropsWithChildren } from 'react';

import { Icon } from '@keboola/design';

type Props = {
  title: string;
  tableName: string;
};

export const SqlTableCard = ({ title, children, tableName }: PropsWithChildren<Props>) => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <div className="tw-flex tw-flex-col tw-gap-2">
        <h4 className="uppercase tw-m-0 tw-text-xs tw-font-medium tw-text-neutral-200">{title}</h4>

        <div className="tw-flex tw-items-center tw-gap-2 tw-pl-0.5 tw-text-sm tw-font-normal">
          <Icon className="tw-text-neutral-200" icon="table" />
          <span>{tableName}</span>
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-gap-2">
        <h4 className="uppercase tw-m-0 tw-flex tw-items-center tw-gap-1 tw-text-xs tw-font-medium tw-text-neutral-200">
          Sql query syntax
        </h4>

        <div className="tw-flex tw-flex-col tw-gap-2">{children}</div>
      </div>
    </div>
  );
};
