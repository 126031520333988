import createReactClass from 'create-react-class';

import { Icon } from '@keboola/design';

import { canCreateToken } from '@/modules/admin/privileges';
import { routeNames } from '@/modules/settings/constants';
import { RouterLink as Link } from '@/react/common';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';

const NewTokenHeaderButtons = createReactClass({
  mixins: [createStoreMixin(ApplicationStore)],

  getStateFromStores() {
    return {
      sapiToken: ApplicationStore.getSapiToken(),
    };
  },

  render() {
    if (!canCreateToken(this.state.sapiToken)) {
      return null;
    }

    return (
      <Link className="btn btn-success" to={routeNames.NEW_TOKEN}>
        <Icon icon="plus" className="icon-addon-right" />
        New Token
      </Link>
    );
  },
});

export default NewTokenHeaderButtons;
