import { format } from 'd3-format';
import type { Map } from 'immutable';

import { STACKS } from '@keboola/constants';

import dayjs from '@/date';
import {
  CREDITS_DISCOUNTS,
  INVOICE_STATUS,
  MINUTES_PER_CREDIT,
  TOP_UP_INVOICE_TYPE,
} from './constants';
import type { Purchase } from './store';

const priceFormatter = format('$,.2f');
const twoDecimalsFormatter = format('.2~f');

const convertCreditsToMinutes = (creditsCount: number) => {
  const minutes = creditsCount * MINUTES_PER_CREDIT;

  return minutes > 1000 ? Math.round(minutes) : twoDecimalsFormatter(minutes);
};

const convertCreditsToHours = (creditsCount: number) => {
  return Math.round((creditsCount * MINUTES_PER_CREDIT) / 60);
};

const convertCreditsToPrice = (credits: number, creditPrice: number) => {
  const discount =
    credits in CREDITS_DISCOUNTS ? CREDITS_DISCOUNTS[credits as keyof typeof CREDITS_DISCOUNTS] : 0;
  const price = convertCreditsToRawPrice(credits, creditPrice);

  return formatPrice(price - (price * discount) / 100);
};

const convertCreditsToRawPrice = (credits: number, creditPrice: number) => {
  return (credits * creditPrice) / 100;
};

const convertCreditsToPriceWithoutDiscount = (credits: number, creditPrice: number) => {
  return formatPrice(convertCreditsToRawPrice(credits, creditPrice));
};

const formatPrice = (price: number) => priceFormatter(price);

const parseBillingInformation = (billingInformation: Map<any, any>, fallbackEmail = '') => ({
  name: billingInformation.get('name') ?? '',
  email: billingInformation.get('email') ?? fallbackEmail,
  addressCountry: billingInformation.getIn(['address', 'country']) ?? '',
  addressLine1: billingInformation.getIn(['address', 'line1']) ?? '',
  addressLine2: billingInformation.getIn(['address', 'line2']) ?? '',
  addressCity: billingInformation.getIn(['address', 'city']) ?? '',
  addressPostalCode: billingInformation.getIn(['address', 'postal_code']) ?? '',
  vatId: billingInformation.get('vat_id') ?? '',
});

const getTopUpPurchasesFromCurrentMonth = (purchases: Purchase[]) => {
  return purchases.filter((purchase) => {
    return (
      purchase.invoiceTopUpType === TOP_UP_INVOICE_TYPE &&
      purchase.stripeInvoiceStatus === INVOICE_STATUS.PAID &&
      dayjs(purchase.created).isSame(dayjs(), 'month')
    );
  });
};

const isVatIdValid = (vatId: string, country: string) => {
  if (vatId && country === 'CZ') {
    return /^CZ[0-9]{8,10}$/.test(vatId);
  }

  return true;
};

const hasActivityCenter = (project: Map<any, any>) => {
  return (
    project.has('id') &&
    project.getIn(['organization', 'activityCenterProjectId']) === project.get('id')
  );
};

const hasOrganizationUsage = (project: Map<any, any>, stackId: string) => {
  return (
    !hasActivityCenter(project) &&
    !!project.getIn(['organization', 'crmId']) &&
    stackId !== STACKS.SLSP.id
  );
};

export {
  formatPrice,
  convertCreditsToMinutes,
  convertCreditsToHours,
  convertCreditsToPrice,
  convertCreditsToRawPrice,
  convertCreditsToPriceWithoutDiscount,
  parseBillingInformation,
  isVatIdValid,
  getTopUpPurchasesFromCurrentMonth,
  hasActivityCenter,
  hasOrganizationUsage,
};
