import { ControlLabel, FormGroup } from 'react-bootstrap';

import { SHARED_TYPES, SHARED_TYPES_OPTIONS } from '@/modules/data-catalog/constants';
import Select from '@/react/common/Select';

const ShareWithSelect = (props: {
  value: string;
  onChange: (selected: string) => void;
  hasUsersOptions: boolean;
  hasProjectsOptions: boolean;
  disabled?: boolean;
}) => {
  return (
    <FormGroup controlId="formShareWithControl">
      <ControlLabel>Shared with</ControlLabel>
      <Select
        id="formShareWithControl"
        clearable={false}
        value={props.value}
        onChange={props.onChange}
        options={SHARED_TYPES_OPTIONS.map((option) => {
          if (option.value === SHARED_TYPES.SELECTED_PEOPLE && !props.hasUsersOptions) {
            return { ...option, isDisabled: true, disabledReason: 'No users available' };
          }

          if (option.value === SHARED_TYPES.SELECTED_PROJECT && !props.hasProjectsOptions) {
            return { ...option, isDisabled: true, disabledReason: 'No projects available' };
          }

          return option;
        })}
        disabled={props.disabled}
      />
    </FormGroup>
  );
};

export default ShareWithSelect;
