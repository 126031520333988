import { useState } from 'react';
import { useProductFruitsApi } from 'react-product-fruits';
import { type Map } from 'immutable';

import { Badge, cn, Icon } from '@keboola/design';

import CollapsibleBox from '@/react/common/CollapsibleBox';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';

type Checklist = {
  id: number;
  name: string;
  title: string;
  description: string;
  items: Task[];
  state: 'not_touched' | 'in_progress' | 'finished';
};

type Task = {
  id: string;
  internalId: string | undefined;
  title: string;
  state: 'tbd' | 'open' | 'done';
};

const ProductFruitsChecklists = (props: { allComponents: Map<string, any> }) => {
  const [checklists, setChecklists] = useState<Checklist[]>();

  useProductFruitsApi((api) => {
    setChecklists(
      (api?.checklists?.getChecklists() as Checklist[]).sort((a, b) =>
        a.name === 'Beginner' || (a.name === 'Intermediate' && b.name === 'Expert') ? -1 : 1,
      ),
    );
  }, []);

  if (!checklists?.length) return null;

  return (
    <div className="product-fruits-checklists">
      {checklists.map((checklist) => (
        <Checklist
          key={checklist.id}
          showInitially={checklist.state !== 'finished'}
          allComponents={props.allComponents}
          {...checklist}
        />
      ))}
    </div>
  );
};

const Checklist = ({
  id,
  name: level,
  title,
  description,
  items,
  showInitially,
  allComponents,
}: Checklist & { showInitially: boolean; allComponents: Map<string, any> }) => {
  const totalTasks = items?.length ?? 0;
  const finishedTasks = items?.filter(({ state }) => state === 'done')?.length ?? 0;
  const componentsMatch = description.match(/\[components=(.*)\]/);
  let components: string[] = [];

  if (componentsMatch) {
    description = description.replace(componentsMatch[0], '').trim();
    components = componentsMatch[1].split(',');
  }

  return (
    <CollapsibleBox
      title={
        <span className="tw-flex tw-items-center">
          {title}
          <Badge text={level} variant="blue" placement="right" />
        </span>
      }
      entity="checklist"
      defaultOpen={showInitially}
      additionalActions={() => (
        <div className="text-muted f-12">
          {totalTasks === finishedTasks ? (
            <span className="color-success">
              <Icon icon="circle-check" className="icon-addon-right f-16" fixedWidth />
              All Done
            </span>
          ) : (
            <>
              <span className={cn({ 'color-orange': finishedTasks > 0 })}>
                {finishedTasks} of {totalTasks}
              </span>
              <span> tasks completed</span>
            </>
          )}
        </div>
      )}
      childrenClassName="box-container two-columns"
    >
      <div>
        <p className="text-muted mb-0">{description}</p>
        {!!components.length && (
          <div className="box-container three-columns mtp-8">
            {components.map((componentId) => {
              const component = allComponents.find(
                (component) => component.get('id') === componentId,
              );

              if (!component) return null;
              return <ComponentBox key={componentId} component={component} />;
            })}
          </div>
        )}
      </div>
      <div
        ref={(el) => {
          if (!el || el.children.length) return;

          window.productFruits?.api?.checklists?.injectToElement(id, el);
        }}
      ></div>
    </CollapsibleBox>
  );
};

const ComponentBox = (props: { component: Map<string, any> }) => {
  return (
    <div className="box no-shadow p-0 flex-container flex-start">
      <ComponentIcon component={props.component} size="40" className="mrp-3" />
      <div className="flex-container flex-column align-top">
        <ComponentName component={props.component} showType />
      </div>
    </div>
  );
};
export default ProductFruitsChecklists;
