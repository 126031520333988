import { KEBOOLA_DATA_APPS } from '@/constants/componentIds';
import InstalledComponentsActionsCreators from '@/modules/components/InstalledComponentsActionCreators';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import createVersionsPageRoute from '@/modules/components/utils/createVersionsPageRoute';
import VersionsActionCreators from '@/modules/components/VersionsActionCreators';
import DataAppsStore from '@/modules/data-apps/DataAppsStore';
import * as NotificationActions from '@/modules/notifications/actions';
import { configPoll } from '@/utils/genericPolls';
import * as DataAppsActions from './actions';
import { DataAppState, routeNames } from './constants';
import { DataAppNameEdit } from './DataAppNameEdit';
import Detail from './Detail';
import DetailStatusLabel from './DetailStatusLabel';
import Header from './Header';
import Index from './Index';
import { NotificationsTab } from './NotificationsTab';
import RedeployInfoAlert from './RedeployInfoAlert';
import { TerminalLogsTab } from './TerminalLogsTab';

const loadDataApp = (params) => {
  const appId = InstalledComponentsStore.getConfigData(KEBOOLA_DATA_APPS, params.config).getIn([
    'parameters',
    'id',
  ]);

  if (appId) {
    return DataAppsActions.loadDataApp(appId);
  }
};

const routes = {
  name: routeNames.ROOT,
  title: 'Data Apps',
  defaultRouteHandler: Index,
  headerButtonsHandler: Header,
  requireData: [
    (params) => {
      if (params.config) {
        return Promise.resolve();
      }

      return Promise.all([
        DataAppsActions.loadDataApps(),
        InstalledComponentsActionsCreators.loadComponentConfigsData(KEBOOLA_DATA_APPS),
        InstalledComponentsActionsCreators.loadComponentsMetadata(),
      ]);
    },
  ],
  poll: {
    skipFirst: true,
    action: () => {
      return Promise.all([
        DataAppsActions.loadDataApps(),
        InstalledComponentsActionsCreators.loadComponentConfigsDataForce(KEBOOLA_DATA_APPS),
      ]);
    },
  },
  childRoutes: [
    {
      name: routeNames.DATA_APP_DETAIL,
      path: ':config',
      title: (routerState) => {
        return InstalledComponentsStore.getConfig(
          KEBOOLA_DATA_APPS,
          routerState.getIn(['params', 'config']),
        ).get('name', 'Data App');
      },
      nameEdit: DataAppNameEdit,
      defaultRouteHandler: Detail,
      labelHandler: DetailStatusLabel,
      infoAlertHandler: RedeployInfoAlert,
      poll: configPoll(KEBOOLA_DATA_APPS, loadDataApp),
      requireData: [
        () => StorageActionCreators.loadBucketsAndTables(),
        (params) => {
          return InstalledComponentsActionsCreators.loadComponentConfigData(
            KEBOOLA_DATA_APPS,
            params.config,
          ).then(() => loadDataApp(params));
        },
        (params) => VersionsActionCreators.loadVersions(KEBOOLA_DATA_APPS, params.config),
      ],
      childRoutes: [
        {
          name: routeNames.DATA_APP_TERMINAL_LOGS,
          path: 'terminal-logs',
          defaultRouteHandler: TerminalLogsTab,
          nameEdit: DataAppNameEdit,
          labelHandler: DetailStatusLabel,
          breadcrumbHandler: (props) => props.breadcrumbs[0],
          title: (routerState) => {
            return InstalledComponentsStore.getConfig(
              KEBOOLA_DATA_APPS,
              routerState.getIn(['params', 'config']),
            ).get('name', 'Data App');
          },
          poll: {
            skipFirst: true,
            interval: 2000,
            skip: (params) => {
              const appId = InstalledComponentsStore.getConfigData(
                KEBOOLA_DATA_APPS,
                params.config,
              ).getIn(['parameters', 'id']);
              if (!appId) return true;

              const app = DataAppsStore.getDataApp(appId);
              return app.state !== DataAppState.STARTING;
            },
            action: loadDataApp,
          },
        },
        {
          name: routeNames.DATA_APP_NOTIFICATIONS,
          path: 'notifications',
          title: 'Notifications',
          nameEdit: DataAppNameEdit,
          requireData: [() => NotificationActions.loadNotifications()],
          labelHandler: DetailStatusLabel,
          defaultRouteHandler: NotificationsTab,
          breadcrumbHandler: (props) => props.breadcrumbs[0],
        },
        {
          ...createVersionsPageRoute(KEBOOLA_DATA_APPS, 'config', routeNames.DATA_APP_VERSIONS, {
            links: {
              versionsLinkTo: 'versions',
              notificationsLinkTo: routeNames.DATA_APP_NOTIFICATIONS,
            },
          }),
          nameEdit: DataAppNameEdit,
          labelHandler: DetailStatusLabel,
        },
      ],
    },
  ],
};

export default routes;
