import { Modal as ReactModal } from 'react-bootstrap';

import { FileSize } from '@/react/common';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';

const Modal = (props) => {
  const renderButton = () => {
    return (
      <ConfirmButtons
        block
        saveStyle="success"
        onSave={props.onPrepareStart}
        saveLabel={'Download file'}
      />
    );
  };

  return (
    <span onClick={(e) => e.stopPropagation()}>
      <ReactModal show={props.isModalOpen} onHide={props.onModalHide}>
        <ReactModal.Header closeButton>
          <ReactModal.Title>Sliced File Download</ReactModal.Title>
          <ModalIcon icon="circle-down" color="green" bold />
        </ReactModal.Header>
        <ReactModal.Body>
          <p>
            File{' '}
            <strong>
              {props.file.get('name')} (<FileSize size={props.file.get('sizeBytes')} />)
            </strong>{' '}
            is sliced into multiple chunks.
          </p>
          <p>
            All chunks will be packed into a <code>ZIP</code> file, once it&apos;s ready the
            download will start automatically.
          </p>
        </ReactModal.Body>
        <ReactModal.Footer>{renderButton()}</ReactModal.Footer>
      </ReactModal>
    </span>
  );
};

export default Modal;
