import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import _ from 'underscore';

import { Icon } from '@keboola/design';

import createStoreMixin from '@/react/mixins/createStoreMixin';
import { GapiStore } from './GapiFlux';
import * as InitGoogleApis from './InitGoogleApis';
import templates from './PickerViewTemplates';

const GDRIVE_SCOPE = ['https://www.googleapis.com/auth/drive.file'];

const SHEETS_SCOPE = [
  'https://www.googleapis.com/auth/drive.file',
  'https://www.googleapis.com/auth/spreadsheets.readonly',
];

function setZIndex() {
  const elements = document.getElementsByClassName('picker');

  for (let i = 0; i < elements.length; i++) {
    const el = elements.item(i);
    el.style.zIndex = '1500';
  }
}

/** @type {any} */
const GooglePicker = createReactClass({
  mixins: [createStoreMixin(GapiStore)],

  propTypes: {
    dialogTitle: PropTypes.string.isRequired,
    buttonLabel: PropTypes.node.isRequired,
    onPickedFn: PropTypes.func.isRequired,
    views: PropTypes.array,
    viewGroups: PropTypes.array,
    buttonProps: PropTypes.object,
    requireSheetsApi: PropTypes.bool,
    multiselectEnabled: PropTypes.bool,
  },

  getDefaultProps() {
    return {
      dialogTitle: 'Choose',
      buttonLabel: 'Choose',
      views: [],
      requireSheetsApi: false,
      multiselectEnabled: true,
    };
  },

  getStateFromStores() {
    return { isInitialized: GapiStore.isInitialized() };
  },

  componentDidMount() {
    return InitGoogleApis.injectGapiScript();
  },

  render() {
    return (
      <Button
        bsStyle="success"
        onClick={this._ButtonClick}
        disabled={!this.state.isInitialized}
        {...this.props.buttonProps}
      >
        <Icon icon="plus" className="icon-addon-right" />
        {this.props.buttonLabel}
      </Button>
    );
  },

  getInitialState() {
    return { accessToken: null };
  },

  _ButtonClick() {
    if (this.state.accessToken) {
      return this.openGdrivePicker();
    }

    InitGoogleApis.authorize(
      this.props.requireSheetsApi ? SHEETS_SCOPE : GDRIVE_SCOPE,
      (authResult) => {
        if (authResult && !authResult.error) {
          this.setState({ accessToken: authResult.access_token }, this.openGdrivePicker);
        }
      },
    );
  },

  openGdrivePicker() {
    window.gapi.load('picker', () => {
      let picker = new window.google.picker.PickerBuilder()
        .setAppId(InitGoogleApis.appId)
        .setDeveloperKey(InitGoogleApis.apiKey)
        .enableFeature(window.google.picker.Feature.SUPPORT_TEAM_DRIVES)
        .setTitle(this.props.dialogTitle)
        .setCallback(this._onPicked)
        .setOAuthToken(this.state.accessToken);

      let views = this.props.views;
      if (_.isEmpty(views)) views = [templates.root];

      for (let group of this.props.viewGroups || []) {
        picker.addViewGroup(group());
      }
      for (let view of views) {
        picker.addView(view());
      }

      if (this.props.multiselectEnabled) {
        picker.enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED);
      }

      picker.build().setVisible(true);
      setZIndex();
    });
  },

  _onPicked(data) {
    if (data.action !== 'picked') return;
    this.props.onPickedFn(data.docs);
    return;
  },
});

export default GooglePicker;
