import type { MouseEventHandler } from 'react';

import { cn } from '../../utils';
import { Icon } from '../Icon';

type Props = {
  onClick: MouseEventHandler<HTMLButtonElement>;
  testId?: string;
};

export const ClearButton = ({ onClick, testId }: Props) => {
  // Prevent default, as we do not want to lose input focus.
  const handleMouseDown: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
  };

  return (
    <button
      type="button"
      onClick={onClick}
      tabIndex={-1} // clear button should not be focusable
      onMouseDown={handleMouseDown}
      className={cn(
        // reset
        'tw-border-none',
        'tw-bg-transparent',
        'tw-p-0',

        // positioning
        'tw-flex',
        'tw-items-center',

        'tw-cursor-pointer',
        'tw-text-neutral-300',
        'hover:tw-text-neutral-800',
      )}
      data-testid={testId}
    >
      <Icon className="tw-size-4" icon="xmark" />
    </button>
  );
};
