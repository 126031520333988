import type { Map } from 'immutable';

import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';

const CreatedFrom = (props: { from?: Map<string, any> }) => {
  if (!props.from || props.from.isEmpty()) {
    return null;
  }

  return (
    <>
      <div className="sidebar-box created-by">
        <h4 className="mb-1">Created from</h4>
        <ComponentConfigurationLink
          className="overflow-break-anywhere"
          componentId={props.from.get('componentId')}
          configId={props.from.get('configId')}
          isDeleted={props.from.get('isDeleted')}
        >
          {props.from.get('name')}
        </ComponentConfigurationLink>
      </div>
      <hr />
    </>
  );
};

const CreatedFromInline = (props: { from: Map<string, any> }) => {
  return (
    <>
      Created from:{' '}
      <ComponentConfigurationLink
        className="overflow-break-anywhere font-medium"
        componentId={props.from.get('componentId')}
        configId={props.from.get('configId')}
        isDeleted={props.from.get('isDeleted')}
      >
        {props.from.get('name')}
      </ComponentConfigurationLink>
    </>
  );
};

export { CreatedFromInline };

export default CreatedFrom;
