import { useState } from 'react';
import type { SyntheticEvent } from 'react';
import { Modal } from 'react-bootstrap';
import type { Map } from 'immutable';
import { List } from 'immutable';

import { ButtonInline, Icon } from '@keboola/design';

import type { Instance } from '@/api/routes/templatesService';
import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { routeNames } from '@/modules/flows/constants';
import { CreatedDate, Truncated } from '@/react/common';
import string from '@/utils/string';
import ModalIcon from './ModalIcon';
import { RouterLink as Link } from './RouterLink';

const FlowsListModal = (props: {
  flows: Map<string, any>;
  modalTitle: string;
  hidePlaceholder?: boolean;
}) => {
  const [showModal, setShowModal] = useState(false);

  if (props.flows.isEmpty()) {
    return (
      <span className="text-muted no-wrap">
        {props.hidePlaceholder ? (
          '-'
        ) : (
          <>
            <Icon icon="bars-staggered" className="f-16 btn-icon" />0 flows
          </>
        )}
      </span>
    );
  }

  return (
    <>
      <ButtonInline
        variant="link"
        className="flex-container inline-flex flex-start no-wrap"
        onClick={(event: SyntheticEvent) => {
          event.stopPropagation();

          setShowModal(true);
        }}
      >
        <Icon icon="bars-staggered" className="f-16 btn-icon text-muted" />
        {props.flows.count()} {string.pluralize(props.flows.count(), 'flow')}
      </ButtonInline>
      <Modal
        className="simple-modal-with-table"
        show={showModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header className="no-border" closeButton>
          <Modal.Title>
            {props.modalTitle}
            <ModalIcon icon="bars-staggered" color="blue" bold />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table table-hover">
            <div className="thead">
              <div className="tr">
                <div className="th">Name</div>
                <div className="th">Last Change</div>
              </div>
            </div>
            <div className="tbody">
              {props.flows
                .map((flow: Map<string, any>) => {
                  return (
                    <Link
                      key={flow.get('id')}
                      to={routeNames.DETAIL}
                      params={{ config: flow.get('id') }}
                      className="tr"
                    >
                      <div className="td color-main">
                        <div className="flex-container flex-start">
                          <Truncated text={flow.get('name')} />
                        </div>
                      </div>
                      <div className="td text-muted">
                        <CreatedDate createdTime={flow.getIn(['currentVersion', 'created'])} />
                      </div>
                    </Link>
                  );
                })
                .toArray()}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export const UsedInFlowsModal = (props: {
  flows: Map<string, any>;
  component: Map<string, any>;
  config: Map<string, any>;
  hidePlaceholder?: boolean;
}) => {
  const parentFlows = props.flows
    .filter((flow) => {
      return flow.getIn(['configuration', 'tasks'], List()).some((task: Map<string, any>) => {
        return (
          task.getIn(['task', 'componentId']) === props.component.get('id') &&
          task.getIn(['task', 'configId']) === props.config.get('id')
        );
      });
    })
    .toMap();

  return (
    <FlowsListModal
      flows={parentFlows}
      modalTitle={`${props.config.get('name')} - Used in flows`}
      hidePlaceholder={props.hidePlaceholder}
    />
  );
};

export const FlowsCreatedByTemplateModal = (props: {
  flows: Map<string, any>;
  instance: Instance;
}) => {
  const childFlows = props.flows.filter((flow) =>
    props.instance.configurations.some(
      ({ componentId, configId }) =>
        componentId === KEBOOLA_ORCHESTRATOR && configId === flow.get('id'),
    ),
  ) as Map<string, any>;

  return (
    <FlowsListModal
      flows={childFlows}
      modalTitle={`${props.instance.name} - Created flows`}
      hidePlaceholder
    />
  );
};
