import { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import { Alert, Icon, Tooltip } from '@keboola/design';

import {
  GENERIC_DOCKER_UI_TABLE_INPUT,
  GENERIC_DOCKER_UI_TABLE_OUTPUT,
} from '@/constants/componentFlags';
import { features as componentFeatures } from '@/modules/components/Constants';
import { unhandledRequest } from '@/modules/components/DockerActionsApi';
import {
  getDestinationTypeFromStagingStorage,
  getSourceTypeFromStagingStorage,
} from '@/modules/components/helpers';
import componentsAction from '@/modules/components/InstalledComponentsActionCreators';
import {
  hasQueries,
  resolveEditorMode,
} from '@/modules/components/react/components/generic/code-blocks/helpers';
import TableInputMapping from '@/modules/components/react/components/generic/TableInputMapping';
import TableOutputMapping from '@/modules/components/react/components/generic/TableOutputMapping';
import JSONSchemaEditor from '@/modules/components/react/components/JSONSchemaEditor';
import MappingsWrapper from '@/modules/components/react/components/MappingsWrapper';
import CodeEditor from '@/react/common/CodeEditor';
import Loader from '@/react/common/Loader';
import Markdown from '@/react/common/Markdown';
import SaveButtons from '@/react/common/SaveButtons';
import SyncActionError from '@/utils/errors/SyncActionError';
import fromJSOrdered from '@/utils/fromJSOrdered';

class GenericPatternUi extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isGenerating: false,
      jsonSchemEditorResetKey: _.uniqueId('json_schema_editor_'),
    };

    this.codeMirrorEditor = null;

    this.handleGenerate = this.handleGenerate.bind(this);
    this.handleResetParameters = this.handleResetParameters.bind(this);
    this.handleSubmitParameters = this.handleSubmitParameters.bind(this);
  }

  render() {
    if (!this.props.pattern) {
      return null;
    }

    return (
      <>
        <div className="box">
          <div className="box-header big-padding with-border">
            <h3 className="box-title">{this.props.pattern.get('name')}</h3>
          </div>
          {this.props.pattern.get('longDescription') && (
            <div className="box-content">
              <Markdown source={this.props.pattern.get('longDescription')} />
            </div>
          )}
        </div>
        <MappingsWrapper>
          {this.renderTableInputMapping()}
          {this.renderTableOutputMapping()}
        </MappingsWrapper>
        {!this.props.pattern.get('configurationSchema', Map()).isEmpty() && (
          <div className="box">
            <div className="box-header big-padding with-border">
              <h2 className="box-title">Parameters</h2>
              <SaveButtons
                isSaving={this.props.isSaving}
                isChanged={this.props.parametersIsChanged}
                disabled={!this.props.parametersIsValid}
                onSave={this.handleSubmitParameters}
                onReset={this.handleResetParameters}
              />
            </div>
            <div className="box-content">
              <JSONSchemaEditor
                key={this.state.jsonSchemEditorResetKey}
                component={this.props.component}
                schema={this.props.pattern.get('configurationSchema', Map())}
                value={fromJSOrdered(JSON.parse(this.props.parameters))}
                onChange={(value) => {
                  return this.props.parametersOnEditChange(JSON.stringify(value));
                }}
                readOnly={this.props.isSaving || this.props.readOnly}
              />
            </div>
          </div>
        )}
        {this.renderError()}
        <div className="box">
          <div className="box-header big-padding with-border">
            <h2 className="box-title">
              {hasQueries(this.props.component.get('id')) ? 'Queries' : 'Scripts'}
            </h2>
            {this.renderGenerateButton()}
          </div>
          <div className="box-content">
            <CodeEditor
              editorDidMount={(editor) => {
                this.codeMirrorEditor = editor;
              }}
              value={this.getCurrentScript()}
              options={{
                mode: resolveEditorMode(this.props.component.get('id')),
                placeholder: '-- Here will be generated code ---',
                cursorHeight: 0,
                readOnly: true,
              }}
            />
          </div>
        </div>
      </>
    );
  }

  renderTableInputMapping() {
    if (!this.props.pattern.get('flags').includes(GENERIC_DOCKER_UI_TABLE_INPUT)) {
      return null;
    }

    return (
      <TableInputMapping
        readOnly={this.props.readOnly}
        tables={this.props.tables}
        buckets={this.props.buckets}
        configId={this.props.configId}
        componentId={this.props.component.get('id')}
        onDeleteMappings={(...args) =>
          componentsAction.deleteMappings(this.state.configData, ...args)
        }
        value={this.props.configData.getIn(['storage', 'input', 'tables'], List())}
        destinationType={getDestinationTypeFromStagingStorage(
          this.props.component.getIn(['data', 'staging_storage', 'input']),
        )}
        allowedComponents={this.props.allowedComponents}
        sandboxes={this.props.sandboxes}
        hasPayAsYouGo={this.props.hasPayAsYouGo}
      />
    );
  }

  renderTableOutputMapping() {
    if (!this.props.pattern.get('flags').includes(GENERIC_DOCKER_UI_TABLE_OUTPUT)) {
      return null;
    }

    return (
      <TableOutputMapping
        readOnly={this.props.readOnly}
        tables={this.props.tables}
        buckets={this.props.buckets}
        configId={this.props.configId}
        onDeleteMappings={(...args) =>
          componentsAction.deleteMappings(this.state.configData, ...args)
        }
        componentId={this.props.component.get('id')}
        configName={this.props.config.get('name')}
        value={this.props.configData.getIn(['storage', 'output', 'tables'], List())}
        sourceType={getSourceTypeFromStagingStorage(
          this.props.component.getIn(['data', 'staging_storage', 'output']),
        )}
      />
    );
  }

  renderGenerateButton() {
    const features = this.props.pattern.get('features', List());

    if (
      this.props.isDevModeActive &&
      (features.includes(componentFeatures.DEV_BRANCH_CONFIGURATION_UNSAFE) ||
        features.includes(componentFeatures.DEV_BRANCH_JOB_BLOCKED))
    ) {
      return null;
    }

    if (this.state.isGenerating) {
      return (
        <Button bsStyle="primary" disabled>
          <Loader className="icon-addon-right" />
          Generating code...
        </Button>
      );
    }

    if (
      this.props.pattern.hasIn(['configurationSchema', 'required']) &&
      !this.props.configData.hasIn(['runtime', 'codePattern', 'parameters'])
    ) {
      return (
        <Tooltip placement="top" tooltip="Parameters must be filled and saved." type="explanatory">
          <Button bsStyle="primary" className="disabled">
            <Icon icon="code" className="icon-addon-right" /> Generate code
          </Button>
        </Tooltip>
      );
    }

    return (
      <Button bsStyle="primary" onClick={this.handleGenerate}>
        <Icon icon="code" className="icon-addon-right" />
        {this.getCurrentScript().length > 0 ? 'Regenerate' : 'Generate'} code
      </Button>
    );
  }

  renderError() {
    if (!this.state.error) {
      return null;
    }

    const errorMessage = this.state.error.includes('php-component.ERROR:')
      ? this.state.error.split('php-component.ERROR:')[1].trim()
      : this.state.error;

    return (
      <Alert variant="error" className="box-separator tw-mb-5">
        {errorMessage}
      </Alert>
    );
  }

  handleSubmitParameters() {
    this.props.parametersOnEditSubmit();
  }

  handleResetParameters() {
    this.props.parametersOnEditCancel();
    this.resetJsonSchemaEditor();
  }

  resetJsonSchemaEditor() {
    if (!this.props.pattern.get('configurationSchema', Map()).isEmpty()) {
      this.setState({ jsonSchemEditorResetKey: _.uniqueId('json_schema_editor_') });
    }
  }

  handleGenerate() {
    this.setState({ isGenerating: true, error: null });
    return unhandledRequest(this.props.pattern.get('id'), 'generate', {
      configData: {
        storage: this.props.configData.get('storage', Map()).toJS(),
        parameters: this.props.configData
          .getIn(['runtime', 'codePattern', 'parameters'], Map())
          .set('_componentId', this.props.component.get('id'))
          .toJS(),
      },
    })
      .then((response) => {
        if (response.error === 'User error' && response.message) {
          return this.setState({ error: response.message });
        }

        if (response.status === 'error') {
          throw new SyncActionError(
            response.message || 'An error occurred while generating code',
            response.exceptionId,
          );
        }

        return componentsAction.updateComponentConfiguration(
          this.props.component.get('id'),
          this.props.configId,
          {
            configuration: JSON.stringify(
              this.props.configData
                .update('parameters', Map(), (parameters) => {
                  if (response.result.parameters) {
                    return fromJS(response.result.parameters);
                  }
                  return parameters;
                })
                .update('storage', Map(), (storage) => {
                  if (response.result.storage) {
                    return fromJS(response.result.storage);
                  }
                  return storage;
                })
                .toJS(),
            ),
          },
          `Update generated configuration`,
        );
      })
      .then(() => {
        if (this.codeMirrorEditor) {
          this.codeMirrorEditor.refresh();
        }
      })
      .finally(() => {
        this.setState({ isGenerating: false });
      });
  }

  getCurrentScript() {
    return this.props.configData
      .getIn(['parameters', 'blocks', 0, 'codes', 0, 'script'], List())
      .join('\n\n');
  }
}

GenericPatternUi.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  pattern: PropTypes.instanceOf(Map),
  configId: PropTypes.string.isRequired,
  component: PropTypes.instanceOf(Map).isRequired,
  tables: PropTypes.instanceOf(Map).isRequired,
  buckets: PropTypes.instanceOf(Map).isRequired,
  config: PropTypes.instanceOf(Map).isRequired,
  configData: PropTypes.instanceOf(Map).isRequired,
  isDevModeActive: PropTypes.bool.isRequired,
  parameters: PropTypes.string.isRequired,
  parametersIsValid: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  parametersIsChanged: PropTypes.bool.isRequired,
  parametersOnEditCancel: PropTypes.func.isRequired,
  parametersOnEditChange: PropTypes.func.isRequired,
  parametersOnEditSubmit: PropTypes.func.isRequired,
  allowedComponents: PropTypes.instanceOf(Map).isRequired,
  sandboxes: PropTypes.instanceOf(Map).isRequired,
  hasPayAsYouGo: PropTypes.bool.isRequired,
};

export default GenericPatternUi;
