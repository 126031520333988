import { Alert } from '@keboola/design';

export const RealTimeInfoAlert = () => {
  return (
    <div className="container tw-mt-5">
      <Alert>
        Please note that the dashboard <b>does not display real-time data</b> as the data is
        processed with delay. Therefore, the numbers are not immediately updated to reflect running
        jobs or workspace usage.
      </Alert>
    </div>
  );
};
