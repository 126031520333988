import React from 'react';
import { Map } from 'immutable';

import { Icon } from '@keboola/design';

import ConfigurationRow from '@/modules/flows/components/ConfigurationRow';
import { Truncated } from '@/react/common';
import { matchByWords } from '@/utils';
import type { Configuration } from './ComponentDetail';

type Props = {
  configurations: Configuration[];
  folder: string;
  component: Map<string, any>;
  query: string;
  onSelect: (_: any, configuration: Map<string, any>) => void;
};

const Folder = ({ configurations, folder, component, query, onSelect }: Props) => {
  const [open, setOpen] = React.useState(
    query ? configurations.some((c) => matchByWords(c.name, query)) : false,
  );

  return (
    <div>
      <button
        className="tw-block tw-h-[36px] tw-w-full tw-rounded tw-border-0 tw-bg-transparent tw-p-2 hover:tw-bg-neutral-50"
        onClick={() => {
          setOpen((prevState) => !prevState);
        }}
      >
        <div className="tw-flex tw-flex-col">
          <div className="tw-flex tw-items-center tw-gap-2">
            <Icon
              className="tw-text-neutral-400"
              icon={open ? 'chevron-down' : 'chevron-right'}
              fixedWidth
            />
            <Icon
              fontSize={20}
              className="tw-text-neutral-400"
              icon={open ? 'folder-open' : 'folder'}
              fixedWidth
            />
            <Truncated text={folder} />
          </div>
        </div>
      </button>
      {open && configurations.length > 0 && (
        <div className="tw-flex tw-flex-col tw-pl-7">
          {configurations.map((configuration) => {
            return (
              <ConfigurationRow
                key={configuration.id}
                withComponentType={false}
                configuration={Map(configuration)}
                component={component}
                query={query}
                onSelect={onSelect}
                customFooterContent={
                  configuration.description ? (
                    <Truncated className="tw-text-neutral-400" text={configuration.description} />
                  ) : null
                }
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Folder;
