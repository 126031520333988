import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { fromJS, List, Map } from 'immutable';

import { cn, Icon, Search } from '@keboola/design';

import keyCodes from '@/constants/keyCodes';
import MetadataActionCreators from '@/modules/components/MetadataActionCreators';
import { MetadataKeys, ObjectTypes } from '@/modules/components/MetadataConstants';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import SandboxesActions from '@/modules/sandboxes/Actions';
import AddSandboxModal from '@/modules/sandboxes/components/AddSandboxModal/AddSandboxModal';
import { FORM_STEPS } from '@/modules/sandboxes/Constants';
import { updateExistingWorkspace, workspaceActionsDisabled } from '@/modules/sandboxes/helpers';
import {
  createSnapshots,
  deleteMultipleTables,
  deleteTable,
  exportTables,
  truncateMultipleTables,
  truncateTable,
} from '@/modules/storage/actions';
import CreateSnapshotModal from '@/modules/storage/components/CreateSnapshotModal';
import DeleteMultipleTablesModal from '@/modules/storage/components/DeleteMultipleTablesModal';
import { DeleteRowsModal } from '@/modules/storage/components/DeleteRowsModal';
import DeleteTableModal from '@/modules/storage/components/DeleteTableModal';
import DevBranchStorageWarning from '@/modules/storage/components/DevBranchStorageWarning';
import ExportModal from '@/modules/storage/components/ExportModal';
import TruncateTableModal from '@/modules/storage/components/TruncateTableModal';
import { TypedTableLabel } from '@/modules/storage/components/TypedTableLabel';
import { routeNames, tableModalTabs, tableTabs } from '@/modules/storage/constants';
import {
  getDescriptionValue,
  getFilteredData,
  getTableAliases,
  getTableLinks,
  prepareMappingFromSelectedBucketsAndTables,
  sortByDisplayName,
} from '@/modules/storage/helpers';
import { routeNames as tableBrowserRouteNames } from '@/modules/table-browser/constants';
import { CreatedDate, FileSize, SortIcon, TableLabels } from '@/react/common';
import Checkbox from '@/react/common/Checkbox';
import ConfirmModal from '@/react/common/ConfirmModal';
import { SEARCH_TYPES } from '@/react/common/constants';
import ExternalTableLabel from '@/react/common/ExternalTableLabel';
import InlineDescriptionEditInput from '@/react/common/InlineDescriptionEditInput';
import NoResultsFound from '@/react/common/NoResultsFound';
import RowsCount from '@/react/common/RowsCount';
import TableUpdatedBy from '@/react/common/TableUpdatedBy';
import RoutesStore from '@/stores/RoutesStore';
import hasSelections from '@/utils/hasSelections';
import onClickSelectionCell from '@/utils/onClickSelectionCell';
import string from '@/utils/string';
import { simulateClickIfMiddleMouseIsUsed } from '@/utils/windowOpen';
import MultiActionsButtons from './MultiActionsButtons';
import TableActions from './TableActions';
import TableLink from './TableLink';

const tableRowCountGetter = (table) => table.get('rowsCount');
const tableDataSizeGetter = (table) => table.get('dataSizeBytes');
const tableLastChangeGetter = (table) => {
  return new Date(table.get('lastChangeDate') ?? table.get('created')).valueOf();
};

class TablesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTables: Map(),
      selectedTable: Map(),
      activeActionModal: null,
      activeMultiActionModal: null,
      sortValueGetter: sortByDisplayName,
      sortDirection: -1,
      searchQuery: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
    };

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleDeleteTables = this.handleDeleteTables.bind(this);
    this.handleTruncateTables = this.handleTruncateTables.bind(this);
  }

  render() {
    const isFilterActive = this.state.searchQuery.length > 1;
    const filteredData = getFilteredData(
      List([this.props.bucket.set('bucketTables', this.props.tables)]),
      isFilterActive ? this.state.searchQuery : '',
      null,
      ['bucket'],
    ).getIn([0, 'bucketTables'], List());

    return (
      <div className="tw-mt-6 tw-flex tw-flex-col tw-gap-6">
        <Search
          placeholder={this.getPlaceholder()}
          defaultValue={this.state.searchQuery}
          onChange={(searchQuery) => {
            this.setState({ searchQuery });
            RoutesStore.getRouter().updateQuery({ q: searchQuery });
          }}
        />
        {isFilterActive && filteredData.isEmpty() ? (
          <NoResultsFound entityName="tables or columns" />
        ) : (
          this.renderTable(filteredData, isFilterActive)
        )}
      </div>
    );
  }

  renderTable(filteredData, isFilterActive) {
    const { selectedTables, activeMultiActionModal } = this.state;
    const {
      sapiToken,
      hasFlows,
      bucket,
      components,
      configurations,
      canWriteBucket,
      urlTemplates,
      deletingTables,
      truncatingTables,
      exportingTables,
      createSnapshotsTables,
    } = this.props;
    const matchedTables = filteredData.filter((table) => table.get('matches'));
    const selectedTablesCount = selectedTables.count((isTableSelected) => isTableSelected);
    const isAllSelected = !!selectedTablesCount && selectedTablesCount === matchedTables.count();
    const isSomeSelected = !!selectedTablesCount && selectedTablesCount !== matchedTables.count();

    const checkedTables = matchedTables.filter((table) => selectedTables.get(table.get('id')));
    const checkedTablesIds = this.state.selectedTables.filter(Boolean).keySeq();
    const isExternal = bucket.get('hasExternalSchema');

    return (
      <div className="box">
        <table className="table table-hover">
          <thead>
            <tr className="with-action-buttons is-sticky bg-color-white">
              {canWriteBucket ? (
                <>
                  <th className="w-52 pr-0" onClick={onClickSelectionCell}>
                    <Checkbox
                      tooltip={`${
                        isAllSelected || isSomeSelected ? 'Deselect' : 'Select'
                      } all tables`}
                      checked={isAllSelected}
                      disabled={matchedTables.isEmpty()}
                      indeterminate={!!selectedTablesCount && !isAllSelected}
                      onChange={(checked) => {
                        this.setState(() => ({
                          selectedTables: !checked
                            ? Map()
                            : matchedTables
                                .map((table) => [table.get('id'), true])
                                .fromEntrySeq()
                                .toMap(),
                        }));
                      }}
                    />
                  </th>
                  <th>
                    <div className="flex-container flex-start">
                      {!selectedTablesCount ? (
                        this.renderSortHeader('Table Name', sortByDisplayName)
                      ) : (
                        <strong>
                          {selectedTablesCount} {string.pluralize(selectedTablesCount, 'table')}{' '}
                          selected
                        </strong>
                      )}
                      {selectedTablesCount > 0 && this.renderMultiActionsButtons()}
                    </div>
                  </th>
                </>
              ) : (
                <th>
                  {isFilterActive ? 'Name' : this.renderSortHeader('Table Name', sortByDisplayName)}
                </th>
              )}
              {matchedTables.length !== 0 && (
                <>
                  {!isExternal && (
                    <>
                      <th className="w-150 text-right">
                        {this.renderSortHeader('Row Count', tableRowCountGetter)}
                      </th>
                      <th className="w-150 text-right">
                        {this.renderSortHeader('Data Size', tableDataSizeGetter)}
                      </th>
                      <th className="w-200 text-right">Recently Updated By</th>
                    </>
                  )}
                  <th className="w-200 text-right">
                    {this.renderSortHeader(
                      isExternal ? 'Created' : 'Last Change',
                      tableLastChangeGetter,
                    )}
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredData.isEmpty() && (
              <tr className="no-hover">
                <td colSpan={6}>No tables created yet.</td>
              </tr>
            )}
            {filteredData
              .sort((a, b) => {
                const sorterFn = isFilterActive ? sortByDisplayName : this.state.sortValueGetter;
                const valueA = sorterFn(a);
                const valueB = sorterFn(b);
                if (valueA < valueB) return 1 * this.state.sortDirection;
                if (valueA > valueB) return -1 * this.state.sortDirection;
                return 0;
              })
              .map((table) => {
                const isDeleting = deletingTables.get(table.get('id'), false);
                const isTruncating = truncatingTables.get(table.get('id'), false);
                const isExporting = exportingTables.get(table.get('id'), false);
                const isCreatingSnapshot = createSnapshotsTables.get(table.get('id'), false);
                const isAllSelected =
                  !!selectedTablesCount && selectedTablesCount === matchedTables.count();
                const isSomeSelected =
                  !!selectedTablesCount && selectedTablesCount !== matchedTables.count();
                const tooltipDescription = `${
                  isAllSelected || isSomeSelected ? 'Deselect' : 'Select'
                } table`;

                return (
                  <Fragment key={table.get('id')}>
                    {table.get('matches') && (
                      <tr
                        key={table.get('id')}
                        onClick={() => {
                          if (hasSelections()) {
                            return;
                          }

                          this.redirectToTable(table);
                        }}
                        onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
                        onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
                        onKeyDown={(event) => {
                          if (event.key === keyCodes.ENTER) {
                            this.redirectToTable(table);
                          }
                        }}
                        className="clickable hoverable-actions-with-replacement"
                        role="button"
                        tabIndex="0"
                      >
                        {canWriteBucket && (
                          <td className="w-52 pr-0" onClick={onClickSelectionCell}>
                            <Checkbox
                              tooltip={tooltipDescription}
                              disabled={
                                isDeleting || isTruncating || isExporting || isCreatingSnapshot
                              }
                              checked={!!selectedTables.get(table.get('id'))}
                              onChange={(checked) => {
                                this.setState(({ selectedTables }) => ({
                                  selectedTables: selectedTables.set(table.get('id'), checked),
                                }));
                              }}
                            />
                          </td>
                        )}
                        <td>
                          <span className="flex-container flex-start overflow-break-anywhere">
                            <Icon icon="table" className="icon-addon-right text-muted f-18" />
                            <div>
                              <div className="flex-container flex-start">
                                {this.renderTableLink(table)}
                                <TableLabels table={table} />
                              </div>

                              <div className="f-13 text-muted">
                                <InlineDescriptionEditInput
                                  entity={ObjectTypes.TABLE}
                                  description={getDescriptionValue(table.get('metadata'))}
                                  onSave={(newDescription) => {
                                    return MetadataActionCreators.saveMetadata(
                                      ObjectTypes.TABLE,
                                      table.get('id'),
                                      MetadataKeys.DESCRIPTION,
                                      newDescription.trim(),
                                    );
                                  }}
                                  readOnly={!canWriteBucket}
                                />
                              </div>
                            </div>
                          </span>
                        </td>
                        <>
                          {!isExternal && (
                            <>
                              <td className="text-right">
                                <RowsCount count={table.get('rowsCount')} />
                              </td>
                              <td className="text-right">
                                <FileSize size={table.get('dataSizeBytes')} />
                              </td>
                              <td className="text-right">
                                <TableUpdatedBy
                                  table={table}
                                  components={components}
                                  configurations={configurations}
                                  hasFlows={hasFlows}
                                />
                              </td>
                            </>
                          )}
                          <td>
                            {!this.props.canWriteBucket && !this.props.canExportTable ? (
                              <CreatedDate
                                createdTime={table.get('lastChangeDate') || table.get('created')}
                              />
                            ) : (
                              <div
                                className={cn('actions-container', {
                                  'force-actions':
                                    isDeleting || isTruncating || isExporting || isCreatingSnapshot,
                                })}
                              >
                                <div className="not-actions">
                                  <CreatedDate
                                    createdTime={
                                      table.get('lastChangeDate') || table.get('created')
                                    }
                                  />
                                </div>
                                <div className="actions">
                                  {this.renderTableActions(
                                    table,
                                    isDeleting,
                                    isTruncating,
                                    isExporting,
                                    isCreatingSnapshot,
                                  )}
                                </div>
                              </div>
                            )}
                          </td>
                        </>
                      </tr>
                    )}
                    {isFilterActive &&
                      table
                        .get('columns', List())
                        .sortBy((columnName) => {
                          if (columnName === this.props.query) {
                            return -1;
                          }

                          return columnName.toLowerCase();
                        })
                        .map((columnName) => (
                          <tr
                            key={columnName}
                            onClick={() => {
                              if (hasSelections()) {
                                return;
                              }

                              this.redirectToTable(table, columnName);
                            }}
                            onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
                            onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
                            onKeyDown={(event) => {
                              if (event.key === keyCodes.ENTER) {
                                this.redirectToTable(table, columnName);
                              }
                            }}
                            className="clickable"
                            role="button"
                            tabIndex="0"
                          >
                            {canWriteBucket && (
                              <td className="w-52 pr-0">
                                <Checkbox disabled />
                              </td>
                            )}

                            <td colSpan={5} className="!tw-text-left">
                              <div className="tw-flex !tw-min-h-[42px] tw-items-center">
                                <Icon
                                  icon="table-columns"
                                  className="icon-addon-right text-muted f-18"
                                />
                                <span className="text-muted">{table.get('displayName')}</span>
                                <Icon
                                  icon={['far', 'angle-right']}
                                  className="text-muted"
                                  fixedWidth
                                />
                                {this.renderTableLink(table, columnName)}
                                <TypedTableLabel isTyped={table.get('isTyped', false)} />
                                <ExternalTableLabel
                                  tableType={table.get('tableType')}
                                  hasExternalSchema={table.getIn(['bucket', 'hasExternalSchema'])}
                                />
                              </div>
                            </td>
                          </tr>
                        ))
                        .toArray()}
                  </Fragment>
                );
              })
              .toArray()}
          </tbody>
        </table>
        <DeleteMultipleTablesModal
          bucket={bucket}
          tables={checkedTables}
          allTables={this.props.allTables}
          show={activeMultiActionModal === tableModalTabs.DELETE_MULTIPLE}
          sapiToken={sapiToken}
          urlTemplates={urlTemplates}
          deleting={!deletingTables.isEmpty()}
          onConfirm={this.handleDeleteTables}
          onHide={this.handleCloseModal}
        />
        <ConfirmModal
          closeAfterResolve
          show={activeMultiActionModal === tableModalTabs.TRUNCATE}
          icon="xmark"
          title="Truncate Table"
          text={
            <>
              <p>Are you sure you want to truncate the selected tables?</p>
              <DevBranchStorageWarning
                message="The tables will also be truncated in production."
                hasProductionEntity={selectedTables
                  .map((table, tableId) => this.props.tables.get(tableId))
                  .filter(Boolean)
                  .some((table) => !isCreatedInDevBranch(table.get('bucket')))}
              />
            </>
          }
          buttonLabel={!truncatingTables.isEmpty() ? 'Truncating...' : 'Truncate'}
          buttonType="danger"
          onConfirm={this.handleTruncateTables}
          onHide={this.handleCloseModal}
          isLoading={!truncatingTables.isEmpty()}
        />
        <DeleteTableModal
          show={this.state.activeActionModal === tableModalTabs.DELETE}
          table={this.state.selectedTable}
          sapiToken={this.props.sapiToken}
          urlTemplates={this.props.urlTemplates}
          tableAliases={getTableAliases(
            this.state.selectedTable,
            this.props.allTables,
            this.props.sapiToken,
          )}
          tableLinks={getTableLinks(this.state.selectedTable, this.props.bucket)}
          deleting={this.props.deletingTables.get(this.state.selectedTable.get('id'), false)}
          onConfirm={() => deleteTable(this.state.selectedTable.get('id'))}
          onHide={this.handleCloseModal}
        />
        <CreateSnapshotModal
          show={
            this.state.activeActionModal === tableModalTabs.SNAPSHOT ||
            this.state.activeMultiActionModal === tableModalTabs.SNAPSHOT
          }
          multiple={
            this.state.activeMultiActionModal === tableModalTabs.SNAPSHOT &&
            checkedTablesIds.count() > 1
          }
          onConfirm={async (description) => {
            return createSnapshots(
              this.state.activeActionModal === tableModalTabs.SNAPSHOT
                ? [this.state.selectedTable.get('id')]
                : checkedTablesIds.toJS(),
              {
                description,
              },
            );
          }}
          onHide={this.handleCloseModal}
        />
        <TruncateTableModal
          show={this.state.activeActionModal === tableModalTabs.TRUNCATE}
          table={this.state.selectedTable}
          onConfirm={() => truncateTable(this.state.selectedTable.get('id'))}
          onHide={this.handleCloseModal}
        />
        <DeleteRowsModal
          show={this.state.activeActionModal === tableModalTabs.DELETE_ROWS}
          table={this.state.selectedTable}
          onHide={this.handleCloseModal}
        />
        <ExportModal
          show={
            this.state.activeActionModal === tableModalTabs.EXPORT ||
            this.state.activeMultiActionModal === tableModalTabs.EXPORT
          }
          tables={
            this.state.activeActionModal === tableModalTabs.EXPORT
              ? List([this.state.selectedTable])
              : checkedTables
          }
          onSubmit={(type) =>
            exportTables(
              this.state.activeActionModal === tableModalTabs.EXPORT
                ? List([this.state.selectedTable])
                : checkedTables,
              type,
            )
          }
          onHide={this.handleCloseModal}
        />
        <AddSandboxModal
          hasTableInputMapping
          show={[tableModalTabs.CREATE_WORKSPACE, tableModalTabs.USE_WORKSPACE].includes(
            this.state.activeActionModal,
          )}
          forceStep={
            this.state.activeActionModal === tableModalTabs.USE_WORKSPACE
              ? FORM_STEPS.SANDBOX_UPDATE
              : null
          }
          onHide={this.handleCloseModal}
          workspaces={this.props.sandboxes}
          allowedComponents={this.props.allowedTransformationComponents}
          hasPayAsYouGo={this.props.hasPayAsYouGo}
          onUpdate={(workspace, preserve) => {
            return prepareMappingFromSelectedBucketsAndTables(
              this.state.selectedTables.map((table, tableId) => this.props.tables.get(tableId)),
              this.props.tables,
              workspace.get('type'),
            ).then((storage) => {
              return updateExistingWorkspace(
                fromJS({ configuration: { parameters: { id: workspace.get('id') }, storage } }),
                workspace,
                preserve,
                'Use mapping from storage tables',
              );
            });
          }}
          onSubmit={(name, type, options, params, description) => {
            return prepareMappingFromSelectedBucketsAndTables(
              this.state.selectedTables.map((table, tableId) => this.props.tables.get(tableId)),
              this.props.tables,
              type,
            ).then((storage) => {
              return SandboxesActions.createSandbox(
                { name, description, configuration: JSON.stringify({ storage }) },
                type,
                options,
                params.set('storage', storage),
              );
            });
          }}
        />
      </div>
    );
  }

  renderTableLink(table, columnName) {
    return (
      <TableLink
        table={table}
        isBucketBrowser={this.props.isBucketBrowser}
        columnName={columnName}
        searchQuery={this.state.searchQuery}
      />
    );
  }

  renderMultiActionsButtons() {
    const anySelectedIsAlias = this.state.selectedTables
      .keySeq()
      .some((tableId) => this.props.tables.get(tableId, Map()).get('isAlias'));

    return (
      <MultiActionsButtons
        onSelectModal={(modal) => this.setState({ activeMultiActionModal: modal })}
        onSelectSingleActionModal={(modal) => this.setState({ activeActionModal: modal })}
        hasSnowflakePartnerConnectLimited={this.props.hasSnowflakePartnerConnectLimited}
        workspaceActionsDisabled={workspaceActionsDisabled(this.props.bucket)}
        isDeleteSelectedDisabled={!this.props.deletingTables.isEmpty()}
        isTruncateSelectedDisabled={!this.props.truncatingTables.isEmpty()}
        isExportSelectedDisabled={!this.props.exportingTables.isEmpty()}
        isCreateSnapshotsDisabled={!this.props.createSnapshotsTables.isEmpty()}
        anySelectedIsAlias={anySelectedIsAlias}
        selectedTablesSize={this.state.selectedTables.count()}
      />
    );
  }

  renderSortHeader(label, sorter) {
    const isSorted = this.state.sortValueGetter === sorter;

    return (
      <span
        className="clickable"
        title={`Sort by ${label.toLowerCase()}`}
        onClick={() => {
          this.setState({
            sortValueGetter: sorter,
            sortDirection: isSorted ? this.state.sortDirection * -1 : -1,
          });
        }}
      >
        <SortIcon isSorted={isSorted} isSortedDesc={this.state.sortDirection === -1} />
        {label}
      </span>
    );
  }

  renderTableActions(table, isDeleting, isTruncating, isExporting, isCreatingSnapshot) {
    return (
      <TableActions
        table={table}
        isDeleting={isDeleting}
        isTruncating={isTruncating}
        isExporting={isExporting}
        isCreatingSnapshot={isCreatingSnapshot}
        canExportTable={this.props.canExportTable}
        canWriteBucket={this.props.canWriteBucket}
        onSetState={(table, modal) => {
          this.setState({
            selectedTable: table,
            activeActionModal: modal,
          });
        }}
      />
    );
  }

  redirectToTable = (table, columName) => {
    if (this.props.isBucketBrowser) {
      const location = RoutesStore.getRouterState().get('location', Map());

      return RoutesStore.getRouter().transitionTo(
        tableBrowserRouteNames.ROOT,
        {
          tableId: table.get('id'),
          tableTab: columName ? tableTabs.DATA_SAMPLE : tableTabs.OVERVIEW,
        },
        {
          context: location.getIn(['query', 'context']),
          ...(columName && { q: columName, qt: SEARCH_TYPES.KEY }),
        },
        '',
        location.get('state'),
      );
    }

    if (columName) {
      return RoutesStore.getRouter().transitionTo(
        routeNames.TABLE,
        {
          bucketId: table.getIn(['bucket', 'id']),
          tableName: table.get('name'),
          tableTab: tableTabs.DATA_SAMPLE,
        },
        { q: columName, qt: SEARCH_TYPES.KEY },
      );
    }

    return RoutesStore.getRouter().transitionTo(routeNames.TABLE, {
      bucketId: table.getIn(['bucket', 'id']),
      tableName: table.get('name'),
    });
  };

  handleDeleteTables = () => {
    const selectedTables = this.state.selectedTables.filter(Boolean).keySeq();

    return deleteMultipleTables(selectedTables).then(() =>
      this.setState({ selectedTables: Map() }),
    );
  };

  handleTruncateTables = () => {
    const selectedTables = this.state.selectedTables.filter(Boolean).keySeq();

    return truncateMultipleTables(selectedTables).then(() =>
      this.setState({ selectedTables: Map() }),
    );
  };

  handleCloseModal = () => {
    this.setState({ activeActionModal: null, activeMultiActionModal: null, selectedTable: Map() });
  };

  getPlaceholder = () => {
    return `Search tables (${this.props.tables.count()}) and columns (${this.props.tables
      .map((table) => table.get('columns'))
      .flatten(1)
      .count()})`;
  };
}

TablesList.propTypes = {
  canExportTable: PropTypes.bool.isRequired,
  hasFlows: PropTypes.bool.isRequired,
  sapiToken: PropTypes.instanceOf(Map).isRequired,
  allowedTransformationComponents: PropTypes.instanceOf(Map).isRequired,
  sandboxes: PropTypes.instanceOf(Map).isRequired,
  bucket: PropTypes.instanceOf(Map).isRequired,
  tables: PropTypes.instanceOf(Map).isRequired,
  allTables: PropTypes.instanceOf(Map).isRequired,
  components: PropTypes.instanceOf(Map).isRequired,
  configurations: PropTypes.instanceOf(Map).isRequired,
  urlTemplates: PropTypes.instanceOf(Map).isRequired,
  deletingTables: PropTypes.instanceOf(Map).isRequired,
  truncatingTables: PropTypes.instanceOf(Map).isRequired,
  exportingTables: PropTypes.instanceOf(Map).isRequired,
  createSnapshotsTables: PropTypes.instanceOf(Map).isRequired,
  hasPayAsYouGo: PropTypes.bool.isRequired,
  canWriteBucket: PropTypes.bool.isRequired,
  hasSnowflakePartnerConnectLimited: PropTypes.bool.isRequired,
  isBucketBrowser: PropTypes.bool,
};

export default TablesList;
