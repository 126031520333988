import Promise from 'bluebird';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import Actions from '@/modules/configurations/ConfigurationsActionCreators';
import ConfigurationsStore from '@/modules/configurations/ConfigurationsStore';
import dockerActions from '@/modules/configurations/DockerActionsActionCreators';
import DockerActionsStore from '@/modules/configurations/DockerActionsStore';
import JsonConfiguration from '@/modules/configurations/react/components/JsonConfiguration';
import isParsableConfiguration from '@/modules/configurations/utils/isParsableConfiguration';
import sectionsHelpers from '@/modules/configurations/utils/sections';
import OAuthStore from '@/modules/oauth-v2/Store';
import StackFeaturesStore from '@/modules/stack-features/Store';
import CatchUnsavedChanges from '@/react/common/CatchUnsavedChanges';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import fromJSOrdered from '@/utils/fromJSOrdered';

const IndexSections = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      StackFeaturesStore,
      InstalledComponentsStore,
      ComponentsStore,
      ConfigurationsStore,
      DockerActionsStore,
      OAuthStore,
    ),
  ],

  getStateFromStores() {
    const settings = RoutesStore.getRouteSettings();
    const sections = settings.getIn(['index', 'sections'], List());
    const componentId = settings.get('componentId');
    const configurationId = RoutesStore.getCurrentRouteParam('config');
    const component = ComponentsStore.getComponent(componentId);
    const configuration = ConfigurationsStore.getConfiguration(componentId, configurationId);
    const createBySectionsFn = sectionsHelpers.makeCreateFn(sections);
    const conformFn = settings.getIn(['index', 'onConform'], (config) => config);
    const context = InstalledComponentsStore.getConfigurationContext(componentId, configurationId);
    const parseBySectionsFn = sectionsHelpers.makeParseFn(sections, conformFn, context);
    const configurationBySections = ConfigurationsStore.getEditingConfiguration(
      componentId,
      configurationId,
      parseBySectionsFn,
    );
    const storedConfigurationSections = parseBySectionsFn(configuration);
    const pendingActions = ConfigurationsStore.getPendingActions(componentId, configurationId);

    return {
      context,
      settings,
      sections,
      component,
      componentId,
      configurationId,
      configuration,
      parseBySectionsFn,
      createBySectionsFn,
      configurationBySections,
      storedConfigurationSections,
      jsonConfigurationValue: ConfigurationsStore.getEditingJsonConfigurationString(
        componentId,
        configurationId,
      ),
      isJsonConfigurationSaving: pendingActions.has('save-json'),
      isChanged: !configurationBySections.equals(storedConfigurationSections),
      isJsonChanged: ConfigurationsStore.isEditingJsonConfiguration(componentId, configurationId),
      isParsableConfiguration: isParsableConfiguration(
        conformFn(configuration),
        parseBySectionsFn,
        createBySectionsFn,
      ),
      isJsonValid: ConfigurationsStore.isEditingJsonConfigurationValid(
        componentId,
        configurationId,
      ),
      isJsonEditorOpen: ConfigurationsStore.hasJsonEditor(
        componentId,
        configurationId,
        parseBySectionsFn,
        createBySectionsFn,
        conformFn,
      ),
      isSaving: pendingActions.has('save-configuration'),
      stackFeatures: StackFeaturesStore.getAll(),
      readOnly: ApplicationStore.isReadOnly(),
      admins: ApplicationStore.getAdmins(),
    };
  },

  componentDidMount() {
    dockerActions.reloadIndexSyncActions(this.state.componentId, this.state.configurationId);
  },

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.configuration.has('authorization') &&
      !prevState.configuration.has('authorization')
    ) {
      dockerActions.reloadIndexSyncActions(this.state.componentId, this.state.configurationId);
    }
  },

  onUpdateSection(sectionKey, diff) {
    const { configurationBySections, componentId, configurationId } = this.state;
    const newConfigurationBySections = configurationBySections.set(
      sectionKey,
      configurationBySections.get(sectionKey).merge(fromJSOrdered(diff)),
    );
    const created = this.state.createBySectionsFn(newConfigurationBySections);
    const parsed = this.state.parseBySectionsFn(created);
    return Actions.updateConfiguration(componentId, configurationId, parsed);
  },

  onSaveSection(sectionKey, diff) {
    const { configurationBySections, componentId, configurationId } = this.state;
    const newConfigurationBySections = configurationBySections.set(
      sectionKey,
      configurationBySections.get(sectionKey).merge(fromJSOrdered(diff)),
    );
    const created = this.state.createBySectionsFn(newConfigurationBySections);
    const configData = this.state.configuration.set('parameters', created.get('parameters'));
    return Actions.saveForcedConfiguration(componentId, configurationId, configData);
  },

  renderForm() {
    let sections = this.state.sections;
    let actionsData = Map();

    this.state.settings.getIn(['index', 'actions'], List()).forEach((action) => {
      actionsData = actionsData.set(
        action.get('name'),
        DockerActionsStore.get(this.state.componentId, action, this.state.configuration),
      );
    });

    return sections.map((section, key) => {
      const SectionComponent = section.get('render');
      const onSectionSave = section.get('onSave');
      const sectionIsCompleteFn = section.get('isComplete', () => true);
      const storedConfiguration = this.state.storedConfigurationSections.get(key);
      const editedConfiguration = this.state.configurationBySections.get(key);
      const isComplete = sectionIsCompleteFn(onSectionSave(storedConfiguration));

      return (
        <SectionComponent
          key={key}
          isComplete={isComplete}
          readOnly={this.state.readOnly}
          isChanged={!storedConfiguration.equals(editedConfiguration)}
          disabled={this.state.isSaving || this.state.readOnly}
          onChange={(diff) => this.onUpdateSection(key, diff)}
          onReset={() => this.onUpdateSection(key, storedConfiguration)}
          onSave={() => this.onSaveSection(key, editedConfiguration)}
          value={editedConfiguration.toJS()}
          context={this.state.context}
          stackFeatures={this.state.stackFeatures}
          imageParameters={this.state.component.getIn(['data', 'image_parameters'], Map())}
          actions={actionsData}
          admins={this.state.admins}
        />
      );
    });
  },

  renderJsonEditor() {
    return (
      <JsonConfiguration
        readOnly={this.state.readOnly}
        isSaving={this.state.isJsonConfigurationSaving}
        value={this.state.jsonConfigurationValue}
        onEditChange={(parameters) =>
          Actions.updateJsonConfiguration(
            this.state.componentId,
            this.state.configurationId,
            parameters,
          )
        }
      />
    );
  },

  render() {
    return (
      <CatchUnsavedChanges
        isDirty={this.state.isChanged || this.state.isJsonChanged}
        onSave={this.handleSave}
        isSaveDisabled={
          (this.state.isJsonEditorOpen || !this.state.isParsableConfiguration) &&
          !this.state.isJsonValid
        }
        onDirtyLeave={this.onDirtyLeave}
      >
        {this.state.isJsonEditorOpen || !this.state.isParsableConfiguration
          ? this.renderJsonEditor()
          : this.renderForm()}
      </CatchUnsavedChanges>
    );
  },

  handleSave() {
    if (this.state.isJsonEditorOpen || !this.state.isParsableConfiguration) {
      return Actions.saveJsonConfiguration(
        this.state.componentId,
        this.state.configurationId,
        'Configuration edited manually',
      );
    }

    return Promise.each(this.state.sections, (section, key) => {
      return this.onSaveSection(key, this.state.configurationBySections.get(key));
    });
  },

  onDirtyLeave() {
    if (this.state.isJsonEditorOpen || !this.state.isParsableConfiguration) {
      Actions.resetJsonConfiguration(this.state.componentId, this.state.configurationId);
    } else {
      Actions.resetConfiguration(this.state.componentId, this.state.configurationId);
    }
  },
});

export default IndexSections;
