import { Component } from 'react';

import BlankImage from '../../../../../images/illustrations/blank.png';

import PaginatedBox from '@/react/common/PaginatedBox/PaginatedBox';
import { fileUrl } from '@/utils/fileHelpers';
import NewTransformationBucketButton from './NewTransformationBucketButton';

class BlankTransformationsPage extends Component {
  render() {
    return (
      <div className="blank-page">
        <img src={fileUrl(BlankImage)} alt="Transformations introduction" className="intro-image" />
        <PaginatedBox>
          <PaginatedBox.Item>
            <h2>What is a transformation bucket?</h2>
            <p className="intro-text">
              A transformation bucket is a container for related transformations.
              <br />
              Once the bucket is created, you can start creating transformations inside it.
            </p>
          </PaginatedBox.Item>
          <PaginatedBox.Item>
            <h2>What is a transformation?</h2>
            <p className="intro-text">
              Transformations pick data from Storage, manipulate it and then store it back.
              <br />
              They can be written in SQL (Snowflake, Redshift), R, Python, Julia and OpenRefine.
            </p>
          </PaginatedBox.Item>
        </PaginatedBox>
        <div className="intro-action">
          <NewTransformationBucketButton label="New Transformation Bucket" />
        </div>
      </div>
    );
  }
}

export default BlankTransformationsPage;
