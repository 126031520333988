import type { MouseEventHandler } from 'react';
import { useQuery } from '@tanstack/react-query';

import type { DataApp } from '@keboola/api-client';
import { useApiClient } from '@keboola/api-client';
import { Button, Icon } from '@keboola/design';

import { dataScienceApiKey } from '@/constants/queryKeys';
import Loader from '@/react/common/Loader';
import { downloadStringAsTextFile } from './utils';

type Props = {
  app: DataApp;
};

export const DownloadButton = ({ app }: Props) => {
  const isAppNotRunning = app.state !== 'running';
  const api = useApiClient();

  const { isLoading, refetch } = useQuery({
    queryKey: dataScienceApiKey.appLogsDownload(app.id),
    queryFn: ({ signal }) => api.dataScience.getAppLogsDownload(app.id, signal),
    enabled: false,
    retry: false,
  });

  const downloadTerminalLogs: MouseEventHandler<HTMLButtonElement> = async () => {
    const result = await refetch();

    if (result.isSuccess) {
      downloadStringAsTextFile(result.data.fileName, result.data.logs);
    }
  };

  const icon = isLoading ? <Loader /> : <Icon icon={['far', 'arrow-down-to-line']} />;

  return (
    <Button
      disabled={isAppNotRunning || isLoading}
      onClick={downloadTerminalLogs}
      variant="outline"
      className="tw-flex tw-gap-2"
    >
      {icon}
      Download logs
    </Button>
  );
};
