export const routeNames = {
  SHARED_WITH_YOU: 'data-catalog-shared-with-you',
  SHARED_WITH_YOU_DETAIL: 'shared-with-you-detail',
  SHARED_FROM_THIS_PROJECT: 'data-catalog-shared-from-this-project',
  SHARED_FROM_THIS_PROJECT_DETAIL: 'shared-from-this-project-detail',
};

export const SHARED_TYPES = {
  ORGANIZATION_MEMBER: 'organization',
  PROJECT_MEMBERS: 'organization-project',
  SELECTED_PROJECT: 'specific-projects',
  SELECTED_PEOPLE: 'specific-users',
};

export const SHARED_TYPES_OPTIONS = [
  { value: SHARED_TYPES.ORGANIZATION_MEMBER, label: 'Organization Members' },
  { value: SHARED_TYPES.PROJECT_MEMBERS, label: 'Project Members' },
  { value: SHARED_TYPES.SELECTED_PROJECT, label: 'Selected Projects' },
  { value: SHARED_TYPES.SELECTED_PEOPLE, label: 'Selected Users' },
];

export const SHARING_TYPE_LABELS = {
  [SHARED_TYPES.ORGANIZATION_MEMBER]: 'Organization Members',
  [SHARED_TYPES.PROJECT_MEMBERS]: 'Project Members',
  [SHARED_TYPES.SELECTED_PROJECT]: 'Selected Projects',
  [SHARED_TYPES.SELECTED_PEOPLE]: 'Selected Users',
};
