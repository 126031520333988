import { useCallback } from 'react';
import type { KeyboardEvent, MouseEvent } from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';

import { cn } from '@keboola/design';

import { HIDDEN_COLUMNS_WIDTH_LIMIT } from '@/constants';
import type { BucketColumn, BucketType, SourceTab } from '@/modules/data-catalog/types';
import { getLinkToDetail } from '@/modules/data-catalog/utils';
import { onTableRowKeyDown } from '@/react/common/ConfigurationsTable/helpers';
import NoResultsFound from '@/react/common/NoResultsFound';
import useMatchMedia from '@/react/hooks/useMatchMedia';
import RoutesStore from '@/stores/RoutesStore';
import { isInteractiveElement } from '@/utils';
import hasSelections from '@/utils/hasSelections';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen,
} from '@/utils/windowOpen';

const HIDDEN_COLUMNS_FOR_SMALL_SCREEN = ['last_modified', 'size'];

type Props = {
  data: BucketType[];
  columns: BucketColumn[];
  sourceTab: SourceTab;
};

export const DataCatalogTable = ({ columns, data, sourceTab }: Props) => {
  const tableInstance = useReactTable<BucketType>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const rows = tableInstance.getRowModel().rows;

  const handleRowAction = useCallback(
    (bucket: BucketType) => (e?: MouseEvent | KeyboardEvent) => {
      if (hasSelections()) {
        return;
      }

      const { name, params } = getLinkToDetail({ bucket, sourceTab });

      if (shouldUseNewWindow(e)) {
        return windowOpen(RoutesStore.getRouter().createHref(name, params));
      }

      return RoutesStore.getRouter().transitionTo(name, params);
    },
    [sourceTab],
  );

  const handleRowClick = useCallback(
    (bucket: BucketType, event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!isInteractiveElement(target)) {
        handleRowAction(bucket)(event);
      }
    },
    [handleRowAction],
  );

  const matchMediaHandler = useCallback(
    ({ matches }: { matches: boolean }) => {
      tableInstance
        .getAllColumns()
        .filter((column) => HIDDEN_COLUMNS_FOR_SMALL_SCREEN.includes(column.id))
        .forEach((column) => column.toggleVisibility(!!matches));
    },
    [tableInstance],
  );

  useMatchMedia(`(min-width: ${HIDDEN_COLUMNS_WIDTH_LIMIT}px)`, matchMediaHandler);

  if (data.length < 1) {
    return <NoResultsFound entityName="shared buckets" />;
  }

  return (
    <div className="box tw-overflow-x-auto">
      <div className="table table-hover react-table [&_.tbody_.tr]:tw-h-[58px] [&_.td]:tw-align-middle max-md:[&_.td]:!tw-p-2">
        <div className="thead">
          {tableInstance.getHeaderGroups().map((headerGroup) => (
            <div key={headerGroup.id} className="tr with-action-button is-sticky bg-color-white">
              {headerGroup.headers.map((header) => (
                <div
                  key={header.column.id}
                  className={cn('th', {
                    'text-right': [
                      'last_updated_by',
                      'last_modified',
                      'size',
                      'datashare_owner',
                    ].includes(header.column.id),
                  })}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </div>
              ))}
            </div>
          ))}
        </div>

        <div className="tbody">
          {rows.map((row) => (
            <div
              key={row.id}
              className="tr hoverable-actions-with-replacement clickable"
              onClick={(event) => handleRowClick(row.original, event)}
              onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
              onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
              onKeyDown={onTableRowKeyDown(handleRowAction(row.original))}
              role="button"
              tabIndex={0}
            >
              {row.getVisibleCells().map((cell) => (
                <div
                  key={cell.id}
                  className={cn('td', {
                    'text-right': [
                      'last_updated_by',
                      'last_modified',
                      'size',
                      'datashare_owner',
                    ].includes(cell.column.id),
                  })}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
