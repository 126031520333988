import { Map } from 'immutable';

import { cn } from '@keboola/design';

type Props = {
  type: string;
  group: string;
  label: string;
  searchFilters: Map<string, any>;
  setSearchFilters: (filters: Map<string, any>) => void;
};

export const FilterButton = ({ type, group, label, searchFilters, setSearchFilters }: Props) => {
  const active = group ? searchFilters.get(group) === type : searchFilters.isEmpty();

  const handleClick = () => {
    const newSearchFilters = group
      ? active
        ? searchFilters.remove(group)
        : searchFilters.set(group, type)
      : Map<string, any>();

    setSearchFilters(newSearchFilters);
  };

  return (
    <button className={cn('btn predefined-search-link', { active })} onClick={handleClick}>
      {label}
    </button>
  );
};
