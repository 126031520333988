import createReactClass from 'create-react-class';

import OrchestrationsActionCreators from '@/modules/orchestrations/ActionCreators';
import OrchestrationsStore from '@/modules/orchestrations/stores/OrchestrationsStore';
import EditButtons from '@/react/common/EditButtons';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const OrchestrationTasksButtons = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, OrchestrationsStore)],

  UNSAFE_componentWillReceiveProps() {
    return this.setState(this.getStateFromStores());
  },

  getStateFromStores() {
    const orchestrationId = RoutesStore.getCurrentRouteIntParam('orchestrationId');

    return {
      orchestrationId,
      isEditing: OrchestrationsStore.isEditing(orchestrationId, 'tasks'),
      isSaving: OrchestrationsStore.isSaving(orchestrationId, 'tasks'),
      readOnly: ApplicationStore.isReadOnly(),
    };
  },

  _handleSave() {
    return OrchestrationsActionCreators.saveOrchestrationTasks(this.state.orchestrationId);
  },

  _handleCancel() {
    return OrchestrationsActionCreators.cancelOrchestrationTasksEdit(this.state.orchestrationId);
  },

  _handleStart() {
    return OrchestrationsActionCreators.startOrchestrationTasksEdit(this.state.orchestrationId);
  },

  render() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <EditButtons
        isEditing={this.state.isEditing}
        isSaving={this.state.isSaving}
        editLabel="Edit Tasks"
        onCancel={this._handleCancel}
        onSave={this._handleSave}
        onEditStart={this._handleStart}
      />
    );
  },
});

export default OrchestrationTasksButtons;
