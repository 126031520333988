import { cn, Tooltip } from '@keboola/design';

import Markdown from '@/react/common/Markdown';

const StaticArea = (props: { value: string; readOnly: boolean; onClick: () => void }) => {
  return (
    <Markdown source={props.value}>
      {(markdown) => (
        <Tooltip
          placement="top"
          tooltip="Click to edit description"
          forceHide={props.readOnly || !props.value}
          triggerOnClick={() => {
            if (props.readOnly || !!window.getSelection()?.toString()) {
              return null;
            }

            props.onClick();
          }}
          triggerClassName={cn('tw-block tw-whitespace-normal', { clickable: !props.readOnly })}
        >
          {markdown}
        </Tooltip>
      )}
    </Markdown>
  );
};

export default StaticArea;
