import Promise from 'bluebird';

import { canLoadSharedBuckets } from '@/modules/admin/privileges';
import Metadata from '@/modules/components/MetadataActionCreators';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import Storage from '@/modules/components/StorageActionCreators';
import ApplicationStore from '@/stores/ApplicationStore';

const saveSharedDescription = (bucketId, description) => {
  if (!description) {
    return Promise.resolve();
  }

  return Metadata.saveMetadata('bucket', bucketId, MetadataKeys.SHARED_DESCRIPTION, description);
};

export default {
  shareBucket(
    {
      name,
      displayName,
      description,
      stage,
      tables,
      sharedType,
      color,
      ownerId,
      ownerEmail,
      ...params
    },
    sapiToken,
  ) {
    const backend = sapiToken.getIn(['owner', 'defaultBackend']);

    return Storage.createBucket({ name, displayName, stage, backend }).then((bucket) => {
      const aliasTables = tables
        .map((tableName, tableId) => ({ name: tableName, sourceTable: tableId }))
        .toArray();

      return Promise.each(aliasTables, (table) => {
        return Storage.createAliasTable(bucket.id, table, { skipNotification: true });
      })
        .then(() => Storage.shareBucket(sharedType, bucket.id, params))
        .then(() => saveSharedDescription(bucket.id, description))
        .then(() => {
          if (ownerId || ownerEmail) {
            return Storage.updateBucketOwnerAction({ bucketId: bucket.id, ownerId, ownerEmail });
          }
          return Promise.resolve();
        })
        .then(() => {
          if (color) {
            return Storage.updateBucket(bucket.id, { color });
          }
          return Promise.resolve();
        })
        .catch((error) => {
          Storage.deleteBucket(bucket.id, { forceDelete: true, skipNotification: true });
          throw error;
        });
    });
  },

  shareBucketSimple({ bucketId, sharedType, description, ownerId, ownerEmail, params, color }) {
    return Storage.shareBucket(sharedType, bucketId, params)
      .then(() => saveSharedDescription(bucketId, description))
      .then(() => {
        if (ownerId || ownerEmail) {
          return Storage.updateBucketOwnerAction({ bucketId, ownerId, ownerEmail });
        }
        return Promise.resolve();
      })
      .then(() => {
        if (color) {
          return Storage.updateBucket(bucketId, { color });
        }
        return Promise.resolve();
      });
  },

  loadSharedBuckets() {
    if (!canLoadSharedBuckets(ApplicationStore.getSapiToken())) {
      return Promise.resolve();
    }

    return Storage.sharedBuckets();
  },

  getOwner(bucketId) {
    return Storage.getOwner(bucketId);
  },
};
