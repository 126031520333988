import { useCallback, useMemo } from 'react';
import type { KeyboardEvent, MouseEvent } from 'react';
import type { ColumnDef } from '@tanstack/react-table';
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { Map } from 'immutable';

import { cn } from '@keboola/design';

import { HIDDEN_COLUMNS_WIDTH_LIMIT } from '@/constants';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { toggleExpandedBucket, toggleShowAllBuckets } from '@/modules/storage/actions';
import { routeNames, storageInitialLimit } from '@/modules/storage/constants';
import BlockButton from '@/react/common/BlockButton';
import { onTableRowKeyDown } from '@/react/common/ConfigurationsTable/helpers';
import useMatchMedia from '@/react/hooks/useMatchMedia';
import RoutesStore from '@/stores/RoutesStore';
import type { Data } from '@/types/types';
import hasSelections from '@/utils/hasSelections';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen,
} from '@/utils/windowOpen';

const HIDDEN_COLUMNS_FOR_SMALL_SCREEN = ['last_updated_by'];

type Props = {
  columns: ColumnDef<Data>[];
  data: Data[];
  expandedBuckets: Map<string, boolean>;
  showAll: boolean;
};

export const ExpandableTable = ({ columns, data, expandedBuckets, showAll }: Props) => {
  const initialState = useMemo(
    () => getTableInitialState(data, expandedBuckets),
    [data, expandedBuckets],
  );

  const tableInstance = useReactTable<Data>({
    columns,
    data,
    initialState,
    getRowId: (row) => row.item.get('id'),
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });
  let rows = tableInstance.getRowModel().rows;

  const matchMediaHandler = useCallback(
    ({ matches }: { matches: boolean }) => {
      return tableInstance
        .getAllColumns()
        .filter((column) => HIDDEN_COLUMNS_FOR_SMALL_SCREEN.includes(column.id))
        .forEach((column) => column.toggleVisibility(!!matches));
    },
    [tableInstance],
  );

  useMatchMedia(`(min-width: ${HIDDEN_COLUMNS_WIDTH_LIMIT}px)`, matchMediaHandler);

  if (!showAll) {
    rows = rows.slice(0, storageInitialLimit);
  }

  return (
    <>
      <div className="table table-hover react-table">
        <div className="thead">
          {tableInstance.getHeaderGroups().map((headerGroup) => (
            <div
              key={headerGroup.id}
              className={cn('tr with-action-button is-sticky bg-color-white')}
            >
              {headerGroup.headers.map((header) => {
                return (
                  <div
                    key={header.column.id}
                    className={cn('th', {
                      'w-280 text-right': header.column.id === 'last_updated_by',
                      'w-200 text-right': header.column.id === 'last_modified',
                    })}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        <div className="tbody">
          {rows.map((row) => {
            const rowAction = (
              e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement | Element>,
            ) => {
              if (hasSelections()) {
                return;
              }

              if (row.depth === 0) {
                if (shouldUseNewWindow(e)) {
                  return windowOpen(
                    RoutesStore.getRouter().createHref(routeNames.BUCKET, {
                      bucketId: row.original.item.get('id'),
                    }),
                  );
                }

                toggleExpandedBucket(row.original.item.get('id'), !row.getIsExpanded());
                return row.toggleExpanded(!row.getIsExpanded());
              }

              if (shouldUseNewWindow(e)) {
                return windowOpen(
                  RoutesStore.getRouter().createHref(routeNames.TABLE, {
                    bucketId: row.original.item.getIn(['bucket', 'id']),
                    tableName: row.original.item.get('name'),
                  }),
                );
              }

              return RoutesStore.getRouter().transitionTo(routeNames.TABLE, {
                bucketId: row.original.item.getIn(['bucket', 'id']),
                tableName: row.original.item.get('name'),
              });
            };

            return (
              <div
                key={row.id}
                {...(row.depth === 0 && row.subRows.length === 0
                  ? {
                      className: 'tr hoverable-actions-with-replacement',
                      title: 'No tables in bucket',
                    }
                  : {
                      tabIndex: 0,
                      role: 'button',
                      className: 'tr clickable hoverable-actions-with-replacement',
                      onMouseDown: simulateClickIfMiddleMouseIsUsed.mousedown,
                      onMouseUp: simulateClickIfMiddleMouseIsUsed.mouseup,
                      onClick: rowAction,
                      onKeyDown: onTableRowKeyDown(rowAction),
                    })}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <div
                      key={cell.id}
                      className={cn('td', {
                        'text-right': ['last_updated_by', 'last_modified'].includes(cell.column.id),
                        'bg-selected': row.getIsSelected(),
                        'dev-bucket': isCreatedInDevBranch(
                          row.original.item.has('stage')
                            ? row.original.item
                            : row.original.item.get('bucket'),
                        ),
                      })}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      {tableInstance.getRowModel().rows.length > storageInitialLimit && !showAll && (
        <BlockButton label="Show All" onClick={() => toggleShowAllBuckets(true)} />
      )}
    </>
  );
};

type GetTableInitialStateProps = {
  expanded: Record<number, boolean>;
  columnVisibility?: Record<string, boolean>;
};

const getTableInitialState = (
  data: Data[],
  expandedBuckets: Map<string, boolean>,
): GetTableInitialStateProps => {
  const expanded = data.reduce<Record<number, boolean>>((result, row) => {
    if (expandedBuckets.has(row.item.get('id'))) {
      result[row.item.get('id')] = true;
    }
    return result;
  }, {});

  const isSmallScreen = window.innerWidth < HIDDEN_COLUMNS_WIDTH_LIMIT;

  return {
    expanded,
    ...(isSmallScreen && {
      columnVisibility: createHiddenColumnsVisibility(HIDDEN_COLUMNS_FOR_SMALL_SCREEN),
    }),
  };
};

const createHiddenColumnsVisibility = (columns: string[]): Record<string, boolean> =>
  Object.fromEntries(columns.map((column) => [column, false]));
