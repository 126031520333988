import TablesStore from '@/modules/components/stores/StorageTablesStore';
import { TableLabels } from '@/react/common';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';

const TableRouteLabels = () => {
  const { table } = useStores(
    () => {
      return {
        table: TablesStore.getTableByName(
          RoutesStore.getCurrentRouteParam('tableName'),
          RoutesStore.getCurrentRouteParam('bucketId'),
        ),
      };
    },
    [],
    [RoutesStore, TablesStore],
  );

  return <TableLabels table={table} />;
};

export default TableRouteLabels;
