import type { ReactNode } from 'react';

import { cn, Icon, Tooltip } from '@keboola/design';

type Props = {
  tooltip?: ReactNode;
  className?: string;
  triggerClassName?: string;
};

const InfoTooltip = ({ tooltip, className, triggerClassName }: Props) => {
  return (
    <Tooltip
      placement="top"
      tooltip={tooltip}
      type="static-explanatory"
      triggerClassName={triggerClassName}
    >
      <Icon
        icon="circle-info"
        className={cn('tw-ml-1.5 tw-text-base tw-text-neutral-400', className)}
      />
    </Tooltip>
  );
};

export default InfoTooltip;
