import { Fragment } from 'react';
import { IndexRedirect, IndexRoute, Redirect, Route } from 'react-router';
import Promise, { CancellationError } from 'bluebird';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import RouterActionCreators from '@/actions/RouterActionCreators';
import { FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED } from '@/constants/features';
import { APP_ROUTE } from '@/constants/routeNames';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { switchedAnyBranch } from '@/modules/dev-branches/helpers';
import { hasLegacyOrchestrationInvalidToken } from '@/modules/orchestrations/helpers';
import { routeNames as snowflakePartnerConnectRouteNames } from '@/modules/snowflake-partner-connect/constants';
import {
  routesDisabledInBranch,
  routesDisabledInDemoPreview,
  routesDisabledInProduction,
  routesDisabledInSox,
  routesDisabledInSoxProduction,
  routesDisabledInSPCTrial,
} from '@/routes';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { HTTP_STATUS_CODE_BAD_GATEWAY, HTTP_STATUS_CODE_NOT_FOUND } from './errors/helpers';
import Timer from './Timer';

let pendingPoller = null;
let pendingPromise = null;

const registerPollers = (state) => {
  const poller = RoutesStore.getPollerForLastRoute(state.routes);

  if (poller) {
    const cb = () => {
      if (poller.has('skip') && poller.get('skip')(state.params, state.location.query)) {
        return Promise.resolve();
      }

      ApplicationActionCreators.showPollLoading();
      return Promise.resolve(poller.get('action')(state.params, state.location.query))
        .catch((error) => {
          // Ignore bad gateway errors during background polling
          if (error?.response?.status === HTTP_STATUS_CODE_BAD_GATEWAY) {
            return;
          }

          throw error;
        })
        .finally(() => ApplicationActionCreators.hidePollLoading());
    };

    Timer.poll(cb, { interval: poller.get('interval'), skipFirst: poller.get('skipFirst') });
    pendingPoller = cb;
  }
};

const stopRegisteredPollers = () => {
  if (pendingPoller) {
    Timer.stop(pendingPoller);
    pendingPoller = null;
  }
};

const cancelPendingPromises = () => {
  if (pendingPromise) {
    pendingPromise.cancel();
  }
};

const loadRequiredData = (state, initialLoading = false) => {
  cancelPendingPromises();
  stopRegisteredPollers();

  RouterActionCreators.routeChangeStart(state);

  const promises = RoutesStore.getRequireDataFunctionsForRouterState(state.routes)
    .map((requireData) => Promise.resolve(requireData(state.params, state.location.query, state)))
    .toArray();

  if (initialLoading && promises.length > 0) {
    ApplicationActionCreators.showInitialLoading();
  }

  pendingPromise = Promise.all(promises);

  return pendingPromise
    .then(() => {
      registerPollers(state);
      RouterActionCreators.routeChangeSuccess(state);
      return null;
    })
    .catch((e) => {
      // bluebird error, calling cancel on already cancelled  promise
      if (e instanceof CancellationError) {
        return;
      }

      if (
        e?.response?.statusCode === HTTP_STATUS_CODE_NOT_FOUND ||
        hasLegacyOrchestrationInvalidToken(e)
      ) {
        if (switchedAnyBranch() && !state.location.state?.force) {
          return RoutesStore.getRouter().transitionToForce(APP_ROUTE);
        }

        return RouterActionCreators.routeChangeError(e, state);
      }

      throw e;
    })
    .finally(() => {
      if (initialLoading && promises.length > 0) {
        ApplicationActionCreators.hideInitialLoading();
      }
    });
};

const onEnter = (nextState, replace, callback) => {
  loadRequiredData(nextState, true).finally(callback);
};

const onChange = (prevState, nextState, replace, callback) => {
  loadRequiredData(nextState).finally(callback);
};

const getDisabledRoutes = () => {
  let disabledRoutes = DevBranchesStore.isDevModeActive()
    ? routesDisabledInBranch
    : routesDisabledInProduction;

  if (ApplicationStore.isDemoPreview()) {
    disabledRoutes = disabledRoutes.concat(routesDisabledInDemoPreview);
  }

  if (ApplicationStore.hasProtectedDefaultBranch()) {
    disabledRoutes = disabledRoutes.concat(routesDisabledInSox);

    if (!DevBranchesStore.isDevModeActive()) {
      disabledRoutes = disabledRoutes.concat(routesDisabledInSoxProduction);
    }
  }

  return [...new Set(disabledRoutes)];
};

const createReactRouterRoutes = (rootRoute, baseUrl) => {
  let _key = 0;

  const composeRoutes = (route) => {
    if (!route.childRoutes || route.childRoutes.length === 0) {
      return (
        <Route
          key={_key++}
          name={route.name}
          path={route.path || route.name}
          component={route.defaultRouteHandler}
          onEnter={route.onEnter}
        />
      );
    }

    return (
      <Route key={_key++} name={route.name} path={route.path || route.name} onEnter={route.onEnter}>
        <IndexRoute component={route.defaultRouteHandler} />
        {route.childRoutes.map(composeRoutes)}
      </Route>
    );
  };

  return (
    <Route
      path={baseUrl}
      onEnter={onEnter}
      onChange={onChange}
      component={rootRoute.handler}
      key={_key++}
    >
      <IndexRedirect to={rootRoute.path} />
      <Route
        path={rootRoute.path}
        name={rootRoute.name}
        component={rootRoute.defaultRouteHandler}
        onEnter={rootRoute.onEnter}
      />
      {getDisabledRoutes().map((routeName) => (
        <Fragment key={routeName}>
          <Redirect from={routeName} to={rootRoute.path} />
          <Redirect from={`${routeName}/*`} to={rootRoute.path} />
        </Fragment>
      ))}
      {ApplicationStore.hasCurrentProjectFeature(FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED) &&
        routesDisabledInSPCTrial.map((routeName) => (
          <Fragment key={routeName}>
            <Redirect
              from={routeName}
              to={snowflakePartnerConnectRouteNames.UPGRADE_PAGE}
              query={{ d: routeName }}
            />
            <Redirect
              from={`${routeName}/*`}
              to={snowflakePartnerConnectRouteNames.UPGRADE_PAGE}
              query={{ d: routeName }}
            />
          </Fragment>
        ))}
      {/* TODO: This redirect is disabled for testing purposes. It should be enabled when the feature is released.
      {ApplicationStore.hasCurrentProjectFeature(FEATURE_LEGACY_TRANSFORMATIONS_NEW_QUEUE) && (
        <>
          <Redirect from="transformations" to={fakeLegacyTransformationRouteNames.ROOT} />
          <Redirect from="transformations/*" to={`${fakeLegacyTransformationRouteNames.ROOT}/*`} />
        </>
      )} */}
      {rootRoute.childRoutes.map(composeRoutes)}
      <Route component={rootRoute.notFoundRouteHandler} path="*" name="notFound" />
    </Route>
  );
};

export default createReactRouterRoutes;
