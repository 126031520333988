import { cn, Icon } from '@keboola/design';

const Loader = ({
  className = '',
  fixedWidth = true,
}: {
  className?: string | Record<string, boolean>;
  fixedWidth?: boolean;
}) => {
  return <Icon spin icon="spinner" fixedWidth={fixedWidth} className={cn(className)} />;
};

export default Loader;
