import { useState, useTransition } from 'react';
import type { ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'underscore';

export type Sliceable<T> = { slice: (start: number, count: number) => T };

const LazyList = <T extends Sliceable<T>>(props: {
  items: T;
  limit?: number;
  render?: (items: T) => any;
  rootElement?: ReactNode;
  className?: string;
  useWindow?: boolean;
  getScrollParent?: () => HTMLElement | null;
}) => {
  const defaultLimit = props.limit || 20;
  const [isPending, startTransition] = useTransition();
  const [limit, setLimit] = useState(defaultLimit);
  const items = props.items.slice(0, limit);

  return (
    <InfiniteScroll
      hasMore={!isPending && !_.isEqual(items, props.items)}
      loadMore={() => startTransition(() => setLimit(limit + defaultLimit))}
      element={props.rootElement}
      className={props.className}
      useWindow={props.useWindow}
      getScrollParent={props.getScrollParent}
    >
      {props.render ? props.render(items) : items}
    </InfiniteScroll>
  );
};

export default LazyList;
