import { Component } from 'react';
import PropTypes from 'prop-types';

import { FormGroup, HelpBlock, Label, PanelWithDetails, TextInput } from '@keboola/design';

import Checkbox from '@/react/common/Checkbox';

const FORM_GROUP_CLASS_NAMES = 'tw-grid tw-grid-cols-3 tw-items-baseline tw-mb-4 tw-gap-4';

class Source extends Component {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Source</h3>
        </div>
        <div className="box-content with-panel">
          <FormGroup className={FORM_GROUP_CLASS_NAMES}>
            <Label htmlFor="s3-bucket">S3 Bucket</Label>
            <div className="tw-col-span-2">
              <TextInput
                id="s3-bucket"
                variant="secondary"
                value={this.props.value.bucket}
                onChange={(value) => {
                  this.props.onChange({ bucket: value });
                }}
                placeholder="mybucket"
                disabled={this.props.disabled}
              />
            </div>
          </FormGroup>

          <FormGroup className={FORM_GROUP_CLASS_NAMES}>
            <Label htmlFor="search-key">Search Key</Label>
            <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
              <TextInput
                id="search-key"
                variant="secondary"
                value={this.props.value.key}
                onChange={(value) => {
                  this.props.onChange({ key: value });
                }}
                placeholder="myfolder/myfile.csv"
                disabled={this.props.disabled}
              />
              <HelpBlock>
                Filename including folders or a prefix. Do not type <code>*</code> or <code>%</code>{' '}
                wildcards, use <strong>Wildcard</strong> checkbox instead.
              </HelpBlock>
            </div>
          </FormGroup>

          <div className="-tw-mx-5">
            <PanelWithDetails
              defaultExpanded={
                this.props.value.newFilesOnly ||
                this.props.value.wildcard ||
                this.props.value.subfolders
              }
              placement="bottom"
              labelOpen="Show additional source settings"
              labelCollapse="Hide additional source settings"
            >
              <FormGroup className={FORM_GROUP_CLASS_NAMES}>
                <div className="tw-col-span-2 tw-col-start-2 tw-flex tw-flex-col tw-gap-1">
                  <Checkbox
                    checked={this.props.value.newFilesOnly}
                    onChange={(checked) => this.props.onChange({ newFilesOnly: checked })}
                    disabled={this.props.disabled}
                  >
                    New Files Only
                  </Checkbox>
                  <HelpBlock>
                    Every job stores the timestamp of the last downloaded file and a subsequent job
                    can pick up from there.
                  </HelpBlock>
                </div>
              </FormGroup>

              <FormGroup className={FORM_GROUP_CLASS_NAMES}>
                <div className="tw-col-span-2 tw-col-start-2 tw-flex tw-flex-col tw-gap-1">
                  <Checkbox
                    checked={this.props.value.wildcard}
                    onChange={(checked) => {
                      let change = { wildcard: checked };
                      if (change.wildcard === false) {
                        change.subfolders = false;
                      }
                      this.props.onChange(change);
                    }}
                    disabled={this.props.disabled}
                  >
                    Wildcard
                  </Checkbox>
                  <HelpBlock>Match all files beginning with the specified key.</HelpBlock>
                </div>
              </FormGroup>

              <FormGroup className={FORM_GROUP_CLASS_NAMES}>
                <div className="tw-col-span-2 tw-col-start-2 tw-flex tw-flex-col tw-gap-1">
                  <Checkbox
                    checked={this.props.value.subfolders}
                    onChange={(checked) => this.props.onChange({ subfolders: checked })}
                    disabled={this.props.disabled || !this.props.value.wildcard}
                  >
                    Subfolders
                  </Checkbox>
                  <HelpBlock>Download subfolders recursively.</HelpBlock>
                </div>
              </FormGroup>
            </PanelWithDetails>
          </div>
        </div>
      </div>
    );
  }
}

Source.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Source;
