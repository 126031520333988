import { Skeleton } from '@keboola/design';

export const ModalBodySkeleton = () => (
  <div className="tw-flex tw-flex-col tw-gap-2">
    <div className="tw-flex tw-justify-between tw-gap-6">
      <Skeleton height="xs" className="tw-w-20" />
      <Skeleton height="xs" className="tw-w-96" />
    </div>
    <div className="tw-flex tw-justify-between tw-gap-6">
      <Skeleton height="xs" className="tw-w-20" />
      <Skeleton height="xs" className="tw-w-72" />
    </div>

    <div className="tw-flex tw-justify-between tw-gap-6">
      <Skeleton height="xs" className="tw-w-28" />
      <Skeleton height="xs" className="tw-w-52" />
    </div>

    <div className="tw-flex tw-justify-between tw-gap-6">
      <Skeleton height="xs" className="tw-w-24" />
      <Skeleton height="xs" className="tw-w-44" />
    </div>

    <div className="tw-flex tw-justify-between tw-gap-6">
      <Skeleton height="xs" className="tw-w-28" />
      <Skeleton height="xs" className="tw-w-48" />
    </div>

    <div className="tw-flex tw-justify-between tw-gap-6">
      <Skeleton height="xs" className="tw-w-28" />
      <Skeleton height="xs" className="tw-w-32" />
    </div>

    <Skeleton height="xl" className="tw-mt-2 tw-w-full" />
  </div>
);
