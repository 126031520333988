import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { Icon } from '@keboola/design';

import TaskSelectTable from '@/modules/orchestrations/react/components/TaskSelectTable';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import Loader from '@/react/common/Loader';

const TaskSelect = createReactClass({
  propTypes: {
    tasks: PropTypes.object.isRequired,
    onTaskUpdate: PropTypes.func.isRequired,
    onTasksUpdate: PropTypes.func.isRequired,
    onRun: PropTypes.func.isRequired,
    onOpen: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return { showModal: false };
  },

  close() {
    return this.setState({
      showModal: false,
    });
  },

  open() {
    this.props.onOpen();
    return this.setState({
      showModal: true,
    });
  },

  render() {
    return (
      <span>
        {this.renderOpenButton()}
        <Modal show={this.state.showModal} bsSize="large" onHide={this.close}>
          <Modal.Header closeButton>
            <Modal.Title>Retry Job</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p>You are about to run the orchestration again</p>
              <TaskSelectTable
                tasks={this.props.tasks}
                onTaskUpdate={this.props.onTaskUpdate}
                onTasksUpdate={this.props.onTasksUpdate}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              isDisabled={!this._isValid()}
              saveLabel="Run"
              onSave={this._handleRun}
            />
          </Modal.Footer>
        </Modal>
      </span>
    );
  },

  renderOpenButton() {
    return (
      <Button onClick={this.open}>
        {this.props.isSaving ? (
          <Loader className="icon-addon-right" />
        ) : (
          <Icon icon="play" className="icon-addon-right" />
        )}
        Job retry
      </Button>
    );
  },

  _handleRun() {
    this.props.onRun();
    return this.close();
  },

  _isValid() {
    return (
      this.props.tasks
        .filter(
          (tasks) =>
            tasks
              .get('tasks')
              .filter((task) => task.get('active'))
              .count() > 0,
        )
        .count() > 0
    );
  },
});

export default TaskSelect;
