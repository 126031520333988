import { IconButton } from '@keboola/design';

const Pagination = ({
  onClick,
  currentPage,
  showPagination,
  allPagesNum,
}: {
  onClick: (newPage: number) => void;
  currentPage: number;
  showPagination: boolean;
  allPagesNum: number;
}) => {
  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-rounded-b-md tw-px-2 tw-font-medium tw-text-neutral-400">
      {showPagination ? (
        <div className="tw-mb-2 tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between">
          <IconButton
            variant="invisible"
            disabled={currentPage === 1}
            size="small"
            onClick={() => {
              if (currentPage !== 1) {
                onClick(currentPage - 1);
              }
            }}
            icon="chevron-left"
          />
          <span>
            {currentPage} of {allPagesNum}
          </span>
          <IconButton
            variant="invisible"
            disabled={currentPage === allPagesNum}
            size="small"
            onClick={() => {
              if (currentPage !== allPagesNum) {
                onClick(currentPage + 1);
              }
            }}
            icon="chevron-right"
          />
        </div>
      ) : (
        <div className="tw-p-4" />
      )}
    </div>
  );
};

export default Pagination;
