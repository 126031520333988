const skeletonSVGGradient = (
  <defs>
    <linearGradient id="animatedGradient" x1="0%" y1="0%" x2="0%" y2="0%">
      <stop offset="0%" stopColor="#c5cbd6" />
      <stop offset="20%" stopColor="#c5cbd6" />
      <stop offset="50%" stopColor="#e0e3e8" />
      <stop offset="80%" stopColor="#c5cbd6" />
      <stop offset="100%" stopColor="#c5cbd6" />
      <animate attributeName="x1" dur="1.3s" from="-200%" to="50%" repeatCount="indefinite" />
      <animate attributeName="x2" dur="1.3s" from="50%" to="200%" repeatCount="indefinite" />
    </linearGradient>
  </defs>
);

export default skeletonSVGGradient;
