import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { Link } from '@keboola/design';

import { IS_DEVELOPMENT } from '@/constants/environment';
import ApplicationStore from '@/stores/ApplicationStore';

export const DevButtons = () => {
  if (!IS_DEVELOPMENT) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        display: 'inline-block',
        left: '1em',
        bottom: '1em',
        backgroundColor: 'white',
        borderRadius: '.33em',
        border: 'thin solid #e3e9f3',
        fontFamily: 'monospace',
        lineHeight: '1.8em',
        padding: '0 .6em',
        zIndex: 3000,
      }}
      className="[&_.tsqd-open-btn-container]:tw-bottom-14"
    >
      <a href="/dev.html" title="Open local development settings">
        DEV
      </a>
      {' | '}
      <Link
        href={ApplicationStore.getSapiUrl() + window.location.pathname}
        title="Open in production"
      >
        PROD
      </Link>
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
    </div>
  );
};
