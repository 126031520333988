import { Icon, Tooltip } from '@keboola/design';

const ContinueOnFailureIcon = () => {
  return (
    <Tooltip placement="bottom" tooltip="Continue on Failure" triggerClassName="simple-icon">
      <Icon icon="rotate-exclamation" className="color-danger f-16" />
    </Tooltip>
  );
};

export default ContinueOnFailureIcon;
