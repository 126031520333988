import type { GenericFetchClient } from '../storageClient';

import type { GetWorkspacePath, Workspace } from './types';

export const createWorkspaces = (client: GenericFetchClient) => {
  const getWorkspace = async (id: GetWorkspacePath['workspaceId'], signal?: AbortSignal) => {
    const { data } = await client.get<Workspace, GetWorkspacePath>(
      '/workspaces/{workspaceId}',
      { path: { workspaceId: id } },
      { signal },
    );

    return data;
  };

  return {
    getWorkspace,
  };
};
