import type { ComponentProps } from 'react';

import { cn, Icon } from '@keboola/design';

type SyntaxType = 'invalid' | 'valid';

type Props = {
  type: SyntaxType;
  text: string;
};

const icon: Record<SyntaxType, ComponentProps<typeof Icon>['icon']> = {
  invalid: 'ban',
  valid: 'circle-check',
};

export const SqlSyntaxItem = ({ type, text }: Props) => (
  <div
    className={cn(
      // positioning
      'tw-flex',
      'tw-gap-2',
      'tw-items-center',

      // text styling
      'tw-text-sm',
      'tw-font-normal',
      'tw-tracking-widest',
      'tw-text-neutral-200',
      {
        'tw-opacity-50': type == 'invalid',
      },
    )}
  >
    <Icon
      className={cn('tw-h-4 tw-w-4', {
        'tw-text-primary-500': type === 'valid',
        'tw-text-error-500': type === 'invalid',
      })}
      icon={icon[type]}
    />
    <span>CREATE VIEW</span>
    <span className="tw-font-bold tw-text-white">{text}</span>
    <span>as ..</span>
  </div>
);
