import createReactClass from 'create-react-class';

import { URLS } from '@keboola/constants';
import { cn, Icon } from '@keboola/design';

import { TRANSFORMATION } from '@/constants/componentIds';
import { SIDEBAR } from '@/constants/external';
import DocumentationLink from '@/modules/components/react/components/DocumentationLink';
import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import SidebarJobs from '@/modules/components/react/components/SidebarJobs';
import SidebarVersions from '@/modules/components/react/components/SidebarVersions';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import VersionsStore from '@/modules/components/stores/VersionsStore';
import RowVersionsStore from '@/modules/configurations/RowVersionsStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import LatestJobsStore from '@/modules/jobs/stores/LatestJobsStore';
import NotificationsStore from '@/modules/notifications/store';
import TransformationsActionCreators from '@/modules/transformations/ActionCreators';
import { backends, routeNames } from '@/modules/transformations/Constants';
import CopyTransformationButton from '@/modules/transformations/react/components/CopyTransformationButton';
import CreateSandboxButton from '@/modules/transformations/react/components/CreateSandboxButton';
import TransformationVersion from '@/modules/transformations/react/components/TransformationVersion';
import Phase from '@/modules/transformations/react/modals/Phase';
import TransformationBucketsStore from '@/modules/transformations/stores/TransformationBucketsStore';
import TransformationsStore from '@/modules/transformations/stores/TransformationsStore';
import * as sandboxUtils from '@/modules/transformations/utils/sandboxUtils';
import { isKnownTransformationType } from '@/modules/transformations/utils/transformationTypes';
import { RouterLink as Link } from '@/react/common';
import ActivateDeactivateButton from '@/react/common/ActivateDeactivateButton';
import Confirm from '@/react/common/Confirm';
import RunUnsavedWarning from '@/react/common/RunUnsavedWarning';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import TransformationDetailStatic from './TransformationDetailStatic';

const TransformationDetail = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      DevBranchesStore,
      TransformationsStore,
      TransformationBucketsStore,
      InstalledComponentsStore,
      ComponentsStore,
      StorageTablesStore,
      StorageBucketsStore,
      VersionsStore,
      RowVersionsStore,
      LatestJobsStore,
    ),
  ],

  UNSAFE_componentWillReceiveProps() {
    return this.setState(this.getStateFromStores());
  },

  getStateFromStores() {
    let highlightQueryNumber;
    const componentId = TRANSFORMATION;
    const routerState = RoutesStore.getRouterState();
    const bucketId = RoutesStore.getCurrentRouteParam('config');
    const transformationId = RoutesStore.getCurrentRouteParam('row');
    const versions = VersionsStore.getVersions(componentId, bucketId);
    const latestVersionId = versions.map((v) => v.get('version')).max();

    if (routerState.hasIn(['location', 'query', 'highlightQueryNumber'])) {
      highlightQueryNumber = parseInt(
        routerState.getIn(['location', 'query', 'highlightQueryNumber']),
        10,
      );
    }

    return {
      readOnly: ApplicationStore.isReadOnly(),
      notifications: NotificationsStore.getAll(),
      component: ComponentsStore.getComponent(componentId),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      bucket: TransformationBucketsStore.get(bucketId),
      transformationBuckets: TransformationBucketsStore.getAll(),
      allConfigurations: InstalledComponentsStore.getAll(),
      transformation: TransformationsStore.getTransformation(bucketId, transformationId),
      editingFields: TransformationsStore.getTransformationEditingFields(
        bucketId,
        transformationId,
      ),
      pendingActions: TransformationsStore.getTransformationPendingActions(
        bucketId,
        transformationId,
      ),
      tables: StorageTablesStore.getAll(),
      buckets: StorageBucketsStore.getAll(),
      bucketId,
      transformationId,
      transformations: TransformationsStore.getTransformations(bucketId),
      isTransformationEditingValid: TransformationsStore.getTransformationEditingIsValid(
        bucketId,
        transformationId,
      ),
      highlightQueryNumber,
      latestVersionId,
      latestJobs: LatestJobsStore.getTransformationJobs(bucketId, transformationId),
      rowVersions: RowVersionsStore.getVersions(componentId, bucketId, transformationId),
      rowVersionsConfigs: RowVersionsStore.getVersionsConfigs(
        componentId,
        bucketId,
        transformationId,
      ),
      isLoadingRowVersions: RowVersionsStore.isLoadingVersions(
        componentId,
        bucketId,
        transformationId,
      ),
      isPendingRowVersions: RowVersionsStore.isPendingConfig(
        componentId,
        bucketId,
        transformationId,
      ),
      pendingMultiLoadRowVersions: RowVersionsStore.getPendingMultiLoad(
        componentId,
        bucketId,
        transformationId,
      ),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      admins: ApplicationStore.getAdmins(),
    };
  },

  resolveLinkDocumentationLink() {
    let subpageName;
    const documentationLink = `${URLS.USER_DOCUMENTATION}/transformations/`;
    const transformationType = this.state.transformation.get('type');

    if (transformationType === 'simple') {
      subpageName = this.state.transformation.get('backend');
    } else {
      subpageName = transformationType;
    }

    return documentationLink + subpageName;
  },

  _deleteTransformation() {
    const bucketId = this.state.bucket.get('id');
    RoutesStore.getRouter().transitionTo(routeNames.BUCKET, { config: bucketId });
    const transformationId = this.state.transformation.get('id');
    return TransformationsActionCreators.deleteTransformation(bucketId, transformationId);
  },

  _handleActiveChange(newValue) {
    let changeDescription;
    if (newValue) {
      changeDescription = `Transformation ${this.state.transformation.get(
        'name',
        this.state.transformation.get('id'),
      )} enabled`;
    } else {
      changeDescription = `Transformation ${this.state.transformation.get(
        'name',
        this.state.transformation.get('id'),
      )} disabled`;
    }
    return TransformationsActionCreators.changeTransformationProperty(
      this.state.bucketId,
      this.state.transformationId,
      'disabled',
      !newValue,
      changeDescription,
    );
  },

  _showDetails() {
    return (
      (this.state.transformation.get('backend') === 'redshift' &&
        this.state.transformation.get('type') === 'simple') ||
      (this.state.transformation.get('backend') === 'snowflake' &&
        this.state.transformation.get('type') === 'simple') ||
      this.state.transformation.get('backend') === 'docker'
    );
  },

  render() {
    const transformationBackend = this.state.transformation.get('backend');
    const transformationType = this.state.transformation.get('type');
    const { bucketId, latestVersionId } = this.state;

    return (
      <div className="row">
        <div className="col-sm-9">
          <TransformationDetailStatic
            readOnly={this.state.readOnly}
            bucket={this.state.bucket}
            transformation={this.state.transformation}
            editingFields={this.state.editingFields}
            transformations={this.state.transformations}
            pendingActions={this.state.pendingActions}
            tables={this.state.tables}
            buckets={this.state.buckets}
            bucketId={this.state.bucketId}
            transformationId={this.state.transformationId}
            showDetails={this._showDetails()}
            isEditingValid={this.state.isTransformationEditingValid}
            isQueriesProcessing={this.state.pendingActions.has('queries-processing')}
            highlightQueryNumber={this.state.highlightQueryNumber}
          />
        </div>
        <div className="col-sm-3">
          <div className={`sidebar-content ${SIDEBAR}`}>
            <ul className="nav nav-stacked">
              {!this.state.readOnly && isKnownTransformationType(this.state.transformation) && (
                <>
                  <li className={cn({ disabled: this.state.transformation.get('disabled') })}>
                    <RunComponentButton
                      mode="sidebar"
                      title="Run Transformation"
                      componentId={TRANSFORMATION}
                      disabled={this.state.transformation.get('disabled')}
                      disabledReason={
                        this.state.transformation.get('disabled')
                          ? 'Transformation is disabled'
                          : ''
                      }
                      runParams={() => ({
                        configBucketId: this.state.bucketId,
                        transformations: [this.state.transformation.get('id')],
                      })}
                      forceModal={this.state.editingFields.get('queriesChanged', false)}
                    >
                      {this.state.editingFields.get('queriesChanged', false) && (
                        <RunUnsavedWarning
                          section={
                            this.state.transformation.get('backend') === backends.DOCKER
                              ? 'scripts'
                              : 'queries'
                          }
                          entity="transformation"
                        />
                      )}
                      You are about to run the transformation{' '}
                      {this.state.transformation.get('name', this.state.transformation.get('id'))}.
                    </RunComponentButton>
                  </li>
                  <li>
                    <ActivateDeactivateButton
                      isActive={!this.state.transformation.get('disabled')}
                      isPending={this.state.pendingActions.has('save-disabled')}
                      onChange={this._handleActiveChange}
                    />
                  </li>
                </>
              )}
              {isKnownTransformationType(this.state.transformation) && (
                <li>
                  <Link
                    to={routeNames.DETAIL_GRAPH}
                    params={{
                      row: this.state.transformation.get('id'),
                      config: this.state.bucket.get('id'),
                    }}
                  >
                    <Icon icon="magnifying-glass" fixedWidth />
                    Overview
                  </Link>
                </li>
              )}
              {!this.state.readOnly && (
                <>
                  <li>
                    <Phase
                      transformation={this.state.transformation}
                      bucketId={this.state.bucketId}
                    />
                  </li>
                  <li>
                    <TransformationVersion
                      transformation={this.state.transformation}
                      bucketId={this.state.bucketId}
                    />
                  </li>
                </>
              )}
              {!this.state.readOnly &&
                !this.state.isDevModeActive &&
                sandboxUtils.hasSandbox(transformationBackend, transformationType) && (
                  <li>
                    <CreateSandboxButton
                      backend={transformationBackend}
                      transformationType={transformationType}
                      runParams={sandboxUtils.generateRunParameters(
                        this.state.transformation,
                        bucketId,
                        latestVersionId,
                      )}
                    />
                  </li>
                )}
              {!this.state.readOnly && (
                <li>
                  <CopyTransformationButton
                    transformation={this.state.transformation}
                    transformationBuckets={this.state.transformationBuckets}
                    bucketId={this.state.bucketId}
                  />
                </li>
              )}
              {isKnownTransformationType(this.state.transformation) && (
                <li>
                  <DocumentationLink href={this.resolveLinkDocumentationLink()} />
                </li>
              )}
              {!this.state.readOnly && (
                <li>
                  <hr />
                  <Confirm
                    icon="trash"
                    title="Delete Transformation"
                    text={`Are you sure you want to delete the transformation ${this.state.transformation.get(
                      'name',
                      this.state.transformation.get('id'),
                    )}?`}
                    buttonLabel="Delete"
                    buttonType="danger"
                    onConfirm={this._deleteTransformation}
                    childrenRootElement="a"
                  >
                    <Icon icon="trash" fixedWidth />
                    <span className="text-muted">Delete transformation</span>
                  </Confirm>
                </li>
              )}
            </ul>
            {isKnownTransformationType(this.state.transformation) && (
              <SidebarJobs
                hasNewQueue={false}
                jobs={this.state.latestJobs}
                admins={this.state.admins}
                allConfigurations={this.state.allConfigurations}
                componentId={TRANSFORMATION}
                configId={this.state.bucketId}
                rowId={this.state.transformationId}
                currentAdmin={this.state.currentAdmin}
                notifications={this.state.notifications}
              />
            )}
            {isKnownTransformationType(this.state.transformation) && (
              <>
                <hr />
                <SidebarVersions
                  component={this.state.component}
                  configId={this.state.bucketId}
                  rowId={this.state.transformationId}
                  versions={this.state.rowVersions}
                  versionsConfigs={this.state.rowVersionsConfigs}
                  versionsLinkTo={routeNames.BUCKET_ROW_VERSIONS}
                  versionsLinkParams={{
                    component: TRANSFORMATION,
                    config: this.state.bucketId,
                    row: this.state.transformationId,
                  }}
                  admins={this.state.admins}
                  isLoading={this.state.isLoadingRowVersions}
                  isPending={this.state.isPendingRowVersions}
                  pendingMultiLoad={this.state.pendingMultiLoadRowVersions}
                  limit={5}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  },
});

export default TransformationDetail;
