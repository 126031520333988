import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { Alert, ButtonGroup, ButtonInline, HelpBlock, IconButton } from '@keboola/design';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import DataCatalogActions from '@/modules/data-catalog/actions';
import { SHARED_TYPES } from '@/modules/data-catalog/constants';
import { getSharedBucketDescription } from '@/modules/data-catalog/helpers';
import { filterProductionBuckets } from '@/modules/dev-branches/helpers';
import { EXTERNAL_DATASET_DISABLE_TOOLTIP } from '@/modules/storage/constants';
import { bucketDisplayNameWithStage, isBucketShared } from '@/modules/storage/helpers';
import { backends } from '@/modules/transformations/Constants';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import { bucketLabel } from '@/react/common/selectLabels';
import { getFormattedAdminsForSelect } from '@/utils/getFormattedAdmins';
import { BucketColorSelect } from './BucketColorSelect';
import { DataShareOwnerSelect } from './DataShareOwnerSelect';
import ShareWithSelect from './ShareWithSelect';

const INITIAL_STATE = {
  isSharing: false,
  error: null,
  selectedBucket: null,
  sharedDescription: '',
  sourceDescription: '',
  sharedType: SHARED_TYPES.ORGANIZATION_MEMBER,
  color: void 0,
  ownerId: void 0,
  targetUsers: List(),
  targetProjects: List(),
};

const ShareExistingBucket = createReactClass({
  propTypes: {
    onHide: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    allBuckets: PropTypes.instanceOf(Map).isRequired,
    availableUsersOptions: PropTypes.array.isRequired,
    availableProjectsOptions: PropTypes.array.isRequired,
    defaultSelectedBucket: PropTypes.instanceOf(Map),
  },

  getInitialState() {
    const { defaultSelectedBucket } = this.props;
    return defaultSelectedBucket
      ? {
          ...INITIAL_STATE,
          selectedBucket: defaultSelectedBucket.get('id'),
          sourceDescription: getSharedBucketDescription(defaultSelectedBucket) || '',
        }
      : INITIAL_STATE;
  },

  render() {
    return (
      <>
        <form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Share Existing Bucket</Modal.Title>
            <ModalIcon icon="folder" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            {this.state.error && (
              <Alert variant="error" className="tw-mb-5">
                {this.state.error}
              </Alert>
            )}
            <FormGroup controlId="formSelectBucketControl">
              <ControlLabel>Select a bucket</ControlLabel>
              <Select
                autoFocus={!this.state.selectedBucket}
                id="formSelectBucketControl"
                clearable={false}
                value={this.state.selectedBucket}
                onChange={this.handleSelectBucket}
                options={filterProductionBuckets(this.props.allBuckets)
                  .filter((bucket) => !bucket.get('sourceBucket') && !isBucketShared(bucket))
                  .sortBy((bucket) => bucketDisplayNameWithStage(bucket))
                  .map((bucket) => ({
                    value: bucket.get('id'),
                    label: bucketLabel(bucket),
                    name: bucket.get('displayName'),
                    isDisabled:
                      bucket.get('hasExternalSchema') &&
                      bucket.get('backend') !== backends.SNOWFLAKE,
                    disabledReason: EXTERNAL_DATASET_DISABLE_TOOLTIP.DATA_CATALOG_BUCKET,
                  }))
                  .toArray()}
              />
            </FormGroup>

            <FormGroup controlId="formDescriptionConrol">
              <ControlLabel>Description</ControlLabel>
              <FormControl
                rows={3}
                componentClass="textarea"
                value={this.state.sharedDescription}
                onChange={(e) => this.setState({ sharedDescription: e.target.value })}
              />
              <HelpBlock className="tw-mt-1">
                New description for shared bucket.
                {this.state.sourceDescription && (
                  <>
                    {' '}
                    To copy the original description from the bucket{' '}
                    <ButtonInline
                      className="tw-text-xs"
                      onClick={() => {
                        this.setState({ sharedDescription: this.state.sourceDescription });
                      }}
                    >
                      click here
                    </ButtonInline>
                    .
                  </>
                )}
              </HelpBlock>
            </FormGroup>

            <ShareWithSelect
              value={this.state.sharedType}
              onChange={(type) => this.setState({ sharedType: type })}
              hasUsersOptions={this.props.availableUsersOptions.length > 0}
              hasProjectsOptions={this.props.availableProjectsOptions.length > 0}
              disabled={this.state.isSharing}
            />

            {this.renderAdditionalControls()}

            <BucketColorSelect
              value={this.state.color}
              onChange={(color) => this.setState({ color })}
              disabled={this.state.isSharing}
            />

            <DataShareOwnerSelect
              options={getFormattedAdminsForSelect()}
              value={this.state.ownerId}
              onChange={(ownerId) => this.setState({ ownerId })}
              disabled={this.state.isSharing}
            />
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup variant="block">
              {this.props?.onBack && (
                <IconButton variant="outline" icon="arrow-left" onClick={this.props.onBack} />
              )}
              <ConfirmButtons
                block
                saveButtonType="submit"
                saveLabel={this.state.isSharing ? 'Sharing bucket...' : 'Share a bucket'}
                isSaving={this.state.isSharing}
                isDisabled={this.isDisabled()}
              />
            </ButtonGroup>
          </Modal.Footer>
        </form>
      </>
    );
  },

  renderAdditionalControls() {
    if (this.state.sharedType === SHARED_TYPES.SELECTED_PEOPLE) {
      return (
        <Select
          multi
          placeholder="Select users"
          options={this.props.availableUsersOptions}
          value={this.state.targetUsers}
          onChange={(targetUsers) => this.setState({ targetUsers })}
        />
      );
    }

    if (this.state.sharedType === SHARED_TYPES.SELECTED_PROJECT) {
      return (
        <Select
          multi
          placeholder="Select projects"
          options={this.props.availableProjectsOptions}
          value={this.state.targetProjects}
          onChange={(targetProjects) => this.setState({ targetProjects })}
        />
      );
    }

    return null;
  },

  handleSelectBucket(selected) {
    const bucket = this.props.allBuckets.get(selected);

    this.setState({
      selectedBucket: bucket.get('id'),
      sourceDescription: getSharedBucketDescription(bucket) || '',
    });
  },

  handleSubmit(e) {
    e.preventDefault();

    const params = {
      targetProjectIds: this.state.targetProjects.toJS(),
      targetUsers: this.state.targetUsers.toJS(),
    };

    this.setState({ isSharing: true });
    DataCatalogActions.shareBucketSimple({
      bucketId: this.state.selectedBucket,
      sharedType: this.state.sharedType,
      description: this.state.sharedDescription,
      ownerId: this.state.ownerId,
      color: this.state.color,
      params,
    })
      .then(() => {
        const sharedBucket = this.props.allBuckets.get(this.state.selectedBucket);
        ApplicationActionCreators.sendNotification({
          type: 'success',
          message: `Bucket "${sharedBucket.get('displayName')}" has been shared.`,
        });
        this.props.onHide();
      })
      .catch((error) => {
        this.setState({ error, isSharing: false });
        return null;
      });
  },

  isDisabled() {
    if (this.state.isSharing || !this.state.selectedBucket) {
      return true;
    }

    if (this.state.sharedType === SHARED_TYPES.SELECTED_PEOPLE) {
      return !this.state.targetUsers.count();
    }

    if (this.state.sharedType === SHARED_TYPES.SELECTED_PROJECT) {
      return !this.state.targetProjects.count();
    }

    return false;
  },
});

export default ShareExistingBucket;
