import { useState } from 'react';
import type { ReactNode } from 'react';

import type { TooltipProps } from '@keboola/design';
import { cn, Tooltip } from '@keboola/design';

import { copyToClipboard } from '../../utils';

type Props = {
  children: ReactNode;
  text?: string;
  tooltipText?: TooltipProps['tooltip'];
  tooltipPlacement?: TooltipProps['placement'];
  tooltipType?: TooltipProps['type'];
  className?: string;
};

export const Clipboard = ({
  text = '',
  children,
  tooltipText = 'Copy to Clipboard',
  tooltipPlacement = 'top',
  tooltipType = 'action',
  className,
}: Props) => {
  const [isCopied, setIsCopied] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const tooltip =
    error || (text ? (isCopied ? 'Copied to Clipboard' : tooltipText) : 'Nothing to copy');

  return (
    <Tooltip
      tooltip={tooltip}
      type={isCopied ? 'action' : tooltipType}
      forceHide={!tooltip}
      forceShow={isCopied}
      placement={tooltipPlacement}
      triggerClassName={cn('tw-inline-flex', className)}
      triggerOnClick={(event) => {
        event.preventDefault();
        event.stopPropagation();

        if (!text) {
          return;
        }

        copyToClipboard(text).then(
          () => {
            setIsCopied(true);
            setTimeout(() => {
              setIsCopied(false);
              setError(null);
            }, 1100);
          },
          (error: { message?: string | null }) => {
            setIsCopied(false);
            setError(error.message || 'Failed to copy');
            setTimeout(() => {
              setIsCopied(false);
              setError(null);
            }, 2300);
          },
        );

        setTimeout(() => (document.activeElement as HTMLElement | null)?.blur(), 300);
      }}
    >
      {children}
    </Tooltip>
  );
};
