import type { SandboxDetail, WorkspaceSaml2Login } from '@keboola/api-client';

import { CONTAINER_BASED, SANDBOX_TYPE } from '@/modules/sandboxes/Constants';

export const openSsoSanboxInNewWindow = (data: WorkspaceSaml2Login) => {
  const fragment = document.createDocumentFragment();

  const form = document.createElement('form');
  form.setAttribute('target', '_blank');
  form.setAttribute('method', 'POST');
  form.setAttribute('action', data.acsUrl);

  const inputs = [
    { name: 'SAMLResponse', value: data.samlResponse },
    { name: 'RelayState', value: data.relayState },
  ].map(({ name, value }) => {
    const input = document.createElement('input');
    input.setAttribute('name', name);
    input.setAttribute('value', value);
    input.setAttribute('type', 'hidden');
    return input;
  });

  inputs.forEach((input) => form.appendChild(input));
  fragment.appendChild(form);
  document.body.appendChild(fragment);

  form.submit();

  setTimeout(() => {
    if (document.body.contains(form)) {
      document.body.removeChild(form);
    }
  }, 100); // Gives browser time to process the submission
};

export const createSandboxUrl = (sandbox?: SandboxDetail) => {
  if (!sandbox || !sandbox.url || !sandbox.password || !sandbox.type) return '';
  const url = new URL(sandbox.url);

  if (CONTAINER_BASED.includes(sandbox.type) && sandbox.type !== SANDBOX_TYPE.STREAMLIT)
    url.searchParams.set('token', sandbox.password);

  return url.toString();
};
