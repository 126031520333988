import { cn, IconButton, Tooltip } from '@keboola/design';

import type { FeedbackStatusType } from '@/modules/ai/constants';
import { FEEDBACK_STATUS } from '@/modules/ai/constants';

const FeedbackButtons = ({
  feedbackStatus,
  onGood,
  onBad,
  onReload,
  entityName,
  disabled,
}: {
  feedbackStatus: FeedbackStatusType | null;
  onGood: () => void;
  onBad: (message?: string) => void;
  onReload?: () => void;
  entityName: string;
  disabled: boolean;
}) => {
  const commonProps = {
    disabled,
    variant: 'inline',
    size: 'small',
  } as const;
  return (
    <div className="feedback-buttons">
      <Tooltip
        placement="top"
        tooltip={`Like ${entityName}`}
        forceHide={feedbackStatus === FEEDBACK_STATUS.GOOD}
      >
        <IconButton
          {...commonProps}
          className={cn('[&_svg]:hover:!tw-text-primary-500 [&_svg]:focus:!tw-text-primary-500', {
            '[&_svg]:!tw-text-primary-500 tw-cursor-not-allowed':
              feedbackStatus === FEEDBACK_STATUS.GOOD,
          })}
          onClick={(event) => {
            event.preventDefault();

            if (feedbackStatus === FEEDBACK_STATUS.GOOD) {
              return;
            }
            onGood();
          }}
          icon="thumbs-up"
        />
      </Tooltip>
      <Tooltip
        placement="top"
        tooltip={`Dislike ${entityName}`}
        forceHide={feedbackStatus === FEEDBACK_STATUS.BAD}
      >
        <IconButton
          {...commonProps}
          className={cn('[&_svg]:hover:!tw-text-error-500 [&_svg]:focus:!tw-text-error-500', {
            '[&_svg]:!tw-text-error-500 tw-cursor-not-allowed':
              feedbackStatus === FEEDBACK_STATUS.BAD,
          })}
          onClick={(event) => {
            event.preventDefault();

            if (feedbackStatus === FEEDBACK_STATUS.BAD) {
              return;
            }

            onBad();
          }}
          icon="thumbs-down"
        />
      </Tooltip>
      {onReload && (
        <>
          <div className="btn-separator tw-m-0" />
          <Tooltip placement="top" tooltip={`Regenerate ${entityName}`}>
            <IconButton {...commonProps} onClick={onReload} icon="arrows-rotate" />
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default FeedbackButtons;
