import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { Badge, Icon, Search } from '@keboola/design';

import { isInternal } from '@/constants/helpers';
import { canManageBuckets, canManageTokens } from '@/modules/admin/privileges';
import { routeNames } from '@/modules/settings/constants';
import RefreshTokenModal from '@/modules/tokens/react/modals/RefreshTokenModal';
import SendTokenModal from '@/modules/tokens/react/modals/SendTokenModal';
import {
  CreatedDate,
  RouterLink as Link,
  RowActionDropdown,
  RowActionMenuItem,
} from '@/react/common';
import Check from '@/react/common/Check';
import ConfirmMenuItem from '@/react/common/ConfirmMenuItem';
import LazyList from '@/react/common/LazyList';
import Loader from '@/react/common/Loader';
import RoutesStore from '@/stores/RoutesStore';
import { matchByWords } from '@/utils';
import string from '@/utils/string';

const TokensTable = createReactClass({
  propTypes: {
    tokens: PropTypes.object.isRequired,
    sapiToken: PropTypes.instanceOf(Map).isRequired,
    onDeleteFn: PropTypes.func.isRequired,
    isDeletingFn: PropTypes.func.isRequired,
    onSendTokenFn: PropTypes.func.isRequired,
    isSendingTokenFn: PropTypes.func.isRequired,
    onRefreshFn: PropTypes.func.isRequired,
    isRefreshingFn: PropTypes.func.isRequired,
    hasNewQueue: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return {
      searchQuery: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
      sendToken: Map(),
      refreshToken: Map(),
    };
  },

  render() {
    return (
      <div className="tw-flex tw-flex-col tw-gap-6">
        {this.renderTokenRefreshModal()}
        {this.renderTokenSendModal()}
        <Search
          placeholder="Search tokens"
          defaultValue={this.state.searchQuery}
          onChange={(searchQuery) => {
            this.setState({ searchQuery });
            RoutesStore.getRouter().updateQuery({ q: searchQuery });
          }}
        />
        <div className="box">
          <div className="table table-hover">
            <div className="thead is-sticky bg-color-white">
              <div className="tr">
                <div className="th">Description</div>
                <div className="th">Created</div>
                <div className="th">Refreshed</div>
                <div className="th">Expires</div>
                <div className="th">Files</div>
                <div className="th">Components</div>
                <div className="th">Buckets</div>
                <div className="th">Tokens</div>
              </div>
            </div>
            <LazyList
              className="tbody"
              items={this.getFilteredTokens()}
              render={this.renderTokens}
            />
          </div>
        </div>
      </div>
    );
  },

  getFilteredTokens() {
    return this.props.tokens
      .filter((token) => matchByWords(token.get('description'), this.state.searchQuery))
      .sortBy((token) => token.get('description').toLowerCase())
      .sortBy((token) => (this.isYoursToken(token) === true ? -1 : 0));
  },

  renderComponentsAccess(token) {
    if (canManageBuckets(token)) {
      return 'All components';
    }

    const accessCnt = token.get('componentAccess', List()).count();

    if (!accessCnt) {
      return 'None';
    }

    return `${accessCnt} ${string.pluralize(accessCnt, 'component')}`;
  },

  renderBucketsAccess(token) {
    if (canManageBuckets(token)) {
      return 'All buckets';
    }

    const accessCnt = token.get('bucketPermissions', List()).count();

    if (accessCnt === 0) {
      return 'None';
    }

    return `${accessCnt} ${string.pluralize(accessCnt, 'bucket')}`;
  },

  renderMasterLabel(token) {
    if (!token.get('isMasterToken', false)) {
      return null;
    }

    return <Badge text="Master" variant="green" placement="right" />;
  },

  isYoursToken(token) {
    return token.get('id') === this.props.sapiToken.get('id');
  },

  renderYoursLabel(token) {
    if (!this.isYoursToken(token)) {
      return null;
    }

    return <Badge text="Yours" variant="blue" placement="right" />;
  },

  renderTokenDelete(token) {
    const isDeleting = !!this.props.isDeletingFn(token);

    return (
      <ConfirmMenuItem
        icon="trash"
        title="Delete Token"
        text={`Are you sure you want to delete the token ${token.get('description')} (${token.get(
          'id',
        )})?`}
        buttonLabel="Delete"
        onConfirm={() => this.props.onDeleteFn(token)}
        isDisabled={isDeleting}
      >
        {isDeleting ? <Loader /> : <Icon icon="trash" fixedWidth />}
        Delete token
      </ConfirmMenuItem>
    );
  },

  renderTokenSendModal() {
    return (
      <SendTokenModal
        token={this.state.sendToken}
        show={!!this.state.sendToken.get('id')}
        onHideFn={() => this.setState({ sendToken: Map() })}
        onSendFn={(params) => this.props.onSendTokenFn(this.state.sendToken, params)}
        isSending={!!this.props.isSendingTokenFn(this.state.sendToken.get('id'))}
      />
    );
  },

  renderTokenSendButton(token) {
    if (token.get('isMasterToken', false)) {
      return null;
    }

    const isSending = !!this.props.isSendingTokenFn(token.get('id'));

    return (
      <RowActionMenuItem onSelect={() => this.setState({ sendToken: token })} disabled={isSending}>
        {isSending ? <Loader /> : <Icon icon="share" fixedWidth />}
        Send via email
      </RowActionMenuItem>
    );
  },

  renderTokenRefreshButton(token) {
    const isRefreshing = !!this.props.isRefreshingFn(token);

    return (
      <RowActionMenuItem
        onSelect={() => this.setState({ refreshToken: token })}
        disabled={isRefreshing}
      >
        {isRefreshing ? <Loader /> : <Icon icon="arrows-rotate" fixedWidth />}
        Refresh token
      </RowActionMenuItem>
    );
  },

  renderTokenRefreshModal() {
    return (
      <RefreshTokenModal
        token={this.state.refreshToken}
        show={!!this.state.refreshToken.get('id')}
        onHideFn={() => this.setState({ refreshToken: Map() })}
        onRefreshFn={() => this.props.onRefreshFn(this.state.refreshToken)}
        isRefreshing={!!this.props.isRefreshingFn(this.state.refreshToken)}
        hasNewQueue={this.props.hasNewQueue}
        sapiToken={this.props.sapiToken}
      />
    );
  },

  renderTokens(tokens) {
    return tokens.map(this.renderTableRow).toArray();
  },

  renderTableRow(token) {
    return (
      <Link
        to={routeNames.TOKEN_DETAIL}
        params={{ tokenId: token.get('id') }}
        className="tr hoverable-actions-with-replacement"
        key={token.get('id')}
      >
        <div className="td">
          {token.get('description')} {this.renderYoursLabel(token)}
          {this.renderMasterLabel(token)}
        </div>
        <div className="td">
          <CreatedDate createdTime={token.get('created')} />
        </div>
        <div className="td">
          <CreatedDate createdTime={token.get('refreshed')} />
        </div>
        <div className="td">
          <CreatedDate createdTime={token.get('expires')} fallback="Never" />
        </div>
        <div className="td">{token.get('canReadAllFileUploads') ? 'All files' : 'Own files'}</div>
        <div className="td">{this.renderComponentsAccess(token)}</div>
        <div className="td">{this.renderBucketsAccess(token)}</div>
        <div className="td">
          <div className="actions-container">
            <div className="not-actions">
              <Check isChecked={canManageTokens(token)} />
            </div>
            <div className="actions">
              <RowActionDropdown showLoading={this.showLoading(token)}>
                {this.renderTokenSendButton(token)}
                {!isInternal(token.get('description')) && (
                  <>
                    {this.renderTokenRefreshButton(token)}
                    {!token.has('admin') && (
                      <>
                        <RowActionMenuItem divider />
                        {this.renderTokenDelete(token)}
                      </>
                    )}
                  </>
                )}
              </RowActionDropdown>
            </div>
          </div>
        </div>
      </Link>
    );
  },

  showLoading(token) {
    const isSending = !!this.props.isSendingTokenFn(token.get('id'));
    const isRefreshing = !!this.props.isRefreshingFn(token);
    const isDeleting = !!this.props.isDeletingFn(token);
    return isSending || isRefreshing || isDeleting;
  },
});

export default TokensTable;
