import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { Alert } from '@keboola/design';

import { loadSourceTables } from '@/modules/ex-db-generic/actionsProvisioning';
import Loader from '@/react/common/Loader';

const AsynchActionError = createReactClass({
  propTypes: {
    configId: PropTypes.string.isRequired,
    componentId: PropTypes.string.isRequired,
    sourceTablesLoading: PropTypes.bool.isRequired,
    sourceTablesError: PropTypes.string,
  },

  render() {
    if (!this.props.sourceTablesError) {
      return null;
    }

    return (
      <Alert
        variant="error"
        title="An error occurred while fetching the list of tables"
        className="tw-mb-5"
      >
        <p>{this.props.sourceTablesError}</p>
        <p>
          {this.props.sourceTablesLoading ? (
            <span>
              <Loader /> Retrying fetch of the list of tables from the source database...
            </span>
          ) : (
            <button
              className="btn btn-danger"
              onClick={() => loadSourceTables(this.props.componentId, this.props.configId)}
            >
              Try again
            </button>
          )}
        </p>
      </Alert>
    );
  },
});

export default AsynchActionError;
