import type { List } from 'immutable';

import { ButtonInline } from '@keboola/design';

type Props = {
  primaryKey: List<string> | undefined;
  onChange: (primaryKey: List<string>) => void;
};

const CopyPrimaryKeyButton = ({ primaryKey, onChange }: Props) => {
  if (!primaryKey) {
    return null;
  }

  return (
    <>
      <ButtonInline variant="link" onClick={() => onChange(primaryKey!)}>
        Fill in the primary key
      </ButtonInline>{' '}
      from the Storage table.
    </>
  );
};

export default CopyPrimaryKeyButton;
