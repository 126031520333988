import { createPrefixedString } from '../../utils';

export const genSearchTestId = (prefix: string = '') => {
  const ps = createPrefixedString(prefix);
  return {
    toString: () => prefix,
    wrapper: ps`search-wrapper`,
    input: ps`search-input`,
    clearButton: ps`search-clear-button`,
  };
};
