import { Component } from 'react';

import Checkbox from '@/react/common/Checkbox';

type Props = {
  tooltip: string;
  checked: boolean;
  onChange: (checked: boolean) => boolean;
  indeterminate?: boolean;
  disabled?: boolean;
};

class CheckboxCell extends Component<Props> {
  render() {
    return (
      <Checkbox
        tooltip={this.props.tooltip}
        checked={this.props.checked}
        indeterminate={this.props.indeterminate}
        onChange={this.props.onChange}
        disabled={this.props.disabled}
      />
    );
  }
}

export default CheckboxCell;
