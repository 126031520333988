import { URLS } from '@keboola/constants';
import { ButtonLink, Icon, Link } from '@keboola/design';

const DocumentationButton = () => {
  return (
    <ButtonLink variant="outline">
      <Link href={`${URLS.USER_DOCUMENTATION}/templates/`}>
        <Icon icon="book-blank" />
        Documentation
      </Link>
    </ButtonLink>
  );
};

export default DocumentationButton;
