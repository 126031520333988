import { useState } from 'react';
import type { FormEvent } from 'react';
import { Modal } from 'react-bootstrap';
import { fromJS, List, Map } from 'immutable';

import { Alert, FormGroup, Label, TextInput } from '@keboola/design';

import { KEBOOLA_ORCHESTRATOR, ORCHESTRATOR } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import keyCodes from '@/constants/keyCodes';
import InstalledComponentsApi from '@/modules/components/InstalledComponentsApi';
import { CreatedDate } from '@/react/common';
import Checkbox from './Checkbox';
import ConfirmButtons from './ConfirmButtons';
import ModalIcon from './ModalIcon';

type Props = {
  entity: string;
  configId: string;
  componentId: string;
  componentType: string;
  version: Map<string, any>;
  show: boolean;
  onClose: () => void;
  onCopy: (excludeRows: boolean) => void;
  newVersionName?: string;
  onChangeName: (name: string) => void;
  isSaving: boolean;
  isRow?: boolean;
  isLast?: boolean;
};

const CopyVersionModal = ({
  entity,
  configId,
  componentId,
  componentType,
  version,
  show,
  onClose,
  onCopy,
  newVersionName,
  onChangeName,
  isSaving,
  isRow,
  isLast,
}: Props) => {
  const [configuration, setConfiguration] = useState(Map());
  const [excludeRows, setExcludeRows] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onCopy(excludeRows);
  };

  const rows = configuration.get('rows', List()) as List<any>;

  return (
    <span
      className="tw-hidden"
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.key === keyCodes.ENTER && e.stopPropagation()}
    >
      <Modal
        show={show}
        onHide={onClose}
        onEnter={() => {
          if (isRow) {
            return;
          }

          InstalledComponentsApi.getComponentConfigByVersion(
            componentId,
            configId,
            version.get('version'),
          ).then((response) => setConfiguration(fromJS(response)));
        }}
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Version Copy</Modal.Title>
            <ModalIcon color="green" icon="clone" bold />
          </Modal.Header>
          <Modal.Body className="!tw-mb-5 tw-flex tw-flex-col tw-gap-3">
            {componentId === ORCHESTRATOR && !isLast && (
              <Alert variant="warning">
                <p>
                  Event Trigger scheduling cannot be automatically copied from the older {entity}{' '}
                  version.
                </p>
                <p>
                  If {entity} had Event Trigger scheduling set, it has to be configured again in the
                  copied {entity}.
                </p>
              </Alert>
            )}
            {componentType === componentTypes.TRANSFORMATION && !isLast && (
              <Alert variant="warning">
                If the {entity} has variables set, the latest values for those variables will be
                used. This may differ from the values used in this version of the {entity}. Please
                verify them.
              </Alert>
            )}
            {componentId === KEBOOLA_ORCHESTRATOR && (
              <Alert variant="warning">
                If the {entity} has a schedule set, it has to be configured again in the copied{' '}
                {entity}.
              </Alert>
            )}
            <p className="tw-mb-0">
              This will copy version <strong>#{version.get('version')}</strong>, created{' '}
              <CreatedDate createdTime={version.get('created')} /> by{' '}
              <strong>{version.getIn(['creatorToken', 'description'], 'unknown')}</strong> to a new{' '}
              {entity}.
            </p>
            <FormGroup>
              <Label htmlFor="new-name">New {entity} name</Label>
              <TextInput
                id="new-name"
                variant="secondary"
                autoFocus
                value={newVersionName}
                onChange={(value) => onChangeName(value)}
              />
            </FormGroup>
            {!rows.isEmpty() && (
              <FormGroup>
                <Checkbox checked={excludeRows} onChange={(checked) => setExcludeRows(checked)}>
                  Copy only an empty configuration and keep the credentials
                </Checkbox>
              </FormGroup>
            )}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveLabel={isSaving ? `Creating ${entity}...` : `Copy ${entity}`}
              isSaving={isSaving}
              isDisabled={!newVersionName?.trim().length || (!isRow && configuration.isEmpty())}
            />
          </Modal.Footer>
        </form>
      </Modal>
    </span>
  );
};

export default CopyVersionModal;
