import PropTypes from 'prop-types';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import JobDuration from '@/react/common/JobDuration';
import JobStatusLabel from '@/react/common/JobStatusLabel';
import Loader from '@/react/common/Loader';
import TimeAndUser from '@/react/common/TimeAndUser';

const JobsTable = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    jobs: PropTypes.instanceOf(List).isRequired,
    admins: PropTypes.instanceOf(Map).isRequired,
    showJobDetailModal: PropTypes.func.isRequired,
    renderTableLink: PropTypes.func.isRequired,
    renderDataTransfer: PropTypes.func.isRequired,
  },

  render() {
    return (
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>ID</th>
              <th>Operation</th>
              <th>Table ID</th>
              <th className="text-right">Duration</th>
              <th className="text-right">Data Transfer</th>
              <th className="text-right">Created</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>{this.renderRows()}</tbody>
        </table>
      </div>
    );
  },

  renderRows() {
    if (this.props.jobs.isEmpty()) {
      return (
        <tr>
          <td colSpan={7}>
            <Loader /> loading jobs..
          </td>
        </tr>
      );
    }

    return this.props.jobs.map(this.renderRow).toArray();
  },

  renderRow(job) {
    const admin = this.props.admins.get(job.getIn(['creatorToken', 'description']));

    return (
      <tr
        key={job.get('id')}
        onClick={() => this.props.showJobDetailModal(job.get('id'))}
        className="clickable"
      >
        <td>{job.get('id')}</td>
        <td>{job.get('operationName')}</td>
        <td className="tw-break-all">{this.props.renderTableLink(job)}</td>
        <td className="text-right">
          <JobDuration
            status={job.get('status')}
            startTime={job.get('startTime')}
            endTime={job.get('endTime')}
          />
        </td>
        <td className="text-right">{this.props.renderDataTransfer(job)}</td>
        <td className="text-right">
          <TimeAndUser
            admin={admin}
            time={job.get('createdTime')}
            fallbackName={job.getIn(['creatorToken', 'description'])}
          />
        </td>
        <td>
          <JobStatusLabel status={job.get('status')} />
        </td>
      </tr>
    );
  },
});

export default JobsTable;
