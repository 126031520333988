import { useState } from 'react';
import type { Map } from 'immutable';

import { Icon } from '@keboola/design';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import Confirm from '@/react/common/Confirm';
import { READ_ONLY_TOOLTIP_MESSAGE } from '@/react/common/constants';
import { humanitazeExpiration } from '@/react/common/TimeoutControl/helpers';
import TimeoutControl from '@/react/common/TimeoutControl/TimeoutControl';

const DEFAULT_TIMEOUT = 7200;

type Props = {
  config: Map<string, any>;
  componentId: string;
  readOnly: boolean;
  defaultTimeout?: number;
};

const QueryTimeoutModal = (props: Props) => {
  const currentTimeout =
    props.config.getIn(['configuration', 'parameters', 'query_timeout']) ||
    props.defaultTimeout ||
    DEFAULT_TIMEOUT;

  const [timeout, setTimeout] = useState(currentTimeout);
  const [showCustomExpiration, setCustomExpiration] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    setIsLoading(true);

    return InstalledComponentsActionCreators.updateComponentConfiguration(
      props.componentId,
      props.config.get('id'),
      {
        configuration: JSON.stringify(
          props.config.get('configuration').setIn(['parameters', 'query_timeout'], timeout).toJS(),
        ),
      },
      'Change query timeout',
    ).finally(() => setIsLoading(false));
  };

  const handleSetTimeout = (value: number, customExpiration: boolean) => {
    setTimeout(value);
    setCustomExpiration(customExpiration);
  };

  return (
    <>
      <Confirm
        closeAfterResolve
        icon={['fas', 'gear-complex-code']}
        title="Set query timeout"
        onConfirm={handleSubmit}
        onHide={() => setTimeout(currentTimeout)}
        onEnter={() => setTimeout(currentTimeout)}
        isLoading={isLoading}
        text={
          <>
            <p>Set the timeout for a single query.</p>
            <TimeoutControl
              label="Query timeout"
              isDisabled={props.readOnly}
              value={timeout}
              onChange={handleSetTimeout}
              showCustom={showCustomExpiration}
            />
          </>
        }
        buttonType="primary"
        buttonLabel="Set query timeout"
        childrenRootElement="button"
        childrenRootElementClass="btn btn-link btn-block"
        isDisabledConfirmButton={!timeout}
        isDisabled={props.readOnly}
        {...(props.readOnly && { disabledReason: READ_ONLY_TOOLTIP_MESSAGE })}
      >
        <Icon icon={['fas', 'gear-complex-code']} fixedWidth />
        Query timeout:{' '}
        <span className="tw-font-medium">{humanitazeExpiration(currentTimeout)}</span>
      </Confirm>
    </>
  );
};

export default QueryTimeoutModal;
