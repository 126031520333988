import type { ComponentProps } from 'react';

import type { Icon } from '@keboola/design';

const commonFaIcons: { [key: string]: ComponentProps<typeof Icon>['icon'] } = {
  upload: 'up-from-line',
  download: 'down-to-line',
  settings: 'gear',
  import: 'file-import',
};

export default commonFaIcons;
