import createReactClass from 'create-react-class';

import { Alert, Tabs, TabsContent } from '@keboola/design';

import { isInternal } from '@/constants/helpers';
import { canManageTokens } from '@/modules/admin/privileges';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import Events from '@/modules/sapi-events/react/Events';
import TokensActions from '@/modules/tokens/actionCreators';
import TokenEditor from '@/modules/tokens/react/components/tokenEditor/TokenEditor';
import TokensStore from '@/modules/tokens/StorageTokensStore';
import createTokenEventsApi from '@/modules/tokens/TokenEventsApi';
import SaveButtons from '@/react/common/SaveButtons';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const Detail = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, TokensStore, BucketsStore, ComponentsStore)],

  getStateFromStores() {
    const localState = TokensStore.localState();
    const tokenId = RoutesStore.getCurrentRouteParam('tokenId');
    const token = TokensStore.getAll().find((t) => t.get('id') === tokenId);

    return {
      localState,
      tokenId,
      token,
      admins: ApplicationStore.getAdmins(),
      editedToken: localState.getIn(['editedToken', tokenId], token),
      allComponents: ComponentsStore.getAll(),
      allBuckets: BucketsStore.getAll(),
      sapiToken: ApplicationStore.getSapiToken(),
      hasNewQueue: ApplicationStore.hasNewQueue(),
    };
  },

  getInitialState() {
    return {
      isSaving: false,
      eventsApi: createTokenEventsApi(this.props.params.tokenId),
    };
  },

  render() {
    return this.state.token ? this.renderDetail() : this.renderNotFound();
  },

  renderDetail() {
    return (
      <Tabs
        triggers={[
          { title: 'Overview', value: 'overview' },
          { title: 'Events', value: 'events' },
        ]}
      >
        <TabsContent value="overview">
          <div className="box p-1 form form-horizontal">
            {isInternal(this.state.token.get('description')) && (
              <Alert title="Internal token" className="tw-mb-5">
                Note that internal tokens cannot be modified by users in the UI, as doing so may
                cause issues with the product&apos;s functionality. However, it may be updated via
                API if necessary.
              </Alert>
            )}
            <div className="save-buttons">
              <SaveButtons
                isSaving={this.state.isSaving}
                disabled={!this.isValid() || !canManageTokens(this.state.sapiToken)}
                isChanged={!this.state.token.equals(this.state.editedToken)}
                onSave={this.handleSave}
                onReset={this.resetEditedToken}
              />
            </div>
            <TokenEditor
              isEditing
              disabled={
                !!this.state.isSaving ||
                !canManageTokens(this.state.sapiToken) ||
                isInternal(this.state.token.get('description'))
              }
              token={this.state.editedToken}
              sapiToken={this.state.sapiToken}
              allBuckets={this.state.allBuckets}
              allComponents={this.state.allComponents}
              updateToken={this.updateToken}
              hasNewQueue={this.state.hasNewQueue}
            />
          </div>
        </TabsContent>
        <TabsContent value="events">
          <Events autoReload eventsApi={this.state.eventsApi} admins={this.state.admins} />
        </TabsContent>
      </Tabs>
    );
  },

  renderNotFound() {
    return <p>Token {this.state.tokenId} does not exist or has been removed.</p>;
  },

  isValid() {
    return !!(
      this.state.editedToken.get('description') && this.state.editedToken.get('expiresIn') !== 0
    );
  },

  resetEditedToken() {
    const updatedLocalState = this.state.localState.deleteIn(['editedToken', this.state.tokenId]);
    TokensActions.updateLocalState(updatedLocalState);
  },

  updateToken(newToken) {
    const updatedLocalState = this.state.localState.setIn(
      ['editedToken', this.state.tokenId],
      newToken,
    );
    TokensActions.updateLocalState(updatedLocalState);
  },

  handleSave() {
    this.saving(true);
    return TokensActions.updateToken(this.state.tokenId, this.state.editedToken.toJS()).finally(
      () => {
        this.saving(false);
        this.resetEditedToken();
      },
    );
  },

  saving(value) {
    this.setState({
      isSaving: value,
    });
  },
});

export default Detail;
