import { Fragment } from 'react';
import type { ReactNode } from 'react';
import Linkify from 'react-linkify';
import LinkifyIt from 'linkify-it';

import { Link } from '@keboola/design';

import MarkedText from './MarkedText';

const linkifyIt = new LinkifyIt(
  {},
  {
    fuzzyLink: false,
    fuzzyEmail: false,
    fuzzyIP: false,
  },
);

const checkText = (text: string) =>
  linkifyIt.add('ftp:', null).add('//', null).add('mailto:', null).match(text);

const splitNewLines = (text: string, searchQuery: string) => {
  const lines = text.split('\n');
  const result: ReactNode[] = [];

  lines.forEach((value, index) => {
    result.push(
      <Fragment key={index}>
        <MarkedText source={value} mark={searchQuery} />
      </Fragment>,
    );
    if (index < lines.length - 1) {
      result.push(<br key={`${index}-br`} />);
    }
  });

  return result;
};

const renderExternalLink = (href: string, text: string, key: number) => {
  return (
    <Link href={href} key={key}>
      {text}
    </Link>
  );
};

const UrlsToLinks = ({ text, searchQuery = '' }: { text: string; searchQuery?: string }) => {
  if (!text || !text.length) {
    return null;
  }

  return (
    <Linkify componentDecorator={renderExternalLink} matchDecorator={checkText}>
      {splitNewLines(text, searchQuery)}
    </Linkify>
  );
};

export default UrlsToLinks;
