import { useState } from 'react';

import { Button, ButtonGroup, Icon } from '@keboola/design';

import { NEW_ENTITY_BUTTON } from '@/constants/external';
import { FEATURE_SNOWFLAKE_REGISTER_EXTERNAL_SECURE_DATA_SHARE } from '@/constants/features';
import { canManageBuckets, canRegisterExternalBucket } from '@/modules/admin/privileges';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import { createBucket } from '@/modules/storage/actions';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import string from '@/utils/string';
import CreateBucketModal from './CreateBucketModal';
import RegisterExternalBucketModal from './RegisterExternalBucketModal';

const HeaderButtons = () => {
  const store = useStores(
    () => {
      return {
        sapiToken: ApplicationStore.getSapiToken(),
        allBuckets: BucketsStore.getAll(),
        isCreatingBucket: BucketsStore.isCreatingBucket(),
        hasSnowflakeRegisterExternalSecureDataShare: ApplicationStore.hasCurrentProjectFeature(
          FEATURE_SNOWFLAKE_REGISTER_EXTERNAL_SECURE_DATA_SHARE,
        ),
      };
    },
    [],
    [ApplicationStore, BucketsStore],
  );

  const [showCreateBucketModal, setShowCreateBucketModal] = useState(false);
  const [showRegisterExternalBucketModal, setShowRegisterExternalBucketModal] = useState(false);

  if (!canManageBuckets(store.sapiToken) && !canRegisterExternalBucket(store.sapiToken)) {
    return null;
  }

  const backend = store.sapiToken.getIn(['owner', 'defaultBackend']);

  return (
    <>
      <ButtonGroup>
        {canRegisterExternalBucket(store.sapiToken) && (
          <Button
            variant="secondary"
            disabled={store.isCreatingBucket}
            onClick={() => setShowRegisterExternalBucketModal(true)}
            className={NEW_ENTITY_BUTTON}
          >
            Register your {string.capitalize(backend)} Dataset
          </Button>
        )}
        {canManageBuckets(store.sapiToken) && (
          <Button
            className={NEW_ENTITY_BUTTON}
            disabled={store.isCreatingBucket}
            onClick={() => setShowCreateBucketModal(true)}
          >
            <Icon icon="plus" />
            Create bucket
          </Button>
        )}
      </ButtonGroup>
      <RegisterExternalBucketModal
        backend={backend}
        openModal={showRegisterExternalBucketModal}
        onHide={() => setShowRegisterExternalBucketModal(false)}
        isSaving={store.isCreatingBucket}
        buckets={store.allBuckets}
        hasSnowflakeRegisterExternalSecureDataShare={
          store.hasSnowflakeRegisterExternalSecureDataShare
        }
      />
      <CreateBucketModal
        openModal={showCreateBucketModal}
        sapiToken={store.sapiToken}
        onSubmit={createBucket}
        onHide={() => setShowCreateBucketModal(false)}
        isSaving={store.isCreatingBucket}
        buckets={store.allBuckets}
      />
    </>
  );
};

export default HeaderButtons;
