import { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

import { Alert, FormGroup, Icon, Label, TextInput } from '@keboola/design';

import { createWorksheet } from '@/modules/wr-onedrive/action';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import SyncActionError from '@/utils/errors/SyncActionError';

const INITIAL_STATE = {
  showModal: false,
  isCreating: false,
  error: null,
  warning: null,
  name: '',
};

class CreateWorksheet extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <>
        <Button
          bsStyle="success"
          disabled={this.props.disabled || !this.props.driveId || !this.props.fileId}
          onClick={this.openModal}
        >
          <Icon icon="plus" className="icon-addon-right" />
          Create Worksheet
        </Button>
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Create Worksheet</Modal.Title>
              <ModalIcon.Plus />
            </Modal.Header>
            <Modal.Body>
              {this.state.error && (
                <Alert variant="error" className="tw-mb-5">
                  {this.state.error}
                </Alert>
              )}
              {this.state.warning && (
                <Alert variant="warning" className="tw-mb-5">
                  {this.state.warning}
                </Alert>
              )}
              <FormGroup className="tw-mb-4 tw-grid tw-grid-cols-4 tw-items-center tw-gap-4">
                <Label htmlFor="name">Name</Label>
                <TextInput
                  id="name"
                  className="tw-col-span-3"
                  variant="secondary"
                  autoFocus
                  disabled={this.state.isCreating}
                  value={this.state.name}
                  onChange={(name) => this.setState({ name })}
                />
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <ConfirmButtons
                block
                saveLabel="Create"
                saveButtonType="submit"
                isSaving={this.state.isCreating}
                isDisabled={!this.state.name.trim()}
              />
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ isCreating: true, warning: null, error: null });
    return createWorksheet(
      this.props.configId,
      this.props.driveId,
      this.props.fileId,
      this.state.name,
    )
      .then(({ worksheet }) => {
        this.props.onCreate({ name: this.state.name, id: worksheet.worksheetId });
        this.closeModal();
        return null;
      })
      .catch(SyncActionError, (error) => {
        this.setState({ error: error.message });
      })
      .finally(() => {
        this.setState({ isCreating: false });
      });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState(INITIAL_STATE);
  }
}

CreateWorksheet.propTypes = {
  disabled: PropTypes.bool.isRequired,
  configId: PropTypes.string.isRequired,
  driveId: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default CreateWorksheet;
