import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Map } from 'immutable';

import { Alert, cn, Icon, Tooltip } from '@keboola/design';

import type { PARAM_VALUE } from '@/modules/ex-generic/constants';
import { Truncated } from '@/react/common';
import Loader from '@/react/common/Loader';
import string from '@/utils/string';
import DocumentationLink from './DocumentationLink';
import NewPagination from './NewPagination';

const Paginations = (props: {
  readOnly: boolean;
  parameters: Map<string, any>;
  onSave: (parameters: Map<string, any>, changeDescription: string) => Promise<any>;
}) => {
  const [show, setShow] = useState(false);
  const [deleting, deletePagination] = useState<string | null>(null);
  const [editingPagination, editEditingPagination] = useState<string | null>(null);

  const pagination = props.parameters.getIn(['api', 'pagination'], Map());
  const scrollers = pagination.get('scrollers', Map());
  const hasMoreSpace = window.innerWidth > 1292;

  const handleDelete = (name: string, isCustomPagination: boolean) => {
    const parameters = isCustomPagination
      ? props.parameters.deleteIn(['api', 'pagination'])
      : props.parameters.deleteIn(['api', 'pagination', 'scrollers', name]).update('api', (api) => {
          if (api.getIn(['pagination', 'scrollers'], Map()).isEmpty()) {
            return api.delete('pagination');
          }

          return api;
        });

    deletePagination(name);
    return props.onSave(parameters, 'Delete pagination').finally(() => deletePagination(null));
  };

  const renderPagination = (pagination: Map<string, any>, paginationName?: string) => {
    const isCustomPagination = !paginationName;
    const name = paginationName || 'Custom';

    return (
      <div
        key={name}
        className="tw-group tw-flex tw-justify-between tw-rounded tw-border tw-border-solid tw-border-neutral-150 tw-p-4"
      >
        <div className="tw-inline-flex tw-max-w-[90%] tw-justify-start tw-gap-4">
          <div className={hasMoreSpace ? 'tw-w-32' : 'tw-w-28'}>
            <div className="tw-mb-1 tw-text-sm tw-text-neutral-400">Name</div>
            <Truncated className="tw-text-sm tw-font-medium" text={name} />
          </div>
          {pagination
            .map((value: PARAM_VALUE, key?: string) => {
              if (!key) {
                return;
              }

              return (
                <div
                  key={key}
                  className={cn('tw-flex tw-flex-col', hasMoreSpace ? 'tw-w-32' : 'tw-w-24')}
                >
                  <Truncated
                    text={string.humanize(key)}
                    className="tw-mb-1 tw-text-sm tw-text-neutral-400"
                  />
                  {Map.isMap(value) ? (
                    <span className="tw-text-sm tw-font-medium">Configured</span>
                  ) : (
                    <Truncated className="tw-text-sm tw-font-medium" text={String(value)} />
                  )}
                </div>
              );
            })
            .toArray()}
        </div>
        {!props.readOnly && (
          <div className="tw-inline-flex tw-justify-end tw-gap-4 tw-opacity-0 tw-transition-opacity group-hover:tw-opacity-100">
            {!isCustomPagination && (
              <Tooltip tooltip="Edit pagination" placement="top">
                <Button
                  bsStyle="link"
                  disabled={!!deleting}
                  className="btn-link-inline text-muted"
                  onClick={() => {
                    editEditingPagination(name);
                    setShow(true);
                  }}
                >
                  <Icon icon="pen" fixedWidth />
                </Button>
              </Tooltip>
            )}
            <Tooltip tooltip="Delete pagination" placement="top">
              <Button
                bsStyle="link"
                disabled={!!deleting}
                className="btn-link-inline text-muted"
                onClick={() => handleDelete(name, isCustomPagination)}
              >
                {deleting === name ? <Loader /> : <Icon icon="trash" fixedWidth />}
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
    );
  };

  const renderBody = () => {
    if (scrollers.isEmpty() && pagination.isEmpty()) {
      return (
        <div className="box-content tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 !tw-py-10">
          <p className="tw-mb-0 tw-max-w-xl tw-text-center">
            Pagination settings may be linked to each endpoint. All pagination methods used
            throughout the configuration are displayed here.{' '}
            <DocumentationLink path="configuration/api/pagination/" format="text" />
          </p>
          {!props.readOnly && (
            <>
              <Alert>You can set them here or at any time in the respective contexts.</Alert>
              <Button onClick={() => setShow(true)}>
                <Icon icon="plus" className="btn-icon" />
                Create New Paginations
              </Button>
            </>
          )}
        </div>
      );
    }

    if (scrollers.isEmpty()) {
      return (
        <div className="tw-flex tw-flex-col tw-gap-3 tw-px-6 tw-py-5">
          {renderPagination(pagination)}
          <Alert title="Custom pagination detected">
            Custom pagination is used for all defined endpoints. If you want to use a specific
            pagination for any endpoint, use the{' '}
            <DocumentationLink path="configuration/api/pagination/multiple/">
              multiple-scrollers
            </DocumentationLink>{' '}
            strategy. To do so, delete your custom pagination and start over.
          </Alert>
        </div>
      );
    }

    return (
      <div className="tw-flex tw-flex-col tw-gap-3 tw-px-6 tw-py-5">
        {scrollers
          .sortBy((pagination: Map<string, any>, name: string) => name.toLowerCase())
          .map(renderPagination)
          .toArray()}
      </div>
    );
  };

  return (
    <>
      <div className="tw-flex tw-items-start tw-justify-between">
        <h2 className="tw-mb-5 tw-mt-1 tw-text-2xl tw-font-normal">Paginations</h2>
        {!props.readOnly && !scrollers.isEmpty() && (
          <Button onClick={() => setShow(true)}>
            <Icon icon="plus" className="btn-icon" />
            Create Pagination
          </Button>
        )}
      </div>
      <div className="box">{renderBody()}</div>
      <NewPagination
        show={show}
        existingPagination={editingPagination}
        parameters={props.parameters}
        onSave={props.onSave}
        onHide={() => {
          setShow(false);
          editEditingPagination(null);
        }}
      />
    </>
  );
};

export default Paginations;
