import type { PropsWithChildren } from 'react';

import errorImage from '../../../images/illustrations/error.png';

import { fileUrl } from '@/utils/fileHelpers';

const Text = ({ children }: PropsWithChildren) => (
  <p className="tw-m-0 tw-text-base tw-text-neutral-400">{children}</p>
);

type Props = {
  headerText?: string;
  text?: string;
  exceptionId?: string;
};

const ErrorContent = ({ headerText, text, exceptionId }: Props) => (
  <div className="error-page tw-mt-20 tw-text-center">
    <img src={fileUrl(errorImage)} alt={headerText} />
    <h1 className="tw-mb-7 tw-mt-14 tw-text-3xl tw-text-neutral-900">
      {headerText || 'Something went wrong'}
    </h1>
    {text && <Text>{text}</Text>}
    {exceptionId && <Text>Exception id: {exceptionId}</Text>}
  </div>
);

export default ErrorContent;
