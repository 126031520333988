import { useMemo } from 'react';
import type { List, Map } from 'immutable';

import ApplicationStore from '@/stores/ApplicationStore';

export const useAllowedProjectIds = () => {
  const organizations = ApplicationStore.getOrganizations() as List<any>;

  return useMemo(() => {
    const allowedProjectIds = new Set<number>();
    organizations.forEach((org) => {
      org.get('projects').forEach((project: Map<string, any>) => {
        allowedProjectIds.add(project.get('id'));
      });
    });
    return allowedProjectIds;
  }, [organizations]);
};
