import Promise from 'bluebird';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { cn } from '@keboola/design';

import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { hasDynamicBackendSizeEnabled } from '@/modules/components/helpers';
import InstalledComponentsActionsCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import SoundNotifications from '@/utils/SoundNotifications';
import ChildJobs from './components/ChildJobs';
import { ConfigRowsInfo } from './components/ConfigRowsInfo';
import JobLogPanel from './components/JobLogPanel';
import JobsGraphWithPaging from './components/JobsGraphWithPaging';
import PanelsRows from './components/PanelsRows';
import RunInfoPanel from './components/RunInfoPanel';
import { JOBS_STATUS, JOBS_TYPES } from './constants';
import { getChildJobs, getUserRunnedParentJob } from './helpers';
import JobsStore from './store';

const Detail = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      RoutesStore,
      InstalledComponentsStore,
      ComponentsStore,
      StackFeaturesStore,
      JobsStore,
    ),
  ],

  getStateFromStores() {
    const jobId = RoutesStore.getCurrentRouteParam('jobId');
    const job = JobsStore.get(jobId);
    const allJobs = JobsStore.getAll();
    const allComponents = ComponentsStore.getAll();

    return {
      job,
      allJobs,
      allComponents,
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      childJobs: getChildJobs(allJobs, job),
      admins: ApplicationStore.getAdmins(),
      sapiToken: ApplicationStore.getSapiToken(),
      allConfigurations: InstalledComponentsStore.getAll(),
      showBackendSize: hasDynamicBackendSizeEnabled(
        allComponents.get(job.get('component'), Map()),
        ApplicationStore.hasSnowflakeDynamicBackendSize(),
        ApplicationStore.hasJobsDynamicBackendSize(),
      ),
      hasAllowedAi: StackFeaturesStore.hasAllowedAi(),
      hasSnowflakeDynamicBackendSize: ApplicationStore.hasSnowflakeDynamicBackendSize(),
      hasJobsDynamicBackendSize: ApplicationStore.hasJobsDynamicBackendSize(),
      hasFlows: ApplicationStore.hasFlows(),
    };
  },

  getInitialState() {
    return {
      initialGraphData: null,
    };
  },

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.job || this.state.job.get('status') === prevState.job.get('status')) {
      return;
    }

    switch (this.state.job.get('status')) {
      case JOBS_STATUS.SUCCESS:
      case JOBS_STATUS.WARNING:
        if (this.state.job.get('component') === KEBOOLA_SANDBOXES) {
          Promise.delay(1000).then(() => {
            InstalledComponentsActionsCreators.loadComponentConfigsData(KEBOOLA_SANDBOXES);
          });
        }

        return SoundNotifications.success();
      case JOBS_STATUS.ERROR:
      case JOBS_STATUS.CANCELLED:
      case JOBS_STATUS.TERMINATED:
        return SoundNotifications.crash();
      default:
        break;
    }
  },

  UNSAFE_componentWillReceiveProps() {
    this.setState(this.getStateFromStores());
  },

  render() {
    if (!this.state.job) {
      return null;
    }

    return (
      <div className="job-detail-page">
        <RunInfoPanel
          job={this.state.job}
          admin={this.state.admins.get(this.state.job.getIn(['token', 'description']))}
          jobConfigurationLink={this.renderJobConfigurationLink()}
          showBackendSize={this.state.showBackendSize}
        />
        <PanelsRows
          job={this.state.job}
          sapiToken={this.state.sapiToken}
          configData={this.getConfiguration(this.state.job).get('configuration', Map())}
          allComponents={this.state.allComponents}
          graphData={this.state.initialGraphData}
          hasPayAsYouGo={this.state.hasPayAsYouGo}
          hasAllowedAi={this.state.hasAllowedAi}
          hasSnowflakeDynamicBackendSize={this.state.hasSnowflakeDynamicBackendSize}
          hasJobsDynamicBackendSize={this.state.hasJobsDynamicBackendSize}
        />
        <ChildJobs job={this.state.job} childJobs={this.state.childJobs} />
        {this.renderGraph()}
        {JOBS_TYPES.STANDARD === this.state.job.get('type') && (
          <JobLogPanel job={this.state.job} admins={this.state.admins} />
        )}
      </div>
    );
  },

  renderJobConfigurationLink() {
    const rows = this.state.job.get('configRowIds', List()).toArray();

    if (rows.length > 1) {
      return (
        <ConfigRowsInfo>
          {rows.map((rowId) => {
            return (
              <div key={rowId}>
                {this.renderConfigurationLink()}
                {this.renderConfigurationRowLink(rowId)}
                {this.renderConfigVersion()}
              </div>
            );
          })}
        </ConfigRowsInfo>
      );
    }

    return (
      <>
        {this.renderConfigurationLink()}
        {this.renderConfigVersion()}
      </>
    );
  },

  renderGraph() {
    return (
      <div className="box box-panel">
        <div className="box-header">
          <h2 className="box-title">All Runs</h2>
        </div>
        <div className="box-panel-content">
          <JobsGraphWithPaging
            job={this.state.job}
            showBackendSize={this.state.showBackendSize}
            onInitialGraphDataLoad={(initialGraphData) => this.setState({ initialGraphData })}
          />
        </div>
      </div>
    );
  },

  renderConfigurationLink() {
    let job = getUserRunnedParentJob(this.state.job, this.state.allJobs);
    let configuration = this.getConfiguration(job);

    if (
      job.get('config') &&
      (job.get('component') !== KEBOOLA_SANDBOXES ||
        configuration.hasIn(['configuration', 'parameters', 'id']))
    ) {
      return (
        <ComponentConfigurationLink
          className={cn(!configuration.isEmpty() && 'font-medium')}
          componentId={job.get('component')}
          configId={job.get('config')}
          isDeleted={configuration.isEmpty()}
          hasFlows={this.state.hasFlows}
        >
          {configuration.get('name', job.get('config'))}
        </ComponentConfigurationLink>
      );
    }

    return <em>N/A</em>;
  },

  renderConfigurationRowLink(rowId) {
    const componentId = this.state.job.get('component');
    const component = this.state.allComponents.get(componentId, Map());
    const configId = this.state.job.get('config');
    const rowName = ConfigurationRowsStore.get(componentId, configId, rowId).get('name');

    if (rowId && rowName) {
      return (
        <>
          {' / '}
          <ComponentConfigurationLink
            className="font-medium"
            componentType={component.get('type')}
            componentId={componentId}
            configId={configId}
            rowId={rowId}
          >
            {rowName}
          </ComponentConfigurationLink>
        </>
      );
    }

    if (!!rowId) {
      return <>{` / ${rowId}`}</>;
    }

    return null;
  },

  renderConfigVersion() {
    if (!!this.state.job.getIn(['result', 'configVersion'])) {
      return ` / Version ${this.state.job.getIn(['result', 'configVersion'])}`;
    }

    return null;
  },

  getConfiguration(job) {
    return this.state.allConfigurations.getIn(
      [job.get('component'), 'configurations', job.get('config')],
      Map(),
    );
  },
});

export default Detail;
