import PropTypes from 'prop-types';
import { Button, FormControl, FormGroup } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import _ from 'underscore';

import { HelpBlock } from '@keboola/design';

import Checkbox from '@/react/common/Checkbox';
import Select from '@/react/common/Select';

const RedshiftDataTypesAddForm = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    columnValue: PropTypes.string.isRequired,
    datatypeValue: PropTypes.string.isRequired,
    sizeValue: PropTypes.string.isRequired,
    compressionValue: PropTypes.string.isRequired,
    convertEmptyValuesToNullValue: PropTypes.bool.isRequired,

    columnsOptions: PropTypes.array.isRequired,
    datatypeOptions: PropTypes.array.isRequired,
    compressionOptions: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
    showSize: PropTypes.bool.isRequired,

    handleAddDataType: PropTypes.func.isRequired,
    columnOnChange: PropTypes.func.isRequired,
    datatypeOnChange: PropTypes.func.isRequired,
    convertEmptyValuesToNullOnChange: PropTypes.func.isRequired,
    sizeOnChange: PropTypes.func.isRequired,
    compressionOnChange: PropTypes.func.isRequired,

    availableColumns: PropTypes.array.isRequired,
  },

  _handleSizeOnChange(e) {
    return this.props.sizeOnChange(e.target.value);
  },

  _getDatatypeOptions() {
    return _.map(this.props.datatypeOptions, (datatype) => ({
      label: datatype,
      value: datatype,
    }));
  },

  _getCompressionOptions() {
    return _.map(this.props.compressionOptions, (compression) => ({
      label: compression,
      value: compression,
    }));
  },

  render() {
    return (
      <div className="well">
        <FormGroup>
          <div className="col-xs-3 pr-0">
            <Select
              name="add-column"
              value={this.props.columnValue}
              disabled={this.props.disabled}
              placeholder="Column"
              onChange={this.props.columnOnChange}
              options={this.props.availableColumns}
            />
          </div>
          <div className="col-xs-3 pr-0">
            <Select
              name="add-datatype"
              value={this.props.datatypeValue}
              disabled={this.props.disabled}
              placeholder="Datatype"
              onChange={this.props.datatypeOnChange}
              options={this._getDatatypeOptions()}
            />
          </div>
          <div className="col-xs-3">
            <FormControl
              type="text"
              name="add-size"
              value={this.props.sizeValue}
              disabled={this.props.disabled || !this.props.showSize}
              placeholder="Length, eg. 255"
              onChange={this._handleSizeOnChange}
            />
          </div>
          <div className="col-xs-3 pl-0">
            <Select
              name="add-datatype-compression"
              value={this.props.compressionValue}
              disabled={this.props.disabled}
              placeholder="Compression"
              onChange={this.props.compressionOnChange}
              options={this._getCompressionOptions()}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div className="col-xs-6">
            <Checkbox
              checked={this.props.convertEmptyValuesToNullValue}
              onChange={this.props.convertEmptyValuesToNullOnChange}
            >
              Convert empty values to <code>null</code>
            </Checkbox>
          </div>
          <div className="col-xs-6 text-right">
            <Button
              bsStyle="success"
              onClick={this.props.handleAddDataType}
              disabled={this.props.disabled || !this.props.columnValue || !this.props.datatypeValue}
            >
              Create data type
            </Button>
          </div>
        </FormGroup>
        <HelpBlock className="tw-mt-1">
          <p>
            <code>VARCHAR(255) ENCODE LZO</code> default for primary key columns
          </p>
          <p>
            <code>VARCHAR(65535) ENCODE LZO</code> default for all other columns
          </p>
        </HelpBlock>
      </div>
    );
  },
});

export default RedshiftDataTypesAddForm;
