import createReactClass from 'create-react-class';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import OrchestrationActionCreators from '@/modules/orchestrations/ActionCreators';
import RefreshIcon from '@/react/common/RefreshIcon';
import createStoreMixin from '@/react/mixins/createStoreMixin';

const ComponentsReloaderButton = createReactClass({
  mixins: [createStoreMixin(InstalledComponentsStore)],

  getStateFromStores() {
    return { isLoading: InstalledComponentsStore.getIsLoading() };
  },

  _handleRefreshClick() {
    InstalledComponentsActionCreators.loadInstalledComponentsForce();
    return OrchestrationActionCreators.loadOrchestrationsForce();
  },

  render() {
    return <RefreshIcon isLoading={this.state.isLoading} onClick={this._handleRefreshClick} />;
  },
});

export default ComponentsReloaderButton;
