import { Badge, Icon, Tooltip } from '@keboola/design';

import { externalBigQueryTableType, externalSnowflakeTableType } from '@/modules/storage/constants';

const ExternalTableLabel = ({
  tableType,
  hasExternalSchema,
  className,
}: {
  tableType: string;
  hasExternalSchema: boolean;
  className?: string;
}) => {
  const isExternalTableType = [externalBigQueryTableType, externalSnowflakeTableType].includes(
    tableType,
  );

  if (!isExternalTableType && !hasExternalSchema) {
    return null;
  }

  return (
    <Tooltip
      placement="top"
      tooltip='This table an external table and in order to work must be registered as an external table type "BigLake table"'
      forceHide={!isExternalTableType}
      type="explanatory"
    >
      <Badge variant="blue" className={className} placement="right">
        External
        {isExternalTableType && (
          <Icon icon={['far', 'arrow-left-to-line']} className="f-13 tw-ml-2" />
        )}
      </Badge>
    </Tooltip>
  );
};

export default ExternalTableLabel;
