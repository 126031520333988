import { getIncomers, getOutgoers, useReactFlow } from '@xyflow/react';

import { parseFQID } from '@/modules/lineage/fqid';
import type { CustomEdge, CustomNode, NodeData, OnSelectProject } from '@/modules/lineage/rfTypes';
import LinkWithWindowIcon from './LinkWithWindowIcon';
import RowWithRenderer from './RowWithRenderer';

type ProjectData = { id: string; name: string };

const prepareLinkedProjects = (
  neighbourNodes: CustomNode[],
  currentProjectId: string | null,
  nodeProjectId: string | null,
) => {
  return neighbourNodes
    .map((node) => ({
      id: parseFQID(node.id)?.projectId ?? null,
      name: 'projectName' in node.data ? node.data.projectName : '',
    }))
    .filter(({ id }) => {
      return id !== null && id !== currentProjectId && id !== nodeProjectId;
    }) as ProjectData[];
};

const LinkedInfo = ({
  node,
  projectId,
  nodeProjectId,
  onSelectProject,
}: {
  node: NodeData;
  projectId: string | null;
  nodeProjectId: string | null;
  onSelectProject: OnSelectProject;
}) => {
  const { getNodes, getEdges } = useReactFlow<CustomNode, CustomEdge>();

  const linkedFrom = prepareLinkedProjects(
    getIncomers({ id: node.fqid }, getNodes(), getEdges()),
    projectId,
    nodeProjectId,
  );

  const linkedTo = prepareLinkedProjects(
    getOutgoers({ id: node.fqid }, getNodes(), getEdges()),
    projectId,
    nodeProjectId,
  );

  const renderProjectsLinks = (value: ProjectData[]) => {
    if (!value || value.length === 0) {
      return null;
    }

    return (
      <div className="tw-flex tw-flex-col">
        {value.map((project) => (
          <LinkWithWindowIcon
            key={project.id}
            onClick={(event) => onSelectProject(project.name, project.id, node.fqid, event)}
          >
            {project.name}
          </LinkWithWindowIcon>
        ))}
      </div>
    );
  };

  if (linkedFrom.length === 0 && linkedTo.length === 0) {
    return null;
  }

  if (linkedFrom.length > 0) {
    return (
      <RowWithRenderer title="Linked from" value={linkedFrom}>
        {renderProjectsLinks}
      </RowWithRenderer>
    );
  }

  return (
    <RowWithRenderer title="Used by" value={linkedTo}>
      {renderProjectsLinks}
    </RowWithRenderer>
  );
};

export default LinkedInfo;
