import type { GenericFetchClient } from '../managementClient';

import type { Feature, GetAllFeaturesQuery } from './types';

export const createFeatures = (client: GenericFetchClient) => {
  const getAllFeatures = async (query: GetAllFeaturesQuery, signal?: AbortSignal) => {
    const { data } = await client.get<Feature[], void, GetAllFeaturesQuery>(
      '/features',
      { query },
      { signal },
    );
    return data;
  };

  return {
    getAllFeatures,
  };
};
