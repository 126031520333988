import { useState } from 'react';
import type Promise from 'bluebird';
import type { Map } from 'immutable';

import { AUTH_METHODS } from '@/modules/ex-generic/constants';
import * as authMapping from '@/modules/ex-generic/mapping/auth';
import * as destinationMapping from '@/modules/ex-generic/mapping/destination';
import * as generalMapping from '@/modules/ex-generic/mapping/general';
import { prepareMapToApiFn, prepareMapToStateFn } from '@/modules/ex-generic/mapping/helpers';
import CatchUnsavedChanges from '@/react/common/CatchUnsavedChanges';
import SaveButtons from '@/react/common/SaveButtons';
import fromJSOrdered from '@/utils/fromJSOrdered';
import { isValidJsonConfig } from '@/utils/validation';
import Authentication from './Authentication';
import Destination from './Destination';
import General from './General';

const MAPPINGS = [generalMapping, authMapping, destinationMapping];

const mapToState = prepareMapToStateFn(MAPPINGS);
const mapToApi = prepareMapToApiFn(MAPPINGS);

const Base = (props: {
  readOnly: boolean;
  parameters: Map<string, any>;
  allBuckets: Map<string, any>;
  onSave: (parameters: Map<string, any>, changeDescription: string) => Promise<any>;
}) => {
  const preparedParameters = mapToState(props.parameters);

  const [editing, setEditing] = useState(preparedParameters);
  const [saving, setSaving] = useState(false);

  const isChanged = !editing.equals(preparedParameters);

  const isDisabled = () => {
    if (
      editing.getIn([authMapping.KEY, 'method']) === AUTH_METHODS.CUSTOM &&
      !isValidJsonConfig(editing.getIn([authMapping.KEY, 'authentication']))
    ) {
      return true;
    }

    return !editing.getIn([generalMapping.KEY, 'baseUrl']);
  };

  const prepareSectionProps = (key: string) => {
    return {
      onSave: props.onSave,
      readOnly: props.readOnly,
      parameters: props.parameters,
      editing: editing.get(key),
      setEditing: (values: Map<string, any>) => setEditing(editing.set(key, values)),
    };
  };

  const handleSave = () => {
    setSaving(true);
    return props
      .onSave(mapToApi(props.parameters, editing), 'Update base configuration')
      .then((configData: Record<string, any>) => {
        setEditing(mapToState(fromJSOrdered(configData.parameters)));
      })
      .finally(() => setSaving(false));
  };

  const handleReset = () => setEditing(preparedParameters);

  return (
    <>
      <div className="tw-flex tw-items-start tw-justify-between">
        <h2 className="tw-mb-5 tw-mt-1 tw-text-2xl tw-font-normal">Base Configuration</h2>
        <CatchUnsavedChanges
          isDirty={isChanged && !saving}
          onSave={handleSave}
          onDirtyLeave={handleReset}
        >
          <SaveButtons
            isSaving={saving}
            isChanged={isChanged}
            onSave={handleSave}
            onReset={handleReset}
            disabled={isDisabled()}
            className="tw-inline-flex"
          />
        </CatchUnsavedChanges>
      </div>
      <General {...prepareSectionProps(generalMapping.KEY)} />
      <Authentication {...prepareSectionProps(authMapping.KEY)} />
      <Destination {...prepareSectionProps(destinationMapping.KEY)} allBuckets={props.allBuckets} />
    </>
  );
};

export default Base;
