import type { ReactNode } from 'react';

import { cn, FormGroup, Label, type ValidityState } from '@keboola/design';

import { nameWarning } from '@/modules/storage/constants';
import InputValidation from '@/react/common/InputValidation';

const PredefinedInput = (props: {
  entity: 'tableName' | 'bucketName';
  value: string;
  onChange: (value: string) => void;
  autoFocus?: boolean;
  label?: ReactNode;
  warning?: ReactNode;
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <InputValidation predefined={props.entity} value={props.value}>
      {(inputState: 'error' | null) => {
        const state: ValidityState = props.warning ? 'error' : (inputState ?? 'default');
        const messages: Partial<Record<ValidityState, ReactNode>> = {
          default: nameWarning,
          error: props.warning,
        };

        return (
          <FormGroup className={cn('tw-mb-4', props.className)} state={state}>
            <Label htmlFor="entity">
              {props.label || (props.entity === 'bucketName' ? 'Name' : 'Table Name')}
            </Label>

            <FormGroup.TextInput
              id="entity"
              name={props.entity}
              value={props.value}
              autoFocus={props.autoFocus}
              variant="secondary"
              disabled={props.disabled}
              onChange={(val) => props.onChange(val)}
            />

            <FormGroup.Help>{messages[state]}</FormGroup.Help>
          </FormGroup>
        );
      }}
    </InputValidation>
  );
};

export default PredefinedInput;
