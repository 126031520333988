import { Area, AreaChart, ResponsiveContainer, Tooltip as ChartTooltip } from 'recharts';

import { formatAbsolute } from '@/react/common/helpers';
import { durationInWords } from '@/utils/duration';

const Tooltip = ({ x, y }) => (
  <div className="tw-ml-1 tw-whitespace-nowrap tw-rounded-lg tw-bg-neutral-900 tw-p-2 tw-text-left tw-text-xs tw-text-white">
    <div>Created: {formatAbsolute(x)}</div>
    <div>Duration: {durationInWords(y)}</div>
  </div>
);

const LatestImportGraph = (props) => {
  const data = props.data
    .toList()
    .map((event) => ({
      y: event.getIn(['performance', 'importDuration']),
      x: event.get('created'),
    }))
    .toArray()
    .reverse();

  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={215}>
      <AreaChart data={data} margin={0}>
        <ChartTooltip
          cursor={false}
          followPointer={false}
          content={(props) => {
            const values = data[Number(props?.label)];

            return <Tooltip x={values?.x} y={values?.y} />;
          }}
        />
        <Area
          type="linear"
          dataKey="y"
          stroke="#1F8FFF"
          fill="#e1f0ff"
          activeDot={{ stroke: '#1f68ff', fill: '#cce6ff', strokeWidth: 1, r: 4 }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default LatestImportGraph;
