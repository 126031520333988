import { Component } from 'react';
import PropTypes from 'prop-types';

import { Icon, Tooltip } from '@keboola/design';

import { routeNames } from '@/modules/transformations/Constants';
import { RouterLink as Link } from '@/react/common';

class DependentTransformations extends Component {
  render() {
    const transformations = this.getDependentTransformations();

    return (
      <>
        <h2 className="first-line">
          Dependent Transformations
          <Tooltip
            placement="top"
            type="static-explanatory"
            tooltip="Transformations dependent on the current transformation"
          >
            <Icon icon="circle-question" className="icon-addon-left" />
          </Tooltip>
        </h2>
        {transformations.count() > 0 ? (
          <div>
            {transformations
              .map((dependent) => (
                <Link
                  key={dependent.get('id')}
                  to={routeNames.DETAIL}
                  className="bigger-label clickable mr-1 mt-1"
                  params={{ row: dependent.get('id'), config: this.props.bucket.get('id') }}
                >
                  {parseInt(dependent.get('phase', 1), 10) !==
                  parseInt(this.props.transformation.get('phase', 1), 10)
                    ? dependent.get('name') + ' (phase mismatch)'
                    : dependent.get('name')}
                </Link>
              ))
              .toArray()}
          </div>
        ) : (
          <p className="last-line mt-2 base-size">No dependent transformation</p>
        )}
      </>
    );
  }

  getDependentTransformations() {
    return this.props.transformations.filter((transformation) =>
      transformation.get('requires').contains(this.props.transformation.get('id')),
    );
  }
}

DependentTransformations.propTypes = {
  transformations: PropTypes.object.isRequired,
  transformation: PropTypes.object.isRequired,
  bucket: PropTypes.object.isRequired,
};

export default DependentTransformations;
