import { Map } from 'immutable';

import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import { BucketLabels } from '@/react/common';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';

const BucketLabelsFromStore = () => {
  const { bucket } = useStores(
    () => {
      return {
        bucket: BucketsStore.getBucket(
          RoutesStore.getRouterState().getIn(['params', 'bucketId']),
          Map(),
        ),
      };
    },
    [],
    [RoutesStore, BucketsStore],
  );

  return <BucketLabels bucket={bucket} withStageLabel={false} />;
};

export default BucketLabelsFromStore;
