import { routeNames } from '@/modules/data-catalog/constants';
import type { BucketType, SourceTab } from '@/modules/data-catalog/types';

type Props = { sourceTab: SourceTab; bucket: BucketType };

export const getLinkToDetail = ({ sourceTab, bucket }: Props) => {
  const bucketId = bucket.get('id');
  const projectId = bucket.getIn(['project', 'id']);

  if (sourceTab === routeNames.SHARED_WITH_YOU) {
    return {
      name: routeNames.SHARED_WITH_YOU_DETAIL,
      params: { projectId, bucketId },
    };
  }

  return {
    name: routeNames.SHARED_FROM_THIS_PROJECT_DETAIL,
    params: { bucketId },
  };
};
