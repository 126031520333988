const Box = ({ title, children }: { title: string; children: React.ReactNode }) => {
  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      <h4 className="tw-m-0 tw-px-2 tw-text-xs tw-font-semibold tw-uppercase tw-leading-5">
        {title}
      </h4>
      <div className="tw-flex tw-flex-col tw-gap-1">{children}</div>
    </div>
  );
};

export default Box;
