import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { Icon } from '@keboola/design';

import { routeNames } from '@/modules/ex-mongodb/constants';
import RoutesStore from '@/stores/RoutesStore';

const CreateQueryElement = createReactClass({
  propTypes: {
    isNav: PropTypes.bool.isRequired,
    configurationId: PropTypes.string.isRequired,
    componentId: PropTypes.string,
    actionCreators: PropTypes.object.isRequired,
  },

  createQuery() {
    let query = this.props.actionCreators.createNewQuery(this.props.configurationId);
    RoutesStore.getRouter().transitionTo(routeNames.QUERY, {
      config: this.props.configurationId,
      query: query.get('id'),
    });
  },

  render() {
    if (this.props.isNav) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a className="list-group-item" onClick={this.createQuery}>
          <strong>
            <Icon icon="plus" className="icon-addon-right" />
            Create a new entry
          </strong>
        </a>
      );
    }

    return (
      <Button bsStyle="success" bsSize="sm" onClick={this.createQuery}>
        <Icon icon="plus" className="icon-addon-right" />
        New Export
      </Button>
    );
  },
});

export default CreateQueryElement;
