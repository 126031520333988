import { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { fromJS, List, Map } from 'immutable';

import { KEBOOLA_VARIABLES } from '@/constants/componentIds';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import Filled from '@/react/common/KeyValueBox/Filled';
import nextTick from '@/utils/nextTick';
import {
  checkIfVariableConfigExists,
  createConfigWithVariableDefinitionAndRowWithFirstValue,
  createVariableDefinitionAndValue,
  deleteVariableDefinitionAndValue,
  deleteVariableReferenceAndVariableDefinition,
  initMissingVariables,
  updateVariableDefinitionAndValue,
} from './actions';
import { getClipboardVariable } from './helpers.js';
import VariableForm from './VariableForm';
import VariablesBox from './VariablesBox';

class Variables extends Component {
  constructor(props) {
    super(props);

    this.state = {
      savingVariables: Map(),
      deletingVariables: Map(),
      editingVariables: Map(),
      savingVariableName: null,
      isAddingNewVariable: false,
      isAddingMissingVariables: false,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.loadVariables = this.loadVariables.bind(this);
  }

  render() {
    return (
      <VariablesBox
        isLoading={this.state.isAddingMissingVariables}
        isReadOnly={this.props.readOnly}
        isDisabled={this.state.isAddingNewVariable}
        onAddNew={() => this.setState({ isAddingNewVariable: true })}
      >
        {this.renderVariables()}
      </VariablesBox>
    );
  }

  renderVariables() {
    if (
      !this.state.isAddingNewVariable &&
      this.props.variables.isEmpty() &&
      this.props.missingVariables.isEmpty()
    ) {
      return null;
    }

    return (
      <>
        {this.props.variables
          .filter((variable) => {
            if (this.state.savingVariableName) {
              return variable.get('name') !== this.state.savingVariableName;
            }

            return true;
          })
          .map((variable, index) => {
            const variableName = variable.get('name');

            if (this.state.editingVariables.has(variableName)) {
              return (
                <VariableForm
                  key={`edit-${variableName}-${index}`}
                  saveVariableFn={(name, value) => {
                    return this.handleUpdate(variableName, variable.get('value'), name, value);
                  }}
                  resetVariableFn={() => {
                    this.setState({
                      editingVariables: this.state.editingVariables.delete(variableName),
                    });
                  }}
                  variableName={variable.get('name')}
                  variableValue={variable.get('value')}
                  isSaving={this.state.savingVariables.has(variableName)}
                  variables={this.props.variables}
                />
              );
            }

            return (
              <Filled
                key={`view-${variableName}-${index}`}
                readOnly={this.props.readOnly}
                name={variable.get('name')}
                value={variable.get('value')}
                deleteVariableFn={() => this.handleDelete(variableName)}
                startEditingFn={() => {
                  this.setState({
                    editingVariables: this.state.editingVariables.set(variableName, true),
                  });
                }}
                isDeleting={this.state.deletingVariables.has(variableName)}
                clipboardText={getClipboardVariable(variable.get('name'))}
                entity="variable"
              />
            );
          })}
        {this.state.isAddingNewVariable && (
          <VariableForm
            saveVariableFn={this.handleAdd}
            resetVariableFn={() => this.setState({ isAddingNewVariable: false })}
            isSaving={!!this.state.savingVariableName}
            variables={this.props.variables}
          />
        )}
        {this.renderMissingVariablesWarning()}
      </>
    );
  }

  renderMissingVariablesWarning() {
    if (!this.props.missingVariables.count() || this.props.readOnly) {
      return null;
    }

    return (
      <div className="help-block">
        {this.props.missingVariables.count() > 1
          ? 'Several variables required by Shared Code are missing '
          : 'A variable required by Shared Code is missing '}
        (
        {this.props.missingVariables
          .map((variable) => <code key={variable}>{variable}</code>)
          .reduce((prev, curr) => [prev, ', ', curr])}
        ) , please define them. Or{' '}
        <Button
          bsStyle="link"
          className="btn-link-inline"
          disabled={this.state.isAddingMissingVariables}
          onClick={() => {
            this.setState({ isAddingMissingVariables: true });
            initMissingVariables(
              this.props.mainComponentId,
              this.props.mainConfigurationId,
              this.props.configVariablesId,
              this.props.configVariablesValuesId,
              this.props.variables.map((variable) => variable.get('name')),
              this.props.missingVariables,
            )
              .then(this.loadVariables)
              .finally(() => {
                this.setState({ isAddingMissingVariables: false });
              });
          }}
        >
          click here
        </Button>{' '}
        to initialize the variables with empty values.
      </div>
    );
  }

  handleDelete(name) {
    this.setState({ deletingVariables: this.state.deletingVariables.set(name, true) });
    return deleteVariableDefinitionAndValue(
      name,
      this.props.configVariablesId,
      this.props.configuration,
      this.props.configVariablesValuesId,
      this.props.row,
    )
      .then(fromJS)
      .then((rowConfiguration) => {
        if (!rowConfiguration.getIn(['configuration', 'values'], List()).isEmpty()) {
          return this.loadVariables();
        }

        return deleteVariableReferenceAndVariableDefinition(
          this.props.mainComponentId,
          this.props.mainConfigurationId,
          this.props.configVariablesId,
          this.props.mainConfiguration,
        );
      })
      .finally(() => {
        this.setState({ deletingVariables: this.state.deletingVariables.delete(name) });
      });
  }

  handleUpdate(oldVariableName, newVariableName, oldVariableValue, newVariableValue) {
    this.setState({ savingVariables: this.state.savingVariables.set(oldVariableName, true) });
    return updateVariableDefinitionAndValue(
      oldVariableName,
      newVariableName,
      oldVariableValue,
      newVariableValue,
      this.props.configVariablesId,
      this.props.configuration,
      this.props.configVariablesValuesId,
      this.props.row,
    )
      .then(this.loadVariables)
      .finally(() => {
        this.setState({ savingVariables: this.state.savingVariables.delete(oldVariableName) });
      });
  }

  handleAdd(variableName, variableValue) {
    this.setState({ savingVariableName: variableName });

    return checkIfVariableConfigExists(this.props.configVariablesId)
      .then((variableConfigExists) => {
        if (variableConfigExists && this.props.configVariablesValuesId) {
          return createVariableDefinitionAndValue(
            variableName,
            variableValue,
            this.props.configVariablesId,
            this.props.configuration,
            this.props.configVariablesValuesId,
            this.props.row,
          ).then(this.loadVariables);
        }

        return createConfigWithVariableDefinitionAndRowWithFirstValue(
          variableName,
          variableValue,
          this.props.mainComponentId,
          this.props.mainConfigurationId,
          this.props.mainConfiguration,
        );
      })
      .finally(() => this.setState({ savingVariableName: null }));
  }

  loadVariables() {
    return nextTick(() =>
      InstalledComponentsActionCreators.loadComponentConfigDataForce(
        KEBOOLA_VARIABLES,
        this.props.configVariablesId,
      ),
    );
  }
}

Variables.propTypes = {
  readOnly: PropTypes.bool.isRequired,
  mainComponentId: PropTypes.string.isRequired,
  mainConfigurationId: PropTypes.string.isRequired,
  mainConfiguration: PropTypes.instanceOf(Map).isRequired,
  configuration: PropTypes.instanceOf(Map).isRequired,
  row: PropTypes.instanceOf(Map).isRequired,
  variables: PropTypes.instanceOf(List).isRequired,
  missingVariables: PropTypes.instanceOf(List),
  configVariablesId: PropTypes.string,
  configVariablesValuesId: PropTypes.string,
};

Variables.defaultProps = {
  missingVariables: List(),
};

export default Variables;
