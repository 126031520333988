import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { URLS } from '@keboola/constants';
import { FormGroup, HelpBlock, Label, Link, TextInput } from '@keboola/design';

import { KEBOOLA_EX_GMAIL } from '@/constants/componentIds';
import { isGoogleService } from '@/modules/oauth-v2/helpers';
import string from '@/utils/string';

const FORM_GROUP_CLASS_NAME = 'tw-grid tw-grid-cols-4 tw-items-baseline tw-gap-4';

const CustomAuthorizationFields = createReactClass({
  propTypes: {
    componentId: PropTypes.string.isRequired,
    values: PropTypes.object.isRequired,
    onChangeFn: PropTypes.func,
    disabled: PropTypes.bool,
  },

  render() {
    let fields = { appKey: 'Client ID', appSecret: 'Client secret' };

    return (
      <div className="tw-flex tw-flex-col tw-gap-4">
        {isGoogleService(this.props.componentId) && (
          <p>
            Provide your own OAuth 2.0 credentials from{' '}
            <Link href="https://console.developers.google.com">Google API Console</Link>
            .
            <br />
            Follow these <Link href={this.getInstructionsUrl()}>instructions</Link> to set up an
            application and obtain a pair of credentials.
          </p>
        )}
        <FormGroup className={FORM_GROUP_CLASS_NAME}>
          <Label htmlFor="name">
            Name <Label.Optional />
          </Label>
          <div className="tw-col-span-3 tw-flex tw-flex-col tw-gap-1">
            <TextInput
              id="name"
              variant="secondary"
              value={this.props.values.authorizedFor || ''}
              onChange={(value) => this.props.onChangeFn('authorizedFor', value)}
              autoFocus
              disabled={this.props.disabled}
            />
            <HelpBlock>Describe this authorization, e.g., by the account name.</HelpBlock>
          </div>
        </FormGroup>
        {Object.keys(fields).map((key) => this.renderInput(key, fields[key]))}
      </div>
    );
  },

  renderInput(key, name) {
    return (
      <FormGroup className={FORM_GROUP_CLASS_NAME}>
        <Label htmlFor={string.webalize(name)}>{name}</Label>
        <div className="tw-col-span-3 tw-flex tw-flex-col tw-gap-1">
          <TextInput
            id={string.webalize(name)}
            variant="secondary"
            name={key}
            value={this.props.values[key] || ''}
            onChange={(value) => this.props.onChangeFn(key, value)}
            disabled={this.props.disabled}
          />
          <HelpBlock>{name} of your app</HelpBlock>
        </div>
      </FormGroup>
    );
  },

  getInstructionsUrl() {
    if (this.props.componentId === KEBOOLA_EX_GMAIL) {
      return `${URLS.USER_DOCUMENTATION}/components/extractors/communication/gmail/#authorization`;
    }

    return `${URLS.USER_DOCUMENTATION}/components/extractors/marketing-sales/google-analytics/#custom-oauth-credentials`;
  },
});

export default CustomAuthorizationFields;
