import PropTypes from 'prop-types';

import { cn } from '@keboola/design';

import { routeNames as storageRoutes } from '@/modules/storage/constants';
import { RouterLink as Link } from '@/react/common';
import { parse as parseTable } from '@/utils/tableIdParser';

const StorageApiTableLink = ({ tableId, children, className }) => {
  const parsedTable = parseTable(tableId);

  return (
    <Link
      to={storageRoutes.TABLE}
      className={cn(className)}
      params={{
        bucketId: `${parsedTable.parts.stage}.${parsedTable.parts.bucket}`,
        tableName: parsedTable.parts.table,
      }}
    >
      {children}
    </Link>
  );
};

StorageApiTableLink.propTypes = {
  tableId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default StorageApiTableLink;
