import { useState } from 'react';
import type { FormEvent } from 'react';
import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';
import type Promise from 'bluebird';
import type { Map } from 'immutable';
import { fromJS, List } from 'immutable';

import { Alert } from '@keboola/design';

import { STAGE } from '@/constants';
import { canWriteBucket } from '@/modules/admin/privileges';
import { filterProductionBuckets } from '@/modules/dev-branches/helpers';
import { ALIAS_SUFFIX } from '@/modules/storage/constants';
import { bucketDisplayNameWithStage, validateTableName } from '@/modules/storage/helpers';
import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import { isValidName } from '@/react/common/helpers';
import InfoTooltip from '@/react/common/InfoTooltip';
import ModalIcon from '@/react/common/ModalIcon';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import Select from '@/react/common/Select';
import { bucketLabel } from '@/react/common/selectLabels';
import whereOperatorConstants from '@/react/common/whereOperatorConstants';
import string from '@/utils/string';
import PredefinedInput from './PredefinedInput';

const initialNewTableAlias = {
  destinationBucket: '',
  name: '',
  aliasFilter: {
    column: '',
    operator: whereOperatorConstants.EQ_VALUE,
    values: List(),
  },
  aliasColumnsAutosync: true,
};

type Props = {
  buckets: Map<string, any>;
  tables: Map<string, any>;
  table: Map<string, any>;
  sapiToken: Map<string, any>;
  show: boolean;
  onSubmit: (bucketId: string, tableAlias: Record<string, any>) => Promise<null>;
  onHide: () => void;
  isSaving: boolean;
};

type State = {
  newTableAlias: Map<string, any>;
  tableColumns: { label: string; value: string }[];
  error: string | null;
  warning: string | null;
};

const CreateAliasTableAlternativeModal = (props: Props) => {
  const initState: State = {
    newTableAlias: fromJS(initialNewTableAlias).set(
      'name',
      string.webalize(`${props.table.get('displayName')}${ALIAS_SUFFIX}`),
    ),
    tableColumns: props.table
      .get('columns')
      .map((column: string) => ({ label: column, value: column }))
      .toArray(),
    error: null,
    warning: null,
  };

  const [state, setState] = useState<State>(initState);

  const resetState = () => {
    setState((prevState) => ({
      ...prevState,
      newTableAlias: fromJS(initialNewTableAlias),
      error: null,
      warning: null,
    }));
  };

  const onHide = () => {
    props.onHide();
    resetState();
  };

  const validateName = () => {
    setState((prevState) => ({
      ...prevState,
      warning: validateTableName(
        prevState.newTableAlias.get('name'),
        props.tables.filter((table) => {
          if (prevState.newTableAlias.get('destinationBucket')) {
            return (
              table.getIn(['bucket', 'id']) === prevState.newTableAlias.get('destinationBucket')
            );
          }
          return true;
        }) as Map<string, any>,
      ),
    }));
  };

  const destinationBucketOptions = filterProductionBuckets(props.buckets)
    .filter((bucket: Map<string, any>) => {
      return (
        canWriteBucket(props.sapiToken, bucket) &&
        Object.values(STAGE).includes(bucket.get('stage'))
      );
    })
    .sortBy((bucket: Map<string, any>) => bucketDisplayNameWithStage(bucket))
    .map((bucket: Map<string, any>) => ({
      value: bucket.get('id'),
      label: bucketLabel(bucket),
      name: bucket.get('displayName'),
    }))
    .toArray();

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const bucketId = state.newTableAlias.get('destinationBucket');
    const tableAlias = state.newTableAlias
      .update((tableAlias) => {
        if (!tableAlias.getIn(['aliasFilter', 'column'])) {
          return tableAlias.delete('aliasFilter');
        }
        return tableAlias;
      })
      .set('sourceTable', props.table.get('id'))
      .delete('destinationBucket')
      .toJS();

    setState((prevState) => ({ ...prevState, error: null }));
    props.onSubmit(bucketId, tableAlias).then(onHide, (message) => {
      setState((prevState) => ({ ...prevState, error: message }));
    });
  };

  const isDisabled = () => {
    const tableAlias = state.newTableAlias;
    const aliasFilter = tableAlias.get('aliasFilter');

    if (
      !tableAlias.get('name') ||
      !isValidName(tableAlias.get('name')) ||
      !tableAlias.get('destinationBucket')
    ) {
      return true;
    }

    if (
      aliasFilter.get('column') &&
      (!aliasFilter.get('operator') || !aliasFilter.get('values').count())
    ) {
      return true;
    }

    if (
      !tableAlias.get('aliasColumnsAutosync', true) &&
      tableAlias.get('aliasColumns', List()).isEmpty()
    ) {
      return true;
    }

    return props.isSaving;
  };

  return (
    <Modal show={props.show} onHide={onHide}>
      <form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Create Alias of Table {props.table.get('name')}</Modal.Title>
          <ModalIcon icon="table" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          {state.error && (
            <Alert variant="error" className="tw-mb-5">
              {state.error}
            </Alert>
          )}
          <FormGroup>
            <ControlLabel>Destination bucket</ControlLabel>
            <Select
              autoFocus
              clearable={false}
              value={state.newTableAlias.get('destinationBucket')}
              onChange={(bucketId: string) => {
                setState((prevState) => ({
                  ...prevState,
                  newTableAlias: prevState.newTableAlias.set('destinationBucket', bucketId),
                }));
                validateName();
              }}
              options={destinationBucketOptions}
            />
          </FormGroup>
          <PredefinedInput
            entity="bucketName"
            value={state.newTableAlias.get('name')}
            warning={state.warning}
            onChange={(name) => {
              setState((prevState) => ({
                ...prevState,
                newTableAlias: prevState.newTableAlias.set('name', name),
              }));
              validateName();
            }}
          />
          {state.newTableAlias.get('destinationBucket') &&
          !!props.buckets.getIn([state.newTableAlias.get('destinationBucket'), 'sharing']) ? (
            <Alert variant="warning" className="tw-mb-5">
              Filtering and synchronization of specific columns is not available when creating an
              alias table in a shared bucket.
            </Alert>
          ) : (
            <>
              <FormGroup>
                <ControlLabel>
                  Filtering <OptionalFormLabel />
                  <InfoTooltip
                    tooltip={
                      <>
                        You can specify a column to filter by, and comma-separated values
                        you&apos;re looking for. The alias table will contain only the matching
                        rows.
                      </>
                    }
                  />
                </ControlLabel>
                <div className="select-group">
                  <Select
                    clearable
                    placeholder="Column"
                    value={state.newTableAlias.getIn(['aliasFilter', 'column'])}
                    onChange={(option: string) => {
                      setState((prevState) => ({
                        ...prevState,
                        newTableAlias: option
                          ? prevState.newTableAlias.setIn(['aliasFilter', 'column'], option)
                          : prevState.newTableAlias.deleteIn(['aliasFilter', 'column']),
                      }));
                    }}
                    options={state.tableColumns}
                  />
                  <Select
                    searchable={false}
                    clearable={false}
                    value={state.newTableAlias.getIn(['aliasFilter', 'operator'])}
                    onChange={(value: string) => {
                      setState((prevState) => ({
                        ...prevState,
                        newTableAlias: prevState.newTableAlias.setIn(
                          ['aliasFilter', 'operator'],
                          value,
                        ),
                      }));
                    }}
                    options={[
                      {
                        label: whereOperatorConstants.EQ_LABEL,
                        value: whereOperatorConstants.EQ_VALUE,
                      },
                      {
                        label: whereOperatorConstants.NOT_EQ_LABEL,
                        value: whereOperatorConstants.NOT_EQ_VALUE,
                      },
                    ]}
                  />
                </div>
                <Select
                  multi
                  allowCreate
                  emptyStrings
                  placeholder="Add a value"
                  value={state.newTableAlias.getIn(['aliasFilter', 'values'])}
                  disabled={state.tableColumns.length === 0}
                  onChange={(value) => {
                    setState((prevState) => ({
                      ...prevState,
                      newTableAlias: prevState.newTableAlias.setIn(
                        ['aliasFilter', 'values'],
                        value,
                      ),
                    }));
                  }}
                />
              </FormGroup>
              <FormGroup>
                <ControlLabel>
                  Columns
                  <InfoTooltip
                    tooltip={
                      <>
                        <p>By default, columns are synchronized with the source table.</p>
                        <p>
                          You can disable this behavior and select only particular columns to be
                          included in the alias table.
                        </p>
                      </>
                    }
                  />
                </ControlLabel>
                <Checkbox
                  checked={state.newTableAlias.get('aliasColumnsAutosync')}
                  onChange={() => {
                    setState((prevState) => ({
                      ...prevState,
                      newTableAlias: prevState.newTableAlias
                        .update(
                          'aliasColumnsAutosync',
                          (aliasColumnsAutosync) => !aliasColumnsAutosync,
                        )
                        .set('aliasColumns', List()),
                    }));
                  }}
                >
                  Synchronize columns with the source table
                </Checkbox>
              </FormGroup>
              {!state.newTableAlias.get('aliasColumnsAutosync') && (
                <FormGroup>
                  <Select
                    multi
                    allowCreate
                    placeholder="Select alias table columns..."
                    value={state.newTableAlias.get('aliasColumns', List())}
                    onChange={(columns) => {
                      setState((prevState) => ({
                        ...prevState,
                        newTableAlias: prevState.newTableAlias.set('aliasColumns', columns),
                      }));
                    }}
                    options={state.tableColumns}
                  />
                </FormGroup>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isSaving={props.isSaving}
            isDisabled={isDisabled()}
            saveLabel={props.isSaving ? 'Creating alias table...' : 'Create alias table'}
            saveButtonType="submit"
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CreateAliasTableAlternativeModal;
