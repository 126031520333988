import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const parseLastLogTimestamp = (logs: string | null) => {
  if (logs === null) return null;

  const TIMESTAMP_REGEX = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+Z/;
  const lines = logs.split('\n');

  for (let i = lines.length - 1; i >= 0; i--) {
    const match = lines[i].match(TIMESTAMP_REGEX);
    if (match === null) continue;
    return match[0];
  }

  return null;
};

export const calculateNextLogTimestamp = (timestamp: string | null) => {
  if (timestamp === null) return null;
  return dayjs(timestamp).utc().add(1, 'second').utc().format();
};
