import type { ReactNode } from 'react';

import { cn } from '../../utils';

type Props = {
  children: ReactNode;
  space?: 'extra-small' | 'small' | 'medium' | 'large';
  className?: string;
  variant?: 'block' | 'inline';
};

export const ButtonGroup = ({
  children,
  space = 'small',
  className,
  variant = 'inline',
}: Props) => {
  return (
    <div
      className={cn(className, 'tw-inline-flex tw-items-center', {
        'tw-w-full [&_button:not([data-button-group-id=icon-button])]:tw-w-full [&_a]:tw-w-full':
          variant === 'block',
        'tw-gap-1': space === 'extra-small',
        'tw-gap-3': space === 'small',
        'tw-gap-4': space === 'medium',
        'tw-gap-6': space === 'large',
      })}
    >
      {children}
    </div>
  );
};
