import { useEffect } from 'react';

import { KEBOOLA_EX_INSTAGRAM } from '@/constants/componentIds';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import actionsProvisioning from '@/modules/ex-facebook/actionsProvisioning';
import { QUERY_TYPE } from '@/modules/ex-facebook/constants';
import storeProvisioning from '@/modules/ex-facebook/storeProvisioning';
import QueryTemplates from '@/modules/ex-facebook/templates/queryTemplates';
import ConfigurationInfoPanel from '@/react/common/ConfigurationInfoPanel';
import useStores from '@/react/hooks/useStores';
import Sidebar from '@/react/layout/Sidebar/Sidebar';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import AccountsSetting from './AccountsSetting';
import AdvancedSettings from './AdvancedSettings';
import DateRangeSelector from './DateRange';
import GraphAPIExplorerLink from './GraphAPIExplorerLink';
import Report from './Report';

const Query = () => {
  const state = useStores(
    () => {
      const componentId = RoutesStore.getCurrentRouteComponentId();
      const configId = RoutesStore.getCurrentRouteParam('config');
      const queryId = RoutesStore.getCurrentRouteParam('queryId');
      const store = storeProvisioning(componentId, configId);
      const actions = actionsProvisioning(componentId, configId);
      const query = store.findQuery(queryId);

      return {
        componentId,
        component: ComponentsStore.getComponent(componentId),
        componentsMetadata: InstalledComponentsStore.getAllMetadata(),
        query: query,
        queryId: queryId,
        store,
        actions,
        configId,
        localState: store.getLocalState(),
        flows: InstalledComponentsStore.getComponentConfigurations(componentId),
        readOnly: ApplicationStore.isReadOnly(),
      };
    },
    [],
    [RoutesStore, ApplicationStore, InstalledComponentsStore, ComponentsStore],
  );

  useEffect(() => {
    const { localState } = state;

    // TODO remove local state
    if (!localState.has('query') || localState.getIn(['query', 'id']) !== state.query.get('id')) {
      state.actions.updateLocalState(['query'], state.query);
    }
  }, [state]);

  const isAuthorized = () => {
    return state.store.isAuthorized();
  };

  const updateLocalState = (path, newValue) => {
    return state.actions.updateLocalState(['query'].concat(path), newValue);
  };

  const config = state.localState.get('query', state.query);
  const query = config.get('query');
  const isAsync = config.get('type') === QUERY_TYPE.ASYNC_INSIGHTS;

  if (state.query.get('id') !== config.get('id')) {
    return null;
  }

  return (
    <>
      <ConfigurationInfoPanel
        component={state.component}
        config={state.store.config}
        flows={state.flows}
        metadata={state.componentsMetadata}
      />

      <div className="row">
        <div className="col-xs-9">
          <AccountsSetting
            query={query}
            readOnly={state.readOnly}
            accounts={state.store.accounts}
            updateLocalState={updateLocalState}
            storedIds={state.query.getIn(['query', 'ids'])}
          />
          <Report
            componentId={state.componentId}
            config={config}
            templates={QueryTemplates.get(state.componentId)}
            isAsync={isAsync}
            readOnly={state.readOnly}
            documentationUrl={state.component.get('documentationUrl')}
            resetTemplate={state.query.equals(config)}
            updateLocalState={updateLocalState}
          />
          {!isAsync && (
            <DateRangeSelector
              readOnly={state.readOnly}
              config={config}
              updateLocalState={updateLocalState}
            />
          )}
          <AdvancedSettings
            readOnly={state.readOnly}
            config={config}
            updateLocalState={updateLocalState}
          />
        </div>
        <div className="col-xs-3">
          <Sidebar
            componentId={state.componentId}
            configId={state.configId}
            hideJobs
            hideVersions
            hideSchedule
            run={{
              disabled: !isAuthorized()
                ? `No ${
                    state.componentId === KEBOOLA_EX_INSTAGRAM ? 'Instagram' : 'Facebook'
                  } account authorized`
                : '',
              text: 'You are about to run an extraction.',
              title: 'Run Query',
              params: () => ({
                config: state.configId,
                configData: state.store.getRunSingleQueryData(state.queryId),
              }),
            }}
            additionalPrimaryButtons={
              <GraphAPIExplorerLink
                isAsyncInsightQueryChecked={isAsync}
                ids={state.store.accounts.keySeq()}
                query={query}
                queryType={config.get('type')}
                apiVersion={state.store.version}
              />
            }
          />
        </div>
      </div>
    </>
  );
};

export default Query;
