import { useState } from 'react';
import type { ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import type Promise from 'bluebird';

import keyCodes from '@/constants/keyCodes';
import StackFeaturesStore from '@/modules/stack-features/Store';
import string from '@/utils/string';
import DescriptionBox from './DescriptionBox/DescriptionBox';
import ModalIcon from './ModalIcon';

const DescriptionModal = (props: {
  show: boolean;
  onHide: () => void;
  onSave: (description: string) => Promise<void>;
  description: string;
  entity: string;
  readOnly?: boolean;
  additionalTitleControl?: ReactNode;
  warning?: ReactNode;
  configId?: string;
  componentId?: string;
  rowId?: string;
}) => {
  const [editorValue, setEditorValue] = useState<string | null>(null);

  const handleSubmit = () => props.onSave(editorValue?.trim() || '').then(props.onHide);

  return (
    <span
      className="tw-hidden"
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.key === keyCodes.ENTER && e.stopPropagation()}
    >
      <Modal
        bsSize="large"
        show={props.show}
        onHide={props.onHide}
        onEnter={() => setEditorValue(props.description ? null : '')}
      >
        <Modal.Header closeButton>
          <Modal.Title className="flex-container">
            {props.entity.toLowerCase() === 'other'
              ? 'Description'
              : `${string.capitalize(props.entity)} Description`}
            {props.additionalTitleControl}
          </Modal.Title>
          <ModalIcon icon="note-sticky" color="blue" bold />
        </Modal.Header>
        <DescriptionBox
          editorValue={editorValue}
          onSetEditorValue={setEditorValue}
          inModal
          readOnly={!!props.readOnly}
          description={props.description}
          onSave={handleSubmit}
          warning={props.warning}
          {...(props.componentId && {
            hasAllowedAi: StackFeaturesStore.hasAllowedAi(),
            componentId: props.componentId,
            configId: props.configId,
            rowId: props.rowId,
          })}
        />
      </Modal>
    </span>
  );
};

export default DescriptionModal;
