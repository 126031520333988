import AutomaticLoadTypeLastUpdated from './AutomaticLoadTypeLastUpdated';
import changedSinceConstants from './changedSinceConstants';
import Select from './Select';
import timeOptionCreator from './timeOptionCreator';

const selectOptions = [
  { label: '10 minutes', value: '-10 minutes' },
  { label: '15 minutes', value: '-15 minutes' },
  { label: '30 minutes', value: '-30 minutes' },
  { label: '45 minutes', value: '-45 minutes' },
  { label: '1 hour', value: '-1 hours' },
  { label: '2 hours', value: '-2 hours' },
  { label: '4 hours', value: '-4 hours' },
  { label: '6 hours', value: '-6 hours' },
  { label: '12 hours', value: '-12 hours' },
  { label: '18 hours', value: '-18 hours' },
  { label: '1 day', value: '-1 days' },
  { label: '2 days', value: '-2 days' },
  { label: '3 days', value: '-3 days' },
  { label: '7 days', value: '-7 days' },
  { label: '15 days', value: '-15 days' },
  { label: '30 days', value: '-30 days' },
];

type Props = {
  onChange: () => void;
  value?: string;
  disabled: boolean;
  withAdaptiveOption?: boolean;
  helpBlock?: string;
  tableId?: string;
  id?: string;
};

const ChangedSinceInput = ({
  onChange,
  value,
  id,
  disabled,
  withAdaptiveOption,
  helpBlock,
  tableId = '',
}: Props) => {
  const getSelectOptions = () => {
    const options = [...selectOptions];

    if (withAdaptiveOption || value === changedSinceConstants.ADAPTIVE_VALUE) {
      options.unshift({
        label: changedSinceConstants.ADAPTIVE_LABEL,
        value: changedSinceConstants.ADAPTIVE_VALUE,
      });
    }

    if (!value) {
      return options;
    }

    if (options.filter((item) => item.value === value).length === 0) {
      options.push({
        label: value.replace('-', ''),
        value: value,
      });
    }

    return options;
  };

  const newOptionCreator = (inputValue: string) => {
    const option = timeOptionCreator(inputValue);

    return {
      label: option,
      value: '-' + option,
    };
  };

  const promptTextCreator = (label: string) => {
    const option = timeOptionCreator(label);
    if (option === false) {
      return 'Invalid range';
    }
    return option;
  };

  return (
    <div>
      <Select
        id={id}
        allowCreate
        placeholder="Select range"
        noResultsText="Invalid range"
        value={value}
        disabled={disabled}
        onChange={onChange}
        options={getSelectOptions()}
        newOptionCreator={newOptionCreator}
        isValidNewOption={(inputValue) => timeOptionCreator(inputValue) !== false}
        promptTextCreator={promptTextCreator}
      />
      {tableId !== '' && value === changedSinceConstants.ADAPTIVE_VALUE && (
        <AutomaticLoadTypeLastUpdated tableId={tableId} />
      )}
      <span className="help-block">
        Type in any range, e.g., <code>13 hours</code>. We support these time dimensions:{' '}
        <code>minutes</code>, <code>hours</code> and <code>days</code>.
        {helpBlock && <span> {helpBlock}</span>}
      </span>
    </div>
  );
};

export default ChangedSinceInput;
