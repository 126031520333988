import { routeNames } from '@/modules/data-catalog/constants';
import type { SourceTab } from '@/modules/data-catalog/types';
import { TabLink, TabNav } from '@/react/common';

type Props = {
  sourceTab?: SourceTab;
};

export const TabNavigation = ({ sourceTab }: Props) => (
  <div className="tabs-with-border-wrapper">
    <TabNav>
      <TabLink to={routeNames.SHARED_WITH_YOU} active={sourceTab === routeNames.SHARED_WITH_YOU}>
        Shared with you
      </TabLink>
      <TabLink
        to={routeNames.SHARED_FROM_THIS_PROJECT}
        active={sourceTab === routeNames.SHARED_FROM_THIS_PROJECT}
      >
        Shared from this project
      </TabLink>
    </TabNav>
  </div>
);
