import Promise from 'bluebird';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import dispatcher from '@/Dispatcher';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import Api from '@/modules/components/InstalledComponentsApi';
import ConfigurationCopiedNotification from '@/modules/components/react/components/ConfigurationCopiedNotification';
import ApplicationStore from '@/stores/ApplicationStore';
import Constants from './RowVersionsConstants';
import Store from './RowVersionsStore';

export default {
  loadVersions: function (componentId, configId, rowId) {
    if (Store.hasVersions(componentId, configId, rowId)) {
      this.loadVersionsForce(componentId, configId, rowId);
      return Promise.resolve();
    }
    return this.loadVersionsForce(componentId, configId, rowId);
  },

  loadVersionsForce: function (componentId, configId, rowId) {
    dispatcher.handleViewAction({
      componentId: componentId,
      configId: configId,
      rowId: rowId,
      type: Constants.ActionTypes.ROW_VERSIONS_LOAD_START,
    });
    return Api.getComponentConfigRowVersions(componentId, configId, rowId)
      .then(function (result) {
        dispatcher.handleViewAction({
          componentId: componentId,
          configId: configId,
          rowId: rowId,
          type: Constants.ActionTypes.ROW_VERSIONS_LOAD_SUCCESS,
          versions: result,
        });
        return result;
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          componentId: componentId,
          configId: configId,
          rowId: rowId,
          type: Constants.ActionTypes.ROW_VERSIONS_LOAD_ERROR,
        });
        throw error;
      });
  },

  loadComponentConfigByVersion(componentId, configId, rowId, version) {
    if (Store.hasConfigByVersion(componentId, configId, rowId, version)) {
      return Promise.resolve();
    }
    return this.loadComponentConfigByVersionForce(componentId, configId, rowId, version);
  },

  loadComponentConfigByVersionForce(componentId, configId, rowId, version) {
    this.pendingStart(componentId, configId, rowId, version, 'config');
    return Api.getComponentConfigRowByVersion(componentId, configId, rowId, version)
      .then((result) => {
        dispatcher.handleViewAction({
          componentId: componentId,
          configId: configId,
          rowId: rowId,
          version: version,
          data: result,
          type: Constants.ActionTypes.ROW_VERSIONS_CONFIG_LOAD_SUCCESS,
        });
      })
      .finally(() => {
        this.pendingStop(componentId, configId, rowId);
      });
  },

  loadTwoComponentConfigVersions(componentId, configId, rowId, version1, version2) {
    dispatcher.handleViewAction({
      componentId: componentId,
      configId: configId,
      rowId: rowId,
      pivotVersion: version1,
      type: Constants.ActionTypes.ROW_VERSIONS_MULTI_PENDING_START,
    });
    return Promise.all([
      this.loadComponentConfigByVersion(componentId, configId, rowId, version1),
      version2 && this.loadComponentConfigByVersion(componentId, configId, rowId, version2),
    ]).finally(() => {
      dispatcher.handleViewAction({
        componentId: componentId,
        configId: configId,
        rowId: rowId,
        pivotVersion: version1,
        type: Constants.ActionTypes.ROW_VERSIONS_MULTI_PENDING_STOP,
      });
    });
  },

  rollbackVersion: function (componentId, configId, rowId, version, reloadCallback) {
    this.pendingStart(componentId, configId, rowId, version, 'rollback');
    return Api.rollbackRowVersion(componentId, configId, rowId, version).then(() => {
      return Promise.all([
        this.loadVersionsForce(componentId, configId, rowId),
        ...reloadCallback(),
      ])
        .then(() => {
          ApplicationActionCreators.sendNotification({
            type: 'success',
            message: 'Configuration rollback successful.',
          });
        })
        .finally(() => {
          this.pendingStop(componentId, configId, rowId);
        });
    });
  },

  copyVersion: function (componentId, configId, rowId, version, name) {
    this.pendingStart(componentId, configId, rowId, version, 'copy');
    return Api.createConfigRowCopy(componentId, configId, rowId, version)
      .tap((response) =>
        Api.updateConfigurationRow(componentId, configId, response.id, { name }, 'Change name'),
      )
      .tap((response) => {
        return InstalledComponentsActionCreators.loadComponentConfigsData(componentId).then(() => {
          ApplicationActionCreators.sendNotification({
            type: 'success',
            message: (props) => (
              <ConfigurationCopiedNotification
                componentId={componentId}
                configId={configId}
                rowId={response.id}
                name={name}
                onClick={props.onClick}
                hasFlows={ApplicationStore.hasFlows()}
              />
            ),
          });
        });
      })
      .finally(() => this.pendingStop(componentId, configId, version, 'copy'));
  },

  pendingStart: function (componentId, configId, rowId, version, action) {
    dispatcher.handleViewAction({
      componentId: componentId,
      configId: configId,
      rowId: rowId,
      version: version,
      action: action,
      type: Constants.ActionTypes.ROW_VERSIONS_PENDING_START,
    });
  },

  pendingStop: function (componentId, configId, rowId) {
    dispatcher.handleViewAction({
      componentId: componentId,
      configId: configId,
      rowId: rowId,
      type: Constants.ActionTypes.ROW_VERSIONS_PENDING_STOP,
    });
  },
};
