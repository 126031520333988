import { AddWorkspaceIcon } from '@/react/common/icons';
import { TableIconAction } from './TableIconAction';

type Props = {
  onClick: () => void;
  tooltip?: string | null;
  disabled?: boolean;
};

export const CreateWorkspace = ({ onClick, tooltip = null, disabled = false }: Props) => (
  <TableIconAction
    tooltip={tooltip || 'Create new workspace'}
    onClick={onClick}
    disabled={disabled}
    icon={<AddWorkspaceIcon />}
  />
);
