import { cn, Icon } from '@keboola/design';

import Loader from '@/react/common/Loader';

const PhaseOutput = (props: { continueOnFailure: boolean; isLoading: boolean }) => {
  return (
    <div className="phase-output-label no-wrap">
      {props.isLoading ? (
        <Loader className="color-main" />
      ) : (
        <Icon
          icon={props.continueOnFailure ? 'rotate-exclamation' : 'down-from-dotted-line'}
          className={cn('f-16', props.continueOnFailure ? 'color-danger' : 'color-muted')}
        />
      )}
      {props.continueOnFailure ? 'Continue on Failure' : 'All to Finish'}
    </div>
  );
};

export default PhaseOutput;
