import type { ChangeEvent } from 'react';
import { FormControl } from 'react-bootstrap';
import type { List, Map } from 'immutable';

import { FormGroup, Label, TextInput } from '@keboola/design';

import { DEFAULT_RETRY_CODES } from '@/modules/ex-generic/constants';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import Select from '@/react/common/Select';
import DocumentationLink from './DocumentationLink';

const Retry = (props: {
  readOnly: boolean;
  editing: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
}) => {
  return (
    <CollapsibleBox
      title="Retry Config"
      titleSuffix={<DocumentationLink path="configuration/api/#retry-configuration" />}
      childrenClassName="tw-flex tw-flex-col tw-gap-4"
    >
      <div className="tw-grid tw-grid-cols-4 tw-items-start tw-gap-4">
        <FormGroup className="tw-col-span-3">
          <Label htmlFor="retry-header">Retry Header</Label>
          <TextInput
            id="retry-header"
            variant="secondary"
            disabled={props.readOnly}
            value={props.editing.get('retryHeader')}
            onChange={(value) => {
              props.setEditing(props.editing.set('retryHeader', value));
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="maxRetries">Max Retries</Label>
          <FormControl
            id="maxRetries"
            min={0}
            type="number"
            disabled={props.readOnly}
            value={props.editing.get('maxRetries')}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const maxRetries = parseInt(e.target.value, 10) || 0;
              props.setEditing(props.editing.set('maxRetries', maxRetries));
            }}
          />
        </FormGroup>
      </div>
      <FormGroup>
        <Label htmlFor="retry-codes">Retry Codes</Label>
        <Select
          id="retry-codes"
          multi
          allowCreate
          placeholder="Select codes"
          disabled={props.readOnly}
          promptTextCreator={(label) => `Create code "${label}"`}
          isValidNewOption={(value: string) => {
            const code = parseInt(value, 10);
            return code >= 400 && code < 600;
          }}
          value={props.editing.get('codes')}
          onChange={(codes: List<string | number>) => {
            props.setEditing(props.editing.set('codes', codes.map(Number)));
          }}
          options={DEFAULT_RETRY_CODES.map((value) => ({ label: value, value }))}
        />
      </FormGroup>
    </CollapsibleBox>
  );
};

export default Retry;
