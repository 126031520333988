import { Component } from 'react';
import PropTypes from 'prop-types';

import Select from '@/react/common/Select';
import { REDSHIFT_COMPRESSION_OPTIONS } from './constants';

class RedshiftCompressionHeader extends Component {
  render() {
    return (
      <td>
        <Select
          className="w-150"
          value={this.props.value}
          onChange={this.props.onChange}
          options={REDSHIFT_COMPRESSION_OPTIONS.map((value) => ({ label: value, value }))}
        />
      </td>
    );
  }
}

RedshiftCompressionHeader.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default RedshiftCompressionHeader;
