import { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

import TableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';

class TablesList extends Component {
  render() {
    if (this.props.tables.get('tables', List()).isEmpty()) {
      return <div className="font-mono text-muted">No tables.</div>;
    }

    return (
      <div className="params-list">
        <ul className="overflow-break-anywhere">
          {this.props.tables
            .get('tables')
            .sortBy((table) => -table.get('durationTotalSeconds'))
            .toSeq()
            .slice(0, this.props.listItemsLimit)
            .map((table) => (
              <li key={table.get('id')}>
                <TableLinkEx tableId={table.get('id')} />
              </li>
            ))
            .toArray()}
        </ul>
      </div>
    );
  }
}

TablesList.propTypes = {
  tables: PropTypes.instanceOf(Map).isRequired,
  listItemsLimit: PropTypes.number.isRequired,
};

export default TablesList;
