import type { ReactNode } from 'react';

const PAGE_HEADER_HEIGHT = 530;

type Props = {
  children: ReactNode;
};

export const Logs = ({ children }: Props) => (
  <pre
    className="tw-font-mono tw-min-h-96 tw-rounded tw-border-none tw-bg-neutral-100 tw-p-3 tw-text-sm tw-font-normal"
    style={{ maxHeight: `calc(100vh - ${PAGE_HEADER_HEIGHT}px)` }}
  >
    {children}
  </pre>
);
