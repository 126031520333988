import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { fromJS } from 'immutable';

import { Icon } from '@keboola/design';

import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import TaskParametersEditModal from '@/modules/orchestrations/react/modals/TaskParametersEdit';
import { RowActionDropdown, RowActionMenuItem } from '@/react/common';
import Checkbox from '@/react/common/Checkbox';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';

const TasksEditTableRow = createReactClass({
  propTypes: {
    task: PropTypes.object.isRequired,
    component: PropTypes.object,
    disabled: PropTypes.bool.isRequired,
    onTaskDelete: PropTypes.func.isRequired,
    onTaskUpdate: PropTypes.func.isRequired,
    toggleMarkTask: PropTypes.func.isRequired,
    isMarked: PropTypes.bool.isRequired,
  },

  render() {
    return (
      <tr className="hoverable-actions">
        <td>
          <Checkbox
            tooltip="Select task to move between phases"
            checked={this.props.isMarked}
            onChange={() => this.props.toggleMarkTask(this.props.task.get('id'))}
          />
        </td>
        <td>
          {this.props.component && (
            <ComponentIcon
              component={this.props.component}
              className="icon-addon-right"
              size="32"
            />
          )}
          {this.props.component ? (
            <ComponentName component={this.props.component} showType />
          ) : (
            this.props.task.get('componentUrl')
          )}
        </td>
        <td>
          {this.props.task.has('config') ? (
            <ComponentConfigurationLink
              componentId={this.props.task.get('component')}
              configId={this.props.task.getIn(['config', 'id'])}
              hasFlows={false}
            >
              {this.props.task.getIn(['config', 'name'])}
            </ComponentConfigurationLink>
          ) : (
            'N/A'
          )}
        </td>
        <td>
          <FormControl
            type="text"
            value={this.props.task.get('action')}
            disabled={this.props.disabled}
            onChange={this._handleActionChange}
          />
        </td>
        <td>
          <Checkbox
            disabled={this.props.disabled}
            checked={this.props.task.get('active')}
            onChange={this._handleActiveChange}
          />
        </td>
        <td>
          <Checkbox
            disabled={this.props.disabled}
            checked={this.props.task.get('continueOnFailure')}
            onChange={this._handleContinueOnFailureChange}
          />
        </td>
        <td className="pr-1">
          <RowActionDropdown>
            <TaskParametersEditModal
              isEditable
              onSet={this._handleParametersChange}
              parameters={this.props.task.get('actionParameters').toJS()}
            />
            <RowActionMenuItem divider />
            <RowActionMenuItem onSelect={this._handleDelete}>
              <Icon icon="trash" fixedWidth />
              Remove task
            </RowActionMenuItem>
          </RowActionDropdown>
        </td>
      </tr>
    );
  },

  _handleParametersChange(parameters) {
    return this.props.onTaskUpdate(this.props.task.set('actionParameters', fromJS(parameters)));
  },

  _handleActionChange(event) {
    return this.props.onTaskUpdate(this.props.task.set('action', event.target.value.trim()));
  },

  _handleActiveChange() {
    return this.props.onTaskUpdate(this.props.task.set('active', !this.props.task.get('active')));
  },

  _handleContinueOnFailureChange() {
    return this.props.onTaskUpdate(
      this.props.task.set('continueOnFailure', !this.props.task.get('continueOnFailure')),
    );
  },

  _handleDelete() {
    return this.props.onTaskDelete(this.props.task.get('id'));
  },
});

export default TasksEditTableRow;
