import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import type BluebirdPromise from 'bluebird';
import type { Map } from 'immutable';

import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import DevBranchStorageWarning from './DevBranchStorageWarning';

type Props = {
  onConfirm: () => BluebirdPromise<unknown>;
  onHide: () => void;
  table: Map<string, any>;
  show: boolean;
};

const TruncateTableModal = (props: Props) => {
  const [isPending, setIsPending] = useState(false);

  const handleConfirm = () => {
    setIsPending(true);
    props.onConfirm().finally(() => {
      setIsPending(false);
      props.onHide();
    });
  };

  return (
    <Modal onHide={props.onHide} show={props.show}>
      <Modal.Header closeButton>
        <Modal.Title>Truncate Table</Modal.Title>
        <ModalIcon color="red" icon="xmark" bold />
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to truncate the table <b>{props.table.get('displayName')}</b>?
        </p>
        <DevBranchStorageWarning
          message="The table will also be truncated in production."
          hasProductionEntity={
            props.table.has('bucket') && !isCreatedInDevBranch(props.table.get('bucket'))
          }
        />
        <p>
          The TRUNCATE TABLE command removes all rows from the table but retains its structure,
          including columns. Table statistics will also be reset.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <ConfirmButtons
          block
          isSaving={isPending}
          saveLabel={isPending ? 'Truncating table...' : 'Truncate table'}
          saveStyle="danger"
          onSave={handleConfirm}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default TruncateTableModal;
