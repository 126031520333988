import { sortEntities } from '@/constants';
import { SortableHeader } from '@/modules/data-catalog/react/tableComponents/SortableHeader';
import type { FlatTableRow, SetSortType, SortType } from '@/modules/data-catalog/types';
import { FileSize } from '@/react/common';

type Props = {
  sort: SortType;
  setSort: SetSortType;
};

export const sizeColumn = ({ sort, setSort }: Props) => ({
  id: 'size',
  header: () => (
    <SortableHeader label="Size" entityKey={sortEntities.SIZE} sort={sort} setSort={setSort} />
  ),
  cell: ({ row }: { row: FlatTableRow }) => (
    <span className="text-muted">
      <FileSize size={row.original.get('dataSizeBytes')} />
    </span>
  ),
});
