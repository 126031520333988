import type { ComponentProps, ReactNode } from 'react';
import { Button } from 'react-bootstrap';

import { Icon, Tooltip } from '@keboola/design';

type Props = {
  tooltip: string;
  onClick: () => void;
  disabled?: boolean;
} & IconProps;

type IconProps =
  | { libraryIcon: ComponentProps<typeof Icon>['icon']; icon?: never }
  | { icon: ReactNode; libraryIcon?: never };

export const TableIconAction = ({ tooltip, libraryIcon, icon, ...buttonProps }: Props) => (
  <Tooltip placement="top" tooltip={tooltip} triggerClassName="tw-align-middle">
    <Button {...buttonProps} bsStyle="link" className={'btn-link-inline btn-link-muted'}>
      {libraryIcon ? <Icon icon={libraryIcon} fixedWidth /> : icon}
    </Button>
  </Tooltip>
);
