import { HelpBlock } from '@keboola/design';

const EncryptedPropertiesHelpBlock = () => {
  return (
    <HelpBlock className="tw-mt-1">
      Properties prefixed with <code>#</code> sign will be encrypted on save. Already encrypted
      strings will persist.
    </HelpBlock>
  );
};

export default EncryptedPropertiesHelpBlock;
