import ImmutableRenderMixin from 'react-immutable-render-mixin';
import InfiniteScroll from 'react-infinite-scroller';
import createReactClass from 'create-react-class';

import { ButtonGroup, IconButton, SearchForm, Tooltip } from '@keboola/design';

import { canDownloadSlicedFile } from '@/modules/admin/privileges';
import FilesStore from '@/modules/components/stores/StorageFilesStore';
import BlockButton from '@/react/common/BlockButton';
import Loader from '@/react/common/Loader';
import NoResultsFound from '@/react/common/NoResultsFound';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import ExamplesModal from './components/FilesSearchExamplesModal';
import FilesTable from './components/FilesTable';
import StorageTabs from './components/StorageTabs';
import { deleteFile, filterFiles, loadMoreFiles, updateFilesSearchQuery } from './actions';
import { routeNames } from './constants';

const Files = createReactClass({
  mixins: [ImmutableRenderMixin, createStoreMixin(ApplicationStore, RoutesStore, FilesStore)],

  getStateFromStores() {
    return {
      files: FilesStore.getAll(),
      hasMore: FilesStore.hasMore(),
      searchQuery: RoutesStore.getRouterState().getIn(['location', 'query', 'q']),
      downloadingFiles: FilesStore.getDownloadingFiles(),
      isLoading: FilesStore.getIsLoading(),
      isLoadingMore: FilesStore.getIsLoadingMore(),
      isDeleting: FilesStore.getIsDeleting(),
      isReadOnly: ApplicationStore.isReadOnly(),
      canDownloadSlicedFile: canDownloadSlicedFile(ApplicationStore.getSapiToken()),
      hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      admins: ApplicationStore.getAdmins(),
      canEnableDataStreams: ApplicationStore.canEnableDataStreams(),
    };
  },

  componentWillUnmount() {
    updateFilesSearchQuery('');
  },

  getInitialState() {
    return {
      openExamplesModal: false,
    };
  },

  render() {
    return (
      <StorageTabs
        activeTab={routeNames.FILES}
        hasProtectedDefaultBranch={this.state.hasProtectedDefaultBranch}
        hasPayAsYouGo={this.state.hasPayAsYouGo}
        canEnableDataStreams={this.state.canEnableDataStreams}
      >
        <SearchForm
          name="files-search"
          placeholder='Search: tags:"tag" or click on the tag'
          value={this.state.searchQuery}
          onSubmit={filterFiles}
          suffix={
            <ButtonGroup>
              <Tooltip tooltip="Search syntax &amp; Examples" placement="top">
                <IconButton
                  variant="outline"
                  size="small"
                  onClick={this.openExamplesModal}
                  icon="circle-question"
                />
              </Tooltip>
            </ButtonGroup>
          }
          className="tw-mb-6"
        />
        {this.renderFiles()}
        <ExamplesModal
          show={this.state.openExamplesModal}
          onHide={this.closeExamplesModal}
          onSelectExample={filterFiles}
        />
      </StorageTabs>
    );
  },

  renderFiles() {
    if (!this.state.files.count() && this.state.isLoading) {
      return (
        <div className="box">
          <div className="box-content">
            <Loader /> Loading files...
          </div>
        </div>
      );
    }

    if (!this.state.files.count()) {
      return <NoResultsFound entityName="files" />;
    }

    return (
      <div className="box">
        <InfiniteScroll
          initialLoad={false}
          loadMore={this.fetchMoreFiles}
          hasMore={this.state.hasMore}
        >
          <FilesTable
            readOnly={this.state.isReadOnly}
            files={this.state.files}
            downloadingFiles={this.state.downloadingFiles}
            admins={this.state.admins}
            canDownloadSlicedFile={this.state.canDownloadSlicedFile}
            onSearchQuery={filterFiles}
            onDeleteFile={deleteFile}
            isDeleting={this.state.isDeleting}
            searchQuery={this.state.searchQuery}
          />
        </InfiniteScroll>
        {this.renderMoreButton()}
      </div>
    );
  },

  renderMoreButton() {
    if (!this.state.files || !this.state.hasMore) {
      return null;
    }

    return <BlockButton onClick={this.fetchMoreFiles} isLoading={this.state.isLoadingMore} />;
  },

  openExamplesModal() {
    this.setState({
      openExamplesModal: true,
    });
  },

  closeExamplesModal() {
    this.setState({
      openExamplesModal: false,
    });
  },

  fetchMoreFiles() {
    if (this.state.isLoadingMore) return;

    return loadMoreFiles({
      offset: this.state.files.count(),
      ...(this.state.searchQuery && { q: this.state.searchQuery }),
    });
  },
});

export default Files;
