import PropTypes from 'prop-types';
import { FormControl, InputGroup } from 'react-bootstrap';

import { HelpBlock } from '@keboola/design';

const SourceSearchInEditor = ({ inputSearch, id }) => {
  return (
    <>
      <InputGroup>
        <InputGroup.Addon>
          <small>{inputSearch.get('key', '')}</small>
        </InputGroup.Addon>
        <FormControl id={id} value={inputSearch.get('value', '')} disabled readOnly />
      </InputGroup>
      <HelpBlock className="tw-mt-1">Table will be searched by its metadata</HelpBlock>
    </>
  );
};

SourceSearchInEditor.propTypes = {
  inputSearch: PropTypes.object.isRequired,
  id: PropTypes.string,
};

export default SourceSearchInEditor;
