import createReactClass from 'create-react-class';
import { List } from 'immutable';

import { URLS } from '@keboola/constants';
import { cn, Icon, Tabs, TabsContent } from '@keboola/design';

import { ORCHESTRATOR } from '@/constants/componentIds';
import { SIDEBAR } from '@/constants/external';
import { canManageTokens } from '@/modules/admin/privileges';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import DocumentationLink from '@/modules/components/react/components/DocumentationLink';
import SidebarVersions from '@/modules/components/react/components/SidebarVersions';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import VersionsStore from '@/modules/components/stores/VersionsStore';
import EventTriggersStore from '@/modules/event-trigger/EventTriggersStore';
import { routeNames as orchestrationsRouteNames } from '@/modules/orchestrations/Constants';
import OrchestrationActiveButton from '@/modules/orchestrations/react/components/OrchestrationActiveButton';
import OrchestrationDeleteButton from '@/modules/orchestrations/react/components/OrchestrationDeleteButton';
import OrchestrationRunButton from '@/modules/orchestrations/react/components/OrchestrationRunButton';
import ScheduleEdit from '@/modules/orchestrations/react/modals/Schedule';
import OrchestrationJobsStore from '@/modules/orchestrations/stores/OrchestrationJobsStore';
import OrchestrationStore from '@/modules/orchestrations/stores/OrchestrationsStore';
import JobsTable from '@/modules/orchestrations-v2/components/JobsTable';
import JobsGraph from '@/modules/queue/components/JobsGraph';
import { JOBS_LIMIT_FOR_GRAPH } from '@/modules/queue/constants';
import { prepareGraphData } from '@/modules/queue/helpers';
import CronRecord from '@/modules/scheduler/components/CronRecord';
import { routeNames as projectSettingsRouteNames } from '@/modules/settings/constants';
import { RouterLink as Link } from '@/react/common';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import {
  getOrchestrationJobsByType,
  ORCHESTRATION_JOB_TYPE_FAILED,
  ORCHESTRATION_JOB_TYPE_SUCCESSFUL,
} from './jobsFilter';
import TasksSummary from './TasksSummary';

const OrchestrationDetail = createReactClass({
  mixins: [
    createStoreMixin(
      RoutesStore,
      ComponentsStore,
      OrchestrationStore,
      OrchestrationJobsStore,
      VersionsStore,
      StorageTablesStore,
      StorageBucketsStore,
      EventTriggersStore,
      ApplicationStore,
    ),
  ],

  getStateFromStores() {
    const orchestrationIdString = RoutesStore.getCurrentRouteParam('orchestrationId');
    const orchestrationId = parseInt(orchestrationIdString, 10);
    const jobs = OrchestrationJobsStore.getOrchestrationJobs(orchestrationId);
    const trigger = EventTriggersStore.getSingle(ORCHESTRATOR, orchestrationIdString);

    return {
      component: ComponentsStore.getComponent(ORCHESTRATOR),
      orchestration: OrchestrationStore.get(orchestrationId),
      tasksToRun: OrchestrationStore.getTasksToRun(orchestrationId),
      phases: OrchestrationStore.getOrchestrationTasks(orchestrationId),
      isLoading: OrchestrationStore.getIsOrchestrationLoading(orchestrationId),
      jobs,
      pendingActions: OrchestrationStore.getPendingActionsForOrchestration(orchestrationId),
      tables: StorageTablesStore.getAll(),
      buckets: StorageBucketsStore.getAll(),
      trigger: trigger,
      sapiTokenCanManageTokens: canManageTokens(ApplicationStore.getSapiToken()),
      readOnly: ApplicationStore.isReadOnly(),
      admins: ApplicationStore.getAdmins(),
      versions: VersionsStore.getVersions(ORCHESTRATOR, orchestrationIdString),
      versionsConfigs: VersionsStore.getVersionsConfigs(ORCHESTRATOR, orchestrationIdString),
      isLoadingVersions: VersionsStore.isLoadingVersions(ORCHESTRATOR, orchestrationIdString),
      isPendingVersions: VersionsStore.isPendingConfig(ORCHESTRATOR, orchestrationIdString),
      pendingMultiLoadVersions: VersionsStore.getPendingMultiLoad(
        ORCHESTRATOR,
        orchestrationIdString,
      ),
    };
  },

  getInitialState() {
    return {
      activeTab: 'all-runs',
    };
  },

  UNSAFE_componentWillReceiveProps() {
    return this.setState(this.getStateFromStores());
  },

  render() {
    return (
      <div className="row">
        <div className="col-sm-9">
          <ComponentDescription
            componentId={ORCHESTRATOR}
            configId={this.state.orchestration.get('id').toString()}
            placeholderEntity="orchestrator"
          />
          <div className="box info-row">
            {this.renderTasks()}
            {this.renderSchedule()}
            {this.renderNotifications()}
          </div>
          <Tabs
            value={this.state.activeTab}
            onValueChange={this.handleSelectTab}
            triggers={[
              {
                value: 'all-runs',
                title: 'All Runs',
              },
              {
                value: 'successful-runs',
                title: 'Successful',
              },
              {
                value: 'failed-runs',
                title: 'Failed',
              },
            ]}
          >
            {this.renderTab('all-runs', '')}
            {this.renderTab('successful-runs', ORCHESTRATION_JOB_TYPE_SUCCESSFUL)}
            {this.renderTab('failed-runs', ORCHESTRATION_JOB_TYPE_FAILED)}
          </Tabs>
        </div>
        <div className="col-sm-3">
          <div className={`sidebar-content ${SIDEBAR}`}>
            {!this.state.readOnly && (
              <ul className="nav nav-stacked">
                <li>
                  <OrchestrationRunButton
                    buttonBlock
                    tasks={this.state.tasksToRun}
                    orchestration={this.state.orchestration}
                  />
                  <hr />
                </li>
                <li className={cn({ disabled: !this.state.sapiTokenCanManageTokens })}>
                  <OrchestrationActiveButton
                    orchestration={this.state.orchestration}
                    isPending={this.state.pendingActions.get('active', false)}
                    buttonDisabled={!this.state.sapiTokenCanManageTokens}
                    mode="link"
                  />
                </li>
                <li>
                  <DocumentationLink href={`${URLS.USER_DOCUMENTATION}/orchestrator/running/`} />
                </li>
                <li>
                  <hr />
                  <OrchestrationDeleteButton
                    buttonBlock
                    orchestration={this.state.orchestration}
                    isPending={this.state.pendingActions.get('delete', false)}
                  />
                </li>
              </ul>
            )}
            <div className="sidebar-box">
              <h4>Assigned token</h4>
              <Link
                to={projectSettingsRouteNames.TOKEN_DETAIL}
                params={{ tokenId: this.state.orchestration.getIn(['token', 'id']) }}
                className="text-muted"
              >
                <Icon icon="toolbox" className="icon-addon-right" />
                {this.state.orchestration.getIn(['token', 'description'])}
              </Link>
            </div>
            <hr />
            <SidebarVersions
              component={this.state.component}
              configId={this.state.orchestration.get('id').toString()}
              versionsLinkTo={orchestrationsRouteNames.VERSIONS}
              versionsLinkParams={{ orchestrationId: this.state.orchestration.get('id') }}
              versions={this.state.versions}
              versionsConfigs={this.state.versionsConfigs}
              isLoading={this.state.isLoadingVersions}
              isPending={this.state.isPendingVersions}
              pendingMultiLoad={this.state.pendingMultiLoadVersions}
              admins={this.state.admins}
            />
          </div>
        </div>
      </div>
    );
  },

  renderTab(tabName, jobTypeFilter) {
    let jobs = this.state.jobs;

    if (jobTypeFilter) {
      jobs = getOrchestrationJobsByType(jobs, jobTypeFilter);
    }

    return (
      <TabsContent value={tabName}>
        <div>
          {this.renderGraph(tabName, jobTypeFilter)}
          <JobsTable
            orchestration={this.state.orchestration}
            jobs={jobs.toList()}
            admins={this.state.admins}
          />
        </div>
      </TabsContent>
    );
  },

  renderGraph(tabName, jobTypeFilter) {
    if (tabName !== this.state.activeTab) {
      return null;
    }

    let jobs = this.state.jobs;

    if (jobTypeFilter) {
      jobs = getOrchestrationJobsByType(jobs, jobTypeFilter);
    }

    const graphData = prepareGraphData(jobs);

    if (!graphData || graphData.isEmpty()) {
      return null;
    }

    return <JobsGraph data={graphData} minEntries={JOBS_LIMIT_FOR_GRAPH} />;
  },

  renderTasks() {
    return (
      <div className="info-row-section">
        <h4 className="first-line">
          Tasks
          <Link
            className="btn-link-inline btn-link-muted icon-addon-left"
            to={orchestrationsRouteNames.TASKS}
            params={{
              orchestrationId: this.state.orchestration.get('id'),
            }}
          >
            <Icon icon="pen" />
          </Link>
        </h4>
        <p className="last-line text-muted">
          <TasksSummary phases={this.state.phases} />
        </p>
      </div>
    );
  },

  renderSchedule() {
    return (
      <div className="info-row-section">
        <h4 className="first-line">
          {this.state.trigger.count() ? 'Event Trigger' : 'Schedule'}
          <ScheduleEdit
            orchestrationId={this.state.orchestration.get('id')}
            crontabRecord={this.state.orchestration.get('crontabRecord')}
            crontabTimezone={this.state.orchestration.get('crontabTimezone')}
            tables={this.state.tables}
            buckets={this.state.buckets}
            trigger={this.state.trigger}
            isDisabled={!this.state.sapiTokenCanManageTokens}
          />
        </h4>
        <div className="last-line text-muted">
          {this.state.trigger.count() ? this.renderEventTrigger() : this.renderCronRecord()}
        </div>
      </div>
    );
  },

  renderNotifications() {
    if (!this.state.orchestration.get('notifications').count()) {
      return (
        <div className="info-row-section">
          <Link
            className="btn btn-primary"
            to={orchestrationsRouteNames.NOTIFICATIONS}
            params={{
              orchestrationId: this.state.orchestration.get('id'),
            }}
          >
            <Icon icon="pen" className="icon-addon-right" />
            Configure notifications
          </Link>
        </div>
      );
    }

    return (
      <div className="info-row-section">
        <h4 className="first-line">
          Notifications
          <Link
            className="btn-link-inline btn-link-muted icon-addon-left"
            to={orchestrationsRouteNames.NOTIFICATIONS}
            params={{
              orchestrationId: this.state.orchestration.get('id'),
            }}
          >
            <Icon icon="pen" />
          </Link>
        </h4>
        <p className="last-line text-muted">
          {this.state.orchestration.get('notifications').count()} notifications set
        </p>
      </div>
    );
  },

  renderEventTrigger() {
    const tables = this.state.trigger.get('tables', List()).count();
    const period = this.state.trigger.get('coolDownPeriodMinutes', 0);

    return (
      <div>
        <span>Tables to check: {tables}</span>
        <br />
        <span>Cooldown period: {period} minutes</span>
      </div>
    );
  },

  renderCronRecord() {
    return (
      <CronRecord
        crontabRecord={this.state.orchestration.get('crontabRecord')}
        crontabTimezone={this.state.orchestration.get('crontabTimezone')}
      />
    );
  },

  handleSelectTab(eventKey) {
    this.setState({ activeTab: eventKey });
  },
});

export default OrchestrationDetail;
