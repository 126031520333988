import { cn } from '@keboola/design';

import Loader from './Loader';

type Props = {
  label?: string;
  className?: string;
};

const LoadingBlock = ({ label, className }: Props) => (
  <div
    className={cn(
      'tw-flex tw-h-7 tw-flex-wrap tw-items-center tw-justify-center tw-bg-white tw-text-neutral-400',
      className,
    )}
  >
    <Loader className="icon-addon-right" />
    {label || 'Loading...'}
  </div>
);

export default LoadingBlock;
