import { Component } from 'react';
import type { FormEvent, MouseEvent } from 'react';
import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { List, Map } from 'immutable';

import { Button, ButtonGroup, ButtonInline, Icon } from '@keboola/design';

import { BaseTypes, MetadataKeys } from '@/modules/components/MetadataConstants';
import { getDataType, isLengthSupported } from '@/modules/components/utils/datatypeHelpers';
import { getLastActiveDatatypeProvider } from '@/modules/components/utils/tableMetadataHelper';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { Truncated } from '@/react/common';
import Checkbox from '@/react/common/Checkbox';
import ComponentName from '@/react/common/ComponentName';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import DevBranchStorageWarning from './DevBranchStorageWarning';

type Props = {
  show: boolean;
  table: Map<string, any>;
  components: Map<string, any>;
  columnName: string;
  metadata: List<any>;
  saveUserType: (columnName: string, userDataType: Map<string, any>) => Promise<void>;
  deleteUserType: (columnName: string) => Promise<void>;
  onHide: () => void;
  sampleData?: Map<string, any>;
};

type State = {
  isLoading: boolean;
  isDeleting: boolean;
  userDataType: Map<string, any>;
  machineDataType: Map<string, any>;
  showMoreSampleValues: boolean;
};

class ColumnDatatypesModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      isDeleting: false,
      userDataType: Map(),
      machineDataType: Map(),
      showMoreSampleValues: false,
    };

    this.initData = this.initData.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleLengthChange = this.handleLengthChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleNullableChange = this.handleNullableChange.bind(this);
  }

  render() {
    const baseType = this.state.userDataType.get(MetadataKeys.BASE_TYPE, '');
    const length = this.state.userDataType.get(MetadataKeys.LENGTH, '');
    const isDeleteDisabled = this.isDeleteDisabled();

    return (
      <Modal show={this.props.show} onHide={this.props.onHide} onEnter={this.initData}>
        <form onSubmit={this.handleSave}>
          <Modal.Header closeButton>
            <Modal.Title>Column {this.props.columnName} Data Type</Modal.Title>
            <ModalIcon.Edit />
          </Modal.Header>
          <Modal.Body>
            <DevBranchStorageWarning
              message="The column metadata will also be modified in the production table."
              hasProductionEntity={!isCreatedInDevBranch(this.props.table.get('bucket'))}
            />
            <FormGroup>
              <ControlLabel>Type</ControlLabel>
              <Select
                autoFocus
                clearable={false}
                value={baseType}
                onChange={this.handleTypeChange}
                options={BaseTypes.map((type) => ({ label: type, value: type }))}
                disabled={this.state.isLoading || this.state.isDeleting}
              />
            </FormGroup>
            {isLengthSupported(baseType) && (
              <FormGroup>
                <ControlLabel>Length</ControlLabel>
                <FormControl
                  type="text"
                  value={length}
                  onChange={this.handleLengthChange}
                  disabled={this.state.isLoading || this.state.isDeleting}
                  placeholder={baseType === 'STRING' ? 'eg. 255' : 'eg. 38,0'}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Checkbox
                disabled={this.state.isLoading || this.state.isDeleting}
                checked={this.state.userDataType.get(MetadataKeys.NULLABLE, false)}
                onChange={this.handleNullableChange}
              >
                Nullable
              </Checkbox>
            </FormGroup>
            {this.renderSampleValues()}
            {this.renderMachineDatatype()}
          </Modal.Body>
          <Modal.Footer>
            <ButtonGroup variant="block">
              {!isDeleteDisabled && (
                <Button
                  variant="danger"
                  className="tw-text-nowrap"
                  onClick={this.handleDelete}
                  disabled={this.state.isDeleting}
                >
                  {this.state.isDeleting ? (
                    <>
                      <Loader />
                      Deleting...
                    </>
                  ) : (
                    <>
                      <Icon icon="trash" />
                      Delete user type
                    </>
                  )}
                </Button>
              )}
              <ConfirmButtons
                block
                saveButtonType="submit"
                saveLabel={this.state.isLoading ? 'Saving...' : 'Save user type'}
                isDisabled={isDeleteDisabled && !baseType}
                isSaving={this.state.isLoading}
              />
            </ButtonGroup>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  renderSampleValues() {
    return (
      <div className="well tw-p-4">
        <strong className="tw-sticky tw-top-0 tw-block tw-bg-white tw-pb-2">
          Column Sample Values
        </strong>
        {!this.props.sampleData ? (
          <p className="tw-m-0 tw-text-neutral-400">
            <Loader /> Loading data...
          </p>
        ) : (
          this.renderSampleValueData()
        )}
      </div>
    );
  }

  renderSampleValueData() {
    const columnIndex = this.props.sampleData
      ?.get('columns', List())
      .indexOf(this.props.columnName);
    const allValues = this.props.sampleData
      ?.get('rows', List())
      .map((row: Map<string, any>) => row.getIn([columnIndex, 'value']))
      .toSet()
      .toList();

    if (columnIndex === -1 || allValues.isEmpty()) {
      return <p className="tw-m-0">No data found.</p>;
    }

    const showedValued =
      allValues.count() > 3 && !this.state.showMoreSampleValues ? allValues.take(3) : allValues;

    return (
      <div className="tw-max-h-64 tw-overflow-y-auto">
        {showedValued.map((value: string, index: number) => {
          return value === '' ? (
            <div key={index}>
              <code>EMPTY STRING</code>
            </div>
          ) : (
            <Truncated className="tw-flex" key={index} text={String(value)} />
          );
        })}
        {allValues.count() > showedValued.count() && (
          <ButtonInline
            className="tw-mt-3"
            onClick={() => this.setState({ showMoreSampleValues: true })}
          >
            Show More Values
          </ButtonInline>
        )}
      </div>
    );
  }

  renderMachineDatatype() {
    const component = this.props.components.get(
      this.state.machineDataType.get(MetadataKeys.PROVIDER),
    );

    if (!component) {
      return null;
    }

    const baseType = this.state.machineDataType.get(MetadataKeys.BASE_TYPE);
    const length = this.state.machineDataType.get(MetadataKeys.LENGTH);
    const nullable = this.state.machineDataType.get(MetadataKeys.NULLABLE);

    return (
      <p>
        If the user data type is not defined, machine data type{' '}
        <b>
          {baseType}
          {length ? ` (${length})` : ''}
          {nullable ? ', Nullable' : ''}
        </b>{' '}
        provided by{' '}
        <b>
          <ComponentName component={component} showType />
        </b>{' '}
        will be used.
      </p>
    );
  }

  initData() {
    this.setState({
      userDataType: getDataType(this.getUserMetadata()),
      machineDataType: getDataType(this.getMachineMetadata()),
    });
  }

  isDeleteDisabled() {
    return !this.props.metadata.some((item) => {
      return (
        item.get('provider') === 'user' &&
        [MetadataKeys.BASE_TYPE, MetadataKeys.LENGTH, MetadataKeys.NULLABLE].includes(
          item.get('key'),
        )
      );
    });
  }

  getUserMetadata() {
    return this.props.metadata.filter((item) => item.get('provider') === 'user');
  }

  getMachineMetadata() {
    const lastActiveProvider = getLastActiveDatatypeProvider(this.props.metadata, {
      exclude: ['user'],
    });

    return this.props.metadata.filter((item) => item.get('provider') === lastActiveProvider);
  }

  handleTypeChange(value: string) {
    let userDataType = this.state.userDataType.set(MetadataKeys.BASE_TYPE, value);

    if (!isLengthSupported(value)) {
      userDataType = userDataType.delete(MetadataKeys.LENGTH);
    }

    this.setState({ userDataType });
  }

  handleLengthChange({ target }: { target: { value: string } }) {
    this.setState({
      userDataType: target.value
        ? this.state.userDataType.set(MetadataKeys.LENGTH, target.value)
        : this.state.userDataType.delete(MetadataKeys.LENGTH),
    });
  }

  handleNullableChange(checked: boolean) {
    this.setState({
      userDataType: this.state.userDataType.set(MetadataKeys.NULLABLE, checked),
    });
  }

  handleDelete(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();

    this.setState({ isDeleting: true });
    this.props
      .deleteUserType(this.props.columnName)
      .then(() => this.setState({ isDeleting: false }))
      .then(this.props.onHide);
  }

  handleSave(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    this.setState({ isLoading: true });
    this.props
      .saveUserType(this.props.columnName, this.state.userDataType)
      .then(() => this.setState({ isLoading: false }))
      .then(this.props.onHide);
  }
}

export default ColumnDatatypesModal;
