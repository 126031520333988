import { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import actionCreators from '@/modules/transformations/ActionCreators';
import { backends, transformationType } from '@/modules/transformations/Constants';
import { isKnownTransformationType } from '@/modules/transformations/utils/transformationTypes';
import BackendVersionModal from './backend-version/Modal';
import SidebarLabel from './backend-version/SidebarLabel';
import { getVersions, hasVersions } from './backend-version/versions';
import TransformationType from './TransformationType';

class TransformationVersion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    this.canSetBackendVersion = this.canSetBackendVersion.bind(this);
    this.saveImageTag = this.saveImageTag.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  render() {
    if (!this.canSetBackendVersion() || !isKnownTransformationType(this.props.transformation)) {
      return null;
    }

    return (
      <>
        <Button bsStyle="link" onClick={this.showModal}>
          <TransformationType transformation={this.props.transformation} showLabel={false} />
          <SidebarLabel version={this.props.transformation.get('imageTag')} />
        </Button>
        <BackendVersionModal
          show={this.state.showModal}
          availableVersions={getVersions(this.props.transformation.get('type')).map((runtime) => ({
            label: runtime.label,
            value: runtime.version,
          }))}
          onClose={this.hideModal}
          onSave={this.saveImageTag}
          imageTag={this.props.transformation.get('imageTag', '')}
        />
      </>
    );
  }

  canSetBackendVersion() {
    const { transformation } = this.props;
    const supportedBackend = [
      transformationType.PYTHON,
      transformationType.JULIA,
      transformationType.R,
    ];

    return (
      transformation.get('backend') === backends.DOCKER &&
      supportedBackend.includes(transformation.get('type')) &&
      (transformation.has('imageTag') || hasVersions(transformation.get('type')))
    );
  }

  showModal() {
    this.setState({ showModal: true });
  }

  hideModal() {
    this.setState({ showModal: false });
  }

  saveImageTag(imageTag) {
    if (!imageTag) {
      return actionCreators.deleteTransformationProperty(
        this.props.bucketId,
        this.props.transformation.get('id'),
        'imageTag',
        `Deleted imageTag in ${this.props.transformation.get('name')}`,
      );
    }

    return actionCreators.changeTransformationProperty(
      this.props.bucketId,
      this.props.transformation.get('id'),
      'imageTag',
      imageTag,
      `Set imageTag to ${imageTag} in ${this.props.transformation.get('name')}`,
    );
  }
}

TransformationVersion.propTypes = {
  transformation: PropTypes.object.isRequired,
  bucketId: PropTypes.string.isRequired,
};

export default TransformationVersion;
