import { useState } from 'react';
import type { Map } from 'immutable';

import { updateScheduler } from '@/modules/scheduler/actions';
import { SCHEDULE_STATE, SOX_BRANCH_TOOLTIP_MESSAGE } from '@/modules/scheduler/constants';
import { isActiveScheduler } from '@/modules/scheduler/helpers';
import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';
import ReadOnlyTooltip from '@/react/common/ReadOnlyTooltip';

const ScheduleSwitch = (props: {
  scheduler: Map<string, any>;
  canManageSchedule: boolean;
  hasProtectedDefaultBranch: boolean;
}) => {
  const [pending, setPending] = useState(false);
  const isActive = isActiveScheduler(props.scheduler);

  return (
    <ReadOnlyTooltip readOnly={!props.canManageSchedule}>
      <ActivateDeactivateSwitch
        className="tw-p-0"
        buttonDisabled={!props.canManageSchedule}
        activateTooltip={
          props.hasProtectedDefaultBranch
            ? SOX_BRANCH_TOOLTIP_MESSAGE
            : 'Disabled (click to enable)'
        }
        deactivateTooltip={
          props.hasProtectedDefaultBranch
            ? SOX_BRANCH_TOOLTIP_MESSAGE
            : 'Enabled (click to disable)'
        }
        isActive={isActive}
        isPending={pending}
        onChange={() => {
          setPending(true);
          return updateScheduler(
            props.scheduler.get('id'),
            props.scheduler.setIn(
              ['configuration', 'schedule', 'state'],
              isActive ? SCHEDULE_STATE.DISABLED : SCHEDULE_STATE.ENABLED,
            ),
          ).finally(() => setPending(false));
        }}
      />
    </ReadOnlyTooltip>
  );
};

export default ScheduleSwitch;
