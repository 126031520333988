import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { Alert, FormGroup, HelpBlock, Label, TextInput } from '@keboola/design';

import {
  KDS_TEAM_EX_ZOHO,
  SMETRIC_EX_SIMPRO_API,
  SMETRIC_EX_VEND_API,
} from '@/constants/componentIds';
import Select from '@/react/common/Select';
import string from '@/utils/string';

const FORM_GROUP_CLASS_NAME = 'tw-grid tw-grid-cols-4 tw-items-baseline tw-gap-4';

const zohoAuthUrls = [
  {
    value: 'zoho.eu',
    label: 'EU (zoho.eu)',
  },
  {
    value: 'zoho.uk',
    label: 'UK (zoho.uk)',
  },
  {
    value: 'zoho.com',
    label: 'US (zoho.com)',
  },
  {
    value: 'zohocloud.ca',
    label: 'CA (zohocloud.ca)',
  },
  {
    value: 'zoho.com.au',
    label: 'AU (zoho.com.au)',
  },
  {
    value: 'zoho.in',
    label: 'In (zoho.in)',
  },
  {
    value: 'zoho.jp',
    label: 'JP (zoho.jp)',
  },
];

const InstantAuthorizationFields = createReactClass({
  propTypes: {
    values: PropTypes.object.isRequired,
    componentId: PropTypes.string.isRequired,
    onChangeFn: PropTypes.func,
    infoText: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
  },

  render() {
    return (
      <div className="tw-flex tw-flex-col tw-gap-4">
        {!!this.props.infoText && <Alert variant="warning">{this.props.infoText}</Alert>}

        <FormGroup className={FORM_GROUP_CLASS_NAME}>
          <Label htmlFor="name">
            Name <Label.Optional />
          </Label>
          <div className="tw-col-span-3 tw-flex tw-flex-col tw-gap-1">
            <TextInput
              id="name"
              autoFocus
              variant="secondary"
              value={this.props.values.authorizedFor || ''}
              onChange={(value) => this.props.onChangeFn('authorizedFor', value)}
              disabled={this.props.disabled}
            />
            <HelpBlock>Describe this authorization, e.g., by the account name.</HelpBlock>
          </div>
        </FormGroup>

        {this.props.componentId === SMETRIC_EX_SIMPRO_API && (
          <>
            <FormGroup className={FORM_GROUP_CLASS_NAME}>
              <Label htmlFor="company-url">Company URL</Label>
              <div className="tw-col-span-3 tw-flex tw-flex-col tw-gap-1">
                <TextInput
                  id="company-url"
                  variant="secondary"
                  onChange={(value) => {
                    this.handleCompanyUrlChange(value);
                  }}
                  disabled={this.props.disabled}
                />
                <input type="hidden" name="authUrl" value={this.props.values.authUrl || ''} />
                <HelpBlock>
                  Use the full URL, e.g., <code>https://ACCOUNT_NAME.simprosuite.com</code>
                </HelpBlock>
              </div>
            </FormGroup>

            <FormGroup className={FORM_GROUP_CLASS_NAME}>
              <Label htmlFor="token-url">Token URL</Label>
              <TextInput
                id="token-url"
                variant="secondary"
                className="tw-col-span-3"
                name="tokenUrl"
                onChange={(value) => this.props.onChangeFn('tokenUrl', value)}
                value={this.props.values.tokenUrl || ''}
              />
            </FormGroup>

            <FormGroup className={FORM_GROUP_CLASS_NAME}>
              <Label htmlFor="client-id">Client ID</Label>
              <TextInput
                id="client-id"
                variant="secondary"
                className="tw-col-span-3"
                name="appKey"
                onChange={(value) => this.props.onChangeFn('appKey', value)}
                value={this.props.values.appKey || ''}
                disabled={this.props.disabled}
              />
            </FormGroup>

            <FormGroup className={FORM_GROUP_CLASS_NAME}>
              <Label htmlFor="client-secret">Client secret</Label>
              <TextInput
                id="client-secret"
                variant="secondary"
                className="tw-col-span-3"
                name="appSecret"
                onChange={(value) => this.props.onChangeFn('appSecret', value)}
                value={this.props.values.appSecret || ''}
                disabled={this.props.disabled}
              />
            </FormGroup>
          </>
        )}

        {this.props.componentId === SMETRIC_EX_VEND_API && (
          <FormGroup className={FORM_GROUP_CLASS_NAME}>
            <Label htmlFor="token-url">Token URL</Label>

            <div className="tw-col-span-3 tw-flex tw-flex-col tw-gap-1">
              <TextInput
                id="token-url"
                variant="secondary"
                name="tokenUrl"
                onChange={(value) => this.props.onChangeFn('tokenUrl', value)}
                value={this.props.values.tokenUrl || ''}
                disabled={this.props.disabled}
              />
              <HelpBlock>
                Use the full Token URL, e.g., <code>https://STORE.vendhq.com/api/1.0/token</code>
              </HelpBlock>
            </div>
          </FormGroup>
        )}

        {this.props.componentId === KDS_TEAM_EX_ZOHO && (
          <FormGroup className={FORM_GROUP_CLASS_NAME}>
            <Label htmlFor="company-url">Company URL</Label>
            <div className="tw-col-span-3">
              <Select
                id="company-url"
                placeholder="Select available URL"
                clearable={false}
                disabled={this.props.disabled}
                options={zohoAuthUrls}
                value={string.getStringBetween(
                  this.props.values.authUrl,
                  'https://accounts.',
                  '/oauth',
                )}
                onChange={(value) => this.handleZohoUrlChange(value)}
              />
              <input type="hidden" name="authUrl" value={this.props.values.authUrl || ''} />
              <input type="hidden" name="tokenUrl" value={this.props.values.tokenUrl || ''} />
            </div>
          </FormGroup>
        )}
      </div>
    );
  },

  handleZohoUrlChange(companyUrl) {
    const authUrl = `https://accounts.${companyUrl}/oauth/v2/auth?scope=ZohoCRM.modules.all,ZohoCRM.settings.modules.READ,ZohoCRM.bulk.READ,ZohoCRM.settings.fields.READ&client_id=%%client_id%%&response_type=code&access_type=offline&redirect_uri=%%redirect_uri%%`;
    const tokenUrl = `https://accounts.${companyUrl}/oauth/v2/token`;

    this.props.onChangeFn('authUrl', authUrl);
    this.props.onChangeFn('tokenUrl', tokenUrl);
  },

  handleCompanyUrlChange(companyUrl) {
    const authUrl = `${string.rtrim(companyUrl.trim(), '/')}/oauth2/login?client_id=%%client_id%%`;

    this.props.onChangeFn('authUrl', authUrl);
  },
});

export default InstantAuthorizationFields;
