import PropTypes from 'prop-types';
import immutableMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import TransformationsActionCreators from '@/modules/transformations/ActionCreators';
import TransformationsStore from '@/modules/transformations/stores/TransformationsStore';
import Loader from '@/react/common/Loader';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import Graph from './Graph';

const GraphContainer = createReactClass({
  mixins: [createStoreMixin(TransformationsStore), immutableMixin],

  propTypes: {
    bucketId: PropTypes.string.isRequired,
    transformationId: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  getStateFromStores() {
    return {
      showDisabled: TransformationsStore.isShowDisabledInOverview(
        this.props.bucketId,
        this.props.transformationId,
      ),
      isLoading: TransformationsStore.isOverviewLoading(
        this.props.bucketId,
        this.props.transformationId,
      ),
      model: TransformationsStore.getOverview(this.props.bucketId, this.props.transformationId),
    };
  },

  componentDidMount() {
    return this._loadData();
  },

  render() {
    return (
      <div className="graph">
        {this.state.isLoading ? (
          <div className="row text-center">
            <Loader />
          </div>
        ) : (
          this._renderNodes()
        )}
      </div>
    );
  },

  _renderNodes() {
    if (!this.state.model || this.state.model.get('nodes').count() === 0) {
      return <div className="row text-center">No nodes found.</div>;
    }

    return (
      <>
        <Graph
          model={this.state.model}
          centerNodeId={this.props.bucketId + '.' + this.props.transformationId}
          disabledTransformation={this.props.disabled}
          showDisabled={this.state.showDisabled}
          showDisabledHandler={this._handleChangeShowDisabled}
        />
        <div className="help-block">
          Please note that the graph shows a maximum of 7 levels of nesting.
        </div>
      </>
    );
  },

  _loadData() {
    return TransformationsActionCreators.loadTransformationOverview(
      this.props.bucketId,
      this.props.transformationId,
      this.state.showDisabled,
    );
  },

  _handleChangeShowDisabled(val) {
    return TransformationsActionCreators.showTransformationOverviewDisabled(
      this.props.bucketId,
      this.props.transformationId,
      val,
    );
  },
});

export default GraphContainer;
