import CollapsibleAlert from './CollapsibleAlert';
import { EXPERIMENTAL_TEXT } from './constants';

const ExperimentalComponentInfoAlert = () => {
  return (
    <CollapsibleAlert
      id="experimental-component"
      title="This component is experimental"
      variant="warning"
    >
      {EXPERIMENTAL_TEXT}
    </CollapsibleAlert>
  );
};

export default ExperimentalComponentInfoAlert;
