import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { Alert, Button, ButtonGroup, HelpBlock, IconButton, Link } from '@keboola/design';

import { backendImages } from '@/constants/backendImages';
import StorageApi from '@/modules/components/StorageApi';
import { registerExternalBucket } from '@/modules/storage/actions';
import { backends, nameWarning, SNOWFLAKE_DOCS } from '@/modules/storage/constants';
import { parseBigQueryDatasetUrl } from '@/modules/storage/helpers';
import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import InfoTooltip from '@/react/common/InfoTooltip';
import Markdown from '@/react/common/Markdown';
import ModalIcon from '@/react/common/ModalIcon';
import string from '@/utils/string';

const FORM_STEPS = {
  FORM: 'form',
  REGISTER: 'register',
};

const INITIAL_STATE = {
  step: FORM_STEPS.FORM,
  displayName: '',
  tempData: Map(),
  error: null,
  isLoadingGuide: false,
  guide: null,
};

const ICONS_MAP = Map({
  [backends.SNOWFLAKE]: backendImages.snowflake,
  [backends.TERADATA]: backendImages.teradata,
  [backends.BIGQUERY]: backendImages.bigquery,
});

const HAS_GUIDE = [backends.SNOWFLAKE, backends.BIGQUERY];

const RegisterExternalBucketModal = createReactClass({
  propTypes: {
    openModal: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    backend: PropTypes.string.isRequired,
    buckets: PropTypes.object.isRequired,
    hasSnowflakeRegisterExternalSecureDataShare: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return INITIAL_STATE;
  },

  render() {
    return (
      <Modal show={this.props.openModal} onHide={this.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Register Your {string.capitalize(this.props.backend)} Dataset</Modal.Title>
          {ICONS_MAP.has(this.props.backend) && (
            <ModalIcon iconUrl={ICONS_MAP.get(this.props.backend)} />
          )}
        </Modal.Header>
        {this.renderBody()}
      </Modal>
    );
  },

  renderBody() {
    if (this.state.step === FORM_STEPS.FORM) {
      return this.renderForm();
    }

    return this.renderRegister();
  },

  renderForm() {
    return (
      <>
        <Modal.Body>
          {this.renderError()}
          <p>Connect your dataset to a bucket in Keboola.</p>
          {this.renderFormFields()}
        </Modal.Body>
        <Modal.Footer>
          {HAS_GUIDE.includes(this.props.backend)
            ? this.renderNextButton()
            : this.renderSubmitButton()}
        </Modal.Footer>
      </>
    );
  },

  renderRegister() {
    return (
      <>
        <Modal.Body>
          {this.renderError()}
          <Markdown collapsible={false} source={this.state.guide} className="tw-mb-3" />
          {this.renderAdditionalRegisterFields()}
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup variant="block">
            <IconButton
              variant="outline"
              onClick={() => this.setState({ step: FORM_STEPS.FORM })}
              icon="arrow-left"
            />
            {this.renderSubmitButton()}
          </ButtonGroup>
        </Modal.Footer>
      </>
    );
  },

  renderFormFields() {
    return (
      <>
        <FormGroup>
          <ControlLabel>Name</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={this.state.displayName}
            onChange={(event) => {
              this.setState({ displayName: string.sanitizeKbcTableIdString(event.target.value) });
            }}
          />
          <HelpBlock className="tw-mt-1">{nameWarning}</HelpBlock>
        </FormGroup>
        {this.renderAdditionalFields()}
      </>
    );
  },

  renderAdditionalFields() {
    switch (this.props.backend) {
      case backends.BIGQUERY:
        return this.renderSimpleInput('Dataset', 'dataset');

      case backends.SNOWFLAKE:
        return (
          <>
            {this.renderSimpleInput('Database', 'database')}
            {this.renderSimpleInput('Schema', 'schema')}
            {this.props.hasSnowflakeRegisterExternalSecureDataShare && (
              <FormGroup className="tw-flex tw-items-center">
                <Checkbox
                  checked={this.state.tempData.get('isSnowflakeSharedDatabase', false)}
                  onChange={(checked) => {
                    this.setState({
                      tempData: this.state.tempData.set('isSnowflakeSharedDatabase', checked),
                    });
                  }}
                >
                  Secure Data Sharing
                </Checkbox>
                <InfoTooltip
                  tooltip={
                    <p>
                      Enable this if you&apos;re registering data shared with you via Secure Data
                      Sharing. This lets Keboola access the shared data directly without copying it.
                      Ensure you have the necessary permissions from the data provider before
                      enabling. Read more <Link href={SNOWFLAKE_DOCS}>here</Link>.
                    </p>
                  }
                />
              </FormGroup>
            )}
          </>
        );

      case backends.TERADATA:
        return this.renderSimpleInput('Database', 'database');

      default:
        return null;
    }
  },

  renderAdditionalRegisterFields() {
    switch (this.props.backend) {
      case backends.BIGQUERY:
        return this.renderSimpleInput('URL', 'url');

      default:
        return null;
    }
  },

  renderSimpleInput(label, name) {
    return (
      <FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <FormControl
          type="text"
          value={this.state.tempData.get(name, '')}
          onChange={(event) =>
            this.setState({ tempData: this.state.tempData.set(name, event.target.value) })
          }
        />
      </FormGroup>
    );
  },

  renderError() {
    if (!this.state.error) {
      return null;
    }

    return (
      <Alert variant="error" className="tw-mb-5">
        {this.state.error}
      </Alert>
    );
  },

  renderSubmitButton() {
    return (
      <ConfirmButtons
        block
        isSaving={this.props.isSaving}
        isDisabled={this.isSubmitDisabled()}
        onSave={this.onSubmit}
        saveLabel={this.props.isSaving ? 'Registering...' : 'Register Dataset'}
      />
    );
  },

  renderNextButton() {
    return (
      <Button
        block
        onClick={() => {
          this.setState({ error: null });
          this.loadGuide().then(() => {
            this.setState({ step: FORM_STEPS.REGISTER });
          });
        }}
        disabled={this.isNextStepDisabled() || this.state.isLoadingGuide}
      >
        {this.state.isLoadingGuide ? 'Loading guide...' : 'Next Step'}
      </Button>
    );
  },

  onHide() {
    this.props.onHide();
    this.setState(INITIAL_STATE);
  },

  onSubmit(event) {
    event.preventDefault();

    return registerExternalBucket(
      this.props.backend,
      this.state.displayName,
      this.prepareRegisterPath(),
      this.props.backend === backends.SNOWFLAKE
        ? this.state.tempData.get('isSnowflakeSharedDatabase', false)
        : void 0,
    ).then(this.onHide, (message) => this.setState({ error: message }));
  },

  loadGuide() {
    this.setState({ isLoadingGuide: true });
    return StorageApi.getBucketRegistrationGuide({
      backend: this.props.backend,
      path: this.prepareGuidePath(),
      isSnowflakeSharedDatabase:
        this.props.backend === backends.SNOWFLAKE
          ? this.state.tempData.get('isSnowflakeSharedDatabase', false)
          : void 0,
    })
      .then(
        (response) => this.setState({ guide: response.markdown }),
        (error) => this.setState({ error: error?.message ?? error }),
      )
      .finally(() => this.setState({ isLoadingGuide: false }));
  },

  prepareRegisterPath() {
    switch (this.props.backend) {
      case backends.BIGQUERY:
        return parseBigQueryDatasetUrl(this.state.tempData.get('url', ''));

      case backends.SNOWFLAKE:
        return [this.state.tempData.get('database'), this.state.tempData.get('schema')];

      case backends.TERADATA:
        return [this.state.tempData.get('database')];

      default:
        return [];
    }
  },

  prepareGuidePath() {
    switch (this.props.backend) {
      case backends.BIGQUERY:
        return [this.state.tempData.get('dataset')];

      case backends.SNOWFLAKE:
        return [this.state.tempData.get('database'), this.state.tempData.get('schema')];

      default:
        return [];
    }
  },

  isSubmitDisabled() {
    switch (this.props.backend) {
      case backends.BIGQUERY:
        return !this.state.tempData.get('url');

      default:
        return false;
    }
  },

  isNextStepDisabled() {
    if (!this.state.displayName || this.props.isSaving) {
      return true;
    }

    switch (this.props.backend) {
      case backends.BIGQUERY:
        return !this.state.tempData.get('dataset');

      case backends.SNOWFLAKE:
        return !this.state.tempData.get('database') || !this.state.tempData.get('schema');

      case backends.TERADATA:
        return !this.state.tempData.get('database');

      default:
        return false;
    }
  },
});

export default RegisterExternalBucketModal;
