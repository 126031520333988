import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Map } from 'immutable';
import { fromJS } from 'immutable';

import { KEBOOLA_EX_SAMPLE_DATA } from '@/constants/componentIds';
import { COLLAPSED_TRASH_CONFIGURATIONS } from '@/constants/localStorageKeys';
import { useRestoreMultipleConfigurations } from '@/modules/trash/rqHooks/useRestoreMultipleConfigurations';
import type { Configuration, DeletedComponent } from '@/modules/trash/types';
import { getFilteredConfigurations, hasDisabledRestore } from '@/modules/trash/utils';
import Checkbox from '@/react/common/Checkbox';
import CollapseButton from '@/react/common/CollapseButton';
import ComponentDetailLink from '@/react/common/ComponentDetailLink';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import ComponentType from '@/react/common/ComponentType';
import useLocalStorage from '@/react/hooks/useLocalStorage';
import string from '@/utils/string';
import DeletedConfigurationRow from './DeletedConfigurationRow';
import RestoreConfigurationButton from './RestoreConfigurationButton';

type Props = {
  component: DeletedComponent;
  allComponents: Map<string, any>;
  searchQuery: string;
  admins: Map<string, any>;
  hasFlows: boolean;
  readOnly: boolean;
  isDeleteEnabled: boolean;
};

const DeletedComponentRow = ({
  component,
  allComponents,
  searchQuery,
  readOnly,
  hasFlows,
  admins,
  isDeleteEnabled,
}: Props) => {
  const [isCollapsed, setIsCollapsed] = useLocalStorage(
    `${COLLAPSED_TRASH_CONFIGURATIONS}-${component.id}`,
    true,
  );

  const [selectedConfigurations, setSelectedConfigurations] = useState<Set<string>>(new Set());

  const configurations = useMemo(
    () => getFilteredConfigurations(component, searchQuery),
    [component, searchQuery],
  );

  const restoreMultipleConfigurations = useRestoreMultipleConfigurations();

  const selectedSize = selectedConfigurations.size;
  const componentType = component.type;
  const isAllSelected = configurations.length === selectedSize;
  const isSomeSelected = selectedSize > 0;
  const isRestoreEnabled = !hasDisabledRestore(component.id);

  const handleSelectConfiguration = useCallback(
    (configId: string) => {
      if (selectedConfigurations.has(configId)) {
        setSelectedConfigurations((prev) => {
          prev.delete(configId);
          return new Set(prev);
        });
      } else {
        setSelectedConfigurations((prev) => {
          return new Set(prev.add(configId));
        });
      }
    },
    [selectedConfigurations],
  );

  useEffect(() => {
    selectedConfigurations.forEach((id) => {
      // Clean up -> when we delete or restore config in a different way
      // we want to remove it from selected configs
      if (!configurations.some((configuration) => configuration.id === id)) {
        handleSelectConfiguration(id);
      }
    });
  }, [selectedConfigurations, configurations, handleSelectConfiguration]);

  const handleMultipleSelection = () => {
    // Deselect
    if (selectedSize > 0) {
      setSelectedConfigurations(new Set());
    } else {
      // Select all
      const configIds = configurations.map((config) => config.id);

      setSelectedConfigurations(new Set(configIds));
    }
  };

  const handleRestore = () => {
    const configsToRestore = [...selectedConfigurations]
      .map((configId) => configurations.find((config) => config.id === configId))
      .filter(Boolean) as Configuration[];

    return restoreMultipleConfigurations.mutateAsync(
      {
        componentId: component.id,
        configsToRestore,
        selectedSize,
      },
      {
        onSettled: () => handleMultipleSelection(),
      },
    );
  };

  const renderConfiguration = (configuration: Configuration) => {
    const realComponentId = configuration.isSample ? KEBOOLA_EX_SAMPLE_DATA : component.id;
    const realComponent = allComponents.get(realComponentId);

    const configId = configuration.id;

    return (
      <DeletedConfigurationRow
        key={configId}
        config={configuration}
        component={realComponent}
        readOnly={readOnly}
        admins={admins}
        isDeleteEnabled={isDeleteEnabled}
        isSelected={selectedConfigurations.has(configId)}
        isRestoreEnabled={isRestoreEnabled}
        onSelect={() => handleSelectConfiguration(configId)}
      />
    );
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const getRestoreConfigurationModalText = () => {
    if (selectedSize === 1) {
      const [configId] = selectedConfigurations;
      const configName = configurations.find((config) => config.id === configId)?.name ?? '';

      return (
        <>
          Are you sure you want to restore the configuration <b>{configName}</b>?
        </>
      );
    }

    return (
      <>
        Are you sure you want to restore the <b>{selectedSize}</b>{' '}
        {string.pluralize(selectedSize, 'configuration')}?
      </>
    );
  };

  return (
    <div className="box">
      <div
        onClick={toggleCollapse}
        className="flex-container component-name-above-table btn-collapse-area"
      >
        <span className="flex-container flex-start">
          <ComponentIcon component={fromJS(component)} size="48" className="mrp-4" />
          <div className="flex-container flex-column align-top">
            <ComponentDetailLink componentId={component.id} hasFlows={hasFlows}>
              <ComponentName component={fromJS(component)} />
            </ComponentDetailLink>
            {componentType !== 'other' && (
              <ComponentType
                noIcon
                type={componentType}
                className="f-14 font-medium line-height-20"
              />
            )}
          </div>
        </span>

        <CollapseButton isCollapsed={isCollapsed} onToggle={toggleCollapse} />
      </div>
      {isCollapsed ? (
        <div className="collapsed-configurations clickable" onClick={toggleCollapse}>
          <span className="font-semibold">{configurations.length} </span>
          <span className="text-muted">
            {string.pluralize(configurations.length, 'Configuration')}
          </span>
        </div>
      ) : (
        <div className="table table-hover fixed">
          <div className="thead">
            <div className="tr">
              <div className="th">
                <div className="tw-flex tw-h-5 tw-items-center tw-gap-3">
                  {!readOnly && isRestoreEnabled && (
                    <Checkbox
                      checked={isAllSelected}
                      tooltip={isAllSelected || isSomeSelected ? 'Deselect all' : 'Select all'}
                      indeterminate={isSomeSelected && !isAllSelected}
                      className="tw-mb-0.5"
                      onChange={handleMultipleSelection}
                    />
                  )}
                  {isSomeSelected ? (
                    <strong className="tw-flex tw-items-center">
                      <span>
                        {selectedSize} {string.pluralize(selectedSize, 'configuration')} selected
                      </span>

                      <div>
                        <RestoreConfigurationButton
                          title={`Restore ${string.pluralize(selectedSize, 'Configuration')}`}
                          text={getRestoreConfigurationModalText()}
                          onConfirm={handleRestore}
                          isPending={restoreMultipleConfigurations.isPending}
                        />
                      </div>
                    </strong>
                  ) : (
                    'Name'
                  )}
                </div>
              </div>
              <span className="th w-300 text-right">Deleted</span>
            </div>
          </div>
          <div className="tbody">{configurations.map(renderConfiguration)}</div>
        </div>
      )}
    </div>
  );
};

export default DeletedComponentRow;
