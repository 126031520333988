import { lazy, Suspense } from 'react';

import BillingStore from '@/modules/billing/store';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';

const Pay = lazy(() => import(/* webpackChunkName: "billing-credits-pay" */ './Pay'));

export const Credits = () => {
  const store = useStores(
    () => {
      return {
        currentAdmin: ApplicationStore.getCurrentAdmin(),
        stripePublishableKey: ApplicationStore.getStripePublishableKey(),
        creditPrice: BillingStore.getCreditPrice(),
        billingInformation: BillingStore.getBillingInformation(),
        purchases: BillingStore.getPurchases(),
        topUpSetting: BillingStore.getTopUpSetting(),
      };
    },
    [],
    [ApplicationStore, BillingStore],
  );

  return (
    <Suspense fallback={null}>
      <Pay
        user={store.currentAdmin}
        purchases={store.purchases}
        creditPrice={store.creditPrice}
        billingInformation={store.billingInformation}
        stripePublishableKey={store.stripePublishableKey}
        topUpSetting={store.topUpSetting}
      />
    </Suspense>
  );
};
