import { useState } from 'react';
import type { ReactNode } from 'react';
import _ from 'underscore';

import { cn, Tooltip } from '@keboola/design';

const OFFSET_TOLERANCE = 2;

type Props = {
  text: ReactNode;
  tooltip?: ReactNode;
  className?: string;
  twoLines?: boolean;
  noTooltip?: boolean;
  redact?: boolean;
};

export const Truncated = ({
  text,
  tooltip = text,
  className,
  twoLines = false,
  noTooltip = false,
  redact = false,
}: Props) => {
  const tooltipText = _.isString(tooltip) ? tooltip : '';
  const [showTooltip, setShowTooltip] = useState(false);

  const isScrollableTarget = (e: HTMLElement): boolean => {
    return e.scrollHeight - OFFSET_TOLERANCE >= e.clientHeight;
  };

  if (noTooltip || !tooltipText) {
    return (
      <span className={cn(className, 'truncated', { 'truncated-2-lines': twoLines })}>{text}</span>
    );
  }

  return (
    <Tooltip
      placement="top"
      type="explanatory"
      forceHide={!showTooltip}
      tooltip={<div data-heap-redact-text={redact}>{tooltipText}</div>}
    >
      <span
        className={cn(className, 'truncated', { 'truncated-2-lines': twoLines })}
        onMouseOver={(e) => setShowTooltip(isScrollableTarget(e.currentTarget))}
        onFocus={(e) => setShowTooltip(isScrollableTarget(e.currentTarget))}
      >
        {text}
      </span>
    </Tooltip>
  );
};
