import { sortEntities } from '@/constants';
import { SortableHeader } from '@/modules/data-catalog/react/tableComponents/SortableHeader';
import type { FlatTableRow, SetSortType, SortType } from '@/modules/data-catalog/types';
import { CreatedDate, Truncated } from '@/react/common';

type Props = {
  sort: SortType;
  setSort: SetSortType;
};

export const lastModified = ({ sort, setSort }: Props) => ({
  id: 'last_modified',
  header: () => (
    <SortableHeader
      label="Last Change (sharing)"
      entityKey={sortEntities.LAST_SHARE}
      sort={sort}
      setSort={setSort}
    />
  ),
  cell: ({ row }: { row: FlatTableRow }) => {
    const bucket = row.original;
    const sharedByDate = bucket.getIn(['sharedBy', 'date']);
    const sharedByName = bucket.getIn(['sharedBy', 'name']);

    return (
      <div className="tw-flex tw-flex-col tw-items-end">
        <CreatedDate createdTime={sharedByDate} />
        <Truncated className="text-muted" text={sharedByName} />
      </div>
    );
  },
});
