import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { IconButton, Tooltip } from '@keboola/design';

import { dataPreviewDataType } from '@/modules/storage/constants';
import type { Backend } from '@/modules/storage/types';
import ModalIcon from '@/react/common/ModalIcon';

const DataSampleHelp = ({ backend }: { backend: Backend }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Tooltip tooltip="Supported syntax" placement="top">
        <IconButton
          variant="inline"
          size="small"
          onClick={() => setShowModal(true)}
          icon="circle-info"
        />
      </Tooltip>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Supported Syntax</Modal.Title>
          <ModalIcon icon="circle-info" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered border-collapsed">
            <thead>
              <tr>
                <th className="text-center w-150">Data type</th>
                <th className="text-center w-150">Operator</th>
                <th className="text-left">Examples</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={2} className="text-center">
                  String
                </td>
                <td className="text-center">=</td>
                <td className="text-left">
                  <code>joe</code>
                  <br />
                  <code>=joe</code>
                  <br />
                  <code>=joe,doe</code>
                </td>
              </tr>
              <tr>
                <td className="text-center">!=</td>
                <td className="text-left">
                  <code>!=joe</code>
                  <br />
                  <code>!=joe,doe</code>
                </td>
              </tr>
              <tr>
                <td className="text-center">Empty String</td>
                <td className="text-center">&quot;&quot;</td>
                <td className="text-left" />
              </tr>
              <tr>
                <td rowSpan={3} className="text-center">
                  Numeric
                </td>
                <td className="text-center">=</td>
                <td className="text-left">
                  <code>=10</code>
                  <br />
                  <code>=10,5</code>
                </td>
              </tr>
              <tr>
                <td className="text-center">!=</td>
                <td className="text-left">
                  <code>!=10</code>
                  <br />
                  <code>!=10,5</code>
                </td>
              </tr>
              {Object.keys(dataPreviewDataType).includes(backend) && (
                <tr>
                  <td className="text-center">
                    &gt;
                    <br />
                    &lt;
                    <br />
                    &gt;=
                    <br />
                    &lt;=
                  </td>
                  <td className="text-left">
                    <code>&gt;10</code>
                    <br />
                    <code>&lt;23.5</code>
                    <br />
                    <code>&gt;=8</code>
                    <br />
                    <code>&lt;=0.125</code>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <ul className="mt-1">
            <li>
              Comma <code>,</code> is a value separator
            </li>
            <li>
              Dot <code>.</code> is decimal point
            </li>
            <li>
              Quotation marks (e.g., <code>&quot;&gt;10&quot;</code>) enforce string search
            </li>
            <li>
              It is also possible to use <code>&gt;</code> and <code>&lt;</code> operators when
              comparing string values (e.g., <code>&gt;2015-10-05</code>)
            </li>
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DataSampleHelp;
