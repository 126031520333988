import createReactClass from 'create-react-class';

import createStoreMixin from '@/react/mixins/createStoreMixin';
import RoutesStore from '@/stores/RoutesStore';
import ErrorContent from './ErrorContent';

const ErrorPage = createReactClass({
  mixins: [createStoreMixin(RoutesStore)],

  getStateFromStores() {
    return {
      error: RoutesStore.getError(),
    };
  },

  render() {
    if (!this.state.error) {
      return <ErrorContent />;
    }

    return (
      <ErrorContent
        text={this.state.error.getText()}
        headerText={this.state.error.getTitle()}
        exceptionId={this.state.error.getExceptionId()}
      />
    );
  },
});

export default ErrorPage;
