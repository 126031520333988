import { Map, OrderedMap } from 'immutable';

import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import JobsTable from '@/modules/flows/components/JobsTable';
import JobsGraphWithPaging from '@/modules/queue/components/JobsGraphWithPaging';
import { filterLatestJobs } from '@/modules/queue/helpers';
import JobsStore from '@/modules/queue/store';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const GenericDetailJobs = () => {
  const store = useStores(
    () => {
      const componentId = RoutesStore.getCurrentRouteComponentId();
      const configId = RoutesStore.getCurrentRouteParam('config');

      const latestJobData = JobsStore.getLatestJobs(componentId, configId);

      return {
        configId,
        componentId,
        latestJobData,
        admins: ApplicationStore.getAdmins(),
        sapiToken: ApplicationStore.getSapiToken(),
        allJobs: JobsStore.getAll() as Map<string, any>,
        config: InstalledComponentsStore.getConfig(componentId, configId),
        jobsPendingActions: JobsStore.getPendingActions() as Map<string, any>,
        filteredJobs: filterLatestJobs(latestJobData.get('jobs') ?? OrderedMap()),
      };
    },
    [],
    [ApplicationStore, RoutesStore, InstalledComponentsStore, JobsStore],
  );

  return (
    <>
      <ConfigurationTabs
        componentId={store.componentId}
        configId={store.configId}
        config={store.config}
      />
      <JobsGraphWithPaging job={store.filteredJobs.first()} />
      <JobsTable
        jobs={store.filteredJobs}
        configId={store.configId}
        allJobs={store.allJobs}
        admins={store.admins}
        sapiToken={store.sapiToken}
        isLoading={store.latestJobData.get('isLoading', false)}
        terminatingPendingActions={store.jobsPendingActions.get('terminating', Map())}
      />
    </>
  );
};

export default GenericDetailJobs;
