import { useState } from 'react';

import { Alert, Button, Icon } from '@keboola/design';

import Loader from './Loader';

type Result = { status: 'success' } | { status: 'error'; message: string; exceptionId?: string };

type Props = {
  isEditing: boolean;
  testCredentialsFn: () => Promise<any>;
  disabled?: boolean;
};

const TestCredentialsButtonGroup = (props: Props) => {
  const [isTesting, setIsTesting] = useState(false);
  const [result, setResult] = useState<Result | null>(null);

  const renderResult = () => {
    if (!result) {
      return null;
    }

    if (result.status === 'error') {
      return (
        <Alert variant="error" title="Failed to connect!" className="tw-mt-3.5">
          <p>{result.message}</p>
          {result.exceptionId && <small>ExceptionId: {result.exceptionId}</small>}
        </Alert>
      );
    }

    return (
      <Alert variant="success" className="tw-mt-3.5" title="Connected!">
        {props.isEditing && <p>Don&apos;t forget to save the credentials.</p>}
      </Alert>
    );
  };

  return (
    <div className="tw-grid tw-grid-cols-3 tw-gap-4">
      <div className="tw-col-span-2 tw-col-start-2">
        <Button
          variant="outline"
          disabled={isTesting || props.disabled}
          onClick={() => {
            setResult(null);
            setIsTesting(true);
            return props
              .testCredentialsFn()
              .then((response: Result) => setResult(response))
              .finally(() => setIsTesting(false));
          }}
        >
          {isTesting ? (
            <>
              <Loader />
              Testing Connection
            </>
          ) : (
            <>
              <Icon icon="key" />
              Test Connection
            </>
          )}
        </Button>
        {renderResult()}
      </div>
    </div>
  );
};

export default TestCredentialsButtonGroup;
