import type { GenericFetchClient } from '../storageClient';

import { mapBranchMetadata } from './mappers';
import type { BranchMetadataRaw, GetWorkspaceSaml2LoginPath, WorkspaceSaml2Login } from './types';

export const createBranches = (client: GenericFetchClient) => {
  const getBranchMetadata = async (id: string, signal?: AbortSignal) => {
    const { data } = await client.get<BranchMetadataRaw[], { id: string }>(
      '/branch/{id}/metadata',
      { path: { id } },
      { signal },
    );

    return data.map(mapBranchMetadata);
  };

  const getWorkspaceSaml2Login = async (path: GetWorkspaceSaml2LoginPath, signal?: AbortSignal) => {
    const { data } = await client.get<WorkspaceSaml2Login, GetWorkspaceSaml2LoginPath>(
      '/branch/{branchId}/workspaces/{workspaceId}/saml2-login',
      { path },
      { signal },
    );

    return data;
  };

  return {
    getBranchMetadata,
    getWorkspaceSaml2Login,
  };
};
