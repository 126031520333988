import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';

import FakeBarGraph from '../../../images/fake-bar-graph.png';

import { fileUrl } from '@/utils/fileHelpers';
import Loader from './Loader';

const EmptyGraph = ({ isLoading, text }: { isLoading: boolean; text?: string | null }) => {
  const [isInitialLoading, setIsInitialLoading] = useState(isLoading);

  useEffect(() => {
    if (isInitialLoading && !isLoading) {
      setIsInitialLoading(false);
    }
  }, [isInitialLoading, isLoading]);

  return (
    <div className="tw-relative tw-mx-7 tw-mb-6 tw-mt-0">
      <div className="tw-absolute tw-left-2/4 tw-top-2/4 tw-flex tw-min-w-64 -tw-translate-x-2/4 -tw-translate-y-2/4 tw-flex-col tw-items-center tw-text-center">
        {isInitialLoading ? (
          <>
            <Loader className="icon-addon-right" />
            Loading...
          </>
        ) : (
          <>
            <strong className="tw-mt-3">Why is there no bar chart visible?</strong>
            <p>{text}</p>
          </>
        )}
      </div>
      <Image
        responsive
        src={fileUrl(FakeBarGraph)}
        className="tw-mx-auto tw-my-0 tw-max-h-60 tw-min-h-44"
      />
    </div>
  );
};

export default EmptyGraph;
