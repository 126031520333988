import ConfigurationRowEditField from '@/modules/components/react/components/ConfigurationRowEditField';
import InlineEditTextInput from '@/react/common/InlineEditTextInput';

const ConfigurationRowName = (props: {
  componentId: string;
  configId: string;
  rowId: string;
  placeholder?: string;
}) => {
  return (
    <ConfigurationRowEditField
      key={`name-${props.componentId}-${props.configId}-${props.rowId}`}
      componentId={props.componentId}
      configId={props.configId}
      rowId={props.rowId}
      fieldName="name"
      editElement={InlineEditTextInput}
      placeholder={props.placeholder || 'My Configuration'}
      tooltipPlacement="bottom"
    />
  );
};

export default ConfigurationRowName;
