import type { Map } from 'immutable';
import { List } from 'immutable';

import type { GetDataPreview, WhereFilters } from '@keboola/api-client';

import { getTableColumnMetadata } from '@/modules/components/utils/tableMetadataHelper';
import { dataPreviewDataType } from '@/modules/storage/constants';
import { findBasetypeDatatype, hasNumberLikeBasetype } from '@/modules/storage/helpers';

export type Params = GetDataPreview & {
  whereFilters: WhereFilters[];
};

export const DEFAULT_WHERE_FILTER: WhereFilters = { column: '', operator: 'eq', values: [] };

export const prepareParams = (table: Map<string, any>, params: Params) => {
  const metadata = getTableColumnMetadata(table);
  const validFilters = params.whereFilters.filter(
    (filter) => filter.column && filter.values.length > 0,
  );

  return {
    ...(params.changedSince && { changedSince: params.changedSince }),
    ...(params.changedUntil && { changedUntil: params.changedUntil }),
    ...(validFilters.length > 0 && {
      whereFilters: validFilters.map((filter) => {
        return {
          column: filter.column,
          operator: filter.operator,
          values: filter.values,
          ...(!table.get('isTyped') &&
            hasNumberLikeBasetype(findBasetypeDatatype(metadata.get(filter.column, List()))) && {
              dataType:
                dataPreviewDataType[table.get('backend') as keyof typeof dataPreviewDataType],
            }),
        };
      }),
    }),
  };
};
