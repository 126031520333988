import { KEBOOLA_DATA_APPS } from '@/constants/componentIds';
import { ComponentNameEdit } from '@/modules/components/react/components/ComponentNameEdit';

type Props = {
  config: string;
};

export const DataAppNameEdit = ({ config }: Props) => (
  <ComponentNameEdit
    key={`${KEBOOLA_DATA_APPS}-${config}`}
    componentId={KEBOOLA_DATA_APPS}
    configId={config}
  />
);
