import { useCallback, useEffect, useState } from 'react';
import type { ReactNode } from 'react';
import type { List, Map } from 'immutable';

import VersionsStore from '@/modules/components/stores/VersionsStore';
import VersionsActionCreators from '@/modules/components/VersionsActionCreators';
import RowVersionsActionCreators from '@/modules/configurations/RowVersionsActionCreators';
import RowVersionsStore from '@/modules/configurations/RowVersionsStore';
import nextTick from '@/utils/nextTick';
import { SIDE } from './SplitDiff/constants';
import type { SIDE_OPTIONS } from './SplitDiff/SplitDiff';
import SplitDiff, { SplitDiffTitle } from './SplitDiff/SplitDiff';
import Checkbox from './Checkbox';
import FullScreenEditor from './FullScreenEditor';
import {
  haveConfigurationQueriesChanged,
  haveConfigurationsOnlyQueriesChanged,
  prepareQueriesDiffData,
} from './VersionsDiffModalHelpers';

type Props = {
  show: boolean;
  component: Map<string, any>;
  admins: Map<string, any>;
  referentialVersion: Map<string, any>;
  compareVersion: Map<string, any>;
  sharedCodes: List<any>;
  config?: Map<string, any>;
  row?: Map<string, any>;
  isBranchDiff?: boolean;
  hideRollback?: boolean;
  additionalButtons?: () => ReactNode;
  noChangesPlaceholder?: ReactNode;
  helpText?: ReactNode;
  title?: ReactNode;
  onClose: () => void;
};

const VersionsDiffModal = (props: Props) => {
  const [leftVersion, setLeftVersion] = useState(props.compareVersion);
  const [rightVersion, setRightVersion] = useState(props.referentialVersion);
  const [detailedDiff, setDetailedDiff] = useState(false);
  const [hasQueriesChanges, setQueriesChanges] = useState(false);
  const componentId = props.component.get('id');
  const configId = props.config?.get('id');
  const rowId = props.row?.get('id');

  const setQueries = useCallback(() => {
    setLeftVersion(props.compareVersion);
    setRightVersion(props.referentialVersion);
  }, [props.compareVersion, props.referentialVersion]);

  const checkQueries = useCallback(() => {
    const hasQueriesChanges = haveConfigurationQueriesChanged(
      componentId,
      leftVersion,
      rightVersion,
    );

    const hasOnlyQueriesChanges =
      hasQueriesChanges &&
      haveConfigurationsOnlyQueriesChanged(componentId, leftVersion, rightVersion);

    setQueriesChanges(hasQueriesChanges);
    setDetailedDiff(hasOnlyQueriesChanges);
  }, [leftVersion, rightVersion, componentId]);

  useEffect(() => {
    if (props.show) {
      nextTick(checkQueries);
    }
  }, [props.show, checkQueries]);

  const handleChangeVersion = useCallback(
    (version: number, side: SIDE_OPTIONS) => {
      if (!configId || !version) {
        return Promise.resolve();
      }

      return Promise.resolve()
        .then(() => {
          if (!!rowId) {
            return RowVersionsActionCreators.loadComponentConfigByVersion(
              componentId,
              configId,
              rowId,
              version,
            ).then(() => {
              return RowVersionsStore.getConfigByVersion(componentId, configId, rowId, version);
            });
          }

          return VersionsActionCreators.loadComponentConfigByVersion(
            componentId,
            configId,
            version,
          ).then(() => {
            return VersionsStore.getConfigByVersion(componentId, configId, version);
          });
        })
        .then(side === SIDE.LEFT ? setLeftVersion : setRightVersion);
    },
    [componentId, configId, rowId],
  );

  const renderEditor = () => {
    return (
      <SplitDiff
        componentId={componentId}
        config={props.config}
        row={props.row}
        branchDiff={props.isBranchDiff || false}
        rightVersion={rightVersion}
        leftVersion={leftVersion}
        noChangesPlaceholder={props.noChangesPlaceholder}
        hideRollback={props.hideRollback}
        admins={props.admins}
        handleChangeVersion={handleChangeVersion}
        {...(detailedDiff && {
          prepareData: (version) => {
            return prepareQueriesDiffData(componentId, version, props.sharedCodes);
          },
        })}
      />
    );
  };

  const renderTitle = () => {
    return (
      <SplitDiffTitle
        component={props.component}
        name={props.title || props.referentialVersion.get('name')}
        helpText={props.helpText}
      />
    );
  };

  const renderButtons = () => {
    return (
      <>
        {hasQueriesChanges && (
          <>
            <Checkbox checked={detailedDiff} onChange={setDetailedDiff}>
              Detailed diff for queries or scripts
            </Checkbox>
            <span className="btn-separator" />
          </>
        )}
        {props.additionalButtons?.()}
      </>
    );
  };

  if (!props.show) {
    return null;
  }

  return (
    <FullScreenEditor
      onEnter={setQueries}
      onClose={props.onClose}
      renderEditor={renderEditor}
      renderTitle={renderTitle}
      renderButtons={renderButtons}
      className="with-scroll"
    />
  );
};

export default VersionsDiffModal;
