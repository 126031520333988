import { ButtonInline } from '@keboola/design';

import StorageApiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';

const TriggerInfo = (props: {
  tables: { tableId: string }[];
  coolDownPeriodMinutes: number;
  onEdit?: () => void;
}) => {
  return (
    <>
      {props.tables.length === 1 ? (
        <>
          Table: <StorageApiTableLinkEx tableId={props.tables[0].tableId} showLabels={false} />
        </>
      ) : props.onEdit ? (
        <ButtonInline onClick={props.onEdit}>{props.tables.length} tables</ButtonInline>
      ) : (
        <>{props.tables.length} tables</>
      )}
      , Cooldown: {props.coolDownPeriodMinutes} minutes
    </>
  );
};

export default TriggerInfo;
