import { useEffect, useState } from 'react';

import { Tabs, TabsContent } from '@keboola/design';

import type { WorkspaceData } from './api';
import { createCredentials } from './api';
import LazyLoadedDashboard from './LazyLoadedDashboard';
import LoadingData from './LoadingData';

const TABS = [
  { title: 'Organization Usage', value: 'organization-usage' },
  { title: 'Project Overview', value: 'project-overview' },
  { title: 'Project Users', value: 'project-users' },
  { title: 'Project Health', value: 'project-health' },
  { title: 'Jobs Monitoring', value: 'jobs-monitoring' },
];

// this order is hardcoded in the backend, so don't change it
// https://github.com/keboola/gooddata-cn-provisioning/blob/main/provisioning/common/kubernetes.tf#L80
const DASHBOARD_ID_MAP = [
  'organization-usage',
  'project-overview',
  'project-users',
  'project-health',
  'jobs-monitoring',
];

const ActivityCenter = () => {
  return (
    <Tabs triggers={TABS}>
      {TABS.map(({ value }) => {
        return (
          <TabsContent key={value} value={value}>
            <Dashboard type={value} />
          </TabsContent>
        );
      })}
    </Tabs>
  );
};

const Dashboard = (props: { type: string }) => {
  const [credentials, setCredentials] = useState<WorkspaceData | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    createCredentials({ project_type: 'activity-center' })
      .then((data) => {
        const dashboardsIds = data.dashboardId.split(',');
        const dashboardTypeIndex = DASHBOARD_ID_MAP.indexOf(props.type);

        setCredentials({
          userToken: data.userToken,
          workspaceId: data.workspaceId,
          dashboardId: dashboardsIds[dashboardTypeIndex],
        });
      })
      .finally(() => setIsLoading(false));
  }, [props.type]);

  if (!credentials) {
    return <LoadingData isLoading={isLoading} />;
  }

  return <LazyLoadedDashboard credentials={credentials} />;
};

export default ActivityCenter;
