import { sharedFromProjectRouter, sharedWithYouRouter } from '@/modules/data-catalog/routes';
import { KEBOOLA_NO_CODE_DBT_TRANSFORMATION } from './constants/componentIds';
import billingRoutes from './modules/billing/routes';
import bucketBrowser from './modules/bucket-browser/routes';
import components from './modules/components-directory/routes';
import { routeNames as dataAppsRouteNames } from './modules/data-apps/constants';
import dataAppsRoutes from './modules/data-apps/routes';
import { routeNames as dataCatalogRouteNames } from './modules/data-catalog/constants';
import { routeNames as dataTakeoutRouteNames } from './modules/data-takeout/constants';
import DataTakeout from './modules/data-takeout/Index';
import { routeNames as devBranchesRouteNames } from './modules/dev-branches/constants';
import devBranchesRoutes from './modules/dev-branches/routes';
import flowRoutes from './modules/flows/routes';
import homeRoute from './modules/home/routes';
import jobRoutes from './modules/jobs/Routes';
import { routeNames as fakeLegacyTransformationRouteNames } from './modules/legacy-transformation/Constants';
import fakeLegacyTransformationRoutes from './modules/legacy-transformation/Routes';
import lineage from './modules/lineage/routes';
import { routeNames as legacyOrchestrationsRouteNames } from './modules/orchestrations/Constants';
import orchestrationRoutes from './modules/orchestrations/Routes';
import { routeNames as orchestrationsRouteNames } from './modules/orchestrations-v2/constants';
import orchestrations from './modules/orchestrations-v2/routes';
import queueRoutes from './modules/queue/routes';
import { routeNames as sandboxesRouteNames } from './modules/sandboxes/Constants';
import workspacesRoutes from './modules/sandboxes/routes';
import { routeNames as settingsRouteNames } from './modules/settings/constants';
import settings from './modules/settings/routes';
import snowflakePartnerConnectRoutes from './modules/snowflake-partner-connect/routes';
import { routeNames as storageRouteNames } from './modules/storage/constants';
import storageRoutes from './modules/storage/routes';
import tableBrowser from './modules/table-browser/routes';
import telemetryRoutes from './modules/telemetry/routes';
import { routeNames as templatesRouteNames } from './modules/templates/constants';
import templatesRoutes from './modules/templates/routes';
import { routeNames as legacyTransformationsRouteNames } from './modules/transformations/Constants';
import legacyTransformationRoutes from './modules/transformations/Routes';
import { routeNames as transformationsRouteNames } from './modules/transformations-v2/constants';
import transformations from './modules/transformations-v2/routes';
import { routeNames as trashRouteNames } from './modules/trash/constants';
import trashRoutes from './modules/trash/routes';
import { RouterLink as Link } from './react/common';
import App from './react/layout/App';
import ErrorPage from './react/pages/ErrorPage';

export const routesDisabledInBranch = [
  templatesRouteNames.TEMPLATES,
  dataCatalogRouteNames.SHARED_WITH_YOU,
  dataCatalogRouteNames.SHARED_FROM_THIS_PROJECT,
  fakeLegacyTransformationRouteNames.ROOT,
  `${legacyTransformationsRouteNames.ROOT}/${legacyTransformationsRouteNames.SANDBOX}`,
  legacyOrchestrationsRouteNames.ROOT,
  dataTakeoutRouteNames.DATA_TAKEOUT,
  settingsRouteNames.PROJECT,
  settingsRouteNames.USERS,
  settingsRouteNames.TOKENS,
  settingsRouteNames.CLI,
  transformationsRouteNames.MODELS,
  `${transformationsRouteNames.ROOT}/${KEBOOLA_NO_CODE_DBT_TRANSFORMATION}`,
  dataAppsRouteNames.ROOT,
  `${storageRouteNames.ROOT}/data-streams`,
];

export const routesDisabledInDemoPreview = [
  dataCatalogRouteNames.SHARED_WITH_YOU,
  dataCatalogRouteNames.SHARED_FROM_THIS_PROJECT,
  legacyTransformationsRouteNames.ROOT,
  `${transformationsRouteNames.ROOT}/workspaces`,
  legacyOrchestrationsRouteNames.ROOT,
  orchestrationsRouteNames.ROOT,
  dataTakeoutRouteNames.DATA_TAKEOUT,
  settingsRouteNames.PROJECT,
  settingsRouteNames.USERS,
  settingsRouteNames.TOKENS,
  settingsRouteNames.CLI,
  transformationsRouteNames.MODELS,
];

export const routesDisabledInProduction = [devBranchesRouteNames.DEVELOPMENT_OVERVIEW];

export const routesDisabledInSoxProduction = [trashRouteNames.SETTINGS_TRASH];

export const routesDisabledInSox = [`${storageRouteNames.ROOT}/data-streams`];

export const routesDisabledInSPCTrial = [
  templatesRouteNames.TEMPLATES,
  transformationsRouteNames.ROOT,
  sandboxesRouteNames.WORKSPACES,
];

export default {
  handler: App,
  ...homeRoute,
  notFoundRouteHandler: ErrorPage,
  childRoutes: [
    fakeLegacyTransformationRoutes,
    components,
    transformations, // new
    orchestrations, // new
    flowRoutes, // new
    dataAppsRoutes,
    sharedWithYouRouter,
    sharedFromProjectRouter,
    storageRoutes,
    orchestrationRoutes,
    legacyTransformationRoutes,
    workspacesRoutes,
    jobRoutes,
    queueRoutes,
    bucketBrowser,
    tableBrowser,
    lineage,
    trashRoutes,
    templatesRoutes,
    devBranchesRoutes,
    snowflakePartnerConnectRoutes,
    ...settings,
    ...billingRoutes,
    ...telemetryRoutes,
    {
      name: dataTakeoutRouteNames.DATA_TAKEOUT,
      title: 'Data Takeout',
      breadcrumbHandler: () => (
        <Link className="dark muted" to={settingsRouteNames.PROJECT}>
          Settings
        </Link>
      ),
      defaultRouteHandler: DataTakeout,
    },
  ],
};
