import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { List, Map, OrderedMap } from 'immutable';

import { URLS } from '@keboola/constants';
import { cn, HelpBlock, Icon, Link, Tooltip } from '@keboola/design';

import * as componentFlags from '@/constants/componentFlags';
import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { SIDEBAR } from '@/constants/external';
import dayjs from '@/date';
import {
  getAllowedTransformations,
  getDestinationTypeFromStagingStorage,
  getSourceTypeFromStagingStorage,
  prepareCreatedFromMetadata,
} from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import DocumentationLink from '@/modules/components/react/components/DocumentationLink';
import FileInputMapping from '@/modules/components/react/components/generic/FileInputMapping';
import FileOutputMapping from '@/modules/components/react/components/generic/FileOutputMapping';
import TableInputMapping from '@/modules/components/react/components/generic/TableInputMapping';
import TableInputMappingReadOnlyInfo from '@/modules/components/react/components/generic/TableInputMappingReadOnlyInfo';
import TableOutputMapping from '@/modules/components/react/components/generic/TableOutputMapping';
import MappingWrapper from '@/modules/components/react/components/MappingsWrapper';
import SidebarJobs from '@/modules/components/react/components/SidebarJobs';
import SidebarVersions from '@/modules/components/react/components/SidebarVersions';
import { GenericConfigBody } from '@/modules/components/react/pages/GenericConfigBody';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import VersionsStore from '@/modules/components/stores/VersionsStore';
import { routeNames as componentsRoutes } from '@/modules/components-directory/constants';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import LatestJobsStore from '@/modules/jobs/stores/LatestJobsStore';
import NotificationsStore from '@/modules/notifications/store';
import JobsStore from '@/modules/queue/store';
import RuntimesStore from '@/modules/runtimes/store';
import SandboxesActions from '@/modules/sandboxes/Actions';
import {
  AUTO_SLEEP_NODE,
  CONTAINER_BASED,
  DISABLE_SHARING_MESSAGE,
  ENABLE_SHARING_MESSAGE,
  ONLY_READONLY_STORAGE,
  routeNames,
  SANDBOX_LABELS,
  SANDBOX_TYPE,
} from '@/modules/sandboxes/Constants';
import {
  canCreateTransformation,
  getBackendSizeNote,
  prepareSandboxes,
  prepareSandboxTypeLabel,
  resolveComponentIdFromSandboxType,
} from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import { CreatedDate, RouterLink } from '@/react/common';
import Confirm from '@/react/common/Confirm';
import CreatedFrom from '@/react/common/CreatedFrom';
import ParametersBox from '@/react/common/CredentialsBox';
import Loader from '@/react/common/Loader';
import useStores from '@/react/hooks/useStores';
import ErrorContent from '@/react/pages/ErrorContent';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import string from '@/utils/string';
import CreateTransformationModal from './CreateTransformationModal';
import { CredentialsModal } from './CredentialsModal';
import LoadDataButton from './LoadDataButton';
import OwnerAndSharing from './OwnerAndSharing';
import RestoreWorkspace from './RestoreWorkspace';
import UpdateTransformationModal from './UpdateTransformationModal';

const SandboxDetail = () => {
  const state = useStores(
    () => {
      const componentId = KEBOOLA_SANDBOXES;
      const configId = RoutesStore.getCurrentRouteParam('config');
      const sapiToken = ApplicationStore.getSapiToken();
      const configData = InstalledComponentsStore.getConfigData(componentId, configId);
      const sandbox = SandboxesStore.getSandbox(configData.getIn(['parameters', 'id']));
      const isCurrentOwner = sandbox.get('tokenId') === sapiToken.get('id');
      const hasNewQueue = ApplicationStore.hasNewQueue();

      return {
        componentId,
        configId,
        sandbox,
        configData,
        sapiToken,
        hasNewQueue,
        createdFrom: prepareCreatedFromMetadata(
          componentId,
          configId,
          InstalledComponentsStore.getAll(),
          InstalledComponentsStore.getAllMetadata(),
        ),
        tables: StorageTablesStore.getAll(),
        buckets: StorageBucketsStore.getAll(),
        allConfigurations: InstalledComponentsStore.getAll() as Map<string, any>,
        component: ComponentsStore.getComponent(
          resolveComponentIdFromSandboxType(sandbox.get('type')),
        ),
        allowedTransformationComponents: getAllowedTransformations(
          ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
          ApplicationStore.getSapiToken(),
          ApplicationStore.getCurrentProjectFeatures(),
          StackFeaturesStore.getAll(),
        ),
        sandboxes: prepareSandboxes(
          SandboxesStore.getSandboxes(),
          InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SANDBOXES),
        ),
        latestJobs: hasNewQueue
          ? JobsStore.getLatestJobs(componentId, configId)
          : LatestJobsStore.getJobs(componentId, configId),
        componentsMetadata: InstalledComponentsStore.getAllMetadata() as Map<string, any>,
        sandboxComponent: ComponentsStore.getComponent(componentId),
        config: InstalledComponentsStore.getConfig(componentId, configId),
        componentPendingActions: InstalledComponentsStore.getPendingActions(componentId, configId),
        pendingActions: SandboxesStore.getPendingActions() as Map<string, any>,
        mlflowInstanceUrl: SandboxesStore.getMlflowInstanceUrl(),
        admins: ApplicationStore.getAdmins(),
        currentAdmin: ApplicationStore.getCurrentAdmin(),
        notifications: NotificationsStore.getAll(),
        versions: VersionsStore.getVersions(componentId, configId),
        versionsConfigs: VersionsStore.getVersionsConfigs(componentId, configId),
        isLoadingVersions: VersionsStore.isLoadingVersions(componentId, configId),
        isPendingVersions: VersionsStore.isPendingConfig(componentId, configId),
        pendingMultiLoadVersions: VersionsStore.getPendingMultiLoad(componentId, configId),
        isDevModeActive: DevBranchesStore.isDevModeActive(),
        readOnly: !sandbox.get('shared') && !isCurrentOwner,
        hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
        userReadOnly: ApplicationStore.isReadOnly(),
      };
    },
    [],
    [
      ApplicationStore,
      RoutesStore,
      DevBranchesStore,
      SandboxesStore,
      InstalledComponentsStore,
      StorageTablesStore,
      StorageBucketsStore,
      ComponentsStore,
      NotificationsStore,
      LatestJobsStore,
      JobsStore,
      VersionsStore,
      StackFeaturesStore,
    ],
  );

  const [showCreateTransformationModal, setShowCreateTransformationModal] = useState(false);
  const [showUpdateTransformationModal, setShowUpdateTransformationModal] = useState(false);
  const [showCredentialsModal, setShowCredentialsModal] = useState(false);

  if (!state.sandbox.count()) {
    return <ErrorContent headerText="Workspace not found" />;
  }

  const handleTerminate = () => {
    return SandboxesActions.terminateSandbox(state.sandbox.get('id'), state.config.get('id'));
  };

  const handleUnloadData = () => {
    return SandboxesActions.unloadData(
      state.sandbox.get('id'),
      state.config.get('id'),
      state.configData.getIn(['storage', 'output'], Map()).toJS(),
    );
  };

  const handleDelete = () => {
    RoutesStore.getRouter().transitionTo(routeNames.WORKSPACES);
    return SandboxesActions.deleteSandbox(state.sandbox.get('id'), state.config.get('id'));
  };

  const isTerminating = state.pendingActions.hasIn(['terminate', state.sandbox.get('id')]);

  const isRestoring = state.pendingActions.hasIn(['restore', state.sandbox.get('id')]);

  const renderConnectButton = () => {
    const isActive = state.sandbox.get('active');
    const hideTooltip = isActive && !state.readOnly;
    const isDisabled = !isActive || state.readOnly;

    return (
      <>
        <Tooltip
          placement="top"
          forceHide={hideTooltip}
          tooltip={state.readOnly ? 'Workspace is private' : 'Workspace is inactive'}
        >
          <Button
            block
            bsStyle="success"
            className={cn({ disabled: isDisabled })}
            onClick={() => !isDisabled && setShowCredentialsModal(true)}
          >
            <Icon icon="circle-play" className="icon-addon-right" fixedWidth />
            Connect
          </Button>
        </Tooltip>
        <hr />
      </>
    );
  };

  const renderMlFlowButton = () => {
    if (
      SANDBOX_TYPE.PYTHON_MLFLOW !== state.sandbox.get('type') ||
      !state.mlflowInstanceUrl ||
      state.isDevModeActive
    ) {
      return null;
    }

    return (
      <Link className="btn btn-link btn-block btn-link-inline" href={state.mlflowInstanceUrl}>
        <Icon icon="arrow-up-right-from-square" />
        Open MLflow
      </Link>
    );
  };

  const renderRestoreButton = () => {
    if (
      state.sandbox.get('active') ||
      !CONTAINER_BASED.includes(state.sandbox.get('type')) ||
      state.userReadOnly
    ) {
      return null;
    }

    return (
      <RestoreWorkspace
        mode="sidebar"
        config={state.config}
        sandbox={state.sandbox}
        configData={state.configData}
        isRestoring={isRestoring}
        hasPayAsYouGo={state.hasPayAsYouGo}
      />
    );
  };

  const renderTerminateButton = () => {
    if (
      !state.sandbox.get('active') ||
      !CONTAINER_BASED.includes(state.sandbox.get('type')) ||
      state.userReadOnly
    ) {
      return null;
    }

    return (
      <Confirm
        icon="circle-pause"
        buttonType="danger"
        title="Sleep Workspace"
        text={
          <>
            Are you sure you want to sleep the workspace <strong>{state.config.get('name')}</strong>
            ?
          </>
        }
        buttonLabel="Sleep Workspace"
        onConfirm={handleTerminate}
        isDisabled={isTerminating || state.readOnly}
        childrenRootElement="a"
      >
        {isTerminating ? <Loader /> : <Icon icon="circle-pause" fixedWidth />}Sleep workspace
      </Confirm>
    );
  };

  const renderLoadButton = () => {
    if (state.userReadOnly) {
      return null;
    }

    return (
      <LoadDataButton
        configId={state.config.get('id')}
        sandbox={state.sandbox}
        configData={state.configData}
        pendingActions={state.pendingActions}
        readOnly={state.readOnly}
      />
    );
  };

  const renderUnloadButton = () => {
    if (!CONTAINER_BASED.includes(state.sandbox.get('type')) || state.userReadOnly) {
      return null;
    }

    const isActive = state.sandbox.get('active');
    const hasOutputMapping = state.configData.getIn(['storage', 'output'], Map()).count() > 0;
    const isUnloadingData = state.pendingActions.hasIn(['unload-data', state.sandbox.get('id')]);

    return (
      <Confirm
        icon="cloud-arrow-down"
        buttonType="success"
        title="Unload Data from Workspace"
        text="You are about to unload data based on the output mapping."
        buttonLabel="Unload Data"
        onConfirm={handleUnloadData}
        isDisabled={!isActive || !hasOutputMapping || isUnloadingData || state.readOnly}
        disabledReason={
          state.readOnly
            ? ''
            : !isActive
              ? 'Workspace is inactive'
              : isUnloadingData
                ? ''
                : 'No output mapping defined'
        }
        childrenRootElement="a"
        childrenRootElementClass="tw-flex"
      >
        {isUnloadingData ? <Loader /> : <Icon icon="cloud-arrow-down" fixedWidth />}Unload data
      </Confirm>
    );
  };

  const renderShareButton = () => {
    if (state.sandbox.get('tokenId') !== state.sapiToken.get('id') || state.userReadOnly) {
      return null;
    }

    const isSharePending = state.pendingActions.hasIn(['share', state.sandbox.get('id')]);

    if (state.sandbox.get('shared')) {
      return (
        <Confirm
          closeAfterResolve
          buttonType="success"
          icon="share"
          title="Disable Sharing"
          text={DISABLE_SHARING_MESSAGE}
          buttonLabel="Disable sharing"
          onConfirm={() => SandboxesActions.unshareSandbox(state.sandbox.get('id'))}
          isDisabled={isSharePending || state.readOnly}
          isLoading={isSharePending}
          childrenRootElement="a"
        >
          <Icon icon="share" fixedWidth />
          Disable sharing
        </Confirm>
      );
    }

    return (
      <Confirm
        closeAfterResolve
        buttonType="success"
        icon="share"
        title="Enable Sharing"
        text={ENABLE_SHARING_MESSAGE}
        buttonLabel="Enable sharing"
        onConfirm={() => SandboxesActions.shareSandbox(state.sandbox.get('id'))}
        isDisabled={isSharePending || state.readOnly}
        isLoading={isSharePending}
        childrenRootElement="a"
      >
        <Icon icon="share" fixedWidth />
        Enable sharing
      </Confirm>
    );
  };

  const renderCreateTransformationButton = () => {
    if (!canCreateTransformation(state.sandbox.get('type')) || state.userReadOnly) {
      return null;
    }

    return (
      <Button
        block
        bsStyle="link"
        className="btn-link-inline"
        onClick={() => setShowCreateTransformationModal(true)}
        disabled={state.readOnly}
      >
        <span className="fa-layers">
          <Icon icon="circle" />
          <Icon icon="gear" transform="shrink-8" style={{ color: '#fff' }} />
        </span>
        Create new transformation
      </Button>
    );
  };

  const renderCopyToExistingTransformationButton = () => {
    if (!canCreateTransformation(state.sandbox.get('type')) || state.userReadOnly) {
      return null;
    }

    return (
      <Button
        block
        bsStyle="link"
        className="btn-link-inline"
        onClick={() => setShowUpdateTransformationModal(true)}
        disabled={state.readOnly}
      >
        <span className="fa-layers">
          <Icon icon="circle" />
          <Icon icon="gear" transform="shrink-8" style={{ color: '#fff' }} />
        </span>
        Copy to existing transformation
      </Button>
    );
  };

  const renderRawConfigurationButton = () => {
    return (
      <li>
        <RouterLink
          to={componentsRoutes.GENERIC_CONFIG_RAW}
          params={{ component: KEBOOLA_SANDBOXES, config: state.configId }}
          className="btn btn-link btn-block btn-link-inline"
        >
          <Icon icon="bug" fixedWidth />
          Debug mode
        </RouterLink>
      </li>
    );
  };

  const renderDeleteButton = () => {
    if (state.userReadOnly) {
      return null;
    }

    const isDeleting = state.pendingActions.hasIn(['delete', state.sandbox.get('id')]);
    const isLoadingData = state.pendingActions.hasIn(['load-data', state.sandbox.get('id')]);

    return (
      <>
        <hr />
        <Confirm
          icon="trash"
          buttonType="danger"
          title="Delete Workspace"
          text={
            <>
              Are you sure you want to delete the workspace{' '}
              <strong>{state.config.get('name')}</strong>?
            </>
          }
          buttonLabel="Delete"
          onConfirm={handleDelete}
          isDisabled={isDeleting || isLoadingData || state.readOnly}
          childrenRootElement="a"
        >
          {isDeleting ? <Loader /> : <Icon icon="trash" fixedWidth />}
          Delete workspace
        </Confirm>
      </>
    );
  };

  const renderDocumentationButton = () => {
    return <DocumentationLink href={`${URLS.USER_DOCUMENTATION}/transformations/workspace`} />;
  };

  const renderCreatedFrom = () => {
    return <CreatedFrom from={state.createdFrom} />;
  };

  const renderDetail = () => {
    let rows = OrderedMap<string, Record<string, any>>({
      'Workspace ID': {
        noCopy: true,
        text: state.sandbox.get('id'),
      },
      Type: {
        noCopy: true,
        text: prepareSandboxTypeLabel(state.sandbox.get('type')),
      },
      Created: {
        noCopy: true,
        component: <CreatedDate createdTime={state.sandbox.get('createdTimestamp')} />,
      },
      'Last Change': {
        noCopy: true,
        component: <CreatedDate createdTime={state.sandbox.get('updatedTimestamp')} />,
      },
      'Owner, Sharing': {
        noCopy: true,
        component: (
          <OwnerAndSharing
            sandbox={state.sandbox}
            config={state.config}
            sapiToken={state.sapiToken}
            admins={state.admins}
          />
        ),
      },
    });

    const runtimes = RuntimesStore.getRuntimes(
      resolveComponentIdFromSandboxType(state.sandbox.get('type')) ?? '',
    );

    if (runtimes.length !== 0) {
      const activeVersion = state.sandbox.get('imageVersion', '');
      const runtime =
        runtimes.find((runtime) => runtime.sandboxImageTag === activeVersion) ??
        runtimes.find((runtime) => runtime.isTypeDefault) ??
        null;

      rows = rows.set('Backend Version', {
        noCopy: true,
        text: runtime?.description ?? activeVersion,
      });
    }

    if (!!state.sandbox.get('size')) {
      const sandboxSize = state.sandbox.get('size');

      rows = rows.set('Backend Size', {
        noCopy: true,
        text: SANDBOX_LABELS[sandboxSize] || string.capitalize(sandboxSize),
        ...(!state.hasPayAsYouGo && { hint: getBackendSizeNote() }),
      });
    }

    const expiration = state.sandbox.get('expirationAfterHours');

    if (expiration > 0) {
      rows = rows.set('Auto Sleep', {
        noCopy: true,
        text: `Activated (${expiration} ${string.pluralize(expiration, 'hour')})`,
        hint: AUTO_SLEEP_NODE,
      });
    } else if (!!state.sandbox.get('expirationTimestamp')) {
      const expiration = dayjs(state.sandbox.get('expirationTimestamp'));

      rows = rows.set(expiration > dayjs() ? 'Expires' : 'Expired', {
        noCopy: true,
        text: expiration.fromNow(),
      });
    }

    if (state.configData.getIn(['parameters', 'packages'], List()).count() > 0) {
      rows = rows.set(
        'Packages',
        state.configData.getIn(['parameters', 'packages']).toArray().join(', '),
      );
    }

    if (!!state.sandbox.getIn(['persistentStorage', 'pvcName'])) {
      rows = rows.set('Data Persistency', {
        hint: 'Changes are saved with Auto Sleep. Ask our support team to disable it.',
        noCopy: true,
        text: 'Enabled',
      });
    }

    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">Workspace Parameters</h2>
        </div>
        <div className="box-content">
          <ParametersBox rows={rows} noBorder />
          {renderPackagesInfo()}
        </div>
      </div>
    );
  };

  const renderPackagesInfo = () => {
    let packagesLink = null;

    switch (state.sandbox.get('type')) {
      case SANDBOX_TYPE.PYTHON:
        packagesLink = `${URLS.USER_DOCUMENTATION}/transformations/python-plain#packages`;
        break;

      case SANDBOX_TYPE.R:
        packagesLink = `${URLS.USER_DOCUMENTATION}/transformations/r-plain#packages`;
        break;
    }

    if (!packagesLink) {
      return null;
    }

    return (
      <HelpBlock className="tw-mt-1">
        This workspace came with pre-installed packages. Read more in the{' '}
        <Link href={packagesLink}>documentation</Link>.
      </HelpBlock>
    );
  };

  const tableInputMapping = () => {
    if (ONLY_READONLY_STORAGE.includes(state.sandbox.get('type'))) {
      return <TableInputMappingReadOnlyInfo />;
    }

    if (!state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_TABLE_INPUT)) {
      return null;
    }

    return (
      <TableInputMapping
        readOnly={state.readOnly || state.userReadOnly}
        componentId={state.componentId}
        replacementComponentId={state.component.get('id')}
        destinationType={getDestinationTypeFromStagingStorage(
          state.component.getIn(['data', 'staging_storage', 'input']),
        )}
        configId={state.configId}
        onDeleteMappings={(...args: unknown[]) =>
          InstalledComponentsActionCreators.deleteMappings(state.configData, ...args)
        }
        value={state.configData.getIn(['storage', 'input', 'tables'], List())}
        tables={state.tables}
        buckets={state.buckets}
        allowedComponents={state.allowedTransformationComponents}
        sandboxes={state.sandboxes}
        hasPayAsYouGo={state.hasPayAsYouGo}
      />
    );
  };

  const fileInputMapping = () => {
    if (!state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_FILE_INPUT)) {
      return null;
    }

    return (
      <FileInputMapping
        readOnly={state.readOnly || state.userReadOnly}
        componentId={state.componentId}
        configId={state.configId}
        value={state.configData.getIn(['storage', 'input', 'files'], List())}
        onDeleteMappings={(...args: unknown[]) =>
          InstalledComponentsActionCreators.deleteMappings(state.configData, ...args)
        }
        allowedComponents={state.allowedTransformationComponents}
        sandboxes={state.sandboxes}
        hasPayAsYouGo={state.hasPayAsYouGo}
      />
    );
  };

  const tableOutputMapping = () => {
    if (!state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_TABLE_OUTPUT)) {
      return null;
    }

    return (
      <TableOutputMapping
        readOnly={state.readOnly || state.userReadOnly}
        componentId={state.componentId}
        configId={state.configId}
        onDeleteMappings={(...args: unknown[]) =>
          InstalledComponentsActionCreators.deleteMappings(state.configData, ...args)
        }
        configName={state.config.get('name')}
        value={state.configData.getIn(['storage', 'output', 'tables'], List())}
        tables={state.tables}
        buckets={state.buckets}
        sourceType={getSourceTypeFromStagingStorage(
          state.component.getIn(['data', 'staging_storage', 'output']),
        )}
      />
    );
  };

  const fileOutputMapping = () => {
    if (!state.component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_FILE_OUTPUT)) {
      return null;
    }

    return (
      <FileOutputMapping
        readOnly={state.readOnly || state.userReadOnly}
        componentId={state.componentId}
        configId={state.configId}
        value={state.configData.getIn(['storage', 'output', 'files'], List())}
        onDeleteMappings={(...args: unknown[]) =>
          InstalledComponentsActionCreators.deleteMappings(state.configData, ...args)
        }
      />
    );
  };

  const renderSidebar = () => {
    return (
      <div className={`sidebar-content ${SIDEBAR}`}>
        <div className="nav nav-stacked">
          {renderConnectButton()}
          {renderMlFlowButton()}
          {renderRestoreButton()}
          {renderTerminateButton()}
          {renderLoadButton()}
          {renderUnloadButton()}
          {renderShareButton()}
          {renderCreateTransformationButton()}
          {renderCopyToExistingTransformationButton()}
          {renderRawConfigurationButton()}
          {renderDocumentationButton()}
          {renderDeleteButton()}
        </div>
        {renderCreatedFrom()}
        <SidebarJobs
          jobs={state.latestJobs}
          componentId={KEBOOLA_SANDBOXES}
          configId={state.configId}
          hasNewQueue={state.hasNewQueue}
          allConfigurations={state.allConfigurations}
          admins={state.admins}
          notifications={state.notifications}
          currentAdmin={state.currentAdmin}
        />
        <hr />
        <SidebarVersions
          configId={state.configId}
          component={state.sandboxComponent}
          config={state.config}
          versionsLinkTo={routeNames.WORKSPACE_VERSIONS}
          versions={state.versions}
          versionsConfigs={state.versionsConfigs}
          isLoading={state.isLoadingVersions}
          isPending={state.isPendingVersions}
          pendingMultiLoad={state.pendingMultiLoadVersions}
          admins={state.admins}
        />
      </div>
    );
  };

  return (
    <GenericConfigBody
      key={`${state.componentId}-${state.configId}`}
      componentId={state.componentId}
      configId={state.configId}
      sidebar={renderSidebar()}
    >
      {renderDetail()}
      <MappingWrapper>
        {tableInputMapping()}
        {fileInputMapping()}
        {tableOutputMapping()}
        {fileOutputMapping()}
      </MappingWrapper>
      <CreateTransformationModal
        config={state.config}
        sandbox={state.sandbox}
        show={showCreateTransformationModal}
        onHide={() => setShowCreateTransformationModal(false)}
        transformationComponent={state.component}
      />
      <UpdateTransformationModal
        config={state.config}
        metadata={state.componentsMetadata}
        transformationComponent={state.component}
        existingTransformations={state.allConfigurations.getIn(
          [state.component.get('id'), 'configurations'],
          Map(),
        )}
        show={showUpdateTransformationModal}
        onHide={() => setShowUpdateTransformationModal(false)}
      />
      <CredentialsModal
        sandbox={state.sandbox}
        show={showCredentialsModal}
        onHide={() => setShowCredentialsModal(false)}
      />
    </GenericConfigBody>
  );
};

export default SandboxDetail;
