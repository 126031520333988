import type { Map } from 'immutable';

import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import ComponentsActionCreators from '@/modules/components/ComponentsActionCreators';
import InstalledComponentsActionsCreators from '@/modules/components/InstalledComponentsActionCreators';
import { ComponentNameEdit } from '@/modules/components/react/components/ComponentNameEdit';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import createVersionsPageRoute from '@/modules/components/utils/createVersionsPageRoute';
import VersionsActionCreators from '@/modules/components/VersionsActionCreators';
import WorkspacesBillingAlert from '@/modules/home/react/WorkspacesBillingAlert';
import WorkspacesReadOnlyStorageAlert from '@/modules/home/react/WorkspacesReadOnlyStorageAlert';
import ApplicationStore from '@/stores/ApplicationStore';
import { configPoll } from '@/utils/genericPolls';
import SandboxDetail from './components/SandboxDetail';
import SandboxesHeaderButton from './components/SandboxesHeaderButton';
import SandboxesIndex from './components/SandboxesIndex';
import SandboxStatusLabel from './components/SandboxStatusLabel';
import SandboxesActions from './Actions';
import { routeNames } from './Constants';
import { resolveComponentIdFromSandboxType } from './helpers';
import SandboxesStore from './SandboxesStore';

const routes = {
  name: routeNames.WORKSPACES,
  title: 'Workspaces',
  defaultRouteHandler: SandboxesIndex,
  infoAlertHandler: WorkspacesBillingAlert,
  headerButtonsHandler: SandboxesHeaderButton,
  requireData: [() => SandboxesActions.loadIndexData()],
  poll: {
    skipFirst: true,
    action: () => SandboxesActions.loadIndexData(),
  },
  childRoutes: [
    {
      name: routeNames.WORKSPACE,
      path: ':config',
      title(routerState: Map<string, any>) {
        const config = InstalledComponentsStore.getConfig(
          KEBOOLA_SANDBOXES,
          routerState.getIn(['params', 'config']),
        );
        return config.get('name');
      },
      nameEdit(params: { config: string }) {
        const config = InstalledComponentsStore.getConfig(KEBOOLA_SANDBOXES, params.config);
        const sandbox = SandboxesStore.getSandbox(
          config.getIn(['configuration', 'parameters', 'id']),
        );

        return (
          <ComponentNameEdit
            key={`${KEBOOLA_SANDBOXES}-${params.config}`}
            componentId={KEBOOLA_SANDBOXES}
            configId={params.config}
            readOnly={sandbox.get('tokenId') !== ApplicationStore.getSapiToken().get('id')}
          />
        );
      },
      defaultRouteHandler: SandboxDetail,
      infoAlertHandler: WorkspacesReadOnlyStorageAlert,
      labelHandler: SandboxStatusLabel,
      poll: configPoll(KEBOOLA_SANDBOXES, (params) => {
        const sandboxId = InstalledComponentsStore.getConfigData(
          KEBOOLA_SANDBOXES,
          params.config,
        ).getIn(['parameters', 'id']);

        return SandboxesActions.loadSandbox(sandboxId);
      }),
      requireData: [
        () => StorageActionCreators.loadBucketsAndTables(),
        (params: { config: string }) => {
          return InstalledComponentsActionsCreators.loadComponentConfigData(
            KEBOOLA_SANDBOXES,
            params.config,
          ).then(() => {
            const sandboxId = InstalledComponentsStore.getConfigData(
              KEBOOLA_SANDBOXES,
              params.config,
            ).getIn(['parameters', 'id']);

            return SandboxesActions.loadSandbox(sandboxId).then(() => {
              const sandbox = SandboxesStore.getSandbox(sandboxId);
              const componentId = resolveComponentIdFromSandboxType(sandbox.get('type'));

              if (!ComponentsStore.hasComponentDetails(componentId)) {
                return ComponentsActionCreators.loadComponentForce(componentId);
              }
            });
          });
        },
        (params: { config: string }) =>
          VersionsActionCreators.loadVersions(KEBOOLA_SANDBOXES, params.config),
      ],
      childRoutes: [
        createVersionsPageRoute(KEBOOLA_SANDBOXES, 'config', routeNames.WORKSPACE_VERSIONS),
      ],
    },
  ],
};

export default routes;
