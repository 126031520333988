import { cn, Icon } from '@keboola/design';

const svgGradient = (
  <svg className="tw-pointer-events-none tw-absolute tw-h-0">
    <defs>
      <linearGradient
        xmlns="http://www.w3.org/2000/svg"
        id="ai_gradient"
        x1="0%"
        y1="100%"
        x2="100%"
        y2="0%"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0%" stopColor="#0975E0" />
        <stop offset="100%" stopColor="#6F36E0" />
      </linearGradient>
    </defs>
  </svg>
);

export const AiIcon = ({ className }: { className?: string }) => (
  <>
    {svgGradient}
    <Icon
      icon="brain-circuit"
      className={cn('tw-text-[16px] [&_path]:tw-fill-[url(#ai_gradient)]', className)}
    />
  </>
);
