import type { ReactNode } from 'react';

import RowWrapper from './RowWrapper';

const RowWithRenderer = <T,>({
  title,
  value,
  children,
}: {
  title: string;
  value: T;
  children: (value: T) => ReactNode;
}) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return null;
  }

  return <RowWrapper title={title}>{children(value)}</RowWrapper>;
};

export default RowWithRenderer;
