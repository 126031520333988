import { Suspense } from 'react';
import PropTypes from 'prop-types';
import { resolve } from 'react-router-named-routes';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { cn } from '@keboola/design';

import RouterActionCreators from '@/actions/RouterActionCreators';
import { routesNames } from '@/constants/external';
import {
  FEATURE_IS_SINGLE_TENANT,
  FEATURE_POWER_USER,
  FEATURE_SNOWFLAKE_PARTNER_CONNECT,
  FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED,
} from '@/constants/features';
import { DevButtons } from '@/dev/DevButtons';
import Automation from '@/modules/automations/Automation';
import BackToProductionWarning from '@/modules/dev-branches/components/BackToProductionWarning';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import WelcomeModalGcp from '@/modules/gcp/components/WelcomeModal';
import { getCampaignTarget, isValidCampaignTarget } from '@/modules/home/helpers';
import Expiration from '@/modules/home/react/Expiration';
import WelcomeModal from '@/modules/snowflake-partner-connect/components/WelcomeModal';
import { routeNames as snowflakePartnerConnectRouteNames } from '@/modules/snowflake-partner-connect/constants';
import UpgradeSPCBar from '@/modules/snowflake-partner-connect/UpgradeBar';
import StackFeaturesStore from '@/modules/stack-features/Store';
import TopBarNav from '@/react/admin/project/TopBarNav';
import Confetti from '@/react/common/Confetti';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { handleError } from '@/utils/promise';
import { BackFlowModal } from './BackFlowModal';
import { CanaryStackBar } from './CanaryStackBar';
import CreateProjectBar from './CreateProjectBar';
import DevModeBar from './DevModeBar';
import DocumentTitle from './DocumentTitle';
import Favicon from './Favicon';
import FloatingNotifications from './FloatingNotifications';
import Hotkeys from './Hotkeys';
import { MainContainer } from './MainContainer';
import PageTitle from './PageTitle';
import ProductFruits from './ProductFruits';
import ReleaseManager from './ReleaseManager';
import ScrollAndFocusManager from './ScrollAndFocusManager';
import TopUpBar from './TopUpBar';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: handleError,
  }),
  mutationCache: new MutationCache({
    onError: handleError,
  }),
});

const App = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, DevBranchesStore, StackFeaturesStore, RoutesStore)],

  getStateFromStores() {
    const currentProject = ApplicationStore.getCurrentProject();

    return {
      currentProject,
      currentOrganization: ApplicationStore.getCurrentOrganization(),
      organizations: ApplicationStore.getOrganizations(),
      currentDevBranch: DevBranchesStore.getCurrentDevBranch(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      projectFeatures: ApplicationStore.getCurrentProjectFeatures(),
      projectExpiration: currentProject.get('expires'),
      isDemoPreview: ApplicationStore.isDemoPreview(),
      hasSnowflakePartnerConnect: ApplicationStore.hasCurrentProjectFeature(
        FEATURE_SNOWFLAKE_PARTNER_CONNECT,
      ),
      hasSnowflakePartnerConnectLimited: ApplicationStore.hasCurrentProjectFeature(
        FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED,
      ),
      hasInvalidCustomBackend: ApplicationStore.hasInvalidCustomBackend(),
      currentDevBranchMergeRequest: DevBranchesStore.getCurrentDevBranchMergeRequest(),
      hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
      sapiToken: ApplicationStore.getSapiToken(),
      readOnly: ApplicationStore.isReadOnly(),
      isPowerUser: ApplicationStore.hasCurrentAdminFeature(FEATURE_POWER_USER),
      location: RoutesStore.getRouterState().get('location', Map()).toJS(),
    };
  },

  getInitialState() {
    RouterActionCreators.routerCreated({
      isActive: (name, params, index) =>
        this.props.router.isActive({ pathname: resolve(name, params) }, index),
    });

    return {
      isSingleTenant: StackFeaturesStore.hasStackFeature(FEATURE_IS_SINGLE_TENANT),
      urlTemplates: ApplicationStore.getUrlTemplates(),
      projectTemplates: ApplicationStore.getProjectTemplates(),
      canManageApps: ApplicationStore.getKbcVars().get('canManageApps'),
    };
  },

  componentDidMount() {
    this.validateAccess();
  },

  componentDidUpdate() {
    this.identifyUser();
    this.validateAccess();
  },

  validateAccess() {
    if (this.state.hasInvalidCustomBackend) {
      if (!this.state.location.pathname.includes('dashboard')) {
        RoutesStore.getRouter().transitionTo('app');
      }
    }
  },

  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <div
          className={cn(routesNames(this.props.router.routes), 'tw-flex tw-flex-col tw-h-screen', {
            'demo-preview': this.state.isDemoPreview,
          })}
        >
          <Favicon />
          <DocumentTitle />
          <Hotkeys />
          <ReleaseManager location={this.state.location} />
          <ScrollAndFocusManager location={this.state.location} />
          <FloatingNotifications />
          <CanaryStackBar />
          <CreateProjectBar />
          {this.renderUpgradeSPCBar()}
          {this.renderProjectExpirationBar()}
          <DevModeBar
            sapiToken={this.state.sapiToken}
            currentDevBranch={this.state.currentDevBranch}
            hasProtectedDefaultBranch={this.state.hasProtectedDefaultBranch}
            mergeRequest={this.state.currentDevBranchMergeRequest}
          />
          <TopUpBar />
          {!this.state.hasProtectedDefaultBranch && !this.state.isPowerUser && (
            <BackToProductionWarning />
          )}
          {this.renderLayout()}
        </div>
        <ProductFruits
          user={this.state.currentAdmin}
          sapiToken={this.state.sapiToken}
          location={this.state.location}
          isDemoPreview={this.state.isDemoPreview}
        />
        {this.renderConfetti()}
        {this.renderWelcome()}
        {this.renderSPCWelcomeModal()}
        <DevButtons />
      </QueryClientProvider>
    );
  },

  renderLayout() {
    if (this.state.location.state?.flowId && !this.state.location.state.automationId) {
      return <BackFlowModal>{this.renderPage()}</BackFlowModal>;
    }

    return (
      <>
        <TopBarNav
          user={this.state.currentAdmin}
          urlTemplates={this.state.urlTemplates}
          currentProject={this.state.currentProject}
          currentOrganization={this.state.currentOrganization}
          projectTemplates={this.state.projectTemplates}
          canManageApps={this.state.canManageApps}
          organizations={this.state.organizations}
        />
        <Automation>{this.renderPage()}</Automation>
      </>
    );
  },

  renderPage() {
    return (
      <>
        <PageTitle
          hasInvalidCustomBackend={this.state.hasInvalidCustomBackend}
          isDemoPreview={this.state.isDemoPreview}
        />
        <MainContainer>{this.props.children}</MainContainer>
      </>
    );
  },

  renderConfetti() {
    if (!this.state.location.query || typeof this.state.location.query.celebrate === 'undefined') {
      return null;
    }

    return <Confetti location={this.state.location} />;
  },

  renderWelcome() {
    if (!('welcome' in this.state.location.query)) {
      return null;
    }

    return <WelcomeModalGcp location={this.state.location} />;
  },

  renderProjectExpirationBar() {
    if (!this.state.hasSnowflakePartnerConnect || this.state.hasSnowflakePartnerConnectLimited) {
      return null;
    }

    return <Expiration expires={this.state.projectExpiration} showInStickyPanel />;
  },

  renderUpgradeSPCBar() {
    if (
      !this.state.hasSnowflakePartnerConnectLimited ||
      this.props.router.isActive(snowflakePartnerConnectRouteNames.UPGRADE_PAGE) ||
      this.state.readOnly
    ) {
      return null;
    }

    return <UpgradeSPCBar />;
  },

  renderSPCWelcomeModal() {
    if (!this.state.hasSnowflakePartnerConnect) {
      return null;
    }

    return (
      <Suspense fallback={null}>
        <WelcomeModal isUpgraded={!this.state.hasSnowflakePartnerConnectLimited} />
      </Suspense>
    );
  },

  identifyUser() {
    if (
      this.state.isSingleTenant ||
      !window.heap ||
      // we support both heap sdks - new one has getIdentity function, old one has identity property
      (window.heap.getIdentity?.() ?? window.heap.identity)
    ) {
      return;
    }

    window.heap.identify(this.state.currentAdmin.get('email'));

    const userProperties = { organization: this.state.currentOrganization.get('name') };

    if (isValidCampaignTarget(this.state.location.query.target)) {
      userProperties.landing_page = getCampaignTarget(this.state.location.query.target);
      userProperties.campaign = this.state.location.query.campaign;
    }

    window.heap.addUserProperties(userProperties);
  },
});

App.propTypes = {
  router: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default App;
