import { URLS } from '@keboola/constants';

import type { Provider } from './components/AuthenticationSettings';

export const DataAppState = {
  CREATED: 'created',
  STARTING: 'starting',
  RUNNING: 'running',
  STOPPING: 'stopping',
  STOPPED: 'stopped',
  DELETING: 'deleting',
  DELETED: 'deleted',
  SLEEPING: 'sleeping',
  RESTARTING: 'restarting',
} as const;

const routeNames = {
  ROOT: 'data-apps',
  DATA_APP_TERMINAL_LOGS: 'data-apps-terminal-logs',
  DATA_APP_DETAIL: 'apps-details',
  DATA_APP_VERSIONS: 'data-apps-versions',
  DATA_APP_NOTIFICATIONS: 'data-apps-notifications',
};

const ActionTypes = {
  DATA_APP_LOAD_SUCCESS: 'DATA_APP_LOAD_SUCCESS',
  DATA_APPS_LOAD_SUCCESS: 'DATA_APPS_LOAD_SUCCESS',
  DATA_APPS_CREATE_SUCCESS: 'DATA_APPS_CREATE_SUCCESS',
  DATA_APP_DELETE_SUCCESS: 'DATA_APP_DELETE_SUCCESS',
};

const basicProxyAuthorization = {
  app_proxy: {
    auth_providers: [{ id: 'simpleAuth', type: 'password' }],
    auth_rules: [{ type: 'pathPrefix', value: '/', auth_required: true, auth: ['simpleAuth'] }],
  },
};

const noneProxyAuthorization = {
  app_proxy: {
    auth_providers: [],
    auth_rules: [{ type: 'pathPrefix', value: '/', auth_required: false }],
  },
};

const DOCUMENTATION_LINK = `${URLS.USER_DOCUMENTATION}/components/data-apps/oidc/`;

const OIDC_PROVIDERS: Record<string, Provider> = {
  oidc: {
    name: 'oidc',
    label: 'Generic OIDC',
    url: '',
    inputs: [
      {
        label: 'Issuer URL',
        property: 'issuer_url',
      },
      {
        label: 'Logout URL',
        property: 'logout_url',
        optional: true,
        help: 'Provided by the identity provider.',
      },
    ],
  },
  azure: {
    name: 'azure',
    label: 'Azure OIDC',
    url: 'microsoftonline.com',
    inputs: [
      {
        label: 'Tenant ID',
        property: 'tenant_id',
        placeholder: '1111-111-1111-11111111',
      },
    ],
    values: {
      tenant_id: {
        property: 'issuer_url',
        regex: /microsoftonline.com\/([^/]+)/,
      },
    },
    mapping: {
      issuer_url: 'https://login.microsoftonline.com/{tenant_id}/v2.0',
      logout_url: 'https://login.microsoftonline.com/{tenant_id}/oauth2/logout',
    },
  },
  google: {
    name: 'google',
    label: 'Google SSO',
    url: 'google.com',
    defaults: {
      issuer_url: 'https://accounts.google.com',
      logout_url: 'https://accounts.google.com/Logout',
    },
  },
  okta: {
    name: 'okta',
    label: 'Okta',
    url: 'okta.com',
    inputs: [
      {
        label: 'Domain/Org URL',
        property: 'issuer_url',
        placeholder: 'https://yourdomain.okta.com/oauth2/default',
      },
      {
        label: 'Logout URL',
        property: 'logout_url',
        optional: true,
        placeholder: 'https://yourdomain.okta.com/login/signout',
      },
    ],
  },
  auth0: {
    name: 'auth0',
    label: 'Auth0',
    url: 'auth0.com',
    inputs: [
      {
        label: 'Issuer URL',
        property: 'issuer_url',
        placeholder: 'https://yourdomain.us.auth0.com',
      },
      {
        label: 'Logout URL',
        property: 'logout_url',
        optional: true,
        placeholder: 'https://yourdomain.us.auth0.com/oidc/logout',
      },
    ],
  },
};

// expiration time in seconds
const MINIMUM_EXPIRTION = 300;
const DEFAULT_EXPIRATION = 900;

const PREINSTALLED_PACKAGES = [
  'altair',
  'attrs',
  'backports.zoneinfo',
  'blinker',
  'cachetools',
  'certifi',
  'charset-normalizer',
  'click',
  'decorator',
  'gitdb',
  'GitPython',
  'idna',
  'importlib-metadata',
  'importlib-resources',
  'Jinja2',
  'jsonschema',
  'jsonschema-specifications',
  'markdown-it-py',
  'MarkupSafe',
  'mdurl',
  'numpy',
  'packaging',
  'pandas',
  'Pillow',
  'pip',
  'pkgutil_resolve_name',
  'protobuf',
  'pyarrow',
  'pydeck',
  'Pygments',
  'Pympler',
  'python-dateutil',
  'pytz',
  'pytz-deprecation-shim',
  'referencing',
  'requests',
  'rich',
  'rpds-py',
  'setuptools',
  'six',
  'smmap',
  'streamlit',
  'tenacity',
  'toml',
  'toolz',
  'tornado',
  'typing_extensions',
  'tzdata',
  'tzlocal',
  'urllib3',
  'validators',
  'watchdog',
  'wheel',
  'zipp',
];

const THEME_DEFAULT_FONT = 'sans serif';
const THEME_KEBOOLA = {
  font: THEME_DEFAULT_FONT,
  textColor: '#222529',
  backgroundColor: '#FFFFFF',
  secondaryBackgroundColor: '#E6F2FF',
  primaryColor: '#1F8FFF',
} as const;

const themes = {
  keboola: THEME_KEBOOLA,
  'light-red': {
    font: THEME_DEFAULT_FONT,
    textColor: '#222529',
    backgroundColor: '#FFFFFF',
    secondaryBackgroundColor: '#FFE6E6',
    primaryColor: '#FF5D5D',
  },
  'light-purple': {
    font: THEME_DEFAULT_FONT,
    textColor: '#222529',
    backgroundColor: '#FFFFFF',
    secondaryBackgroundColor: '#F2E6FF',
    primaryColor: '#9A6DD7',
  },
  'light-blue': {
    font: THEME_DEFAULT_FONT,
    textColor: '#222529',
    backgroundColor: '#FFFFFF',
    secondaryBackgroundColor: '#E6E6FF',
    primaryColor: '#0000B2',
  },
  'dark-green': {
    font: THEME_DEFAULT_FONT,
    textColor: '#FFFFFF',
    backgroundColor: '#222529',
    secondaryBackgroundColor: '#3D4F41',
    primaryColor: '#4CAF50',
  },
  'dark-amber': {
    font: THEME_DEFAULT_FONT,
    textColor: '#FFFFFF',
    backgroundColor: '#222529',
    secondaryBackgroundColor: '#4A3A24',
    primaryColor: '#FFC107',
  },
  'dark-orange': {
    font: THEME_DEFAULT_FONT,
    textColor: '#FFFFFF',
    backgroundColor: '#222529',
    secondaryBackgroundColor: '#4A3324',
    primaryColor: '#FFA500',
  },
  custom: { font: THEME_DEFAULT_FONT },
  streamlit: {},
} as const;

type ThemeName = keyof typeof themes;
type ThemeValues = { [key in string]: string };

const SIZE_PATH = ['configuration', 'parameters', 'size'];
const EXPIRATION_PATH = ['configuration', 'parameters', 'autoSuspendAfterSeconds'];
const EXPIRATION_PATH_FALLBACK = ['configuration', 'parameters', 'expirationAfterHours'];

const AUTO_SLEEP_NOTE =
  'Automatically puts the app to sleep after the selected duration of inactivity. Timeout can only be adjusted during redeployment.';

export {
  routeNames,
  ActionTypes,
  basicProxyAuthorization,
  noneProxyAuthorization,
  PREINSTALLED_PACKAGES,
  OIDC_PROVIDERS,
  DOCUMENTATION_LINK,
  themes,
  THEME_DEFAULT_FONT,
  THEME_KEBOOLA,
  SIZE_PATH,
  MINIMUM_EXPIRTION,
  DEFAULT_EXPIRATION,
  EXPIRATION_PATH,
  EXPIRATION_PATH_FALLBACK,
  AUTO_SLEEP_NOTE,
  ThemeName,
  ThemeValues,
};
