import type { getFileStatus } from '@/modules/stream/helpers';
import { getFileStatistics } from '@/modules/stream/helpers';
import type { StoreSource } from '@/modules/stream/store';
import { FileSize } from '@/react/common';
import GraphTooltip from '@/react/common/Graph/GraphTooltip';
import MiniBarGraph from '@/react/common/Graph/MiniBarGraph';
import { formatAbsolute } from '@/react/common/helpers';
import { calculateDuration, durationInWords } from '@/utils/duration';

const getStatusColor = (record: ReturnType<typeof getFileStatistics>) => {
  const colorMap: Record<ReturnType<typeof getFileStatus>, string> = {
    success: '#BAF5BA',
    error: '#E00025',
    'in-progress': '#D17D00',
  };

  return colorMap[record.state];
};

const RecordResults = (props: { source: StoreSource }) => {
  const files = props.source.sinks?.flatMap((sink) => sink.statistics ?? []) ?? [];

  const records = files
    .filter((file) => file.statistics?.total?.recordsCount)
    .map((file) => {
      const fileStatistics = getFileStatistics(file);

      return { ...fileStatistics, inProgress: fileStatistics.state === 'in-progress' };
    })
    .sort((a, b) => new Date(a.openedAt).getTime() - new Date(b.openedAt).getTime());

  if (!records?.length) {
    return <span className="text-muted">No records yet</span>;
  }

  return (
    <MiniBarGraph
      records={records}
      dataKey="rows"
      getColor={getStatusColor}
      renderTooltip={(record) => (
        <GraphTooltip>
          <div style={{ color: getStatusColor(record) }}>
            {record.state === 'success'
              ? 'Success Import'
              : record.state === 'error'
                ? 'Error'
                : 'Waiting for Import'}
          </div>
          <div>
            Data: <FileSize size={record.data} />
          </div>
          <div>Records: {record.rows}</div>
          <div>
            Duration:{' '}
            {(!record.importingAt || !record.importedAt) && record.state === 'in-progress'
              ? 'processing'
              : durationInWords(
                  calculateDuration(
                    record.importingAt ?? new Date(),
                    record.importedAt ?? new Date(),
                  ),
                )}
          </div>
          {record.importingAt && <div>Start: {formatAbsolute(record.importingAt, true)}</div>}
          {record.importedAt && <div>End: {formatAbsolute(record.importedAt, true)}</div>}
        </GraphTooltip>
      )}
    />
  );
};

export default RecordResults;
