import { useCallback, useState } from 'react';
import { Fade } from 'react-bootstrap';
import { List, Map } from 'immutable';

import { cn, Tooltip } from '@keboola/design';

import automateImage from '../../../images/illustrations/automate.png';

import { KEBOOLA_NO_CODE_DBT_TRANSFORMATION, KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { canExportTable } from '@/modules/admin/privileges';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageFilesStore from '@/modules/components/stores/StorageFilesStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import { routeNames as jobsRouteNames } from '@/modules/jobs/Constants';
import LatestJobsStore from '@/modules/jobs/stores/LatestJobsStore';
import { JOBS_STATUS, routeNames as queueRouteNames } from '@/modules/queue/constants';
import JobsStore from '@/modules/queue/store';
import ScheduleConfigurationButton from '@/modules/scheduler/components/ScheduleConfigurationButton';
import LoadingData from '@/modules/simplified-ui/components/LoadingData';
import Step from '@/modules/simplified-ui/components/Step';
import { prepareTablesMetadataMap } from '@/modules/storage/helpers';
import { routeNames as transformationRoutes } from '@/modules/transformations-v2/constants';
import { RouterLink } from '@/react/common';
import ConfigurationInfoPanel from '@/react/common/ConfigurationInfoPanel';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import JobStatusLabelWithErrorAndIcon from '@/react/common/JobStatusLabelWithErrorAndIcon';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { fileUrl } from '@/utils/fileHelpers';
import NoCodeOperations from './components/Operations';
import SimplifiedTableInputMappingStepWrapper, {
  SimplifiedTableInputMapping,
} from './components/SimplifiedTableInputMapping';
import SimplifiedTableOutputMapping from './components/SimplifiedTableOutputMapping';

const COMPONENT_ID = KEBOOLA_NO_CODE_DBT_TRANSFORMATION;

type Store = {
  component: Map<string, any>;
  componentsMetadata: Map<string, any>;
  configId: string;
  config: Map<string, any>;
  configData: Map<string, any>;
  allComponents: Map<string, any>;
  allBuckets: Map<string, any>;
  allTables: Map<string, any>;
  allUploadingProgress: Map<string, any>;
  flows: Map<string, any>;
  lastJobs: Map<string, any>;
  exportingTables: Map<string, any>;
  canExportTable: boolean;
  isCreatingTable: boolean;
  hasNewQueue: boolean;
  hasFlows: boolean;
  readOnly: boolean;
};

type CommonProps = Store & {
  commonStepsState: Record<string, any>;
  updateCommonState: (newState: CommonProps['commonStepsState']) => void;
  isInputConfigured: boolean;
  isOutputConfigured: boolean;
  isOperationsConfigured: boolean;
  expectedTables: List<Record<string, any>>;
};

const NocodeDetail = () => {
  const store: Store = useStores(
    () => {
      const configId = RoutesStore.getCurrentRouteParam('configId');
      const hasNewQueue = ApplicationStore.hasNewQueue();

      return {
        configId,
        hasNewQueue,
        canExportTable: canExportTable(ApplicationStore.getSapiToken()),
        config: InstalledComponentsStore.getConfig(COMPONENT_ID, configId),
        configData: InstalledComponentsStore.getConfigData(COMPONENT_ID, configId),
        allComponents: ComponentsStore.getAll(),
        component: ComponentsStore.getComponent(COMPONENT_ID),
        componentsMetadata: InstalledComponentsStore.getAllMetadata(),
        allBuckets: StorageBucketsStore.getAll(),
        allTables: StorageTablesStore.getAll() as Map<string, any>,
        exportingTables: StorageTablesStore.getExportingTables(),
        isCreatingTable: StorageTablesStore.getIsCreatingTable(),
        allUploadingProgress: StorageFilesStore.getAllUploadingProgress(),
        flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
        lastJobs: hasNewQueue
          ? JobsStore.getLatestJobs(COMPONENT_ID, configId)
          : LatestJobsStore.getJobs(COMPONENT_ID, configId),
        readOnly: ApplicationStore.isReadOnly(),
        hasFlows: ApplicationStore.hasFlows(),
      } as Store;
    },
    [],
    [
      ApplicationStore,
      RoutesStore,
      ComponentsStore,
      StorageBucketsStore,
      StorageTablesStore,
      StorageFilesStore,
      InstalledComponentsStore,
      LatestJobsStore,
      JobsStore,
    ],
  );
  const [commonStepsState, setCommonStepsState] = useState({});

  const updateCommonState = useCallback(
    (newState: Record<string, any>) => setCommonStepsState({ ...commonStepsState, ...newState }),
    [commonStepsState],
  );

  const isInputConfigured = !store.configData.getIn(['parameters', 'tables'], List()).isEmpty();
  const isOutputConfigured = !store.configData
    .getIn(['storage', 'output', 'tables'], List())
    .isEmpty();
  const isOperationsConfigured = !store.configData
    .getIn(['parameters', 'models'], List())
    .isEmpty();
  const expectedTables = store.configData
    .getIn(['storage', 'output', 'tables'], List())
    .map((table: Record<string, any>) => table.get('destination'));
  const commonProps: CommonProps = {
    ...store,
    isInputConfigured,
    isOperationsConfigured,
    isOutputConfigured,
    expectedTables,
    commonStepsState,
    updateCommonState,
  };

  return (
    <>
      <ConfigurationTabs
        componentId={COMPONENT_ID}
        configId={store.configId}
        config={store.config}
        flows={store.flows}
        showDeleteButton={!store.readOnly}
        versionsLinkTo={transformationRoutes.GENERIC_TRANSFORMATION_VERSIONS}
        notificationsLinkTo={transformationRoutes.GENERIC_TRANSFORMATION_NOTIFICATIONS}
      />
      <ConfigurationInfoPanel
        component={store.component}
        allComponents={store.allComponents}
        config={store.config}
        flows={store.flows}
        tablesMetadataMap={prepareTablesMetadataMap(store.allTables)}
        metadata={store.componentsMetadata}
      />
      <ComponentDescription componentId={COMPONENT_ID} configId={store.configId} />
      <div className="box-separator simplified-form">
        <SimplifiedInputMappingStep {...commonProps} />
        <AvailableNoCodeOperationsStep {...commonProps} />
        <SelectedNoCodeOperationsStep {...commonProps} />
        <SimplifiedOutputMappingStep {...commonProps} canExportTable={store.canExportTable} />
        <AutomationBox {...commonProps} />
      </div>
    </>
  );
};

const SimplifiedInputMappingStep = (props: CommonProps) => {
  return (
    <Step
      paddingless
      keepOpenAfterConfigured
      title="Data"
      isConfigured={props.isInputConfigured}
      isDisabled={props.readOnly}
      headerButton={
        <SimplifiedTableInputMapping
          isHeaderButton
          componentId={COMPONENT_ID}
          configId={props.configId}
          configData={props.configData}
          readOnly={props.readOnly}
          allBuckets={props.allBuckets}
          allTables={props.allTables}
          isCreatingTable={props.isCreatingTable}
          allUploadingProgress={props.allUploadingProgress}
          commonState={props.commonStepsState}
          setCommonState={props.updateCommonState}
        />
      }
    >
      <SimplifiedTableInputMappingStepWrapper
        componentId={COMPONENT_ID}
        configId={props.configId}
        configData={props.configData}
        readOnly={props.readOnly}
        allBuckets={props.allBuckets}
        allTables={props.allTables}
        isCreatingTable={props.isCreatingTable}
        allUploadingProgress={props.allUploadingProgress}
        commonState={props.commonStepsState}
        setCommonState={props.updateCommonState}
      />
    </Step>
  );
};

const AvailableNoCodeOperationsStep = (props: CommonProps) => {
  return (
    <Step
      paddingless
      keepOpenAfterConfigured
      title="Data Manipulations"
      isConfigured={props.isOperationsConfigured}
      isDisabled={!props.isInputConfigured || props.readOnly}
    >
      <NoCodeOperations
        componentId={COMPONENT_ID}
        configId={props.configId}
        configData={props.configData}
        allTables={props.allTables}
        commonState={props.commonStepsState}
        setCommonState={props.updateCommonState}
      />
    </Step>
  );
};

const SelectedNoCodeOperationsStep = (props: CommonProps) => {
  return (
    <Step
      keepOpenAfterConfigured
      paddingless
      title="Active Data Manipulations"
      isConfigured={props.isOperationsConfigured}
      isDisabled={!props.isInputConfigured || !props.isOperationsConfigured || props.readOnly}
    >
      <NoCodeOperations
        editMode
        componentId={COMPONENT_ID}
        configId={props.configId}
        configData={props.configData}
        allTables={props.allTables}
        commonState={props.commonStepsState}
        setCommonState={props.updateCommonState}
      />
    </Step>
  );
};

const SimplifiedOutputMappingStep = (props: CommonProps & { canExportTable: boolean }) => {
  const isDisabled = !props.isInputConfigured || !props.isOperationsConfigured;

  if (props.isOutputConfigured || props.readOnly) {
    return <LoadingStep {...props} isDisabled={isDisabled} canExportTable={props.canExportTable} />;
  }

  return (
    <Step paddingless title="Result" isConfigured={false} isDisabled={isDisabled}>
      <SimplifiedTableOutputMapping
        componentId={COMPONENT_ID}
        configId={props.configId}
        configName={props.config.get('name', '')}
        configData={props.configData}
        readOnly={props.readOnly}
        hasNewQueue={props.hasNewQueue}
        allBuckets={props.allBuckets}
        allTables={props.allTables}
        lastJob={props.lastJobs.getIn(['jobs', 0], Map())}
        commonState={props.commonStepsState}
      />
    </Step>
  );
};

const LoadingStep = (props: CommonProps & { isDisabled: boolean; canExportTable: boolean }) => {
  const [allFilesFinished, setAllFilesFinished] = useState(false);
  const job = props.lastJobs.getIn(['jobs', 0], Map());
  const isProcessing = [JOBS_STATUS.CREATED, JOBS_STATUS.WAITING, JOBS_STATUS.PROCESSING].includes(
    job.get('status'),
  );

  return (
    <Step
      paddingless
      keepOpenAfterConfigured
      title={
        isProcessing ? (allFilesFinished ? 'Finishing job...' : 'Processing data...') : 'Result'
      }
      status={
        !job.isEmpty() && (
          <div className={cn('ml-1', { 'text-muted': props.isDisabled })}>
            Last job status:{' '}
            <Tooltip tooltip="Check job detail" placement="top">
              <RouterLink
                className="no-underline"
                to={props.hasNewQueue ? queueRouteNames.JOB_DETAIL : jobsRouteNames.DETAIL}
                params={{ jobId: job.get('id') }}
              >
                <JobStatusLabelWithErrorAndIcon job={job} />
              </RouterLink>
            </Tooltip>
          </div>
        )
      }
      isProcessing={isProcessing}
      isConfigured={job.get('status') === JOBS_STATUS.SUCCESS}
      isDisabled={props.isDisabled}
      onStartProcessing={() => setAllFilesFinished(false)}
      headerButton={
        <SimplifiedTableOutputMapping
          isHeaderButton
          componentId={COMPONENT_ID}
          configId={props.configId}
          configName={props.config.get('name', '')}
          configData={props.configData}
          readOnly={props.readOnly || props.isDisabled}
          hasNewQueue={props.hasNewQueue}
          allBuckets={props.allBuckets}
          allTables={props.allTables}
          lastJob={props.lastJobs.getIn(['jobs', 0], Map())}
          commonState={props.commonStepsState}
        />
      }
    >
      <LoadingData
        simplifiedList
        canExportTable={props.canExportTable}
        key={job.get('runId', 'first-job')}
        job={job}
        isProcessing={isProcessing}
        allBuckets={props.allBuckets}
        allTables={props.allTables}
        getExpectedTables={() => props.expectedTables}
        hasNewQueue={props.hasNewQueue}
        allProcessingFinished={() => setAllFilesFinished(true)}
        exportingTables={props.exportingTables}
      />
    </Step>
  );
};

const AutomationBox = (props: CommonProps) => {
  return (
    <div className="flex-container align-top justify-center mt-2 pt-2">
      <Fade in={!props.lastJobs.get('jobs', List()).isEmpty()}>
        <div className="max-w-400 text-center p-2">
          <img
            height={122}
            src={fileUrl(automateImage)}
            alt="Automate configuration"
            loading="lazy"
          />
          <h3 className="f-18 mt-0">Run this configuration periodically</h3>
          <p className="text-muted mb-2">
            Create a single task {props.hasFlows ? 'flow' : 'orchestration'} from this
            configuration.
          </p>
          <ScheduleConfigurationButton
            linkToIndex
            mode="button"
            flows={props.flows}
            component={props.component}
            config={props.config}
            hasNewQueue={props.hasNewQueue}
            hasFlows={props.hasFlows}
          />
        </div>
      </Fade>
    </div>
  );
};

export default NocodeDetail;
