import { RadioGroup, Search } from '@keboola/design';

import { type FilterType, VARIABLE_TYPE } from '@/modules/vault/constants';

type Props = {
  query: string;
  setSearchQuery: (query: string) => void;
  variablesType: FilterType;
  setVariablesType: (type: FilterType) => void;
  placeholder: string;
  isDevModeActive: boolean;
};

export const VaultSearch = ({
  query,
  setSearchQuery,
  variablesType,
  setVariablesType,
  placeholder,
  isDevModeActive,
}: Props) => {
  return (
    <Search
      defaultValue={query}
      onChange={setSearchQuery}
      placeholder={placeholder}
      className="tw-mb-6"
      suffix={
        <RadioGroup
          variant="button"
          value={variablesType}
          onChange={(value) => setVariablesType(value as FilterType)}
        >
          <RadioGroup.Item value={VARIABLE_TYPE.ALL}>All</RadioGroup.Item>
          <RadioGroup.Separator />
          <RadioGroup.Item value={VARIABLE_TYPE.WIDE}>Project Wide</RadioGroup.Item>
          {isDevModeActive && (
            <RadioGroup.Item value={VARIABLE_TYPE.SCOPED}>Branch Specific</RadioGroup.Item>
          )}
          <RadioGroup.Item
            value={isDevModeActive ? VARIABLE_TYPE.PRODUCTION : VARIABLE_TYPE.SCOPED}
          >
            Production Only
          </RadioGroup.Item>
        </RadioGroup>
      }
    />
  );
};
