import type { PropsWithChildren } from 'react';
import { ErrorBoundary } from '@sentry/react';

import { ErrorBoundaryFallback } from '@/react/common/ErrorBoundaryFallback';
import useStores from '@/react/hooks/useStores';
import ErrorPage from '@/react/pages/ErrorPage';
import LoadingPage from '@/react/pages/LoadingPage';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { injectPropsToChildren } from '@/utils/injectPropsToChildren';

export const MainContainer = ({ children, ...props }: PropsWithChildren) => {
  const store = useStores(
    () => ({
      isInitialLoading: ApplicationStore.getInitialLoading(),
      error: RoutesStore.isError(),
    }),
    [],
    [RoutesStore, ApplicationStore],
  );

  return (
    <ErrorBoundary fallback={() => <ErrorBoundaryFallback />}>
      <div className="container main-container">
        {store.isInitialLoading ? (
          <LoadingPage />
        ) : store.error ? (
          <ErrorPage />
        ) : (
          injectPropsToChildren(children, props)
        )}
      </div>
    </ErrorBoundary>
  );
};
