import type { ChangeEvent } from 'react';
import { FormControl } from 'react-bootstrap';
import type { Map } from 'immutable';
import { fromJS, List } from 'immutable';

import { cn, FormGroup, HelpBlock, Label, TextInput } from '@keboola/design';

import callDockerAction from '@/modules/components/DockerActionsApi';
import Checkbox from '@/react/common/Checkbox';
import PasswordControl from '@/react/common/PasswordControl';
import Select from '@/react/common/Select';
import SshForm from '@/react/common/SshForm';
import TestCredentialsButtonGroup from '@/react/common/TestCredentialsButtonGroup';

const FORM_GROUP_CLASS_NAMES = 'tw-grid tw-grid-cols-3 tw-items-baseline tw-gap-4';

type ValueProps = {
  connectionType: 'FTP' | 'FTPS' | 'SFTP' | '';
  host: string;
  username: string;
  password: string;
  privateKey: string;
  port: number;
  ignorePassiveAddress: boolean;
  ssh?: Record<string, any>;
};

type Props = {
  value: ValueProps;
  context: Map<string, any>;
  imageParameters: Map<string, any>;
  onChange: (value: Partial<ValueProps>) => void;
  readOnly: boolean;
  isChanged: boolean;
  disabled: boolean;
};

const SourceServer = (props: Props) => {
  const approvedHostnames = props.imageParameters.get('approvedHostnames', List());
  const isDisabled = props.disabled || props.readOnly;

  return (
    <form className="tw-flex tw-flex-col tw-gap-4">
      <FormGroup className={FORM_GROUP_CLASS_NAMES}>
        <Label htmlFor="connection-type">Connection Type</Label>
        <Select
          id="connectiontype"
          className="tw-col-span-2"
          clearable={false}
          placeholder="Select type"
          value={props.value.connectionType}
          onChange={(value: string) => {
            props.onChange({
              connectionType: value,
              ...(approvedHostnames.isEmpty() && { port: value === 'SFTP' ? 22 : 21 }),
            });
          }}
          options={[
            { value: 'FTP', label: 'FTP' },
            { value: 'FTPS', label: 'FTPS' },
            { value: 'SFTP', label: 'SFTP' },
          ]}
          disabled={isDisabled}
        />
      </FormGroup>
      {approvedHostnames.isEmpty() ? (
        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="hostname">Hostname/IP</Label>
          <TextInput
            id="hostname"
            className="tw-col-span-2"
            variant="secondary"
            value={props.value.host}
            disabled={isDisabled}
            onChange={(value) => props.onChange({ host: value })}
          />
        </FormGroup>
      ) : (
        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="hostname-port">Hostname/Port</Label>
          <Select
            id="hostname-port"
            className="tw-col-span-2"
            placeholder="Select host"
            value={props.value.host}
            onChange={(value: string) => {
              const option = approvedHostnames.find(
                (option: Map<string, any>) => option.get('host') === value,
              );
              props.onChange({ host: option.get('host'), port: option.get('port') });
            }}
            options={approvedHostnames
              .map((option: Map<string, any>) => ({
                value: option.get('host'),
                label: `${option.get('host')}:${option.get('port')}`,
              }))
              .toArray()}
            disabled={isDisabled}
          />
        </FormGroup>
      )}
      {approvedHostnames.isEmpty() && (
        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="port">Port</Label>
          <FormControl
            id="port"
            className="tw-col-span-2"
            type="number"
            value={props.value.port}
            disabled={isDisabled}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              props.onChange({ port: Number(e.target.value) })
            }
          />
        </FormGroup>
      )}
      <FormGroup className={FORM_GROUP_CLASS_NAMES}>
        <Label htmlFor="username">Username</Label>
        <TextInput
          id="username"
          className="tw-col-span-2"
          variant="secondary"
          value={props.value.username}
          disabled={isDisabled}
          onChange={(value) => props.onChange({ username: value })}
        />
      </FormGroup>
      <FormGroup className={FORM_GROUP_CLASS_NAMES}>
        <Label htmlFor="password">Password</Label>
        <PasswordControl
          id="password"
          className="tw-col-span-2"
          value={props.value.password}
          disabled={isDisabled}
          onChange={(e) => props.onChange({ password: e.target.value })}
        />
      </FormGroup>
      <FormGroup
        className={cn(FORM_GROUP_CLASS_NAMES, {
          hidden: props.value.connectionType !== 'SFTP',
        })}
      >
        <Label htmlFor="private-key">Private Key</Label>
        <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
          <FormControl
            id="private-key"
            componentClass="textarea"
            rows="5"
            value={props.value.privateKey}
            disabled={isDisabled}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              props.onChange({ privateKey: e.target.value })
            }
          />
          <HelpBlock>
            Only to use with an SFTP connection type. You can paste a private key with or without{' '}
            <code>---BEGIN/END PRIVATE KEY---</code> block.
          </HelpBlock>
        </div>
      </FormGroup>
      <FormGroup className={FORM_GROUP_CLASS_NAMES}>
        <div className="tw-col-span-2 tw-col-start-2 tw-flex tw-flex-col tw-gap-1">
          <Checkbox
            checked={props.value.ignorePassiveAddress}
            onChange={(checked) => props.onChange({ ignorePassiveAddress: checked })}
            disabled={isDisabled}
          >
            Ignore passive address
          </Checkbox>
          <HelpBlock>Ignore the IP address returned from the server on the PASV reply.</HelpBlock>
        </div>
      </FormGroup>
      <SshForm
        isEnabled
        hasPassivePortRange
        showHelp={false}
        readOnly={isDisabled}
        data={fromJS(props.value.ssh)}
        onChange={(data) => props.onChange({ ssh: data.toJS() })}
      />
      {!props.readOnly && (
        <TestCredentialsButtonGroup
          isEditing={props.isChanged}
          disabled={props.disabled}
          testCredentialsFn={() => {
            const { password, privateKey, ...restParameters } = props.value;

            return callDockerAction(props.context.get('componentId'), 'testConnection', {
              configData: {
                parameters: {
                  path: '/',
                  ...restParameters,
                  ['#password']: password,
                  ['#privateKey']: privateKey,
                },
              },
            });
          }}
        />
      )}
    </form>
  );
};

export default SourceServer;
