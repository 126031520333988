import { Children, cloneElement, isValidElement } from 'react';

const FragmentWithForwaredProps = (props: any) => {
  const { children, ...restProps } = props;

  return Children.map(children, (child) => {
    if (!isValidElement(child)) {
      return child;
    }

    return cloneElement(child, restProps);
  });
};

export default FragmentWithForwaredProps;
