import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import NewTransformationBucketModal from '@/modules/transformations/react/modals/NewTransformationBucket';

const NewTransformationBucketButton = createReactClass({
  propTypes: {
    label: PropTypes.string,
  },

  getDefaultProps() {
    return {
      label: 'New Bucket',
    };
  },

  render() {
    return <NewTransformationBucketModal label={this.props.label} />;
  },
});

export default NewTransformationBucketButton;
