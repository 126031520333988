import { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { DEV_BRANCH_EVENTS } from '@/modules/home/constants';
import { CreatedDate } from '@/react/common';
import Gravatar from '@/react/common/Gravatar';

class LatestActivityBranch extends Component {
  render() {
    return (
      <div className="tr">
        <div className="td">{this.renderMessage()}</div>
        <div className="td w-200 text-right text-muted" />
        <div className="td w-200 text-right text-muted">
          <CreatedDate createdTime={this.props.event.get('created')} />
        </div>
        <div className="td w-100" />
      </div>
    );
  }

  renderMessage() {
    const token = this.props.event.get('token');
    const user = this.props.admins.get(token.get('name'));

    if (user) {
      return (
        <>
          <span className="icon-addon-right">
            <Gravatar user={user} size={28} />
          </span>
          {user.get('name') || user.get('email')} {this.renderActionLabel()}{' '}
          <b className="font-medium color-primary">
            {this.props.event.getIn(['params', 'devBranchName'])}
          </b>
        </>
      );
    }

    return (
      <>
        {token.get('name')} {this.renderActionLabel()}{' '}
        <b className="font-medium color-primary">
          {this.props.event.getIn(['params', 'devBranchName'])}
        </b>
      </>
    );
  }

  renderActionLabel() {
    switch (this.props.event.get('event')) {
      case DEV_BRANCH_EVENTS.CREATED:
        return 'created Development branch:';

      case DEV_BRANCH_EVENTS.DELETED:
        return 'deleted Development branch:';

      default:
        return null;
    }
  }
}

LatestActivityBranch.propTypes = {
  event: PropTypes.instanceOf(Map).isRequired,
  admins: PropTypes.instanceOf(Map).isRequired,
};

export default LatestActivityBranch;
