import { Component } from 'react';
import type { ChangeEvent } from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

import { HelpBlock } from '@keboola/design';

import type { PathValueProps } from '@/modules/ex-ftp/SourcePath';
import Checkbox from '@/react/common/Checkbox';

type Props = {
  decompress: boolean;
  disabled: boolean;
  onChange: (data: Partial<PathValueProps>) => void;
  onlyNewFiles: boolean;
  path: string;
};

class DownloadSettings extends Component<Props> {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Download Settings</h3>
        </div>
        <div className="box-content">
          <FormGroup>
            <div className="col-xs-4">
              <ControlLabel>Path</ControlLabel>
            </div>
            <div className="col-xs-8">
              <FormControl
                type="text"
                value={this.props.path}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  this.props.onChange({ path: e.target.value });
                }}
                placeholder="folder/*.csv"
                disabled={this.props.disabled}
              />
              <HelpBlock className="tw-mt-1">
                <span>
                  Exact path to a file or glob syntax. Use an absolute path for FTP(s) connections
                  and a relative path for SFTP connections.
                  <ul>
                    <li>
                      <code>{'**/*.csv'}</code> will download all CSV files in all subdirectories
                    </li>
                    <li>
                      <code>{'files/*.csv'}</code> will download all CSV files in files/ directory
                    </li>
                    <li>
                      <code>{'files/directory/file.txt'}</code> will download a particular text file
                    </li>
                  </ul>
                </span>
              </HelpBlock>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col-xs-8 col-xs-offset-4">
              <Checkbox
                checked={this.props.onlyNewFiles}
                onChange={(checked) => this.props.onChange({ onlyNewFiles: checked })}
                disabled={this.props.disabled}
              >
                Only New Files
              </Checkbox>
              <HelpBlock className="tw-mt-1">
                Every job stores the timestamp of the last downloaded file and a subsequent job can
                pick up from there.
              </HelpBlock>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="col-xs-8 col-xs-offset-4">
              <Checkbox
                checked={this.props.decompress}
                onChange={(checked) => this.props.onChange({ decompress: checked })}
                disabled={this.props.disabled}
              >
                Decompress
              </Checkbox>
              <HelpBlock className="tw-mt-1">
                Decompress downloaded file(s). All files in all archives will be imported into a
                single Storage table.
              </HelpBlock>
            </div>
          </FormGroup>
        </div>
      </div>
    );
  }
}

export default DownloadSettings;
