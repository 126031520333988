import ApplicationStore from '@/stores/ApplicationStore';
import SwitchStateLink from './ActionControls/SwitchStateLink';
import SwitchStateLabel from './SwitchStateLabel';

type Props = {
  isActive: boolean;
  onChange: (isNotActive: boolean) => void;
  readOnly?: boolean;
  isPending?: boolean;
  buttonDisabled?: boolean;
};

const ActivateDeactivateButton = ({
  isActive,
  onChange,
  readOnly = ApplicationStore.isReadOnly(),
  isPending = false,
  buttonDisabled = false,
}: Props) => (
  <SwitchStateLink
    label={<SwitchStateLabel isActive={isActive} />}
    onChange={() => onChange(!isActive)}
    isActive={isActive}
    isPending={isPending}
    disabled={buttonDisabled}
    readOnly={readOnly}
  />
);

export default ActivateDeactivateButton;
