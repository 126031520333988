import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { KEBOOLA_GOODDATA_WRITER } from '@/constants/componentIds';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import SaveButtons from '@/react/common/SaveButtons';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { resetEditingState, updateTableConfiguration } from './actions';

const TableDetailHeaderButtons = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, InstalledComponentsStore, TablesStore)],

  getStateFromStores() {
    const tableId = RoutesStore.getCurrentRouteParam('table');
    const configId = RoutesStore.getCurrentRouteParam('config');
    const config = InstalledComponentsStore.getConfig(KEBOOLA_GOODDATA_WRITER, configId);
    const localState = InstalledComponentsStore.getLocalState(KEBOOLA_GOODDATA_WRITER, configId);

    const tablesFromConfig = config.getIn(['configuration', 'parameters', 'tables'], Map());
    const inputMappingFromConfig = config.getIn(
      ['configuration', 'storage', 'input', 'tables'],
      List(),
    );
    const tablesFromState = localState.getIn(['editing', 'parameters'], tablesFromConfig);
    const inputMappingFromState = localState.getIn(
      ['editing', 'inputMapping'],
      inputMappingFromConfig,
    );

    const editingTableParameters = tablesFromState.get(tableId, Map());
    const editingTableInputMapping = inputMappingFromState.find(
      (table) => table.get('source') === tableId,
      null,
      Map(),
    );

    return {
      config,
      configId,
      tableId,
      isPendingToggleExport: localState.getIn(['pending', tableId, 'activate'], false),
      editingTableParameters,
      editingTableInputMapping,
      isChanged:
        !editingTableParameters.equals(tablesFromConfig.get(tableId, Map())) ||
        !editingTableInputMapping.equals(
          inputMappingFromConfig.find((table) => table.get('source') === tableId, null, Map()),
        ),
      readOnly: ApplicationStore.isReadOnly(),
    };
  },

  getInitialState() {
    return {
      isSaving: false,
    };
  },

  render() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <SaveButtons
        disabled={this.state.isPendingToggleExport}
        isSaving={this.state.isSaving && !this.state.isPendingToggleExport}
        isChanged={this.state.isChanged}
        onSave={() => {
          this.setState({
            isSaving: true,
          });
          updateTableConfiguration(
            this.state.config,
            this.state.tableId,
            this.state.editingTableParameters,
            this.state.editingTableInputMapping,
          )
            .then(() => resetEditingState(this.state.configId))
            .finally(() => {
              this.setState({
                isSaving: false,
              });
            });
        }}
        onReset={() => resetEditingState(this.state.configId)}
      />
    );
  },
});

export default TableDetailHeaderButtons;
