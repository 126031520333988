import { fromJS, Map } from 'immutable';
import _ from 'underscore';

import {
  KDS_TEAM_WR_EXASOL,
  KEBOOLA_EXASOL_TRANSFORMATION,
  KEBOOLA_LEGACY_TRANSFORMATION,
  KEBOOLA_ORACLE_TRANSFORMATION,
  KEBOOLA_REDSHIFT_TRANSFORMATION,
  KEBOOLA_SNOWFLAKE_TRANSFORMATION,
  KEBOOLA_SYNAPSE_TRANSFORMATION,
  KEBOOLA_WR_DB_ORACLE,
  KEBOOLA_WR_DB_SNOWFLAKE,
  KEBOOLA_WR_DB_SYNAPSE,
  KEBOOLA_WR_REDSHIFT_V_2,
} from '@/constants/componentIds';
import { ioType } from '@/modules/components/Constants';
import { validate as formatValidation } from '@/modules/components/utils/columnTypeValidation';
import RedshiftCompressionColumn from './RedshiftCompressionColumn';

const getDatatypesComponentId = (componentId, type) => {
  if (type === ioType.FILE) {
    return null;
  }

  switch (componentId) {
    case KEBOOLA_SNOWFLAKE_TRANSFORMATION:
    case KEBOOLA_LEGACY_TRANSFORMATION:
      return KEBOOLA_WR_DB_SNOWFLAKE;

    case KEBOOLA_SYNAPSE_TRANSFORMATION:
      return KEBOOLA_WR_DB_SYNAPSE;

    case KEBOOLA_REDSHIFT_TRANSFORMATION:
      return KEBOOLA_WR_REDSHIFT_V_2;

    case KEBOOLA_ORACLE_TRANSFORMATION:
      return KEBOOLA_WR_DB_ORACLE;

    case KEBOOLA_EXASOL_TRANSFORMATION:
      return KDS_TEAM_WR_EXASOL;

    default:
      return null;
  }
};

const getDefaultDatatype = (componentId, datatypes) => {
  const baseDefaults = {
    type: datatypes.default.type.toUpperCase(),
    length: datatypes.default.size,
    nullable: true,
    convert_empty_values_to_null: false,
  };

  switch (componentId) {
    case KEBOOLA_REDSHIFT_TRANSFORMATION:
      return { ...baseDefaults, compression: '' };

    default:
      return baseDefaults;
  }
};

const prepareDatatypes = (datatypes) => {
  return fromJS(datatypes)
    .map((type) => {
      if (_.isString(type)) {
        return Map({
          name: type.toUpperCase(),
          length: false,
          defaultLength: '',
          maxLength: '',
        });
      }

      return Map({
        name: type.keySeq().first().toUpperCase(),
        length: true,
        defaultLength: type.first().get('defaultSize', ''),
        maxLength: type.first().get('maxSize', ''),
      });
    })
    .toMap()
    .mapKeys((key, mapping) => mapping.get('name'));
};

const generateColumnTypes = (columns, datatypes, metadata, defaultValues, preferableTypes) => {
  return columns.map((column) => {
    const columnMetadata = metadata.get(column, Map());

    if (!columnMetadata.count()) {
      return Map({ ...defaultValues, source: column, destination: column });
    }

    let columnType = Map({
      source: column,
      destination: column,
      type: columnMetadata.get('type', defaultValues.type).toUpperCase(),
      length: columnMetadata.get('size') || defaultValues.length,
      nullable: columnMetadata.get('nullable', defaultValues.nullable),
      convert_empty_values_to_null: defaultValues.convert_empty_values_to_null,
    });

    if (preferableTypes[columnType.get('type')]) {
      columnType = columnType.set('type', preferableTypes[columnType.get('type')]);
    }

    if (!isLengthValid(columnType, datatypes.get(columnType.get('type')))) {
      columnType = columnType.set('length', '');
    }

    Object.keys(defaultValues).forEach((key) => {
      if (!['type', 'length', 'nullable', 'convert_empty_values_to_null'].includes(key)) {
        columnType = columnType.set(key, defaultValues[key]);
      }
    });

    return columnType;
  });
};

const getPreferableTypes = (componentId) => {
  switch (componentId) {
    case KEBOOLA_WR_DB_SNOWFLAKE:
      return {
        TIMESTAMP: 'TIMESTAMP_NTZ',
      };

    default:
      return {};
  }
};

const isLengthValid = (value, datatype) => {
  if (!value.get('length')) {
    return true;
  }

  if (!formatValidation(value.get('type'), value.get('length'))) {
    return false;
  }

  const [size, precision] = value.get('length').split(',').map(Number);
  const [masSize, maxPrecision] = datatype.get('maxLength').split(',').map(Number);

  if (_.isUndefined(maxPrecision)) {
    return size > 0 && size <= masSize;
  }

  return size > 0 && size <= masSize && maxPrecision >= 0 && precision <= maxPrecision;
};

const getCustomColumns = (componentId) => {
  switch (componentId) {
    case KEBOOLA_REDSHIFT_TRANSFORMATION:
      return RedshiftCompressionColumn;

    default:
      break;
  }
};

export {
  getDatatypesComponentId,
  getDefaultDatatype,
  prepareDatatypes,
  generateColumnTypes,
  getPreferableTypes,
  isLengthValid,
  getCustomColumns,
};
