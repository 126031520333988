import type { ComponentProps } from 'react';

import type { Icon, TooltipProps } from '@keboola/design';

import { IconButton } from '../Button/IconButton';

import { Clipboard } from './Clipboard';

export const ClipboardIcon = ({
  text,
  icon = 'copy',
  size = 'extra-small',
  className,
  buttonClassName,
  tooltipType,
  tooltipText,
  tooltipPlacement,
}: {
  text?: string;
  className?: string;
  icon?: ComponentProps<typeof Icon>['icon'];
  size?: ComponentProps<typeof IconButton>['size'];
  buttonClassName?: ComponentProps<typeof IconButton>['className'];
  tooltipType?: TooltipProps['type'];
  tooltipText?: TooltipProps['tooltip'];
  tooltipPlacement?: TooltipProps['placement'];
}) => {
  return (
    <Clipboard
      text={text}
      className={className}
      tooltipType={tooltipType}
      tooltipText={tooltipText}
      tooltipPlacement={tooltipPlacement}
    >
      <IconButton
        variant="inline"
        size={size}
        icon={icon}
        disabled={!text}
        className={buttonClassName}
      />
    </Clipboard>
  );
};
