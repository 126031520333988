import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { Icon } from '@keboola/design';

import { NEW_ENTITY_BUTTON } from '@/constants/external';
import ActionCreators from '@/modules/legacy-transformation/ActionCreators';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';

const NewTransformationBucket = createReactClass({
  propTypes: {
    label: PropTypes.string.isRequired,
  },

  getInitialState() {
    return {
      isLoading: false,
      name: '',
      description: '',
      showModal: false,
    };
  },

  componentWillUnmount() {
    if (this.cancellablePromise) {
      this.cancellablePromise.cancel();
    }
  },

  render() {
    return (
      <span>
        {this._renderOpenButton()}
        <Modal show={this.state.showModal} onHide={this._close}>
          <Modal.Header closeButton>
            <Modal.Title>New Transformation Bucket</Modal.Title>
            <ModalIcon.Plus />
          </Modal.Header>
          <Modal.Body>
            <form className="form-horizontal" onSubmit={this._handleSubmit}>
              <p className="help-block">
                A transformation bucket is a container for related transformations. Once the bucket
                is created, you can create transformations inside it.
              </p>
              <div className="form-group">
                <label htmlFor="name" className="col-sm-4 control-label">
                  Name
                </label>
                <div className="col-sm-8">
                  <input
                    id="name"
                    placeholder="Main"
                    className="form-control"
                    value={this.state.text}
                    onChange={this._setName}
                    autoFocus
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="description" className="col-sm-4 control-label">
                  Description
                </label>
                <div className="col-sm-8">
                  <textarea
                    id="description"
                    placeholder="Main transformations"
                    className="form-control"
                    value={this.state.description}
                    onChange={this._setDescription}
                  />
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              isSaving={this.state.isLoading}
              isDisabled={!this._isValid()}
              saveLabel="Create Bucket"
              onSave={this._handleCreate}
            />
          </Modal.Footer>
        </Modal>
      </span>
    );
  },

  _renderOpenButton() {
    return (
      <Button onClick={this._open} bsStyle="success" className={NEW_ENTITY_BUTTON}>
        <Icon icon="plus" className="icon-addon-right" />
        {this.props.label}
      </Button>
    );
  },

  _close() {
    this.setState({
      showModal: false,
    });
  },

  _open() {
    this.setState({
      showModal: true,
    });
  },

  _handleSubmit(e) {
    e.preventDefault();
    if (this._isValid()) {
      return this._handleCreate();
    }
  },

  _handleCreate() {
    this.setState({
      isLoading: true,
    });

    this.cancellablePromise = ActionCreators.createTransformationBucket({
      name: this.state.name,
      description: this.state.description,
    }).then(this._close);
  },

  _setName(e) {
    return this.setState({
      name: e.target.value.trim(),
    });
  },

  _setDescription(e) {
    return this.setState({
      description: e.target.value,
    });
  },

  _isValid() {
    return this.state.name.length > 0;
  },
});

export default NewTransformationBucket;
