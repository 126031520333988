import { Icon } from '@keboola/design';

import { FEATURE_CANARY_STACK } from '@/constants/features';
import StackFeaturesStore from '@/modules/stack-features/Store';

export const CanaryStackBar = () => {
  if (!StackFeaturesStore.hasStackFeature(FEATURE_CANARY_STACK)) {
    return null;
  }

  return (
    <div className="top-sticky-bar tw-bg-gradient-to-r tw-from-error-300 tw-to-error-400 tw-text-base">
      <Icon icon="triangle-exclamation" className="tw-mr-2" />
      Running on the Canary Stack
    </div>
  );
};
