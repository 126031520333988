import { useState } from 'react';
import type { Map } from 'immutable';

import { cn } from '@keboola/design';

import { updateTopUp } from '@/modules/billing/actions';
import type { Purchase } from '@/modules/billing/store';
import { CircleIcon } from '@/react/common';
import InfoTooltip from '@/react/common/InfoTooltip';
import pushGtmEvent, { events as gtmEvents } from '@/utils/pushGtmEvent';
import SavedCardInfo from './SavedCardInfo';
import { TopUpDescription, type TopUpSetting, TopUpSwitch } from './TopUpForm';
import TopUpInfo from './TopUpInfo';
import TopUpModal from './TopUpModal';

const CreditCardBox = (props: {
  creditPrice: number;
  card: Map<string, any>;
  purchases: Purchase[];
  topUpSetting?: { enabled: boolean } & TopUpSetting;
}) => {
  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [isUpdating, setUpdating] = useState(false);

  const toggleTopUp = () => {
    if (!props.topUpSetting?.enabled) {
      return setShowTopUpModal(true);
    }

    setUpdating(true);
    return updateTopUp({ ...props.topUpSetting, enabled: false })
      .tap(() => pushGtmEvent(gtmEvents.EVENT_TOP_UP_DEACTIVATED))
      .finally(() => {
        return setUpdating(false);
      });
  };

  return (
    <div className="box box-panel box-panel-small">
      <div className={cn('box-header', { 'with-border': false })}>
        <h2 className="box-title flex-container flex-start">
          Credit Card Details
          <InfoTooltip tooltip="Details of your saved credit card. When you remove it you can only add it again in Top up page." />
        </h2>
        <CircleIcon icon="credit-card" color="blue" bold />
      </div>
      <div
        className={cn(
          'box-panel-content',
          props.card.isEmpty()
            ? 'text-center mt-2'
            : 'padding-bottom flex-container fill-space flex-column align-top stretch',
        )}
      >
        {props.card.isEmpty() ? (
          <>
            <h3 className="f-14 line-height-20 mt-0">No Credit Card Added</h3>
            <p className="text-muted line-height-20 mb-1">
              You can save your credit card when topping up.
            </p>
          </>
        ) : (
          <>
            <SavedCardInfo card={props.card.toJS()} setting={props.topUpSetting} />
            <div className="mt-2">
              <TopUpSwitch
                withNewLabel
                disabled={isUpdating}
                checked={!!props.topUpSetting?.enabled}
                onClick={toggleTopUp}
                label="Automatic top-up"
                className="font-medium f-16 line-height-24 mb-1"
              />
              {!props.topUpSetting?.enabled ? (
                <TopUpDescription />
              ) : (
                <TopUpInfo
                  setting={props.topUpSetting}
                  creditPrice={props.creditPrice}
                  purchases={props.purchases}
                  onEdit={() => setShowTopUpModal(true)}
                />
              )}
              <TopUpModal
                show={showTopUpModal}
                onHide={() => setShowTopUpModal(false)}
                creditPrice={props.creditPrice}
                {...(props.topUpSetting && {
                  setting: { amount: props.topUpSetting.amount, limit: props.topUpSetting.limit },
                })}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreditCardBox;
