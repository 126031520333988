import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import IncrementalResetWarning from '@/modules/components/react/components/IncrementalResetWarning';
import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import Checkbox from '@/react/common/Checkbox';

const RunLoadButton = createReactClass({
  propTypes: {
    mode: PropTypes.oneOf(['sidebar', 'menuitem']).isRequired,
    getRunParams: PropTypes.func.isRequired,
    showIncrementalResetWarning: PropTypes.bool.isRequired,
    isTableDisabled: PropTypes.bool,
    loadOnly: PropTypes.bool,
    tableId: PropTypes.string,
    onKeyDown: PropTypes.func,
    label: PropTypes.string,
  },

  getInitialState() {
    return {
      loadOnly: this.props.loadOnly,
    };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ loadOnly: nextProps.loadOnly });
  },

  render() {
    return (
      <RunComponentButton
        forceModal
        mode={this.props.mode}
        componentId="keboola.gooddata-writer"
        runParams={() => this.props.getRunParams(this.props.tableId, this.state.loadOnly)}
        onKeyDown={this.props.onKeyDown}
        title={this.props.label}
      >
        {this.props.showIncrementalResetWarning && <IncrementalResetWarning />}
        {this.getMessage()}
        {this.renderLoadOnlyForm()}
      </RunComponentButton>
    );
  },

  renderLoadOnlyForm() {
    return (
      <Checkbox
        checked={this.state.loadOnly}
        onChange={() => this.setState({ loadOnly: !this.state.loadOnly })}
      >
        Skip model update and load only table data
      </Checkbox>
    );
  },

  getMessage() {
    let message = `You are about to run a load of ${this.props.tableId} table.`;
    if (this.props.isTableDisabled) {
      message += ' Load to GooDataProject is disabled and will be forced to run.';
    }
    return message;
  },
});

export default RunLoadButton;
