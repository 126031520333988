import { Component } from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { Map } from 'immutable';

import { hasGenericCodeBlocksUI } from '@/modules/components/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';

const INITIAL_STATE = {
  tempData: Map(),
  isLoading: false,
};

class CreateSharedCodeModal extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.onHide = this.onHide.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.onHide}>
        <form onSubmit={this.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>New Shared Code</Modal.Title>
            <ModalIcon icon="code" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>Name</ControlLabel>
              <FormControl
                autoFocus
                type="text"
                value={this.state.tempData.get('name', '')}
                onChange={(e) =>
                  this.setState({ tempData: this.state.tempData.set('name', e.target.value) })
                }
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Type</ControlLabel>
              <Select
                clearable={false}
                searchable={false}
                value={this.state.tempData.get('component', '')}
                options={this.props.allowedTransformationComponents
                  .filter(hasGenericCodeBlocksUI)
                  .sortBy((component) => component.get('name').toLowerCase())
                  .map((component) => {
                    return { label: component.get('name'), value: component.get('id') };
                  })
                  .toArray()}
                onChange={(selected) =>
                  this.setState({ tempData: this.state.tempData.set('component', selected) })
                }
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveLabel={this.state.isLoading ? 'Creating shared code...' : 'Create shared code'}
              saveStyle="primary"
              saveButtonType="submit"
              isSaving={this.state.isLoading}
              isDisabled={this.isDisabled()}
            />
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ isLoading: true });
    return this.props
      .onSubmit(this.state.tempData.get('component'), this.state.tempData.get('name'))
      .then(() => {
        this.props.onHide();
        this.setState({ isLoading: false, tempData: Map() });
      });
  }

  isDisabled() {
    return (
      this.state.isLoading ||
      !this.state.tempData.get('component') ||
      !this.state.tempData.get('name', '').trim()
    );
  }

  onHide() {
    this.setState(INITIAL_STATE);
    this.props.onHide();
  }
}

CreateSharedCodeModal.propTypes = {
  allowedTransformationComponents: PropTypes.instanceOf(Map).isRequired,
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default CreateSharedCodeModal;
