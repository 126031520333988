import { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

import { IconButton } from '@keboola/design';

import { FEATURE_CAN_UPDATE_PROJECT_SETTINGS } from '@/constants/features';
import UpdateProjectModal from './UpdateProjectModal';

class UpdateProjectButton extends Component {
  state = {
    showModal: false,
  };

  render() {
    return (
      <>
        <IconButton
          variant="inline"
          className="tw-inline-flex tw-h-max tw-align-middle"
          onClick={() => this.setState({ showModal: true })}
          icon="pen"
        />
        <UpdateProjectModal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          project={this.props.project}
          projectTemplates={this.props.projectTemplates}
          isSuperAdmin={this.props.user.get('isSuperAdmin', false)}
          canUpdateTypeAndExpiration={this.props.user
            .get('features', List())
            .includes(FEATURE_CAN_UPDATE_PROJECT_SETTINGS)}
          onSubmit={this.props.onSubmit}
        />
      </>
    );
  }
}

UpdateProjectButton.propTypes = {
  user: PropTypes.instanceOf(Map).isRequired,
  project: PropTypes.instanceOf(Map).isRequired,
  projectTemplates: PropTypes.instanceOf(List).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UpdateProjectButton;
