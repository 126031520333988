import { Item } from '@radix-ui/react-radio-group';

import { cn } from '../../utils';
import { Label } from '../Label';

import type { RadioGroupItemProps } from './types';

export const RadioGroupItemRadio = ({
  value,
  className,
  children,
  ...props
}: RadioGroupItemProps) => (
  <div className="tw-flex tw-flex-nowrap tw-items-center tw-gap-2">
    <Item
      {...props}
      id={value}
      value={value}
      className={cn(
        // reset
        'tw-bg-transparent',
        'tw-p-0',
        'tw-peer',
        'tw-cursor-pointer',
        'tw-whitespace-nowrap',

        // position
        'tw-flex',
        'tw-items-center',
        'tw-justify-center',

        // disabled
        'disabled:tw-opacity-40',
        'disabled:tw-cursor-not-allowed',

        'tw-border-none',
        'tw-size-2.5',
        'tw-rounded-full',
        'tw-outline',
        'tw-outline-1',
        'tw-outline-offset-2',

        // checked
        'data-[state=checked]:tw-outline-secondary-500',
        'data-[state=checked]:tw-bg-secondary-500',

        // unchecked
        'data-[state=unchecked]:tw-outline-neutral-200',

        // unchecked:hover
        'data-[state=unchecked]:hover:tw-outline-secondary-500',

        // unchecked:hover:disabled
        'data-[state=unchecked]:disabled:hover:tw-outline-neutral-200',

        // focus
        'focus-visible:tw-ring-2',
        'focus-visible:tw-ring-secondary-200',
        className,
      )}
    />

    <Label className="tw-whitespace-nowrap peer-disabled:tw-opacity-40" htmlFor={value}>
      {children}
    </Label>
  </div>
);
