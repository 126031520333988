import createReactClass from 'create-react-class';

import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import BucketsStore from '@/modules/legacy-transformation/stores/TransformationBucketsStore';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import NewTransformationBucketButton from './NewTransformationBucketButton';

const TransformationBucketButtons = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, DevBranchesStore, BucketsStore)],

  getStateFromStores() {
    return {
      readOnly: ApplicationStore.isReadOnly(),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      hasBuckets: BucketsStore.getAll().count() > 0,
    };
  },

  render() {
    if (this.state.readOnly || !this.state.hasBuckets) {
      return null;
    }

    return <NewTransformationBucketButton />;
  },
});

export default TransformationBucketButtons;
