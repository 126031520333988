import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { cn, Icon, Tooltip } from '@keboola/design';

import StorageActionCreators from '@/modules/components/StorageActionCreators';
import Modal from './Modal';

const ModalHandler = createReactClass({
  propTypes: {
    file: PropTypes.object.isRequired,
    children: PropTypes.element,
    linkClass: PropTypes.string,
    mode: PropTypes.string,
    onKeyDown: PropTypes.func,
  },

  getInitialState() {
    return {
      isModalOpen: false,
    };
  },

  render() {
    return (
      <>
        {this.renderButton()}
        <Modal
          file={this.props.file}
          isModalOpen={this.state.isModalOpen}
          onModalHide={this.closeModal}
          onPrepareStart={this.startJob}
        />
      </>
    );
  },

  renderButton() {
    if (this.props.mode === 'button') {
      return (
        <Tooltip tooltip="Download file" placement="top">
          <Button bsStyle="link" className="text-muted" onClick={this.openModal}>
            <Icon icon="circle-down" fixedWidth />
          </Button>
        </Tooltip>
      );
    }

    return (
      <Button
        bsStyle="link"
        onClick={this.openModal}
        className={cn('btn-link-inline no-underline', this.props.linkClass)}
      >
        {this.props.children}
      </Button>
    );
  },

  startJob() {
    this.closeModal();
    return StorageActionCreators.downloadSlicedFile(this.props.file.toJS());
  },

  openModal() {
    this.setState({ isModalOpen: true });
  },

  closeModal() {
    this.setState({ isModalOpen: false });
  },
});

export default ModalHandler;
