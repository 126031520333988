import type { ReactNode } from 'react';
import * as RadixTabs from '@radix-ui/react-tabs';

import { cn } from '../utils';

const LIST = 'tw-flex tw-border-0 tw-border-b tw-border-solid tw-border-neutral-200 tw-gap-8';

const TRIGGER_COMMON = {
  main: 'tw-flex tw-justify-center tw-items-center tw-bg-transparent tw-group/tab-trigger',
  border: 'tw-border-0 tw-border-b-[3px] tw-border-solid tw-border-transparent',
  spacing: 'tw-pt-2.5 tw-pb-5 tw-px-0 tw-m-0',
  font: 'tw-font-medium tw-leading-5 tw-text-neutral-400 tw-text-base',
  active: 'data-[state=active]:tw-text-secondary-500 data-[state=active]:tw-border-secondary-500',
  disabled:
    'data-[disabled]:tw-text-neutral-400 data-[disabled]:tw-opacity-50 data-[disabled]:tw-cursor-not-allowed',
  hover:
    'hover:enabled:tw-text-secondary-500 hover:enabled:tw-border-secondary-500 hover:enabled:tw-cursor-pointer',
};

type Trigger<T extends string> = {
  value: T;
  title: ReactNode;
} & Omit<RadixTabs.TabsTriggerProps, 'title'>;

const Tabs = <T extends string>({
  triggers,
  inModal = false,
  children,
  defaultValue,
  onValueChange,
  rootClassName,
  listClassName,
  triggerClassName,
  ...rest
}: {
  triggers: Trigger<T>[];
  inModal?: boolean;
  children: ReactNode;
  defaultValue?: string;
  onValueChange?: (value: T) => void;
  rootClassName?: string;
  listClassName?: string;
  triggerClassName?: string;
} & Omit<RadixTabs.TabsProps, 'onValueChange'>) => {
  return (
    <RadixTabs.Root
      {...rest}
      className={cn('tw-flex tw-flex-col tw-gap-6', rootClassName)}
      defaultValue={defaultValue || triggers[0]?.value}
      onValueChange={onValueChange as (value: string) => void}
    >
      <RadixTabs.List className={cn(LIST, listClassName)}>
        {triggers.map(({ value, title, ...rest }) => (
          <RadixTabs.Trigger
            key={value}
            value={value}
            className={cn(
              ...Object.values(TRIGGER_COMMON),
              { 'tw-text-sm': inModal },
              triggerClassName,
            )}
            {...rest}
          >
            {title}
          </RadixTabs.Trigger>
        ))}
      </RadixTabs.List>
      {children}
    </RadixTabs.Root>
  );
};

const TabsContent = (props: RadixTabs.TabsContentProps) => (
  <RadixTabs.Content tabIndex={-1} {...props} />
);

export { Tabs, TabsContent, type Trigger };
