import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import StorageBucketStore from '@/modules/components/stores/StorageBucketsStore';
import ProvisioningActions from '@/modules/gooddata-writer-v3/gooddataProvisioning/actions';
import ProvisioningStore from '@/modules/gooddata-writer-v3/gooddataProvisioning/store';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import Credentials from './Credentials';

const CredentialsContainer = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, StorageBucketStore, ProvisioningStore)],

  propTypes: {
    value: PropTypes.shape({
      pid: PropTypes.string.isRequired,
      login: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired,
      backendUrl: PropTypes.string,
    }),
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
  },

  getStateFromStores() {
    return {
      configId: RoutesStore.getCurrentRouteParam('config'),
      isLoadingModel: ProvisioningStore.getIsLoadingModel(),
      buckets: StorageBucketStore.getAll(),
    };
  },

  UNSAFE_componentWillReceiveProps() {
    this.setState(this.getStateFromStores());
  },

  handleCreate(newProject) {
    const { readLDM, pid, login, password, backendUrl, targetBucket } = newProject;

    if (readLDM) {
      return this.props.onSave({ pid, login, password, backendUrl }).then(() =>
        ProvisioningActions.readModel({
          configurationId: this.state.configId,
          user: { login, '#password': password },
          project: { pid, backendUrl },
          bucket: targetBucket,
        }),
      );
    }

    return this.props.onSave({ pid, login, password, backendUrl });
  },

  resetConfigCredentials() {
    return this.props
      .onSave({ pid: '', login: '', password: '', backendUrl: '' })
      .then(() => this.props.onChange({ pid: '', login: '', password: '', backendUrl: '' }));
  },

  handleResetProject() {
    return this.resetConfigCredentials();
  },

  render() {
    return (
      <Credentials
        disabled={this.props.disabled}
        config={this.props.value}
        isLoadingModel={this.state.isLoadingModel}
        onHandleCreate={this.handleCreate}
        onHandleResetProject={this.handleResetProject}
        buckets={this.state.buckets}
        readOnly={this.props.readOnly}
      />
    );
  },
});

export default CredentialsContainer;
