import type { ReactNode } from 'react';

import { cn } from '@keboola/design';

import { BadgeBeta } from '@/react/common/Badges';

const BetaTitle = ({ small, children }: { small?: boolean; children?: ReactNode }) => {
  return (
    <div className={cn('tw-flex tw-items-center', small ? 'tw-gap-2' : 'tw-gap-3')}>
      {children}
      <BadgeBeta />
    </div>
  );
};

export default BetaTitle;
