import PropTypes from 'prop-types';
import { Button, ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { URLS } from '@keboola/constants';
import { Icon, Link } from '@keboola/design';

import actionCreators from '@/modules/transformations/ActionCreators';
import ConfirmButtons from '@/react/common/ConfirmButtons';

const Phase = createReactClass({
  propTypes: {
    transformation: PropTypes.object.isRequired,
    bucketId: PropTypes.string.isRequired,
  },

  getInitialState() {
    return {
      isSaving: false,
      phase: this.props.transformation.get('phase'),
      showModal: false,
    };
  },

  render() {
    return (
      <>
        <Button bsStyle="link" onClick={this.open} block>
          <Icon icon="list-radio" fixedWidth />
          Phase: <span className="tw-font-medium">{this.props.transformation.get('phase')}</span>
        </Button>
        <Modal onHide={this.close} show={this.state.showModal}>
          <form className="form-inline" onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Transformation Phase</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <Link href={`${URLS.USER_DOCUMENTATION}/transformations/#phases`}>A phase</Link> is
                a set of transformations.
              </p>
              <p>
                Phases may be used to divide transformations into logical blocks, transfer data
                between transformations, transformation engines, and remote transformations.
              </p>
              <FormGroup controlId="phaseNumber">
                <ControlLabel>Phase #</ControlLabel>{' '}
                <FormControl
                  type="number"
                  min={1}
                  autoFocus
                  value={parseInt(this.state.phase, 10)}
                  onChange={this.handlePhaseChange}
                  disabled={this.state.isSaving}
                />
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <ConfirmButtons
                block
                saveButtonType="submit"
                isSaving={this.state.isSaving}
                isDisabled={!this.isValid()}
              />
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  },

  open() {
    this.setState({ showModal: true });
  },

  close() {
    this.setState({ isSaving: false, showModal: false });
  },

  handlePhaseChange(e) {
    if (e.target.value < 0) {
      return;
    }

    this.setState({ phase: e.target.value });
  },

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ isSaving: true });
    actionCreators
      .changeTransformationProperty(
        this.props.bucketId,
        this.props.transformation.get('id'),
        'phase',
        this.state.phase,
      )
      .then(() => this.close())
      .catch((error) => {
        this.setState({ isSaving: false });
        throw error;
      });
  },

  isValid() {
    return this.state.phase >= 1;
  },
});

export default Phase;
