import { Component } from 'react';
import PropTypes from 'prop-types';

import { FormGroup, HelpBlock, Label, TextInput } from '@keboola/design';

import Checkbox from '@/react/common/Checkbox';

const FORM_GROUP_CLASS_NAMES = 'tw-grid tw-grid-cols-3 tw-items-baseline tw-mb-4 tw-gap-4';

class Source extends Component {
  render() {
    return (
      <div className="box">
        <div className="box-header big-padding with-border">
          <h3 className="box-title">Source</h3>
        </div>
        <div className="box-content">
          <FormGroup className={FORM_GROUP_CLASS_NAMES}>
            <Label htmlFor="file-name">File name</Label>
            <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
              <TextInput
                id="file-name"
                variant="secondary"
                value={this.props.value.file_name}
                onChange={(value) => {
                  this.props.onChange({ file_name: value });
                }}
                placeholder="folder/subfolder/file_*"
                disabled={this.props.disabled}
              />
              <HelpBlock>
                Name of a source file with its extension or wildcard.
                <ul>
                  <li>
                    <code>{'folder/subfolder/test.csv'}</code> will download &quot;test&quot; CSV
                    file from &quot;folder/subfolder&quot; directory
                  </li>
                  <li>
                    <code>{'test_*'}</code> will download all CSV files with &quot;test_&quot;
                    prefix
                  </li>
                </ul>
              </HelpBlock>
            </div>
          </FormGroup>

          <FormGroup className={FORM_GROUP_CLASS_NAMES}>
            <div className="tw-col-span-2 tw-col-start-2 tw-flex tw-flex-col tw-gap-1">
              <Checkbox
                checked={this.props.value.new_files_only}
                onChange={(checked) => this.props.onChange({ new_files_only: checked })}
                disabled={this.props.disabled}
              >
                New Files Only
              </Checkbox>
              <HelpBlock>
                Every job stores the timestamp of the last downloaded file and a subsequent job can
                pick up from there.
              </HelpBlock>
            </div>
          </FormGroup>
        </div>
      </div>
    );
  }
}

Source.propTypes = {
  value: PropTypes.shape({
    file_name: PropTypes.string.isRequired,
    new_files_only: PropTypes.bool.isRequired,
  }),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Source;
