import type { Map } from 'immutable';

import { FILTERS, FILTERS_GROUP, SHARING } from '@/constants';
import type { BucketType } from '@/modules/data-catalog/types';
import { matchByWords } from '@/utils';

type FilterOptions = {
  getDescription: (bucket: any, defaultValue: string) => string;
  checkLinked: (bucket: any) => boolean;
};

export const getFilteredSortedBuckets = (
  buckets: BucketType[],
  searchFilters: Map<string, any>,
  currentAdmin: Map<string, any>,
  query: string,
  sorter: (a: any, b: any) => number,
  options: FilterOptions,
): BucketType[] => {
  const connectionFilter = searchFilters?.toJS()[FILTERS_GROUP.SHARING];
  const ownershipFilter = searchFilters?.toJS()[FILTERS_GROUP.OWNERSHIP];

  const queryFilteredBuckets = buckets.filter((bucket) => {
    const id = bucket.get('id');
    const name = bucket.get('displayName');
    const description = options.getDescription(bucket, '');

    return (
      matchByWords([name, description], query) || id.toLowerCase() === query.trim().toLowerCase()
    );
  });

  const sharingFilteredBuckets = queryFilteredBuckets.filter((bucket) => {
    if (connectionFilter && Object.values(SHARING).includes(connectionFilter)) {
      if (connectionFilter === FILTERS.LINKED) return options.checkLinked(bucket);
    }
    return true;
  });

  const ownershipFilteredBuckets = sharingFilteredBuckets.filter((bucket) => {
    if (ownershipFilter) {
      const ownerEmail = bucket.getIn(['owner', 'email']);
      return ownerEmail === currentAdmin.get('email');
    }
    return true;
  });

  return ownershipFilteredBuckets.sort(sorter);
};
