import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import SapiTableSelector from '@/modules/components/react/components/SapiTableSelector';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';

const AddNewTableModal = createReactClass({
  propTypes: {
    show: PropTypes.bool.isRequired,
    onHideFn: PropTypes.func.isRequired,
    selectedTableId: PropTypes.string,
    onSetTableIdFn: PropTypes.func.isRequired,
    configuredTables: PropTypes.object,
    onSaveFn: PropTypes.func.isRequired,
    buckets: PropTypes.object.isRequired,
    tables: PropTypes.object.isRequired,
  },

  render() {
    return (
      <Modal show={this.props.show} onHide={() => this.props.onHideFn()}>
        <Modal.Header closeButton>
          <Modal.Title>Add Table</Modal.Title>
          <ModalIcon.Plus />
        </Modal.Header>
        <Modal.Body>
          <SapiTableSelector
            autoFocus
            placeholder="Source table"
            buckets={this.props.buckets}
            tables={this.props.tables}
            value={this.props.selectedTableId}
            onSelectTableFn={this.props.onSetTableIdFn}
            excludeTableFn={(tableId) =>
              !!(this.props.configuredTables && this.props.configuredTables.get(tableId))
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isDisabled={!!!this.props.selectedTableId}
            saveLabel="Add Table"
            onSave={() => {
              this.props.onSaveFn(this.props.selectedTableId);
              this.props.onHideFn();
            }}
          />
        </Modal.Footer>
      </Modal>
    );
  },
});

export default AddNewTableModal;
