import Promise from 'bluebird';
import type { Map } from 'immutable';

import StorageActionCreators from '@/modules/components/StorageActionCreators';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import DataCatalogActions from '@/modules/data-catalog/actions';
import SharedBucketDetail from '@/modules/data-catalog/react/SharedBucketDetail';
import SharedBucketHeaderButton from '@/modules/data-catalog/react/SharedBucketHeaderButton';
import SharedWithYou from '@/modules/data-catalog/react/SharedWithYou';
import ApplicationStore from '@/stores/ApplicationStore';
import HeaderButtons from './react/HeaderButtons';
import MySharedBucketDetail from './react/MySharedBucketDetail';
import MySharedBucketHeaderButton from './react/MySharedBucketHeaderButton';
import SharedFromThisProject from './react/SharedFromThisProject';
import { routeNames } from './constants';

export const sharedWithYouRouter = {
  name: routeNames.SHARED_WITH_YOU,
  title: 'Data Catalog',
  defaultRouteHandler: SharedWithYou,
  requireData: [
    () => DataCatalogActions.loadSharedBuckets(),
    () => StorageActionCreators.loadBucketsAndTables(),
  ],
  poll: {
    skipFirst: true,
    action: () => {
      return Promise.all([
        DataCatalogActions.loadSharedBuckets(),
        StorageActionCreators.loadBucketsAndTables(),
      ]);
    },
  },
  childRoutes: [
    {
      name: routeNames.SHARED_WITH_YOU_DETAIL,
      path: ':projectId/:bucketId',
      title(routerState: Map<string, any>) {
        const sharedBucketId = routerState.getIn(['params', 'bucketId']);
        const currentProjectId = ApplicationStore.getCurrentProjectId();
        const sharedProjectId = parseInt(routerState.getIn(['params', 'projectId']), 10);
        const sharedBucket = StorageBucketsStore.getSharedBucket(
          sharedBucketId,
          currentProjectId,
          sharedProjectId,
        );
        return `Bucket ${sharedBucket.get('displayName', sharedBucketId)}`;
      },
      defaultRouteHandler: SharedBucketDetail,
      headerButtonsHandler: SharedBucketHeaderButton,
    },
  ],
};

export const sharedFromProjectRouter = {
  name: routeNames.SHARED_FROM_THIS_PROJECT,
  title: 'Data Catalog',
  defaultRouteHandler: SharedFromThisProject,
  headerButtonsHandler: HeaderButtons,
  requireData: [() => StorageActionCreators.loadBucketsAndTables()],
  poll: {
    skipFirst: true,
    action: () => StorageActionCreators.loadBucketsAndTables(),
  },
  childRoutes: [
    {
      name: routeNames.SHARED_FROM_THIS_PROJECT_DETAIL,
      path: ':bucketId',
      title(routerState: Map<string, any>) {
        const sharedBucket = StorageBucketsStore.getBucket(
          routerState.getIn(['params', 'bucketId']),
        );
        return `Bucket ${sharedBucket.get(
          'displayName',
          routerState.getIn(['params', 'bucketId']),
        )}`;
      },
      defaultRouteHandler: MySharedBucketDetail,
      headerButtonsHandler: MySharedBucketHeaderButton,
    },
  ],
};
