import { useState } from 'react';

import { saveMetadataDescription } from '@/modules/home/actions';
import DescriptionBox from '@/react/common/DescriptionBox/DescriptionBox';

const ProjectDescriptionBox = (props: { description: string; readOnly: boolean }) => {
  const [editorValue, setEditorValue] = useState<string | null>(null);

  return (
    <DescriptionBox
      placeholder="Project Description"
      editorValue={editorValue}
      description={props.description}
      onSetEditorValue={setEditorValue}
      onSave={() => saveMetadataDescription(editorValue || '')}
      readOnly={props.readOnly}
    />
  );
};

export default ProjectDescriptionBox;
