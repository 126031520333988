import type { Map } from 'immutable';

import { Badge, Icon } from '@keboola/design';

import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';

const DevBranchLabel = ({
  bucket,
  forceShow,
}: {
  bucket?: Map<string, any>;
  forceShow?: boolean;
}) => {
  if (!forceShow && (!bucket || !isCreatedInDevBranch(bucket))) {
    return null;
  }

  return (
    <Badge variant="orange" placement="left">
      <Icon icon={['far', 'code-branch']} />
    </Badge>
  );
};

export default DevBranchLabel;
