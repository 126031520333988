import { Card } from '@keboola/design';

import { KEBOOLA_DATA_APPS } from '@/constants/componentIds';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { routeNames } from '@/modules/data-apps/constants';
import DataAppsStore from '@/modules/data-apps/DataAppsStore';
import { DownloadButton } from '@/modules/data-apps/TerminalLogsTab/DownloadButton';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';
import { Logs } from './Logs';
import { TerminalLogs } from './TerminalLogs';

export const TerminalLogsTab = () => {
  const componentId = KEBOOLA_DATA_APPS;
  const { app, configId } = useStores(
    () => {
      const configId = RoutesStore.getCurrentRouteParam('config') as string;
      const configData = InstalledComponentsStore.getConfigData(KEBOOLA_DATA_APPS, configId);
      const app = DataAppsStore.getDataApp(configData.getIn(['parameters', 'id']));

      return {
        app,
        configId,
      };
    },
    [],
    [InstalledComponentsStore, RoutesStore, DataAppsStore],
  );

  return (
    <>
      <ConfigurationTabs
        componentId={componentId}
        configId={configId}
        versionsLinkTo={routeNames.DATA_APP_VERSIONS}
        notificationsLinkTo={routeNames.DATA_APP_NOTIFICATIONS}
      />

      <Card>
        <Card.Header className="tw-flex tw-items-center tw-justify-between">
          <Card.Title>Terminal Logs</Card.Title>
          {app && <DownloadButton app={app} />}
        </Card.Header>

        <Card.Content>
          {app ? <TerminalLogs app={app} /> : <Logs>App details not found</Logs>}
        </Card.Content>
      </Card>
    </>
  );
};
