import type { Map } from 'immutable';

import { Icon } from '@keboola/design';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { isObsoleteComponent } from '@/modules/trash/utils';
import FlowsValidationBox from '@/react/common/FlowsValidationBox';

type Props = {
  componentId: string;
  config: Map<string, any>;
  flows: Map<string, any>;
};

const DeleteConfigConfirmBody = ({ componentId, flows, config }: Props) => (
  <>
    <p>
      {'Are you sure you want to delete the '}
      {componentId === KEBOOLA_ORCHESTRATOR ? 'flow' : 'configuration'}
      {config.get('name') ? <strong> {config.get('name')}</strong> : ''}?
    </p>
    {isObsoleteComponent(componentId) && (
      <p>
        <Icon icon="triangle-exclamation" className="icon-addon-right" />
        This configuration can&apos;t be restored.
      </p>
    )}
    <FlowsValidationBox flows={flows} configurations={[{ componentId, config }]} />
  </>
);

export default DeleteConfigConfirmBody;
