import type { MouseEvent, MouseEventHandler, ReactNode } from 'react';
import { Slot } from '@radix-ui/react-slot';

import { cn } from '../../utils';

import { buttonStyles } from './styles';
import type { CommonButtonProps } from './types';

type ButtonLinkProps = {
  children: ReactNode;
  onClick?: (e: MouseEvent) => void;
};

export const ButtonLink = ({
  children,
  onClick,
  size = 'medium',
  variant = 'primary',
  disabled = false,
  className,
}: CommonButtonProps & ButtonLinkProps) => {
  const custom = cn(className, 'hover:tw-text-inherit tw-no-underline');
  const styles = buttonStyles({ variant, size, disabled, className: custom });

  const handleClick: MouseEventHandler = (event) => {
    const isAnchor = event.currentTarget.tagName === 'A';

    // <a/> does not support disabled by default, and we cannot use pointer-events: none because cursor-not-allowed would not work.
    if (isAnchor && disabled) {
      event.preventDefault();
      return;
    }

    onClick?.(event);
  };
  return (
    <Slot data-disabled={disabled} onClick={handleClick} className={styles}>
      {children}
    </Slot>
  );
};
