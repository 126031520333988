import type { HTMLAttributes } from 'react';

import { cn } from '../../utils';

import { cardTitleClassnames } from './styles';

type Props = HTMLAttributes<HTMLHeadingElement>;

export const CardTitle = ({ children, className, ...rest }: Props) => (
  <h3 className={cn(cardTitleClassnames, className)} {...rest}>
    {children}
  </h3>
);
