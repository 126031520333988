import { Modal } from 'react-bootstrap';
import { List, type Map } from 'immutable';

import type { FilterOperator, WhereFilters } from '@keboola/api-client';
import {
  Alert,
  Button,
  FormGroup,
  Icon,
  IconButton,
  Label,
  TextInput,
  Tooltip,
} from '@keboola/design';

import dayjs from '@/date';
import Loader from '@/react/common/Loader';
import Select from '@/react/common/Select';
import type { Params } from './helpers';
import { DEFAULT_WHERE_FILTER, prepareParams } from './helpers';

const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';

const OPERATOR_OPTIONS = [
  { label: 'equal', value: 'eq' },
  { label: 'not equal', value: 'ne' },
];

export const FormStep = (props: {
  table: Map<string, any>;
  params: Params;
  setParams: (params: Params) => void;
  onLoadPreview: () => void;
  isLoading: boolean;
  error: string | null;
}) => {
  const renderWhereFilterRow = (filter: WhereFilters, index: number) => {
    const existingFilter = props.params.whereFilters[index] || DEFAULT_WHERE_FILTER;

    return (
      <div className="tw-mb-4 tw-flex tw-justify-between tw-gap-4" key={index}>
        <div className="tw-grid tw-flex-1 tw-grid-cols-3 tw-items-start tw-gap-4">
          <Select
            clearable={false}
            placeholder="Select column"
            options={props.table.get('columns', List()).map((column: string) => {
              return { label: column, value: column };
            })}
            value={existingFilter.column}
            onChange={(column: string) => {
              props.setParams({
                ...props.params,
                whereFilters: props.params.whereFilters.toSpliced(index, 1, {
                  ...existingFilter,
                  column,
                }),
              });
            }}
          />
          <Select
            clearable={false}
            placeholder="Select operator"
            options={OPERATOR_OPTIONS}
            value={existingFilter.operator}
            onChange={(operator: FilterOperator) =>
              props.setParams({
                ...props.params,
                whereFilters: props.params.whereFilters.toSpliced(index, 1, {
                  ...existingFilter,
                  operator,
                }),
              })
            }
          />
          <Select
            multi
            allowCreate
            emptyStrings
            hideSelectAllOptions
            placeholder="Enter value"
            promptTextCreator={(label) => `Set value "${label}"`}
            value={existingFilter.values}
            onChange={(value) => {
              props.setParams({
                ...props.params,
                whereFilters: props.params.whereFilters.toSpliced(index, 1, {
                  ...existingFilter,
                  values: value.toArray(),
                }),
              });
            }}
          />
        </div>
        <div className="tw-inline-flex tw-justify-end">
          <Tooltip tooltip="Delete filter" placement="top">
            <IconButton
              icon="trash"
              variant="invisible"
              onClick={() =>
                props.setParams({
                  ...props.params,
                  whereFilters: props.params.whereFilters.toSpliced(index, 1),
                })
              }
            />
          </Tooltip>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal.Body>
        <div className="tw-grid tw-grid-cols-2 tw-gap-4">
          <FormGroup className="tw-mb-4">
            <Label htmlFor="changedSince">Changed Since</Label>
            <TextInput
              id="changedSince"
              type="datetime-local"
              placeholder={DATETIME_FORMAT}
              max={dayjs().format(DATETIME_FORMAT)}
              value={props.params.changedSince}
              onChange={(value) => props.setParams({ ...props.params, changedSince: value })}
            />
          </FormGroup>
          <FormGroup className="tw-mb-4">
            <Label htmlFor="changedUntil">Changed Until</Label>
            <TextInput
              id="changedUntil"
              type="datetime-local"
              placeholder={DATETIME_FORMAT}
              max={dayjs().format(DATETIME_FORMAT)}
              value={props.params.changedUntil}
              onChange={(value) => props.setParams({ ...props.params, changedUntil: value })}
            />
          </FormGroup>
        </div>
        <FormGroup className="tw-mb-4">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <Label>Where Filters</Label>
          {props.params.whereFilters.map(renderWhereFilterRow)}
          <Button
            size="small"
            variant="outline"
            className="tw-self-start"
            onClick={() =>
              props.setParams({
                ...props.params,
                whereFilters: [...props.params.whereFilters, DEFAULT_WHERE_FILTER],
              })
            }
          >
            <Icon icon="plus" />
            Add filter
          </Button>
        </FormGroup>
        {props.error && <Alert variant="warning">{props.error}</Alert>}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="tw-w-full"
          onClick={() => props.onLoadPreview()}
          disabled={Object.keys(prepareParams(props.table, props.params)).length === 0}
        >
          {props.isLoading ? (
            <>
              <Loader />
              Loading matched rows...
            </>
          ) : (
            <>
              <Icon icon="eye" />
              Check matched rows
            </>
          )}
        </Button>
      </Modal.Footer>
    </>
  );
};
