import { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import { ButtonInline, Tooltip } from '@keboola/design';

import ModalIcon from '@/react/common/ModalIcon';
import string from '@/utils/string';
import UsedByProjectsList from './UsedByProjectsList';

class UsedBy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  render() {
    return (
      <span className="font-normal">
        {this.renderIcons()}
        {this.renderModal()}
      </span>
    );
  }

  renderIcons() {
    if (!this.props.links.count()) {
      return <span className="text-muted">Not used yet</span>;
    }

    return (
      <Tooltip placement="top" tooltip="Show details">
        <ButtonInline className="tw-text-base" onClick={() => this.setState({ showModal: true })}>
          {this.props.links.count()} {string.pluralize(this.props.links.count(), 'project')}
        </ButtonInline>
      </Tooltip>
    );
  }

  renderModal() {
    if (!this.props.links.count()) {
      return null;
    }

    return (
      <Modal show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Used by</Modal.Title>
          <ModalIcon icon="user" color="blue" bold />
        </Modal.Header>
        <Modal.Body>
          <UsedByProjectsList urlTemplates={this.props.urlTemplates} links={this.props.links} />
        </Modal.Body>
      </Modal>
    );
  }
}

UsedBy.propTypes = {
  urlTemplates: PropTypes.object.isRequired,
  links: PropTypes.object.isRequired,
};

export default UsedBy;
