import { Fragment } from 'react';

import { cn, Separator, Skeleton } from '@keboola/design';

type Props = {
  variant: 'project' | 'admin';
};

export const FeatureListSkeleton = ({ variant }: Props) => {
  const isAdminFeatureVariant = variant === 'admin';
  const count = isAdminFeatureVariant ? 1 : 4;

  return (
    <ul className="tw-m-0 tw-flex tw-flex-col tw-gap-6 tw-p-0">
      {Array(isAdminFeatureVariant ? 1 : 4)
        .fill(count)
        .map((_, index, self) => (
          <Fragment key={index}>
            <div
              key={index}
              className={cn(
                'tw-grid tw-items-start tw-gap-4 tw-font-sans ',
                isAdminFeatureVariant
                  ? 'tw-grid-cols-1'
                  : 'tw-grid-cols-1 xl:tw-grid-cols-2 2xl:tw-grid-cols-3',
              )}
            >
              <div className="tw-flex tw-items-center tw-gap-4">
                <Skeleton className="tw-w-8" height="xs" />
                <Skeleton className="tw-w-24" height="xs" />
              </div>

              <span
                className={cn('tw-flex tw-flex-col tw-gap-2', {
                  'tw-ml-12': isAdminFeatureVariant,
                })}
              >
                <Skeleton count={3} className="tw-w-full" height="xs" />
                <Skeleton className="tw-w-48" height="xs" />
              </span>
            </div>

            {index !== self.length - 1 && <Separator orientation="horizontal" />}
          </Fragment>
        ))}
    </ul>
  );
};
