import { List, Map } from 'immutable';

import { Icon, Tooltip } from '@keboola/design';

import string from '@/utils/string';

const ColumnDataPreview = (props: {
  tableData: Map<string, any>;
  columnName: string;
  loading: boolean;
}) => {
  const columnIndex = props.tableData?.get('columns', List()).indexOf(props.columnName);
  const items = props.tableData
    ?.get('rows', Map())
    .map((row: Map<string, any>) => row.getIn([columnIndex, 'value']))
    .toSet()
    .slice(0, 10)
    .map((value: string, index: number) => (
      <li key={index} className="tw-mb-1 tw-list-[circle]">
        {string.truncate(value, 100)}
      </li>
    ))
    .toArray();

  return (
    <>
      <Tooltip
        type="explanatory"
        placement="left"
        tooltip={
          <>
            {props.loading ? (
              'Loading data...'
            ) : !items?.length ? (
              'No data available'
            ) : (
              <ul className="tw-min-w-fit">{items}</ul>
            )}
          </>
        }
      >
        <Icon
          className="text-muted tw-cursor-pointer hover:tw-text-secondary-500"
          icon="eye"
          fixedWidth
        />
      </Tooltip>
    </>
  );
};

export default ColumnDataPreview;
