import { useContext } from 'react';
import type { ComponentProps, CSSProperties, ReactNode } from 'react';
import { Handle, Position, useStore } from '@xyflow/react';
import { useShallow } from 'zustand/react/shallow';

import { cn, Icon } from '@keboola/design';

import { GraphContext } from '@/modules/lineage/contexts/GraphContext';
import { useGraphStore } from '@/modules/lineage/contexts/graphStore';
import { zoomOutSelector, zoomSelector } from '@/modules/lineage/helpers';
import { Truncated } from '@/react/common';
import MarkedText from '@/react/common/MarkedText';
import NodeDropdown from './Dropdown';

const BaseNode = ({
  nodeId,
  nodeTitle,
  nodeType,
  customIcon,
  iconName,
  iconClassName,
  backgroundColor,
  children,
  bottomContent,
  isLink,
  onHighlight,
  onClick,
  isAlias = false,
  graphId = null,
  additionalTitleContent = null,
}: {
  nodeId: string;
  nodeTitle: string;
  nodeType: string;
  onClick: (nodeId: string) => void;
  customIcon?: ReactNode;
  iconName?: ComponentProps<typeof Icon>['icon'];
  iconClassName?: string;
  backgroundColor?: string;
  children?: ReactNode;
  bottomContent?: ReactNode;
  isLink?: boolean;
  onHighlight?: (nodeId: string) => void;
  isAlias?: boolean;
  graphId?: string | null;
  additionalTitleContent?: ReactNode | null;
}) => {
  const { mainNodeId, zoomOnHover } = useContext(GraphContext);
  const { searchQuery, nodeDetail } = useGraphStore(
    useShallow((state) => ({
      searchQuery: state.searchQuery,
      nodeDetail: state.nodeDetail,
    })),
  );
  const simpleView = useStore(zoomOutSelector);
  const zoom = useStore(zoomSelector);

  const isMainNode = mainNodeId === nodeId;
  const isDetailOpen = nodeDetail?.fqid === nodeId;

  return (
    <div
      data-zoom-on-hover={zoomOnHover}
      className="node-custom tw-group/wrapper tw-relative tw-flex tw-w-[340px] tw-flex-col tw-text-xs"
      style={
        {
          '--zoom': !zoomOnHover || zoom > 0.6 ? 1 : 1 / zoom,
        } as CSSProperties
      }
    >
      <div
        className={cn(
          'tw-rounded-md tw-shadow-[0_3px_3px_0_rgba(34,37,41,0.08)] tw-outline',
          // for some reason focus on node (opacity 0 on other nodes) does not work when default transparent outline is not set
          {
            'tw-outline-[3px] tw-outline-secondary-500': isDetailOpen,
            'tw-outline-[3px] tw-outline-teal-500': isMainNode,
            'tw-outline-1 tw-outline-transparent group-hover/wrapper:tw-outline-neutral-200':
              !isDetailOpen && !isMainNode,
            [`${backgroundColor} group-hover/wrapper:tw-bg-transparent`]: simpleView,
          },
        )}
      >
        <div
          className={cn(
            { '!tw-invisible': simpleView },
            { 'group-hover/wrapper:!tw-visible': simpleView && zoomOnHover },
          )}
        >
          <div className="tw-group/node tw-relative tw-flex">
            <div
              onClick={() => onClick(nodeId)}
              className="tw-flex tw-flex-1 tw-items-center tw-justify-center"
            >
              <div
                className={cn(
                  'tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-rounded-tl-md tw-px-3 tw-py-2',
                  backgroundColor,
                  { 'tw-rounded-bl-md': !bottomContent },
                )}
              >
                {customIcon ??
                  (iconName && (
                    <Icon icon={iconName} className={cn('tw-h-4 tw-w-4', iconClassName)} />
                  ))}
                <p className="tw-mb-0 tw-mt-1 tw-text-[10px] tw-font-semibold">{nodeType}</p>
              </div>
              <div
                className={cn(
                  'tw-flex tw-h-full tw-w-full tw-items-center tw-justify-between tw-bg-white tw-p-3',
                )}
              >
                <div className="tw-flex tw-flex-col tw-items-start tw-gap-2">
                  <Truncated
                    twoLines
                    className={cn('tw-mb-0 tw-flex tw-items-center tw-font-medium', {
                      'tw-border-0 tw-border-b tw-border-dotted tw-border-secondary-600': isAlias,
                    })}
                    tooltip={nodeTitle}
                    text={
                      <span className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                        <MarkedText mark={searchQuery} source={nodeTitle} />
                        {additionalTitleContent}
                      </span>
                    }
                  />
                  {children}
                </div>
              </div>
            </div>
            <div
              onClick={() => isLink && onClick(nodeId)}
              className={cn(
                'tw-flex tw-w-fit tw-min-w-7 tw-items-center tw-justify-end tw-rounded-tr-md tw-bg-white',
                {
                  'tw-rounded-br-md': !bottomContent,
                },
              )}
            >
              {isLink ? (
                <Icon
                  icon="chevron-right"
                  className="tw-invisible tw-mr-4 tw-text-secondary-600 group-hover/node:tw-visible"
                />
              ) : (
                <NodeDropdown onHighlight={onHighlight} graphId={graphId} nodeId={nodeId} />
              )}
            </div>
            <Handle
              id="main"
              type="target"
              position={Position.Left}
              className="tw-invisible -tw-left-0.5"
            />
            <Handle
              id="main"
              type="source"
              position={Position.Right}
              className="tw-invisible -tw-right-0.5"
            />
          </div>
          {bottomContent && (
            <>
              <div className="tw-h-0.5 tw-bg-neutral-150" />
              {bottomContent}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BaseNode;
