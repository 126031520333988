import type { List } from 'immutable';

import type { File } from '@keboola/api-client';

import { downloadFiles, downloadSlicedFiles } from '@/modules/storage/helpers';
import { FileSize } from '@/react/common';
import ConfirmModal from '@/react/common/ConfirmModal';
import string from '@/utils/string';

type Props = {
  files: Map<string, any>;
  downloadingFiles: List<string>;
  selectedIds: Set<string>;
  canDownloadSlicedFile: boolean;
  show: boolean;
  onConfirm: () => void;
  onHide: () => void;
};

const MultipleDownloadModal = (props: Props) => {
  const prepareFiles = () => {
    const files: File[] = [];
    const slicedFiles: File[] = [];

    props.files.forEach((file) => {
      const isSelected = props.selectedIds.has(file.get('id'));

      if (isSelected) {
        if (file.get('isSliced')) {
          if (props.canDownloadSlicedFile && !props.downloadingFiles.includes(file.get('id'))) {
            slicedFiles.push(file.toJS());
          }
        } else {
          files.push(file.toJS());
        }
      }
    });

    return {
      files,
      slicedFiles,
      numberOfFilesToDownload: [...files, ...slicedFiles].length,
    };
  };

  const { files, slicedFiles, numberOfFilesToDownload } = prepareFiles();

  return (
    <ConfirmModal
      show={props.show}
      icon="circle-down"
      title={`Download ${numberOfFilesToDownload} ${string.pluralize(
        numberOfFilesToDownload,
        'file',
      )}`}
      text={
        <>
          {slicedFiles.length > 0 && (
            <>
              <p>Following files are sliced into multiple chunks:</p>
              <ul>
                {slicedFiles.map((file, i) => (
                  <li key={i}>
                    <strong>
                      {file.name} (<FileSize size={file.sizeBytes} />)
                    </strong>
                  </li>
                ))}
              </ul>
              <p>
                All chunks will be packed into a <code>ZIP</code> file, once it&apos;s ready the
                download will start automatically.
              </p>
            </>
          )}
          <p>
            Are you sure you want to download the{' '}
            {numberOfFilesToDownload > 1 ? 'selected files' : 'file'}?
          </p>
        </>
      }
      buttonLabel="Download"
      buttonType="success"
      isDisabled={files.length === 0 && slicedFiles.length === 0}
      onConfirm={() => {
        if (files.length > 0) {
          downloadFiles(files);
        }

        if (slicedFiles.length > 0) {
          downloadSlicedFiles(slicedFiles);
        }

        props.onConfirm();

        return Promise.resolve();
      }}
      onHide={props.onHide}
    />
  );
};

export default MultipleDownloadModal;
