import { Component } from 'react';
import type { ReactNode } from 'react';
import type { WithRouterProps } from 'react-router';
import { withRouter } from 'react-router';
import type { Location } from 'history';
import _ from 'underscore';

import nextTick from '@/utils/nextTick';
import CatchUnsavedChangesModal from './CatchUnsavedChangesModal';

type Props = {
  children: ReactNode;
  isDirty: boolean;
  onSave: () => Promise<unknown>;
  isSaveDisabled?: boolean;
  onDirtyLeave?: () => void;
  text?: ReactNode;
};

class CatchUnsavedChanges extends Component<Props & WithRouterProps> {
  state: { isConfirmed: boolean; showConfirm: boolean; nextLocation: Location | null } = {
    isConfirmed: false,
    showConfirm: false,
    nextLocation: null,
  };

  routeLeaveHookRef: (() => void) | null = null;

  componentDidMount() {
    this.routeLeaveHookRef = this.props.router.setRouteLeaveHook(
      _.last(this.props.routes),
      this.checkIsDirty,
    );
  }

  componentWillUnmount() {
    if (this.props.isDirty && this.props.onDirtyLeave) {
      nextTick(this.props.onDirtyLeave);
    }

    this.routeLeaveHookRef?.();
    this.routeLeaveHookRef = null;
  }

  render() {
    return (
      <>
        {this.props.children}
        <CatchUnsavedChangesModal
          text={this.props.text}
          show={this.state.showConfirm}
          onHide={this.onHide}
          onLeave={() => this.handleLeave(this.state.nextLocation)}
          onSave={() => this.handleSave(this.state.nextLocation)}
          isSaveDisabled={this.props.isSaveDisabled}
        />
      </>
    );
  }

  handleSave = (nextLocation: Location | null) => {
    return this.props.onSave().then(() => this.handleLeave(nextLocation));
  };

  handleLeave = (nextLocation: Location | null) => {
    if (!nextLocation) {
      this.onHide();
      return;
    }

    this.setState({ isConfirmed: true }, () => {
      this.props.router.push(nextLocation);
      this.onHide();
    });
  };

  onHide = () => {
    this.setState({ showConfirm: false, isConfirmed: false, nextLocation: null });
  };

  checkIsDirty = (nextLocation: Location | undefined) => {
    if (this.props.isDirty && !this.state.isConfirmed && !nextLocation?.state?.force) {
      this.setState({ showConfirm: true, nextLocation });
      return false;
    }

    return true;
  };
}

const CatchUnsavedChangesWithRouter = withRouter(CatchUnsavedChanges);

export default CatchUnsavedChangesWithRouter;
