import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import Textarea from 'react-textarea-autosize';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { FormGroup, HelpBlock, Label, TextInput } from '@keboola/design';

import {
  KDS_TEAM_WR_HIVE_CSAS,
  KEBOOLA_EX_DB_HIVE,
  KEBOOLA_EX_DB_HIVE_CSAS,
  KEBOOLA_EX_DB_HIVE_CSAS_TEST,
} from '@/constants/componentIds';
import string from '@/utils/string';
import Checkbox from './Checkbox';
import { HIVE_AUTH_TYPES } from './constants';
import PasswordControl from './PasswordControl';
import Select from './Select';
import SshForm from './SshForm';
import TestCredentialsButtonGroup from './TestCredentialsButtonGroup';

const SSL_FILE_TYPES = {
  PEM: 'pem',
  JKS: 'jks',
};

const FORM_GROUP_CLASS_NAMES = 'tw-grid tw-grid-cols-3 tw-items-baseline tw-gap-4';

const HiveCredentials = createReactClass({
  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    credentials: PropTypes.object.isRequired,
    editingCredentials: PropTypes.object.isRequired,
    isSaving: PropTypes.bool.isRequired,
    hasValidCredentials: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    actionCreators: PropTypes.object.isRequired,
    hasArtifacts: PropTypes.bool.isRequired,
    hasQueries: PropTypes.bool,
  },

  render() {
    return (
      <div className="tw-flex tw-flex-col tw-gap-4">
        {this.renderQueriesWarning()}
        {this.renderForm()}
        {this.renderTestButton()}
      </div>
    );
  },

  renderForm() {
    const isDisabled = this.props.isSaving || this.props.readOnly;
    return (
      <>
        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="hostname">Hostname</Label>
          <TextInput
            id="hostname"
            className="tw-col-span-2"
            variant="secondary"
            value={this.props.credentials.get('host', '')}
            onChange={(value) =>
              this.props.onChange(this.props.editingCredentials.set('host', value))
            }
            disabled={isDisabled}
          />
        </FormGroup>

        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="port">Port</Label>
          <FormControl
            id="port"
            className="tw-col-span-2"
            type="number"
            value={this.props.credentials.get('port', '')}
            onChange={({ target }) =>
              this.props.onChange(
                this.props.editingCredentials.set(
                  'port',
                  target.value ? parseInt(target.value, 10) : '',
                ),
              )
            }
            disabled={isDisabled}
          />
        </FormGroup>

        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="database">Database</Label>
          <TextInput
            id="database"
            className="tw-col-span-2"
            variant="secondary"
            value={this.props.credentials.get('database', '')}
            onChange={(value) =>
              this.props.onChange(this.props.editingCredentials.set('database', value))
            }
            disabled={isDisabled}
          />
        </FormGroup>

        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <div className="tw-col-span-2 tw-col-start-2 tw-flex tw-flex-col tw-gap-2">
            <Checkbox
              disabled={isDisabled}
              checked={this.props.credentials.get('connectThrough', false)}
              onChange={(checked) => {
                this.props.onChange(this.props.editingCredentials.set('connectThrough', checked));
              }}
            >
              Enable Connect Through
            </Checkbox>
            <HelpBlock>
              Sets the <code>DelegationUID</code> from the user who runs the configuration.
            </HelpBlock>
          </div>
        </FormGroup>

        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="auth-type">Auth Type</Label>
          <Select
            id="auth-type"
            className="tw-col-span-2"
            clearable={false}
            options={Object.values(HIVE_AUTH_TYPES).map((value) => ({
              value,
              label: string.capitalize(value),
            }))}
            value={this.props.credentials.get('authType', HIVE_AUTH_TYPES.PASSWORD)}
            onChange={(value) =>
              this.props.onChange(
                value === HIVE_AUTH_TYPES.PASSWORD
                  ? this.props.editingCredentials
                      .set('authType', HIVE_AUTH_TYPES.PASSWORD)
                      .delete('kerberos')
                  : this.props.credentials
                      .set('authType', value)
                      .delete('user')
                      .delete('#password'),
              )
            }
            disabled={isDisabled}
          />
        </FormGroup>
        {this.props.credentials.get('authType', HIVE_AUTH_TYPES.PASSWORD) ===
        HIVE_AUTH_TYPES.PASSWORD
          ? this.renderPasswordForm(isDisabled)
          : this.renderKerberosForm(isDisabled)}
        {this.renderSshForm()}
        {this.renderSslForm(isDisabled)}
        {this.renderAdditionalAdvancedFields(isDisabled)}
      </>
    );
  },

  renderPasswordForm(isDisabled) {
    return (
      <>
        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="username">Username</Label>
          <TextInput
            id="username"
            className="tw-col-span-2"
            variant="secondary"
            value={this.props.credentials.get('user', '')}
            onChange={(value) =>
              this.props.onChange(this.props.editingCredentials.set('user', value))
            }
            disabled={isDisabled}
          />
        </FormGroup>

        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="password">Password</Label>
          <PasswordControl
            id="password"
            className="tw-col-span-2"
            disabled={isDisabled}
            value={this.props.credentials.get('#password', '')}
            onChange={({ target }) =>
              this.props.onChange(this.props.editingCredentials.set('#password', target.value))
            }
          />
        </FormGroup>
      </>
    );
  },

  renderKerberosForm(isDisabled) {
    return (
      <>
        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="kinit-principal">Kinit Principal</Label>
          <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
            <TextInput
              id="kinit-principal"
              variant="secondary"
              value={this.props.credentials.getIn(['kerberos', 'kinitPrincipal'], '')}
              onChange={(value) =>
                this.props.onChange(
                  this.props.editingCredentials.setIn(['kerberos', 'kinitPrincipal'], value),
                )
              }
              disabled={isDisabled}
            />
            <HelpBlock>
              Name of the principal for the kinit. It may be shortened if it is supported by your{' '}
              <code>krb5.conf</code>.
            </HelpBlock>
          </div>
        </FormGroup>

        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="service-principal">Service Principal</Label>
          <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
            <TextInput
              id="service-principal"
              variant="secondary"
              value={this.props.credentials.getIn(['kerberos', 'servicePrincipal'], '')}
              onChange={(value) =>
                this.props.onChange(
                  this.props.editingCredentials.setIn(['kerberos', 'servicePrincipal'], value),
                )
              }
              disabled={isDisabled}
            />
            <HelpBlock>
              Name of the principal for the ODBC connection. A fully qualified name must be used:{' '}
              <code>[service]/[host]@[realm]</code>.
            </HelpBlock>
          </div>
        </FormGroup>

        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="config">Config</Label>
          <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
            <Textarea
              id="config"
              minRows={4}
              className="form-control"
              value={this.props.credentials.getIn(['kerberos', 'config'], '')}
              onChange={({ target }) =>
                this.props.onChange(
                  this.props.editingCredentials.setIn(['kerberos', 'config'], target.value),
                )
              }
              disabled={isDisabled}
            />
            <HelpBlock>
              Content of the <code>krb5.conf</code> file.
            </HelpBlock>
          </div>
        </FormGroup>

        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="keytab">Keytab</Label>
          <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
            <Textarea
              id="keytab"
              minRows={4}
              className="form-control"
              value={this.props.credentials.getIn(['kerberos', '#keytab'], '')}
              onChange={({ target }) =>
                this.props.onChange(
                  this.props.editingCredentials.setIn(['kerberos', '#keytab'], target.value),
                )
              }
              disabled={isDisabled}
            />
            <HelpBlock>
              Base64 encoded content of the <code>*.keytab</code> file
            </HelpBlock>
          </div>
        </FormGroup>
      </>
    );
  },

  renderAdditionalAdvancedFields(isDisabled) {
    if (!this.supportAdditionalFields()) {
      return null;
    }

    return (
      <>
        <h3 className="tw-m-0">Advanced Options</h3>
        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="http-path">Http Path</Label>
          <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
            <TextInput
              id="http-path"
              variant="secondary"
              placeholder="Keep empty for default behavior"
              value={this.props.credentials.get('httpPath', '')}
              onChange={(value) =>
                this.props.onChange(this.props.editingCredentials.set('httpPath', value))
              }
              disabled={isDisabled}
            />
            <HelpBlock>
              Default <code>/hive2</code> if using Windows Azure HDInsight Service. <code>/</code>{' '}
              if using non-Windows Azure HDInsight Service with Thrift Transport set to HTTP.
            </HelpBlock>
          </div>
        </FormGroup>

        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="thift-transport">Thrift Transport</Label>
          <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
            <Select
              id="thift-transport"
              placeholder="Keep empty for default behavior"
              value={this.props.credentials.get('thriftTransport')}
              onChange={(selected) =>
                this.props.onChange(this.props.editingCredentials.set('thriftTransport', selected))
              }
              options={[
                { value: 0, label: 'Binary' },
                { value: 1, label: 'SASL' },
                { value: 2, label: 'HTTP' },
              ]}
              disabled={isDisabled}
            />
            <HelpBlock>
              Default <code>Binary</code> if you are connecting to Hive Server 1. <code>SASL</code>{' '}
              if you are connecting to Hive Server 2.
            </HelpBlock>
          </div>
        </FormGroup>

        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Label htmlFor="batch-size">Batch Size</Label>
          <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
            <FormControl
              id="batch-size"
              min={1}
              type="number"
              value={this.props.credentials.get('batchSize', 10000)}
              onChange={({ target }) =>
                this.props.onChange(
                  this.props.editingCredentials.set('batchSize', parseInt(target.value) || 1),
                )
              }
              disabled={isDisabled}
            />
            <HelpBlock>
              It sets <code>RowsFetchedPerBlock</code> parameter.
            </HelpBlock>
          </div>
        </FormGroup>
        {this.props.hasArtifacts && (
          <FormGroup className={FORM_GROUP_CLASS_NAMES}>
            <div className="tw-col-span-2 tw-col-start-2 tw-flex tw-flex-col tw-gap-1">
              <Checkbox
                checked={this.props.credentials.get('verboseLogging', false)}
                onChange={(checked) =>
                  this.props.onChange(this.props.editingCredentials.set('verboseLogging', checked))
                }
              >
                Verbose Logging
              </Checkbox>
              <HelpBlock>Logs all driver activity.</HelpBlock>
            </div>
          </FormGroup>
        )}
        <hr className="tw-m-0" />
      </>
    );
  },

  renderSshForm() {
    return (
      <SshForm
        isEnabled={!this.props.isSaving}
        readOnly={this.props.isSaving || this.props.readOnly}
        data={this.props.credentials.get('ssh', Map())}
        onChange={(ssh) => {
          this.props.onChange(
            this.props.editingCredentials.set(
              'ssh',
              ssh.get('enabled')
                ? ssh
                : Map({
                    enabled: false,
                  }),
            ),
          );
        }}
      />
    );
  },

  renderSslForm(isDisabled) {
    const activeSsl = this.props.credentials.getIn(['ssl', 'enabled'], false);
    const caFileType = this.props.credentials.getIn(['ssl', 'caFileType'], SSL_FILE_TYPES.PEM);
    const caType = caFileType === SSL_FILE_TYPES.PEM && this.isCsasComponent() ? '#ca' : 'ca';

    return (
      <>
        <FormGroup className={FORM_GROUP_CLASS_NAMES}>
          <Checkbox
            className="tw-col-span-2 tw-col-start-2"
            checked={activeSsl}
            onChange={(checked) =>
              this.props.onChange(
                this.props.editingCredentials.set(
                  'ssl',
                  Map({
                    enabled: checked,
                    ...(checked && {
                      caFileType: SSL_FILE_TYPES.PEM,
                      ca: '',
                      verifyServerCert: true,
                    }),
                  }),
                ),
              )
            }
          >
            Encrypted (SSL) connection
          </Checkbox>
        </FormGroup>

        {activeSsl && (
          <>
            <FormGroup className={FORM_GROUP_CLASS_NAMES}>
              <Label htmlFor="file-type">File Type</Label>
              <Select
                id="file-type"
                className="tw-col-span-2"
                clearable={false}
                options={Object.values(SSL_FILE_TYPES).map((value) => ({
                  value,
                  label: string.capitalize(value),
                }))}
                value={caFileType}
                onChange={(value) =>
                  this.props.onChange(
                    this.props.credentials
                      .setIn(['ssl', 'caFileType'], value)
                      .deleteIn([
                        'ssl',
                        value === SSL_FILE_TYPES.PEM && this.isCsasComponent() ? 'ca' : '#ca',
                      ]),
                  )
                }
                disabled={isDisabled}
              />
            </FormGroup>

            <FormGroup className={FORM_GROUP_CLASS_NAMES}>
              <Label htmlFor="ca-certificate">CA Certificate</Label>
              <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-1">
                <Textarea
                  id="ca-certificate"
                  minRows={4}
                  className="form-control"
                  value={this.props.credentials.getIn(['ssl', caType], '')}
                  onChange={({ target }) => {
                    this.props.onChange(
                      this.props.editingCredentials.setIn(['ssl', caType], target.value),
                    );
                  }}
                  disabled={isDisabled}
                />
                <HelpBlock>
                  Trusted certificates in {caFileType === SSL_FILE_TYPES.PEM ? 'PEM' : 'JKS'}{' '}
                  format.
                </HelpBlock>
              </div>
            </FormGroup>

            <FormGroup className={FORM_GROUP_CLASS_NAMES}>
              <Checkbox
                className="tw-col-span-2 tw-col-start-2"
                disabled={isDisabled}
                checked={this.props.credentials.getIn(['ssl', 'verifyServerCert'], true)}
                onChange={(checked) => {
                  this.props.onChange(
                    this.props.editingCredentials.setIn(['ssl', 'verifyServerCert'], checked),
                  );
                }}
              >
                Verify server certificate
              </Checkbox>
            </FormGroup>

            <FormGroup className={FORM_GROUP_CLASS_NAMES}>
              <Checkbox
                className="tw-col-span-2 tw-col-start-2"
                disabled={isDisabled}
                checked={this.props.credentials.getIn(['ssl', 'ignoreCertificateCn'], false)}
                onChange={(checked) => {
                  this.props.onChange(
                    this.props.editingCredentials.setIn(['ssl', 'ignoreCertificateCn'], checked),
                  );
                }}
              >
                Ignore Certificate CN
              </Checkbox>
            </FormGroup>
          </>
        )}
      </>
    );
  },

  renderQueriesWarning() {
    if (!this.props.hasQueries) {
      return null;
    }

    return (
      <HelpBlock>
        Note that by changing the credentials (e.g., database name), all your configured queries may
        become invalid.
      </HelpBlock>
    );
  },

  renderTestButton() {
    if (this.props.readOnly) {
      return null;
    }

    return (
      <TestCredentialsButtonGroup
        componentId={this.props.componentId}
        configId={this.props.configId}
        isEditing={this.props.isChanged}
        disabled={this.props.isSaving || !this.props.hasValidCredentials()}
        testCredentialsFn={() =>
          this.props.actionCreators.testCredentials(this.props.configId, this.props.credentials)
        }
      />
    );
  },

  isCsasComponent() {
    return [KEBOOLA_EX_DB_HIVE_CSAS, KEBOOLA_EX_DB_HIVE_CSAS_TEST, KDS_TEAM_WR_HIVE_CSAS].includes(
      this.props.componentId,
    );
  },

  supportAdditionalFields() {
    return [KEBOOLA_EX_DB_HIVE_CSAS, KEBOOLA_EX_DB_HIVE_CSAS_TEST, KEBOOLA_EX_DB_HIVE].includes(
      this.props.componentId,
    );
  },
});

export default HiveCredentials;
