const SwitchStateLabel = (props: { isActive: boolean }) => {
  return (
    <div className="flex-container fill-space">
      <span>{props.isActive ? 'Enabled' : 'Disabled'}</span>
      <span className="tw-text-xs tw-text-neutral-400">
        Click to {props.isActive ? 'Disable' : 'Enable'}
      </span>
    </div>
  );
};

export default SwitchStateLabel;
