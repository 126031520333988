import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { ClipboardInline, cn, Icon, Link } from '@keboola/design';

import SettingsStore from '@/modules/settings/SettingsStore';
import CodeEditor from '@/react/common/CodeEditor';
import Loader from '@/react/common/Loader';
import contactSupport from '@/utils/contactSupport';
import { getQueriesToRun, upgrade } from './actions';

const UpgradePage = () => {
  const [queriesToRun, setQueriesToRun] = useState<string[]>(['test']);
  const [isLoading, setIsLoading] = useState(false);
  const [messageType, setMessageType] = useState<'success' | 'error' | null>(null);

  useEffect(() => {
    let poll: NodeJS.Timeout;

    getQueriesToRun().then((queries) => {
      if (!queries.length) {
        return setMessageType('success');
      }

      setQueriesToRun(queries);

      poll = setInterval(() => {
        getQueriesToRun().then((queries) => {
          if (!queries.length) {
            clearInterval(poll);

            return upgrade();
          }

          setQueriesToRun(queries);
        });
      }, 3000);
    });

    return () => poll && clearInterval(poll);
  }, []);

  return (
    <div className="upgrade-spc-page max-w-700 ml-auto mr-auto">
      <div className="mbp-8 text-center">
        <h1 className="mtp-8 mbp-4 f-24 line-height-32">Enable Full Features</h1>
        <p className="mbp-4 font-medium">
          You&apos;re only one step away from unleashing Keboola&apos;s full potential. All you need
          to do is grant us privileges in your Snowflake account.
        </p>
        <p className="text-muted f-12">
          To leverage the offering to the fullest, you need to grant Keboola the rights to create
          users, schemas, databases or to read query history, inside your Snowflake. Granting
          privileges enables you to take advantage of all the features and capabilities that Keboola
          has to offer. Rest assured that we take the security of your data and account very
          seriously. If you have any questions or concerns, please don&apos;t hesitate to reach out
          to our support team.
        </p>
      </div>
      <div>
        <h2 className="mtp-8 mbp-2 f-16 color-main line-height-24">How to</h2>
        <ol className="line-height-28">
          <li>Copy the code below</li>
          <li>
            Run it in your{' '}
            <Link
              href={`https://${SettingsStore.getProject()
                .getIn(['backends', 'snowflake', 'host'], '')
                .replace(/^https?:\/\//, '')}`}
            >
              Snowflake
            </Link>
          </li>
          <li>Return to Keboola</li>
        </ol>
      </div>
      {queriesToRun.length > 0 && (
        <div>
          <div className="flex-container align-bottom">
            <h2 className="mtp-8 mbp-2 f-16 color-main line-height-24">Code</h2>
            <ClipboardInline
              text={queriesToRun.join('\n')}
              tooltipPlacement="top"
              className="tw-mb-2"
            />
          </div>
          <CodeEditor value={queriesToRun.join('\n')} />
        </div>
      )}
      <div className="flex-container gap-16 mtp-8 justify-center">
        <Button onClick={() => contactSupport()}>Contact Support</Button>
        <Button
          bsStyle="success"
          onClick={() => {
            setIsLoading(true);
            setMessageType(null);

            getQueriesToRun()
              .then((queries) => {
                if (!queries.length) {
                  return upgrade();
                }

                setQueriesToRun(queries);
                setMessageType('error');
              })
              .finally(() => setIsLoading(false));
          }}
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <Loader className="icon-addon-right" />
              Enabling...
            </>
          ) : (
            <>
              {!queriesToRun.length && <Icon icon="circle-check" className="icon-addon-right" />}
              {!queriesToRun.length ? 'Confirm' : 'Enable All Features'}
            </>
          )}
        </Button>
      </div>
      {messageType && (
        <p
          className={cn('mtp-4 f-12 font-medium text-center', {
            'color-danger': messageType === 'error',
          })}
        >
          {messageType === 'error' ? (
            <>
              <Icon icon="triangle-exclamation" className="f-16 icon-addon-right" />
              You haven&apos;t granted the necessary privileges yet. If you need help, reach out to
              our support team.
            </>
          ) : (
            <>
              <Icon icon="check" className="f-16 color-success icon-addon-right" />
              You have successfully granted us privileges.
            </>
          )}
        </p>
      )}
    </div>
  );
};

export default UpgradePage;
