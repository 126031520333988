import type { SyntheticEvent } from 'react';

import { cn, Icon } from '@keboola/design';

import Loader from './Loader';

const RefreshIcon = (props: { isLoading: boolean; onClick?: (e: SyntheticEvent) => void }) => {
  if (props.isLoading) {
    return <Loader />;
  }

  return (
    <Icon
      fixedWidth
      icon="arrows-rotate"
      title={!!props.onClick ? 'Refresh' : ''}
      className={cn({ clickable: !!props.onClick })}
      onClick={props.onClick}
    />
  );
};

export default RefreshIcon;
