import { createContext, useContext } from 'react';

import type { ApiClient } from '../client';
import { ErrorMessage } from '../constants';

const context = createContext<ApiClient | null>(null);

export const Provider = context.Provider;

export const useApiClient = () => {
  const client = useContext(context);
  if (client === null) throw new Error(ErrorMessage.REACT_MISSING_CONTEXT_PROVIDER);
  return client;
};
