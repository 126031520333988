import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { bucketDisplayNameWithStage } from '@/modules/storage/helpers';
import Select from '@/react/common/Select';
import { bucketLabel } from '@/react/common/selectLabels';

const BucketsSelector = createReactClass({
  propTypes: {
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    bucketPermissions: PropTypes.object.isRequired,
    allBuckets: PropTypes.object.isRequired,
    permission: PropTypes.string.isRequired,
    wrapperClassName: PropTypes.string,
  },

  render() {
    return (
      <div className={this.props.wrapperClassName}>
        <Select
          placeholder={`Select buckets for ${this.props.permission} access`}
          multi
          disabled={this.props.disabled}
          value={this.getSelectedPermissions()}
          onChange={this.handleSelect}
          options={this.getOptions()}
        />
        <span className="help-block">
          Token has{' '}
          {this.props.permission === 'write' && (
            <>
              <strong>read</strong> and{' '}
            </>
          )}
          <strong>{this.props.permission}</strong> access to the selected buckets.
        </span>
      </div>
    );
  },

  getSelectedPermissions() {
    return this.props.bucketPermissions
      .filter((perm) => perm === this.props.permission)
      .keySeq()
      .toArray();
  },

  getOptions() {
    return this.props.allBuckets
      .filter((bucket, bucketId) =>
        this.props.bucketPermissions.has(bucketId)
          ? this.props.bucketPermissions.get(bucketId) === this.props.permission
          : true,
      )
      .sortBy((bucket) => bucketDisplayNameWithStage(bucket))
      .map((bucket) => ({
        value: bucket.get('id'),
        label: bucketLabel(bucket),
        name: bucket.get('displayName'),
      }))
      .toArray();
  },

  handleSelect(bucketsArray) {
    const perm = this.props.permission;
    const currentBucketPermissions = this.props.bucketPermissions.filter((p) => p !== perm);
    const newPermissions = bucketsArray.reduce(
      (memo, bucketId) => memo.set(bucketId, perm),
      currentBucketPermissions,
    );
    this.props.onChange(newPermissions);
  },
});

export default BucketsSelector;
