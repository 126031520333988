import { useEffect, useRef } from 'react';
import type { ChangeEvent, FormEvent, KeyboardEvent, ReactNode } from 'react';
import { Button, FormControl } from 'react-bootstrap';
import Textarea from 'react-textarea-autosize';

import { cn, Icon } from '@keboola/design';

import { FOCUS_AI_GENERATOR } from '@/constants/customEvents';
import keyCodes from '@/constants/keyCodes';
import Loader from '@/react/common/Loader';
import { AiIcon } from './svgGradient';

const COMMON_INPUT_CLASSES =
  'without-decorations tw-w-full !tw-rounded-md !tw-bg-white tw-pl-[50px] tw-pr-4';

const PromptInput = (props: {
  placeholder: string;
  previousPrompt?: string | null;
  prompt: string;
  onChange: (prompt: string) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  isDisabledInput?: boolean;
  onSubmit?: () => void;
  label?: ReactNode;
  loadingLabel?: string;
  autoFocus?: boolean;
  highlight?: boolean;
  multiLine?: boolean;
  className?: string;
  children?: ReactNode;
  variant?: 'wide' | 'narrow';
}) => {
  const inputRef = useRef<HTMLTextAreaElement | HTMLInputElement | null>(null);

  const submit = (event?: FormEvent<HTMLFormElement> | KeyboardEvent<HTMLTextAreaElement>) => {
    event?.preventDefault();

    if (props.isDisabled) return;

    return props.onSubmit?.();
  };

  useEffect(() => {
    const focusAiGenerator = () => inputRef.current?.focus();

    document.addEventListener(FOCUS_AI_GENERATOR, focusAiGenerator);

    return () => {
      document.removeEventListener(FOCUS_AI_GENERATOR, focusAiGenerator);
    };
  }, []);

  const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === keyCodes.ENTER && !event.shiftKey) {
      submit(event);
    }
  };

  const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    props.onChange(event.target.value);
  };

  return (
    <form className={cn(props.className)} onSubmit={submit}>
      <div
        className={cn(
          'tw-relative tw-rounded-lg tw-bg-[linear-gradient(135deg,#0975E0_0%,#6F36E0_100%)] has-[.form-control:focus]:tw-shadow-outline',
          props.highlight ? 'tw-p-[3px]' : 'tw-p-[2px]',
          { 'tw-opacity-40': props.isDisabledInput },
        )}
      >
        <div className="tw-flex tw-items-center tw-gap-1.5 !tw-rounded-md tw-bg-white">
          <AiIcon className="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-left-[18px] tw-my-auto" />
          {props.multiLine ? (
            <Textarea
              ref={(element) => (inputRef.current = element)}
              minRows={1}
              maxRows={5}
              value={props.prompt}
              autoFocus={props.autoFocus}
              disabled={props.isDisabledInput}
              placeholder={props.previousPrompt ?? props.placeholder}
              onKeyDown={handleKeyDown}
              onChange={handleOnChange}
              className={cn(
                COMMON_INPUT_CLASSES,
                'form-control tw-resize-none',
                props.highlight ? 'tw-py-[15px]' : 'tw-py-3',
              )}
            />
          ) : (
            <FormControl
              inputRef={(element: HTMLInputElement) => (inputRef.current = element)}
              type="text"
              autoFocus={props.autoFocus}
              placeholder={props.previousPrompt ?? props.placeholder}
              value={props.prompt}
              onChange={handleOnChange}
              className={cn(
                COMMON_INPUT_CLASSES,
                'tw-h-11',
                props.highlight ? 'tw-py-[15px]' : 'tw-py-3',
              )}
              disabled={props.isDisabledInput}
            />
          )}
          <div className="tw-my-1.5 tw-mr-1.5 tw-flex tw-gap-4 tw-self-end">
            {props.children}
            {props.onSubmit && (
              <Button
                type="submit"
                bsSize="small"
                bsStyle={props.variant === 'narrow' ? 'link' : 'primary'}
                className={cn({
                  'tw-cursor-not-allowed tw-opacity-65': props.isDisabled || props.isDisabledInput,
                  'hover|focus:tw-bg-secondary-500':
                    (props.isDisabled || props.isDisabledInput) && props.variant !== 'narrow',
                  '!tw-py-2.5 !tw-leading-5': props.highlight,
                  'tw-px-2': props.variant === 'narrow',
                })}
              >
                {props.isLoading ? (
                  <>
                    <Loader className={cn(props.loadingLabel && 'tw-mr-2')} />
                    {props.loadingLabel}
                  </>
                ) : (
                  (props.label ??
                  (props.variant === 'narrow' ? (
                    <Icon icon="paper-plane-top" className="tw-text-secondary-500" />
                  ) : (
                    'Submit'
                  )))
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export default PromptInput;
