import { cn, Icon, Tooltip } from '@keboola/design';

const NoAccessIcon = (props: { classNames?: string }) => {
  return (
    <Tooltip
      placement="left"
      tooltip="You are not a member of this project; therefore, you can only view the lineage and metadata of the selected element."
      type="explanatory"
    >
      <Icon
        icon="lock"
        className={cn('tw-ml-auto tw-text-base tw-text-warning-500', props.classNames)}
      />
    </Tooltip>
  );
};

export default NoAccessIcon;
