import { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import _ from 'underscore';

import { Alert, FormGroup, HelpBlock, Icon, Label, TextInput } from '@keboola/design';

import { createWorkbook } from '@/modules/wr-onedrive/action';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import SyncActionError from '@/utils/errors/SyncActionError';

const INITIAL_STATE = {
  showModal: false,
  isCreating: false,
  error: null,
  warning: null,
  path: '',
};

class CreateWorkbook extends Component {
  constructor(props) {
    super(props);

    this.state = INITIAL_STATE;

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <>
        <Button bsStyle="success" onClick={this.openModal} disabled={this.props.disabled}>
          <Icon icon="plus" className="icon-addon-right" />
          Create Workbook
        </Button>
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Create Workbook</Modal.Title>
              <ModalIcon.Plus />
            </Modal.Header>
            <Modal.Body>
              {this.state.error && (
                <Alert variant="error" className="tw-mb-5">
                  {this.state.error}
                </Alert>
              )}
              {this.state.warning && (
                <Alert variant="warning" className="tw-mb-5">
                  {this.state.warning}
                </Alert>
              )}
              <FormGroup className="tw-mb-4 tw-grid tw-grid-cols-4 tw-items-baseline tw-gap-4">
                <Label htmlFor="path">Path</Label>
                <div className="tw-col-span-3 tw-flex tw-flex-col tw-gap-1">
                  <TextInput
                    id="path"
                    variant="secondary"
                    autoFocus
                    disabled={this.state.isCreating}
                    value={this.state.path}
                    onChange={(path) => this.setState({ path })}
                  />
                  <HelpBlock>
                    Allowed formats are: <br />
                    <code>{'/path/to/file.xlsx'}</code> - the file is created on a personal OneDrive
                  </HelpBlock>
                </div>
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <ConfirmButtons
                block
                saveLabel="Create"
                saveButtonType="submit"
                isSaving={this.state.isCreating}
                isDisabled={!this.state.path.trim()}
              />
            </Modal.Footer>
          </form>
        </Modal>
      </>
    );
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ isCreating: true, warning: null, error: null });
    return createWorkbook(this.props.configId, this.state.path)
      .then(({ file }) => {
        const name = _.last(this.state.path.split('/'));
        this.props.onCreate({ name, driveId: file.driveId, fileId: file.fileId });
        this.closeModal();
        return null;
      })
      .catch(SyncActionError, (error) => {
        this.setState({ error: error.message });
      })
      .finally(() => {
        this.setState({ isCreating: false });
      });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState(INITIAL_STATE);
  }
}

CreateWorkbook.propTypes = {
  disabled: PropTypes.bool.isRequired,
  configId: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
};

export default CreateWorkbook;
