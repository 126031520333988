import { Children, cloneElement, Fragment, isValidElement } from 'react';
import type { ReactElement, ReactNode } from 'react';

export const injectPropsToChildren = (children: ReactNode, props: Record<string, any>) => {
  return Children.toArray(children).map((child) => {
    if (!isValidElement(child)) return child;

    if (child.type === Fragment) {
      return Children.toArray(child.props.children).map(
        (fragmentChild) =>
          isValidElement(fragmentChild) && cloneElement(fragmentChild as ReactElement<any>, props),
      );
    }

    return cloneElement(child as ReactElement<any>, props);
  });
};
