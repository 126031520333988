import { useEffect, useState } from 'react';

import type { WorkspaceData } from './api';
import { createCredentials } from './api';
import LazyLoadedDashboard from './LazyLoadedDashboard';
import LoadingData from './LoadingData';

const BillingConsumptionInfo = () => {
  const [credentials, setCredentials] = useState<WorkspaceData | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    createCredentials()
      .then(setCredentials)
      .finally(() => setIsLoading(false));
  }, []);

  if (!credentials) {
    return <LoadingData isLoading={isLoading} />;
  }

  return <LazyLoadedDashboard credentials={credentials} />;
};

export default BillingConsumptionInfo;
